import { IAccount } from "../../models/IAccount";
import { IAccountPosition } from "../../models/IAccountPosition";
import { IQuoteMediaPrice } from "../../models/IQuoteMediaPrice";
import { ISymbolPosition } from "../../models/ISymbolPosition";
import { IUserPosition } from "../../models/IUserPosition";
import IndexedDBService from "../../utils/idb_helper";
import { getUnrealPnl } from "../../utils/pnl";
import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import { QUOTE_MEDIA_PRICE } from "../quote-media/quote-media-types";
import {
  POSITIONS_COPY_STATE,
  POSITIONS_SET_ACCOUNTS,
  POSITIONS_SET_SYMBOLS,
  POSITIONS_UPDATE_ACCOUNT,
  POSITIONS_UPDATE_SYMBOL,
} from "./positions-types";
import { PositionsState } from "./PositionsState";

const initialState = new PositionsState();

export default function positionsReducer(
  state = initialState,
  action: any
): PositionsState {
  let accounts: IAccount[] = action.accounts;
  let accountPositions: IAccountPosition[] = [];

  let symPositions: ISymbolPosition[];

  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new PositionsState();

    case POSITIONS_SET_ACCOUNTS:
      accountPositions = action.payload.map(
        (position: IAccountPosition) =>
          ({
            ...position,
            Group: accounts.find((x) => x.AccountId === position.AccountId)
              ?.AccountGroup?.Name,
            RunUp: 0,
            DrawDown: 0,
          } as IAccountPosition)
      );
      return {
        ...state,
        accounts: accountPositions,
      };

    case POSITIONS_SET_SYMBOLS:
      let symbols: ISymbolPosition[] = action.payload.map(
        (position: ISymbolPosition) => {
          const account = accounts.find(
            (x) => x.AccountId === position.AccountId
          );

          return {
            ...position,
            // AccountName: account?.Name,
            Currency: account?.Currency?.Name,
            Group: account?.AccountGroup?.Name,
            Last: position.Position !== 0 ? position.Last : 0,
            RunUp: position.TotalPL >= 0 ? position.TotalPL : 0,
            DrawDown: position.TotalPL <= 0 ? position.TotalPL : 0,
          } as ISymbolPosition;
        }
      );

      symbols.forEach(
        (position) => (position.TotalPL = position.RealPL + position.UnrealPL)
      );

      accountPositions = getAccountPositions(state, symbols);
      return {
        ...state,
        ...getUserPosition(action.payload),
        accounts: accountPositions,
        symbols,
      };

    case POSITIONS_UPDATE_ACCOUNT:
      accountPositions = updateAccountPositions(
        state,
        accounts,
        action.payload
      );
      return {
        ...state,
        accounts: accountPositions,
      };

    case POSITIONS_UPDATE_SYMBOL:
      symPositions = upsertSymbolPosition(
        state,
        action.payload,
        action.accounts
      );
      accountPositions = getAccountPositions(state, symPositions);
      return {
        ...state,
        symbols: symPositions,
        accounts: accountPositions,
        ...getUserPosition(symPositions),
      };

    case QUOTE_MEDIA_PRICE:
      const prices = action.payload as IQuoteMediaPrice[];
      symPositions = updateSymbolPositions([...state.symbols], prices);
      accountPositions = getAccountPositions(state, symPositions);
      return {
        ...state,
        symbols: symPositions,
        accounts: accountPositions,
        ...updateUserPosition(symPositions),
      };

    case POSITIONS_COPY_STATE:
      return action.payload;

    default:
      return state;
  }
}

function getAccountPositions(
  state: PositionsState,
  positions: ISymbolPosition[]
): IAccountPosition[] {
  const accountPositions = [...state.accounts];
  accountPositions.forEach((accPosition) => {
    const accPositions = positions.filter(
      (x) => x.AccountId === accPosition.AccountId
    );
    const reducer = (accumulator: number, pnl: number) => accumulator + pnl;
    accPosition.RealPL = accPositions.map((x) => x.RealPL).reduce(reducer, 0);
    accPosition.UnrealPL = accPositions.map((x) => x.UnrealPL).reduce(reducer, 0);
    accPosition.TotalPL = accPositions.map((x) => x.TotalPL).reduce(reducer, 0);

    if (accPosition.TotalPL >= 0 && accPosition.TotalPL > accPosition.RunUp) {
      accPosition.RunUp = accPosition.TotalPL;
    }

    if (
      accPosition.TotalPL <= 0 &&
      accPosition.TotalPL < accPosition.DrawDown
    ) {
      accPosition.DrawDown = accPosition.TotalPL;
    }
  });

  return accountPositions;
}

function getUserPosition(positions: ISymbolPosition[]): IUserPosition {
  const userPosition: IUserPosition = {
    position: 0,
    totalVolume: 0,
    openPnL: 0,
    closePnL: 0,
  };

  positions.forEach((position: ISymbolPosition) => {
    userPosition.totalVolume += position.TotalPosition;
    userPosition.position += Math.abs(position.Position);
    userPosition.openPnL += position.UnrealPL;
    userPosition.closePnL += position.RealPL;
  });

  return userPosition;
}

function updateAccountPositions(
  state: PositionsState,
  accounts: IAccount[],
  newAccPosition: IAccountPosition
): IAccountPosition[] {
  newAccPosition.Group = accounts.find(
    (x) => x.AccountId === newAccPosition.AccountId
  )?.AccountGroup?.Name;

  const accPositions = [...state.accounts];
  const accPosition = accPositions.find(
    (x) => x.AccountId === newAccPosition.AccountId
  );

  if (accPosition) {
    newAccPosition.RunUp = accPosition.RunUp;
    newAccPosition.DrawDown = accPosition.DrawDown;

    const idx = accPositions.indexOf(accPosition as IAccountPosition);
    accPositions.splice(idx, 1, newAccPosition);
  } else {
    newAccPosition.RunUp = 0;
    newAccPosition.DrawDown = 0;

    accPositions.push(newAccPosition);
  }
  IndexedDBService.getValueByKey("AccountPositions", newAccPosition.AccountId).then((currentSymbolPosition) => {
    if(!currentSymbolPosition)
    {
      IndexedDBService.writeToDB("AccountPositions", "AccountId", {...newAccPosition, MinBPUsed: newAccPosition.BPUsed, MaxBPUsed: newAccPosition.BPUsed});
    }
    else {
      IndexedDBService.writeToDB("AccountPositions", "AccountId", {...newAccPosition, MinBPUsed: Math.min(newAccPosition.BPUsed, currentSymbolPosition.MinBPUsed), MaxBPUsed: Math.max(newAccPosition.BPUsed, currentSymbolPosition.MaxBPUsed)});
    }
  });

  return accPositions;
}

function upsertSymbolPosition(
  state: PositionsState,
  newSymPosition: ISymbolPosition,
  accounts: IAccount[]
) {
  const account = accounts.find(
    (x) => x.AccountId === newSymPosition.AccountId
  );
  const { TotalPL } = newSymPosition;

  newSymPosition = {
    ...newSymPosition,
    AccountName: account?.Name,
    Currency: account?.Currency?.Name,
    Group: account?.AccountGroup?.Name,
    RunUp: TotalPL >= 0 ? TotalPL : 0,
    DrawDown: TotalPL <= 0 ? TotalPL : 0,
  };

  const symPositions = [...state.symbols];
  const symPosition = symPositions.find((x) => x.Id === newSymPosition.Id);

  if (symPosition) {
    const idx = symPositions.indexOf(symPosition as ISymbolPosition);
    symPositions.splice(idx, 1, newSymPosition);
  } else {
    symPositions.push(newSymPosition);
  }
  IndexedDBService.writeToDB("SymbolPositions", "Id", newSymPosition);

  return symPositions;
}

function updateSymbolPositions(
  symPositions: ISymbolPosition[],
  prices: IQuoteMediaPrice[]
) {
  const price = prices[prices.length - 1];
  const sPositions = symPositions.filter((x) => {
    return x.Symbol === price.symbol;
  });

  sPositions.forEach((symPosition: ISymbolPosition) => {
    const { AvgPrice, Exposure, Position, RealPL } = symPosition;
    const { last } = price;

    const unrealPnl = getUnrealPnl(Position, Exposure, last, AvgPrice);

    symPosition = {
      ...symPosition,
      Last: symPosition.Position !== 0 ? price.last : 0,
      UnrealPL: unrealPnl,
      TotalPL: RealPL + unrealPnl,
    };

    const { DrawDown, RunUp, TotalPL } = symPosition;
    if (TotalPL >= 0 && (!RunUp || TotalPL > RunUp)) {
      symPosition.RunUp = TotalPL;
    }

    if (TotalPL <= 0 && (!DrawDown || TotalPL < DrawDown)) {
      symPosition.DrawDown = TotalPL;
    }

    const idx = symPositions.findIndex(
      (x) => x.Symbol === price.symbol && x.AccountId === symPosition.AccountId
    );
    symPositions.splice(idx, 1, symPosition);
    IndexedDBService.writeToDB("SymbolPositions", "Id", symPosition);
  });

  return symPositions;
}

function updateUserPosition(positions: ISymbolPosition[]): IUserPosition {
  const userPosition: IUserPosition = {
    position: 0,
    totalVolume: 0,
    openPnL: 0,
    closePnL: 0,
  };

  positions.forEach((position: ISymbolPosition) => {
    userPosition.totalVolume += position.TotalPosition;
    userPosition.position += Math.abs(position.Position);
    userPosition.openPnL += getUnrealPnl(
      position.Position,
      position.Exposure,
      position.Last || 0,
      position.AvgPrice
    );
    userPosition.closePnL += position.RealPL;
  });

  return userPosition;
}
