import { RssFeedOutlined } from "@material-ui/icons";
import FlexView from "react-flexview";
import { connect } from "react-redux";
import { QuoteMediaConnectionStatus } from "../../enums/QuoteMediaConnectionStatus";
import QuoteMedia from "../../Images/quotemedia.png";
import YouTube from "../../Images/youtube.png";
import { IAppState } from "../../store/reducers/IAppState";
import Advertisement from "../advertisement/advertisement";
import LiveChat from "../live-chat/live-chat";
import "./styles.scss";

const Footer = (props) => {
  return (
    <div className="footer w-100" style={{ bottom: "0", zIndex: 9999 }}>
      <FlexView height={30}>
        <FlexView vAlignContent="center" hAlignContent="left">
          <div className="copy-right-info">
            <span>&copy; 2024 Haywood financial Technologies Inc.</span>
            <span className="ml-2">Market Data Powered By</span>
            <img
              src={QuoteMedia}
              alt="Quote Media"
              className="ml-2 quote-media-link"
              onClick={openQuoteMedia}
            />
          </div>
        </FlexView>
        <FlexView grow>
          <Advertisement />
        </FlexView>
        <FlexView
          className="help-center-wrapper"
          vAlignContent="center"
          hAlignContent="right"
        >
          <div className="ml-2">
            <span className="connection-status">
              Market Data
              <RssFeedOutlined
                className={props.isQmConnected ? "is-connected" : ""}
              />
            </span>
            <span className="connection-status">
              Gateway
              <RssFeedOutlined
                className={props.isNatsConnected ? "is-connected" : ""}
              />
            </span>
          </div>
        </FlexView>
      </FlexView>
    </div>
  );
};

const openQuoteMedia = () => {
  const newWindow = window.open(
    "https://quotemedia.com/",
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) newWindow.opener = null;
};
const mapStateToProps = (state: IAppState) => ({
  isNatsConnected: state.connection.isNatsConnected,
  isQmConnected:
    state.qm.connectionStatus === QuoteMediaConnectionStatus.Connected,
});

export default connect(mapStateToProps)(Footer);
