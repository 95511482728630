import axios, { AxiosInstance, AxiosResponse } from "axios";
import { refreshToken } from "../store/auth/auth-async-actions";
import tempCache from "./temp-cache";

class ApiService {
  api: AxiosInstance;
  bearerToken?: string;

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_HW_SVC_BASE_URL,
      timeout: 250000,
    });

    this.api.interceptors.request.use(config => {
      let headers = {
        ...config.headers,
        application: process.env.REACT_APP_NAME,
        version: process.env.REACT_APP_VERSION
      };

      if (this.bearerToken)
        headers.Authorization = `Bearer ${this.bearerToken}`;

      return {
        ...config,
        headers
      };
    });

    const interval = setInterval(() => {
      if (!tempCache.username) {
        clearInterval(interval);
        return;
      }

      const date = new Date();
      date.setMinutes(date.getMinutes() - 29);
      if (tempCache.lastLoginAt < date.getTime()) {
        refreshToken();
      }
    }, 5 * 1000);
  }

  delete(
    url: string,
    primaryKey: number,
    onfulfilled?: ((value: AxiosResponse<any>) => AxiosResponse<any> | PromiseLike<AxiosResponse<any>>) | null | undefined,
  ): Promise<AxiosResponse<any> | null> {
    return this.api
      .delete(`${url}`, {
        params: {
          PrimaryKey: primaryKey,
          Force: true,
          LoadCache: true,
        }
      })
      .then(onfulfilled)
      .catch(reason => {
        console.log("An error occurred.", url, reason)
        return null;
      });
  }

  get(
    url: string,
    onfulfilled?: ((value: AxiosResponse<any>) => AxiosResponse<any> | PromiseLike<AxiosResponse<any>>) | null | undefined
  ): Promise<AxiosResponse<any> | null> {
    return this.api
      .get(`${url}`)
      .then(onfulfilled)
      .catch(reason => {
        console.log("An error occurred.", url, reason);
        console.error(reason);
        return null;
      });
  }

  put(url: string, data: any) {
    return this.api.put(url, {
      Data: data,
      LoadCache: true
    });
  }
}

const apiSvc = new ApiService();
export default apiSvc;
