import { USERROLES_GET_USERROLES } from "./user-roles-types";
import { UserRolesState } from "./UserRolesState";

const initialState = new UserRolesState();

export default function UserRolesReducer(state = initialState, action: any): UserRolesState {
  switch (action.type) {
    case USERROLES_GET_USERROLES:
      return {
        ...state,
        userRoles: action.payload,
      };

    default:
      return state;
  }
}
