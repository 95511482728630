import { AccountState } from "../account/AccountState";
import { ACCOUNTSDESTINATIONS_GET_ACCOUNTSDESTINATIONS } from "./accounts-destinations-types";

const initialState = new AccountState();

export default function AccountsDestinationsReducer(
  state = initialState,
  action: any
): AccountState {
  switch (action.type) {
    case ACCOUNTSDESTINATIONS_GET_ACCOUNTSDESTINATIONS:
      return {
        ...state,
        destinationsLookup: action.payload,
      };

    default:
      return state;
  }
}
