import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import FlexView from "react-flexview";
import { connect } from "react-redux";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import screenfull from "screenfull";
import GuestPhoto from "../../Images/guest.png";
import { logoutUser } from "../../store/auth/auth-actions";
import store from "../../store/index";
import { IAppState } from "../../store/reducers/IAppState";
import { setSettingModalStatus } from "../../store/settings/settings-actions";
import { setSelectedUserAccount } from "../../store/user-accounts/user-account-actions";
import {
  fetchUserOrders,
  fetchUserPositions,
} from "../../store/user-accounts/user-account-async-action";
import ChangePasswordView from "../change-password/change-password";
import Markets from "../markets/markets";
import { IHeaderDispatchProps, IHeaderProps } from "./IHeaderProps";
import { IHeaderState } from "./IHeaderState";
import { TbSearch } from "react-icons/tb";
import { Dropdown } from "react-bootstrap";
import "./style.scss";
import {
  setSearchParams,
  setSearchResults,
} from "../../store/users/users-actions";
import notificationUrl from "../../Images/WebAdminIconSVG/notification.svg";
import downArrowurl from "../../Images/WebAdminIconSVG/down-arrow.svg";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import globalCache from "../../services/global-cache";
import { areArraysIdentical } from "../../utils/general";

type Props = IHeaderProps & IHeaderDispatchProps;
export class Header extends Component<Props, IHeaderState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentTime: this.getCurrentTime(),
      globalSeachHistory: [],
      fullScreenIcon: faExpand,
      fullScreenLabel: "Full Screen",
      showChangePassword: false,
      searchKey: "all",
      searchValue: "",
    };
  }

  componentDidMount() {
    // const { allads } = this.state;
    // this.ads = [];

    // allads.forEach((x) => {
    //   if (x.type !== "Flash") {
    //     let difference = (x.endTime - x.startTime) / x.frequency;
    //     x["difference"] = difference;

    //     for (
    //       let dueTime = x.startTime;
    //       dueTime < x.endTime;
    //       dueTime += x.difference
    //     ) {
    //       const data = {
    //         ...x,
    //         dueTime,
    //         visibleTime: x.type === "Normal" ? 20 : 30 || 0,
    //       };
    //       this.ads.push(data);
    //     }
    //   } else {
    //     x.dueTime = 0;
    //     x.visibleTime = 60;
    //     this.ads.push(x);
    //   }
    // });
    // console.log(this.ads);

    const element = document.getElementById("searchBar");
    if(element)
    {
      element.addEventListener("keypress", this.getSearchResultsOnEnter);
    }

    setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  componentDidUpdate() {
    this.updateGlobalSearchHistory();
  }

  componentWillUnmount(): void {
    const element = document.getElementById("searchBar");
    if(element)
    {
      element.removeEventListener("keypress", this.getSearchResultsOnEnter);
    }
  }

  // calculateAdTime = () => {
  //   if (this.adText) {
  //     this.ads.forEach((x) => {
  //       let currentTime = new Date();
  //       let timeSinceLastAd = currentTime.getHours() - this.lastAdShownAt;

  //       if (x.visibleTime && timeSinceLastAd < x.visibleTime) {
  //         return;
  //       }
  //       this.adText = "";
  //     });
  //   }

  // remove previous add
  // check if there is another ad to be shown
  // if yes, show that ad and set all the variables accordingly isAdVisible = true
  // if no, simply exit and set isAdVisible = false

  // this.ads.forEach((x) => {
  //   if (x.visibleTime && x.visibleTime > 0) {
  //     // Check whether Ad should be displayed
  //     this.lastAdShownAt = new Date().getHours();
  //     this.adText = x.text;
  //     if (x.text === "Flash") {
  //       // If Ad "Flash", Remove it from ads array
  //       this.ads = this.ads.filter((t) => t.text !== x.text);
  //     }

  //     x.visibleTime -= 1; // Decrement the value because Ad has been displayed one time
  //   } else {
  //     this.ads = this.ads.filter((t) => t.text !== x.text);
  //   }
  // });

  selectHandleChange = (e) => {
    const { userAccounts } = this.props;
    const id = parseInt(e.target.value);
    const filteredUser = userAccounts.filter((x) => x.AccountId === id);
    console.log("filtered User : ", filteredUser);
    // this.props.setSelectedUserAccount(filteredUser);

    this.props.fetchUserOrders(filteredUser[0].AccountId);
    this.props.fetchUserPosition(filteredUser[0].AccountId);
  };

  appendCurrentSearchToHistory = (searchString: string) => {
    const savedSearchHistory = globalCache.globalSearchHistory;
    if(!savedSearchHistory.find(w => w === searchString))
    {
      globalCache.globalSearchHistory = [searchString].concat(savedSearchHistory).slice(0, 9);
    }
    this.updateGlobalSearchHistory();
  };

  getSearchResults = () => {
    if (this.state.searchValue.trim()) {
      this.appendCurrentSearchToHistory(this.state.searchValue.trim());
      store.dispatch(
        setSearchParams({
          key: this.state.searchKey,
          value: this.state.searchValue,
        })
      );
      store.dispatch(
        setSearchResults(
          this.state.searchValue,
          this.state.searchKey[0].toUpperCase() + this.state.searchKey.slice(1)
        )
      );
    }
  };

  getSearchResultsOnEnter = (e) => {
    if(e.key === "Enter")
    {
      this.getSearchResults();
    }
  };

  updateGlobalSearchHistory = () => {
    const updatedSearchHistory = globalCache.globalSearchHistory;
    const currentSearchHistory = this.state.globalSeachHistory;
    if((!areArraysIdentical(updatedSearchHistory, currentSearchHistory)) || currentSearchHistory.length < updatedSearchHistory.length)
    {
      this.setState({globalSeachHistory: updatedSearchHistory});
    }
  };

  render() {
    const { currentTime, fullScreenIcon, fullScreenLabel, showChangePassword, globalSeachHistory } =
      this.state;
    const { displayName, isLoggedIn, profileUrl } = this.props;

    let dropdownData = [
      {
        key: "all",
        value: "All",
      },
      {
        key: "Users",
        value: "Users",
      },
      {
        key: "Accounts",
        value: "Accounts",
      },
      {
        key: "Groups",
        value: "Groups",
      },
      {
        key: "Firms",
        value: "Firms",
      },
      {
        key: "Order",
        value: "Order",
      }
    ];

    if(!this.props.isSuperAdmin)
    {
      dropdownData = dropdownData.filter((w) => w.key !== "Firms");
    }

    return (
      <div className="col-12 header pl-0 pr-1">
        <FlexView height={82}>
          {/* <HeaderStats></HeaderStats> */}
          {/* {this.props.isAdmin && <Form.Label style={{ marginTop: "13px", fontSize: "14px" }}>Acconts:</Form.Label>}
          {this.props.isAdmin && (
            <Form.Control
              as="select"
              // value="Account"
              style={{ width: "10%", marginTop: "10px", marginLeft: "5px" }}
              onChange={(e) => {
                this.selectHandleChange(e);
              }}
            >
              {userAccounts.map((data: any, index) => (
                <option value={data.AccountId}>{data.Account.Name}</option>
              ))}
            </Form.Control>
          )} */}

          <div className="col-4 px-0 d-flex max-width-100">
            <FlexView grow className="header-market-holder m-0">
              <FlexView className="searchbar">
                {/* <Markets></Markets> */}
                <Dropdown className="module">
                  <Dropdown.Toggle className="linking-icon search-dropdown">
                    <span id="filterCategory" title="Link Widget">
                      {
                        dropdownData[
                          dropdownData.findIndex(
                            (key) => key.key === this.state.searchKey
                          )
                        ].value
                      }
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="linking-icon-dropdown-menu">
                    {dropdownData.map((title, index) => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            this.setState({ searchKey: title.key });
                          }}
                          key={index}
                          className="linking-icon-dropdown-items px-3"
                        >
                          <span className="mr-1 px-2 py-1 text-white bg-color-inherit">
                            {title.value}
                          </span>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                {/* <input
                  type="text"
                  placeholder={`Search in ${
                    dropdownData[
                      dropdownData.findIndex(
                        (key) => key.key === this.state.searchKey
                      )
                    ].value
                  }`}
                  value={this.state.searchValue}
                  onChange={(e) => {
                    this.setState({ searchValue: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.getSearchResults();
                    }
                  }}
                /> */}
                <div className="auto-search-custom">
                <AutoCompleteComponent
                  id="searchBar"
                  placeholder={`Search in ${dropdownData[
                      dropdownData.findIndex((key) => key.key === this.state.searchKey)
                    ].value
                    }`}
                  dataSource={globalSeachHistory.map((w, index) => {
                    return {id: index + 1, value: w}
                  })}
                  fields={{ value: 'value' }}
                  autofill={true}
                  value={this.state.searchValue}
                  change={(e) => {
                    this.setState({ searchValue: e.value });
                  }}
                  select={(e) => {
                    this.getSearchResults();
                  }}
                />
                </div>
              </FlexView>
              <TbSearch
                className="searchIcon"
                onClick={this.getSearchResults}
              />
            </FlexView>
          </div>
          <div className="col-5 px-2 d-flex">
            <FlexView
              grow
              className="header-market-holder  justify-content-center"
            >
              <Markets></Markets>
            </FlexView>
          </div>
          <div className="col-3 pl-0 pr-1">
            <FlexView className="header-top-right justify-content-end">
              <FlexView hAlignContent="right">
                <FlexView className="clock-section">
                  <FlexView className="time-clock flex-column">
                    <span className="headerSubOptions">New York</span>
                    <span id="clock_timer">{currentTime}</span>
                  </FlexView>
                </FlexView>
                <FlexView className="profile-section">
                  <div className="d-flex notification-section">
                    <img src={notificationUrl} alt="" />
                  </div>

                  <div className="user-profile">
                    <img
                      src={profileUrl || GuestPhoto}
                      alt="profile"
                    />
                  </div>
                  <UncontrolledDropdown className="user-options-dropdown">
                    <DropdownToggle className="p-0 widgetBtn btn align-items-center">
                      <span className="text-white p-1 text-truncate profile-name" id="profile_name">
                        {displayName || "Guest"}
                      </span>
                      {isLoggedIn && <img className="downarrow-img"
                      src={downArrowurl}
                      alt="downarrow"
                    /> }
                    </DropdownToggle>
                    {isLoggedIn && (
                      <DropdownMenu right className="list-Dropdown-menu">
                        <DropdownItem>Profile</DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            this.setState({
                              showChangePassword: true,
                            })
                          }
                        >
                          Change Password
                        </DropdownItem>

                        <DropdownItem
                          onClick={() =>
                            store.dispatch(setSettingModalStatus(true))
                          }
                        >
                          Settings
                        </DropdownItem>

                        <DropdownItem divider />
                        <DropdownItem onClick={this.logOut}>
                          Log Out
                        </DropdownItem>
                      </DropdownMenu>
                    )}
                  </UncontrolledDropdown>
                </FlexView>

                <FlexView className="maximize-section">
                  <FontAwesomeIcon
                    icon={fullScreenIcon}
                    size={"2x"}
                    id={"viewFullScreenBtn"}
                    onClick={this.viewFullScreen}
                    title={fullScreenLabel}
                  />
                </FlexView>
              </FlexView>
            </FlexView>
          </div>
        </FlexView>

        {showChangePassword && (
          <ChangePasswordView
            displayName={displayName}
            close_Click={() => this.setState({ showChangePassword: false })}
          />
        )}
      </div>
    );
  }

  private getCurrentTime = () => {
    const date = new Date();
    var currentDateTimeCentralTimeZone = date.toLocaleTimeString("en-US", {
      timeZone: "America/New_York",
      hour12: false,
    });
    return `${currentDateTimeCentralTimeZone}`;
  };

  private logOut = () => {
    store.dispatch(logoutUser());
  };

  private updateTime = () => {
    this.setState({ currentTime: this.getCurrentTime() });
  };

  private viewFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();

      const { fullScreenIcon } = this.state;

      if (fullScreenIcon === faExpand) {
        this.setState({
          fullScreenIcon: faCompress,
          fullScreenLabel: "Exit Full Screen",
        });
      } else {
        this.setState({
          fullScreenIcon: faExpand,
          fullScreenLabel: "Full Screen",
        });
      }
    }
  };
}

const mapStateToProps = (state: IAppState): IHeaderProps => {
  return {
    displayName: state.auth.authInfo?.UserName || "",
    isLoggedIn: state.auth.authInfo !== undefined,
    profileUrl: state.auth.authInfo?.ProfileUrl || "",
    userAccounts: state.userAccountsList.userAccountsList || [],
    isAdmin: state.auth.authInfo?.Meta.RoleName === "Admin",
    isSuperAdmin: state.auth.authInfo?.Meta.RoleName === "SuperAdmin",
  };
};
const mapDispatchToProps = (dispatch: any): IHeaderDispatchProps => ({
  setSelectedUserAccount: (data) => dispatch(setSelectedUserAccount(data)),
  fetchUserOrders: (accountId) => dispatch(fetchUserOrders(accountId)),
  fetchUserPosition: (accountId) => dispatch(fetchUserPositions(accountId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
