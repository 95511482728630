import { IAccount } from "../../models/IAccount";
import { IAccountData } from "../../models/IAccountData";
import { IAccountTradeLimit } from "../../models/IAccountTradeLimit";
import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import {
  ACCOUNTS_SET_ACCOUNTS,
  ACCOUNTS_SET_ACCOUNTTRADELIMIT,
} from "./accounts-types";
import { AdminAccountState } from "./AdminAccountState";

const initialState = new AdminAccountState();

export default function AdminAccountsReducer(
  state = initialState,
  action: any
): AdminAccountState {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new AdminAccountState();

    // case ACCOUNTS_SET_ACCOUNTS:
    //   return {
    //     ...state,
    //     accounts: action.payload,
    //   };

    case ACCOUNTS_SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        accountData: prepareAccountsList(
          action.payload,
          state.accountTradeLimits
        ),
      };
    case ACCOUNTS_SET_ACCOUNTTRADELIMIT:
      return {
        ...state,
        accountTradeLimits: action.payload,
        accountData: prepareAccountsList(state.accounts, action.payload),
      };

    default:
      return state;
  }
}

function prepareAccountsList(
  accounts: IAccount[],
  accountTradeLimits: IAccountTradeLimit[]
): IAccountData[] {
  const result = accounts?.map((account) => ({
    account,
    accountTradeLimits: accountTradeLimits?.find(
      (x) => x.AccountId === account.AccountId
    ),
  }));

  return result;
}
