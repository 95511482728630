import store from "..";
import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import { setUserAds } from "./users-actions";
import {
  USERS_SET_ADS,
  USERS_SET_USERS,
  USERS_SET_SEARCH_PARAMS,
  USERS_SHOW_EDIT_FORM,
  USERS_SET_SEARCH_RESULTS,
  USERS_UPDATE_SEARH_RESULTS,
  USERS_UPDATE_ONLINE_USERS_LIST,
  USERS_COPY_USERS_STATE,
} from "./users-types";
import { UsersState } from "./UsersState";

handleExpiredAds();

const initialState = new UsersState();

export default function UsersReducer(
  state = initialState,
  action: any
): UsersState {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new UsersState();

    case USERS_SET_ADS:
      return {
        ...state,
        ads: action.payload,
      };

    case USERS_SET_USERS:
      return {
        ...state,
        data: action.payload,
      };

    case USERS_SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.payload,
        searchResults: state.searchResults ? {...state.searchResults, IsSuccess: false} : undefined
      };

    case USERS_SHOW_EDIT_FORM:
      if (action.payload.data) {
        return {
          ...state,
          showEditForm: action.payload.status,
          formData: action.payload.data,
        };
      }
      return {
        ...state,
        showEditForm: action.payload.status,
      };

    case USERS_SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    case USERS_UPDATE_SEARH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    case USERS_UPDATE_ONLINE_USERS_LIST:
      let onlineUsers = state.onlineUsers.slice();
      if(!action.payload.status && onlineUsers.includes(action.payload.userId))
      {
        onlineUsers = onlineUsers.filter((w) => w !== action.payload.userId);
      }
      else if(action.payload.status && !onlineUsers.includes(action.payload.userId))
      {
        onlineUsers.push(action.payload.userId);
      }
      else {
        return state;
      }
      return {
        ...state,
        onlineUsers
      };

      case USERS_COPY_USERS_STATE:
        return action.payload;

    default:
      return state;
  }
}

function handleExpiredAds() {
  setInterval(() => {
    const { ads } = store.getState().users;
    const expiredAds = ads.filter(
      (x) => x.Expiry.getTime() <= new Date().getTime()
    );

    if (expiredAds.length > 0) {
      const validAds = ads.filter(
        (x) => x.Expiry.getTime() > new Date().getTime()
      );

      store.dispatch(setUserAds(validAds));
    }
  }, 1000);
}
