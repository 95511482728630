import { CONNECTION_COPY_CONNECTION_STATE, CONNECTION_SET_IS_INTERNET_CONNECTED, CONNECTION_SET_IS_NATS_CONNECTED } from "./connection-types";

export const setInternetIsConnected = (isConnected: boolean) => (dispatch) => {
  dispatch({ type: CONNECTION_SET_IS_INTERNET_CONNECTED, payload: isConnected });
}

export const setNatsIsConnected = (isConnected: boolean) => (dispatch) => {
  dispatch({ type: CONNECTION_SET_IS_NATS_CONNECTED, payload: isConnected });
}

export const copyConnectionState = (data) => (dispatch: any) => {
  dispatch({ type: CONNECTION_COPY_CONNECTION_STATE, payload: data });
}

