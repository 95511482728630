export const charFromUint8Array = (array: Uint8Array, offset: number) => {
  const buffer = Buffer.from(array);
  const charCode = buffer.readUIntBE(offset, 1);
  return String.fromCharCode(charCode);
}

export const doubleFromUint8Array = (array: Uint8Array, offset: number) => {
  const buffer = new Uint8Array(array).buffer.slice(offset, offset + 8);
  const reverse = new Uint8Array(buffer).reverse().buffer;
  const view = new DataView(reverse);
  return view.getFloat64(0);
}

export const int16FromUint8Array = (array: Uint8Array, offset: number) => {
  const buffer = new Uint8Array(array).buffer.slice(offset, offset + 2);
  const reverse = new Uint8Array(buffer).reverse().buffer;
  const view = new DataView(reverse);
  return view.getUint16(0);
}

export const int32FromUint8Array = (array: Uint8Array, offset: number) => {
  const buffer = new Uint8Array(array).buffer.slice(offset, offset + 4);
  const reverse = new Uint8Array(buffer).reverse().buffer;
  const view = new DataView(reverse);
  return view.getUint32(0);
}

export const int64FromUint8Array = (array: Uint8Array, offset: number) => {
  const buffer = new Uint8Array(array).buffer.slice(offset, offset + 8);
  const reverse = new Uint8Array(buffer).reverse().buffer;
  const view = new DataView(reverse);
  return view.getBigInt64(0);
}

export const numberFromUint8Array = (array: Uint8Array, offset: number) => {
  const buffer = Buffer.from(array);
  return buffer.readUIntBE(offset, 1);
}

export const stringFromUint8Array = (array: Uint8Array, offset: number, length: number) => {
  const buffer = new Uint8Array(array).buffer.slice(offset, offset + length);
  return new TextDecoder().decode(buffer);
}
