import { ValueFormatterParams } from "ag-grid-community";

export const addThousandSeparators = (value?: string | number) => {
  if (typeof value === "number") {
    value = "" + value;
  }

  return value?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "";
};

export const numberFormatter =
  (decimalPlaces: number) => (params: ValueFormatterParams) => {
    const fixed = params.value?.toFixed(decimalPlaces);

    if (!fixed) {
      return "";
    }

    const parts = fixed.split(".");
    const withSeparators = addThousandSeparators(parts[0]);

    return parts.length === 1
      ? withSeparators
      : `${withSeparators}.${parts[1]}`;
  };

export const volumeFormatter = (params: ValueFormatterParams) => {
  return `${addThousandSeparators(((params.value || 0) / 1000).toFixed(0))}K`;
};

export const wholeNumberFormatter = (params: ValueFormatterParams) =>
  addThousandSeparators(params.value?.toString());

export const formatNumberForKPI = (
  number: number,
  isCurrency?: boolean
): string => {
  if (!number) return isCurrency ? "$0" : "0";

  else if (number < 100000000) {
    const formattedNumber =
      number % 1 !== 0
        ? number.toLocaleString("en-US", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
          })
        : number.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
    if (isCurrency) {
      return `$${formattedNumber}`;
    } else {
      return `${formattedNumber}`;
    }
  }

  else {
    const abbreviations = ["", "K", "M", "B", "T"];
    let i = 0;
    while (number >= 1000 && i < abbreviations.length - 1) {
      number /= 1000;
      i++;
    }
  
    const formattedNumber =
      i === 0 || number % 1 !== 0
        ? number.toLocaleString("en-US", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
          })
        : number.toFixed(0);
  
    if (isCurrency) {
      return `$${formattedNumber}${abbreviations[i]}`;
    } else {
      return `${formattedNumber}${abbreviations[i]}`;
    }
  }

};

export const formatCurrency = (number: number): string => {
  if (!number) {
    return "$0";
  }
  // Combine custom formatting with toLocaleString for currency formatting
  return number.toLocaleString("en-US", {
    useGrouping: true,
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });
};
