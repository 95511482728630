import { useEffect, useRef, useState } from 'react'
import AggridList from '../ag-grid-list/aggrid-list';
import { GridOptions } from 'ag-grid-community';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CustomSpinner from '../spinner/spinner';
import { groupColumnsDefinition } from './GroupColumnsDefinition';
import { loadGroups } from '../../../store/groups/groups-async-actions';
import { ContextMenuOption } from '../../context-menu/context-menu-types';
import store from '../../../store';
import { showEditForm } from '../../../store/users/users-actions';
import editIcon from "../../../Images/editicon.svg";
import copyIcon from '../../../Images/copyicon.svg';
import { copyToClipboard } from '../../../utils/general';
import { IAppState } from '../../../store/reducers/IAppState';

type Props = {
  windowId: string,
  searchResults?: any[]
}

const GroupsList = ({windowId, searchResults}: Props) => {

  const gridOptions: GridOptions = {
    columnDefs: groupColumnsDefinition
  };

  const dispatch = useDispatch();
  const formData = useSelector((state: IAppState) => state.users.formData);

  const [groupData, setGroupData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [currentRow, setCurrentRow] = useState<any>();

  const gridApiRef = useRef<any>(null);

  const groupContextMenuOptions: ContextMenuOption[] = [
    {
      handler: () => { 
        store.dispatch(
          showEditForm({
            status: true,
            data: {
              fieldName: `Edit Group - ${currentRow?.GroupName}`,
              fieldData: currentRow?.Id,
            },
          })
        );
      },
      name: "Edit Group",
      optionIcon: editIcon
    },
    {
      handler: () => copyToClipboard(gridApiRef),
      name: "Copy All",
      optionIcon: copyIcon
    },
  ];

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
  };

  const addNewGroup = () => {
    store.dispatch(
      showEditForm({
        status: true,
        data: {
          fieldName: "Add Group",
          fieldData: -1,
        },
      })
    );
  };

  const loadGroupsList = async () => {
    try {
      const response: any = await loadGroups(dispatch);
      if (response && response.groupsResponse && response.supervisorsResponse) {
        if(searchResults && searchResults.length)
        {
          response.groupsResponse = searchResults;
        }
        const formattedGroups = response.groupsResponse.map((group) => {
          let supervisors = response.supervisorsResponse.filter((w) => w.GroupId == group.GroupId).map((w) => `${w?.Supervisor?.FirstName} ${w?.Supervisor?.LastName}`);
          supervisors = supervisors.filter((w) => w && w.trim().length).length ? supervisors.join(",") : "";
          return {
            FirmName: group?.Firm?.Name,
            GroupName: group?.Name,
            Id: group?.GroupId,
            SuperVisors: supervisors,
            CreatedOn: moment(group.CreatedOn).format("YYYY-MM-DD hh:mm:ss"),
          }
        }) .sort((a, b) => {
          const dateA = new Date(a.CreatedOn).getTime();
          const dateB = new Date(b.CreatedOn).getTime();
          return dateB - dateA;
        });
        setGroupData(formattedGroups);
      }
    }
    catch (e) {
      console.error(e);
    }
    finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    loadGroupsList();
  }, []);

  useEffect(() => {
    if(formData && formData.updatedData && formData.updatedData.groupDetail)
    {
      setIsLoadingData(true);
      loadGroupsList();
    }
  }, [JSON.stringify(formData)]);

  return (
    <>
      {
        isLoadingData
        ?
        <CustomSpinner />
        :
        <AggridList
          gridOptions={gridOptions}
          rowData={groupData}
          pagination={true}
          primaryButtonText={searchResults && searchResults.length > 0 ? "" : 'Add New Group'}
          primaryButtonOnClickHandler={addNewGroup}
          contextMenuOptions={groupContextMenuOptions}
          setRecordForContextMenu={setCurrentRow}
          gridName={"Groups"}
          onGridReady={onGridReady}
          windowId={windowId}
          noKPIData={searchResults && searchResults.length > 0}
          hideInternalSearch={searchResults && searchResults.length > 0}
        />
      }
    </>
  )
}

export default GroupsList