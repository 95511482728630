export const firebaseConfig = {
  apiKey: "AIzaSyBhrARgA3WRSrLBLHe6wUDy69BNO9R6eKA",
  authDomain: "trader2b-admin.firebaseapp.com",
  databaseURL: "https://trader2b-admin-default-rtdb.firebaseio.com",
  projectId: "trader2b-admin",
  storageBucket: "trader2b-admin.appspot.com",
  messagingSenderId: "314863533614",
  appId: "1:314863533614:web:3ba163d53db8ed659de9bf",
  measurementId: "G-QLW20NSH81"
};
