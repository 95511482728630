import { IAccount } from "../../models/IAccount";
import { IAccountTradeLimit } from "../../models/IAccountTradeLimit";
import { ACCOUNTS_SET_ACCOUNTS, ACCOUNTS_SET_ACCOUNTTRADELIMIT } from "./accounts-types";

export const setAccounts = (accounts: IAccount[] | undefined) => (dispatch: any) =>
  dispatch({ type: ACCOUNTS_SET_ACCOUNTS, payload: accounts });

export const setAccountTradeLimits = (accountTradeLimit: IAccountTradeLimit[]) => (dispatch: any) =>
  dispatch({
    type: ACCOUNTS_SET_ACCOUNTTRADELIMIT,
    payload: accountTradeLimit,
  });
