import { NatsMessageTypeEnum } from "../enums/NatsMessageTypeEnum";
import {
  doubleFromUint8Array,
  int16FromUint8Array,
  int32FromUint8Array,
  numberFromUint8Array,
  stringFromUint8Array,
} from "../utils/decoder";
import { ISymbolPosition } from "./ISymbolPosition";

export class SymbolPositionResponse {
  private static readonly _offset = 5;

  private _buffer: Uint8Array;

  constructor(buffer: Uint8Array) {
    this._buffer = buffer;
  }

  public get size(): number {
    return numberFromUint8Array(this._buffer, 0);
  }

  public get messageType(): NatsMessageTypeEnum {
    return numberFromUint8Array(this._buffer, 1);
  }

  public get process(): number {
    return numberFromUint8Array(this._buffer, 2);
  }

  public get symbolIndex(): number {
    return numberFromUint8Array(this._buffer, 3);
  }

  public get symbolLength(): number {
    return numberFromUint8Array(this._buffer, 4);
  }

  public get accountId(): number {
    return int32FromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 4
    );
  }

  public get longOpenExp(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 8
    );
  }

  public get shortOpenExp(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 16
    );
  }

  public get buyOpenShares(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 24
    );
  }

  public get sellOpenShares(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 32
    );
  }

  public get buyOpenOrdersCount(): number {
    return int16FromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 40
    );
  }

  public get sellOpenOrdersCount(): number {
    return int16FromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 44
    );
  }

  public get ordersCount(): number {
    return int16FromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 48
    );
  }

  public get short(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 52
    );
  }

  public get long(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 60
    );
  }

  public get avgPrice(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 68
    );
  }

  public get realPnL(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 76
    );
  }

  public get unrealPnL(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 84
    );
  }

  public get bpUsed(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 92
    );
  }

  public get buyBpUsed(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 100
    );
  }

  public get sellBpUsed(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 108
    );
  }

  public get exposure(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 116
    );
  }

  public get shortExposure(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 124
    );
  }

  public get longExposure(): number {
    return doubleFromUint8Array(
      this._buffer,
      SymbolPositionResponse._offset + 132
    );
  }

  public get symbol() {
    return stringFromUint8Array(
      this._buffer,
      this.symbolIndex,
      this.symbolLength
    );
  }

  public getSymbolPosition(): ISymbolPosition {
    return {
      AccountId: this.accountId,
      AvgPrice: this.avgPrice,
      BPUsed: this.bpUsed,
      BuyBPUsed: this.buyBpUsed,
      Exposure: this.exposure,
      Id: `${this.accountId}${this.symbol}`,
      Long: this.long,
      LongExposure: this.longExposure,
      LongOpenExposure: this.longOpenExp,
      OpenBuyOrderCount: this.buyOpenOrdersCount,
      OpenBuyOrderSharesCount: this.buyOpenShares,
      OpenOrderCount: this.buyOpenOrdersCount + this.sellOpenOrdersCount,
      OpenOrderSharesCount: this.buyOpenShares + this.sellOpenShares,
      OpenSellOrderCount: this.sellOpenOrdersCount,
      OpenSellOrderSharesCount: this.sellOpenShares,
      OrdersCount: this.ordersCount,
      Position: this.long - this.short,
      RealPL: this.realPnL,
      SellBPUsed: this.sellBpUsed,
      Short: this.short,
      ShortExposure: this.shortExposure,
      ShortOpenExposure: this.shortOpenExp,
      Symbol: this.symbol,
      TotalPL: this.realPnL + this.unrealPnL,
      TotalPosition: this.long + this.short,
      UnrealPL: this.unrealPnL,
    };
  }
}
