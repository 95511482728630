import { IColumnDefinition } from "../../../models/IColumnDefinition";
import { CheckboxRenderer } from "../config/ConfigColumnsDefinition";

export const firmColumnsDefinition: IColumnDefinition[] = [
  {
    title: "Name",
    field: "Name",
    visible: true,
    sortOrder: 3,
    minWidth: 80,
  },
  {
    title: "Address",
    field: "Address",
    visible: true,
    sortOrder: 13,
    minWidth: 80,
  },
  {
    title: "City, Country",
    field: "CityCountry",
    visible: true,
    sortOrder: 18,
    minWidth: 70,
  },
  {
    title: "Firm Type",
    field: "FirmType",
    visible: true,
    sortOrder: 18,
    minWidth: 50,
  },
  {
    title: "Risk Enabled",
    field: "RiskEnabled",
    cellRendererFramework: CheckboxRenderer,
    visible: true,
    sortOrder: 18,
    minWidth: 80,
  },
  {
    title: "Created On",
    field: "CreatedOn",
    visible: true,
    sortOrder: 9,
    minWidth: 80,
    filter: 'agDateColumnFilter' 
  }];
