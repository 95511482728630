import store from "..";
import { AccountStatusEnum } from "../../enums/AccountStatusEnum";
import { ExecutionReport } from "../../models/ExecutionReport";
import { IAccount } from "../../models/IAccount";
import { IAccountOrder } from "../../models/IAccountOrder";
import { IAccountTradeLimit } from "../../models/IAccountTradeLimit";
import { IOrderExecution } from "../../models/IOrderExecution";
import { NewOrder } from "../../models/NewOrder";
import userAccountSvc from "../../services/user-account-service";
import { loadAccounts } from "../admin-accounts/accounts-async-actions";
import { IAppState } from "../reducers/IAppState";
import { loadUserAccounts } from "../user-accounts/user-account-async-action";
import {
  ACCOUNTS_COPY_ACCOUNT_STATE,
  ACCOUNT_ADD_ASSIGNED_USER_ACCOUNT,
  ACCOUNT_REMOVE_UNASSIGNED_USER_ACCOUNT,
  ACCOUNT_SET_ACCOUNTS,
  ACCOUNT_SET_ACCOUNT_STATUS,
  ACCOUNT_SET_ACCOUNT_TRADE_LIMITS,
  ACCOUNT_SET_DETACH_WINDOW_DETAILS_IN_PROGRESS,
  ACCOUNT_SET_ORDERS,
  ACCOUNT_SET_ORDER_EXECUTIONS,
  ACCOUNT_SET_USER_SYMBOL_LIST,
  ACCOUNT_UPDATE_ASSIGNED_USER_ACCOUNT,
  ADD_NEW_ORDER,
  ADD_ORDER_EXECUTION,
} from "./account-types";
import { updateExecutionReportToIDB } from "./execution-helper";

export const addAssignedUserAccount =
  (account: IAccount[]) => (dispatch: any) =>
    dispatch({ type: ACCOUNT_ADD_ASSIGNED_USER_ACCOUNT, payload: account });

export const addNewOrder =
  (order: NewOrder) => async (dispatch: any, getState: () => IAppState) => {
    const state = getState();
    let accountName = state.adminAccounts.accounts.find(
      (x) => x.AccountId === order.accountId
    )?.Name;
    if(!accountName)
    {
      // store.dispatch(loadAccounts);
      // store.dispatch(loadUserAccounts);
      // let accounts = await userAccountSvc.get();
      // accountName = accounts.find(x => x.AccountId === order.accountId)?.Account.Name;
    }
    dispatch({
      accountName: accountName,
      displayName: state.auth.authInfo?.DisplayName,
      destinationLookup: state.accountsDestinations.destinationsLookup,
      payload: order,
      type: ADD_NEW_ORDER,
      users: state.users.data,
      userAccounts: state.userAccountsList.userAccountsList,
    });
  };

export const addOrderExecution =
  (executionReport: ExecutionReport) =>
  (dispatch: any, getState: () => IAppState) => {
    const state = getState();
    dispatch({
      type: ADD_ORDER_EXECUTION,
      payload: executionReport,
      users: state.users.data,
      userAccounts: state.userAccountsList.userAccountsList,
      destinationLookup: state.accountsDestinations.destinationsLookup,
    });
    updateExecutionReportToIDB(executionReport, state.accountsDestinations.destinationsLookup, state.userAccountsList.userAccountsList);
  };

export const removeUnassignedUserAccount =
  (accountId: number) => (dispatch: any) =>
    dispatch({
      type: ACCOUNT_REMOVE_UNASSIGNED_USER_ACCOUNT,
      payload: accountId,
    });

export const setAccountOrders =
  (accountOrders: IAccountOrder[]) => (dispatch: any) => {
    dispatch({ type: ACCOUNT_SET_ORDERS, payload: accountOrders });
  };

export const setAccountStatus =
  (accountId: number, status: AccountStatusEnum) => (dispatch: any) =>
    dispatch({
      type: ACCOUNT_SET_ACCOUNT_STATUS,
      payload: { accountId, status },
    });

export const setBuyingPower =
  (accountId: number, BuyingPower: number) => (dispatch: any) =>
    dispatch({
      type: ACCOUNT_SET_ACCOUNT_STATUS,
      payload: { accountId, BuyingPower },
    });

export const setUserAccounts =
  (userAccounts: IAccount[]) => (dispatch: any) => {
    dispatch({ type: ACCOUNT_SET_ACCOUNTS, payload: userAccounts });
  };

export const setUserSymbolsList = (userSymbol: string[]) => (dispatch: any) => {
  dispatch({ type: ACCOUNT_SET_USER_SYMBOL_LIST, payload: userSymbol });
};

export const setDetachWindowDetailsInProgress =
  (isDetachWindowDetails: boolean) => (dispatch: any) => {
    dispatch({
      type: ACCOUNT_SET_DETACH_WINDOW_DETAILS_IN_PROGRESS,
      payload: isDetachWindowDetails,
    });
  };

export const updateAssignedUserAccount =
  (account: IAccount) => (dispatch: any) =>
    dispatch({ type: ACCOUNT_UPDATE_ASSIGNED_USER_ACCOUNT, payload: account });

export const updateAccountTradeLimits =
  (tradeLimits: IAccountTradeLimit) => (dispatch: any) =>
    dispatch({ type: ACCOUNT_SET_ACCOUNT_TRADE_LIMITS, payload: tradeLimits });

export const setOrderExecutions =
(executions: IOrderExecution[]) => (dispatch: any) =>
  dispatch({ type: ACCOUNT_SET_ORDER_EXECUTIONS, payload: executions });

export const copyAccountState = (data) => (dispatch: any) =>
  dispatch({ type: ACCOUNTS_COPY_ACCOUNT_STATE, payload: data });