import { IFirm } from "../../models/IFirm";
import { IFirmTradeLimit } from "../../models/IFirmTradeLimit";
import { FIRMS_SET_FIRMS, FIRMS_SET_FIRMSTRADELIMITS } from "./firms-types";

export const setFirms = (firms: IFirm[] | undefined) => (dispatch: any) =>
  dispatch({ type: FIRMS_SET_FIRMS, payload: firms });

export const setFirmTradeLimits =
  (firmTradeLimits: IFirmTradeLimit[]) => (dispatch: any) =>
    dispatch({ type: FIRMS_SET_FIRMSTRADELIMITS, payload: firmTradeLimits });
