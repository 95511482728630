import { IConfig } from "../models/IConfig";
import apiSvc from "./api-service";

class ConfigServices {
  configData: IConfig[] = [];
  async get() {
    const configResponse = await apiSvc.get("SelectConfigRequest");
    this.configData = configResponse?.data.Data;
    return this.configData;
  }
  async add(configData) {
    await apiSvc.api.put("SaveConfigRequest", configData);
  }

  async delete(configKey) {
    await apiSvc.api.delete("DeleteConfigRequest", {
      params: {
        PrimaryKey: configKey,
        LoadCache: true,
      },
    });
  }
}
const configSvc = new ConfigServices();
export default configSvc;
