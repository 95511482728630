import { DeleteOutlined } from "@material-ui/icons";
import { removeWatchListSymbol$ } from "../../store/watch-list/watch-list-observables";
import { IRemoveSymbolButtonProps } from "./IRemoveSymbolButtonProps";

const RemoveSymbolButton = (props: IRemoveSymbolButtonProps) => {
  const clicked = () => {
    removeWatchListSymbol$.next({
      widgetId: props.widgetId,
      symbol: props.node.data.symbol
    });
  }

  return (
    <DeleteOutlined className="row-delete-button" onClick={clicked} />
  );
};

export default RemoveSymbolButton;
