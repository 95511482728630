import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import { USER_SYMBOL_SET_USER_SYMBOLS } from "./user-symbol-types";
import { UserSymbolState } from "./UserSymbolState";

const initialState = new UserSymbolState();

export default function UserSymbolsReducer(state = initialState, action: any): UserSymbolState {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new UserSymbolState();

    case USER_SYMBOL_SET_USER_SYMBOLS:
      return {
        ...state,
        userSymbolsList: action.payload,
      };
      
    default:
      return state;
  }
}
