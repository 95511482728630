import uuid from "react-uuid";
import { widgetsConfig } from "../../constants/widgets-config";
import { WatchListTypeEnum } from "../../enums/WatchListTypeEnum";

export interface IWorkspaceDictionary {
  [workspaceId: string]: IWorkspace;
}

export class MainViewState {
  app_version: string = "1.0.0";
  currentTime: number = 0;
  selectedWorkspaceKey: string = "Default";
  unsavedWorkspaceIds: string[] = [];
  widgetsTotalWidth: number = 0;
  workspaces: { [key: string]: IWorkspace };
  isSaveLayout = false;
  sidebarCollapse: boolean = false;
  searchParam: any = "";
  searchString: any = "";
  isWorkspaceLoaded: boolean = false;

  constructor(workspaces?: { [key: string]: IWorkspace }) {
    this.workspaces = workspaces || {
      Default: {
        id: "Default",
        counter: 0,
        title: "Default",
        widgets: MainViewState.defaultLayout,
      },
    };
  }

  static get defaultLayout() {
    const widgets = [
      {
        widgetId: "level-two-view",
        positionX: 0,
        positionY: 0,
        width: 627,
        height: 440,
      },
      {
        widgetId: "time-and-sales",
        positionX: 626,
        positionY: 0,
        width: 316,
        height: 440,
      },
      {
        widgetId: "chart",
        positionX: 941,
        positionY: 0,
        width: 660,
        height: 440,
      },
      {
        widgetId: "order-blotter",
        positionX: 0,
        positionY: 439,
        width: 472,
        height: 257,
      },
      {
        widgetId: "position-blotter",
        positionX: 471,
        positionY: 439,
        width: 471,
        height: 257,
      },
      {
        widgetId: "watch-list",
        positionX: 941,
        positionY: 439,
        width: 660,
        height: 257,
        watchListType: WatchListTypeEnum.Trader2B,
      },
    ] as any[];

    widgets.forEach((widget, idx) => {
      widgets[idx] = {
        key: uuid(),
        ...widgetsConfig[widget.widgetId],
        ...widget,
        isLocked: false,
        isMaximized: false,
        link: "",
        symbol: "",
      };
    });

    return widgets;
  }

  static get usersWorkspace() {
    const widgets = [
      {
        widgetId: "users",
        positionX: 0,
        positionY: 0,
        width: 627,
        height: 440,
      }
    ] as any[];

    widgets.forEach((widget, idx) => {
      widgets[idx] = {
        key: uuid(),
        ...widgetsConfig[widget.widgetId],
        ...widget,
        isLocked: false,
        isMaximized: true,
        link: "",
        symbol: "",
      };
    });

    return widgets;
  }
  static get accountsWorkspace() {
    const widgets = [
      {
        widgetId: "accounts",
        positionX: 0,
        positionY: 0,
        width: 627,
        height: 440,
      }
    ] as any[];

    widgets.forEach((widget, idx) => {
      widgets[idx] = {
        key: uuid(),
        ...widgetsConfig[widget.widgetId],
        ...widget,
        isLocked: false,
        isMaximized: true,
        link: "",
        symbol: "",
      };
    });

    return widgets;
  }
  static get positionsWorkspace() {
    const widgets = [
      {
        widgetId: "position-blotter",
        positionX: 0,
        positionY: 0,
        width: 627,
        height: 440,
      }
    ] as any[];

    widgets.forEach((widget, idx) => {
      widgets[idx] = {
        key: uuid(),
        ...widgetsConfig[widget.widgetId],
        ...widget,
        isLocked: false,
        isMaximized: true,
        link: "",
        symbol: "",
      };
    });

    return widgets;
  }
  static get ordersWorkspace() {
    const widgets = [
      {
        widgetId: "order-blotter",
        positionX: 0,
        positionY: 0,
        width: 627,
        height: 440,
      }
    ] as any[];

    widgets.forEach((widget, idx) => {
      widgets[idx] = {
        key: uuid(),
        ...widgetsConfig[widget.widgetId],
        ...widget,
        isLocked: false,
        isMaximized: true,
        link: "",
        symbol: "",
      };
    });

    return widgets;
  }
  static get accountPerformanceWorkspace() {
    const widgets = [
      {
        widgetId: "account-performance",
        positionX: 0,
        positionY: 0,
        width: 627,
        height: 440,
      }
    ] as any[];

    widgets.forEach((widget, idx) => {
      widgets[idx] = {
        key: uuid(),
        ...widgetsConfig[widget.widgetId],
        ...widget,
        isLocked: false,
        isMaximized: true,
        link: "",
        symbol: "",
      };
    });

    return widgets;
  }
}

export interface IWorkspace {
  id: string;
  counter: number;
  title: string;
  widgets: Widget[];
}

export class Widget {
  constructor(
    public title: string,
    public width: number,
    public height: number,
    public maxHeight: any,
    public maxWidth: any,
    public dropdownValues: any,
    public positionX: number,
    public positionY: number,
    public icon: any,
    public key: string
  ) {
    this.positionX = positionX || 0;
    this.positionY = positionY || 0;
    this.key = key || uuid();
  }

  canBeMaximized?: boolean;
  // key: string;
  isLinkingEnabled = false;
  isLocked = false;
  isMaximized = false;
  link = "";
  symbol = "";
  watchListType = WatchListTypeEnum.None;
}
