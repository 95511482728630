import uuid from "react-uuid";
import store from "..";
import { IAccountOrder } from "../../models/IAccountOrder";
import { IOrderExecution } from "../../models/IOrderExecution";
import { IUser } from "../../models/IUser";
import { IUserAd } from "../../models/IUserAd";
import apiSvc from "../../services/api-service";
import notificationSvc from "../../services/notification-service";
import { fixDateTimeOffset } from "../../utils/datetime";
import {
  addAssignedUserAccount,
  setAccountOrders,
  setOrderExecutions,
  updateAssignedUserAccount,
} from "../account/account-actions";
import { subscribeToAccountMessages } from "../connection/connection-async-actions";
import { setDestinations } from "../destination/destination-actions";
import { IAppState } from "../reducers/IAppState";
import { setUserAccounts } from "../user-accounts/user-account-actions";
import { setUserAds, setUsers } from "./users-actions";

let allDestinations;
let allUserAccounts;

const getAllDestinations = async () => {
  const response = await apiSvc.get("SelectAccountDestinationsRequest");
  allDestinations = await response?.data.Data;
  store.dispatch(setDestinations(allDestinations));
};

export const getAllUserAccounts = async () => {
  const response = await apiSvc.get("SelectUserAccountsRequest");
  allUserAccounts = await response?.data.Data;
  store.dispatch(setUserAccounts(allUserAccounts));
  return allUserAccounts;
};

export const addUserAccount = (accountId: number) => (dispatch: any) => {
  apiSvc.get(
    `SelectAccountsRequest?SelectColumnName=AccountId&SelectKey=${accountId}`,
    (response) => {
      const account = response.data.Data[0];

      if (account) {
        dispatch(addAssignedUserAccount(response.data.Data[0]));
        subscribeToAccountMessages(response.data.Data[0]);
      }

      return response;
    }
  );
};

export const fetchUserAds = (userId: number) => (dispatch: any) => {
  const url = `SelectUserAdRequest?SelectColumnName=UserId&SelectKey=${userId}`;
  apiSvc.api.get(url).then((response) => {
    const validAds = response.data.Data.map((x: IUserAd) => ({
      ...x,
      Expiry: new Date(x.Expiry),
    })).filter((x: IUserAd) => x.Expiry.getTime() > new Date().getTime());

    dispatch(setUserAds(validAds));
  });
};

export async function loadUsers(dispatch: any) {
  // dispatch(setUsers([]));
  return apiSvc.get("SelectUsersRequest", (res) => dispatch(setUsers(res.data.Data)));
}

export const updateUserAccount = (accountId: number) => (dispatch: any) => {
  apiSvc.get(
    `SelectAccountsRequest?SelectColumnName=AccountId&SelectKey=${accountId}`,
    (response) => dispatch(updateAssignedUserAccount(response.data.Data))
  );
};

export function setExecutions(executions: IOrderExecution[]) {
  return (dispatch: any, getState: () => IAppState) => {
    const { destinationsLookup: destinationsList } = getState().account;

    const destinations: { [key: number]: string } = {};
    destinationsList?.forEach((x) => (destinations[x.DestinationId] = x.DisplayName));

    const userExecutions: IOrderExecution[] = executions
      .map(execution => {
        return {
          ...execution,
          //AccountName: execution.Account?.Name,
          ClientOrderId: BigInt(execution.ClOrdId).toString(),
          DateTime: fixDateTimeOffset(execution.ExecTime),
          Destination: destinations[execution.DestId],
          RowId: uuid()
        } as IOrderExecution;
      });

    dispatch(setOrderExecutions(userExecutions));
  }
}

export function setOrders(orders: IAccountOrder[]) {
  return async (dispatch: any, getState: () => IAppState) => {
    const { authInfo } = getState().auth;

    if (!authInfo) {
      return;
    }

    const { UserId, UserName } = authInfo;
    const userId = parseInt(UserId);
    if (!allDestinations || !allDestinations.length) {
      await getAllDestinations();
    }
    if (!allUserAccounts || !allUserAccounts.length) {
      await getAllUserAccounts();
    }
    const destinationsList: any =
      allDestinations || store.getState().account.destinationsLookup;

    const destinations: { [key: number]: string } = {};
    destinationsList?.forEach(
      (x) => (destinations[x.AccountId] = x.DisplayName)
    );

    const userOrders: IAccountOrder[] = orders
      .map((order: IAccountOrder) => {
        return {
          ...order,
          SentUserName:
            order.Source === "AutoFlatter"
              ? "Auto Flatter"
              : userId === order.SentUserId
              ? UserName
              : allUserAccounts.find((x) => x.UserId === order.SentUserId)?.User
                  .UserName,
          CancelledUserName:
            order.Status === "Cancelled"
              ? userId === order.CancelledUserId
                ? UserName
                : allUserAccounts.find(
                    (x) => x.UserId === order.CancelledUserId
                  )?.User.UserName
              : "",
          ClientOrderId: BigInt(order.ClientOrderId).toString(),
          Destination: destinations[order.AccountId],
          DateTime: fixDateTimeOffset(order.DateTime),
        } as IAccountOrder;
      })
      .reverse();

    dispatch(setAccountOrders(userOrders));
  };
}

export const postUser =
  (user: IUser) => async (dispatch: any, getState: () => IAppState) => {
    const userData = {
      Data: user,
      LoadCache: true,
      PushToCrm: false,
    };
    const { data } = getState().users;
    let isSaveUser = data.some(
      (i) => i.UserName.toLowerCase() === user.UserName.toLowerCase()
    );

    if (isSaveUser === false) {
      apiSvc.api
        .put("SaveUsersRequest", userData)
        .then(() => dispatch(loadUsers));
    } else {
      notificationSvc.error("Duplicate User Not Allowed!");
      dispatch(loadUsers);
    }
  };

export const deleteUser = (userKey: number) => (dispatch: any) => {
  apiSvc.delete("DeleteUsersRequest", userKey, () => dispatch(loadUsers));
};
