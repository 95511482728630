import React, { useCallback, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, FilterChangedEvent, GetRowNodeIdFunc, GridApi, GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LeftArrow from "../../../Images/leftarrow.svg";
import RightArrow from "../../../Images/rightarrow.svg";
import "./style.scss";
import CustomSpinner from '../spinner/spinner';
import { KPIData } from './aggrid-list';
import StatCard from '../../kpi-card/stat-card';
import { ITableProps } from '../../list/ITableProps';

interface AGGridServerComponentProps {
  columnDefs: ColDef[];
  rowData: any[];
  onFilterChanged: (event: FilterChangedEvent) => void;
  gridApi: GridApi | null;
  paginationPageSize: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  setPageSize: (page: number) => void;
  onGridReady: (event: GridReadyEvent) => void;
  totalResults: number;
  isLoadingGridData: boolean;
  showKPIData?: boolean;
  kpiData?: KPIData[];
  rowIdSelector?: GetRowNodeIdFunc | undefined
}

const pageSizes = [50, 100, 500];


const AGGridServerComponent: React.FC<AGGridServerComponentProps & ITableProps> = ({
  columnDefs,
  rowData,
  onFilterChanged,
  gridApi,
  onGridReady,
  paginationPageSize,
  currentPage,
  totalPages,
  onPageChange,
  setPageSize,
  totalResults,
  isLoadingGridData,
  kpiData,
  rowIdSelector
}) => {


  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
      suppressAutoSize: true,
      suppressSizeToFit: true,
      unSortIcon: true,
    };
  }, []);

  const onBtNext = useCallback(() => {
    gridApi?.paginationGoToNextPage();
    onPageChange(currentPage + 1);
  }, [currentPage]);

  const onBtPrevious = useCallback(() => {
    gridApi?.paginationGoToPreviousPage();
    onPageChange(currentPage - 1);
  }, [currentPage]);

  const goToPage = (pageNumber: number) => {
    if (pageNumber !== currentPage) {
      gridApi?.paginationGoToPage(pageNumber);
      onPageChange(pageNumber);
    }
  };

  const renderPageSizeOptions = () => {
    return pageSizes.map((resultPerPage, index) => (
      <option key={index} value={resultPerPage}>{resultPerPage}</option>
    ));
  };

  const visiblePageRange = () => {
    const numberOfPages = totalPages;
    const PageWindow = Array.from({ length: numberOfPages }).slice(0, 5).map((page, index) => {
      return (
        <>
          {
            (currentPage) > numberOfPages
              ?
              <React.Fragment></React.Fragment>
              :
              <button className={`btn-page-name ${index === 0 ? "active" : ""}`} onClick={() => { goToPage(currentPage + index) }}>{currentPage + index}</button>
          }
        </>
      );
    });

    return (
      <>
        {
          currentPage === 1
            ?
            <></>
            :
            <button className="btn-page-name px-1" onClick={onBtPrevious} disabled={currentPage === 1}>
              <img src={LeftArrow} alt="" />
            </button>
        }
        {
          PageWindow
        }
        {currentPage > numberOfPages - 1
          ?
          <></>
          :
          <button className="btn-page-name px-1" onClick={onBtNext} disabled={currentPage >= numberOfPages - 1}>
            <img src={RightArrow} alt="" />
          </button>
        }
      </>
    )
  };

  useEffect(() => {
    if (gridApi && currentPage && totalPages) {
      gridApi.paginationGoToPage(currentPage - 1);
    }
  }, [currentPage, gridApi, totalPages]);

  return (
    <>
      {
        isLoadingGridData
          ?
          <CustomSpinner />
          :
          <>
          <div className="row mx-0 info-table-top flex-nowrap">
            {kpiData && kpiData.map((data, index) => {
              return (
                <StatCard key={index} data={data.data} title={data.title} />
              )
            })}
          </div>
          </>
      }
      <div className={`ag-theme-alpine ${isLoadingGridData ? "d-none" : ""}`} style={{ height: 600, width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          onFilterChanged={onFilterChanged}
          pagination={true}
          paginationPageSize={paginationPageSize}
          suppressPaginationPanel={true}
          onGridReady={onGridReady}
          getRowNodeId={rowIdSelector}
        />
        <div className="pagination-header col-12 pl-0 pr-5 d-flex justify-content-between align-items-center">
          <div className="col-auto px-0">
            <span>Show 1 - </span>
            <select onChange={(e) => { setPageSize(Number(e.target.value)) }}>
              {renderPageSizeOptions()}
            </select>
            <span>of {totalResults} Items</span>
          </div>
          <div className="col-auto px-0">
            {visiblePageRange()}
          </div>
        </div>
      </div>
    </>
  );
};

export default AGGridServerComponent;
