import uuid from "react-uuid";
import { TradeTypeEnum } from "../../enums/TradeTypeEnum";
import { ILevelIIStats } from "../../models/ILevelIIStats";
import { IMarketDepth } from "../../models/IMarketDepth";
import { IMarketDepthRawData } from "../../models/IMarketDepthRawData";
import { IQuoteMediaMMQuote } from "../../models/IQuoteMediaMMQuote";
import { IQuoteMediaPrice } from "../../models/IQuoteMediaPrice";
import { IQuoteMediaQuote } from "../../models/IQuoteMediaQuote";
import { IQuoteMediaTrade } from "../../models/IQuoteMediaTrade";
import { IWatchList } from "../../models/IWatchList";
import tempCache from "../../services/temp-cache";
import {
  ACCOUNT_DROPDOWN,
  ORD_STATUS_DROPDOWN,
  SYMBOL_DROPDOWN,
  TIME_SALES_SEC,
  WATCH_LIST
} from "../actions/types";
import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import { SETTINGS_LEVEL_II_SKIN_COLOR } from "../settings/settings-types";
import {
  QUOTE_MEDIA_MM_QUOTE,
  QUOTE_MEDIA_PRICE,
  QUOTE_MEDIA_QUOTE,
  QUOTE_MEDIA_SET_AUTH_STATUS, QUOTE_MEDIA_SET_CONNECTION_STATUS, QUOTE_MEDIA_SET_SID,
  QUOTE_MEDIA_SET_SYMBOL_LONG_NAME,
  QUOTE_MEDIA_TRADE,
  QUOTE_MEDIA_SET_HEADLINES,
  QUOTE_MEDIA_COPY_QM_STATE,
} from "./quote-media-types";
import { QuoteMediaState } from "./QuoteMediaState";

const initialState = new QuoteMediaState();

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any): QuoteMediaState {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new QuoteMediaState();

    case QUOTE_MEDIA_MM_QUOTE:
      const qmMmQuotes: IQuoteMediaMMQuote[] = action.payload;

      return {
        ...state,
        levelIIStats: { ...processLevelIIStats({ ...state.levelIIStats }, qmMmQuotes) },
        mmQuoteData: mergeMmQuotes({ ...state.mmQuoteData }, qmMmQuotes),
        marketDepths: [...processMarketDepthsFromMmQuotes(state.marketDepths, qmMmQuotes, action.decimalPlaces, action.skinColor)],
      };

    case QUOTE_MEDIA_PRICE:
      const qmPrices: IQuoteMediaPrice[] = action.payload;
      const watchListFromPrice = updateWatchListFromPrice([...state.watchList], state.symbolLongNames, qmPrices);
      setPriceRowClass(qmPrices, action.decimalPlaces, state);
      return {
        ...state,
        watchList: watchListFromPrice,
        priceData: mergePrices({ ...state.priceData }, qmPrices),
      };

    case QUOTE_MEDIA_QUOTE:
      const qmQuotes: IQuoteMediaQuote[] = action.payload;
      const watchListFromQuote = updateWatchListFromQuote([...state.watchList], state.symbolLongNames, qmQuotes);

      return {
        ...state,
        levelIIStats: { ...processLevelIIStats({ ...state.levelIIStats }, qmQuotes) },
        quoteData: mergeQuotes({ ...state.quoteData }, qmQuotes),
        marketDepths: [...processMarketDepthsFromQuotes(state.marketDepths, qmQuotes, action.decimalPlaces, action.skinColor)],
        watchList: watchListFromQuote,
      };

    case QUOTE_MEDIA_SET_AUTH_STATUS:
      return {
        ...state,
        authStatus: action.payload,
      };

    case QUOTE_MEDIA_SET_CONNECTION_STATUS:
      return {
        ...state,
        connectionStatus: action.payload,
      };

    case QUOTE_MEDIA_SET_SID:
      tempCache.sid = action.payload;

      return {
        ...state,
        sid: action.payload,
        sidGeneratedAt: new Date()
      };

    case QUOTE_MEDIA_SET_SYMBOL_LONG_NAME:
      const symbolLongNames = {
        ...state.symbolLongNames,
        [action.payload.symbol]: action.payload.longName
      };

      return {
        ...state,
        symbolLongNames,
        watchList: updateWatchListFromLongName([...state.watchList], action.payload)
      };

    case QUOTE_MEDIA_TRADE:
      const tradesData = action.payload as IQuoteMediaTrade[];

      setTradeRowClass(tradesData, action.decimalPlaces, state);

      const tradeData = tradesData[tradesData.length - 1];
      const tradesList = addTradesToList(state, tradesData);

      return {
        ...state,
        tradeData: {
          ...state.tradeData,
          [tradeData.symbol]: tradeData,
        },
        tradesList: {
          ...tradesList,
        },
      };

    case SETTINGS_LEVEL_II_SKIN_COLOR:
      return {
        ...state,
        marketDepths: applyMarketDepthBackground(state.marketDepths, action.decimalPlaces, action.payload)
      };

    case TIME_SALES_SEC:
      return {
        ...state,
        timeSalesSymbolData: {
          ...state.timeSalesSymbolData,
          [action.symbol]: {
            windowId: [action.windowId],
            symbol: action.symbol,
          },
        },
      };

    case WATCH_LIST:
      return {
        ...state,
        watchListData: [...state.watchListData, action.payload],
      };

    case ORD_STATUS_DROPDOWN:
      return {
        ...state,
        searchFilter: action.payload,
      };

    case SYMBOL_DROPDOWN:
      return {
        ...state,
        symbolFilterDd: action.payload,
      };

    case ACCOUNT_DROPDOWN:
      return {
        ...state,
        accountFilterDd: action.payload,
      };
      
    case QUOTE_MEDIA_SET_HEADLINES:
      return {
        ...state,
        headlines: action.payload
      }; 

    case QUOTE_MEDIA_COPY_QM_STATE:
      return action.payload;

    default:
      return state;
  }
}

export const initializeQuoteMediaState = () => {
  initialState.sid = tempCache.sid;
}

function addTradesToList(state: QuoteMediaState, tradesData: IQuoteMediaTrade[]) {
  let tradesList = { ...state.tradesList };

  tradesData.forEach((tradeData) => {
    if (!(tradeData.symbol in tradesList)) {
      tradesList[tradeData.symbol] = [];
    }

    let trades = [...tradesList[tradeData.symbol]];

    const firstRow = trades[0];
    if (
      !firstRow ||
      firstRow.excode !== tradeData.excode ||
      firstRow.size !== tradeData.size ||
      firstRow.price !== tradeData.price
    ) {
      trades = [tradeData, ...trades];
      trades = trades.slice(0, 50);
    }

    tradesList = {
      ...tradesList,
      [tradeData.symbol]: [...trades],
    };
  });

  return tradesList;
}

function applyMarketDepthBackground(marketDepths: IMarketDepth[], decimalPlaces: number, skinColor: string) {
  marketDepths = [...marketDepths];

  marketDepths.forEach(x => ({
    ...x,
    skinColor
  }));

  const asks = marketDepths
    .filter((x) => x.tradeType === TradeTypeEnum.Ask)
    .sort((a, b) => {
      if (a.price > b.price) {
        return 1;
      } else if (a.price < b.price) {
        return -1;
      }

      const aTime = new Date(a.date).getTime();
      const bTime = new Date(b.date).getTime();
      if (aTime < bTime) {
        return 1;
      } else if (aTime > bTime) {
        return -1;
      } else {
        return 0
      }
    });
  const bids = marketDepths
    .filter((x) => x.tradeType === TradeTypeEnum.Bid)
    .sort((a, b) => {
      if (a.price < b.price) {
        return 1;
      } else if (a.price > b.price) {
        return -1;
      }

      const aTime = new Date(a.date).getTime();
      const bTime = new Date(b.date).getTime();
      if (aTime < bTime) {
        return 1;
      } else if (aTime > bTime) {
        return -1;
      } else {
        return 0
      }
    });

  _applyBackground(asks);
  _applyBackground(bids);

  return [...asks, ...bids];

  function _applyBackground(depths: IMarketDepth[]) {
    const arrangedMarketDepths: { [symbol: string]: IMarketDepth[] } = {};

    depths.forEach((depth) =>
      (arrangedMarketDepths[depth.symbol] = arrangedMarketDepths[depth.symbol] || []).push(depth)
    );

    Object.keys(arrangedMarketDepths).forEach((symbol) => {
      const symbolMarketDepths = arrangedMarketDepths[symbol];
      let price = 0;
      let idx = 1;
      let rowClass = "";

      symbolMarketDepths.forEach((x) => {
        if (price.toFixed(decimalPlaces) !== x.price.toFixed(decimalPlaces)) {
          rowClass = `level-ii color-${idx}`;

          if (++idx === 6) {
            idx = 1;
          }

          price = x.price;
        }

        x.rowClass = rowClass;
      });
    });
  }
}

function mergeMmQuotes(quotesData: { [key: string]: IQuoteMediaMMQuote }, qmQuotes: IQuoteMediaMMQuote[]) {
  qmQuotes.forEach((qmQuote) => {
    quotesData = {
      ...quotesData,
      [qmQuote.symbol]: qmQuote,
    };
  });

  return quotesData;
}

function mergePrices(priceData: { [key: string]: IQuoteMediaPrice }, qmPrices: IQuoteMediaPrice[]) {
  qmPrices.forEach((qmPrice) => {
    priceData = {
      ...priceData,
      [qmPrice.symbol]: qmPrice,
    };
  });

  return priceData;
}

function mergeQuotes(quotesData: { [key: string]: IQuoteMediaQuote }, qmQuotes: IQuoteMediaQuote[]) {
  qmQuotes.forEach((qmQuote) => {
    quotesData = {
      ...quotesData,
      [qmQuote.symbol]: qmQuote,
    };
  });

  return quotesData;
}

function processLevelIIStats(
  levelIIStats: { [symbol: string]: ILevelIIStats },
  quotes: IQuoteMediaMMQuote[] | IQuoteMediaQuote[]
): { [symbol: string]: ILevelIIStats } {
  quotes.forEach((quote: IQuoteMediaMMQuote | IQuoteMediaQuote) =>
    levelIIStats = {
      ...levelIIStats,
      [quote.symbol]: {
        askPrice: quote.askPrice,
        askSize: quote.askSize,
        bidPrice: quote.bidPrice,
        bidSize: quote.bidSize,
      }
    });

  return levelIIStats;
}

function processMarketDepths(
  marketDepths: IMarketDepth[],
  rawData: IMarketDepthRawData[],
  decimalPlaces: number,
  skinColor: string
): IMarketDepth[] {
  marketDepths = [...marketDepths];

  rawData.forEach((quote) => {
    if (quote.askPrice) {
      marketDepths
        .filter((x) => x.symbol === quote.symbol && x.tradeType === TradeTypeEnum.Ask && x.price < quote.askPrice)
        .forEach((ask) => marketDepths.splice(marketDepths.indexOf(ask), 1));

      const askIdx = marketDepths.findIndex(
        (x) => x.market === quote.askMarket && x.symbol === quote.symbol && x.tradeType === TradeTypeEnum.Ask
      );

      if (askIdx === -1) {
        marketDepths.push({
          id: uuid(),
          market: quote.askMarket,
          symbol: quote.symbol,
          price: quote.askPrice,
          size: quote.askSize * 100,
          date: new Date(quote.timestamp),
          tradeType: TradeTypeEnum.Ask,
          isBest: false,
          isHitJoinStage: false,
          sortOrderId: 0,
        });
      } else {
        const ask = { ...marketDepths[askIdx] };
        ask.price = quote.askPrice;
        ask.size = quote.askSize * 100;
        ask.date = new Date(quote.timestamp);
        marketDepths[askIdx] = ask;
      }
    }

    if (quote.bidPrice) {
      marketDepths
        .filter((x) => x.symbol === quote.symbol && x.tradeType === TradeTypeEnum.Bid && x.price > quote.bidPrice)
        .forEach((bid) => marketDepths.splice(marketDepths.indexOf(bid), 1));

      const bidIdx = marketDepths.findIndex(
        (x) => x.market === quote.bidMarket && x.symbol === quote.symbol && x.tradeType === TradeTypeEnum.Bid
      );

      if (bidIdx === -1) {
        marketDepths.push({
          id: uuid(),
          market: quote.bidMarket,
          symbol: quote.symbol,
          price: quote.bidPrice,
          size: quote.bidSize * 100,
          date: new Date(quote.timestamp),
          tradeType: TradeTypeEnum.Bid,
          isBest: false,
          isHitJoinStage: false,
          sortOrderId: 0,
        });
      } else {
        const bid = { ...marketDepths[bidIdx] };
        bid.price = quote.bidPrice;
        bid.size = quote.bidSize * 100;
        bid.date = new Date(quote.timestamp);
        marketDepths[bidIdx] = bid;
      }
    }
  });

  return applyMarketDepthBackground(marketDepths, decimalPlaces, skinColor);
}

function processMarketDepthsFromQuotes(
  marketDepths: IMarketDepth[],
  quotes: IQuoteMediaQuote[],
  decimalPlaces: number,
  skinColor: string,
): IMarketDepth[] {
  const rawData: IMarketDepthRawData[] = quotes.map(x => ({
    ...x,
    askMarket: x.askExcode,
    bidMarket: x.bidExcode
  }));

  return processMarketDepths(marketDepths, rawData, decimalPlaces, skinColor);
}

function processMarketDepthsFromMmQuotes(
  marketDepths: IMarketDepth[],
  quotes: IQuoteMediaMMQuote[],
  decimalPlaces: number,
  skinColor: string
): IMarketDepth[] {
  const rawData: IMarketDepthRawData[] = quotes.map(x => ({
    ...x,
    askMarket: x.marketMakerID,
    bidMarket: x.marketMakerID
  }));

  return processMarketDepths(marketDepths, rawData, decimalPlaces, skinColor);
}

function setPriceRowClass(tradesData: IQuoteMediaPrice[], decimalPlaces: number, state: QuoteMediaState) {
  const arrangedPriceData: { [symbol: string]: IQuoteMediaPrice[] } = {};

  tradesData.forEach((priceData) => {
    arrangedPriceData[priceData.symbol] = arrangedPriceData[priceData.symbol] || [];
    arrangedPriceData[priceData.symbol].push(priceData);
  });

  Object.keys(arrangedPriceData).forEach((symbol) => {
    const symbolPriceData = arrangedPriceData[symbol];

    if (symbolPriceData.length > 1) {
      for (let idx = 0; idx < symbolPriceData.length - 1; idx++) {
        const a = symbolPriceData[idx];
        const b = symbolPriceData[idx + 1];

        const aPrice = (a.last || 0).toFixed(decimalPlaces);
        const bPrice = (b.last || 0).toFixed(decimalPlaces);

        b.rowClass = aPrice === bPrice ? "price-equal" : bPrice > aPrice ? "price-positive" : "price-negative";
      }
    }

    const firstPriceData = symbolPriceData[0];
    if (state.tradeData[symbol]) {
      const aPrice = state.tradeData[symbol].price.toFixed(decimalPlaces);
      const bPrice = (firstPriceData.last || 0).toFixed(decimalPlaces);

      if (aPrice === bPrice) {
        firstPriceData.rowClass = "price-equal";
      } else if (bPrice > aPrice) {
        firstPriceData.rowClass = "price-positive";
      } else {
        firstPriceData.rowClass = "price-negative";
      }
    }
  });
}

function setTradeRowClass(tradesData: IQuoteMediaTrade[], decimalPlaces: number, state: QuoteMediaState) {
  const arrangedTradesData: { [symbol: string]: IQuoteMediaTrade[] } = {};

  tradesData.forEach((tradeData) => {
    arrangedTradesData[tradeData.symbol] = arrangedTradesData[tradeData.symbol] || [];
    arrangedTradesData[tradeData.symbol].push(tradeData);
  });

  Object.keys(arrangedTradesData).forEach((symbol) => {
    const symbolTradesData = arrangedTradesData[symbol];

    if (symbolTradesData.length > 1) {
      for (let idx = 0; idx < symbolTradesData.length - 1; idx++) {
        const a = symbolTradesData[idx];
        const b = symbolTradesData[idx + 1];

        const aPrice = (a.price || 0).toFixed(decimalPlaces);
        const bPrice = (b.price || 0).toFixed(decimalPlaces);

        b.rowClass = aPrice === bPrice ? "price-equal" : bPrice > aPrice ? "price-positive" : "price-negative";
      }
    }

    const firstTradeData = symbolTradesData[0];
    if (state.tradeData[symbol]) {
      const aPrice = state.tradeData[symbol].price.toFixed(decimalPlaces);
      const bPrice = (firstTradeData.price || 0).toFixed(decimalPlaces);

      if (aPrice === bPrice) {
        firstTradeData.rowClass = "price-equal";
      } else if (bPrice > aPrice) {
        firstTradeData.rowClass = "price-positive";
      } else {
        firstTradeData.rowClass = "price-negative";
      }
    }
  });
}

function updateWatchListFromLongName(watchList: IWatchList[], payload: { symbol: string, longName: string }) {
  const itemIdx = watchList.findIndex(x => x.symbol === payload.symbol);
  const item = {
    ...(
      itemIdx !== -1
        ? watchList[itemIdx]
        : {
          symbol: payload.symbol,
          type: "symbol"
        } as IWatchList
    ),
    longName: payload.longName
  };

  if (itemIdx !== -1) {
    watchList[itemIdx] = item;
  } else {
    watchList.push(item);
  }

  return watchList;
}

function updateWatchListFromPrice(watchList: IWatchList[], longNames: { [symbol: string]: string }, prices: IQuoteMediaPrice[]) {
  const price = prices[prices.length - 1];
  let item: IWatchList | undefined = watchList.find((x) => x.symbol === price.symbol);
  if (!item) {
    item = {
      symbol: price.symbol,
      type: "symbol"
    } as IWatchList;
  }

  item = {
    ...item,
    accumulatedVolume: price.accumulatedVolume,
    change: price.change,
    close: price.close,
    high: price.high,
    last: price.last,
    lastTradeSize: price.lastTradeSize,
    lastTradeTime: new Date(price.lastTradeTime),
    low: price.low,
    open: price.open,
    percentChange: price.percentChange,
    vwap: price.vwap,
  };

  const idx = watchList.findIndex((x) => x.symbol === price.symbol);
  if (idx !== -1) {
    watchList.splice(idx, 1, item as IWatchList);
  } else {
    watchList.push(item as IWatchList);
  }

  watchList = watchList.map(x => ({ ...x, longName: longNames[x.symbol] }));

  return watchList;
}

function updateWatchListFromQuote(watchList: IWatchList[], longNames: { [symbol: string]: string }, quotes: IQuoteMediaQuote[]) {
  quotes.forEach((quote) => {
    let item: IWatchList | undefined = watchList.find((x) => x.symbol === quote.symbol);

    if (!item) {
      item = {
        symbol: quote.symbol,
        type: "symbol"
      } as IWatchList;
    }

    item = {
      ...item,
      askPrice: quote.askPrice,
      askSize: quote.askSize,
      bidPrice: quote.bidPrice,
      bidSize: quote.bidSize,
    };

    const idx = watchList.findIndex((x) => x.symbol === quote.symbol);
    if (idx !== -1) {
      watchList.splice(idx, 1, item);
    } else {
      watchList.push(item);
    }
  });

  watchList = watchList.map(x => ({ ...x, longName: longNames[x.symbol] }));

  return watchList;
}
