import { USER_ACCOUNT_COPY_USER_ACCOUNTS, USER_ACCOUNT_SET_SELECTED_USER_ACCOUNT, USER_ACCOUNT_SET_USER_ACCOUNTS } from "./user-account-types";

export const setUserAccounts = (userAccount: any[]) => (dispatch: any, getState: any) => {
  const { adminAccounts } = getState();
  const destinationData = userAccount?.map((account) => ({
    ...account,
    AccountDestination: adminAccounts.accounts.find((x) => x.AccountId === account.AccountId)?.AccountDestination,
  }));
  dispatch({ type: USER_ACCOUNT_SET_USER_ACCOUNTS, payload: destinationData });
};

export const setSelectedUserAccount = (accountData: any) => (dispatch: any) =>
  dispatch({ type: USER_ACCOUNT_SET_SELECTED_USER_ACCOUNT, payload: accountData });

export const copyUserAccounts = (data) => (dispatch: any) =>
  dispatch({ type: USER_ACCOUNT_COPY_USER_ACCOUNTS, payload: data });
