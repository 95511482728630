import { useEffect, useRef, useState } from "react";
import AggridList from "../ag-grid-list/aggrid-list";
import {
  GridOptions,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CustomSpinner from "../spinner/spinner";
import store from "../../../store";
import { showEditForm } from "../../../store/users/users-actions";
import { ContextMenuOption } from "../../context-menu/context-menu-types";
import editIcon from "../../../Images/editicon.svg";
import apiSvc from "../../../services/api-service";
import { IAppState } from "../../../store/reducers/IAppState";
import { auditColumnsDefinition } from "./AuditColumnDefinitions";


type Props = {
  windowId: string;
};

let userNameList = {};

const AuditTrail = ({ windowId }: Props) => {
  const gridApiRef = useRef<any>(null);

  const gridOptions: GridOptions = {
    columnDefs: auditColumnsDefinition,
  };

  const auditContextMenuOptions: ContextMenuOption[] = [
    {
      handler: () => {},
      name: "View Record",
      optionIcon: editIcon,
    },
  ];

  const [gridData, setGridData] = useState<any[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [currentRow, setCurrentRow] = useState<any>();
  const [isGridReady, setIsGridReady] = useState<boolean>(false);

  const users = useSelector((state: IAppState) => state.users);

  const handleRowDoubleClick = (event: RowDoubleClickedEvent) => {
    store.dispatch(
      showEditForm({
        status: true,
        data: {
          fieldName: `Edit User ${event.data.Email}`,
          fieldData: event.data.Id,
        },
      })
    );
  };

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
    setIsGridReady(true);
  };

  const getUserName = (userId: number) => {
    let userName: string = userNameList[userId]
    if(userName)
    {
      return userName;
    }
    else {
      userName = users.data.find((usr) => usr.Id === userId)?.UserName || "";
      userNameList = {...userNameList, [userId]: userName};
    }
  };

  const loadAuditLogs = async () => {
    try {
      setIsLoadingData(true);
      const response = await apiSvc.get(`SelectAuditTrailRequest`);
        if(response?.data && response.data.Data)
        {
          const formattedAuditLogs = response.data.Data.map((w) => {
            return {
              Id: w.Id,
              Table: w.TableName,
              Field: w.FieldName,
              OldValue: w.OldValue,
              NewValue: w.NewValue,
              ModifiedBy: getUserName(w.ModifiedBy),
              ModifiedOn: moment(w.DateTime).format("YYYY-MM-DD HH:mm:ss")
            }
          });
          setGridData(formattedAuditLogs);
        }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    if(users.data.length && !gridData.length)
    {
      loadAuditLogs();
    }
  }, [JSON.stringify(users)]);

  return (
    <>
      {isLoadingData ? (
        <CustomSpinner />
      ) : (
        <AggridList
          gridOptions={gridOptions}
          onRowDoubleClicked={handleRowDoubleClick}
          primaryButtonText={""}
          primaryButtonOnClickHandler={() => {}}
          pagination={true}
          rowData={gridData}
          contextMenuOptions={auditContextMenuOptions}
          setRecordForContextMenu={setCurrentRow}
          gridName={"Audit Logs"}
          onGridReady={onGridReady}
          rowIdSelector={(data) => data.Id}
          kpiData={[]}
          windowId={windowId}
          noKPIData={true}
        />
      )}
    </>
  );
};

export default AuditTrail;
