import uuid from "react-uuid";
import store from "..";
import { NatsMessageTypeEnum } from "../../enums/NatsMessageTypeEnum";
import { OrderSideEnum } from "../../enums/OrderSideEnum";
import { OrderStatusEnum } from "../../enums/OrderStatusEnum";
import { AccountPositionResponse } from "../../models/AccountPositionResponse";
import { CancelOrder } from "../../models/CancelOrder";
import { ClientHeartBeat } from "../../models/ClientHeartBeat";
import { ExecutionReport } from "../../models/ExecutionReport";
import { IAccount } from "../../models/IAccount";
import { NewOrder } from "../../models/NewOrder";
import { SymbolPositionResponse } from "../../models/SymbolPositionResponse";
import { authSvc } from "../../services/auth-service";
import notificationSvc from "../../services/notification-service";
import {
  connect,
  NatsConnectionImpl,
  StringCodec,
  SubscriptionImpl,
} from "../../vendor/nats";
import { addNewOrder, addOrderExecution, setAccountOrders } from "../account/account-actions";
import {
  updateAccountPosition,
  updateSymbolPosition,
} from "../positions/positions-actions";
import { IAppState } from "../reducers/IAppState";
import { setNatsIsConnected } from "./connection-actions";
import { updateOnlineUsersArrayList } from "../users/users-actions";
import moment from "moment";

const currentSessionId = uuid();

const cq = require("concurrent-queue");

const queue = cq()
  .limit({ concurrency: 2 })
  .process((task, callBack) => {
    callBack(task);
  });

let isNatsConnectionInProgress = false;
let natsConnectionPromise: Promise<NatsConnectionImpl>;
let getGlobalState: () => IAppState;
let multipleLoginInterval: any = null;
let subscriptions: SubscriptionImpl[] = [];
let userHeartbeats = {};

export const cancelNatsOrder = (order: CancelOrder) => async () => {
  if (isNatsConnectionInProgress) {
    return;
  }

  order.toString();

  natsConnectionPromise.then((nc) => {
    nc.publish("TRD", order.encode());
  });
};

// const orderStatusInterval = () => {
//   const orderStatusCheckInterval = setInterval(() => {
//     let userOrders = store.getState().account.userOrders.slice();
//     let isUpdated = false;

//     userOrders.forEach((order) => {
//       const orderTimeStamp = moment(order.DateTime);
//       const currentTimeStamp = moment();
//       const difference = currentTimeStamp.diff(orderTimeStamp, 'seconds');;

//       if(["Sending", "Pending"].includes(order.Status)) // For now by pass this if order is stop order
//       {
//         if(["Stop", "TP", "Limit"].includes(order.OrderType))
//         {
//           order.Status = "Open";
//           isUpdated = true;
//         }
//         else {
//           if(difference > 15)
//           {
//             order.Status = "Timeout";
//             isUpdated = true;
//             // notificationSvc.error(`${order.Symbol} with ${order.Qty} timed out`);
//           }
//         }
//       }
//     })

//     if(isUpdated)
//     {
//       store.dispatch(setAccountOrders(userOrders));
//       isUpdated = false;
//     }

//   }, 250);
// };

export const connectNats =
  (userId: string) => async (dispatch: any, getState: () => IAppState) => {
    getGlobalState = getState;

    if (isNatsConnectionInProgress) {
      return;
    }

    isNatsConnectionInProgress = true;

    natsConnectionPromise = connect({
      servers: process.env.REACT_APP_WS_URL,
      noEcho: true,
      token: "s23cr77t",
    });

    natsConnectionPromise
      .then((nc) => {
        isNatsConnectionInProgress = false;

        dispatch(handleMultipleLogins(BigInt(userId)));
        monitorUserStatus();
        dispatch(setNatsIsConnected(true));
        subscribeToAccountPositions(nc);
        subscribeToOrders(nc, 0);
        subscribeToSymPosition(nc, 0);
        dispatch(connectNatsForOrderInfo());
        dispatch(subscribeForSymPosInfo())
        // subscribeToAccountsMessages(accounts);
        // subscribeToUserAds(nc, userId);
        // dispatch(subscribeToConfigUpdates(nc, userId));
        // setInterval(() => publishAd("Another ad", "Urgent", new Date(new Date().getTime() + 10000)), 10000);
      //  orderStatusInterval();
      })
      .catch((err) => {
        dispatch(setNatsIsConnected(false));
        console.error("Not Connected", err);
      });
  };
export const connectNatsForOrderInfo =
  (accountId?) => async (dispatch: any, getState: () => IAppState) => {
    getGlobalState = getState;
    const userId = getState().auth.authInfo?.UserId;
    if (!userId || isNatsConnectionInProgress) {
      return;
    }
    natsConnectionPromise
      .then((nc) => {
        // handleMultipleLogins(nc, dispatch);
        // dispatch(setNatsIsConnected(true));
        subscribeToOrders(nc, accountId);
      })
      .catch((err) => {
        dispatch(setNatsIsConnected(false));
        console.error("Not Connected", err);
      });
  };
export const subscribeForSymPosInfo =
  (accountId?) => async (dispatch: any, getState: () => IAppState) => {
    getGlobalState = getState;
    const userId = getState().auth.authInfo?.UserId;

    if (!userId || isNatsConnectionInProgress) {
      return;
    }
    natsConnectionPromise
      .then((nc) => {
        subscribeToSymPosition(nc, accountId);
      })
      .catch((err) => {
        dispatch(setNatsIsConnected(false));
        console.error("Not Connected", err);
      });
  };

export const disconnectNats = () => {
  console.log("Disconnect Nats");
  subscriptions.forEach((sub) => sub.close());
  subscriptions = [];

  if (multipleLoginInterval) {
    clearInterval(multipleLoginInterval);
  }

  natsConnectionPromise?.then((nc) => {
    if (!nc.isClosed()) {
      nc.close();
    }
  });
};

export const handleMultipleLogins = (userId: bigint) => {
  return (dispatch: any) => {
    const topicId = `$t2badm.{userId}.cnnz`;
    const sc = StringCodec();

    natsConnectionPromise
      ?.then((nc) => {
        if (nc.isClosed()) {
          return;
        }

        nc.publish(
          topicId,
          ClientHeartBeat.create(userId, currentSessionId).encode()
        );
        nc.flush();

        multipleLoginInterval = setInterval(() => {
          if (nc.isClosed()) {
            return;
          }

          nc.publish(
            topicId,
            ClientHeartBeat.create(userId, currentSessionId).encode()
          );
          nc.flush();
        }, 1000);

        (async () => {
          if (nc.isClosed()) {
            return;
          }

          const sub = nc.subscribe(topicId);
          subscriptions.push(sub);

          for await (const m of sub) {
            if (m.data[1] === NatsMessageTypeEnum.ClientHeartBeat) {
              const heartbeat = new ClientHeartBeat(m.data);
              if (heartbeat.loginTime > authSvc.loginTime) {
                // notificationSvc.info("You are logged in from another device.");
                // dispatch(logoutUser());
              }
            } else {
              // support for old heart beat (only session Id)
              const message = sc.decode(m.data);

              if (currentSessionId !== message) {
                // notificationSvc.info("You are logged in from another device.");
                // dispatch(logoutUser());
              }
            }
          }
        })();
      })
      .catch(console.log);
  };
};

export const monitorUserStatus = () => {
  
  const topicId = `*.connections`;
  
  const interval = setInterval(() => {
    const currentStamp = +new Date();
    Object.keys(userHeartbeats).forEach((user) => {
      if(currentStamp - userHeartbeats[user] > 5000)
      {
        store.dispatch(updateOnlineUsersArrayList(Number(user), false));
      }
    });
  }, 1500);

  natsConnectionPromise.then((nc) => {
   
      (async () => {
      const sub = nc.subscribe(topicId);
      subscriptions.push(sub);

      for await (const m of sub) {      
        if (m.data[1] === NatsMessageTypeEnum.ClientHeartBeat) {
          const heartbeat = new ClientHeartBeat(m.data);
          const userId = Number(heartbeat.userId);
          store.dispatch(updateOnlineUsersArrayList(userId, true));
          userHeartbeats = {...userHeartbeats, [userId]: +new Date()};
        }
      }
    })();
  });
};

export const placeNatsOrder =
  (order: NewOrder) => async (dispatch: any, getState: () => IAppState) => {
    const { account, connection, settings } = getState();
    const destination = account.destinationsLookup.find(
      (x) => x.DestinationId === order.destinationId
    );
    const isConnected = connection.isNatsConnected;
    const notificationSetting = settings.notificationSetting;
    const placeOrderMessage = "Order Placed Successfully";

    order.destinationName = destination?.DisplayName || "";
    dispatch(addNewOrder(order));

    if (!isConnected) {
      return;
    }

    natsConnectionPromise.then((nc) => {
      // nc.publish("TRD", order.encode());
      nc.publish(`TRD`, order.encode());

      if (notificationSetting.orderNotification === true) {
        notificationSvc.success(placeOrderMessage);
      }
      if (
        notificationSetting.orderLimitNotification === true &&
        notificationSetting.orderLimit === order.quantity
      ) {
        notificationSvc.success(placeOrderMessage + " With Your Set Limit");
      }
    });
  };
export const updateConfig =
  (configUpdate, userId) =>
  async (dispatch: any, getState: () => IAppState) => {
    const { connection } = getState();
    const isConnected = connection.isNatsConnected;

    if (!isConnected) {
      return;
    }

    natsConnectionPromise.then((nc) => {
      nc.publish(`U.ADM.${userId}`, configUpdate._buffer);
    });
  };

export const subscribeToAccountMessages = (account: IAccount) => {
  natsConnectionPromise.then((nc) => {
    const sub = nc.subscribe(`*.${account.AccountId}`);
    subscriptions.push(sub);
    receiveNatsMessages(sub);
  });
};

export const unsubscribeOrders = () => {
  unsubscribeTopic("Ord.");
  unsubscribeTopic("TRD");
};

export const unsubscribeSymPositions = () => {
  unsubscribeTopic("SPs.");
};
export const unsubscribeAccountPositions = () => {
  unsubscribeTopic("APs.");
};

function processMessage(buffer: Uint8Array) {
  const notificationSetting = getGlobalState().settings.notificationSetting;
  switch (buffer[1]) {
    case NatsMessageTypeEnum.NewOrder:
      const order = new NewOrder();
      order.load(buffer);
      store.dispatch(addNewOrder(order));
      break;
    case NatsMessageTypeEnum.AccPosUpdate:
      const accPos = new AccountPositionResponse(buffer);
      store.dispatch(updateAccountPosition(accPos.getAccountPosition()));
      break;

    case NatsMessageTypeEnum.SymPosUpdate:
      const symPos = new SymbolPositionResponse(buffer);
      store.dispatch(updateSymbolPosition(symPos.getSymbolPosition()));
      break;

    case NatsMessageTypeEnum.ExecReport:
      const report = new ExecutionReport(buffer);
      store.dispatch(addOrderExecution(report));

      switch (report.status) {
        case OrderStatusEnum.Filled:
          const filledMessage =
            `${OrderSideEnum[report.side]} : ${report.quantity} ` +
            `${report.symbol} @ ${report.price || "MKT"} is filled.`;
          //notificationSvc.success(filledMessage);
          break;

        case OrderStatusEnum.Rejected:
          const rejectedMessage =
            `${OrderSideEnum[report.side]} : ${report.quantity} ${
              report.symbol
            } ` +
            `@ ${report.price || "MKT"} is rejected. Reason: ${
              report.rejDetail
            }.`;
          if (notificationSetting.orderRejectionNotification === true) {
            //notificationSvc.error(rejectedMessage);
          }

          break;
      }

      break;
  }
}

async function receiveNatsMessages(sub: SubscriptionImpl) {
  for await (const m of sub) {
    queue(m.data, processMessage);
    // processMessage(m.data);
  }
}

function subscribeTopic(nc: NatsConnectionImpl, topic: string) {
  const subscription = nc.subscribe(topic);
  subscriptions.push(subscription);
  receiveNatsMessages(subscription);
}

function subscribeToOrders(nc: NatsConnectionImpl, accountId: number) {
  unsubscribeOrders();
  subscribeTopic(nc, accountId ? `Ord.${accountId}` : `Ord.>`);
  subscribeTopic(nc, accountId ? `NOrd.${accountId}` : `NOrd.>`);
  subscribeTopic(nc, `TRD`);
}

function subscribeToSymPosition(nc: NatsConnectionImpl, accountId: number) {
  unsubscribeSymPositions();
  subscribeTopic(nc, accountId ? `SPs.${accountId}` : "SPs.>");
}
function subscribeToAccountPositions(nc: NatsConnectionImpl) {
  unsubscribeAccountPositions();
  subscribeTopic(nc, "APs.>");
}

function unsubscribeTopic(topic: string) {
  subscriptions
    .filter((x) => x.subject.includes(topic))
    .forEach((sub) => sub.close());
  subscriptions = subscriptions.filter((x) => !x.subject.includes(topic));
}

// function subscribeToConfigUpdates(nc: NatsConnectionImpl, userId: string) {
//   return async (dispatch: any) => {
//     const subscription = nc.subscribe(`U.ADM.${userId}`);
//     subscriptions.push(subscription);
//     for await (const message of subscription) {
//       dispatch(processConfigUpdate(new ConfigUpdate(message.data)));
//     }
//   };
// }
