import { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import store from "../../store";
import "./forms.scss";
import jquery from "jquery";
import DragAndDrop from "./DragAndDrop";
import apiSvc from "../../services/api-service";
import { AccountDetails, SymbolTradeLimit } from "../../models/EditDetails";
import { IFirm } from "../../models/IFirm";
import { IGroup } from "../../models/IGroup";
import { IFee } from "../../models/IFee";
import { IBorrowList } from "../../models/IBorrowList";
import { setTimeToUTC } from "../../utils/datetime";
import { IAccountTradeLimit } from "../../models/IAccountTradeLimit";
import { ISymbol } from "../../models/ISymbol";
import { IDestination } from "../../models/IDestination";
import { IBroker } from "../../models/IBroker";
import { IStrategy } from "../../models/IStrategy";
import { IUser } from "../../models/IUser";
import { MultiSelect } from "react-multi-select-component";
import { ICountry } from "../../models/ICountry";
import moment from "moment";
import { IGroupTradeLimit } from "../../models/IGroupTradeLimit";
import { IAccountDestination } from "../../models/IAccountDestination";
import { IFirmType } from "../../models/IFirmType";
import { IFirmTradeLimit } from "../../models/IFirmTradeLimit";
import {
  updateSearchResults,
  showEditForm,
} from "../../store/users/users-actions";
import notificationSvc from "../../services/notification-service";
import accountDestinationSvc from "../../services/account-destination-service";
import { IAccount } from "../../models/IAccount";
import userAccountSvc from "../../services/user-account-service";
import userSymbolSvc from "../../services/user-symbol-service";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/reducers/IAppState";
import { loadUsers } from "../../store/users/users-async-actions";
import {
  TodoItem,
  addNewAccountWithNats,
  assignAccountToUsersWithNats,
  changeAccountBalanceWithNats,
  changeAccountDestinationsWithNats,
  changeAccountFirmWithNats,
  changeAccountGroupWithNats,
  changeAccountStatusWithNats,
  changeAccountSymbolTradeLimitWithNats,
  changeAccountTradeLimitWithNats,
  changeUserStatusWithNats,
  unAssignAccountToUsersWithNats,
} from "./natsMessages";
import { loadAccounts } from "../../store/admin-accounts/accounts-async-actions";
import { loadUserAccounts } from "../../store/user-accounts/user-account-async-action";

interface UserNote {
  CreatedBy: number;
  CreatedByUserName: string;
  CreatedOn: string;
  Note: string;
  NoteId: number;
  UserId: number;
}

const Form = ({ fieldName, id }) => {
  const { firms, groups, feeList, borrowList, auth } = store.getState();
  const [key, setKey] = useState("AccountDetails");
  const [recordId, setRecordId] = useState(0);
  const [leftRow, setLeftRow] = useState(false);
  const [rightRow, setRightRow] = useState(false);
  const [accountForm, setAccountForm] = useState<AccountDetails | any>();
  const [firmList, setFirmList] = useState<IFirm[]>(
    store.getState().firms.list
  );
  const [groupList, setGroupList] = useState<IGroup[]>([]);
  const [allFees, setAllFees] = useState<IFee[]>([]);
  const [allborrowList, setAllBorrowList] = useState<IBorrowList[]>([]);
  const [accounts, setAccounts] = useState<IAccount[]>(
    store.getState().adminAccounts.accounts
  );
  const [symbolList, setsymbolList] = useState<ISymbol[]>([]);
  const [unAssignedAccount, setUnAssignedAccount] = useState<IAccount[]>([]);
  const [assignedAccount, setAssignedAccount] = useState<IUser[] | any[]>([]);
  const [userConfigData, setUserConfigData] = useState<any[]>([]);
  const [symbolTradeLimit, setSymbolTradeLimits] = useState<
    SymbolTradeLimit | any
  >();
  const [accountTradeLimitData, setAccountTradeLimitData] = useState<
    IAccountTradeLimit | any
  >();
  const [allDestinations, setAllDestinations] = useState<
    IDestination[] | any[]
  >([]);
  const [selectedDestinations, setSelectedDestinations] = useState<
    IAccountDestination[] | any
  >([]);
  const [userKey, setUserKey] = useState("Userdetail");
  const [groupKey, setGroupKey] = useState("groupdetails");
  const [firmKey, setFirmKey] = useState("firmdetails");
  const [brokerList, setBrokerList] = useState<IBroker[]>([]);
  const [strategyList, setStrategyList] = useState<IStrategy[]>([]);
  const [currentUser, setCurrentUser] = useState<IUser | any>({ Id: 0 });
  const [allCountries, setAllCountries] = useState<ICountry[]>([]);
  const [qmAccountData, setQmAccountData] = useState<any>();
  const [userAssignedSymbols, setUserAssignedSymbols] = useState<any[]>([]);
  const [groupDetail, setGroupDetail] = useState<IGroup | any>();
  const [enAssignSupervisor, setEnAssignSupervisor] = useState<boolean>(false);
  const [enGroupRisk, setEnGroupRisk] = useState<boolean>(false);
  const [showAllAccounts, setShowAllAccounts] = useState<boolean>(false);
  const [groupTradeLimit, setGroupTradeLimit] = useState<
    IGroupTradeLimit | any
  >();
  const [groupAccounts, setGroupAccounts] = useState<any[]>([]);
  const [groupSupervisors, setGroupSupervisors] = useState<any[]>([]);
  const [allSuperVisors, setAllSuperVisors] = useState<any[]>([]);
  const [currentFirm, setCurrentFirm] = useState<IFirm | any>();
  const [firmTypes, setFirmTypes] = useState<IFirmType[]>([]);
  const [selectedFirmRisk, setSelectedFirmRisk] = useState<
    IFirmTradeLimit | any
  >();
  const [newDataForDragNDrop, setNewDataForDragNDrop] = useState<any[]>([]);
  const [deletedDataForDragNDrop, setDeletedDataForDragNDrop] = useState<any[]>(
    []
  );
  const [subscriptionList, setSubscriptionList] = useState<any[]>([]);
  const [groupsByAccount, setGroupsByAccount] = useState<any[]>([]);
  const [usersByAccount, setUsersByAccount] = useState<any[]>([]);
  const [userDataSource, setUserDataSource] = useState<any[]>([]);
  const [userHWIP, setUserHWIP] = useState<string>("");
  const [userDFIP, setUserDFIP] = useState<string>("");
  const [allUserActivitiylist, setAllUserActivitiylist] = useState<any[]>([]);
  const [currentUserActivitylist, setCurrentUserActivitylist] = useState<any[]>(
    []
  );
  const [firmAccounts, setFirmAccounts] = useState<any>([]);
  const [userNotes, setUserNotes] = useState<UserNote[]>([]);
  const [userNoteInput, setUserNoteInput] = useState<string>("");

  const addUserNote = async () => {
    try {
      const adminInfo = auth.authInfo;
      if (userNoteInput.trim().length && adminInfo) {
        const note = {
          NoteId: 0,
          Note: userNoteInput.trim(),
          UserId: recordId,
          CreatedBy: Number(adminInfo.UserId),
          CreatedOn: setTimeToUTC(new Date()),
        };

        const response = await apiSvc.put("SaveUserNotesRequest", note);
        if (response.data.Success) {
          setUserNotes(
            userNotes.concat([
              {
                ...note,
                NoteId: response.data.PrimaryKey,
                CreatedByUserName: adminInfo.DisplayName,
              },
            ])
          );
          setUserNoteInput("");
          notificationSvc.success(
            `Note Added for ${currentUser.FirstName} ${currentUser.LastName}`
          );
        }
      } else {
        notificationSvc.error("Enter something to note");
      }
    } catch (e) {
      console.error(e);
      notificationSvc.error("Can not add note");
    }
  };

  const searchResults = useSelector(
    (state: IAppState) => state.users.searchResults
  );

  const dispatch = useDispatch();

  let adminDetails;

  const roleName = store.getState().auth.authInfo
    ? store.getState().auth.authInfo?.Meta.RoleName
    : "";

  const isAdmin = roleName && roleName === "Admin";
  const isRiskManager = roleName && roleName === "RiskManager";
  const isComplianceOfficer = roleName && roleName === "ComplianceOfficer";
  const isSuperAdmin = roleName && roleName === "SuperAdmin";

  const allRoles = [
    {
      RoleID: 1,
      Name: "SuperAdmin",
      DisplayName: "Super Admin",
    },
    {
      RoleID: 2,
      Name: "Admin",
      DisplayName: "Admin",
    },
    {
      RoleID: 3,
      Name: "ComplianceOfficer",
      DisplayName: "Compliance Officer",
    },
    {
      RoleID: 4,
      Name: "RiskManager",
      DisplayName: "Risk Manager",
    },
    {
      RoleID: 5,
      Name: "Supervisor",
      DisplayName: "Supervisor",
    },
    {
      RoleID: 6,
      Name: "Support",
      DisplayName: "Support",
    },
    {
      RoleID: 7,
      Name: "Trader",
      DisplayName: "Trader",
    },
  ];
  const userRoles = isSuperAdmin
    ? allRoles
    : isAdmin || isComplianceOfficer || isRiskManager
    ? [
        {
          RoleID: 5,
          Name: "Supervisor",
          DisplayName: "Supervisor",
        },
        {
          RoleID: 6,
          Name: "Support",
          DisplayName: "Support",
        },
        {
          RoleID: 7,
          Name: "Trader",
          DisplayName: "Trader",
        },
      ]
    : [
        {
          RoleID: 6,
          Name: "Support",
          DisplayName: "Support",
        },
        {
          RoleID: 7,
          Name: "Trader",
          DisplayName: "Trader",
        },
      ];

  const roleId =
    allRoles[allRoles.findIndex((x) => x.Name === roleName)].RoleID;

  const allCurrencies = [
    {
      CurrencyID: 3,
      Name: "EUR",
    },
    {
      CurrencyID: 1,
      Name: "USD",
    },
    {
      CurrencyID: 5,
      Name: "CAD",
    },
    {
      CurrencyID: 4,
      Name: "JPY",
    },
  ];

  const dataSources = [
    {
      value: 4,
      label: "QM",
    },
    {
      value: 2,
      label: "Forex",
    },
    {
      value: 5,
      label: "IQFeed",
    },
    {
      value: 1,
      label: "Crypto",
    },
  ];

  const handleDestinationUpdate = () => {
    const destinationTable = jquery("#to");
    const mainTable = jquery("#from");
    if (mainTable) {
      mainTable.find("tbody tr td").each(function (i, el) {
        if (jquery(el).attr("data-id") === "DisplayName") {
          jquery(el).addClass("d-none");
        }
      });
    }
    if (destinationTable) {
      destinationTable.find("tbody tr").each(function (i, el) {
        let $tds = jquery(el).find("td");
        $tds.eq(3).removeClass("d-none");
      });
    }
  };

  useEffect(() => {
    jquery(function () {
      jquery(document).on("click", ".rtol", function () {
        var getselectedvalue = jquery("#to tbody input:checked")
          .parents("tr")
          .clone()
          .appendTo(jquery("#from tbody").add(getselectedvalue));
        jquery("#to tbody input:checked").parents("tr").remove();
        handleDestinationUpdate();
      });
      jquery(document).on("click", ".ltor", function () {
        var getselectedvalue = jquery("#from tbody input:checked")
          .parents("tr")
          .clone()
          .appendTo(jquery("#to tbody").add(getselectedvalue));
        jquery("#from tbody input:checked").parents("tr").remove();
        handleDestinationUpdate();
      });
    });

    setRecordId(id);

    getAdminAccountDetails(store.getState().auth.authInfo?.UserId).then(() => {
      if (fieldName.includes("Account")) {
        // jquery("#EditForm")[0].innerText = "Add Account";

        if (!firms.list.length) {
          getAllFirms();
        } else {
          setFirmList(firms.list);
        }

        if (groups.groups.length) {
          setGroupList(groups.groups);
        } else {
          getAllGroups();
        }

        if (feeList.feeList.length) {
          setAllFees(feeList.feeList);
        } else {
          getAllFees();
        }

        if (borrowList.borrowList.length) {
          setAllBorrowList(borrowList.borrowList);
        } else {
          getAllBorrowlist();
        }

        if (!accounts || !accounts.length) {
          getAllAccounts();
        }

        if (id > 0) {
          getAccountTradeLimits(id);
          getSymbolTradeLimits(id);
          getCurrentAccountDestinations(id);
          getUserAccountInfo(id);
          getGroupsForAccount(id);
          getUsersForAccount(id);
          // jquery("#EditForm")[0].innerText = "Edit Account";
        }
        getAllDestinations();
        getAllSymbols();
        getAllBrokers();
        getAllStretagy();
      } else if (fieldName.includes("User")) {
        // jquery("#EditForm")[0].innerText = "Add User";
        if (!firms.list.length) {
          getAllFirms();
        } else {
          setFirmList(firms.list);
        }
        getAllCountries();
        getAllSymbols();
        getAllPackages();
        getUnassignedAccounts();
        getAllAccounts();
        getAllUserPermissions();
        if (id > 0) {
          // jquery("#EditForm")[0].innerText = "Edit User";
          getQmAccountData(id);
          getUserDetail(id);
          getUserSymbols(id);
          getAssignedAccounts(id);
          getUserConfig(id);
          getUserPermissions(id);
        }
      } else if (fieldName.includes("Group")) {
        // jquery("#EditForm")[0].innerText = "Add Group";
        if (id > 0) {
          setRecordId(id);
          getSpecificGroup(id);
          getGroupTradelimit(id);
          getGroupAccounts(id);
          getGroupSuperVisors(id);
          // jquery("#EditForm")[0].innerText = "Edit Group";
        }
        getAllGroups();
        getAllFirms();
        getAllAccounts();
        getAllSuperVisor();
        getUnassignedAccounts();
      } else if (fieldName.includes("Firm")) {
        // jquery("#EditForm")[0].innerText = "Add Firm";
        getAllFirmTypes();
        getAllAccounts();
        getAllGroups();
        getUnassignedAccounts();
        if (id > 0) {
          setRecordId(id);
          getSelectedFirm(id);
          getFirmRisk(id);
          getFirmAccounts(id);
          // jquery("#EditForm")[0].innerText = "Edit Firm";
        }
      }
    });
  }, []);

  const getAllFirms = () => {
    const firms = store.getState().firms.list;
    if (firms.length) {
      if (isSuperAdmin) {
        setFirmList(firms);
      } else {
        setFirmList(firms.filter((x) => x.FirmId === adminDetails.FirmId));
      }
    } else {
      apiSvc.get(`SelectFirmsRequest`).then((firmData) => {
        if (isSuperAdmin) {
          setFirmList(firmData?.data.Data);
        } else {
          let firms = firmData?.data.Data || [];
          setFirmList(firms.filter((x) => x.FirmId === adminDetails.FirmId));
        }
      });
    }
  };

  const getAllGroups = () => {
    const { groups } = store.getState().groups;
    if (groups && groups.length) {
      if (isSuperAdmin) {
        setGroupList(groups);
      } else {
        setGroupList(groups.filter((x) => x.FirmId === adminDetails.FirmId));
      }
    }
    apiSvc.get(`SelectGroupsRequest`).then((groupsData) => {
      if (isSuperAdmin) {
        setGroupList(groupsData?.data.Data);
      } else {
        let groups = groupsData?.data.Data || [];
        setGroupList(groups.filter((x) => x.FirmId === adminDetails.FirmId));
      }
    });
  };

  const getAllFees = () => {
    apiSvc.get(`SelectFeeRequest`).then((feeData) => {
      setAllFees(feeData?.data.Data);
    });
  };

  const getAllBorrowlist = () => {
    apiSvc.get(`SelectBorrowListRequest`).then((borrolistData) => {
      setAllBorrowList(borrolistData?.data.Data);
    });
  };

  const getAllAccounts = () => {
    const stateData = store.getState().adminAccounts.accounts;
    if (stateData && stateData.length) {
      setAccounts(stateData);
    } else {
      apiSvc.get(`SelectAccountsRequest`).then((accountsData) => {
        setAccounts(accountsData?.data.Data);
      });
    }
  };

  const getAllUserPermissions = () => {
    apiSvc.get(`SelectActivitiesRequest`).then((permissions) => {
      setAllUserActivitiylist(permissions?.data.Data);
    });
  };

  const getAllSuperVisor = () => {
    apiSvc
      .get(`SelectUsersRequest?selectColumnName=RoleId&SelectKey=5`)
      .then((supervisors) => {
        setAllSuperVisors(supervisors?.data.Data);
      });
  };

  const getAllBrokers = () => {
    apiSvc.get(`SelectBrokersRequest`).then((brokerData) => {
      setBrokerList(brokerData?.data.Data);
    });
  };

  const getAllStretagy = () => {
    apiSvc.get(`SelectStrategiesRequest`).then((strategyData) => {
      setStrategyList(strategyData?.data.Data);
    });
  };

  const getAllSymbols = () => {
    apiSvc.get(`SelectSymbolsRequest`).then((symbolsData) => {
      setsymbolList(symbolsData?.data.Data);
    });
  };

  const getAllPackages = () => {
    apiSvc.get(`SelectPackagesRequest`).then((symbolsData) => {
      setSubscriptionList(symbolsData?.data.Data);
    });
  };

  const getAllDestinations = () => {
    apiSvc
      .get(`SelectDestinationsRequest?IncludeRelatedTables=true`)
      .then((destinationData) => {
        setAllDestinations(destinationData?.data.Data);
      });
  };

  const getAllCountries = () => {
    apiSvc.get(`SelectCountriesRequest`).then((countries) => {
      setAllCountries(countries?.data.Data);
    });
  };

  const getAllFirmTypes = () => {
    apiSvc.get(`SelectFirmTypesRequest`).then((countries) => {
      setFirmTypes(countries?.data.Data);
    });
  };

  const getUnassignedAccounts = () => {
    apiSvc.get(`UnAssignedAccountRequest`).then((accounts) => {
      setUnAssignedAccount(accounts?.data.Unassigned_Accounts);
    });
  };

  const getUserAccountInfo = async (id, isCopy?: boolean) => {
    const accountsData = store.getState().adminAccounts.accounts;
    if (accountsData && accountsData.length) {
      const account = accountsData.find((x) => x.AccountId == id);
      setAccountForm({...account, Name: isCopy ? "" : account?.Name});
    } else {
      const searchResults = await apiSvc.get(
        `SelectAccountsRequest?SelectKey=${id}&selectColumnName=AccountId`
      );
      if (searchResults?.data && searchResults.data.Data) {
        setAccountForm(searchResults.data.Data[0]);
      }
    }
  };

  const getStrategyName = (id) => {
    let strategy = strategyList.filter((item) => item.StrategyId === id);
    if (strategy && strategy.length) return strategy[0].Name;
    else return "";
  };

  const getBrokerName = (id) => {
    let broker = brokerList.filter((item) => item.BrokerId === id);
    if (broker && broker.length) return broker[0].BrokerName;
    else return "";
  };

  const getDataSourceLabel = (id) => {
    const sourceName = dataSources.filter((source) => source.value === id);
    return sourceName.length && sourceName[0].label ? sourceName[0].label : "";
  };

  const getUserSymbols = async (id) => {
    const userSymbolResult = await apiSvc.get(
      `SelectUserSymbolsRequest?SelectKey=${id}&selectColumnName=UserId`
    );
    if (userSymbolResult?.data && userSymbolResult.data.Data) {
      setUserAssignedSymbols(userSymbolResult.data.Data);
    }
  };

  const getGroupsForAccount = async (id) => {
    const groups = await apiSvc.get(
      `SelectAccountGroupsRequest?SelectKey=${id}&selectColumnName=AccountId`
    );
    if (groups?.data && groups.data.Data) {
      setGroupsByAccount(groups.data.Data);
    }
  };

  const getUsersForAccount = async (id) => {
    const users = await apiSvc.get(
      `SelectUserAccountsRequest?SelectKey=${id}&selectColumnName=AccountId`
    );
    if (users?.data && users.data.Data) {
      setUsersByAccount(users.data.Data);
    }
  };

  const getGroupTradelimit = (id) => {
    apiSvc
      .get(
        `SelectGroupTradeLimitsRequest?SelectKey=${id}&selectColumnName=GroupId`
      )
      .then((groupTradeLimitData) => {
        setGroupTradeLimit(groupTradeLimitData?.data.Data[0]);
      });
  };

  const getGroupAccounts = (id) => {
    apiSvc
      .get(
        `SelectAccountGroupsRequest?SelectKey=${id}&selectColumnName=GroupId`
      )
      .then((groupTradeLimitData) => {
        setGroupAccounts(groupTradeLimitData?.data.Data);
      });
  };

  const getGroupSuperVisors = (id) => {
    apiSvc
      .get(
        `SelectGroupSupervisorsRequest`
      )
      .then((groupSupervisorData) => {
        setGroupSupervisors(groupSupervisorData?.data.Data.filter((w) => w.GroupId == id));
      });
  };

  const getAccountTradeLimits = (id) => {
    apiSvc
      .get(
        `SelectAccountTradeLimitsRequest?SelectKey=${id}&selectColumnName=AccountId`
      )
      .then((tradeLimitData) => {
        setAccountTradeLimitData(tradeLimitData?.data.Data[0]);
      });
  };

  const getSpecificGroup = (id) => {
    const groupsData = store.getState().groups.groups;
    if (groupsData && groupsData.length) {
      const group = groupsData.find((x) => x.GroupId === id);
      if(group)
      {
        setGroupDetail(group);
        setEnGroupRisk(group.EnRiskControl);
        setEnAssignSupervisor(group.HasSupervisorRights > 0);
      }
    } else {
      apiSvc
        .get(`SelectGroupsRequest?SelectKey=${id}&selectColumnName=GroupId`)
        .then((groupDetailData) => {
          setGroupDetail(groupDetailData?.data.Data[0]);
          setEnGroupRisk(groupDetailData?.data.Data[0].EnRiskControl);
        });
    }
  };

  const getSymbolTradeLimits = (id) => {
    apiSvc
      .get(
        `SelectSymbolTradeLimitsRequest?SelectKey=${id}&selectColumnName=AccountId`
      )
      .then((tradeLimitData) => {
        setSymbolTradeLimits(tradeLimitData?.data.Data[0]);
      });
  };

  const getCurrentAccountDestinations = (id) => {
    apiSvc
      .get(
        `SelectAccountDestinationsRequest?SelectKey=${id}&selectColumnName=AccountId`
      )
      .then((destination) => {
        setSelectedDestinations(destination?.data.Data);
      });
  };

  const getQmAccountData = (id) => {
    apiSvc
      .get(`SelectQMAccountRequest?SelectKey=${id}&selectColumnName=UserID`)
      .then((qmData) => {
        setQmAccountData(qmData?.data.Data[0]);
      });
  };

  const getUserDetail = async (id) => {
    const usersData = store.getState().users.data;
    const userArea = jquery(".dis-button");
    let targetRecord;
    if (usersData && usersData.length) {
      targetRecord = usersData.find((x) => x.Id === id);
      setCurrentUser(targetRecord);
      apiSvc
        .get(
          `SelectUserDataSourcesRequest?selectColumnName=UserId&SelectKey=${id}`
        )
        .then((res) => {
          if (res?.data) {
            setUserDataSource(res.data.Data.filter((w) => w.UserId === id));
          }
        });
      apiSvc
        .get(`SelectUserNotesRequest?selectColumnName=UserId&SelectKey=${id}`)
        .then((res) => {
          if (res?.data) {
            setUserNotes(
              res.data.Data.map((w) => {
                return {
                  ...w,
                  CreatedByUserName: usersData.find((z) => z.Id === w.CreatedBy)
                    ?.DisplayName,
                };
              })
            );
          }
        });
    } else {
      const userData = await apiSvc.get(
        `SelectUsersRequest?SelectKey=${id}&selectColumnName=Id&IncludeRelatedTables=true`
      );
      if (userData?.data && userData.data.Data) {
        targetRecord = userData.data.Data[0];
        setCurrentUser(userData.data.Data[0]);
        setUserDataSource(userData.data.Data[0].DataSources || []);
      }
    }
    if (targetRecord && targetRecord.RoleId && targetRecord.RoleId < roleId) {
      if (userArea) {
        userArea.each((index, element) => {
          jquery(element).prop("disabled", true);
        });
      }
    }
  };

  const getAdminAccountDetails = async (id) => {
    const userData = await apiSvc.get(
      `SelectUsersRequest?SelectKey=${id}&selectColumnName=Id&IncludeRelatedTables=true`
    );
    if (userData?.data && userData.data.Data) {
      adminDetails = userData.data.Data[0];
    }
  };

  const getSelectedFirm = async (id) => {
    const firmsData = store.getState().firms.list;
    if (firmsData && firmsData.length) {
      setCurrentFirm(firmsData.filter((x) => x.FirmId === id)[0]);
    } else {
      const firmData = await apiSvc.get(
        `SelectFirmsRequest?SelectKey=${id}&selectColumnName=FirmId`
      );
      if (firmData?.data && firmData.data.Data) {
        setCurrentFirm(firmData.data.Data[0]);
      }
    }
  };

  const getAssignedAccounts = async (id) => {
    const assignedAccountsData = await apiSvc.get(
      `SelectUserAccountsRequest?SelectKey=${id}&selectColumnName=UserId`
    );
    if (assignedAccountsData?.data && assignedAccountsData.data.Data) {
      setAssignedAccount(assignedAccountsData.data.Data);
    }
  };

  // SelectUserConfigRequest
  const getUserConfig = async (id) => {
    const userConfigData = await apiSvc.get(
      `SelectUserConfigRequest?SelectKey=${id}&selectColumnName=UserId`
    );
    if (userConfigData?.data && userConfigData.data.Data) {
      setUserConfigData(userConfigData.data.Data);
    }
  };

  const getUserPermissions = async (id) => {
    const userPermissions = await apiSvc.get(
      `SelectUserActivitiesRequest?SelectKey=${id}&selectColumnName=UserId`
    );
    if (userPermissions?.data && userPermissions.data.Data) {
      setCurrentUserActivitylist(userPermissions.data.Data);
    }
  };

  const getFirmRisk = async (id) => {
    const firmData = await apiSvc.get(
      `SelectFirmTradeLimitsRequest?SelectKey=${id}&selectColumnName=FirmId`
    );
    if (firmData?.data && firmData.data.Data && firmData.data.Data.length) {
      setSelectedFirmRisk(firmData.data.Data[0]);
    } else {
      setSelectedFirmRisk({
        BuyingPower: 0,
        EnFirmBp: false,
        EnFirmMaxLoss: false,
        EnFirmMaxPos: false,
        EnRejectWashTradeWithinFirm: false,
        EnUrlzLinkToBp: false,
        EnUrlzLinkToMaxLoss: false,
        MaxLoss: 0,
        FirmId: id,
      });
    }
  };

  const getFirmAccounts = (id) => {
    if (accounts && accounts.length) {
      const firmAccounts = accounts.filter((x) => x.FirmId == id)
      setFirmAccounts(firmAccounts);
    }
  };

  const checkEmail = (email: string) => {
    const filter: any = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (filter && !filter.test(email)) {
      return false;
    }
    return true;
  };

  const editUser = (userId: string, userEmail: string) => {
    store.dispatch(
      showEditForm({
        status: true,
        data: {
          fieldName: `Edit User ${userEmail}`,
          fieldData: userId,
        },
      })
    );
  };

  const validateAccountDetails = () => {
    const accountRequiredFields = [
      "Name",
      "AccountStatusID",
      "AccountType",
      "MainCurrencyID",
      "FirmId",
      "GroupId",
      "BorrowListID",
      "FeeID",
    ];
    let flag = true;
    if (!accountForm) {
      notificationSvc.error("Please enter the value for Account Name");
      return false;
    }  let missingField = accountRequiredFields.find(
      (v) => !Object.keys(accountForm).find((z: string) => z === v)
    );
    if (missingField) {
      missingField = missingField.replace(/id/gi, "");     
      notificationSvc.error(
        `Please enter the value for ${missingField.replace(
          /([a-z])([A-Z])|Id$/g,
          (match, lower, upper) => (lower ? `${lower} ${upper}` : "")
        )}`
      );
      return false;
    } else {
      Object.keys(accountForm).forEach((field) => {
        if (
          accountRequiredFields.includes(field) &&
          (accountForm[field] === "" || accountForm[field] <= 0)
        ) {
          notificationSvc.error(
            `Please enter valid ${field.replace(
              /([a-z])([A-Z])|Id$/g,
              (match, lower, upper) => (lower ? `${lower} ${upper}` : "")
            )}`
          );
          flag = false;
        }
      });
    }
    return flag;
  };

  const validateUserDetails = () => {
    const userRequiredFields = [
      "UserName",
      "Email",
      "FirstName",
      "LastName",
      // "PasswordExpiresOn",
      "UserStatusId",
      "FirmId",
      "RoleId",
      // "Address2",
      // "City",
      "CountryId",
      // "PhoneNumber",
      "Company",
    ];
    let flag = true;
    if (!currentUser) {
      return false;
    }
    let missingField = userRequiredFields.find(
      (v) => !Object.keys(currentUser).find((z: string) => z === v)
    );
    if (missingField) {
      missingField = missingField.replace(/Id$/, "");
      if (missingField === "Company") {
        missingField = "Subscription";
      }
      notificationSvc.error(
        `Please enter the value for ${missingField.replace(
          /([a-z])([A-Z])|Id$/g,
          (match, lower, upper) => (lower ? `${lower} ${upper}` : "")
        )}`
      );
      return false;
    } else if (!checkEmail(currentUser.UserName)) {
      notificationSvc.error("Please enter valid email address");
      return false;
    } else if (!checkEmail(currentUser.Email)) {
      notificationSvc.error("Username contains invalid characters");
      return false;
    } else if (!recordId && !currentUser.Password) {
      notificationSvc.error("Please Set the Password");
      return false;
    }
    return flag;
  };

  const validateGroupDetails = () => {
    let flag = true;
    const groupRequiredFields = [
      "FirmId",
      "Name"
    ];
    if (!groupDetail || !groupDetail.Name) {
      notificationSvc.error("Please enter group name");
      return false;
    }
    let missingField = groupRequiredFields.find(
      (v) => !Object.keys(groupDetail).find((z: string) => z === v)
    );
    if (missingField) {
      missingField = missingField.replace(/Id$/, "");
      notificationSvc.error(
        `Please enter the value for ${missingField.replace(
          /([a-z])([A-Z])|Id$/g,
          (match, lower, upper) => (lower ? `${lower} ${upper}` : "")
        )}`
      );
      return false;
    } 
    return flag;
  };

  const validateFirmDetails = () => {
    let flag = true;
    const firmRequiredFields = [
      "Name",
      "Address",
      "CityCountry",
      "PostCode",
      "FirmTypeId",
    ];
    if (!currentFirm || !currentFirm.Name) {
      notificationSvc.error("Please enter firm name");
      return false;
    }
    let missingField = firmRequiredFields.find(
      (v) => !Object.keys(currentFirm).find((z: string) => z === v)
    );
    if (missingField) {
      missingField = missingField.replace(/Id$/, "");
      notificationSvc.error(
        `Please enter the value for ${missingField.replace(
          /([a-z])([A-Z])|Id$/g,
          (match, lower, upper) => (lower ? `${lower} ${upper}` : "")
        )}`
      );
      return false;
    } 
    else {
      Object.keys(currentFirm).forEach((field) => {
        if (
          firmRequiredFields.includes(field) &&
          (currentFirm[field] === "" || currentFirm[field] <= 0)
        ) {
          flag = false;
        }
      });
    }
    return flag;
  };

  const validateAccountDestinations = () => {
    const destinationTable = jquery("#to");
    let flag: boolean = true;
    let destinationList: any = [];
    if (destinationTable) {
      destinationTable.find("tbody tr").each(function (i, el) {
        let $tds = jquery(el).find("td");
        const displayName = $tds.eq(3).find("input[type=text]").val();
        if (!displayName || !displayName.trim()) {
          flag = false;
        } else {
          destinationList.push({
            AccountId: recordId,
            DestinationId: $tds.eq(1).attr("data-id"),
            DisplayName: displayName,
          });
        }
      });
    }
    if (!flag) {
      notificationSvc.error("Display name is required");
      destinationList = [];
    }
    return destinationList;
  };

  const handleNumericValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    lastMaxValue: number
  ) => {
    const eventValue = e.target.value;
    if (!eventValue) {
      return 0;
    } else if (Number(eventValue) > 999999) {
      return lastMaxValue;
    } else {
      return Number(eventValue);
    }
  };

  const handleSaveAccount = () => {
    const userAccounts = store.getState().userAccountsList.userAccountsList;
    let userAccount;
    let oldAccountData: any[];
    let oldAccountTradeLimit;
    if (recordId > 0) {
      userAccount = userAccounts.filter((x) => x.AccountId == recordId);
      oldAccountData = accounts.filter((x) => x.AccountId == recordId);
      oldAccountTradeLimit = store
        .getState()
        .adminAccounts.accountTradeLimits.filter(
          (x) => x.AccountId == recordId
        );
    }
    switch (key) {
      case "AccountDetails":
        if (!validateAccountDetails()) {
          break;
        }
        let editedInfo = {
          ...accountForm,
          CreatedBy: accountForm && accountForm.CreatedBy ? accountForm.CreatedBy : parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          CreatedOn: accountForm && accountForm.CreatedOn ? setTimeToUTC(new Date(accountForm.CreatedOn)) : setTimeToUTC(new Date()),
          ModifiedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          ModifiedOn: setTimeToUTC(new Date()),
        };
        apiSvc.put("SaveAccountsRequest", editedInfo).then((res) => {
          if (recordId > 0) {
            if (
              oldAccountData.length &&
              oldAccountData[0].AccountStatusID !== accountForm.AccountStatusID
            ) {
              changeAccountStatusWithNats(
                {
                  AccountId: recordId,
                  AccountStatusID: accountForm.AccountStatusID,
                },
                userAccount[0].UserId
              );
            }

            if (
              oldAccountData.length &&
              oldAccountData[0]?.Balance !== accountForm?.Balance
            ) {
              changeAccountBalanceWithNats(
                { AccountId: recordId, Balance: accountForm?.Balance },
                userAccount[0].UserId
              );
            }

            if (
              oldAccountData.length &&
              oldAccountData[0].FirmId !== accountForm?.FirmId
            ) {
              changeAccountFirmWithNats(
                { AccountId: recordId, FirmId: accountForm?.FirmId },
                userAccount[0].UserId
              );
            }
            if (
              oldAccountData.length &&
              oldAccountData[0].GroupId !== accountForm?.GroupId
            ) {
              changeAccountGroupWithNats(
                { AccountId: recordId, GroupId: accountForm?.GroupId },
                userAccount[0].UserId
              );
            }
          } else {
            addNewAccountWithNats(
              {
                AccountId: res.data.PrimaryKey,
              },
              0
            );
          }
          notificationSvc.info("Details Saved");
          if (res.data && res.data.PrimaryKey && recordId <= 0) {
            setRecordId(res.data.PrimaryKey);
            setAccountForm({ ...accountForm, AccountId: res.data.PrimaryKey });
            addNewAccountWithNats({}, 0);
          }
          store.dispatch(
            showEditForm({
              status: false,
              data: { fieldData: 0, fieldName, updatedData: { account: accountForm }},
            })
          );
          store.dispatch(loadAccounts);
        });
        break;
      case "SymbolLimit":
        let updatedTrafeLimit = {
          ...symbolTradeLimit,
          AccountId: accountForm.AccountId,
          CreatedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          CreatedOn: setTimeToUTC(new Date()),
          ModifiedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          ModifiedOn: setTimeToUTC(new Date()),
        };
        apiSvc
          .put("SaveSymbolTradeLimitsRequest", updatedTrafeLimit)
          .then(() => {
            changeAccountSymbolTradeLimitWithNats(
              {
                AccountId: recordId,
              },
              userAccount[0].UserId
            );
            store.dispatch(
              showEditForm({
                status: false,
                data: { fieldData: 0, fieldName, updatedData: { account: accountForm }},
              })
            );
            store.dispatch(loadAccounts);
          });
        break;
      case "AccountRisk":
        let updatedAccountRisk = {
          ...accountTradeLimitData,
          AccountId: accountForm.AccountId,
          CreatedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          CreatedOn: setTimeToUTC(new Date()),
          ModifiedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          ModifiedOn: setTimeToUTC(new Date()),
        };
        apiSvc
          .put("SaveAccountTradeLimitsRequest", updatedAccountRisk)
          .then(() => {
            changeAccountTradeLimitWithNats(
              {
                AccountId: recordId,
              },
              userAccount[0].UserId
            );
            store.dispatch(
              showEditForm({
                status: false,
                data: { fieldData: 0, fieldName, updatedData: { account: accountForm } },
              })
            );
            store.dispatch(loadAccounts);
          });
        break;
      case "AccountDestination":
        const validationResult = validateAccountDestinations();
        if (validationResult.length) {
          let removedDestinations = selectedDestinations.filter((dest) => {
            const indexOfRemovedDestinations = validationResult.findIndex(
              (currentDest) => currentDest.DestinationId === dest.DestinationId
            );
            if (indexOfRemovedDestinations === -1) return dest;
          });
          let newDestinations = validationResult.filter((dest) => {
            const indexOfRemovedDestinations = selectedDestinations.findIndex(
              (currentDest) => currentDest.DestinationId === dest.DestinationId
            );
            if (indexOfRemovedDestinations === -1) return dest;
          });
          newDestinations.map((dest) => {
            let assignDestinations = {
              ...dest,
              CreatedBy: parseInt(
                auth && auth.authInfo && auth.authInfo.UserId
                  ? auth.authInfo?.UserId
                  : "0"
              ),
              CreatedOn: setTimeToUTC(new Date()),
              ModifiedBy: parseInt(
                auth && auth.authInfo && auth.authInfo.UserId
                  ? auth.authInfo?.UserId
                  : "0"
              ),
              ModifiedOn: setTimeToUTC(new Date()),
            };
            apiSvc.put("SaveAccountDestinationsRequest", assignDestinations);
          });
          removedDestinations.map((dest) => {
            accountDestinationSvc.delete(dest.Id);
          });
          notificationSvc.info("Account Destinations Updated");
          changeAccountDestinationsWithNats(
            {
              AccountId: recordId,
            },
            userAccount[0].UserId
          );
          store.dispatch(
            showEditForm({
              status: false,
              data: { fieldData: 0, fieldName, updatedData: { account: accountForm } },
            })
          );
          store.dispatch(loadAccounts);
        }
        break;
      default:
        break;
    }

    if (
      searchResults &&
      searchResults?.accounts &&
      searchResults.accounts.length
    ) {
      let updatedRecord = searchResults;
      const pos = updatedRecord.accounts.findIndex(
        (x) => x.AccountId === recordId
      );
      if (pos !== -1) {
        updatedRecord.accounts[pos] = accountForm;
      }
      dispatch(updateSearchResults(updatedRecord));
    }
  };

  const handleSaveUser = () => {
    switch (userKey) {
      case "Userdetail":
        if (!validateUserDetails()) {
          break;
        }
        let editedInfo = {
          ...currentUser,
          CreatedBy: currentUser.CreatedBy ? currentUser.CreatedBy : parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          CreatedDate: currentUser.CreatedDate
            ? setTimeToUTC(new Date(currentUser.CreatedDate))
            : setTimeToUTC(new Date()),
          ModifiedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          ModifiedOn: setTimeToUTC(new Date()),
        };
        delete currentUser.DataSources;
        apiSvc
          .put("SaveUsersRequest", editedInfo)
          .then((res) => {
            if (!res.data.Success) {
              notificationSvc.error("Details Not Saved");
            }
            if (recordId > 0) {
              const usersData = store.getState().users.data;
              const oldData = usersData.filter((x) => x.Id === recordId);
              if (
                oldData.length &&
                oldData[0].UserStatusId !== currentUser.UserStatusId
              ) {
                changeUserStatusWithNats(
                  { UserId: recordId, UserStatusId: currentUser.UserStatusId },
                  recordId
                );
              }
            }
            setRecordId(res.data.PrimaryKey);
            let qmData = {};
            if(qmAccountData && qmAccountData.WMID)
            {
              qmData = {
                ...qmAccountData,
                UserId: recordId > 0 ? recordId : res.data.PrimaryKey,
                CreatedBy: parseInt(
                  auth && auth.authInfo && auth.authInfo.UserId
                    ? auth.authInfo?.UserId
                    : "0"
                ),
                WMID: qmAccountData.WMID,
                Password: qmAccountData.Password,
                UserName: qmAccountData.UserName,
                CreatedOn: setTimeToUTC(new Date()),
                ModifiedBy: parseInt(
                  auth && auth.authInfo && auth.authInfo.UserId
                    ? auth.authInfo?.UserId
                    : "0"
                ),
                ModifiedOn: setTimeToUTC(new Date()),
              };
              apiSvc.put("SaveQMAccountRequest", qmData);
            }
            if (userDataSource && userDataSource.length) {
              apiSvc
                .get(
                  `SelectUserDataSourcesRequest?selectColumnName=UserId&SelectKey=${recordId}`
                )
                .then((res) => {
                  if (res?.data.Data) {
                    let existingSources: any = res.data.Data;
                    existingSources.forEach((src) => {
                      apiSvc.api.delete("DeleteUserDataSourcesRequest", {
                        params: {
                          PrimaryKey: src.UserDataSourceId,
                          UserId: recordId > 0 ? recordId : res.data.PrimaryKey,
                          LoadCache: true,
                        },
                      });
                    });
                    userDataSource.forEach((src) => {
                      apiSvc.put("SaveUserDataSourcesRequest", {
                        DataSourceId: src.DataSourceId,
                        UserId: recordId > 0 ? recordId : res.data.PrimaryKey,
                        CreatedBy: parseInt(
                          auth && auth.authInfo && auth.authInfo.UserId
                            ? auth.authInfo?.UserId
                            : "0"
                        ),
                        CreatedOn: setTimeToUTC(new Date()),
                        ModifiedBy: parseInt(
                          auth && auth.authInfo && auth.authInfo.UserId
                            ? auth.authInfo?.UserId
                            : "0"
                        ),
                        ModifiedOn: setTimeToUTC(new Date()),
                      });
                    });
                  }
                });
            }

            apiSvc.put("SaveUserConfigRequest", {
              liveServer: userHWIP,
              demoServer: userHWIP,
              demoDF: userDFIP,
              liveDF: userDFIP,
              UserId: recordId,
              CreatedBy: parseInt(
                auth && auth.authInfo && auth.authInfo.UserId
                  ? auth.authInfo?.UserId
                  : "0"
              ),
              CreatedOn: setTimeToUTC(new Date()),
              ModifiedBy: parseInt(
                auth && auth.authInfo && auth.authInfo.UserId
                  ? auth.authInfo?.UserId
                  : "0"
              ),
              ModifiedOn: setTimeToUTC(new Date()),
            });
            notificationSvc.info("Details Saved");
            store.dispatch(loadUserAccounts);
            if (res.data && res.data.PrimaryKey && recordId <= 0) {
              setRecordId(res.data.PrimaryKey);
              setCurrentUser({ ...currentUser, Id: res.data.PrimaryKey });
            }
            store.dispatch(
              showEditForm({
                status: false,
                data: {
                  fieldData: 0,
                  fieldName,
                  updatedData: { user: currentUser, qmData },
                },
              })
            );
          })
          .catch((e) => {
            console.error(e);
            notificationSvc.error("Details Not Saved");
          });
        break;
      case "Assignaccount":
        newDataForDragNDrop.forEach((itemId) => {
          let payload = {
            AccountId: parseInt(itemId),
            CreatedBy: parseInt(
              auth && auth.authInfo && auth.authInfo.UserId
                ? auth.authInfo?.UserId
                : "0"
            ),
            CreatedOn: setTimeToUTC(new Date()),
            ModifiedBy: parseInt(
              auth && auth.authInfo && auth.authInfo.UserId
                ? auth.authInfo?.UserId
                : "0"
            ),
            ModifiedOn: setTimeToUTC(new Date()),
            UserId: recordId,
          };
          apiSvc.api.put("SaveUserAccountsRequest", {
            Data: payload,
            LoadCache: true,
          });
          assignAccountToUsersWithNats(
            { UserId: recordId, AccountId: parseFloat(itemId) },
            recordId
          );
        });
        deletedDataForDragNDrop.forEach((itemId) => {
          let payload = {
            primaryKey: parseInt(itemId),
            userId: recordId,
          };
          userAccountSvc.delete(payload);
          unAssignAccountToUsersWithNats(
            { UserId: recordId, AccountId: parseFloat(itemId) },
            recordId
          );
        });
        setNewDataForDragNDrop([]);
        setDeletedDataForDragNDrop([]);
        notificationSvc.info("Details Saved");
        store.dispatch(
          showEditForm({
            status: false,
            data: { fieldData: 0, fieldName,
              updatedData: { user: currentUser } },
          })
        );
        break;
      case "AssignSymbol":
        newDataForDragNDrop.forEach((itemId) => {
          let payload = {
            SymbolId: parseInt(itemId),
            CreatedBy: parseInt(
              auth && auth.authInfo && auth.authInfo.UserId
                ? auth.authInfo?.UserId
                : "0"
            ),
            CreatedOn: setTimeToUTC(new Date()),
            ModifiedBy: parseInt(
              auth && auth.authInfo && auth.authInfo.UserId
                ? auth.authInfo?.UserId
                : "0"
            ),
            ModifiedOn: setTimeToUTC(new Date()),
            UserId: recordId,
          };
          apiSvc.api.put("SaveUserSymbolsRequest", {
            Data: payload,
            LoadCache: true,
          });
        });
        deletedDataForDragNDrop.forEach((itemId) => {
          let payload = {
            primaryKey: parseInt(itemId),
            userId: recordId,
          };
          userSymbolSvc.delete(payload);
        });
        setNewDataForDragNDrop([]);
        setDeletedDataForDragNDrop([]);
        notificationSvc.info("Details Saved");
        store.dispatch(
          showEditForm({
            status: false,
            data: { fieldData: 0, fieldName,
              updatedData: { user: currentUser } },
          })
        );
        break;
      case "Permission":
        newDataForDragNDrop.forEach((itemId) => {
          let payload = {
            ActivityId: parseInt(itemId),
            CreatedBy: parseInt(
              auth && auth.authInfo && auth.authInfo.UserId
                ? auth.authInfo?.UserId
                : "0"
            ),
            CreatedOn: setTimeToUTC(new Date()),
            ModifiedBy: parseInt(
              auth && auth.authInfo && auth.authInfo.UserId
                ? auth.authInfo?.UserId
                : "0"
            ),
            ModifiedOn: setTimeToUTC(new Date()),
            UserId: recordId,
          };
          apiSvc.api.put("SaveUserActivitiesRequest", {
            Data: payload,
            LoadCache: true,
          });
        });
        deletedDataForDragNDrop.forEach((itemId) => {
          let payload = {
            primaryKey: parseInt(itemId),
            userId: recordId,
          };
          apiSvc.api.delete("DeleteUserActivitiesRequest", {
            params: {
              PrimaryKey: payload.primaryKey,
              UserId: payload.userId,
              LoadCache: true,
            },
          });
        });
        setNewDataForDragNDrop([]);
        setDeletedDataForDragNDrop([]);
        notificationSvc.info("Details Saved");
        store.dispatch(
          showEditForm({
            status: false,
            data: { fieldData: 0, fieldName,
              updatedData: { user: currentUser } },
          })
        );
        break;
      default:
        break;
    }
    if (searchResults && searchResults?.users && searchResults.users.length) {
      let updatedRecord = searchResults;
      const pos = updatedRecord.users.findIndex((x) => x.Id === recordId);
      if (pos !== -1) {
        updatedRecord.users[pos] = currentUser;
      }
      dispatch(updateSearchResults(updatedRecord));
    }
  };

  const handleSaveGroup = () => {
    let editedInfo;
    switch (groupKey) {
      case "groupdetails":
        if (!validateGroupDetails()) {
          break;
        }
        editedInfo = {
          ...groupDetail,
          CreatedBy: groupDetail.CreatedBy ? groupDetail.CreatedBy : parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          CreatedOn: groupDetail.CreatedDate
          ? setTimeToUTC(new Date(groupDetail.CreatedDate))
          : setTimeToUTC(new Date()),
          ModifiedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          ModifiedOn: setTimeToUTC(new Date()),
        };
        apiSvc.put("SaveGroupsRequest", editedInfo).then((res) => {
          notificationSvc.info("Details Saved");
          if (res.data && res.data.PrimaryKey && recordId <= 0) {
            setRecordId(res.data.PrimaryKey);
            getGroupTradelimit(res.data.PrimaryKey);
            setGroupDetail({ ...groupDetail, Id: res.data.PrimaryKey });
          }
          store.dispatch(
            showEditForm({
              status: false,
              data: { fieldData: 0, fieldName, updatedData: {groupDetail}},
            })
          );
        });
        break;
      case "GroupRisk":
        editedInfo = {
          ...groupDetail,
          CreatedBy: groupDetail.CreatedBy ? groupDetail.CreatedBy : parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          CreatedOn: groupDetail.CreatedDate
          ? setTimeToUTC(new Date(groupDetail.CreatedDate))
          : setTimeToUTC(new Date()),
          ModifiedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          ModifiedOn: setTimeToUTC(new Date()),
        };
        apiSvc.put("SaveGroupsRequest", editedInfo).then((res) => {
          notificationSvc.info("Details Saved");
          if (res.data && res.data.PrimaryKey && recordId <= 0) {
            setRecordId(res.data.PrimaryKey);
            getGroupTradelimit(res.data.PrimaryKey);
            setGroupDetail({ ...groupDetail, Id: res.data.PrimaryKey });
          }
          store.dispatch(
            showEditForm({
              status: false,
              data: { fieldData: 0, fieldName, updatedData: {groupDetail}},
            })
          );
        });
        let groupTradeLimitInfo = {
          ...groupTradeLimit,
          GroupId: recordId,
          CreatedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          CreatedOn: setTimeToUTC(new Date()),
          ModifiedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          ModifiedOn: setTimeToUTC(new Date()),
        };
        apiSvc.put("SaveGroupTradeLimitsRequest", groupTradeLimitInfo);
        store.dispatch(
          showEditForm({
            status: false,
            data: { fieldData: 0, fieldName, updatedData: {groupDetail} },
          })
        );
        break;
      case "AssignAccount":
        newDataForDragNDrop.forEach((itemId) => {
          let payload = {
            AccountId: parseInt(itemId),
            CreatedBy: parseInt(
              auth && auth.authInfo && auth.authInfo.UserId
                ? auth.authInfo?.UserId
                : "0"
            ),
            CreatedOn: setTimeToUTC(new Date()),
            ModifiedBy: parseInt(
              auth && auth.authInfo && auth.authInfo.UserId
                ? auth.authInfo?.UserId
                : "0"
            ),
            ModifiedOn: setTimeToUTC(new Date()),
            GroupId: recordId,
            GroupSupervisorId: 0,
          };
          apiSvc.api.put("SaveAccountGroupsRequest", {
            Data: payload,
            LoadCache: true,
          });
        });
        deletedDataForDragNDrop.forEach((itemId) => {
          apiSvc.api.delete("DeleteAccountGroupsRequest", {
            params: {
              PrimaryKey: parseInt(itemId),
              GroupId: recordId,
              LoadCache: true,
            },
          });
        });
        setNewDataForDragNDrop([]);
        setDeletedDataForDragNDrop([]);
        notificationSvc.info("Details Saved");
        store.dispatch(
          showEditForm({
            status: false,
            data: { fieldData: 0, fieldName, updatedData: {groupDetail} },
          })
        );
        break;
      case "AssignSuperVisor":
        editedInfo = {
          ...groupDetail,
          CreatedBy: groupDetail.CreatedBy ? groupDetail.CreatedBy : parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          CreatedOn: groupDetail.CreatedDate
          ? setTimeToUTC(new Date(groupDetail.CreatedDate))
          : setTimeToUTC(new Date()),
          ModifiedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          ModifiedOn: setTimeToUTC(new Date()),
        };
        apiSvc.put("SaveGroupsRequest", editedInfo).then((res) => {
          notificationSvc.info("Details Saved");
          if (res.data && res.data.PrimaryKey && recordId <= 0) {
            setRecordId(res.data.PrimaryKey);
            getGroupTradelimit(res.data.PrimaryKey);
            setGroupDetail({ ...groupDetail, Id: res.data.PrimaryKey });
          }
          store.dispatch(
            showEditForm({
              status: false,
              data: { fieldData: 0, fieldName, updatedData: {groupDetail}},
            })
          );
        });
        newDataForDragNDrop.forEach((itemId) => {
          let payload = {
            SupervisorId: parseInt(itemId),
            CreatedBy: parseInt(
              auth && auth.authInfo && auth.authInfo.UserId
                ? auth.authInfo?.UserId
                : "0"
            ),
            CreatedOn: setTimeToUTC(new Date()),
            ModifiedBy: parseInt(
              auth && auth.authInfo && auth.authInfo.UserId
                ? auth.authInfo?.UserId
                : "0"
            ),
            ModifiedOn: setTimeToUTC(new Date()),
            GroupId: recordId,
          };
          apiSvc.api.put("SaveGroupSupervisorsRequest", {
            Data: payload,
            LoadCache: true,
          });
        });
        deletedDataForDragNDrop.forEach((itemId) => {
          apiSvc.api.delete("DeleteGroupSupervisorsRequest", {
            params: {
              PrimaryKey: parseInt(itemId),
              GroupId: recordId,
              LoadCache: true,
            },
          });
        });
        setNewDataForDragNDrop([]);
        setDeletedDataForDragNDrop([]);
        notificationSvc.info("Details Saved");
        store.dispatch(
          showEditForm({
            status: false,
            data: { fieldData: 0, fieldName, updatedData: {groupDetail} },
          })
        );
        break;
      default:
        break;
    }
    if (searchResults && searchResults?.groups && searchResults.groups.length) {
      let updatedRecord = searchResults;
      const pos = updatedRecord.groups.findIndex((x) => x.GroupId === recordId);
      if (pos !== -1) {
        updatedRecord.groups[pos] = groupDetail;
      }
      dispatch(updateSearchResults(updatedRecord));
    }
  };

  const handleSaveFirm = () => {
    switch (firmKey) {
      case "firmdetails":
        if (!validateFirmDetails()) {
          break;
        }
        let firmInfo = {
          ...currentFirm,
          CreatedBy: currentFirm.CreatedBy ? currentFirm.CreatedBy : parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          CreatedDate: currentFirm.CreatedDate
            ? setTimeToUTC(new Date(currentFirm.CreatedDate))
            : setTimeToUTC(new Date()),
          ModifiedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          ModifiedOn: setTimeToUTC(new Date()),
        };
        apiSvc.put("SaveFirmsRequest", firmInfo).then((res) => {
          notificationSvc.info("Details Saved");
          store.dispatch(
            showEditForm({
              status: false,
              data: { fieldData: 0, fieldName, updatedData: {currentFirm} },
            })
          );
          if (res.data && res.data.PrimaryKey && recordId <= 0) {
            setRecordId(res.data.PrimaryKey);
            setCurrentFirm({ ...currentFirm, AccountId: res.data.PrimaryKey });
          }
        });
        break;
      case "firmRisk":
        let firmTradeLimitInfo = {
          ...selectedFirmRisk,
          FirmId: recordId,
          CreatedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          CreatedOn: setTimeToUTC(new Date()),
          ModifiedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          ModifiedOn: setTimeToUTC(new Date()),
        };
        apiSvc.put("SaveFirmTradeLimitsRequest", firmTradeLimitInfo);
        store.dispatch(
          showEditForm({
            status: false,
            data: { fieldData: 0, fieldName, updatedData: {currentFirm} },
          })
        );
        break;
      case "AssignAccount":
        let payload = {
          Accounts: newDataForDragNDrop.map(itemId => (parseInt(itemId))),
          CreatedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          CreatedOn: setTimeToUTC(new Date()),
          ModifiedBy: parseInt(
            auth && auth.authInfo && auth.authInfo.UserId
              ? auth.authInfo?.UserId
              : "0"
          ),
          ModifiedOn: setTimeToUTC(new Date()),
          FirmId: recordId,
        };
        apiSvc.api.put("api/UpdateAccountFirmRequest", payload).then(() => {
          setNewDataForDragNDrop([]);
          setDeletedDataForDragNDrop([]);
          notificationSvc.info("Details Saved");
          store.dispatch(
            showEditForm({
              status: false,
              data: { fieldData: 0, fieldName, updatedData: {currentFirm} },
            })
          );
        });
        break;
      case "AssignGroups":
        newDataForDragNDrop.forEach((itemId) => {
          // Add API Call here
        });
        deletedDataForDragNDrop.forEach((itemId) => {
          //Add API call here
        });
        setNewDataForDragNDrop([]);
        setDeletedDataForDragNDrop([]);
        notificationSvc.info("Details Saved");
        store.dispatch(
          showEditForm({
            status: false,
            data: { fieldData: 0, fieldName, updatedData: {currentFirm} },
          })
        );
        break;
      default:
        break;
    }
    if (searchResults && searchResults?.firms && searchResults.firms.length) {
      let updatedRecord = searchResults;
      const pos = updatedRecord.firms.findIndex((x) => x.FirmId === recordId);
      if (pos !== -1) {
        updatedRecord.firms[pos] = currentFirm;
      }
      dispatch(updateSearchResults(updatedRecord));
    }
  };

  const AccountForm = (
    <div>
      <Tabs
        id="controlled-tab-example"
        defaultActiveKey={key}
        onSelect={(k: any) => {
          setKey(k);
        }}
        className="mb-3"
      >
        <Tab eventKey="AccountDetails" title="Account Details">
          <div className="row mx-0 px-3">
            <div className="col-12 px-0 mb-3 mb-md-0">
              <form style={{ overflowY: "scroll", maxHeight: "500px" }}>
              <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputCopy"
                    className="col-3 px-0 form-label"
                  >
                    Copy From
                  </label>
                  <div className="col-9 d-flex align-items-center field pr-4">
                    <select
                      className="form-control fc-field form-select mr-2"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        getUserAccountInfo(e.target.value, true);
                        setUsersByAccount([]);
                      }}
                    >
                      <option value={""}>---</option>
                      {accounts && accounts.length ? (
                        accounts.map((account, index) => {
                          return (
                            <option key={index} value={account?.AccountId}>
                              {account?.Name}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputId"
                    className="col-3 px-0 form-label"
                  >
                    Account Name
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputId"
                      id="exampleInputId"
                      aria-describedby="AccountID"
                      value={accountForm?.Name}
                      onChange={(e) => {
                        setAccountForm({
                          ...accountForm,
                          Name: e.target.value.slice(0, 25),
                        });
                      }}
                      required
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputGroup"
                    className="col-3 px-0 form-label"
                  >
                    Assigned Groups
                  </label>
                  <div className="col-9 d-flex align-items-center field pr-4">
                    <select
                      className="form-control fc-field form-select mr-2"
                      aria-label=".form-select-lg example"
                    >
                      {groupsByAccount && groupsByAccount.length ? (
                        groupsByAccount.map((group, index) => {
                          return (
                            <option key={index} value={group.Group.GroupId}>
                              {group.Group.Name}
                            </option>
                          );
                        })
                      ) : (
                        <option value={""}>---</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputGroup"
                    className="col-3 px-0 form-label"
                  >
                    Assigned Users
                  </label>
                  <div className="col-9 d-flex align-items-center field pr-4">
                    <select
                      className="form-control fc-field form-select custom-select-form mr-2"
                      aria-label=".form-select-lg example"
                      multiple
                    >
                      {usersByAccount && usersByAccount.length ? (
                        usersByAccount.map((user, index) => {
                          return (
                            <option key={index} value={user.UserId} selected onDoubleClick={() => editUser(user.UserId, user.User.Email)}>
                              {user.User.UserName}
                            </option>
                          );
                        })
                      ) : (
                        <option value={""}>---</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputStatus"
                    className="col-3 px-0 form-label"
                  >
                    Status
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        setAccountForm({
                          ...accountForm,
                          AccountStatusID: parseFloat(e.target.value),
                          AccountStatus: {
                            Status:
                              parseInt(e.target.value) === 1
                                ? "Enabled"
                                : parseInt(e.target.value) === 3
                                ? "Disabled"
                                : "Close Position Only",
                          },
                        });
                      }}
                    >
                      <option value={""}>---</option>
                      <option
                        value={1}
                        selected={accountForm?.AccountStatusID === 1}
                      >
                        Enabled
                      </option>
                      <option
                        value={3}
                        selected={accountForm?.AccountStatusID === 3}
                      >
                        Disabled
                      </option>
                      <option
                        value={2}
                        selected={accountForm?.AccountStatusID === 2}
                      >
                        Close Position Only
                      </option>
                    </select>
                    <span className="text-danger my-0 py-0">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputType"
                    className="col-3 px-0 form-label"
                  >
                    Type
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        setAccountForm({
                          ...accountForm,
                          AccountType: e.target.value,
                        });
                      }}
                    >
                      <option value={""}>---</option>
                      <option
                        value="Stock"
                        selected={accountForm?.AccountType === "Stock"}
                      >
                        Stock
                      </option>
                      <option
                        value="Crypto"
                        selected={accountForm?.AccountType === "Crypto"}
                      >
                        Crypto
                      </option>
                      <option
                        value="Future"
                        selected={accountForm?.AccountType === "Future"}
                      >
                        Future
                      </option>
                      <option
                        value="Forex"
                        selected={accountForm?.AccountType === "Forex"}
                      >
                        Forex
                      </option>
                      <option
                        value="Options"
                        selected={accountForm?.AccountType === "Options"}
                      >
                        Options
                      </option>
                    </select>
                    <span className="text-danger my-0 py-0">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputCurrency"
                    className="col-3 px-0 form-label"
                  >
                    Main Currency
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        {
                          setAccountForm({
                            ...accountForm,
                            MainCurrencyID: parseInt(e.target.value),
                            Currency: allCurrencies.filter(
                              (x) => x.CurrencyID === parseInt(e.target.value)
                            )[0],
                          });
                        }
                      }}
                    >
                      <option value={""}>---</option>
                      <option
                        value={3}
                        selected={accountForm?.MainCurrencyID === 3}
                      >
                        EUR
                      </option>
                      <option
                        value={1}
                        selected={accountForm?.MainCurrencyID === 1}
                      >
                        USD
                      </option>
                      <option
                        value={5}
                        selected={accountForm?.MainCurrencyID === 5}
                      >
                        CAD
                      </option>
                      <option
                        value={4}
                        selected={accountForm?.MainCurrencyID === 4}
                      >
                        JPY
                      </option>
                    </select>
                    <span className="text-danger my-0 py-0">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputFirms"
                    className="col-3 px-0 form-label"
                  >
                    Firm
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        {
                          setAccountForm({
                            ...accountForm,
                            FirmId: parseFloat(e.target.value),
                            Firm: firmList.filter(
                              (x) => x.FirmId === parseFloat(e.target.value)
                            )[0],
                          });
                        }
                      }}
                    >
                      <option value={""}>---</option>
                      {firmList && firmList.length ? (
                        firmList.map((firm, index) => {
                          return (
                            <option
                              key={index}
                              value={firm.FirmId}
                              selected={firm.FirmId === accountForm?.FirmId}
                            >
                              {firm.Name}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputGroup"
                    className="col-3 px-0 form-label"
                  >
                    Group
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        {
                          setAccountForm({
                            ...accountForm,
                            GroupId: e.target.value,
                          });
                        }
                      }}
                    >
                      <option value={""}>---</option>
                      {groupList && Object.values(groupList).length ? (
                        Object.values(groupList).map((group, index) => {
                          return (
                            <option
                              key={index}
                              selected={group.GroupId === accountForm?.GroupId}
                              value={group.GroupId}
                            >
                              {group.Name}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                    <span className="text-danger my-0 py-0">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputBorrow"
                    className="col-3 px-0 form-label"
                  >
                    Easy to Borrow List
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        {
                          setAccountForm({
                            ...accountForm,
                            BorrowListID: e.target.value,
                          });
                        }
                      }}
                    >
                      <option value={""}>---</option>
                      {allborrowList.length ? (
                        allborrowList.map((borrowerItem, index) => {
                          return (
                            <option
                              key={index}
                              value={borrowerItem.BorrowListID}
                              selected={
                                borrowerItem.BorrowListID ===
                                accountForm?.BorrowListID
                              }
                            >
                              {borrowerItem.Name}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                    <span className="text-danger my-0 py-0">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputFees"
                    className="col-3 px-0 form-label"
                  >
                    Fees
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        {
                          setAccountForm({
                            ...accountForm,
                            FeeID: e.target.value,
                          });
                        }
                      }}
                    >
                      <option value={""}>---</option>
                      {Object.values(allFees).length ? (
                        Object.values(allFees).map((feeItem, index) => {
                          return (
                            <option
                              key={index}
                              value={feeItem.FeeId}
                              selected={feeItem.FeeId === accountForm?.FeeID}
                            >
                              {feeItem.Name}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                    <span className="text-danger my-0 py-0">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputBalance"
                    className="col-3 px-0 form-label"
                  >
                    Balance
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputBalance"
                      id="exampleInputBalance"
                      aria-describedby="textBalance"
                      value={accountForm?.Balance}
                      onChange={(e) => {
                        {
                          setAccountForm({
                            ...accountForm,
                            Balance: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                          });
                        }
                      }}
                      required
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputMaxDraw"
                    className="col-3 px-0 form-label"
                  >
                    MaxDrawDown
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputMaxDraw"
                      id="exampleInputMaxDraw"
                      aria-describedby="emailMaxDraw"
                      value={accountForm?.MaxDrawDown}
                      onChange={(e) => {
                        {
                          setAccountForm({
                            ...accountForm,
                            MaxDrawDown: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1')
                          });
                        }
                      }}
                      required
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputMaxDraw"
                    className="col-3 px-0 form-label"
                  >
                    Resets
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputMaxDraw"
                      id="exampleInputMaxDraw"
                      aria-describedby="emailMaxDraw"
                      value={accountForm?.Resets}
                      onChange={(e) => {
                        {
                          setAccountForm({
                            ...accountForm,
                            Resets: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1')
                          });
                        }
                      }}
                      required
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputMaxDraw"
                    className="col-3 px-0 form-label"
                  >
                    CR Ratio
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputMaxDraw"
                      id="exampleInputMaxDraw"
                      aria-describedby="emailMaxDraw"
                      value={accountForm?.CrRatio}
                      onChange={(e) => {
                        {
                          setAccountForm({
                            ...accountForm,
                            CrRatio: e.target.value.replace(/^(-?\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1')
                          });
                        }
                      }}
                      required
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputApprove"
                    className="col-3 px-0 form-label"
                  >
                    Dealer Approval Required
                  </label>
                  <div className="col-9 d-flex align-items-center field">
                    <input
                      type="checkbox"
                      checked={accountForm?.ApprovalWhenSendingOrder}
                      onChange={(e) => {
                        setAccountForm({
                          ...accountForm,
                          ApprovalWhenSendingOrder: e.target.checked,
                        });
                      }}
                      ref={(input) => {
                        if (input) {
                          input.indeterminate =
                            !accountForm?.ApprovalWhenSendingOrder
                              ? true
                              : false;
                        }
                      }}
                      required
                    />
                  </div>
                </div>
                <span className="col-12 px-0 text-danger mt-2 py-0 requird-field">
                  *Required Fields
                </span>
              </form>
              <div className="d-flex justify-content-center mt-4 mb-2">
                <button
                  type="button"
                  className="btn btn-form dis-button"
                  style={{ width: "100px" }}
                  onClick={handleSaveAccount}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-form"
                  data-bs-dismiss="modal"
                  style={{ width: "100px" }}
                  onClick={() => {
                    store.dispatch(
                      showEditForm({
                        status: false,
                        data: { fieldData: 0, fieldName },
                      })
                    );
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          disabled={recordId <= 0}
          eventKey="AccountRisk"
          title="Account Risk"
        >
          <div className="row mx-0 px-0">
            <div className="col-12 px-0 mb-3 mb-md-0">
              <form style={{ overflowY: "scroll", maxHeight: "500px" }}>
                <div className="row col-12 mb-2 mx-0 pl-4 pr-5 align-items-center">
                  <label
                    htmlFor="exampleInputAccount"
                    className="col-3 px-0 form-label"
                  >
                    Account
                  </label>
                  <div className="col-7 d-flex field">
                    <input
                      type="Text"
                      className="form-control fc-field"
                      name="exampleInputAccount"
                      id="exampleInputAccount"
                      aria-describedby="textAccount"
                      value={accountForm?.Name}
                      disabled
                    />
                  </div>
                </div>
                <div className="row col-12 mb-1 pl-4 mx-0 align-items-center">
                  <label
                    htmlFor="exampleInputEmailaddress"
                    className="px-0 form-label text-light"
                  >
                    Pick one of three:
                  </label>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label
                    htmlFor="exampleMtrading"
                    className="col-3 px-0 form-label"
                  >
                    i.Margin Trading
                  </label>
                  <div className="col-auto pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="Enable margin"
                      id="Emargin"
                      className="my-1"
                      checked={accountTradeLimitData?.EnMarginCtrl}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnMarginCtrl: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">Enable Margin</span>
                  </div>
                  <div className="col-auto pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="Net Equity"
                      id="Nequity"
                      className="my-1"
                      disabled
                    />{" "}
                    <span className="checklable">From Net Equity</span>
                  </div>
                  <div className="col-auto pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="Cash Balance"
                      id="Cbalance"
                      disabled
                      className="my-1"
                    />{" "}
                    <span className="checklable">From Cash Balance</span>
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label
                    htmlFor="exampleLeverage"
                    className="col-3 px-0 form-label"
                  >
                    ii.Leverage
                  </label>
                  <div className="col-3 pr-0">
                    <input
                      type="checkbox"
                      name="Enable Leverage"
                      disabled
                      id="Eleverage"
                      className="my-1"
                    />{" "}
                    <span className="checklable">Enable Leverage</span>
                  </div>
                  <div className="col-6 pl-5">
                    <input
                      type="Text"
                      className="form-control fc-field text-right"
                      name="exampleInputLeverage"
                      id="exampleInputLeverage"
                      aria-describedby="textLeverage"
                      value="0"
                      disabled
                    />
                  </div>
                </div>

                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-9 pr-0">
                    <input
                      type="checkbox"
                      name="Based on Net Equity"
                      id="BNet"
                      className="my-1"
                      disabled
                    />{" "}
                    <span className="checklable">Based on Net Equity</span>
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-9 pr-0">
                    <input
                      type="checkbox"
                      name="Based on Net Equity"
                      id="BNet"
                      checked={accountTradeLimitData?.LinkWithNetEquity}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          LinkWithNetEquity: e.target.checked,
                        });
                      }}
                      className="my-1"
                    />{" "}
                    <span className="checklable">Cash Balance</span>
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label
                    htmlFor="exampleBuying"
                    className="col-3 px-0 form-label"
                  >
                    iii.Buying Power
                  </label>
                  <div className="col-4 pr-0">
                    <input
                      type="checkbox"
                      name="Enable Buying Power"
                      checked={accountTradeLimitData?.EnBpCtrl}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnBpCtrl: e.target.checked,
                        });
                      }}
                      id="EBuyingPower"
                      className="my-1"
                    />{" "}
                    <span className="checklable">Enable Buying Power</span>
                  </div>
                  <div className="col-5 pl-2">
                    <input
                      type="text"
                      className="form-control fc-field text-right"
                      name="exampleInputBuying"
                      id="exampleInputBuying"
                      aria-describedby="textBuying"
                      value={accountTradeLimitData?.BuyingPower}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          BuyingPower: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1')
                        });
                      }}
                      disabled={!accountTradeLimitData?.EnBpCtrl}
                    />
                  </div>
                </div>

                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-9 pr-0">
                    <input
                      type="checkbox"
                      name="Enable Pre Post Market"
                      id="Epre"
                      className="my-1"
                      checked={accountTradeLimitData?.EnPrePostMkt}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnPrePostMkt: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable disable">
                      Enable Pre Post Market
                    </span>
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-9 pr-0">
                    <input
                      type="checkbox"
                      name="Enable pnl"
                      id="Epnl"
                      className="my-1"
                      checked={accountTradeLimitData?.EnUrlzLinkToBp}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnUrlzLinkToBp: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">
                      Enable unreal PNL link with BP
                    </span>
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-9 pr-0">
                    <input
                      type="checkbox"
                      name="Enable bp order"
                      id="Eorder"
                      className="my-1"
                      checked={accountTradeLimitData?.EnOpenOrderBp}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnOpenOrderBp: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">
                      Enable open order BP Control "Count Open Order BP Used"
                    </span>
                  </div>
                </div>

                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label
                    htmlFor="exampleBuying"
                    className="col-3 px-0 form-label"
                  ></label>
                  <div className="col-6 pr-0">
                    <input
                      type="checkbox"
                      name="Variable Buying Power"
                      id="VarBuyingPower"
                      className="my-1"
                      checked={accountTradeLimitData?.EnVarOpenOrderBp}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnVarOpenOrderBp: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">
                      Variable Buying Power "open order"
                    </span>
                  </div>
                  <div className="col-3 pl-0">
                    <input
                      type="text"
                      className="form-control fc-field text-right"
                      name="exampleInputVarBuying"
                      id="exampleInputVarBuying"
                      aria-describedby="textVarBuying"
                      value={accountTradeLimitData?.OpenOrderBp}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          OpenOrderBp: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                      disabled={!accountTradeLimitData?.EnVarOpenOrderBp}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 pl-0 form-label">Max loss</label>
                  <div className="col-5 my-2 pl-5">
                    <input
                      type="text"
                      className="form-control fc-field text-right"
                      name="exampleInputVarPowerBuying"
                      id="exampleInputVarPowerBuying"
                      aria-describedby="textVarPowerBuying"
                      value={accountTradeLimitData?.MaxLoss}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          MaxLoss: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-auto pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="Enable pnl"
                      className="my-1"
                      checked={accountTradeLimitData?.EnUrlzLinkToMaxLoss}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnUrlzLinkToMaxLoss: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">
                      Enable OpenPL link with Max Loss
                    </span>
                  </div>
                  <div className="col-auto pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="Enable pnl"
                      className="my-1"
                      checked={accountTradeLimitData?.EnFlatOnMaxLoss}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnFlatOnMaxLoss: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">Enable Flats On Max Loss</span>
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0 pl-3 align-items-center">
                  <div className="col-4 px-0 form-lable"></div>
                  <label className="col-3 px-0 form-label">
                    Max Loss Action
                  </label>
                  <div className="col-5 pl-5">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          maxLossActionId: e.target.value,
                        });
                      }}
                    >
                      <option
                        value={2}
                        selected={accountTradeLimitData?.maxLossActionId === 2}
                      >
                        Close Position Only
                      </option>
                      <option
                        value={0}
                        selected={accountTradeLimitData?.maxLossActionId === 1}
                      >
                        Disabled
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label">Orders</label>
                  <div className="col-5 pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="Enable pnl"
                      className="my-1"
                      checked={accountTradeLimitData?.EnMaxPosition}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnMaxPosition: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">Account Max Position</span>
                  </div>
                  <div className="col-4 pl-4">
                    <input
                      type="text"
                      className="form-control fc-field text-right"
                      name="exampleInputVarPowerBuying"
                      id="exampleInputVarPowerBuying"
                      aria-describedby="textVarPowerBuying"
                      value={accountTradeLimitData?.MaxPosition}
                      disabled={!accountTradeLimitData?.EnMaxPosition}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          MaxPosition: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-5 pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="Enable pnl"
                      className="my-1"
                      checked={accountTradeLimitData?.EnMaxOpenOrder}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnMaxOpenOrder: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">Max Open Orders</span>
                  </div>
                  <div className="col-4 pl-4">
                    <input
                      type="text"
                      className="form-control fc-field text-right"
                      name="exampleInputVarPowerBuying"
                      aria-describedby="textVarPowerBuying"
                      value={accountTradeLimitData?.MaxOpenOrderCount}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          MaxOpenOrderCount: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                      disabled={!accountTradeLimitData?.EnMaxOpenOrder}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-5 pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="my-1"
                      checked={accountTradeLimitData?.EnShareLimit}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnShareLimit: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">Max Open Shares</span>
                  </div>
                  <div className="col-4 pl-4">
                    <input
                      type="text"
                      className="form-control fc-field text-right"
                      aria-describedby="textVarPowerBuying"
                      value={accountTradeLimitData?.SharesLimit}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          SharesLimit: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1')
                        });
                      }}
                      disabled={!accountTradeLimitData?.EnShareLimit}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-5 pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="Enable pnl"
                      className="my-1"
                      checked={accountTradeLimitData?.EnMaxOrdVal}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnMaxOrdVal: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">Max Orders Value</span>
                  </div>
                  <div className="col-4 pl-4">
                    <input
                      type="text"
                      className="form-control fc-field text-right"
                      value={accountTradeLimitData?.MaxOrderValue}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          MaxOrderValue: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1')
                        });
                      }}
                      disabled={!accountTradeLimitData?.EnMaxOrdVal}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-5 pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="my-1"
                      checked={accountTradeLimitData?.EnMinShareLimit}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnMinShareLimit: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">Min Shares/Orders</span>
                  </div>
                  <div className="col-4 pl-4">
                    <input
                      type="text"
                      className="form-control fc-field text-right"
                      value={accountTradeLimitData?.MinSharesLimit}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          MinSharesLimit: handleNumericValue(
                            e,
                            accountTradeLimitData?.MinSharesLimit
                          ),
                        });
                      }}
                      disabled={!accountTradeLimitData?.EnMinShareLimit}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-5 pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="Enable pnl"
                      id="Epnl"
                      className="my-1"
                      checked={accountTradeLimitData?.EnShareLimit}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnShareLimit: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">Max Shares/Orders</span>
                  </div>
                  <div className="col-4 pl-4">
                    <input
                      type="text"
                      className="form-control fc-field text-right"
                      name="exampleInputVarPowerBuying"
                      id="exampleInputVarPowerBuying"
                      aria-describedby="textVarPowerBuying"
                      value={accountTradeLimitData?.SharesLimit}
                      disabled={!accountTradeLimitData?.EnShareLimit}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          SharesLimit: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-5 pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="my-1"
                      checked={accountTradeLimitData?.EnMinSymPrice}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnMinSymPrice: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">Min Symbol Prices</span>
                  </div>
                  <div className="col-4 pl-4">
                    <input
                      type="text"
                      className="form-control fc-field text-right"
                      name="exampleInputVarPowerBuying"
                      id="exampleInputVarPowerBuying"
                      aria-describedby="textVarPowerBuying"
                      value={accountTradeLimitData?.MinSymbolPrice}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          MinSymbolPrice: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                      disabled={!accountTradeLimitData?.EnMinSymPrice}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-5 pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="my-1"
                      checked={accountTradeLimitData?.EnMaxSymPrice}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnMaxSymPrice: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">Max Symbol Prices</span>
                  </div>
                  <div className="col-4 pl-4">
                    <input
                      type="text"
                      className="form-control fc-field text-right"
                      name="exampleInputVarPowerBuying"
                      id="exampleInputVarPowerBuying"
                      aria-describedby="textVarPowerBuying"
                      value={accountTradeLimitData?.MaxSymbolPrice}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          MaxSymbolPrice: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                      disabled={!accountTradeLimitData?.EnMaxSymPrice}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-2 mx-0  pl-4 align-items-center">
                  <label className="col-3 px-0 form-label"></label>
                  <div className="col-5 pr-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="my-1"
                      checked={accountTradeLimitData?.EnSymbolRiskCtrl}
                      onChange={(e) => {
                        setAccountTradeLimitData({
                          ...accountTradeLimitData,
                          EnSymbolRiskCtrl: e.target.checked,
                        });
                      }}
                    />{" "}
                    <span className="checklable">
                      Enable Symbol Control Prices
                    </span>
                  </div>
                </div>
              </form>
              <div className="d-flex justify-content-center mt-4">
                <button
                  type="button"
                  className="btn btn-form dis-button"
                  style={{ width: "100px" }}
                  onClick={handleSaveAccount}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-form"
                  data-bs-dismiss="modal"
                  style={{ width: "100px" }}
                  onClick={() => {
                    store.dispatch(
                      showEditForm({
                        status: false,
                        data: { fieldData: 0, fieldName },
                      })
                    );
                  }}
                >
                  Cancel
                </button>
              </div>{" "}
            </div>
          </div>
        </Tab>
        <Tab
          disabled={recordId <= 0}
          eventKey="SymbolLimit"
          title="Symbol Limit"
        >
          <div className="row mx-0 px-0">
            <div className="col-12 px-0 mb-3 mb-md-0">
              <form>
                <div className="row col-12 d-flex justify-content-end mb-2 ml-3 pl-5 pr-3 align-items-center">
                  <label
                    htmlFor="exampleInputAccount"
                    className="col-2 px-0 form-label"
                  >
                    Account
                  </label>
                  <div className="col-9 d-flex field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputAccount"
                      aria-describedby="Account"
                      value={accountForm?.Name}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row col-12 d-flex justify-content-end mb-2 ml-3 pl-5 pr-3 align-items-center">
                  <label
                    htmlFor="exampleInputSymbol"
                    className="col-2 px-0 form-label"
                  >
                    Symbol
                  </label>
                  <div className="col-9 d-flex field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => setSymbolTradeLimits({...symbolTradeLimit, SymbolId: Number(e.target.value)})}
                    >
                      {symbolList.length ? (
                        symbolList.map((symbol, index) => {
                          return (
                            <option
                              key={index}
                              value={symbol.Id}
                              selected={
                                symbolTradeLimit?.SymbolId === symbol.Id
                              }
                            >
                              {symbol.Symbol_}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <input
                  type="checkbox"
                  name="max position"
                  id="maxPosition"
                  className="col-2 my-1 d-flex justify-content-center align-items-center"
                  checked={symbolTradeLimit?.EnSymMaxPos}
                  onChange={(e) => {
                    setSymbolTradeLimits({
                      ...symbolTradeLimit,
                      EnSymMaxPos: e.target.checked,
                    });
                  }}
                />
                <label
                  htmlFor="examplemaxPosition"
                  className="col-4 form-label d-flex justify-content-end align-items-center"
                >
                  Max Position
                </label>
                <div
                  className="col-6 d-flex field"
                  style={{ paddingLeft: "10px" }}
                >
                  <input
                    type="number"
                    className="form-control fc-field"
                    value={symbolTradeLimit?.MaxPosition}
                    onChange={(e) => {
                      setSymbolTradeLimits({
                        ...symbolTradeLimit,
                        MaxPosition: handleNumericValue(
                          e,
                          symbolTradeLimit?.MaxPosition
                        ),
                      });
                    }}
                    name="examplemaxPosition"
                    id="examplemaxPosition"
                    aria-describedby="maxPosition"
                    disabled={!symbolTradeLimit?.EnSymMaxPos}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <input
                  type="checkbox"
                  name="max long position"
                  id="maxlongPosition"
                  className="col-2 my-1 d-flex justify-content-center align-items-center"
                  checked={symbolTradeLimit?.EnMaxLongPosVal}
                  onChange={(e) => {
                    setSymbolTradeLimits({
                      ...symbolTradeLimit,
                      EnMaxLongPosVal: e.target.checked,
                    });
                  }}
                />
                <label
                  htmlFor="examplemaxlongPosition"
                  className="col-4 form-label d-flex justify-content-end align-items-center"
                >
                  Max Long Position
                </label>
                <div
                  className="col-6 d-flex field"
                  style={{ paddingLeft: "10px" }}
                >
                  <input
                    type="text"
                    className="form-control fc-field"
                    value={symbolTradeLimit?.BuyValueLimit}
                    onChange={(e) => {
                      setSymbolTradeLimits({
                        ...symbolTradeLimit,
                        BuyValueLimit: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1')
                      });
                    }}
                    disabled={!symbolTradeLimit?.EnMaxLongPosVal}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <input
                  type="checkbox"
                  name="max short position"
                  id="maxShortPostion"
                  className="col-2 my-1 d-flex justify-content-center align-items-center"
                  checked={symbolTradeLimit?.EnMaxShortPosVal}
                  onChange={(e) => {
                    setSymbolTradeLimits({
                      ...symbolTradeLimit,
                      EnMaxShortPosVal: e.target.checked,
                    });
                  }}
                />
                <label
                  htmlFor="examplemaxShortPostion"
                  className="col-4 form-label d-flex justify-content-end align-items-center"
                >
                  Max Short Position
                </label>
                <div
                  className="col-6 d-flex field"
                  style={{ paddingLeft: "10px" }}
                >
                  <input
                    type="text"
                    className="form-control fc-field"
                    name="examplemaxShortPostion"
                    id="examplemaxShortPostion"
                    aria-describedby="maxShortPostion"
                    value={symbolTradeLimit?.SellValueLimit}
                    onChange={(e) => {
                      setSymbolTradeLimits({
                        ...symbolTradeLimit,
                        SellValueLimit: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                      });
                    }}
                    disabled={!symbolTradeLimit?.EnMaxShortPosVal}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <input
                  type="checkbox"
                  className="col-2 my-1 d-flex justify-content-center align-items-center"
                  checked={symbolTradeLimit?.EnMaxOrdQtyLimit}
                  onChange={(e) => {
                    setSymbolTradeLimits({
                      ...symbolTradeLimit,
                      EnMaxOrdQtyLimit: e.target.checked,
                    });
                  }}
                />
                <label
                  htmlFor="examplemaxQty"
                  className="col-4 form-label d-flex justify-content-end align-items-center"
                >
                  Max Qty / Order
                </label>
                <div
                  className="col-6 d-flex field"
                  style={{ paddingLeft: "10px" }}
                >
                  <input
                    type="text"
                    className="form-control fc-field"
                    name="examplemaxQty"
                    id="examplemaxQty"
                    aria-describedby="maxQty"
                    value={symbolTradeLimit?.MaxOrderQty}
                    onChange={(e) => {
                      setSymbolTradeLimits({
                        ...symbolTradeLimit,
                        MaxOrderQty: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                      });
                    }}
                    disabled={!symbolTradeLimit?.EnMaxOrdQtyLimit}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <input
                  type="checkbox"
                  name="max Qty/order"
                  id="maxQty"
                  className="col-2 my-1 d-flex justify-content-center align-items-center"
                  checked={symbolTradeLimit?.EnMaxOrdValLimit}
                  onChange={(e) => {
                    setSymbolTradeLimits({
                      ...symbolTradeLimit,
                      EnMaxOrdValLimit: e.target.checked,
                    });
                  }}
                />
                <label
                  htmlFor="examplemaxQty"
                  className="col-4 pl-0 form-label d-flex justify-content-end align-items-center"
                  style={{ fontSize: ".86rem" }}
                >
                  Max Dollar Value / Order
                </label>
                <div
                  className="col-6 d-flex field"
                  style={{ paddingLeft: "10px" }}
                >
                  <input
                    type="text"
                    className="form-control fc-field"
                    name="examplemaxQty"
                    id="examplemaxQty"
                    aria-describedby="maxQty"
                    value={symbolTradeLimit?.MaxOrderValue}
                    onChange={(e) => {
                      setSymbolTradeLimits({
                        ...symbolTradeLimit,
                        MaxOrderValue: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                      });
                    }}
                    disabled={!symbolTradeLimit?.EnMaxOrdValLimit}
                  />
                </div>
              </div>
            </form>
            <div className="d-flex justify-content-center mt-4">
              <button
                type="button"
                className="btn btn-form dis-button"
                style={{ width: "100px" }}
                onClick={handleSaveAccount}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-form"
                data-bs-dismiss="modal"
                style={{ width: "100px" }}
                onClick={() => {
                  store.dispatch(
                    showEditForm({
                      status: false,
                      data: { fieldData: 0, fieldName },
                    })
                  );
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Tab>
        <Tab
          disabled={recordId <= 0}
          eventKey="AccountDestination"
          title="Destination"
        >
          <div className="row mx-0 px-0">
            <form name="selection" method="post" className="w-100">
              <div className="row mx-0">
                <div className="col-12 d-flex">
                  <div className="col-5 px-0 px-sm-2 mb-2">
                    <div className="heading text-center">
                      <h6>Destinations</h6>
                    </div>
                    <div className="box table-responsive">
                      <table
                        className="table table-borderless destable firsttable"
                        aria-multiselectable="true"
                        id="from"
                      >
                        <thead>
                          <tr className="row1">
                            <th
                              scope="col"
                              style={{ padding: "0.15rem 0.5rem" }}
                            >
                              <input
                                type="checkbox"
                                name="turoo"
                                id="row_check"
                                checked={leftRow}
                                onChange={() => {
                                  setLeftRow(!leftRow);
                                  jquery("#from")
                                    .find("input[type=checkbox]")
                                    .each(function (this) {
                                      this.checked = !leftRow;
                                    });
                                }}
                              />
                            </th>
                            <th scope="col">Broker</th>
                            <th scope="col" className="head1">
                              Strategy
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {allDestinations.length ? (
                            allDestinations.map((destination, index) => {
                              const indexOfDestination =
                                selectedDestinations.findIndex(
                                  (dest) =>
                                    dest.DestinationId ===
                                    destination.DestinationId
                                );
                              if (indexOfDestination === -1) {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        name="turoo"
                                        id="row_check"
                                      />
                                    </td>
                                    <td data-id={destination?.DestinationId}>
                                      {destination?.Broker?.BrokerName}
                                    </td>
                                    <td data-id={destination?.DestinationId}>
                                      {destination?.Strategy?.Name}
                                    </td>
                                    <td
                                      className="d-none pl-0"
                                      data-id={"DisplayName"}
                                    >
                                      <input
                                        type="text"
                                        className="form-control fc-field"
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-2 d-flex align-items-center">
                    <div className=" d-flex justify-content-center align-items-center px-auto text-center d-none d-md-block">
                      <a className="btn btn-form btn-drag-drop mb-1 rtol">&#60;</a>
                      <a className="btn btn-form btn-drag-drop my-1 ltor">&#62;</a>
                    </div>
                    <div className=" d-flex justify-content-center align-items-center px-0 text-center d-block d-md-none">
                      <a className="btn btn-form me-1 btn-drag-drop ltor">&#60;</a>
                      <a className="btn btn-form mx-1 btn-drag-drop rtol">&#62;</a>
                    </div>
                  </div>
                  <div className="col-5 px-0 px-sm-2 mb-2">
                    <div className="heading text-center">
                      <h6>Assigned</h6>
                    </div>
                    <div className="box table-responsive">
                      <table
                        className="table table-borderless destable secondtable"
                        aria-multiselectable="true"
                        id="to"
                      >
                        <thead>
                          <tr className="row1">
                            <th
                              scope="col"
                              style={{ padding: "0.15rem 0.5rem" }}
                            >
                              <input
                                type="checkbox"
                                name="turoo"
                                id="row_check"
                                checked={rightRow}
                                onChange={() => {
                                  setRightRow(!rightRow);
                                  jquery("#to")
                                    .find("input[type=checkbox]")
                                    .each(function (this) {
                                      this.checked = !rightRow;
                                    });
                                }}
                              />
                            </th>
                            <th scope="col">Broker</th>
                            <th scope="col" className="head1">
                              Strategy
                            </th>
                            <th scope="col">Display Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedDestinations.length ? (
                            selectedDestinations.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      name="turoo"
                                      id="row_check"
                                    />
                                  </td>
                                  <td data-id={item?.DestinationId}>
                                    {getBrokerName(item?.Destination?.BrokerId)}
                                  </td>
                                  <td data-id={item?.DestinationId}>
                                    {getStrategyName(
                                      item?.Destination?.StrategyId
                                    )}
                                  </td>
                                  <td className="pl-0" data-id={"DisplayName"}>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control fc-field"
                                      value={item?.DisplayName}
                                    />{" "}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 d-flex justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn btn-form dis-button"
              style={{ width: "100px" }}
              onClick={handleSaveAccount}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-form"
              data-bs-dismiss="modal"
              style={{ width: "100px" }}
              onClick={() => {
                store.dispatch(
                  showEditForm({
                    status: false,
                    data: { fieldData: 0, fieldName },
                  })
                );
              }}
            >
              Cancel
            </button>
          </div>
        </Tab>
      </Tabs>
    </div>
  );

  const UserForm = (
    <div>
      <Tabs
        id="controlled-tab-example"
        defaultActiveKey={userKey}
        onSelect={(k: any) => setUserKey(k)}
        className="mb-3"
      >
        <Tab eventKey="Userdetail" title="User Details">
          <div
            className={`row mx-0 px-3 ${
              currentUser && currentUser.RoleId && currentUser.RoleId < roleId
                ? "event-none"
                : ""
            }`}
          >
            <div className="col-6 px-0 mb-3 mb-md-0">
              <form>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputUser"
                    className="col-4 px-0 form-label"
                  >
                    User
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      value={currentUser?.UserName}
                      aria-describedby="AccountUser"
                      onChange={(e) => {
                        setCurrentUser({
                          ...currentUser,
                          UserName: e.target.value.trim(),
                          Email: e.target.value.trim(),
                        });
                      }}
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputEmail"
                    className="col-4 px-0 form-label"
                  >
                    Email
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="Email"
                      className="form-control fc-field"
                      value={currentUser?.Email}
                      aria-describedby="AccountEmail"
                      onChange={(e) => {
                        setCurrentUser({
                          ...currentUser,
                          Email: e.target.value.trim(),
                          UserName: e.target.value.trim(),
                        });
                      }}
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputFirst"
                    className="col-4 px-0 form-label"
                  >
                    First Name
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      value={currentUser?.FirstName}
                      aria-describedby="AccountFirst"
                      onChange={(e) => {
                        setCurrentUser({
                          ...currentUser,
                          FirstName: e.target.value,
                          FullName: `${e.target.value} ${currentUser?.LastName}`,
                        });
                      }}
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputLast"
                    className="col-4 px-0 form-label"
                  >
                    Last Name
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      value={currentUser?.LastName}
                      autoComplete="off"
                      aria-describedby="AccountLast"
                      onChange={(e) => {
                        setCurrentUser({
                          ...currentUser,
                          LastName: e.target.value,
                          FullName: `${currentUser?.FirstName} ${e.target.value}`,
                        });
                      }}
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputPassword"
                    className="col-4 px-0 form-label"
                  >
                    Password
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="password"
                      autoComplete="new-password"
                      className="form-control fc-field"
                      aria-describedby="AccountPassword"
                      value={currentUser?.Password}
                      onChange={(e) => {
                        setCurrentUser({
                          ...currentUser,
                          Password: e.target.value,
                        });
                      }}
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputDate"
                    className="col-4 px-0 form-label"
                  >
                    Expires
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="date"
                      className="form-control fc-field"
                      name="exampleInputDate"
                      id="exampleInputDate"
                      value={moment(currentUser?.PasswordExpiresOn).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(e) =>
                        setCurrentUser({
                          ...currentUser,
                          PasswordExpiresOn: e.target.value,
                        })
                      }
                      aria-describedby="AccountDate"
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputStatus"
                    className="col-4 px-0 form-label"
                  >
                    Status
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        setCurrentUser({
                          ...currentUser,
                          UserStatusId: parseFloat(e.target.value),
                        });
                      }}
                    >
                      <option value="">---</option>
                      <option
                        value={1}
                        selected={currentUser && currentUser.UserStatusId === 1}
                      >
                        Enabled
                      </option>
                      <option
                        value={2}
                        selected={currentUser && currentUser.UserStatusId === 2}
                      >
                        Suspended
                      </option>
                      <option
                        value={3}
                        selected={currentUser && currentUser.UserStatusId === 3}
                      >
                        Disabled
                      </option>
                    </select>
                    <span className="text-danger my-0 py-0">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputFirms"
                    className="col-4 px-0 form-label"
                  >
                    Firm
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        setCurrentUser({
                          ...currentUser,
                          FirmId: parseInt(e.target.value),
                        });
                      }}
                    >
                      <option value={0}>---</option>
                      {firmList.length ? (
                        firmList.map((firm, index) => {
                          return (
                            <option
                              key={index}
                              value={firm.FirmId}
                              selected={firm.FirmId === currentUser?.FirmId}
                            >
                              {firm.Name}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputRoles"
                    className="col-4 px-0 form-label"
                  >
                    Role
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) =>
                        setCurrentUser({
                          ...currentUser,
                          RoleId: parseInt(e.target.value),
                          Role: userRoles[
                            userRoles.findIndex(
                              (role) => role.RoleID === parseInt(e.target.value)
                            )
                          ],
                        })
                      }
                    >
                      <option value={0}>---</option>
                      {userRoles.map((role, index) => {
                        return (
                          <option
                            key={index}
                            value={role.RoleID}
                            selected={role.RoleID === currentUser?.RoleId}
                          >
                            {role.DisplayName}
                          </option>
                        );
                      })}
                    </select>
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputDateCreate"
                    className="col-4 px-0 form-label"
                  >
                    Date Created
                  </label>
                  <div className="col-8 pr-4 d-flex align-items-center field">
                    <input
                      type="date"
                      className="form-control fc-field"
                      name="exampleInputDateCreate"
                      id="exampleInputDateCreate"
                      aria-describedby="AccountDateCreate"
                      value={moment(currentUser?.CreatedDate).format(
                        "YYYY-MM-DD"
                      )}
                      disabled
                    />
                    <span></span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputData"
                    className="col-4 px-0 form-label"
                  >
                    Data Sources
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <MultiSelect
                      className="p-0 m-0"
                      options={dataSources}
                      value={
                        (userDataSource &&
                          userDataSource.length &&
                          userDataSource.map((datasource) => {
                            return {
                              label: getDataSourceLabel(
                                datasource.DataSourceId
                              ),
                              value: datasource.DataSourceId,
                            };
                          })) ||
                        []
                      }
                      onChange={(value) => {
                        if (!value.length) {
                          setUserDataSource([]);
                          return;
                        }
                        value.map((val) => {
                          const index = userDataSource
                            ? userDataSource.findIndex(
                                (src) => src.DataSourceId === val.value
                              )
                            : -1;
                          if (index === -1) {
                            setUserDataSource(
                              userDataSource.concat([
                                { DataSourceId: val.value },
                              ])
                            );
                          } else {
                            setUserDataSource(
                              userDataSource.filter(
                                (datasrc) => datasrc.DataSourceId === val.value
                              )
                            );
                          }
                        });
                      }}
                      disableSearch
                      labelledBy="Select"
                      hasSelectAll={false}
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-6 px-0 mb-3 mb-md-0">
              <form>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputCell"
                    className="col-4 px-0 form-label"
                  >
                    Address
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputCell"
                      id="exampleInputCell"
                      aria-describedby="AccountCell"
                      value={currentUser?.Address}
                      onChange={(e) => {
                        setCurrentUser({
                          ...currentUser,
                          Address: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputCity"
                    className="col-4 px-0 form-label"
                  >
                    City
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputCity"
                      id="exampleInputCity"
                      aria-describedby="AccountCity"
                      value={currentUser?.City}
                      onChange={(e) => {
                        let str = e.target.value;
                        const re = new RegExp(/^[A-Za-z]+$/);
                        if (!str.length || re.test(str)) {
                          setCurrentUser({
                            ...currentUser,
                            City: str,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0  align-items-center">
                  <label
                    htmlFor="exampleInputCountry"
                    className="col-4 px-0 form-label"
                  >
                    Country
                  </label>
                  <div className="col-8 d-flex align-items-center field pr-0">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) =>
                        setCurrentUser({
                          ...currentUser,
                          CountryId: parseInt(e.target.value),
                        })
                      }
                    >
                      <option value={""}>---</option>
                      {allCountries.length ? (
                        allCountries.map((country, index) => {
                          return (
                            <option
                              key={index}
                              value={country.CountryId}
                              selected={
                                country.CountryId === currentUser?.CountryId
                              }
                            >
                              {country.Name}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputPhone"
                    className="col-4 px-0 form-label"
                  >
                    Phone
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputPhone"
                      id="exampleInputPhone"
                      aria-describedby="AccountPhone"
                      value={currentUser?.PhoneNumber}
                      onChange={(e) => {
                        setCurrentUser({
                          ...currentUser,
                          PhoneNumber: e.target.value
                            .replace(/[^0-9+]/g, "")
                            .substr(0, 15),
                        });
                      }}
                    />
                  </div>
                </div>
                {/* <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputCell"
                    className="col-4 px-0 form-label"
                  >
                    Cell
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputCell"
                      id="exampleInputCell"
                      aria-describedby="AccountCell"
                      value={currentUser?.Cell || currentUser?.PhoneNumber}
                      onChange={(e) => {
                        setCurrentUser({
                          ...currentUser,
                          PhoneNumber: e.target.value.replace(/[^0-9+]/g, '').substr(0, 15)
                        });
                      }}
                    />
                  </div>
                </div> */}

                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputQMW"
                    className="col-4 px-0 form-label"
                  >
                    QM WMID
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="number"
                      className="form-control fc-field"
                      name="exampleInputQMW"
                      id="exampleInputQMW"
                      aria-describedby="AccountQMW"
                      value={qmAccountData?.WMID}
                      onChange={(e) => {
                        setQmAccountData({
                          ...qmAccountData,
                          WMID: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputQMUser"
                    className="col-4 px-0 form-label"
                  >
                    QM User
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      autoComplete="new-password"
                      aria-describedby="AccountQMUser"
                      value={qmAccountData?.UserName}
                      onChange={(e) => {
                        setQmAccountData({
                          ...qmAccountData,
                          UserName: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputQMPassword"
                    className="col-4 px-0 form-label"
                  >
                    QM Password
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <input
                      type="password"
                      className="form-control fc-field"
                      aria-describedby="AccountQMPassword"
                      autoComplete="new-password"
                      value={qmAccountData?.Password}
                      onChange={(e) => {
                        setQmAccountData({
                          ...qmAccountData,
                          Password: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputHwip"
                    className="col-4 px-0 form-label"
                  >
                    HW IP
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        setUserHWIP(e.target.value);
                      }}
                    >
                      <option value={""}>---</option>
                      <option selected value={"hw4t.haywoodtrader.com:9111"}>
                        hw4t.haywoodtrader.com:9111
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputDFip"
                    className="col-4 px-0 form-label"
                  >
                    DF IP
                  </label>
                  <div className="col-8 d-flex align-items-center field">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        setUserDFIP(e.target.value);
                      }}
                    >
                      <option value={""}>---</option>
                      <option selected value={"hw4t.haywoodtrader.com:9111"}>
                        hw4t.haywoodtrader.com:9111
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0  align-items-center">
                  <label
                    htmlFor="exampleInputSubscription"
                    className="col-4 px-0 form-label"
                  >
                    Subscription
                  </label>
                  <div className="col-8 d-flex align-items-center field pr-0">
                    <select
                      className="form-control fc-field form-select"
                      aria-label=".form-select-lg example"
                      onChange={(e) => {
                        setCurrentUser({
                          ...currentUser,
                          Company: e.target.value,
                        });
                      }}
                    >
                      <option value={""}>---</option>
                      {subscriptionList && subscriptionList.length ? (
                        subscriptionList.map((item) => {
                          return (
                            <option
                              value={item.Package}
                              selected={
                                currentUser &&
                                currentUser.Company &&
                                currentUser.Company === item.Package
                              }
                            >
                              {item.Description}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                    <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
                  <label
                    htmlFor="exampleInputAddress"
                    className="col-4 px-0 form-label"
                  >
                    Exchange Agreement
                  </label>
                  <div className="col-8 pr-0 d-flex align-items-center field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputAddress"
                      id="exampleInputAddress"
                      aria-describedby="Address"
                      value={currentUser?.Address2}
                      onChange={(e) => {
                        setCurrentUser({
                          ...currentUser,
                          Address2: e.target.value,
                        });
                      }}
                      required
                    />
                    <span className="text-danger">*</span>
                  </div>
                </div>
              </form>
            </div>

            <span className="col-12 px-0 text-danger mt-2 py-0 requird-field">
              *Required Fields
            </span>
            <div className="col-12 d-flex justify-content-center mt-4 mb-2">
              <button
                type="button"
                className="btn btn-form dis-button"
                style={{ width: "100px" }}
                onClick={handleSaveUser}
                disabled={
                  currentUser &&
                  currentUser.RoleId &&
                  currentUser.RoleId < roleId
                    ? true
                    : false
                }
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-form"
                data-bs-dismiss="modal"
                style={{ width: "100px" }}
                onClick={() => {
                  store.dispatch(
                    showEditForm({
                      status: false,
                      data: { fieldData: 0, fieldName },
                    })
                  );
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Tab>
        <Tab
          disabled={recordId <= 0}
          eventKey="Assignaccount"
          title="Assign Account"
        >
          <div
            className={`${
              currentUser && currentUser.RoleId && currentUser.RoleId < roleId
                ? "event-none"
                : ""
            }`}
          >
            <div className="row col-12 px-5 d-flex align-items-center field">
              <input
                type="checkbox"
                name="All Accounts"
                id="AllAC"
                className="my-1"
                onChange={(e) => {
                  setShowAllAccounts(e.target.checked);
                }}
              />
              <span
                className="checklable"
                style={{ fontSize: "20px !important" }}
              >
                All Accounts
              </span>
            </div>
            <DragAndDrop
              fieldName={fieldName}
              id={"Accounts"}
              LeftTitle={"Accounts"}
              removePreviousData={setDeletedDataForDragNDrop}
              setNewData={setNewDataForDragNDrop}
              RightSideData={
                assignedAccount && assignedAccount.length
                  ? assignedAccount.map((account) => {
                      return {
                        label: account.Account.Name,
                        value: account.Account.AccountId,
                        primaryKey: account.UserAccountId,
                      };
                    })
                  : []
              }
              LeftSideData={
                showAllAccounts
                  ? accounts && accounts.length
                    ? accounts.map((account) => {
                        return {
                          label: account.Name,
                          value: account.AccountId,
                        };
                      })
                    : []
                  : unAssignedAccount && unAssignedAccount.length
                  ? unAssignedAccount.map((account) => {
                      return { label: account.Name, value: account.AccountId };
                    })
                  : []
              }
              RightTitle={"Assigned"}
              handler={handleSaveUser}
            ></DragAndDrop>
          </div>
        </Tab>
        <Tab disabled={recordId <= 0} eventKey="Permission" title="Permissions">
          <div
            className={`${
              currentUser && currentUser.RoleId && currentUser.RoleId < roleId
                ? "event-none"
                : ""
            }`}
          >
            <DragAndDrop
              fieldName={fieldName}
              LeftSideData={
                allUserActivitiylist && allUserActivitiylist.length
                  ? allUserActivitiylist.map((activity) => {
                      return { label: activity.Name, value: activity.Id };
                    })
                  : []
              }
              LeftTitle={"All permissions"}
              RightSideData={
                currentUserActivitylist && currentUserActivitylist.length
                  ? currentUserActivitylist.map((activity) => {
                      return {
                        label: activity.Activity.Name,
                        value: activity.ActivityId,
                        primaryKey: activity.UserActivityId,
                      };
                    })
                  : []
              }
              RightTitle={"Assigned Permissions"}
              id={"User_Permissions"}
              handler={handleSaveUser}
              removePreviousData={setDeletedDataForDragNDrop}
              setNewData={setNewDataForDragNDrop}
            ></DragAndDrop>
          </div>
        </Tab>
        <Tab
          disabled={recordId <= 0}
          eventKey="AssignSymbol"
          title="Assign Symbol"
        >
          <div
            className={`${
              currentUser && currentUser.RoleId && currentUser.RoleId < roleId
                ? "event-none"
                : ""
            }`}
          >
            <DragAndDrop
              fieldName={fieldName}
              id={"AssignSymbol"}
              LeftTitle={"Symbols"}
              removePreviousData={setDeletedDataForDragNDrop}
              setNewData={setNewDataForDragNDrop}
              RightTitle={"Assigned"}
              LeftSideData={
                symbolList && symbolList.length
                  ? symbolList.map((item) => {
                      return { label: item.Symbol_, value: item.Id };
                    })
                  : []
              }
              RightSideData={
                userAssignedSymbols && userAssignedSymbols.length
                  ? userAssignedSymbols.map((item) => {
                      return {
                        label: item.Symbol.Symbol_,
                        value: item.Symbol.Id,
                        primaryKey: item.UserSymbolId,
                      };
                    })
                  : []
              }
              handler={handleSaveUser}
            ></DragAndDrop>
          </div>
        </Tab>

        <Tab disabled={recordId <= 0} eventKey="UserNotes" title="Notes">
          <div>
            <div className="d-flex col-12 justify-content-center mt-auto align-items-center drag-and-drop-search-bar note-field mb-2">
              <input
                type="text"
                value={userNoteInput}
                onChange={(e) => setUserNoteInput(e.target.value)}
                placeholder="Add a note..."
              />
              <button
                type="button"
                className="btn btn-form"
                onClick={addUserNote}
              >
                Add
              </button>
            </div>
            <ul className="col-12 mx-auto d-flex flex-wrap todo-list">
              {userNotes.map((note, index) => (
                <div className="todo-item mx-1 mb-2" key={index}>
                  <div className="content">{note.Note}</div>
                  <div className="timestamp">{`${
                    note.CreatedByUserName
                  } ${moment
                    .utc(note.CreatedOn)
                    .local()
                    .format("YYYY-MM-DD hh:mm:ss")}`}</div>
                </div>
              ))}
            </ul>
          </div>
        </Tab>
      </Tabs>
    </div>
  );

  const GroupForm = (
    <div>
      <Tabs
        id="controlled-tab-example"
        defaultActiveKey={groupKey}
        onSelect={(k: any) => setGroupKey(k)}
        className="mb-3"
      >
        <Tab eventKey="groupdetails" title="Group Details">
          <div className="row mx-0 px-3">
            <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
              <label htmlFor="exampleInputId" className="col-3 px-0 form-label">
                Group Name
              </label>
              <div className="col-9 d-flex align-items-center field">
                <input
                  type="text"
                  className="form-control fc-field"
                  name="exampleInputId"
                  id="exampleInputId"
                  aria-describedby="GroupID"
                  value={groupDetail?.Name}
                  onChange={(e) =>
                    setGroupDetail({ ...groupDetail, Name: e.target.value })
                  }
                  required
                />
                <span className="text-danger">*</span>
              </div>
            </div>
            <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
              <label
                htmlFor="exampleInputFirms"
                className="col-3 px-0 form-label"
              >
                Firm
              </label>
              <div className="col-9 d-flex align-items-center field">
                <select
                  className="form-control fc-field form-select"
                  aria-label=".form-select-lg example"
                  onChange={(e) => {
                    setGroupDetail({ ...groupDetail, FirmId: e.target.value });
                  }}
                >
                  <option value={0}>---</option>
                  {firmList.length ? (
                    firmList.map((firm, index) => {
                      return (
                        <option
                          key={index}
                          selected={firm.FirmId === groupDetail?.FirmId}
                          value={firm.FirmId}
                        >
                          {firm.Name}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </select>
                <span className="text-danger">*</span>
              </div>
            </div>
            <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
              <label
                htmlFor="exampleInputFirms"
                className="col-3 px-0 form-label"
              ></label>
              <div className="col-9 d-flex align-items-center field">
                <input
                  type="checkbox"
                  name="Assign Supervisior"
                  id="Asupervisior"
                  className="my-1"
                  checked={enAssignSupervisor}
                  onChange={(e) => {
                    setEnAssignSupervisor(!enAssignSupervisor);
                    setGroupDetail({
                      ...groupDetail,
                      HasSupervisorRights: e.target.checked ? 1 : 0,
                    });
                  }}
                />
                <span className="col-9 checklable">Assign Supervisior</span>
                <span className="text-danger"></span>
              </div>
            </div>
            <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
              <label
                htmlFor="exampleInputFirms"
                className="col-3 px-0 form-label"
              ></label>
              <div className="col-9 d-flex align-items-center field">
                <input
                  type="checkbox"
                  name="Enable Group Risk Control"
                  id="EGRControl"
                  className="my-1"
                  checked={groupDetail?.EnRiskControl}
                  onChange={() => {
                    setEnGroupRisk(!enGroupRisk);
                    setGroupDetail({
                      ...groupDetail,
                      EnRiskControl: !enGroupRisk,
                    });
                  }}
                />
                <span className="col-9 checklable">
                  Enable Group Risk Control
                </span>
                <span className="text-danger"></span>
              </div>
            </div>
            
            <span className="col-12 px-0 text-danger mt-2 py-0 requird-field">
              *Required Fields
            </span>
            <div className="col-12 d-flex justify-content-center mt-4 mb-2">
              <button
                type="button"
                className="btn btn-form dis-button"
                style={{ width: "100px" }}
                onClick={handleSaveGroup}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-form"
                data-bs-dismiss="modal"
                style={{ width: "100px" }}
                onClick={() => {
                  store.dispatch(
                    showEditForm({
                      status: false,
                      data: { fieldData: 0, fieldName },
                    })
                  );
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Tab>
        <Tab
          disabled={!enGroupRisk || recordId <= 0}
          eventKey="GroupRisk"
          title="Group Risk"
        >
          <div className="row mx-0 px-0">
            <div className="col-12 px-0 mb-3 mb-md-0">
              <form>
                <div className="row col-12 d-flex justify-content-end mb-2 ml-3 pl-5 pr-3 align-items-center">
                  <label
                    htmlFor="exampleInputGroupID"
                    className="col-2 px-0 form-label"
                  >
                    Group ID
                  </label>
                  <div className="col-9 d-flex field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputGroupID"
                      id="exampleInputGroupID"
                      aria-describedby="GroupID"
                      value={groupDetail?.Name}
                      disabled={true}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <div className="col-2"></div>
                <div className="col-10 pr-0 d-flex ">
                  <input
                    type="checkbox"
                    name="Group BP"
                    id="Groupbp"
                    className="col-2 my-1 d-flex justify-content-center align-items-center"
                    checked={groupTradeLimit?.EnGroupBP}
                    onChange={(e) => {
                      setGroupTradeLimit({
                        ...groupTradeLimit,
                        EnGroupBP: e.target.checked,
                      });
                    }}
                  />
                  <label
                    htmlFor="exampleGroupbp"
                    className="col-4 form-label d-flex justify-content-end align-items-center"
                  >
                    Group BP
                  </label>
                  <div
                    className="col-6 d-flex field"
                    style={{ paddingLeft: "10px" }}
                  >
                    <input
                      type="text"
                      className="form-control fc-field"
                      value={groupTradeLimit?.BuyingPower}
                      onChange={(e) => {
                        setGroupTradeLimit({
                          ...groupTradeLimit,
                          BuyingPower: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                      name="examplemaxPosition"
                      id="examplemaxPosition"
                      aria-describedby="maxPosition"
                      disabled={!groupTradeLimit?.EnGroupBP}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <div className="col-2"></div>
                <div className="col-10 pr-0 d-flex ">
                  <input
                    type="checkbox"
                    name="Group Max Loss"
                    id="Groupmaxloss"
                    className="col-2 my-1 d-flex justify-content-center align-items-center"
                    checked={groupTradeLimit?.EnGroupMaxLoss}
                    onChange={(e) => {
                      setGroupTradeLimit({
                        ...groupTradeLimit,
                        EnGroupMaxLoss: e.target.checked,
                      });
                    }}
                  />
                  <label
                    htmlFor="exampleGroupmaxloss"
                    className="col-4 form-label d-flex justify-content-end align-items-center"
                  >
                    Group Max Loss
                  </label>
                  <div
                    className="col-6 d-flex field"
                    style={{ paddingLeft: "10px" }}
                  >
                    <input
                      type="text"
                      className="form-control fc-field"
                      value={groupTradeLimit?.MaxLoss}
                      onChange={(e) => {
                        setGroupTradeLimit({
                          ...groupTradeLimit,
                          MaxLoss: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                      name="examplemaxPosition"
                      id="examplemaxPosition"
                      aria-describedby="maxPosition"
                      disabled={!groupTradeLimit?.EnGroupMaxLoss}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <div className="col-2"></div>
                <div className="col-10 pr-0 d-flex ">
                  <input
                    type="checkbox"
                    name="Group Max Position"
                    id="GroupmaxPosition"
                    checked={groupTradeLimit?.EnGroupMaxPos}
                    onChange={(e) => {
                      setGroupTradeLimit({
                        ...groupTradeLimit,
                        EnGroupMaxPos: e.target.checked,
                      });
                    }}
                    className="col-2 my-1 d-flex justify-content-center align-items-center"
                  />
                  <label
                    htmlFor="exampleGroumaxPosition"
                    className="col-4 form-label d-flex justify-content-end align-items-center"
                  >
                    Group Max Position
                  </label>
                  <div
                    className="col-6 d-flex field"
                    style={{ paddingLeft: "10px" }}
                  >
                    <input
                      type="text"
                      className="form-control fc-field"
                      value={groupTradeLimit?.MaxPosition}
                      onChange={(e) => {
                        setGroupTradeLimit({
                          ...groupTradeLimit,
                          MaxPosition: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                      name="examplemaxPosition"
                      id="examplemaxPosition"
                      aria-describedby="maxPosition"
                      disabled={!groupTradeLimit?.EnGroupMaxPos}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <div className="col-2"></div>
                <div className="col-10 pr-0 d-flex ">
                  <input
                    type="checkbox"
                    name="GroupERPL"
                    id="GroupERPL"
                    className="col-2 my-1 d-flex justify-content-center align-items-center"
                    checked={groupTradeLimit?.EnUrlzLinkToBP}
                    onChange={(e) => {
                      setGroupTradeLimit({
                        ...groupTradeLimit,
                        EnUrlzLinkToBP: e.target.checked,
                      });
                    }}
                  />
                  <label
                    htmlFor="exampleGrouERPL"
                    className="col-auto form-label d-flex justify-content-end align-items-center"
                  >
                    Enable unreal PNL link with BP/Margin
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <div className="col-2"></div>
                <div className="col-10 pr-0 d-flex ">
                  <input
                    type="checkbox"
                    name="GroupERPML"
                    id="GroupERPLML"
                    checked={groupTradeLimit?.EnUrlzLinkToMaxLoss}
                    onChange={(e) => {
                      setGroupTradeLimit({
                        ...groupTradeLimit,
                        EnUrlzLinkToMaxLoss: e.target.checked,
                      });
                    }}
                    className="col-2 my-1 d-flex justify-content-center align-items-center"
                  />
                  <label
                    htmlFor="exampleGrouERPL"
                    className="col-auto form-label d-flex justify-content-end align-items-center"
                  >
                    Enable unreal PNL link with Max Loss
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <button
              type="button"
              className="btn btn-form dis-button"
              style={{ width: "100px" }}
              onClick={handleSaveGroup}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-form"
              data-bs-dismiss="modal"
              style={{ width: "100px" }}
              onClick={() => {
                store.dispatch(
                  showEditForm({
                    status: false,
                    data: { fieldData: 0, fieldName },
                  })
                );
              }}
            >
              Cancel
            </button>
          </div>
        </Tab>
        <Tab
          disabled={!enAssignSupervisor || recordId <= 0}
          eventKey="AssignSuperVisor"
          title="Assign Supervisor"
        >
          <DragAndDrop
            fieldName={fieldName}
            LeftSideData={
              allSuperVisors && allSuperVisors.length
                ? allSuperVisors.map((superVisor) => {
                    return { label: superVisor.UserName, value: superVisor.Id };
                  })
                : []
            }
            LeftTitle={"Supervisors"}
            RightSideData={
              groupSupervisors && groupSupervisors.length
                ? groupSupervisors.map((user) => {
                    return {
                      label: user.Supervisor.UserName,
                      value: user.SupervisorId,
                      primaryKey: user.GroupSupervisorId,
                    };
                  })
                : []
            }
            RightTitle={"Assigned"}
            id={"SuperVisors"}
            handler={handleSaveGroup}
            removePreviousData={setDeletedDataForDragNDrop}
            setNewData={setNewDataForDragNDrop}
          />
        </Tab>
        <Tab disabled={recordId <= 0} eventKey="AssignAccount" title="Assign Accounts">
          <div className="row col-12 px-5 d-flex align-items-center field">
            <input
              type="checkbox"
              name="All Accounts"
              id="AllAC"
              className="my-1"
              onChange={(e) => {
                setShowAllAccounts(e.target.checked);
              }}
            />
            <span
              className="checklable"
              style={{ fontSize: "20px !important" }}
            >
              All Accounts
            </span>
          </div>
          <DragAndDrop
            fieldName={fieldName}
            LeftTitle={"Accounts"}
            LeftSideData={
              showAllAccounts
                ? accounts && accounts.length
                  ? accounts.map((account) => {
                      return { label: account.Name, value: account.AccountId };
                    })
                  : []
                : unAssignedAccount && unAssignedAccount.length
                ? unAssignedAccount.map((account) => {
                    return { label: account.Name, value: account.AccountId };
                  })
                : []
            }
            RightSideData={
              groupAccounts && groupAccounts.length
                ? groupAccounts.map((item) => {
                    return {
                      label: item.Account.Name,
                      value: item.Account.AccountId,
                      primaryKey: item.AccountGroupId,
                    };
                  })
                : []
            }
            RightTitle={"Assigned Accounts"}
            id={"AssignGroupAccount"}
            handler={handleSaveGroup}
            removePreviousData={setDeletedDataForDragNDrop}
            setNewData={setNewDataForDragNDrop}
          ></DragAndDrop>
        </Tab>
        {/* <Tab eventKey="Assignsymbol" title="Assign Symbol">

        </Tab> */}
      </Tabs>
    </div>
  );

  const FirmForm = (
    <div>
      <Tabs
        id="controlled-tab-example"
        defaultActiveKey={firmKey}
        onSelect={(k: any) => setFirmKey(k)}
        className="mb-3"
      >
        <Tab eventKey="firmdetails" title="Firm Details">
          <div className="row mx-0 px-3">
            <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
              <label
                htmlFor="exampleInputFname"
                className="col-3 px-0 form-label"
              >
                Firm Name
              </label>
              <div className="col-9 d-flex align-items-center field">
                <input
                  type="text"
                  className="form-control fc-field"
                  name="exampleInputFname"
                  id="exampleInputFname"
                  aria-describedby="Firm Name"
                  value={currentFirm?.Name}
                  onChange={(e) =>
                    setCurrentFirm({ ...currentFirm, Name: e.target.value })
                  }
                  required
                />
                <span className="text-danger">*</span>
              </div>
            </div>
            <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
              <label
                htmlFor="exampleInputCityContry"
                className="col-3 px-0 form-label"
              >
                Address
              </label>
              <div className="col-9 d-flex align-items-center field">
                <input
                  type="text"
                  className="form-control fc-field"
                  value={currentFirm?.Address}
                  onChange={(e) => {
                    let str = e.target.value;
                    setCurrentFirm({
                      ...currentFirm,
                      Address: str,
                    });
                  }}
                  required
                />
                <span className="text-danger">*</span>
              </div>
            </div>
            <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
              <label
                htmlFor="exampleInputCityContry"
                className="col-3 px-0 form-label"
              >
                City, Country
              </label>
              <div className="col-9 d-flex align-items-center field">
                <input
                  type="text"
                  className="form-control fc-field"
                  name="exampleInputCityContry"
                  id="exampleInputCityContry"
                  aria-describedby="City Country"
                  value={currentFirm?.CityCountry}
                  onChange={(e) => {
                    let str = e.target.value;
                    setCurrentFirm({
                      ...currentFirm,
                      CityCountry: str,
                    });
                  }}
                  required
                />
                <span className="text-danger">*</span>
              </div>
            </div>
            <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
              <label
                htmlFor="exampleInputPcode"
                className="col-3 px-0 form-label"
              >
                Post Code
              </label>
              <div className="col-9 d-flex align-items-center field">
                <input
                  type="text"
                  className="form-control fc-field"
                  name="exampleInputPcode"
                  id="exampleInputPcode"
                  aria-describedby="Post Code"
                  value={currentFirm?.PostCode}
                  onChange={(e) => {
                    setCurrentFirm({
                      ...currentFirm,
                      PostCode: e.target.value,
                    });
                  }}
                  required
                />
                <span className="text-danger">*</span>
              </div>
            </div>
            <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
              <label
                htmlFor="exampleInputFtype"
                className="col-3 px-0 form-label"
              >
                Firm Type
              </label>
              <div className="col-9 d-flex align-items-center field">
                <select
                  className="form-control fc-field form-select"
                  aria-label=".form-select-lg example"
                  onChange={(e) =>
                    setCurrentFirm({
                      ...currentFirm,
                      FirmTypeId: e.target.value,
                    })
                  }
                >
                  <option value={0}>---</option>
                  {firmTypes && firmTypes.length ? (
                    firmTypes.map((type) => {
                      return (
                        <option
                          value={type.FirmTypeId}
                          selected={type.FirmTypeId === currentFirm?.FirmTypeId}
                        >
                          {type.Name}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </select>
                <span className="text-danger">*</span>
              </div>
            </div>
            <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
              <label
                htmlFor="exampleInputComment"
                className="col-3 px-0 form-label"
              >
                Comments
              </label>
              <div className="col-9 d-flex align-items-center field pr-4">
                <input
                  type="text"
                  className="form-control fc-field"
                  name="exampleInputComment"
                  id="exampleInputComment"
                  aria-describedby="Comments"
                  value={currentFirm?.Comments}
                  onChange={(e) => {
                    setCurrentFirm({
                      ...currentFirm,
                      Comments: e.target.value,
                    });
                  }}
                  required
                />
                <span className="text-danger"></span>
              </div>
            </div>
            <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
              <label
                htmlFor="exampleInputDesc"
                className="col-3 px-0 form-label"
              >
                Description
              </label>
              <div className="col-9 d-flex align-items-center field pr-4">
                <textarea
                  className="form-control fc-field"
                  name="exampleInputDesc"
                  rows={7}
                  aria-describedby="Descriptions"
                  value={currentFirm?.Description}
                  onChange={(e) => {
                    setCurrentFirm({
                      ...currentFirm,
                      Description: e.target.value,
                    });
                  }}
                  required
                />
                <span className="text-danger"></span>
              </div>
            </div>
            <div className="row col-12 mb-1 mx-0 px-0 align-items-center">
              <label
                htmlFor="exampleInputFirms"
                className="col-3 px-0 form-label"
              ></label>
              <div className="col-9 d-flex align-items-center field">
                <input
                  type="checkbox"
                  name="Enable Firm Risk Control"
                  id="EFirmRC"
                  className="my-1"
                  checked={currentFirm?.RiskControlIsEnabled}
                  onChange={(e) => {
                    setCurrentFirm({
                      ...currentFirm,
                      RiskControlIsEnabled: e.target.checked,
                    });
                  }}
                />
                <span className="col-9 checklable">
                  Enable Firm Risk Control
                </span>
                <span className="text-danger"></span>
              </div>
            </div>

            <span className="col-12 px-0 text-danger mt-2 py-0 requird-field">
              *Required Fields
            </span>
            <div className="col-12 d-flex justify-content-center mt-4 mb-2">
              <button
                type="button"
                className="btn btn-form dis-button"
                style={{ width: "100px" }}
                onClick={handleSaveFirm}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-form"
                data-bs-dismiss="modal"
                style={{ width: "100px" }}
                onClick={() => {
                  store.dispatch(
                    showEditForm({
                      status: false,
                      data: { fieldData: 0, fieldName },
                    })
                  );
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Tab>
        <Tab
          disabled={recordId <= 0 || !currentFirm?.RiskControlIsEnabled}
          eventKey="firmRisk"
          title="Firm Risk"
        >
          <div className="row mx-0 px-0">
            <div className="col-12 px-0 mb-3 mb-md-0">
              <form>
                <div className="row col-12 d-flex justify-content-end mb-2 ml-3 pl-5 pr-3 align-items-center">
                  <label
                    htmlFor="exampleInputGroupFname"
                    className="col-2 px-0 form-label"
                  >
                    Firm Name
                  </label>
                  <div className="col-9 d-flex field">
                    <input
                      type="text"
                      className="form-control fc-field"
                      name="exampleInputFname"
                      id="exampleInputFname"
                      aria-describedby="Firm Name"
                      value={currentFirm?.Name}
                      disabled={true}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <div className="col-2"></div>
                <div className="col-10 pr-0 d-flex ">
                  <input
                    type="checkbox"
                    name="Firm BP"
                    id="Firmbp"
                    className="col-2 my-1 d-flex justify-content-center align-items-center"
                    checked={selectedFirmRisk?.EnFirmBp}
                    onChange={(e) => {
                      setSelectedFirmRisk({
                        ...selectedFirmRisk,
                        EnFirmBp: e.target.checked,
                      });
                    }}
                  />
                  <label
                    htmlFor="exampleFirmbp"
                    className="col-4 form-label d-flex justify-content-end align-items-center"
                  >
                    Firm BP
                  </label>
                  <div
                    className="col-6 d-flex field"
                    style={{ paddingLeft: "10px" }}
                  >
                    <input
                      type="text"
                      className="form-control fc-field"
                      value={selectedFirmRisk?.BuyingPower}
                      name="exampleFirmBP"
                      id="exampleFirmBP"
                      aria-describedby="FirmBP"
                      disabled={!selectedFirmRisk?.EnFirmBp}
                      onChange={(e) => {
                        setSelectedFirmRisk({
                          ...selectedFirmRisk,
                          BuyingPower: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <div className="col-2"></div>
                <div className="col-10 pr-0 d-flex ">
                  <input
                    type="checkbox"
                    name="Firm Max Loss"
                    id="Firmmaxloss"
                    className="col-2 my-1 d-flex justify-content-center align-items-center"
                    checked={selectedFirmRisk?.EnFirmMaxLoss}
                    onChange={(e) => {
                      setSelectedFirmRisk({
                        ...selectedFirmRisk,
                        EnFirmMaxLoss: e.target.checked,
                      });
                    }}
                  />
                  <label
                    htmlFor="exampleFirmmaxloss"
                    className="col-4 form-label d-flex justify-content-end align-items-center"
                  >
                    Firm Max Loss
                  </label>
                  <div
                    className="col-6 d-flex field"
                    style={{ paddingLeft: "10px" }}
                  >
                    <input
                      type="text"
                      className="form-control fc-field"
                      value={selectedFirmRisk?.MaxLoss}
                      name="exampleFmaxLoss"
                      id="exampleFmaxLoss"
                      aria-describedby="FmaxLoss"
                      disabled={!selectedFirmRisk?.EnFirmMaxLoss}
                      onChange={(e) => {
                        setSelectedFirmRisk({
                          ...selectedFirmRisk,
                          MaxLoss: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <div className="col-2"></div>
                <div className="col-10 pr-0 d-flex ">
                  <input
                    type="checkbox"
                    name="Firm Max Position"
                    id="FirmmaxPosition"
                    className="col-2 my-1 d-flex justify-content-center align-items-center"
                    checked={selectedFirmRisk?.EnFirmMaxPos}
                    onChange={(e) => {
                      setSelectedFirmRisk({
                        ...selectedFirmRisk,
                        EnFirmMaxPos: e.target.checked,
                      });
                    }}
                  />
                  <label
                    htmlFor="exampleGroumaxPosition"
                    className="col-4 form-label d-flex justify-content-end align-items-center"
                  >
                    Firm Max Position
                  </label>
                  <div
                    className="col-6 d-flex field"
                    style={{ paddingLeft: "10px" }}
                  >
                    <input
                      type="text"
                      className="form-control fc-field"
                      value={selectedFirmRisk?.MaxPosition}
                      name="exampleFmaxPosition"
                      id="exampleFmaxPosition"
                      aria-describedby="FmaxPosition"
                      disabled={!selectedFirmRisk?.EnFirmMaxPos}
                      onChange={(e) => {
                        setSelectedFirmRisk({
                          ...selectedFirmRisk,
                          MaxPosition: e.target.value.replace(/^(\d{0,8}(?:\.\d{0,5})?)?.*$/, '$1'),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <div className="col-2"></div>
                <div className="col-10 pr-0 d-flex ">
                  <input
                    type="checkbox"
                    name="GroupERPL"
                    id="GroupERPL"
                    className="col-2 my-1 d-flex justify-content-center align-items-center"
                    checked={selectedFirmRisk?.EnUrlzLinkToBp}
                    onChange={(e) => {
                      setSelectedFirmRisk({
                        ...selectedFirmRisk,
                        EnUrlzLinkToBp: e.target.checked,
                      });
                    }}
                  />
                  <label
                    htmlFor="exampleGrouERPL"
                    className="col-auto form-label d-flex justify-content-end align-items-center"
                  >
                    Enable unreal PNL link with BP/Margin
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <div className="col-2"></div>
                <div className="col-10 pr-0 d-flex ">
                  <input
                    type="checkbox"
                    name="GroupERPML"
                    id="GroupERPLML"
                    className="col-2 my-1 d-flex justify-content-center align-items-center"
                    checked={selectedFirmRisk?.EnUrlzLinkToMaxLoss}
                    onChange={(e) => {
                      setSelectedFirmRisk({
                        ...selectedFirmRisk,
                        EnUrlzLinkToMaxLoss: e.target.checked,
                      });
                    }}
                  />
                  <label
                    htmlFor="exampleGrouERPL"
                    className="col-auto form-label d-flex justify-content-end align-items-center"
                  >
                    Enable unreal PNL link with Max Loss
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 px-0 mb-2">
            <form>
              <div className="row col-12 d-flex mx-0 px-0">
                <div className="col-2"></div>
                <div className="col-10 pr-0 d-flex ">
                  <input
                    type="checkbox"
                    name="RejectWash"
                    id="RejectWash"
                    className="col-2 my-1 d-flex justify-content-center align-items-center"
                    checked={selectedFirmRisk?.EnRejectWashTradeWithinFirm}
                    onChange={(e) => {
                      setSelectedFirmRisk({
                        ...selectedFirmRisk,
                        EnRejectWashTradeWithinFirm: e.target.checked,
                      });
                    }}
                  />
                  <label
                    htmlFor="exampleRejectWash"
                    className="col-auto form-label d-flex justify-content-end align-items-center"
                  >
                    Reject wash trade within the Firm
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <button
              type="button"
              className="btn btn-form dis-button"
              style={{ width: "100px" }}
              onClick={handleSaveFirm}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-form"
              data-bs-dismiss="modal"
              style={{ width: "100px" }}
              onClick={() => {
                store.dispatch(
                  showEditForm({
                    status: false,
                    data: { fieldData: 0, fieldName },
                  })
                );
              }}
            >
              Cancel
            </button>
          </div>
        </Tab>
        <Tab
          disabled={recordId <= 0}
          eventKey="AssignGroups"
          title="Assign Groups"
        >
          <DragAndDrop
            fieldName={fieldName}
            LeftTitle={"Groups"}
            LeftSideData={[]}
            RightSideData={
              groupList && groupList.length
                ? groupList
                    .filter((x) => x.FirmId === recordId)
                    .map((x) => {
                      return {
                        value: x.GroupId,
                        label: x.Name,
                        primaryKey: x.GroupId,
                      };
                    })
                : []
            }
            RightTitle={"Assigned Groups"}
            id={"AssignFirmGroup"}
            handler={handleSaveFirm}
            removePreviousData={setDeletedDataForDragNDrop}
            setNewData={setNewDataForDragNDrop}
          ></DragAndDrop>
        </Tab>
        <Tab
          disabled={recordId <= 0}
          eventKey="AssignAccount"
          title="Assign Account"
        >
          <div className="row col-12 px-5 d-flex align-items-center field">
            <input
              type="checkbox"
              name="All Accounts"
              id="AllAC"
              className="my-1"
              onChange={(e) => {
                setShowAllAccounts(e.target.checked);
              }}
            />
            <span
              className="checklable"
              style={{ fontSize: "20px !important" }}
            >
              All Accounts
            </span>
          </div>
          <DragAndDrop
            fieldName={fieldName}
            LeftTitle={"Accounts"}
            LeftSideData={
              showAllAccounts
                ? accounts && accounts.length
                  ? accounts.map((account) => {
                      return { label: account.Name, value: account.AccountId };
                    })
                  : []
                : unAssignedAccount && unAssignedAccount.length
                ? unAssignedAccount.map((account) => {
                    return { label: account.Name, value: account.AccountId };
                  })
                : []
            }
            RightSideData={
              firmAccounts && firmAccounts.length
                ? firmAccounts.map((item) => {
                    return {
                      label: item.Name,
                      value: item.AccountId,
                      primaryKey: item.AccountId,
                    };
                  })
                : []
            }
            RightTitle={"Assigned Accounts"}
            id={"AssignFirmAccount"}
            handler={handleSaveFirm}
            removePreviousData={setDeletedDataForDragNDrop}
            setNewData={setNewDataForDragNDrop}
          ></DragAndDrop>
        </Tab>
      </Tabs>
    </div>
  );
  return (
    <>
      {fieldName.includes("Edit Account") ||
      fieldName.includes("Add Account") ? (
        AccountForm
      ) : fieldName.includes("Edit User") || fieldName.includes("Add User") ? (
        UserForm
      ) : fieldName.includes("Edit Group") ||
        fieldName.includes("Add Group") ? (
        GroupForm
      ) : fieldName.includes("Edit Firm") || fieldName.includes("Add Firm") ? (
        FirmForm
      ) : (
        <>Under Development</>
      )}
    </>
  );
};

export default Form;
