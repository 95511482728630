import Marquee from "react-fast-marquee";
import { connect } from "react-redux";
import { IAppState } from "../../store/reducers/IAppState";
import { IAdvertisementProps } from "./IAdvertisementProp";
import "./style.scss";

function Advertisement(props: IAdvertisementProps) {
  const { ads } = props;

  return (
    <Marquee gradient={false}>
      {ads.map((x, idx) => (
        <span key={`ad_${idx}`} className={`${x.Urgency.toLowerCase()} ad`}>{x.Text}</span>
      ))}
    </Marquee>
  );
}

const mapStateToProps = (state: IAppState) => ({
  ads: state.users.ads
});

export default connect(mapStateToProps)(Advertisement);
