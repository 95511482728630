import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducers/IAppState";

export default function NumericText({ value }) {
  const decimalPlaces = useSelector((state: IAppState) => state.settings.general.decimalPlaces);
  
  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={decimalPlaces}
      fixedDecimalScale={!Number.isInteger(value)}
    />
  );
}
