export enum OrderStatusEnum {
  New = 0,
  PartiallyFilled = 1,
  Filled = 2,
  Cancelled = 4,
  Replaced = 5,
  Rejected = 8,
  Pending = 9,
  Sending = 10,
  TimeOut = 11,
  Open = 12,
  NULL_VALUE = 255
}
