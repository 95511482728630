import { Component } from "react";
import { connect } from "react-redux";
import {
  ChartingLibraryWidgetOptions,
  ErrorCallback,
  HistoryCallback,
  IBasicDataFeed,
  IChartingLibraryWidget,
  LibrarySymbolInfo,
  OnReadyCallback,
  PeriodParams,
  ResolutionString,
  ResolveCallback,
  SearchSymbolsCallback, SubscribeBarsCallback,
  ThemeName,
  VisibleTimeRange
} from "../../@types/charting_library";
import { IChartBar } from "../../models/IChartBar";
import { IChartQuote } from "../../models/IChartQuote";
import { IQuoteMediaTrade } from "../../models/IQuoteMediaTrade";
import logSvc from "../../services/log-service";
import tempCache from "../../services/temp-cache";
import { getEnhancedChartData } from "../../store/quote-media/quote-media-async-actions";
import { IAppState } from "../../store/reducers/IAppState";
import { setWidgetSymbol } from "../../store/widgets/widgets-actions";
import { IChartDispatchProps, IChartMappedProps, IChartProps } from "./IChartProps";

declare const TradingView: any;

const qmApiBaseUrl = "https://quotes.quotemedia.com";
const qmApiDataUrl = `${qmApiBaseUrl}/data`;
const supportedResolutions = ["1", "3", "5", "15", "30", "60", "D"] as ResolutionString[];

type Props = IChartProps & IChartMappedProps & IChartDispatchProps;

class Chart extends Component<Props, {}> {
  dataFeed: IBasicDataFeed;
  getBarsData: any[] = [];
  intervals: NodeJS.Timeout[] = [];
  isChartCreated = false;
  isChartReady = false;
  lastQuote?: IChartQuote;
  noAction = () => { };
  qmSid: string | null = '';
  requestsPending = 0;
  subscribers: { [listerGuid: string]: any } = {};
  symbol = '';
  theme: string | null = '';
  webMasterId: string | null = '';
  widget: IChartingLibraryWidget = {} as IChartingLibraryWidget;

  constructor(props: any) {
    super(props);

    this.handleQueryStringParameters(props);
    this.dataFeed = this.getDataFeed();
  }

  get id() {
    return `chart-${this.props.windowId}`;
  }

  get intervalCacheKey() {
    return `trader2B-${this.id}-interval`;
  }

  get layoutCacheKey() {
    return `trader2B-${this.id}-layout`;
  }

  get visibleRangeCacheKey() {
    return `trader2B-${this.id}-visible-range`;
  }

  componentDidMount() {
    if (this.props.qmSid) {
      this.createChart();
    }
  }

  componentDidUpdate(prevProps: Props) {
    this.handleSymbolUpdate(prevProps.symbol);
    this.handleSidUpdate(prevProps.qmSid);
    this.handleTradeUpdate();
  }

  componentWillUnmount() {
    if (this.isChartReady) {
      this.widget.save(state => tempCache.setItem(this.layoutCacheKey, state));
    }

    this.intervals.forEach(clearInterval);
  }

  render() {
    return <div id={this.id} style={{ height: '100%' }}></div>;
  }

  createChart = () => {
    const { userId } = this.props;

    const widgetOptions: ChartingLibraryWidgetOptions = {
      autosize: true,
      charts_storage_api_version: "1.1",
      charts_storage_url: 'https://trader2b-save-load-chart.azurewebsites.net',
      client_id: 'trader2b.com',
      container: this.id,
      datafeed: this.dataFeed,
      debug: false,
      disabled_features: ["display_market_status", "left_toolbar", "use_localstorage_for_settings"],
      enabled_features: ["show_animated_logo"],
      custom_css_url: "/charts.css",
      interval: "D" as ResolutionString,
      library_path: "/charting_library/",
      locale: "en",
      symbol: this.symbol || "AAPL",
      theme: (this.theme as ThemeName) || "Dark",
      timezone: "exchange",
      user_id: userId || 'public_user_id'
    };

    const savedChart = tempCache.getItem<any>(this.layoutCacheKey);
    if (savedChart) {
      widgetOptions.saved_data = savedChart;
    }

    const interval = tempCache.getValue(this.intervalCacheKey) as ResolutionString;
    if (interval) {
      widgetOptions.interval = interval;
    }

    this.widget = new TradingView.widget(widgetOptions);

    this.widget.onChartReady(() => {
      this.isChartReady = true;

      const visibleRange = tempCache.getItem<VisibleTimeRange>(this.visibleRangeCacheKey);
      if (visibleRange) {
        this.widget.activeChart().setVisibleRange(visibleRange);
      }

      this.handleSymbolChange();
      this.handleIntervalChange();
      this.handleVisibleRangeChange();
    });
  };

  executeGetBars = async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onHistoryCallback: HistoryCallback,
    onErrorCallback: ErrorCallback) => {
    const { qmSid, webMasterId } = this;

    if (!webMasterId || !qmSid) {
      return;
    }

    try {
      const data = await getEnhancedChartData(symbolInfo, resolution, periodParams);

      if (!data || !data.results || !data.results.symbolcount) {
        onHistoryCallback([], { noData: true });
        return;
      }

      if (resolution === 'D' || resolution === '1D') {
        if (data.results.history) {
          const history = data.results.history[0];
          if (!history.eoddata) {
            onHistoryCallback([], { noData: true });
            return;
          }

          this.processBarsData(history.eoddata, resolution, onHistoryCallback);
        }
      } else {
        if (data.results.intraday) {
          if (!data.results.intraday[0].interval) {
            onHistoryCallback([], { noData: true });
            return;
          }

          this.processBarsData(data.results.intraday[0].interval, resolution, onHistoryCallback);
        }
      }
    } catch (e) {
      logSvc.error('chart/getBars() - ' + e);
      onErrorCallback('' + e);
    }
  }

  getBars = async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onHistoryCallback: HistoryCallback,
    onErrorCallback: ErrorCallback) => {
    const { qmSid } = this;

    if (qmSid) {
      await this.executeGetBars(
        symbolInfo,
        resolution,
        periodParams,
        onHistoryCallback,
        onErrorCallback
      );
    } else {
      this.getBarsData.push({
        symbolInfo,
        resolution,
        periodParams,
        onHistoryCallback,
        onErrorCallback
      });
    }
  };

  handleErrors = (response: Response): Response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }

    return response;
  };

  handleIntervalChange = () => {
    this.widget.activeChart().onIntervalChanged()
      .subscribe(null,
        (interval: ResolutionString) => {
          tempCache.setItem(this.intervalCacheKey, interval);
        });
  };

  handleQueryStringParameters = (props: any) => {
    if (props.location?.pathname?.toLowerCase() === '/chart') {
      const search = new URLSearchParams(props.location.search);

      this.symbol = search.get('symbol') as string;
      this.webMasterId = search.get('webmasterId');
      this.qmSid = search.get('sessionId');
      this.theme = search.get('theme');
    } else {
      this.symbol = props.symbol;
      this.webMasterId = props.wmid;
      this.qmSid = props.qmSid;
    }
  }

  handleSidUpdate = (prevQmSid: string) => {
    if (this.props.qmSid && prevQmSid !== this.props.qmSid) {
      if (!this.isChartCreated) {
        this.createChart();
        this.isChartCreated = true;
      }

      this.webMasterId = this.props.wmid;
      this.qmSid = this.props.qmSid;

      this.getBarsData.forEach(data => this.executeGetBars(
        data.symbolInfo,
        data.resolution,
        data.periodParams,
        data.onHistoryCallback,
        data.onErrorCallback
      ));

      this.getBarsData = [];
    }
  };

  handleSymbolChange = () => {
    this.widget.activeChart().onSymbolChanged()
      .subscribe(null,
        () => {
          const symbol = this.widget.activeChart().symbol().split(":")[1];

          if (symbol) {
            this.props.setWidgetSymbol(symbol);
          }

          this.lastQuote = undefined;
        });
  };

  handleSymbolUpdate = (prevSymbol: string) => {
    if (this.isChartReady && prevSymbol !== this.props.symbol) {
      this.symbol = this.props.symbol;

      this.widget.activeChart().setSymbol(this.props.symbol, this.noAction);
      this.getBarsData = [];
    }
  };

  handleTradeUpdate = () => {
    if (this.props.trade) {
      this.updateBars(this.props.trade);
    }
  };

  handleVisibleRangeChange = () => {
    this.widget.activeChart().onVisibleRangeChanged().subscribe(null, (range) => {
      tempCache.setItem(this.visibleRangeCacheKey, range);
    });
  };

  onWidgetReady = (cb: OnReadyCallback) => {
    setTimeout(() => cb({
      supported_resolutions: supportedResolutions,
      supports_time: true
    }), 0);

    if (!this.widget) {
      return;
    }

    // this.saveObjectInterval = setInterval(() => this.widget.save((saveObject) => logSvc.log(JSON.stringify(saveObject))), 10000);
  };

  processBarsData = (
    data: IChartBar[],
    resolution: ResolutionString,
    onHistoryCallback: HistoryCallback
  ) => {
    const quotes = data
      .map(item => ({
        time: Date.parse(item.startdatetime ? item.startdatetime : item.date),
        low: item.low,
        high: item.high,
        open: item.open,
        close: item.close,
        volume: item.avolume ? item.avolume : item.sharevolume
      } as IChartQuote));

    if (!resolution.includes('D') && quotes.length >= 2) {
      const diff = (quotes[0].time - quotes[1].time) / (60 * 1000);

      // remove if last bar is not matching to the interval
      if (diff !== +resolution) {
        quotes.shift();
      }
    }

    const sortedQuotes = quotes.sort((a, b) => a.time > b.time ? 1 : -1);
    const latestQuote = sortedQuotes[sortedQuotes.length - 1];
    if (!this.lastQuote || (latestQuote && this.lastQuote.time < latestQuote.time)) {
      this.lastQuote = latestQuote;
    }

    onHistoryCallback(sortedQuotes, { noData: false });
  };

  resolveSymbol = (symbolName: string, onResolve: ResolveCallback) => {
    const split_data = symbolName.split(/[:/]/);

    const symbolInfo = {
      name: symbolName,
      description: "",
      session: "24x7",
      timezone: "Etc/UTC",
      ticker: symbolName,
      data_status: "streaming",
      exchange: split_data[0],
      has_intraday: true,
      has_ticks: true,
      session_display: "",
      intraday_multipliers: ['1', '3', '5', '15', '30', '60'],
      minmov: 1,
      pricescale: 100,
      volume_precision: 8,
    } as LibrarySymbolInfo;

    setTimeout(function () {
      onResolve(symbolInfo);
    }, 0);
  };

  searchSymbols = (userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback) => {
    if (!userInput) {
      return;
    }

    const { qmSid, symbol, webMasterId } = this;

    const queryParams = {
      webmasterId: webMasterId,
      startsWith: symbol,
      exgroup: 'nsd',
      sid: qmSid
    };

    const queryString = Object.keys(queryParams).map(param => `${param}=${queryParams[param]}`).join("&");
    const url = `getSymbolList.json?${queryString}`;

    fetch(`${qmApiDataUrl}/${url} `, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(this.handleErrors)
      .then(async (response) => {
        const data = await response.json();
        if (data.results.symbolcount === 0) {
          onResult([]);
          return;
        }

        const result: any[] = [];

        for (let s of data.results.lookupdata) {
          result.push({
            symbol: s.key.symbol,
            full_name: s.equityinfo.shortname,
            description: s.equityinfo.longname,
            exchange: s.key.exchange,
            type: "stock"
          });
        }

        onResult(result);
      })
      .catch(function (reason) {
        logSvc.error('chart/searchSymbols', reason);
      });
  };

  getDataFeed = () => ({
    onReady: this.onWidgetReady,
    searchSymbols: this.searchSymbols,
    resolveSymbol: this.resolveSymbol,
    getBars: this.getBars,
    subscribeBars: this.subscribeBars,
    unsubscribeBars: this.unsubscribeBars,
  });

  subscribeBars = (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void
  ) => {
    Object.keys(this.subscribers)
      .filter(guid => guid !== listenerGuid)
      .forEach(guid => delete this.subscribers[guid]);

    if (!(listenerGuid in this.subscribers)) {
      this.subscribers[listenerGuid] = {
        symbolInfo: symbolInfo,
        resolution: resolution,
        lastBar: this.lastQuote,
        listener: onTick
      };
    }
  };

  unsubscribeBars = (listenerGuid: string) => {
    delete this.subscribers[listenerGuid];
  };

  updateBar = (resolution, lastBar, trade: IQuoteMediaTrade) => {
    if (resolution.includes('D')) {
      resolution = 1440; // 1 day in minutes === 1440
    } else if (resolution.includes('W')) {
      resolution = 10080; // 1 week in minutes === 10080
    }

    const coeff = resolution * 60;
    const newTime = trade.timestamp;
    const rounded = Math.floor((newTime / 1000) / coeff) * coeff;
    const lastBarSec = lastBar.time / 1000;

    const bar = rounded > lastBarSec
      ? {
        time: newTime,
        low: lastBar.close,
        high: lastBar.close,
        open: lastBar.close,
        close: trade.price,
        volume: trade.accumulatedVolume
      } : {
        time: lastBar.time,
        low: trade.price < lastBar.low ? trade.price : lastBar.low,
        high: trade.price > lastBar.high ? trade.price : lastBar.high,
        open: lastBar.open,
        close: trade.price,
        volume: lastBar.volume + (trade.size * 100)
      };

    return bar;
  };

  updateBars = async (trade: IQuoteMediaTrade) => {
    for (var listenerGuid in this.subscribers) {
      (async (_listenerGUID, _subscriptionRecord) => {
        try {
          if (_subscriptionRecord.symbolInfo.ticker === trade.symbol) {
            const isListenerSubscribed = _listenerGUID in this.subscribers;
            if (!isListenerSubscribed || !_subscriptionRecord.lastBar) {
              return;
            }

            const latestBar = this.updateBar(_subscriptionRecord.resolution, _subscriptionRecord.lastBar, trade);
            _subscriptionRecord.listener(latestBar);
            _subscriptionRecord.lastBar = latestBar;
          }
        } catch (e) {
          logSvc.log('updateBars - ', e);
        }
      })(listenerGuid, this.subscribers[listenerGuid]);
    }
  }
}

const mapStateToProps = (state: IAppState, ownProps: IChartProps): IChartMappedProps => ({
  qmSid: state.qm.sid,
  trade: state.qm.tradeData[ownProps.symbol],
  userId: state.auth.authInfo?.UserId,
  wmid: state.auth.authInfo?.Meta.WMID || ""
});

const mapDispatchToProps = (dispatch: any, ownProps: IChartProps): IChartDispatchProps => ({
  setWidgetSymbol: (symbol: string) => dispatch(setWidgetSymbol(ownProps.windowId, symbol)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
