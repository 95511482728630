import store from "..";
import { IAccountPosition } from "../../models/IAccountPosition";
import { ISymbolPosition } from "../../models/ISymbolPosition";
import userAccountSvc from "../../services/user-account-service";
import { loadAccounts } from "../admin-accounts/accounts-async-actions";
import { IAppState } from "../reducers/IAppState";
import { loadUserAccounts } from "../user-accounts/user-account-async-action";
import {
  POSITIONS_COPY_STATE,
  POSITIONS_SET_ACCOUNTS,
  POSITIONS_SET_SYMBOLS,
  POSITIONS_UPDATE_ACCOUNT,
  POSITIONS_UPDATE_SYMBOL,
} from "./positions-types";

export const setAccountPositions =
  (positions: IAccountPosition[]) =>
  (dispatch: any, getState: () => IAppState) =>
    dispatch({
      type: POSITIONS_SET_ACCOUNTS,
      payload: positions,
      accounts: getState().account.userAccounts,
    });

export const setSymbolPositions =
  (positions: ISymbolPosition[]) =>
  (dispatch: any, getState: () => IAppState) =>
    dispatch({
      type: POSITIONS_SET_SYMBOLS,
      payload: positions,
      accounts: getState().account.userAccounts,
    });

export const updateAccountPosition =
  (position: IAccountPosition) =>
  async (dispatch: any, getState: () => IAppState) => {
    let accounts = getState().userAccountsList.userAccountsList;
    if (accounts.findIndex((x) => x.AccountId === position.AccountId) === -1) {
      // accounts = await userAccountSvc.get();
      // store.dispatch(loadAccounts);
      // store.dispatch(loadUserAccounts);
    }
    dispatch({
      type: POSITIONS_UPDATE_ACCOUNT,
      payload: position,
      accounts: accounts,
    });
  };

export const updateSymbolPosition =
  (position: ISymbolPosition) =>
  async (dispatch: any, getState: () => IAppState) => {
    let accounts = getState().userAccountsList.userAccountsList;
    if (accounts.findIndex((x) => x.AccountId === position.AccountId) === -1) {
      // accounts = await userAccountSvc.get();
      // store.dispatch(loadAccounts);
      // store.dispatch(loadUserAccounts);
    }
    dispatch({
      type: POSITIONS_UPDATE_SYMBOL,
      payload: position,
      accounts: accounts,
    });
  };

export const copyPositionState = (data) => (dispatch: any) =>
  dispatch({
    type: POSITIONS_COPY_STATE,
    payload: data,
  });
