import React, { useEffect, useState } from 'react';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Sort, } from '@syncfusion/ej2-react-grids';
import apiSvc from '../../../services/api-service';

const AutoFlatter = () => {
    const [autoFlatterData, setAutoFlatterData] = useState([]);

    const fetchData = async () => {
        const response = await apiSvc.get("SelectAutoFlatterRequest");
        console.log(response);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <GridComponent dataSource={autoFlatterData} allowSorting={true} height={270}>
                <ColumnsDirective>
                    <ColumnDirective field='Column 1' width='100' textAlign="Right" />
                    <ColumnDirective field='Column 2' width='100' />
                    <ColumnDirective field='Column 3' width='100' textAlign="Right" />
                    <ColumnDirective field='Column 4' width='100' format="C2" textAlign="Right" />
                    <ColumnDirective field='Column 5' width='100' />
                </ColumnsDirective>
                <Inject services={[Sort]} />
            </GridComponent>
        </div>
    )
}

export default AutoFlatter