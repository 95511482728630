import { ColumnModel } from "@syncfusion/ej2-grids";
import {
  ColumnDirective,
  ColumnsDirective,
  DataSourceChangedEventArgs,
  Edit,
  EditEventArgs,
  EditSettingsModel,
  Grid,
  GridComponent,
  InfiniteScroll,
  Inject,
  Page,
  PageSettingsModel,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import * as React from "react";
import { Component } from "react";
import FlexView from "react-flexview/lib";
import { connect } from "react-redux";
import { IBroker } from "../../../models/IBroker";
import brokerSvc from "../../../services/broker-services";
import { addBroker, deleteBroker, loadBrokersList } from "../../../store/broker/broker-async-action";
import { IAppState } from "../../../store/reducers/IAppState";
import { setTimeToUTC } from "../../../utils/datetime";
import CustomSpinner from "../spinner/spinner";
import { IBrokerListDispatchProps, IBrokerListMappedProps, IBrokerListProps } from "./IBrokerListProps";
import { IBrokerState } from "./IBrokerState";

type Props = IBrokerListProps & IBrokerListMappedProps & IBrokerListDispatchProps;

class BrokerList extends Component<Props, IBrokerState> {
  public editOptions: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Normal",
  };
  public toolbarOptions: ToolbarItems[] = ["Add", "Edit", "Delete", "Update", "Cancel", "Search"];
  public pageSettings: PageSettingsModel = { pageSize: 10 };
  private columnDefs: ColumnModel[] = [];
  private grid: Grid | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      brokersList: [],
    };
    this.columnDefs = this.getColumnDefs();
  }

  componentDidMount() {
    brokerSvc.get().then((brokers) => this.setState({ brokersList: brokers }));
  }

  debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = Grid;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  onLoad = () => {
    this.grid?.element.addEventListener(
      "keydown",
      this.debounce((e) => {
        if (e.target.getAttribute("id").indexOf("_searchbar") !== -1) {
          this.grid?.search((e.target as HTMLInputElement).value);
        }
      }, 250)
    );
  };

  render() {
    const { brokersList } = this.state;

    // <AdminBaseList columnDefs={this.columnDefs} rowData={brokersList} dataSourceChanged={this.dataSourceChanged} />
    const data = {
      result: brokersList,
      count: brokersList?.length,
    };
    if (brokersList.length > 0) {
      return (
        <FlexView className="hw-admin-grid ag-theme-alpine">
          <GridComponent
            id="grid"
            height="100%"
            width="100%"
            allowPaging={true}
            editSettings={this.editOptions}
            toolbar={this.toolbarOptions}
            load={this.onLoad}
            dataSource={data}
            dataSourceChanged={this.dataSourceChanged}
            enableInfiniteScrolling={true}
            ref={(g) => (this.grid = g)}
          >
            <ColumnsDirective>
              {this.columnDefs.map((column) => (
                <ColumnDirective key={column.field} headerText={column.headerText} {...column} />
              ))}
            </ColumnsDirective>
            <Inject services={[Edit, Toolbar, InfiniteScroll, Page]} />
          </GridComponent>
        </FlexView>
      );
    } else {
      return (
        <div className="m-auto">
          <CustomSpinner />
        </div>
      );
    }
  }

  getColumnDefs = (): ColumnModel[] => {
    return [
      {
        field: "BrokerName",
        headerText: "Broker",
        validationRules: { required: true },
      },
      {
        field: "CfgFileName",
        headerText: "Cfg FileName",
        validationRules: { required: true },
      },
      {
        field: "CancelTags",
        headerText: "Cancel Tags",
        // validationRules: { required: true },
      },
      {
        field: "ReplaceTags",
        headerText: "Replace Tags",
        // validationRules: { required: true },
      },
      {
        field: "Username",
        headerText: "Username",
        // validationRules: { required: true },
      },
      {
        field: "Password",
        headerText: "Password",
        template: "********",
        // validationRules: { required: true },
      },
    ];
  };
  dataSourceChanged = (args: DataSourceChangedEventArgs) => {
    const item: any = args.data;
    let brokerData: IBroker = {} as IBroker;
    const { form } = args as EditEventArgs;

    if (args.action === "add" || args.action === "edit") {
      const password = (form?.querySelector("#gridPassword") as HTMLInputElement).value;
      if (password) {
        item.Password = password;
      }
      brokerData = {
        BrokerName: item.BrokerName,
        CfgFileName: item.CfgFileName,
        CancelTags: item.CancelTags,
        ReplaceTags: item.ReplaceTags,
        Username: item.Username,
        Password: item.Password,
        CreatedBy: 13,
        CreatedOn: setTimeToUTC(new Date()),
        ModifiedBy: 13,
        ModifiedOn: setTimeToUTC(new Date()),
      };
    }
    if (args.action === "add") {
      brokerSvc.add(brokerData);
    } else if (args.action === "edit") {
      brokerData.BrokerId = item.BrokerId;

      brokerSvc.add(brokerData);
    } else if (args.requestType === "delete") {
      let key = args.data;
      if (key) {
        brokerSvc.delete(key[0].BrokerId);
      }
    }
  };
}

const mapStateToProps = (state: IAppState): IBrokerListMappedProps => ({
  brokersList: state.brokersList.brokersList || [],
});

const mapDispatchToProps = (dispatch: any): IBrokerListDispatchProps => ({
  loadBroker: () => dispatch(loadBrokersList),
  addBroker: (brokerData) => dispatch(addBroker(brokerData)),
  deleteBroker: (delKey) => dispatch(deleteBroker(delKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrokerList);
