export const getClientInfo = () => {
  const { appName, appVersion, userAgent } = navigator;
  let { appName: browserName } = navigator;
  let majorVersion = parseInt(appVersion, 10);
  let fullVersion = '' + parseFloat(appVersion);
  let nameOffset: number, verOffset: number, ix: number;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = userAgent.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    fullVersion = userAgent.substring(verOffset + 6);
    if ((verOffset = userAgent.indexOf("Version")) !== -1)
      fullVersion = userAgent.substring(verOffset + 8);
  }

  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = userAgent.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = userAgent.substring(verOffset + 5);
  }

  // In Chrome, the true version is after "Chrome" 
  else if ((verOffset = userAgent.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    fullVersion = userAgent.substring(verOffset + 7);
  }

  // In Safari, the true version is after "Safari" or after "Version" 
  else if ((verOffset = userAgent.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    fullVersion = userAgent.substring(verOffset + 7);
    if ((verOffset = userAgent.indexOf("Version")) !== -1)
      fullVersion = userAgent.substring(verOffset + 8);
  }

  // In Firefox, the true version is after "Firefox" 
  else if ((verOffset = userAgent.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    fullVersion = userAgent.substring(verOffset + 8);
  }

  // In most other browsers, "name/version" is at the end of userAgent 
  else if ((nameOffset = userAgent.lastIndexOf(' ') + 1) <
    (verOffset = userAgent.lastIndexOf('/'))) {
    browserName = userAgent.substring(nameOffset, verOffset);
    fullVersion = userAgent.substring(verOffset + 1);

    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = appName;
    }
  }

  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) !== -1) {
    fullVersion = fullVersion.substring(0, ix);
  }

  if ((ix = fullVersion.indexOf(" ")) !== -1) {
    fullVersion = fullVersion.substring(0, ix);
  }

  majorVersion = parseInt('' + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(appVersion);
    majorVersion = parseInt(appVersion, 10);
  }

  let operatingSystem = "Unknown OS";
  if (appVersion.indexOf("Win") !== -1) {
    operatingSystem = "Windows";
  } else if (appVersion.indexOf("Mac") !== -1) {
    operatingSystem = "MacOS";
  } else if (appVersion.indexOf("X11") !== -1) {
    operatingSystem = "UNIX";
  } else if (appVersion.indexOf("Linux") !== -1) {
    operatingSystem = "Linux";
  }

  const clientInfo = {
    operatingSystem,
    browserName,
    fullVersion,
    majorVersion,
    navigator: {
      appName,
      userAgent
    }
  };

  return JSON.stringify(clientInfo);
}
