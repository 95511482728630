import { ILinkedWidget } from "../../models/ILinkedWidget";
import globalCache from "../../services/global-cache";
import tempCache from "../../services/temp-cache";
import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import {
  WIDGETS_ACTIVE_WIDGET,
  WIDGETS_COPY_WIDGET_STATE,
  WIDGETS_DETACH_NEW_WIDGET,
  WIDGETS_LINK_WIDGET,
  WIDGETS_LOAD_LINKED_WIDGETS,
  WIDGETS_LOAD_WIDGET_SYMBOLS,
  WIDGETS_SET_LINKED_SYMBOL,
  WIDGETS_SET_WIDGET_SYMBOLS,
  WIDGETS_UNLINK_WIDGET,
} from "./widgets-types";
import { WidgetsState } from "./WidgetsState";

const initialState = new WidgetsState();

function widgetsReducer(state = initialState, action: any): WidgetsState {
  let linkedWidgets: ILinkedWidget[];

  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new WidgetsState();

    case WIDGETS_ACTIVE_WIDGET:
      return {
        ...state,
        activeWidget: {
          ...state.activeWidget,
          [action.payload.workspaceId]: action.payload.widgetId,
        },
      };

    case WIDGETS_SET_LINKED_SYMBOL:
      linkedWidgets = [...state.linkedWidgets];

      linkSymbol(linkedWidgets, action.payload.widgetId, [action.payload.symbol]);
      saveLinkedWidgetsToCache(action.payload.userId, linkedWidgets);

      return {
        ...state,
        linkedWidgets,
      };

    case WIDGETS_SET_WIDGET_SYMBOLS:
      linkedWidgets = [...state.linkedWidgets];
      linkSymbol(linkedWidgets, action.payload.widgetId, action.payload.symbols);
      saveLinkedWidgetsToCache(action.payload.userId, linkedWidgets);
      const symbols = {
        ...state.symbols,
        [action.payload.widgetId]: action.payload.symbols,
      };

      saveWidgetSymbolsToCache(action.payload.userId, symbols);

      return {
        ...state,
        linkedWidgets,
        symbols,
      };

    case WIDGETS_LINK_WIDGET:
      linkedWidgets = [...state.linkedWidgets];
      const { link, symbol, userId, widgetId, workspaceId } = action.payload;

      unlinkWidget(linkedWidgets, widgetId);
      linkWidget(linkedWidgets, workspaceId, widgetId, link);
      if (symbol) {
        linkSymbol(linkedWidgets, widgetId, [symbol], true);
      }

      saveLinkedWidgetsToCache(userId, linkedWidgets);

      return {
        ...state,
        linkedWidgets,
      };

    case WIDGETS_LOAD_LINKED_WIDGETS:
      return {
        ...state,
        linkedWidgets: action.payload,
      };

    case WIDGETS_UNLINK_WIDGET:
      linkedWidgets = [...state.linkedWidgets];
      unlinkWidget(linkedWidgets, action.payload.widgetId);
      saveLinkedWidgetsToCache(action.payload.userId, linkedWidgets);

      return {
        ...state,
        linkedWidgets,
      };

    case WIDGETS_LOAD_WIDGET_SYMBOLS:
      return {
        ...state,
        symbols: action.payload,
      };

    case WIDGETS_DETACH_NEW_WIDGET:
      return {
        ...state,
        detachedWindows: state.detachedWindows.concat(action.payload.windowInstance),
      };

    case WIDGETS_COPY_WIDGET_STATE:
        return action.payload;

    default:
      return state;
  }
}

export default widgetsReducer;

function linkSymbol(linkedWidgets: ILinkedWidget[], widgetId: string, symbols: string[], ignoreIfExists?: boolean) {
  let linkedWidget = linkedWidgets.find((x) => x.widgets.indexOf(widgetId) !== -1);
  symbols = symbols
    .filter(x => x.indexOf("|") === -1 || x.split("|")[1] === "symbol")
    .map(x => x.split("|")[0]);

  if (linkedWidget && (!ignoreIfExists || !linkedWidget.symbol) && symbols.length > 0) {
    const idx = linkedWidgets.indexOf(linkedWidget);
    linkedWidget = {
      ...linkedWidget,
      symbol: symbols[0],
    };

    linkedWidgets[idx] = linkedWidget;
  }
}

function linkWidget(linkedWidgets: ILinkedWidget[], workspaceId: string, widgetId: string, link: string) {
  let linkedWidget = linkedWidgets.find((x) => x.workspaceId === workspaceId && x.color === link);
  if (linkedWidget) {
    const idx = linkedWidgets.indexOf(linkedWidget);

    linkedWidget = {
      ...linkedWidget,
      widgets: [...linkedWidget.widgets, widgetId],
    };

    linkedWidgets[idx] = linkedWidget;
  } else {
    linkedWidgets.push({
      color: link,
      widgets: [widgetId],
      workspaceId: workspaceId,
    });
  }
}

function saveLinkedWidgetsToCache(userId: string, linkedWidgets: ILinkedWidget[]) {
  const { linkedWidgets: allUsersLinkedWidgets } = globalCache;
  allUsersLinkedWidgets[userId] = linkedWidgets;
  globalCache.linkedWidgets = allUsersLinkedWidgets;
}

function saveWidgetSymbolsToCache(userId: string, widgetSymbols: { [key: string]: string[] }) {
  const { isDataLoaded } = tempCache;
  const allUsersWidgetSymbols = globalCache.widgetSymbols || {};

  if (isDataLoaded) {
    allUsersWidgetSymbols[userId] = widgetSymbols;
  } else {
    allUsersWidgetSymbols[userId] = {
      ...allUsersWidgetSymbols[userId],
      ...widgetSymbols
    };
  }

  globalCache.widgetSymbols = allUsersWidgetSymbols;
}

function unlinkWidget(linkedWidgets: ILinkedWidget[], widgetId: string) {
  let linkedWidget = linkedWidgets.find((x) => x.widgets.indexOf(widgetId) !== -1);

  if (linkedWidget) {
    const idx = linkedWidgets.indexOf(linkedWidget);
    linkedWidget = { ...linkedWidget };
    const widgets = [...linkedWidget.widgets];
    widgets.splice(linkedWidget.widgets.indexOf(widgetId), 1);

    if (widgets.length > 0) {
      linkedWidget.widgets = widgets;
      linkedWidgets[idx] = linkedWidget;
    } else {
      linkedWidgets.splice(idx, 1);
    }
  }
}
