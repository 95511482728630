import { IColumnFilter } from "../../models/IColumnFilter";
import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import {
  ORDER_BLOTTER_ADD_COLUMN_FILTER,
  ORDER_BLOTTER_COPY_STATE,
  ORDER_BLOTTER_REMOVE_COLUMN_FILTER,
  ORDER_BLOTTER_RESET_COLUMN_FILTERS,
} from "./order-blotter-types";
import { OrderBlotterState } from "./OrderBlotterState";

const initialState = new OrderBlotterState();

export default function orderBlotterReducer(state = initialState, action: any): OrderBlotterState {
  let columnFilters: IColumnFilter[];

  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new OrderBlotterState();

    case ORDER_BLOTTER_ADD_COLUMN_FILTER:
      columnFilters = removeColumnFilter([...state.columnFilters], action.payload.field);

      return {
        ...state,
        columnFilters: [...columnFilters, action.payload],
      };

    case ORDER_BLOTTER_REMOVE_COLUMN_FILTER:
      columnFilters = removeColumnFilter([...state.columnFilters], action.payload);

      return {
        ...state,
        columnFilters,
      };

    case ORDER_BLOTTER_RESET_COLUMN_FILTERS:
      return {
        ...state,
        columnFilters: [],
      };

    case ORDER_BLOTTER_COPY_STATE:
      return action.payload;

    default:
      return state;
  }
}

function removeColumnFilter(columnFilters: IColumnFilter[], field: string) {
  const idx = columnFilters.findIndex((x) => x.field === field);
  if (idx !== -1) {
    columnFilters.splice(idx, 1);
  }

  return columnFilters;
}
