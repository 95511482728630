import { IGroupPosition } from "../models/IGroupPosition";
import apiSvc from "./api-service";

class GroupPositionService {
  groupPosition: IGroupPosition[] = [];

  async get() {
    const response = await apiSvc.get("SelectGrpPositionRequest");
    return (this.groupPosition = response?.data.Data);
  }
}

const groupPositionSvc = new GroupPositionService();
export default groupPositionSvc;
