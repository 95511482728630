import { IQuoteMediaPrice } from "../../models/IQuoteMediaPrice";
import { IQuoteMediaQuote } from "../../models/IQuoteMediaQuote";
import { IQuoteMediaTrade } from "../../models/IQuoteMediaTrade";
import { IWatchList } from "../../models/IWatchList";
import { IMarketDepth } from "../../models/IMarketDepth";
import { IQuoteMediaHeadline } from "../../models/IQuoteMediaHeadline";
import { IQuoteMediaMMQuote } from "../../models/IQuoteMediaMMQuote";
import { ILevelIIStats } from "../../models/ILevelIIStats";
import { QuoteMediaAuthStatus } from "../../enums/QuoteMediaAuthStatus";
import { QuoteMediaConnectionStatus } from "../../enums/QuoteMediaConnectionStatus";

export class QuoteMediaState {
  accountFilter = "";
  accountFilterDd = "";
  authStatus = QuoteMediaAuthStatus.NotAuthenticated;
  connectionStatus = QuoteMediaConnectionStatus.NotConnected;
  levelIIStats: { [symbol: string]: ILevelIIStats } = {};
  headlines: IQuoteMediaHeadline[] = [];
  marketDepths: IMarketDepth[] = [];
  mmQuoteData: { [symbol: string]: IQuoteMediaMMQuote } = {};
  orderStatusFilter = "";
  priceData: { [symbol: string]: IQuoteMediaPrice } = {};
  quoteData: { [symbol: string]: IQuoteMediaQuote } = {};
  searchFilter = "";
  symbolLongNames: { [symbol: string]: string } = {};
  sid = "";
  sidGeneratedAt = new Date();
  symbolFilterDd = "";
  symbolsDataLevelII = [];
  timeSalesSymbolData = {};
  tradeData: { [symbol: string]: IQuoteMediaTrade } = {};
  tradesList: { [symbol: string]: IQuoteMediaTrade[] } = {};
  watchListData: any[] = [];
  watchList: IWatchList[] = [];
}
