import React from "react";

const CustomLoadingOverlay = (props) => {
  return (
    <div>
      <i className="fas fa-spinner fa-pulse"></i>{" "}
      <span> {props.loadingMessage}</span>
    </div>
  );
};

export default CustomLoadingOverlay;
