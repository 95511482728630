import { IAccountTradeLimit } from "./../../models/IAccountTradeLimit";
import uuid from "react-uuid";
import { AccountStatusEnum } from "../../enums/AccountStatusEnum";
import { ExecutionReport } from "../../models/ExecutionReport";
import { IAccount } from "../../models/IAccount";
import { IAccountOrder } from "../../models/IAccountOrder";
import { NewOrder } from "../../models/NewOrder";
import tempCache from "../../services/temp-cache";
import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import {
  ACCOUNTS_COPY_ACCOUNT_STATE,
  ACCOUNT_ADD_ASSIGNED_USER_ACCOUNT,
  ACCOUNT_REMOVE_UNASSIGNED_USER_ACCOUNT,
  ACCOUNT_SET_ACCOUNTS,
  ACCOUNT_SET_ACCOUNT_STATUS,
  ACCOUNT_SET_ACCOUNT_TRADE_LIMITS,
  ACCOUNT_SET_BUYING_POWER,
  ACCOUNT_SET_DETACH_WINDOW_DETAILS_IN_PROGRESS,
  ACCOUNT_SET_ORDERS,
  ACCOUNT_SET_ORDER_EXECUTIONS,
  ACCOUNT_SET_USER_SYMBOL_LIST,
  ACCOUNT_UPDATE_ASSIGNED_USER_ACCOUNT,
  ADD_NEW_ORDER,
  ADD_ORDER_EXECUTION,
} from "./account-types";
import { AccountState } from "./AccountState";
import store from "..";
import { OrderStatusEnum } from "../../enums/OrderStatusEnum";
import { IUserAccount } from "../../models/IUserAccount";
import { IAccountDestination } from "../../models/IAccountDestination";
import userAccountSvc from "../../services/user-account-service";
import { OrderSourceEnum } from "../../enums/OrderSourceEnum";
import { toJsonDateTime } from "../../utils/datetime";
import { IOrderExecution } from "../../models/IOrderExecution";
import { OrderSideEnum } from "../../enums/OrderSideEnum";
import { IUser } from "../../models/IUser";
import moment from "moment";
import { cleanUpMap } from "../../utils/general";
import IndexedDBService from "../../utils/idb_helper";

const initialState = new AccountState();

export default function accountReducer(
  state = initialState,
  action: any
): AccountState {
  let userAccount: IAccount;
  let userAccounts: IAccount[];
  let userAccountIdx: number;
  let userAccountTradeLimit: IAccountTradeLimit;

  switch (action.type) {
    case ACCOUNT_ADD_ASSIGNED_USER_ACCOUNT:
      userAccount = action.payload;
      userAccounts = [...state.userAccounts, userAccount];
      return getNewState(state, userAccounts);

    case ACCOUNT_REMOVE_UNASSIGNED_USER_ACCOUNT:
      userAccounts = [...state.userAccounts];
      const idx = userAccounts.findIndex((x) => x.AccountId === action.payload);
      if (idx === -1) {
        return state;
      }

      userAccounts.splice(idx, 1);
      return getNewState(state, userAccounts);

    case ACCOUNT_SET_ACCOUNTS:
      return getNewState(state, action.payload);

    case ACCOUNT_SET_BUYING_POWER:
      userAccounts = [...state.userAccounts];
      userAccountTradeLimit = {
        ...(userAccounts.find((x) => x.AccountId === action.payload.accountId)
          ?.AccountTradeLimit as IAccountTradeLimit),
        BuyingPower: action.payload.buyingPower,
      };
      return getNewState(state, userAccounts);

    case ACCOUNT_SET_ACCOUNT_STATUS:
      userAccounts = [...state.userAccounts];
      userAccount = {
        ...(userAccounts.find(
          (x) => x.AccountId === action.payload.accountId
        ) as IAccount),
        AccountStatusID: action.payload.status,
        AccountStatus: {
          AccountStatusID: action.payload.status,
          Description: AccountStatusEnum[action.payload.status],
          Status: AccountStatusEnum[action.payload.status],
        },
      };

      return getNewState(state, userAccounts);

    case ACCOUNT_SET_ORDERS:
      let newOrdersData = action.payload;
      let existingOrdersData = state.orderLookup;
      let lookupData = new Map<BigInt, IAccountOrder>();
      /**To avoid rowId overwritten for grid in order-blotter */
      newOrdersData.forEach((order) => {
        const pos = existingOrdersData.get(order.ClientOrderId);
        if (pos) {
          order.RowId = existingOrdersData.get(order.ClientOrderId)?.RowId;
        } else {
          order.RowId = uuid();
        }
        order.IsOpen = order.Status === "New" || order.Status === "PartiallyFilled" || order.Status === "Sending";
        lookupData.set(BigInt(order.ClientOrderId), order as IAccountOrder);
      });

      let keyValueArray = Array.from(lookupData.values());

      keyValueArray.sort((a, b) => {
        const dateTimeA = moment(a.DateTime);
        const dateTimeB = moment(b.DateTime);
        return dateTimeB.diff(dateTimeA);
      });

      keyValueArray = keyValueArray.slice(0, 100)
      
      return {
        ...state,
        userOrders: keyValueArray,
        orderLookup: lookupData
      };

    case ADD_NEW_ORDER:
      const { accountName, displayName, payload: order } = action;
      const newOrders = addNewOrder(
        order,
        displayName,
        accountName,
        action.userAccounts,
        action.destinationLookup,
        action.users,
        state.orderLookup
      );

      return {
        ...state,
        userOrders: Array.from(newOrders.values()).slice(-100),
        orderLookup: cleanUpMap(newOrders, 50),
      };

    case ADD_ORDER_EXECUTION:
      const executionReport: ExecutionReport = action.payload;

      const {executedOrders, lastOrder} = addOrderExecution(
        action.payload,
        action.userAccounts,
        action.destinationLookup,
        action.users,
        state.orderLookup
      );

      const orderExecutions = [...state.orderExecutions];
      const account = action.userAccounts.find((w) => w.AccountId == executionReport.accountId)?.Account;
      const destinationsList = action.destinationLookup;

      if (executionReport.lastFillQuantity > 0) {
        const dateTime = `${toJsonDateTime(new Date(Number(executionReport.time))).replace('Z', '')}-05:00`;

        const execution: IOrderExecution = {
          ...executionReport,
          Account: account,
          AccountId: executionReport.accountId,
          AccountName: account?.Name,
          AvgFillPrice: executionReport.avgPrice,
          ClOrdId: BigInt(executionReport.orderId).toString(),
          ClientOrderId: BigInt(executionReport.orderId).toString(),
          DateTime: dateTime,
          DestId: lastOrder.DestId,
          Destination: destinationsList?.find(x => x.DestinationId === lastOrder.DestId)?.DisplayName,
          ExecTime: dateTime,
          ExecutionID: executionReport.executionId,
          FillPrice: executionReport.lastPrice,
          FillQty: executionReport.lastFillQuantity,
          Id: parseInt(executionReport.exchangeOrderId),
          LiqFlag: executionReport.liqFlag,
          OrderId: `${executionReport.exchangeOrderId}`,
          OrigOrderId: `${executionReport.origOrderId}`,
          Price: executionReport.price,
          Side: OrderSideEnum[executionReport.side],
          Symbol: executionReport.symbol,
          TIF: lastOrder.TIF,
          RowId: uuid()
        };

        orderExecutions.unshift(execution);
      }

      return {
        ...state,
        // userOrders: executedOrders,
        userOrders: Array.from(executedOrders.values()).slice(-100),
        orderLookup: cleanUpMap(executedOrders, 50),
        orderExecutions
      };

    case ACCOUNT_SET_ACCOUNT_TRADE_LIMITS:
      userAccounts = [...state.userAccounts];
      userAccountIdx = userAccounts.findIndex(
        (x) => x.AccountId === action.payload.AccountId
      );
      if (userAccountIdx !== -1) {
        userAccounts[userAccountIdx] = {
          ...userAccounts[userAccountIdx],
          AccountTradeLimit: action.payload,
        };
      }

      return getNewState(state, userAccounts);

    case ACCOUNT_SET_USER_SYMBOL_LIST:
      return {
        ...state,
        userSymbols: [...action.payload],
      };

    case ACCOUNT_SET_DETACH_WINDOW_DETAILS_IN_PROGRESS:
      return {
        ...state,
        isDetachWindowDetails: action.payload,
      };

    case ACCOUNT_UPDATE_ASSIGNED_USER_ACCOUNT:
      userAccounts = [...state.userAccounts];
      userAccountIdx = userAccounts.findIndex(
        (x) => x.AccountId === action.payload.AccountId
      );
      userAccounts[userAccountIdx] = action.payload;
      return getNewState(state, userAccounts);

    case AUTH_LOGOUT_USER:
      return new AccountState();

    case ACCOUNTS_COPY_ACCOUNT_STATE:
      return action.payload

    case ACCOUNT_SET_ORDER_EXECUTIONS:
      return {
        ...state,
        orderExecutions: action.payload,
      };

    default:
      return state;
  }
}

export const initializeAccountState = () => {
  const { userAccounts } = tempCache;
  if (userAccounts) {
    initialState.userAccounts = userAccounts;
    initialState.userAccount = userAccounts[0];
    initialState.accountId = initialState.userAccount.AccountId;
  }

  const { userOrders } = tempCache;
  if (userOrders) {
    initialState.userOrders = userOrders;
  }
};

function addNewOrder(
  newOrder: NewOrder,
  displayName: string,
  accountName: string,
  userAccounts: IUserAccount[],
  destinations: IAccountDestination[],
  users: IUser[],
  orderLookup: Map<BigInt, IAccountOrder>
): Map<BigInt, IAccountOrder> {
  
  // accOrder.SentUserName = displayName;

  // const existingOrder = orders.find(
  //   (x) =>
  //     x.ClientOrderId &&
  //     (BigInt(x.ClientOrderId) === BigInt(newOrder.id))
  // ) as IAccountOrder

  const existingOrder = orderLookup.get(BigInt(newOrder.id));

  if(existingOrder && existingOrder.ClientOrderId)
  {
    return orderLookup;
  }

  const accOrder = newOrder.getAccountOrder(accountName);
  accOrder.Destination = destinations.find(
    (x) => x.AccountId === accOrder.AccountId
  )?.DisplayName;

  accOrder.AccountName =
    userAccounts.find((x) => x.AccountId === accOrder.AccountId)?.Account
      .Name || "";

  accOrder.SentUserName = accOrder.Source === "AutoFlatter" ? "Auto Flatter" : users.find(
    (x) => x.Id === accOrder.SentUserId
  )?.UserName;

  orderLookup.set(BigInt(accOrder.ClientOrderId), accOrder);
  IndexedDBService.writeToDB("Orders", "ClientOrderId", accOrder);
  return orderLookup;
  // orders.unshift(accOrder);
  // return orders;
}

function addOrderExecution(
  executionReport: ExecutionReport,
  userAccounts: IUserAccount[],
  destinations: IAccountDestination[],
  users: IUser[],
  orderLookup: Map<BigInt, IAccountOrder>
):  {
  executedOrders: Map<BigInt, IAccountOrder>;
  lastOrder: IAccountOrder;
} {
  // const existingOrder = orders.find(
  //   (x) =>
  //     x.ClientOrderId &&
  //     (BigInt(x.ClientOrderId) === executionReport.orderId ||
  //       BigInt(x.ClientOrderId) === executionReport.origOrderId)
  // ) as IAccountOrder;

  const existingOrder: any = (orderLookup.get(executionReport.orderId) || orderLookup.get(executionReport.origOrderId)) as IAccountOrder;

  const accountOrder = executionReport.updateAccountOrder(existingOrder);

  accountOrder.Destination = destinations.find(
    (x) => x.AccountId === accountOrder.AccountId
  )?.DisplayName;

  accountOrder.AccountName =
    userAccounts.find((x) => x.AccountId === accountOrder.AccountId)?.Account
      .Name || "";

  accountOrder.SentUserName = accountOrder.Source === "AutoFlatter" ? "Auto Flatter" : users.find(
    (x) => x.Id === accountOrder.SentUserId
  )?.UserName;

  if (executionReport.status === OrderStatusEnum.Cancelled) {
    accountOrder.CancelledUserId = executionReport.userId;
    accountOrder.CancelledUserName = userAccounts.find(
      (x) => x.UserId === executionReport.userId
    )?.User.UserName;
  }

  if (!existingOrder) {
    accountOrder.RowId = uuid();
  }

  if(accountOrder.ClientOrderId)
  {
    IndexedDBService.writeToDB("Orders", "ClientOrderId", accountOrder);
    orderLookup.set(BigInt(accountOrder.ClientOrderId), accountOrder);
  }


  return {executedOrders: orderLookup, lastOrder: accountOrder};

  // const existingOrderIdx = orders.indexOf(existingOrder);
  // orders.splice(existingOrderIdx, 1, accountOrder);
  // return {executedOrders: orders, lastOrder: accountOrder};
}

function getBuyingPower(accounts: IAccount[]) {
  if (!accounts || accounts.length === 0) {
    return 0;
  }

  return accounts
    .map((x) => x.AccountTradeLimit?.BuyingPower || 0)
    .reduce((accumulate, current) => accumulate + current);
}

function getNewState(state: AccountState, accounts: IAccount[]): AccountState {
  const firstAccount = accounts[0];

  return {
    ...state,
    accountId: firstAccount?.AccountId,
    userAccount: firstAccount,
    userAccounts: accounts,
    buyingPower: getBuyingPower(accounts) || 0,
    destinationsLookup: accounts
      .filter((x) => x.AccountDestination)
      .flatMap((x) => x.AccountDestination),
  };
}
