import { Component } from "react";
import FlexView from "react-flexview";
import { connect } from "react-redux";
import { IAccount } from "../../models/IAccount";
import { IAccountPosition } from "../../models/IAccountPosition";
import { IAppState } from "../../store/reducers/IAppState";
import NumericText from "../controls/numeric-text";
import { IAccountSummaryProps } from "./IAccountSummaryProps";
import { AccountSummaryState } from "./IAccountSummaryState";
import "./styles.scss";

class AccountSummary extends Component<IAccountSummaryProps, AccountSummaryState> {
  render() {
    const { positions, userAccounts, openPnL, positionValue } = this.props;

    let userAccount: IAccount = null as any;
    let position: IAccountPosition | undefined = undefined;

    if (userAccounts.length > 0) {
      userAccount = userAccounts[0];
      position = positions.find((x) => x.AccountId === userAccount.AccountId);
    }

    return (
      <FlexView column className="account-summary" grow>
        <FlexView>
          <FlexView grow>Account</FlexView>
          <FlexView grow hAlignContent="right">
            {userAccount?.Name}
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>Group</FlexView>
          <FlexView grow hAlignContent="right">
            {userAccount?.AccountGroup?.Name}
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>Firm</FlexView>
          <FlexView grow hAlignContent="right">
            {userAccount?.Firm?.Name}
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>Currency</FlexView>
          <FlexView grow hAlignContent="right">
            {userAccount?.Currency?.Name}
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>Account Status</FlexView>
          <FlexView grow hAlignContent="right">
            {userAccount?.AccountStatus?.Status}
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>Max Loss</FlexView>
          <FlexView grow hAlignContent="right">
            <NumericText value={position?.MaxLoss} />
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>Total Position</FlexView>
          <FlexView grow hAlignContent="right">
            <NumericText value={positionValue} />
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>Open Pnl</FlexView>
          <FlexView grow hAlignContent="right">
            <NumericText value={openPnL} />
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>Close Pnl</FlexView>
          <FlexView grow hAlignContent="right">
            <NumericText value={position?.RealPL} />
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>Total Pnl</FlexView>
          <FlexView grow hAlignContent="right">
            <NumericText value={openPnL + (position?.RealPL || 0)} />
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>Qty Traded</FlexView>
          <FlexView grow hAlignContent="right">
            <NumericText value={position?.QtyTraded} />
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>Exposure</FlexView>
          <FlexView grow hAlignContent="right" style={{ color: "#F322C9" }}>
            <NumericText value={position?.Exposure} />
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>Buying Power</FlexView>
          <FlexView grow hAlignContent="right" style={{ color: "#217FE2" }}>
            <NumericText value={userAccount?.AccountTradeLimit?.BuyingPower} />
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>BP used</FlexView>
          <FlexView grow hAlignContent="right">
            <NumericText value={position?.BPUsed} />
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>BP Available</FlexView>
          <FlexView grow hAlignContent="right">
            <NumericText value={userAccount?.AccountTradeLimit?.BuyingPower - (position?.BPUsed || 0)} />
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>$DrawDown</FlexView>
          <FlexView grow hAlignContent="right">
            <NumericText value={position?.DrawDown} />
          </FlexView>
        </FlexView>

        <FlexView>
          <FlexView grow>$RunUp</FlexView>
          <FlexView grow hAlignContent="right">
            <NumericText value={position?.RunUp} />
          </FlexView>
        </FlexView>
      </FlexView>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    positions: state.positions.accounts,
    openPnL: state.positions.openPnL,
    positionValue: state.positions.position,
    userAccounts: state.account.userAccounts,
  };
};

export default connect(mapStateToProps)(AccountSummary);
