import store from "..";
import { IAccount } from "../../models/IAccount";
import { IAccountOrder } from "../../models/IAccountOrder";
import { IAccountPosition } from "../../models/IAccountPosition";
import apiSvc from "../../services/api-service";
import notificationSvc from "../../services/notification-service";
import { buildQueryString } from "../../utils/general";
import IndexedDBService from "../../utils/idb_helper";
import { setAccounts } from "../admin-accounts/accounts-actions";
import {
  setAccountPositions,
  setSymbolPositions,
} from "../positions/positions-actions";
import { IAppState } from "../reducers/IAppState";
import { setExecutions, setOrders } from "../users/users-async-actions";
import { setUserAccounts } from "./user-account-actions";
import JSONbig from "json-bigint";

export async function loadUserAccounts(dispatch: any) {
  const userAccountsData = await apiSvc.get("SelectUserAccountsRequest");
  dispatch(setUserAccounts(userAccountsData?.data.Data));
  return userAccountsData?.data.Data;
}

export const addUserAccounts = (userAccount) => async () => {
  await apiSvc.api.put("SaveUserAccountsRequest", userAccount);
};

export const deleteUserAccount = (delKeys) => async () => {
  apiSvc.api.delete("DeleteUserAccountsRequest", {
    params: {
      PrimaryKey: delKeys.primaryKey,
      UserId: delKeys.userId,
      LoadCache: true,
    },
  });
};

export const getOrders = async (queryParams: string) => {
  try {
    let url = `api/SelectOrdersForAuthorizedAccountsRequest?${queryParams}`;
    const response = await apiSvc.api.get(url, {
      transformResponse: (data) => {
        const parsed = JSONbig.parse(data);
        if (!parsed.IsSuccess) {
          return parsed;
        }

        return {
          ...parsed,
          orders: parsed.orders.map((order: IAccountOrder) => ({
            ...order,
            ClientOrderId: BigInt(order.ClientOrderId.toString()),
          })),
        };
      },
    });
    if (!response.data.IsSuccess) {
      notificationSvc.error(
        "An unexpected error has occurred. Unable to fetch Order details!"
      );
      return null;
    }
    if (response.data.orders && response.data.orders.length) {
      response.data.orders = response.data.orders.map((order) => {
        return {
          ...order,
          ClientOrderId: BigInt(order.ClientOrderId.toString()),
        };
      });
    }

    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getPositions = async (queryParams: string) => {
  try {
    let url = `api/SelectPositionsForAuthorizedAccountsRequest?${queryParams}`;
    const response = await apiSvc.api.get(url);
    if (!response.data.IsSuccess) {
      notificationSvc.error(
        "An unexpected error has occurred. Unable to fetch Positions details!"
      );
      return null;
    }
    if (response.data.symbolsPositions && response.data.accountPositions) {
      IndexedDBService.saveCollectionToDB("SymbolPositions", "Id", response.data.symbolsPositions.map((sym) => {return {...sym, TimeStamp: Date.now()}}));
      IndexedDBService.saveCollectionToDB("AccountPositions", "AccountId", response.data.accountPositions.map((acc) => {return {...acc, TimeStamp: Date.now()}}));
      return response.data;
    }

    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const loadPositionData = () => {
  try {
    const { authInfo } = store.getState().auth;
    if (!authInfo) {
      return;
    }

    let queryParams = {
      UserId: authInfo.UserId,
      Role: authInfo.Meta.RoleName,
    };

    getPositions(buildQueryString(queryParams));
  } catch (e) {
    console.error(e);
  }
};

export const fetchUserOrders =
  (accountId?) => (dispatch: any, getState: () => IAppState) => {
    const { authInfo } = getState().auth;
    let url = "";
    if (!authInfo) {
      return;
    }
    let accounts: any = getState().adminAccounts.accounts;
    if (accounts && accounts.length) {
      const userAccount = accountId
        ? accounts.filter((x) => x.AccountId === accountId)
        : accounts;

      if (accountId === undefined) {
        url = `api/SelectOrdersForAuthorizedAccountsRequest?UserId=${authInfo.UserId}&Role=${authInfo.Meta.RoleName}`;
      } else {
        url = `api/SelectOrdersForAuthorizedAccountsRequest?SelectKey=${accountId}&SelectColumnName=AccountId&UserId=${authInfo.UserId}&Role=${authInfo.Meta.RoleName}`;
      }
      apiSvc.api
        .get(url, {
          transformResponse: (data) => {
            const parsed = JSONbig.parse(data);
            if (!parsed.IsSuccess) {
              return parsed;
            }

            return {
              ...parsed,
              orders: parsed.orders.map((order: IAccountOrder) => ({
                ...order,
                ClientOrderId: BigInt(order.ClientOrderId.toString()),
              })),
            };
          },
        })
        .then((res) => {
          if (res.data.IsSuccess === false) {
            notificationSvc.error(
              "An unexpected error has occurred. Unable to fetch your details!"
            );
            return;
          }
          if (res.data.orders && res.data.orders.length) {
            setAccountName(res.data.orders, userAccount);
            let orders = res.data.orders.map((order) => {
              return {
                ...order,
                ClientOrderId: BigInt(order.ClientOrderId.toString()),
              };
            });
            dispatch(setOrders(orders));
          }
          if (res.data.executions) {
            setAccountName(res.data.executions, userAccount);
            dispatch(setExecutions(res.data.executions));
          }
        });
    } else {
      apiSvc.get("SelectAccountsRequest").then((response) => {
        accounts = response?.data.Data;
        store.dispatch(setAccounts(accounts));
        const userAccount = accountId
          ? accounts.filter((x) => x.AccountId === accountId)
          : accounts;

        if (accountId === undefined) {
          url = `api/SelectOrdersForAuthorizedAccountsRequest?UserId=${authInfo.UserId}&Role=${authInfo.Meta.RoleName}`;
        } else {
          url = `api/SelectOrdersForAuthorizedAccountsRequest?SelectKey=${accountId}&SelectColumnName=AccountId&UserId=${authInfo.UserId}&Role=${authInfo.Meta.RoleName}`;
        }
        apiSvc.api
          .get(url, {
            transformResponse: (data) => {
              const parsed = JSONbig.parse(data);
              if (!parsed.IsSuccess) {
                return parsed;
              }

              return {
                ...parsed,
                orders: parsed.orders.map((order: IAccountOrder) => ({
                  ...order,
                  ClientOrderId: BigInt(order.ClientOrderId.toString()),
                })),
              };
            },
          })
          .then((res) => {
            if (res.data.IsSuccess === false) {
              notificationSvc.error(
                "An unexpected error has occurred. Unable to fetch your details!"
              );
              return;
            }
            if (res.data.orders && res.data.orders.length) {
              setAccountName(res.data.orders, userAccount);
              let orders = res.data.orders.map((order) => {
                return {
                  ...order,
                  ClientOrderId: BigInt(order.ClientOrderId.toString()),
                };
              });
              dispatch(setOrders(orders));
            }
            if (res.data.executions) {
              setAccountName(res.data.executions, userAccount);
              dispatch(setExecutions(res.data.executions));
            }
          });
      });
    }
  };

function setAccountName(list: any[], accounts: IAccount[]) {
  list.forEach(
    (x: IAccountPosition) =>
      (x.AccountName = accounts.find(
        (acc: IAccount) => acc.AccountId === x.AccountId
      )?.Name)
  );
}

export const fetchUserPositions =
  (accountId?) => (dispatch: any, getState: () => IAppState) => {
    const { auth } = getState();
    let accounts: any = getState().adminAccounts.accounts;
    let url = "";
    if (accounts && accounts.length) {
      const userAccount = accountId
        ? accounts.filter((x) => x.AccountId === accountId)
        : accounts;
      if (accountId !== undefined) {
        url = `api/SelectPositionsForAuthorizedAccountsRequest?Role=${auth.authInfo?.Meta.RoleName}&UserId=${auth.authInfo?.UserId}&SelectKey=${accountId}&SelectColumnName=AccountId`;
      } else {
        url = `api/SelectPositionsForAuthorizedAccountsRequest?Role=${auth.authInfo?.Meta.RoleName}&UserId=${auth.authInfo?.UserId}`;
      }

      apiSvc.api.get(url).then((res) => {
        if (res.data.IsSuccess === false) {
          notificationSvc.error(
            "An unexpected error has occurred. Unable to fetch your details!"
          );
          return;
        }

        setAccountName(res.data.symbolsPositions, userAccount);
        dispatch(setSymbolPositions(res.data.symbolsPositions));
        dispatch(setAccountPositions(res.data.accountPositions));
      });
    } else {
      apiSvc.get("SelectAccountsRequest").then((response) => {
        accounts = response?.data.Data;
        store.dispatch(setAccounts(accounts));
        const userAccount = accountId
          ? accounts.filter((x) => x.AccountId === accountId)
          : accounts;
        if (accountId !== undefined) {
          url = `api/SelectPositionsForAuthorizedAccountsRequest?Role=${auth.authInfo?.Meta.RoleName}&UserId=${auth.authInfo?.UserId}&SelectKey=${accountId}&SelectColumnName=AccountId`;
        } else {
          url = `api/SelectPositionsForAuthorizedAccountsRequest?Role=${auth.authInfo?.Meta.RoleName}&UserId=${auth.authInfo?.UserId}`;
        }

        apiSvc.api.get(url).then((res) => {
          if (res.data.IsSuccess === false) {
            notificationSvc.error(
              "An unexpected error has occurred. Unable to fetch your details!"
            );
            return;
          }

          setAccountName(res.data.symbolsPositions, userAccount);
          dispatch(setSymbolPositions(res.data.symbolsPositions));
        });
      });
    }
  };
