import { ColumnModel } from "@syncfusion/ej2-grids";
import {
  ColumnDirective,
  ColumnsDirective,
  EditSettingsModel,
  Grid,
  GridComponent,
  InfiniteScroll,
  Inject,
  Page,
  PageSettingsModel,
} from "@syncfusion/ej2-react-grids";
import * as React from "react";
import { Component } from "react";
import FlexView from "react-flexview/lib";
import groupPositionSvc from "../../../services/group-position-services";
import CustomSpinner from "../spinner/spinner";
import { IGroupPositionProps } from "./IGroupPositionProps";
import { IGroupPositionState } from "./IGroupPositionState";

type Props = IGroupPositionProps;

class GroupPosition extends Component<Props, IGroupPositionState> {
  public editOptions: EditSettingsModel = {
    // allowEditing: true,
    // allowAdding: true,
    // allowDeleting: true,
    mode: "Normal",
  };
  //   public toolbarOptions: ToolbarItems[] = ["Add", "Edit", "Delete", "Update", "Cancel", "Search"];
  public pageSettings: PageSettingsModel = { pageSize: 10 };
  private columnDefs: ColumnModel[] = [];
  private grid: Grid | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      groupPosition: [],
    };
    this.columnDefs = this.getColumnDefs();
  }

  componentDidMount() {
    groupPositionSvc.get().then((groupPosition) => this.setState({ groupPosition }));
  }

  debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = Grid;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  onLoad = () => {
    this.grid?.element.addEventListener(
      "keydown",
      this.debounce((e) => {
        if (e.target.getAttribute("id").indexOf("_searchbar") !== -1) {
          this.grid?.search((e.target as HTMLInputElement).value);
        }
      }, 250)
    );
  };

  render() {
    const { groupPosition } = this.state;
    console.log("group position : ", groupPosition);
    const data = {
      result: groupPosition,
      count: groupPosition?.length,
    };
    if (groupPosition?.length >= 0) {
      return (
        <FlexView className="hw-admin-grid ag-theme-alpine">
          <GridComponent
            id="grid"
            height="100%"
            width="100%"
            allowPaging={true}
            editSettings={this.editOptions}
            // toolbar={this.toolbarOptions}
            load={this.onLoad}
            dataSource={data}
            // dataSourceChanged={this.dataSourceChanged}
            enableInfiniteScrolling={true}
            ref={(g) => (this.grid = g)}
          >
            <ColumnsDirective>
              {this.columnDefs.map((column) => (
                <ColumnDirective key={column.field} headerText={column.headerText} {...column} />
              ))}
            </ColumnsDirective>
            <Inject services={[InfiniteScroll, Page]} />
          </GridComponent>
        </FlexView>
      );
    } else {
      return (
        <div className="m-auto">
          <CustomSpinner />
        </div>
      );
    }
  }

  getColumnDefs = (): ColumnModel[] => {
    return [
      {
        field: "",
        headerText: "Firm",
        width: "130",
      },
      {
        field: "Group.Name",
        headerText: "Group",
        textAlign: "Center",
        width: "130",
      },
      {
        field: "LongPosition",
        headerText: "Long Position",
        width: "130",
      },
      {
        field: "ShortPosition",
        headerText: "Short Position",
        width: "130",
      },
      {
        field: "",
        headerText: "Long BP",
        width: "130",
      },
      {
        field: "",
        headerText: "Short BP",
        width: "130",
      },
      {
        field: "MaxLoss",
        headerText: "Max Loss",
        width: "130",
      },
      {
        field: "UnrealPL",
        headerText: "Open PNL",
        width: "130",
      },
      {
        field: "RealPL",
        headerText: "Close PNL",
        width: "130",
      },
      {
        field: "TotalPL",
        headerText: "Total PNL",
        width: "130",
      },
      {
        field: "QtyTraded",
        headerText: "Qty Traded",
        width: "130",
      },
      {
        field: "",
        headerText: "Buying Power",
        width: "130",
      },
      {
        field: "BPUsed",
        headerText: "BP Used",
        width: "130",
      },
      {
        field: "",
        headerText: "BP Available",
        width: "130",
      },
    ];
  };
}

export default GroupPosition;
