import { IBroker } from "../models/IBroker";
import apiSvc from "./api-service";

class BrokerServices {
  brokerList: IBroker[] = [];

  async get() {
    if (this.brokerList.length > 0) {
      return this.brokerList;
    }
    const response = await apiSvc.get("SelectBrokersRequest");
    return (this.brokerList = response?.data.Data);
  }

  async add(brokerData) {
    const brokerRes = await apiSvc.put("SaveBrokersRequest", brokerData);

    if (brokerRes !== undefined) {
      this.get();
    }
  }
  async delete(delKey) {
    const params = {
      PrimaryKey: delKey,
      LoadCache: true,
    };

    const brokerDelRes = await apiSvc.api.delete("DeleteBrokersRequest", { params });

    if (brokerDelRes !== undefined) {
      this.get();
    }
  }
}

const brokerSvc = new BrokerServices();
export default brokerSvc;
