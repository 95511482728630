export const ACCOUNT_DROPDOWN = "ACCOUNT_DROPDOWN";
export const LEVEL_II = "LEVEL_II";
export const QUICK_TRADE = "QUICK_TRADE";
export const ORD_DROPDOWN = "ORD_DROPDOWN";
export const ORD_STATUS_DROPDOWN = "ORD_STATUS_DROPDOWN";
export const SYMBOL_DROPDOWN = "SYMBOL_DROPDOWN";
export const TIME_SALES_SEC = "TIME_SALES_SEC";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const WATCH_LIST = "WATCH_LIST";
export const WIDGET_MODAL = "WIDGET_MODAL";
