import { useState } from "react";
import { Menu, MenuItem, ProSidebar, SubMenu } from "react-pro-sidebar";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { IAppState } from "../../store/reducers/IAppState";
import { ISideBarMappedProps } from "./SideBarProps";
import Home from "../../Images/WebAdminIconSVG/home.svg";
import System from "../../Images/WebAdminIconSVG/system.svg";
import Tools from "../../Images/WebAdminIconSVG/tools.svg";
import MarketsIcon from "../../Images/WebAdminIconSVG/markets.svg";
import Connectivity from "../../Images/WebAdminIconSVG/Connectivity.svg";
import Risk from "../../Images/WebAdminIconSVG/Risk.svg";
import SidebarLogo from "../../Images/WebAdminIconSVG/sidebarlogo.svg";
import SidebarLogoTag from "../../Images/WebAdminIconSVG/sidebarlogotag.svg";
import trading from "../../Images/WebAdminIconSVG/trading.svg";
import "./styles.scss";
import React from "react";

const Sidebar = (props) => {
  const [isToggle, setIsToggle] = useState(false);
  const {
    isRiskManager,
    isAdmin,
    isSupervisor,
    isSuperAdmin,
    isTrader,
    addNewWidget,
    handleSidebarCollapse,
    isComplianceOfficer,
    isSupport,
  } = props;

  const toggleHandler = () => {
    setIsToggle(!isToggle);
    handleSidebarCollapse();
  };

  const [openSubmenu, setOpenSubmenu] = useState(null);

  const handleSubmenuToggle = (submenuId) => {
    setOpenSubmenu((...prev) => (prev === submenuId ? null : submenuId));
  };

  return (
    <ProSidebar collapsed={isToggle}>
      <span className="mt-2 sidebar-toggle d-flex justify-content-center">
        {isToggle ?
          <>
            <img
              src={SidebarLogo}
              alt=""
              height={36}
              width={36}
              title="Toggle Menu"
              onClick={toggleHandler}
              style={{ cursor: "pointer" }}
            />
          </> :
          <>
            <img
              src={SidebarLogoTag}
              alt=""
              className="py-4"
              title="Toggle Menu"
              onClick={toggleHandler}
              style={{ cursor: "pointer" }}
            />
          </>}
      </span>
      <Menu iconShape="square">
        {(isSuperAdmin ||
          isAdmin ||
          isRiskManager ||
          isSupervisor ||
          isSupport ||
          isComplianceOfficer) && (
            <SubMenu
              title="Management"
              icon={<img src={Home} alt="" height={22} width={22} />}
              onClick={() => handleSubmenuToggle('submenu1')}
              className={openSubmenu === 'submenu1' ? 'active' : ''}
            >
              {openSubmenu === 'submenu1' && (
                <>
                  <div>
                    <MenuItem
                      onClick={() => {
                        addNewWidget("users");
                      }}
                    >
                      {/* <img
                    src={users}
                    alt=""
                    height={26}
                    width={26}
                    className="mr-2 bgblack p-1"
                  /> */}
                      Users
                    </MenuItem>
                  </div>
                  <div>
                    <MenuItem
                      onClick={() => {
                        addNewWidget("accounts");
                      }}
                    >
                      {/* <img
                    src={Accounts}
                    alt=""
                    height={26}
                    width={26}
                    className="mr-2 bgblack p-1"
                  /> */}
                      Accounts
                    </MenuItem>
                  </div>
                  <div>
                    <MenuItem
                      onClick={() => {
                        addNewWidget("groups");
                      }}
                    >
                      {/* <img
                    src={group}
                    alt=""
                    height={26}
                    width={26}
                    className="mr-2 bgblack p-1"
                  /> */}
                      Groups
                    </MenuItem>
                  </div>
                  <div>
                    {
                      isSuperAdmin
                        ?
                        <MenuItem
                          onClick={() => {
                            addNewWidget("firms");
                          }}
                        >
                          {/* <img
                      src={firm}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                          Firms
                        </MenuItem>
                        :
                        <React.Fragment></React.Fragment>
                    }
                  </div>
                </>
              )}
            </SubMenu>
          )}

        {!isTrader && (
          <SubMenu
            title="Trading"
            icon={<img src={trading} alt="" height={22} width={22} />}
            onClick={() => handleSubmenuToggle('submenu2')}
            className={openSubmenu === 'submenu2' ? 'active' : ''}
          >
            {openSubmenu === 'submenu2' && (
              <>
                <div>
                  <MenuItem onClick={() => addNewWidget("order-blotter")}>
                    {/* <img
                  src={MarketData}
                  alt=""
                  height={26}
                  width={26}
                  className="mr-2 bgblack p-1"
                /> */}
                    Orders
                  </MenuItem>
                </div>
                <div>
                  <MenuItem onClick={() => addNewWidget("position-blotter")}>
                    {/* <img
                  src={reports}
                  alt=""
                  height={26}
                  width={26}
                  className="mr-2 bgblack p-1"
                /> */}
                    Positions
                  </MenuItem>
                </div>
                <div>
                  <MenuItem onClick={() => addNewWidget("level-two-view")}>
                    {/* <img
                  src={Start}
                  alt=""
                  height={26}
                  width={26}
                  className="mr-2 bgblack p-1"
                /> */}
                    Level II
                  </MenuItem>
                </div>
                <div>
                  <MenuItem onClick={() => addNewWidget("admin-symbols")}>
                    {/* <img
                  src={monitor}
                  alt=""
                  height={26}
                  width={26}
                  className="mr-2 bgblack p-1"
                /> */}
                    Symbols
                  </MenuItem>
                </div>
                <div>
                  <MenuItem onClick={() => addNewWidget("quick-trade")}>
                    {/* <img
                  src={timetable}
                  alt=""
                  height={26}
                   width={26}
                  className="mr-2 bgblack p-1"
                /> */}
                    Quick Order Entry
                  </MenuItem>
                </div>
              </>
            )}
          </SubMenu>
        )}

        {(isSuperAdmin ||
          isAdmin ||
          isRiskManager ||
          isSupervisor ||
          isSupport ||
          isComplianceOfficer) && (
            <SubMenu
              title="Risk Monitor"
              icon={<img src={Risk} alt="" height={22} width={22} />}
              onClick={() => handleSubmenuToggle('submenu3')}
              className={openSubmenu === 'submenu3' ? 'active' : ''}
            >
               {openSubmenu === 'submenu3' && (
                <>
                  <div>
                    <MenuItem onClick={() => addNewWidget("account-performance")}>
                      {/* <img
                      src={Accounts}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                      Account Watch
                    </MenuItem>
                  </div>
                  <div>
                    <MenuItem onClick={() => addNewWidget("group-position")}>
                      {/* <img
                      src={Accounts}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                      Group Watch
                    </MenuItem>
                  </div>
                  <div>
                    <MenuItem onClick={() => addNewWidget("auto-flatter")}>
                      {/* <img
                      src={Accounts}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                      Auto Flatter
                    </MenuItem>
                  </div>
                </>
               )}
            </SubMenu>
          )}

        {(isSuperAdmin ||
          isAdmin ||
          isRiskManager ||
          isSupervisor ||
          isSupport ||
          isComplianceOfficer) && (
            <SubMenu
              title="Markets"
              icon={<img src={MarketsIcon} alt="" height={22} width={22} />}
              onClick={() => handleSubmenuToggle('submenu4')}
              className={openSubmenu === 'submenu4' ? 'active' : ''}
            >
              {openSubmenu === 'submenu4' && (
                <>
                  <div>
                    <MenuItem onClick={() => addNewWidget("chart")} title="Chart">
                      {/* <img
                      src={chart}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                      Chart
                    </MenuItem>
                  </div>
                  <div>
                    <MenuItem
                      onClick={() => addNewWidget("time-and-sales")}
                      title="Trades"
                    >
                      {/* <img
                      src={news}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                      Trades
                    </MenuItem>
                  </div>
                  <div>
                    <MenuItem onClick={() => addNewWidget("news")} title="News">
                      {/* <img
                      src={news}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                      News
                    </MenuItem>
                  </div>
                  <div>
                    <MenuItem onClick={() => addNewWidget("watch-list")}>
                      {/* <img
                      src={Accounts}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                      Watch List
                    </MenuItem>
                  </div>
                  <div>
                    <MenuItem onClick={() => props.mostActive_Click()}>
                      {/* <img
                      src={Accounts}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                      Most Active
                    </MenuItem>
                  </div>
                  <div>
                    <MenuItem onClick={() => props.trader2B_Click()}>
                      {/* <img
                      src={Accounts}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                      Trader 2b
                    </MenuItem>
                  </div>
              </>
              )}
            </SubMenu>
          )}

        {(isSuperAdmin ||
          isAdmin ||
          isRiskManager ||
          isSupervisor ||
          isSupport ||
          isComplianceOfficer) && (
            <SubMenu
              title="Tools"
              icon={<img src={Tools} alt="" height={22} width={22} />}
              onClick={() => handleSubmenuToggle('submenu5')}
              className={openSubmenu === 'submenu5' ? 'active' : ''}
            >
            
            {openSubmenu === 'submenu5' && (
              <>
                <div>
                  <MenuItem
                    onClick={() => {
                      addNewWidget("user-ads");
                    }}
                  >
                    {/* <img
                    src={broadcast}
                    alt=""
                    height={26}
                    width={26}
                    className="mr-2 bgblack p-1"
                  /> */}
                    Broadcasting
                  </MenuItem>
                </div>
                <div>
                  <MenuItem onClick={() => addNewWidget("fees")}>
                    {/* <img
                    src={fees}
                    alt=""
                    height={26}
                    width={26}
                    className="mr-2 bgblack p-1"
                  /> */}
                    Fees
                  </MenuItem>
                </div>
                <div>
                  <MenuItem
                    onClick={() => {
                      addNewWidget("report");
                    }}
                  >
                    {/* <img
                    src={reports}
                    alt=""
                    height={26}
                    width={26}
                    className="mr-2 bgblack p-1"
                  /> */}
                    Reports
                  </MenuItem>
                </div>
              </>
            )}
            </SubMenu>
          )}

        {isSuperAdmin && (
          <SubMenu
            title="Connectivity"
            icon={<img src={Connectivity} alt="" height={22} width={22} />}
            onClick={() => handleSubmenuToggle('submenu6')}
            className={openSubmenu === 'submenu6' ? 'active' : ''}
          >
            
            {openSubmenu === 'submenu6' && (
              <>  
                <div>
                  <MenuItem
                    onClick={() => {
                      addNewWidget("brokers");
                    }}
                  >
                    {/* <img
                      src={Accounts}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                    Brokers
                  </MenuItem>
                </div>
                <div>
                  <MenuItem
                    onClick={() => {
                      addNewWidget("routing-stratgites");
                    }}
                  >
                    {/* <img
                      src={Accounts}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                    Routing Stratgites
                  </MenuItem>
                </div>
                <div>
                  <MenuItem
                    onClick={() => {
                      addNewWidget("destinations");
                    }}
                  >
                    {/* <img
                      src={Accounts}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                    Destinations
                  </MenuItem>
                </div>
              </>
              )}
          </SubMenu>
        )}
        {isSuperAdmin && (
          <SubMenu
            title="System"
            icon={<img src={System} alt="" height={22} width={22} />}
            onClick={() => handleSubmenuToggle('submenu7')}
            className={openSubmenu === 'submenu7' ? 'active' : ''}
          >
            
            {openSubmenu === 'submenu7' && (
              <>
                <div>
                  <MenuItem>
                    {/* <img
                      src={monitoring}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                    Monitoring
                  </MenuItem>
                </div>
                <div>
                  <MenuItem
                    onClick={() => {
                      addNewWidget("settings");
                    }}
                  >
                    {/* <img
                      src={config}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                    Settings
                  </MenuItem>
                </div>
                <div>
                  <MenuItem
                    onClick={() => {
                      addNewWidget("audit");
                    }}
                  >
                    {/* <img
                      src={Logs}
                      alt=""
                      height={26}
                      width={26}
                      className="mr-2 bgblack p-1"
                    /> */}
                    Log
                  </MenuItem>
                </div>
              </>
            )}
          </SubMenu>
        )}
      </Menu>
    </ProSidebar>
  );
};

const mapStateToProps = (state: IAppState): ISideBarMappedProps => ({
  isTrader: state.auth.authInfo?.Meta.RoleName === "Trader",
  isAdmin: state.auth.authInfo?.Meta.RoleName === "Admin" ? true : false,
  isSupervisor: state.auth.authInfo?.Meta.RoleName === "Supervisor",
  isRiskManager: state.auth.authInfo?.Meta.RoleName === "RiskManager",
  isComplianceOfficer:
    state.auth.authInfo?.Meta.RoleName === "ComplianceOfficer",
  isSuperAdmin: state.auth.authInfo?.Meta.RoleName === "SuperAdmin",
  isSupport: state.auth.authInfo?.Meta.RoleName === "Support",
  roleName: state.auth.authInfo?.Meta.RoleName || "",
});
export default connect(mapStateToProps)(withRouter(Sidebar));
