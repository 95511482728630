import store from "..";
import apiSvc from "../../services/api-service";
import { authSvc } from "../../services/auth-service";
import firebaseSvc from "../../services/firebase-service";
import notificationSvc from "../../services/notification-service";
import soundSvc from "../../services/sound-service";
import tempCache from "../../services/temp-cache";
import * as sounds from "../../sounds";
import { getClientInfo } from "../../utils/client";
import IndexedDBService from "../../utils/idb_helper";
import { loadAccountsDestinations } from "../accounts-destinations/accounts-destinations-async-action";
import { loadAccounts } from "../admin-accounts/accounts-async-actions";
import { connectNats, monitorUserStatus } from "../connection/connection-async-actions";
import { loadGroups } from "../groups/groups-async-actions";
import {
  updateAccountColumnsForAdminRole,
  updateAccountColumnsForRiskManagerRole,
} from "../settings/default-columns-admin/accounts";
import {
  updateFirmColumnsForAdminRole,
  updateFirmColumnsForRiskManagerRole,
} from "../settings/default-columns-admin/firms";
import {
  updateGroupColumnsForAdminRole,
  updateGroupColumnsForRiskManagerRole,
} from "../settings/default-columns-admin/groups";
import { loadSymbols } from "../symbols/symbols-async-actions";
import { loadPositionData, loadUserAccounts } from "../user-accounts/user-account-async-action";
import { getAllUserAccounts, loadUsers } from "../users/users-async-actions";
import {
  logoutUser,
  setAdminDefaultColumns,
  setAuthInfo,
  setLoginRequestInProgress,
  setLoginResponse,
} from "./auth-actions";
import { AuthState } from "./AuthState";

export async function authenticateUser(dispatch, getState) {
  dispatch(setLoginRequestInProgress(true));
  const auth: AuthState = getState().auth;
  apiSvc.api
    .post(`${process.env.REACT_APP_HW_SVC_BASE_URL}/auth/credentials.json`, {
      UserName: auth.username.toLowerCase().trim(),
      Password: auth.password,
      Meta: getClientInfo(),
    })
    .then(async(response) => {
      authSvc.loginTime = new Date().getTime();
      const authInfo = response.data;
      apiSvc.bearerToken = authInfo.BearerToken;
      if (auth.isAdmin && authInfo.Meta.RoleName === "Trader") {
        notificationSvc.error("Unauthorized Access");
      } else {
        soundSvc.play(sounds.login);
        dispatch(connectNats(authInfo.UserId));

        tempCache.userInfo = authInfo;
        tempCache.lastLoginAt = new Date().getTime();
        if (authInfo.Meta.RoleName === "Admin") {
          const firmCol = updateFirmColumnsForAdminRole();
          const groupCol = updateGroupColumnsForAdminRole();
          const accountCol = updateAccountColumnsForAdminRole();
          const obj = {
            firmCol,
            groupCol,
            accountCol,
          };

          dispatch(setAdminDefaultColumns(obj));
        } else if (authInfo.Meta.RoleName === "Risk Manager") {
          const firmCol = updateFirmColumnsForRiskManagerRole();
          const groupCol = updateGroupColumnsForRiskManagerRole();
          const accountCol = updateAccountColumnsForRiskManagerRole();
          const obj = {
            firmCol,
            groupCol,
            accountCol,
          };

          dispatch(setAdminDefaultColumns(obj));
        } else if (authInfo.Meta.RoleName === "SuperAdmin") {
          const firmCol = updateFirmColumnsForRiskManagerRole();
          const groupCol = updateGroupColumnsForRiskManagerRole();
          const accountCol = updateAccountColumnsForRiskManagerRole();
          const obj = {
            firmCol,
            groupCol,
            accountCol,
          };

          dispatch(setAdminDefaultColumns(obj));
        } else {
          const firmCol = updateFirmColumnsForRiskManagerRole();
          const groupCol = updateGroupColumnsForRiskManagerRole();
          const accountCol = updateAccountColumnsForRiskManagerRole();
          const obj = {
            firmCol,
            groupCol,
            accountCol,
          };

          dispatch(setAdminDefaultColumns(obj));
        }
        dispatch(
          setLoginResponse({ isLoginSuccessful: true, loginErrorMessage: "" })
        );
        //Upon Login, clean previous day trades
        IndexedDBService.cleanDatabase();
        loadPositionData()
        dispatch(setAuthInfo(authInfo));
        loadUserAccounts(dispatch);
        loadAccounts(dispatch, store.getState);
        loadUsers(dispatch);
        loadAccountsDestinations(dispatch);
        loadGroups(dispatch)
        loadSymbols(dispatch);
        const hwOffset = `${auth.username.toLowerCase().trim()}Hw!@123456`;
        firebaseSvc.firebaseSignIn(auth.username.toLowerCase().trim(), hwOffset);

        authSvc.isLoggedIn$.next(true);
        authSvc.userId$.next(+authInfo.UserId);
      }
    })
    .catch((error) => {
      if (error.response) {
        const response = {
          isLoginSuccessful: false,
          loginErrorMessage: error.response.data.ResponseStatus.Message,
        };

        dispatch(setLoginResponse(response));
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error(error.message);
      }
    })
    .finally(() => dispatch(setLoginRequestInProgress(false)));
}

export async function refreshToken() {
  apiSvc.api
    .post("auth/credentials.json", {
      UserName: tempCache.username,
      Password: tempCache.password,
      Meta: getClientInfo(),
    })
    .then((response) => {
      const authInfo = response.data;
      tempCache.userInfo = authInfo;
      tempCache.lastLoginAt = new Date().getTime();
      store.dispatch(setAuthInfo(authInfo));
    })
    .catch((error) => {
      console.error("refreshToken", error);
      if (error.response) {
        store.dispatch(logoutUser());

        const response = {
          isLoginSuccessful: false,
          loginErrorMessage: error.response.data.ResponseStatus.Message,
        };

        store.dispatch(setLoginResponse(response));
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error(error.message);
      }
    });
}
