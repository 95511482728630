import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component, KeyboardEvent } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import FlexView from "react-flexview";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import Logo from "../../Images/logo-horizontal1.png";
import globalCache from "../../services/global-cache";
import tempCache from "../../services/temp-cache";
import { logoutUser, setLoginCredentials, setLoginResponse } from "../../store/auth/auth-actions";
import { authenticateUser } from "../../store/auth/auth-async-actions";
import { IAppState } from "../../store/reducers/IAppState";
import { Popup } from "../popup/popup";
import { ILoginDispatchProps, ILoginMappedProps, ILoginProps } from "./ILoginProps";
import { ILoginState } from "./ILoginState";
import "./styles.scss";

type Props = ILoginProps & ILoginMappedProps & ILoginDispatchProps;

class LoginView extends Component<Props, ILoginState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      isPasswordShown: false,
      rememberMeChecked: false,
    };
  }

  componentDidMount() {
    const { userSavedDetails } = globalCache;
    if (userSavedDetails) {
      const { username, password } = userSavedDetails;
      this.setState({
        username,
        password,
        rememberMeChecked: true,
      });
    } else {
      this.props.logout();
    }
  }

  render() {
    const { isLoggedIn, loginErrorMessage, loginRequestInProgress } = this.props;

    return (
      <>
        <Modal show={!isLoggedIn} backdrop="static" keyboard={false} centered className="login-modal text-white">
          <Modal.Header>
            <div className="col-12 logo-wrapper d-flex justify-content-center">
              <img src={Logo} alt="Logo" height={96} />
            </div>
          </Modal.Header>
          <Modal.Body onKeyPress={this.form_KeyPress}>
            <Form.Label>User ID</Form.Label>
            <Form.Control
              type="text"
              value={this.state.username}
              onChange={(e) => this.setState({ username: e.target.value })}
            />
            <Form.Label className="mt-3">Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={this.state.isPasswordShown ? "text" : "password"}
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />

              <InputGroup.Append>
                <InputGroup.Text className="show-password-eye-icon">
                  <FontAwesomeIcon
                    onClick={() => {
                      this.setState({
                        isPasswordShown: !this.state.isPasswordShown,
                      });
                    }}
                    icon={this.state.isPasswordShown ? ["fas", "eye"] : ["fas", "eye-slash"]}
                    color="#fff"
                  />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>

            <FlexView className="mt-3" grow>
              <FlexView grow>
                <Form.Check
                  className="ml-1"
                  style={{ fontSize: "15px" }}
                  type="checkbox"
                  label="Remember me"
                  checked={this.state.rememberMeChecked}
                  onChange={this.rememberMe_Change}
                />
              </FlexView>
              <FlexView grow hAlignContent="right" column>
                <a href="https://trader2b.com/reset-password" target="_blank" rel="noreferrer"
                  className="forgot-password login-links-text cursor-pointer">
                  Forgot Password?
                </a>
              </FlexView>
            </FlexView>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-form w-100" variant="primary" onClick={this.login_Click}>
              {loginRequestInProgress ? (
                <Spinner color="dark" size="sm" className="mr-1" />
              ) : (
                <FontAwesomeIcon icon={["fas", "sign-in-alt"]} className="mr-2" />
              )}
              Login
            </Button>
            <div style={{ width: 75 }}></div>
            <FlexView hAlignContent="center" grow>
              <span className="login-links-text py-2">
                Don't have an account?{" "}
                <strong className="signup-text cursor-pointer" onClick={this.registerAccount}>
                  Sign Up
                </strong>
              </span>
            </FlexView>
            <FlexView hAlignContent="right" style={{ width: 75 }}>
              <span className="login-links-text">
                {process.env.REACT_APP_VERSION}
              </span>
            </FlexView>
          </Modal.Footer>
        </Modal>
        <Popup message={loginErrorMessage} type="danger"
          click={() => this.props.setLoginResponse({ loginErrorMessage: "" })}>
        </Popup>
      </>
    );
  }

  private authenticateUser(username: string, password: string) {
    tempCache.username = username;
    tempCache.password = password;

    const credentials = { username, password };

    this.props.setLoginCredentials(credentials);
    this.props.authenticateUser();

    if (this.state.rememberMeChecked) {
      globalCache.userSavedDetails = credentials;
    }
  }

  private form_KeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      this.authenticateUser(this.state.username, this.state.password);
    }
  };

  private login_Click = () => {
    const { username, password } = this.state;
    if (username === "" && password === "") {
      this.openDemoTab("https://trader2b.com/request-trial/");
    } else this.authenticateUser(this.state.username, this.state.password);
  };

  private rememberMe_Change = (e: any) => {
    this.setState({
      rememberMeChecked: e.target.checked,
    });

    if (!e.target.checked) {
      globalCache.userSavedDetails = null;
    }
  };
  private openDemoTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  private registerAccount = () => {
    this.openDemoTab("https://trader2b.com/register/");
  };
}

const mapStateToProps = (state: IAppState): ILoginMappedProps => ({
  isLoggedIn: state.auth.authInfo !== undefined,
  isLoginSuccessful: state.auth.isLoginSuccessful,
  loginErrorMessage: state.auth.loginErrorMessage,
  loginRequestInProgress: state.auth.loginRequestInProgress,
});

const mapDispatchToProps = (dispatch: any): ILoginDispatchProps => ({
  authenticateUser: () => dispatch(authenticateUser),
  logout: () => dispatch(logoutUser()),
  setLoginCredentials: (credentials) => dispatch(setLoginCredentials(credentials)),
  setLoginResponse: (response) => dispatch(setLoginResponse(response))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
