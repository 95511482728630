import moment from "moment";
import { IColumnDefinition } from "../../../models/IColumnDefinition";

export const auditColumnsDefinition: IColumnDefinition[] = [
  {
    title: "Table",
    field: "Table",
    visible: true,
    sortOrder: 3,
    minWidth: 80,
  },
  {
    title: "Field",
    field: "Field",
    visible: true,
    sortOrder: 13,
    minWidth: 80,
  },
  {
    title: "OldValue",
    field: "OldValue",
    visible: true,
    sortOrder: 18,
    minWidth: 80,
  },
  {
    title: "NewValue",
    field: "NewValue",
    visible: true,
    sortOrder: 19,
    minWidth: 50
  },
  {
    title: "ModifiedBy",
    field: "ModifiedBy",
    visible: true,
    sortOrder: 11,
  },
  {
    title: "ModifiedOn",
    field: "ModifiedOn",
    visible: true,
    sortOrder: 8,
    minWidth: 80,
    filter: "agDateColumnFilter",
    filterParams: {
      filterOptions: ['inRange'],
      maxNumConditions: 1
    },
    valueFormatter: (params) => {
      return params.value ? moment(params?.value).format("YYYY-MM-DD hh:mm:ss") : "";
    },
  },
];
