import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faEdit, faSave, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowDown,
  faArrowUp,
  faBuilding,
  faCoins,
  faCompress,
  faEllipsisV,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faGem,
  faHeart,
  faLayerGroup,
  faLink,
  faLock,
  faPlus,
  faPlusCircle,
  faRedo,
  faSignInAlt,
  faSignOutAlt,
  faSort,
  faThList,
  faTimes,
  faTimesCircle,
  faUnlock,
  faUsers,
  faBars,
  faBriefcase,
  faDollarSign,
  faUser,
  faHandPaper,
  faTags,
  faCogs,
  faChartLine,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowDown,
  faArrowUp,
  faBuilding,
  faCheckCircle,
  faCoins,
  faCompress,
  faEdit,
  faEllipsisV,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faGem,
  faHeart,
  faLayerGroup,
  faLink,
  faLock,
  faPlus,
  faPlusCircle,
  faRedo,
  faSave,
  faSignInAlt,
  faSignOutAlt,
  faSort,
  faThList,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUnlock,
  faUsers,
  faBars,
  faBriefcase,
  faDollarSign,
  faUser,
  faHandPaper,
  faTags,
  faCogs,
  faChartLine,
  faTachometerAlt
);
