import { ColDef } from "ag-grid-community";
import { timeValueGetter } from "../../utils/datetime";
import { numberFormatter } from "../../utils/number";

export class ColumnDefinitions {
  constructor(private decimalPlaces: number) {
  }

  public get = (): ColDef[] => ([
    { headerName: "Time", field: "time", sortable: true, valueGetter: (params: any) => timeValueGetter(params.node.data.timestamp), },
    { 
      headerName: "Last", 
      field: "price", 
      valueFormatter: numberFormatter(this.decimalPlaces) },
    { headerName: "Size", field: "size" },
    { headerName: "Market", sortable: true, field: "excode" },
  ]);
}
