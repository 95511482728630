import {
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDataTransaction,
} from "ag-grid-community";
import { ChangeEvent, Component } from "react";
import Form from "react-bootstrap/Form";
import FlexView from "react-flexview/lib";
import { connect } from "react-redux";
import { CacheKeys } from "../../constants/cache-keys";
import { Defaults } from "../../constants/defaults";
import { IQuoteMediaTrade } from "../../models/IQuoteMediaTrade";
import notificationSvc from "../../services/notification-service";
import tempCache from "../../services/temp-cache";
import {
  subscribeQuoteMediaData,
  unsubscribeQuoteMediaData,
} from "../../store/quote-media/quote-media-async-actions";
import { IAppState } from "../../store/reducers/IAppState";
import { setWidgetSymbol } from "../../store/widgets/widgets-actions";
import NumericText from "../controls/numeric-text";
import Table from "../list/list";
import { ColumnDefinitions } from "./column-defs";
import {
  ITimeAndSalesDispatchProps,
  ITimeAndSalesMappedProps,
  ITimeAndSalesProps,
} from "./ITimeAndSalesProps";
import { ITimeAndSalesState } from "./ITimeAndSalesState";
import "./styles.scss";

type Props = ITimeAndSalesProps &
  ITimeAndSalesMappedProps &
  ITimeAndSalesDispatchProps;

class TimeAndSales extends Component<Props, ITimeAndSalesState> {
  currentTradesList: IQuoteMediaTrade[] = [];
  gridColumnApi: ColumnApi = {} as ColumnApi;
  initialData: IQuoteMediaTrade[];
  lastPrice: number = 0;
  lastPriceColor: string = "price-equal";
  gridApi: GridApi | null = null;
  gridOptions: GridOptions;

  constructor(props: Props) {
    super(props);

    this.state = {
      symbol: this.props.symbol,
      isSymbolSelected: false,
    };

    this.initialData = this.initializeData();

    this.gridOptions = {
      columnDefs: new ColumnDefinitions(this.props.decimalPlaces).get(),
      rowClass: "custom-row",
    };
  }

  componentDidMount() {
    this.loadInitialSymbol();
  }

  componentDidUpdate(prevProps: Props) {
    this.handleSymbolUpdate(prevProps);
    this.handleColumnsUpdate(prevProps);
    this.handleDecimalPlacesUpdate(prevProps);
    this.updateGrid();
  }

  componentWillUnmount() {
    this.props.unsubscribeQuoteMediaData();
  }

  render() {
    const { decimalPlaces, tradesList } = this.props;
    const { symbol } = this.state;

    const symbolTradesData = tradesList[symbol];
    if (symbolTradesData) {
      const symbolTradeData = symbolTradesData[0];
      if (
        symbolTradeData &&
        symbolTradeData.price &&
        symbolTradeData.price.toFixed(decimalPlaces) !==
          this.lastPrice.toFixed(decimalPlaces)
      ) {
        this.lastPrice = symbolTradeData.price;
        this.lastPriceColor = symbolTradeData.rowClass;
      }
    }

    return (
      <FlexView className="time-and-sales" column grow height="100%">
        <FlexView className="section-header">
          <FlexView grow vAlignContent="top" hAlignContent="left" column>
            {/* <Form.Label>Search Symbol</Form.Label> */}
            <Form.Control
              style={{ fontSize: "10px" }}
              className="symbol-textfield"
              type="text"
              placeholder="Symbol"
              value={symbol}
              onChange={this.symbol_Change}
              onKeyPress={this.symbol_KeyPress}
            />
          </FlexView>
          <FlexView grow vAlignContent="center" hAlignContent="left">
            <div>Last Price</div>
            <span className={`${this.lastPriceColor} ml-3`}>
              <NumericText value={this.lastPrice} />
            </span>
          </FlexView>
        </FlexView>

        <FlexView grow vAlignContent="center" hAlignContent="center">
          <Table
            cacheKey={CacheKeys.TimeAndSalesColumns}
            rowData={this.initialData}
            gridOptions={this.gridOptions}
            emptyGridMessage="Enter a symbol to load data"
            getRowClass={this.getRowClass}
            onGridReady={this.onGridReady}
            rowIdSelector={(data) => data.id}
          />
        </FlexView>
      </FlexView>
    );
  }

  clearGrid = () => {
    const transaction: RowDataTransaction = {
      remove: this.currentTradesList,
    };

    if (this.gridApi && this.gridApi.applyTransaction) {
      this.gridApi.applyTransaction(transaction);
      this.currentTradesList = [];
    }
  };

  getRowClass = (params: any) =>
    params.data.rowClass +
    (params.node.rowIndex % 2 === 0 ? " row-light-background" : "");

  handleColumnsUpdate = (prevProps: Props) => {
    const { columns } = this.props;

    if (columns !== prevProps.columns) {
      const visibleColumnNames = columns
        .filter((x) => x.visible)
        .map((x) => x.field);
      this.gridColumnApi.setColumnsVisible(visibleColumnNames, true);

      const hiddenColumnNames = columns
        .filter((x) => !x.visible)
        .map((x) => x.field);
      this.gridColumnApi.setColumnsVisible(hiddenColumnNames, false);
    }
  };

  handleDecimalPlacesUpdate = (prevProps: Props) => {
    if (!this.gridApi) {
      return;
    }

    const { decimalPlaces } = this.props;

    if (decimalPlaces === prevProps.decimalPlaces) {
      return;
    }

    this.gridApi.setColumnDefs(
      new ColumnDefinitions(this.props.decimalPlaces).get()
    );
    this.clearGrid();
    this.updateGrid();
  };

  handleSymbolUpdate = (prevProps: Props) => {
    const { symbol } = this.props;

    if (symbol === prevProps.symbol) {
      return;
    }

    this.setState({ symbol });

    if (!symbol) {
      this.clearGrid();
      return;
    }

    this.setState({ isSymbolSelected: true });
    this.props.subscribeQuoteMediaData(symbol);
    this.props.setWidgetSymbol(symbol);
  };

  onGridReady = async (params: GridReadyEvent) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const { columns } = this.props;
    const hiddenColumns = columns
      .filter((x) => x.visible === false)
      .map((x) => x.field);
    if (hiddenColumns.length > 0) {
      this.gridColumnApi.setColumnsVisible(hiddenColumns, false);
    }
  };

  initializeData = () => {
    const { symbol, tradesList } = this.props;
    this.currentTradesList =
      (tradesList[symbol] && [...tradesList[symbol]]) || [];
    return [...this.currentTradesList];
  };

  loadInitialSymbol(): void {
    const { symbol } = this.props;

    if (symbol) {
      this.setState({ symbol, isSymbolSelected: true });
      this.props.subscribeQuoteMediaData(symbol);
      this.props.setWidgetSymbol(symbol);
    } else {
      const interval = setInterval(() => {
        const { isDataLoaded } = tempCache;
        if (isDataLoaded) {
          const { widgetSymbols, windowId } = this.props;
          if (!(windowId in widgetSymbols)) {
            this.props.setWidgetSymbol(Defaults.symbol);
          }

          clearInterval(interval);
        }
      }, 100);
    }
  }

  symbol_Change = (e: ChangeEvent<HTMLInputElement>) => {
    const { isSymbolSelected } = this.state;

    if (isSymbolSelected) {
      this.props.unsubscribeQuoteMediaData();
      this.clearGrid();
    }

    this.setState({
      symbol: e.target.value.toUpperCase(),
      isSymbolSelected: false,
    });
  };

  symbol_KeyPress = async (e) => {
    if (e.key === "Enter") {
      if (!e.target.value) {
        notificationSvc.error("Enter a Symbol to load Data!");
      } else {
        const symbol = e.target.value.toUpperCase();
        this.props.subscribeQuoteMediaData(symbol);
        this.props.setWidgetSymbol(symbol);
        this.setState({ isSymbolSelected: true });
      }
    }
  };

  updateGrid = () => {
    if (!this.state.isSymbolSelected) {
      return;
    }

    const { tradesList } = this.props;
    const { symbol } = this.state;

    if (symbol) {
      const newTrades = (tradesList[symbol] && [...tradesList[symbol]]) || [];

      const addedTrades = newTrades.filter(
        (x) => this.currentTradesList.findIndex((y) => x.id === y.id) === -1
      );
      const removedTrades = this.currentTradesList.filter(
        (x) => newTrades.findIndex((y) => x.id === y.id) === -1
      );

      const transaction: RowDataTransaction = {
        add: addedTrades,
        addIndex: 0,
        remove: removedTrades,
      };

      if (this.gridApi && this.gridApi.applyTransaction) {
        this.gridApi.applyTransaction(transaction);
        this.currentTradesList = newTrades;
      }
    }
  };
}

const mapStateToProps = (
  state: IAppState,
  ownProps: ITimeAndSalesProps
): ITimeAndSalesMappedProps => ({
  columns: state.settings.timeAndSalesColumns,
  decimalPlaces: state.settings.general.decimalPlaces,
  tradesList: state.qm.tradesList,
  widgetSymbols: state.widgets.symbols,
});

const mapDispatchToProps = (
  dispatch: any,
  ownProps: ITimeAndSalesProps
): ITimeAndSalesDispatchProps => {
  const { windowId } = ownProps;

  return {
    setWidgetSymbol: (symbol) => dispatch(setWidgetSymbol(windowId, symbol)),
    subscribeQuoteMediaData: (symbol) =>
      dispatch(subscribeQuoteMediaData(windowId, symbol)),
    unsubscribeQuoteMediaData: () =>
      dispatch(unsubscribeQuoteMediaData(windowId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeAndSales);
