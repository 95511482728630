import { IAuthInfo } from "../../models/IAuthInfo";

export class AuthState {
  adminPanelState = {
    defaultColumns: {
      firmCol: {},
      groupCol: {},
      accountCol: {},
    },
  };
  authInfo?: IAuthInfo;
  isAdmin = false;
  isLoginSuccessful = true;
  loginErrorMessage = "";
  loginRequestInProgress = false;
  password = "";
  username = "";
}
