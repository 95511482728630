export const SETTINGS_CONFIRMATION_MODAL = "settings/confirmation-modal";
export const SETTINGS_EXITING_SYSTEM_NOTIFICATION = "settings/notification/exiting-system";
export const SETTINGS_FULLSCREEN_MODE = "settings/fullscreen-mode";
export const SETTINGS_GENERAL_SET_DECIMAL_PLACES = "settings/general/decimal-places";
export const SETTINGS_GENERAL_SET_DEFAULT_ACCOUNT = "settings/general/default-account";
export const SETTINGS_GENERAL_SET_DEFAULT_DESTINATION = "settings/general/default-destination";
export const SETTINGS_GENERAL_LOAD = "settings/general/load-settings";
export const SETTINGS_IS_SHORTCUT_COMBINATION_ALLOWED = "settings/keyboardtrading/is-shortcut-combination-allowed";
export const SETTINGS_KEYBOARD_SHORTCUT_SHOW_MODAL = "settings/keyboard-trading/is-keyboard-shortcut-modal";
export const SETTINGS_LEVEL_II_SKIN_COLOR = "settings/market-depth";
export const SETTINGS_MODAL_RESPONSE = "settings/modal-response";
export const SETTINGS_NEW_ORDER_LIMIT_NOTIFICATION = "settings/notification/new-order-limit";
export const SETTINGS_NEW_ORDER_NOTIFICATION = "settings/notification/new-order";
export const SETTINGS_ORDER_BLOTTER_COLUMN_UPDATE = "settings/order-blotter-column/update";
export const SETTINGS_ORDER_PRICE_LIMIT_NOTIFICATION = "settings/notification/order-price-limit";
export const SETTINGS_ORDER_REJECTION_NOTIFICATION = "settings/notification/order-rejection";
export const SETTINGS_POSITION_BLOTTER_COLUMN_UPDATE = "settings/position-blotter-column/update";
export const SETTINGS_RESET_ALL_SETTINGS = "settings/all/reset";
export const SETTINGS_SAVE_LAYOUT_NOTIFICATION = "settings/notification/save-layout";
export const SETTINGS_SAVE_POINT_NOTIFICATION = "settings/notification/save-points";
export const SETTINGS_SAVE_SHARES_NOTIFICATION = "settings/notification/save-shares";
export const SETTINGS_SET_ACCOUNT_STATUSES = "settings/set-account-statuses";
export const SETTINGS_SET_KEYBOARD_SHORTCUT = "settings/keyboardtrading/keyboardshortcut";
export const SETTINGS_SET_SELECTED_KEYBOARD_SHORTCUT = "settings/keyboardtrading/keyboardshortcut/selectedtoedit";
export const SETTINGS_SET_SELECTED_SHORTCUT_COMBINATION = "settings/keyboardtrading/shortcut-combination/selectedtoedit";
export const SETTINGS_SET_SHORTCUT_COMBINATION = "settings/keyboardtrading/shortcut-combination";
export const SETTINGS_SHORTCUT_COMBINATION_INFO_SHOW_MODAL = "settings/keyboard-trading/is-shortcut-combination-info-modal";
export const SETTINGS_SHORTCUT_COMBINATION_SHOW_MODAL = "settings/keyboard-trading/is-shortcut-combination-modal";
export const SETTINGS_SHOW_MODAL = "settings/is-setting-modal";
export const SETTINGS_TIME_AND_SALES_COLUMN_UPDATE = "settings/time-and-sales-column/update";
export const SETTINGS_USER_CLOSED_WIDGET = "settings/shell-user-closed-widget";
export const SETTINGS_WATCH_LIST_COLUMN_UPDATE = "settings/watch-list-column/update";
export const SETTINGS_WIDGET_DISPLAYED = "settings/shell-widget-display";
export const SETTINGS_COPY_STATE = "settings/copy_state";
export const SETTINGS_MARKET_DEPTH_LOAD = "settings/market-depth/load";
export const SETTINGS_NOTIFICATIONS_LOAD = "settings/notification/load";
export const SETTINGS_ORDER_BLOTTER_LOAD_COLUMNS = "settings/order-blotter/load-columns";
export const SETTINGS_TIME_AND_SALES_LOAD_COLUMNS = "settings/time-and-sales/load-columns";
export const SETTINGS_WATCH_LIST_LOAD_COLUMNS = "settings/watch-list/load-columns";
