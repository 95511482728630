import { useEffect, useRef, useState } from 'react'
import AggridList, { KPIData } from '../ag-grid-list/aggrid-list';
import { GridOptions, RowDataTransaction, RowDoubleClickedEvent } from 'ag-grid-community';
import { userColumnsDefinition } from './UserColumnsDefinition';
import { loadUsers } from '../../../store/users/users-async-actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CustomSpinner from '../spinner/spinner';
import store from '../../../store';
import { showEditForm } from '../../../store/users/users-actions';
import { ContextMenuOption } from '../../context-menu/context-menu-types';
import editIcon from "../../../Images/editicon.svg";
import copyIcon from '../../../Images/copyicon.svg';
import { copyToClipboard, isDebounceTimeExceeded } from '../../../utils/general';
import { formatNumberForKPI } from '../../../utils/number';
import apiSvc from '../../../services/api-service';
import { IAppState } from '../../../store/reducers/IAppState';

const USER_STATS = ["Users", "Expired", "This Month New", "Online", "Disabled", "Active QM Accounts", "Active Users", "Demo Users"];
let gridUpdateDebounceTime = Date.now();

type Props = {
  windowId: string,
  searchResults?: any[]
}

const UsersList = ({windowId, searchResults}: Props) => {
  
  const gridApiRef = useRef<any>(null);

  const gridOptions: GridOptions = {
    columnDefs: userColumnsDefinition
  };

  const userContextMenuOptions: ContextMenuOption[] = [
    {
      handler: () => { 
        store.dispatch(
          showEditForm({
            status: true,
            data: {
              fieldName: `Edit User - ${currentRow?.Email}`,
              fieldData: currentRow?.Id,
            },
          })
        );
      },
      name: "Edit User",
      optionIcon: editIcon
    },
    {
      handler: () => copyToClipboard(gridApiRef),
      name: "Copy All",
      optionIcon: copyIcon
    },
  ];

  const dispatch = useDispatch();
  const formData = useSelector((state: IAppState) => state.users.formData);
  const activeUsers = useSelector((state: IAppState) => state.users.onlineUsers);
  const userAccounts = useSelector((state: IAppState) => state.userAccountsList.userAccountsList);


  const [userData, setUserData] = useState<any[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [currentRow, setCurrentRow] = useState<any>();
  const [isGridReady, setIsGridReady] = useState<boolean>(false);

  const userKPIData = () => {
    const stats: KPIData[] = [];

    USER_STATS.forEach((param) => {

      let data;

      let gridData = [];
      if (isGridReady && gridApiRef.current) {
        gridData = gridApiRef.current.getModel().rowsToDisplay.map((row: any) => row.data);
      }

      switch(param)
      {
        case "Users":
          data = formatNumberForKPI(userData.length);
          break;
        case "Expired":
          data = formatNumberForKPI(userData.filter((user: any) => moment(new Date(user.ExpiresOn)).isSameOrBefore(moment())).length);
          break;
        case "This Month New":
          const thisMonthAddedUsers = userData.filter((user: any) => moment(user.CreatedOn).format("YYYY-MM") === moment().format("YYYY-MM")).length;
          const previousMonthUsersCount = userData.filter((user: any) => moment(user.CreatedOn).format("YYYY-MM") === moment().subtract(1, 'months').format("YYYY-MM")).length;
          let difference = thisMonthAddedUsers - previousMonthUsersCount;
          let incrementPercentage = "";
          if(previousMonthUsersCount > 0)
          {
            incrementPercentage = ((difference * 100) / previousMonthUsersCount).toFixed(2);
            data = `${formatNumberForKPI(thisMonthAddedUsers)} (${incrementPercentage}%)`;
          }
          else {
            data = 0;
          }
          break;
        case "Online":
          data = gridData.filter((w: any) => w.ActivityStatus === "Online").length;
          break;
        case "Disabled":
          data = formatNumberForKPI(userData.filter((w: any) => w.UserStatusId === 3).length);
          break;
        case "Active QM Accounts":
        case "Active Users":
          const activeQMAccounts = userData.length - userData.filter((user: any) => moment(new Date(user.ExpiresOn)).isSameOrBefore(moment())).length;
          data = formatNumberForKPI(activeQMAccounts);
          break;
        case "Demo Users":
          let totalDemoAccounts = 0;
          if(userAccounts && userAccounts.length && userData.length)
          {
            userData.forEach((user) => {
              let currentUserAccounts = userAccounts.filter((w) => w.UserId == user.Id);
              if(currentUserAccounts.length && currentUserAccounts.every((ua) => ua.Account && ua.Account.Name && ua.Account.Name.toLowerCase().includes("demo")))
              {
                totalDemoAccounts++;
              }
            })
          }
          data = formatNumberForKPI(totalDemoAccounts);
          break;
      }

      stats.push({
        title: param,
        data
      });

    });

    return stats;
    
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent) => {
    store.dispatch(
      showEditForm({
        status: true,
        data: {
          fieldName: `Edit User ${event.data.Email}`,
          fieldData: event.data.Id,
        },
      })
    );
  };

  const addNewUser = () => {
    store.dispatch(
      showEditForm({
        status: true,
        data: {
          fieldName: "Add User",
          fieldData: -1,
        },
      })
    );
  };

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
    setIsGridReady(true);
  };

  const loadUsersList = async () => {
    try {

      let response: any ={};

      if(searchResults)
      {
        response = {payload: searchResults};
        const usersList = store.getState().users.data;
        if(usersList.length)
        {
          response.payload = searchResults.map((w) => {
            const userRecord = usersList.find((user) => w.Id === user.Id);
            return userRecord;
          })
        }
      }
      else {
        response = await loadUsers(dispatch);
      }

      if (response && response.payload) {

        const qmAccountData = {};
        
        const qmDataResponse = await apiSvc.get(`SelectQMAccountRequest`);

        if(qmDataResponse?.data && qmDataResponse.data.Data)
        {
          qmDataResponse.data.Data.forEach((qmAccount) => {
            qmAccountData[`${qmAccount.UserID}`] = qmAccount;
          });
        }

        const formattedUsers = response.payload.map((user) => {

          let metaData = user?.MetaData;
          try {
            metaData = metaData ? JSON.parse(metaData) : {
              ExternalIp: "",
              Application: "",
              Version: "",
              LastLoginTime: "",
              Location: ""
            };
          }
          catch(e)
          {
            console.error(e);
          }

          return {
            Account: "",
            FirmName: user?.Firm.Name,
            UserName: user?.UserName,
            FullName: `${user?.FirstName} ${user?.LastName}`,
            Email: user?.Email,
            Id: user?.Id,
            RoleId: user?.RoleId,
            ActivityStatus: activeUsers.includes(user?.Id) ? "Online" : "Offline",
            UserStatus: user?.UserStatus?.Status,
            UserStatusId: user?.UserStatusId,
            UserRole: user?.Roles,
            CreatedOn: new Date(moment(user?.CreatedDate).format("YYYY-MM-DD HH:mm:ss")),
            ExpiresOn: new Date(moment(user?.PasswordExpiresOn).format("YYYY-MM-DD HH:mm:ss")),
            MACAddress: "",
            InternalIP: "",
            ExternalIP: metaData.ExternalIp,
            Location: metaData.Location,
            Application: `${metaData.Application} ${metaData.Version}`,
            QMAccount: qmAccountData[`${user?.Id.toString()}`] ? qmAccountData[`${user?.Id.toString()}`].UserName : "",
            LastLoginAt: metaData.LastLoginTime ? new Date(moment(metaData.LastLoginTime).format("YYYY-MM-DD HH:mm:ss")) : ""
          }
        }).sort((a, b) => {
          const dateA = new Date(a.CreatedOn).getTime();
          const dateB = new Date(b.CreatedOn).getTime();
          return dateB - dateA;
        });
        setUserData(formattedUsers);
        if(gridApiRef.current)
        {
          gridApiRef.current.refreshCells();
        }
        store.dispatch(
          showEditForm({
            status: false,
            data: { fieldData: 0, fieldName: "" },
          })
        );
      }
    }
    catch (e) {
      console.error(e);
    }
    finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    loadUsersList();
  }, []);

  useEffect(() => {
    if(formData && formData.updatedData && formData.updatedData.user)
    {
      setIsLoadingData(true);
      setIsGridReady(false);
      loadUsersList();
    }
  }, [JSON.stringify(formData)]);

  //To update activity Status
  useEffect(() => {

    const updateGrid = () => {

      if(!isDebounceTimeExceeded(gridUpdateDebounceTime, 500))
      {
        return;
      }

      let currentData = userData.slice();

      currentData = currentData.map((u) => {
        if(activeUsers.includes(u.Id))
        {
          u.ActivityStatus = "Online";
        }
        else {
          u.ActivityStatus = "Offline";
        }
        return u;
      });

      const transaction: RowDataTransaction = {
        update: currentData
      };

      if (gridApiRef.current && gridApiRef.current.applyTransaction) {
        gridApiRef.current.applyTransaction(transaction);
        const nodes = gridApiRef.current.getRenderedNodes();
        gridApiRef.current.refreshCells({
          force: true,
          rowNodes: nodes,
          columns: ["ActivityStatus"],
        });
        gridUpdateDebounceTime = Date.now();
      }
    }

    updateGrid();
  }, [activeUsers])

  //To map with user accounts
  useEffect(() => {
    const updateGrid = () => {

      if(!isDebounceTimeExceeded(gridUpdateDebounceTime, 500))
      {
        return;
      }

      let currentData = userData.slice();

      currentData = currentData.map((u) => {
        const userAccount = userAccounts.find((w) => w.UserId === u.Id);
        if(userAccount)
        {
          u.Account = userAccount?.Account?.Name;
        }
        return u;
      });

      const transaction: RowDataTransaction = {
        update: currentData
      };

      if (gridApiRef.current && gridApiRef.current.applyTransaction) {
        gridApiRef.current.applyTransaction(transaction);
        gridUpdateDebounceTime = Date.now();
      }
    }

    if(userAccounts.length)
    {
      updateGrid();
    }
  }, [userAccounts, userData])
  
  
  
  return (
    <>
      {
        isLoadingData
          ?
          <CustomSpinner />
          :
          <AggridList
            gridOptions={gridOptions}
            onRowDoubleClicked={handleRowDoubleClick}
            primaryButtonText={searchResults && searchResults.length > 0 ? "" : 'Add User'}
            primaryButtonOnClickHandler={addNewUser}
            pagination={true}
            rowData={userData}
            contextMenuOptions={userContextMenuOptions}
            setRecordForContextMenu={setCurrentRow}
            gridName={"Users"}
            onGridReady={onGridReady}
            rowIdSelector={(data) => data.Id}
            kpiData={userKPIData()}
            windowId={windowId}
            noKPIData={searchResults && searchResults.length > 0}
            hideInternalSearch={searchResults && searchResults.length > 0}
          />
      }
    </>
  )
}

export default UsersList