import { USERSTATUS_SET_USERSTATUS } from "./user-status-types";
import { UsersStatusState } from "./UsersStatusState";

const initialState = new UsersStatusState();

export default function UserStatusReducer(state = initialState, action: any): UsersStatusState {
  switch (action.type) {
    case USERSTATUS_SET_USERSTATUS:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
}
