import DropdownIcon from "../../Images/dropdown-icon.png";
import "./styles.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { WIDGET_MODAL } from "../../store/actions/types";

const ShellDropdown = (props: any) => {
  const [displayDropdown, setDisplayDropdown] = useState<any>(false);
  const dispatch = useDispatch();

  return (
    <>
      <button
        className="btn ml-2 p-0"
        onClick={() => {
          setDisplayDropdown(!displayDropdown);
        }}
      >
        <img src={DropdownIcon} alt="" />{" "}
      </button>

      {displayDropdown && (
        <div id="dropdown-list">
          {props.dataValues.map((value: string, index: number) => {
            if (value === "hr")
              return <hr className="dropdown_list-line" key={index} />;
            else
              return (
                <div
                  className="inner-text"
                  key={index}
                  onClick={() => {
                    setDisplayDropdown(false);
                    dispatch({
                      type: WIDGET_MODAL,
                      payload: value,
                      id: props.id,
                    });
                  }}
                >
                  {value}
                </div>
              );
          })}

          {/* 
          <div className="inner-text">
            Market Movers{" "}
            <img style={{ marginLeft: "8px" }} src={RightArrowIcon} alt="" />{" "}
          </div> */}
        </div>
      )}
    </>
  );
};

export default ShellDropdown;
