import globalCache from "../../services/global-cache";

export class GeneralSettings {
  decimalPlaces = 2;
  defaultAccountId = 0;
  defaultDestinationId = 0;

  constructor() {
    const { generalSettings } = globalCache;

    if (generalSettings) {
      this.decimalPlaces = generalSettings.decimalPlaces;
      this.defaultAccountId = generalSettings.defaultAccountId;
      this.defaultDestinationId = generalSettings.defaultDestinationId;
    }
  }
}
