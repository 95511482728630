import moment from "moment";
import { IColumnDefinition } from "../../../models/IColumnDefinition";

export const userColumnsDefinition: IColumnDefinition[] = [
  {
    title: "Online",
    field: "ActivityStatus",
    visible: true,
    sortOrder: 1,
    sort: "desc",
    minWidth: 30,
    cellClass: (params) => params.data.ActivityStatus === "Online" ? "positive-cell custom-center custom-status-class" : "negative-cell custom-center custom-status-class",
  },
  {
    title: "Name",
    field: "FullName",
    visible: true,
    sortOrder: 18,
    minWidth: 80,
  },
  {
    title: "User",
    field: "UserName",
    visible: true,
    sortOrder: 13,
    minWidth: 100,
  },
  {
    title: "Account",
    field: "Account",
    visible: true,
    sortOrder: 3,
    minWidth: 80,
  },
  {
    title: "Created On",
    field: "CreatedOn",
    visible: true,
    sortOrder: 9,
    minWidth: 80,
    filter: "agDateColumnFilter",
    filterParams: {
      filterOptions: ['inRange'],
      maxNumConditions: 0,
      buttons: ['apply', 'reset'],
      inRangeInclusive: true
    },
    valueFormatter: (params) => {
      return moment(params?.value).format("YYYY-MM-DD");
    },
  },
  {
    title: "ExpiresOn",
    field: "ExpiresOn",
    visible: true,
    sortOrder: 8,
    minWidth: 80,
    filter: "agDateColumnFilter",
    filterParams: {
      filterOptions: ['inRange'],
      maxNumConditions: 0,
      buttons: ['apply', 'reset'],
      inRangeInclusive: true
    },
    valueFormatter: (params) => {
      return moment(params?.value).format("YYYY-MM-DD");
    },
  },
  {
    title: "LastLoginAt",
    field: "LastLoginAt",
    visible: true,
    sortOrder: 8,
    minWidth: 90,
    filter: "agDateColumnFilter",
    filterParams: {
      filterOptions: ['inRange'],
      maxNumConditions: 0,
      buttons: ['apply', 'reset'],
      inRangeInclusive: true
    },
    valueFormatter: (params) => {
      return params.value ? moment(params?.value).format("YYYY-MM-DD HH:mm:ss") : "";
    },
  },
  {
    title: "QM Account",
    field: "QMAccount",
    visible: true,
    sortOrder: 8,
    minWidth: 80,
  },
  {
    title: "External IP",
    field: "ExternalIP",
    visible: true,
    sortOrder: 14,
    minWidth: 80,
  },
  {
    title: "Application",
    field: "Application",
    visible: true,
    sortOrder: 8,
    minWidth: 80,
  },
  {
    title: "Location",
    field: "Location",
    visible: true,
    sortOrder: 7,
    minWidth: 120,
  },
  {
    title: "Status",
    field: "UserStatus",
    visible: true,
    sortOrder: 11,
    minWidth: 60,
  },
  {
    title: "Firm",
    field: "FirmName",
    visible: true,
    sortOrder: 3,
    minWidth: 80,
  },
  {
    title: "Role",
    field: "UserRole",
    visible: true,
    sortOrder: 10,
    minWidth: 50,
  },
  {
    title: "MAC Address",
    field: "MACAddress",
    visible: true,
    sortOrder: 21,
    minWidth: 70,
  },
  {
    title: "Internal IP",
    field: "InternalIP",
    visible: true,
    sortOrder: 12,
    minWidth: 80,
  },
];
