import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import { DATASOURCES_GET_DATASOURCES } from "./data-sources-types";
import { DataSourceState } from "./DataSourcesState";

const initialState = new DataSourceState();

export default function dataSourcesReducer(state = initialState, action: any): DataSourceState {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new DataSourceState();

    case DATASOURCES_GET_DATASOURCES:
      return {
        ...state,
        dataSources: action.payload,
      };

    default:
      return state;
  }
}
