import { IAccount } from '../models/IAccount';
import { IAccountOrder } from '../models/IAccountOrder';
import { IAuthInfo } from '../models/IAuthInfo';
import { IPosition } from '../models/IPosition';

class TempCache {
  get isDataLoaded() {
    return this.getItem('trader2b-is-data-loaded');
  }

  set isDataLoaded(value: boolean | null) {
    this.setItem('trader2b-is-data-loaded', value);
  }

  get lastLoginAt() {
    const value = this.getValue('trader2b-last-login-at');
    return value ? parseInt(value) : new Date().getTime();
  }

  set lastLoginAt(value: number) {
    this.setItem('trader2b-last-login-at', '' + value);
  }

  get password() {
    return this.getValue('trader2b-password');
  }

  set password(value: string | null) {
    this.setItem('trader2b-password', value);
  }

  get sid() {
    return this.getValue('trader2b-sid') || '';
  }

  set sid(value: string) {
    this.setItem('trader2b-sid', value);
  }

  get userAccount() {
    return this.getItem<IAccount>('trader2b-user-account');
  }

  set userAccount(value: IAccount | null) {
    this.setItem('trader2b-user-account', value);
  }

  get userAccounts() {
    return this.getItem<IAccount[]>('trader2b-user-accounts');
  }

  set userAccounts(value: IAccount[] | null) {
    this.setItem('trader2b-user-accounts', value);
  }

  get userInfo() {
    return this.getItem<IAuthInfo>('trader2b-user-info');
  }

  set userInfo(value: IAuthInfo | null) {
    this.setItem('trader2b-user-info', value);
  }

  get username() {
    return this.getValue('trader2b-username');
  }

  set username(value: string | null) {
    this.setItem('trader2b-username', value);
  }

  get userOrders() {
    return this.getItem<IAccountOrder[]>('trader2b-user-orders');
  }

  set userOrders(value: IAccountOrder[] | null) {
    this.setItem('trader2b-user-orders', JSON.stringify(value));
  }

  get widgetPreviousPosition() {
    return this.getItem<IPosition>('trader2b-widget-previous-position');
  }

  set widgetPreviousPosition(value: IPosition | null) {
    this.setItem('trader2b-widget-previous-position', value);
  }

  clear = () => sessionStorage.clear();
  
  getItem = <T>(key: string): T | null => {
    const cached = sessionStorage.getItem(key);
    
    return cached ? JSON.parse(cached) : null
  }

  getValue = (key: string): string | null => sessionStorage.getItem(key);
  
  removeItem = (key: string) => sessionStorage.removeItem(key);
  
  setItem = (key: string, value: any) => 
    sessionStorage.setItem(key, typeof value !== 'string' ? JSON.stringify(value) : value);
};

export default new TempCache();
