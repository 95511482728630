/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Add, CloudDownload, CloudUpload } from "@material-ui/icons";
import {
  ColDef,
  GridApi,
  GridOptions,
  PaginationChangedEvent,
  RowClickedEvent,
  RowDataTransaction,
} from "ag-grid-community";
import { ChangeEvent, Component } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import FlexView from "react-flexview/lib";
import { connect } from "react-redux";
import { filter, map } from "rxjs/operators";
import { CacheKeys } from "../../constants/cache-keys";
import { WatchListTypeEnum } from "../../enums/WatchListTypeEnum";
import { IWatchList } from "../../models/IWatchList";
import notificationSvc from "../../services/notification-service";
import { WIDGET_MODAL } from "../../store/actions/types";
import store from "../../store/index";
import {
  subscribeQuoteMediaData,
  unsubscribeQuoteMediaData,
} from "../../store/quote-media/quote-media-async-actions";
import { invalidSymbols$ } from "../../store/quote-media/quote-media-observables";
import { IAppState } from "../../store/reducers/IAppState";
import { removeWatchListSymbol$ } from "../../store/watch-list/watch-list-observables";
import {
  setLinkedSymbol,
  setWidgetSymbols,
} from "../../store/widgets/widgets-actions";
import { timeValueGetter } from "../../utils/datetime";
import {
  numberFormatter,
  volumeFormatter,
  wholeNumberFormatter,
} from "../../utils/number";
import Table from "../list/list";
import { IListItem } from "./IListItem";
import {
  IWatchListDispatchProps,
  IWatchListProps,
  IWatchListStateProps,
} from "./IWatchListProps";
import { IWatchListState } from "./IWatchListState";
import RemoveSymbolButton from "./remove-symbol-button";
import "./styles.scss";

type Props = IWatchListProps & IWatchListStateProps & IWatchListDispatchProps;

class WatchList extends Component<Props, IWatchListState> {
  columnDefs;
  currentData: IWatchList[] = [];
  gridApi: GridApi = {} as GridApi;
  gridColumnApi: any = {};
  gridOptions: GridOptions;
  initialData: IWatchList[] = [];
  list: IListItem[] = [];
  subscribedSymbols: string[] = [];

  constructor(props: Props) {
    super(props);

    this.columnDefs = this.getColumnDefs();

    this.state = {
      dropdownLoading: false,
      heading: "",
      isLoading: false,
      openHeadingModal: false,
      openSymbolModal: false,
      symbol: "",
      symbols: [] as string[],
    };

    this.gridOptions = {
      columnDefs: this.columnDefs,
      enableCellChangeFlash: true,
    };
  }

  componentDidMount() {
    const { watchListSavedSymbols } = this.props;
    if (watchListSavedSymbols) {
      watchListSavedSymbols.forEach((element) => {
        const parts = element.split("|");
        const item: IListItem = {
          value: parts[0],
          type: parts[1] || "symbol",
        };

        const existingSymbol = this.list.find(
          (element) =>
            element.type === item.type && element.value === item.value
        );

        if (!existingSymbol) {
          this.addListItem(item);
        }
      });
    }

    this.subscribeToRemoveSymbol();
    this.subscribeToInvalidSymbols();
  }

  componentDidUpdate(prevProps: Props) {
    this.handleColumnsVisibility(prevProps);
    this.handleSavedSymbols(prevProps);
    this.updateGrid(this.props.watchList);
  }

  componentWillUnmount() {
    this.props.unsubscribeQuoteMediaData();
  }

  render() {
    const { watchListType } = this.props;
    const { heading, symbol } = this.state;

    return (
      <FlexView height="100%" className="watch-list">
        <FlexView column grow>
          {watchListType !== WatchListTypeEnum.MostActive &&
            watchListType !== WatchListTypeEnum.Trader2B && (
              <FlexView className="action-buttons" vAlignContent="bottom">
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={heading}
                    placeholder="HEADING"
                    onChange={this.heading_Change}
                    onKeyPress={this.heading_KeyPress}
                  />

                  <InputGroup.Append>
                    <InputGroup.Text onClick={this.addHeading_Click}>
                      <Add style={{ color: "#fff" }} />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>

                <InputGroup>
                  <Form.Control
                    type="text"
                    value={symbol}
                    placeholder="SYMBOL"
                    onChange={this.symbol_Change}
                    onKeyPress={this.symbol_KeyPress}
                  />

                  <InputGroup.Append>
                    <InputGroup.Text onClick={this.addSymbol_Click}>
                      <Add style={{ color: "#fff" }} />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>

                <span className="spacer-v"></span>

                <span
                  className="hw-btn-link create-watch-list"
                  onClick={this.createWatchlist_Click}
                >
                  Create watchlist
                </span>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="save-watchlist" className="hw-tooltip">
                      Save watchlist
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <CloudDownload
                      ref={ref}
                      onClick={this.saveWatchlist_Click}
                      {...triggerHandler}
                    />
                  )}
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="load-watchlist" className="hw-tooltip">
                      Load watchlist
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <CloudUpload
                      ref={ref}
                      onClick={this.loadWatchlist_Click}
                      {...triggerHandler}
                    />
                  )}
                </OverlayTrigger>
              </FlexView>
            )}

          <FlexView column grow>
            <Table
              cacheKey={CacheKeys.WatchListColumns}
              gridOptions={this.gridOptions}
              pagination={true}
              rowData={this.initialData}
              emptyGridMessage="Provide any Symbol / Heading to Proceed!"
              rowIdSelector={(x) => x.symbol}
              onGridReady={this.onGridReady}
              onPaginationChanged={this.onPaginationChanged}
              onRowClicked={this.onRowClicked}
            />
          </FlexView>
        </FlexView>

        <input
          style={{ display: "none" }}
          id="LoadUploadFile"
          type="file"
          onChange={(e) => this.showFile(e)}
        />

        <a id="downloadAnchorElem" style={{ display: "none" }}></a>
      </FlexView>
    );
  }

  addHeading_Click = () => {
    this.setState({ openHeadingModal: true });
  };

  addSymbol_Click = () => {
    this.setState({ openSymbolModal: true });
  };

  createWatchlist_Click = () => {
    this.props.unsubscribeQuoteMediaData();
    this.props.setWidgetSymbols([]);
    this.list = [];

    this.setState({
      openSymbolModal: false,
      openHeadingModal: false,
    });

    store.dispatch({
      type: WIDGET_MODAL,
      payload: null,
      id: this.props.windowId,
    });
  };

  heading_Change = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ heading: ev.target.value.toUpperCase() });
  };

  heading_KeyPress = (e) => {
    this.handleChange(e, "heading");
  };

  loadWatchlist_Click = () => {
    document.getElementById("LoadUploadFile")?.click();
  };

  positiveNegativeCellClassRules = () => ({
    "price-positive": (params) => params.value > 0,
    "price-negative": (params) => params.value < 0,
  });

  saveWatchlist_Click = () => {
    var watchListData = this.list.map(function (el) {
      return el;
    });

    const fileContents =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(watchListData));
    const dlAnchorElem = document.getElementById("downloadAnchorElem");
    dlAnchorElem!.setAttribute("href", fileContents);
    dlAnchorElem!.setAttribute("download", `Watchlist.json`);
    dlAnchorElem!.click();
  };

  symbol_Change = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ symbol: ev.target.value.toUpperCase() });
  };

  symbol_KeyPress = (e) => {
    this.handleChange(e, "symbol");
  };

  showFile = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const reader = new FileReader();

    reader.onload = async (e) => {
      var result: any = e.target!.result;
      var listItems: IListItem[] = JSON.parse(result);

      listItems!.forEach((item: any) => {
        const existingItem = this.list.find(
          (element) =>
            element.type === item.type && element.value === item.value
        );

        if (!existingItem) {
          this.addListItem({
            value: item.value,
            type: item.type,
          });

          if (!this.props.watchListSavedSymbols) {
            this.props.setWidgetSymbols([`${item.value}|${item.type}`]);
          } else {
            this.props.setWidgetSymbols([
              ...this.props.watchListSavedSymbols,
              `${item.value}|${item.type}`,
            ]);
          }
        }
      });
    };

    if (e.target?.files?.length === 1) {
      reader.readAsText(e.target.files[0]);
    }
  };

  subscribeToInvalidSymbols = () => {
    invalidSymbols$.pipe(filter((x) => x !== "")).subscribe((symbol) => {
      let idx: number;
      if ((idx = this.list.findIndex((x) => x.value === symbol)) !== -1) {
        this.list.splice(idx, 1);
        this.props.setWidgetSymbols(
          this.list.map((x) => `${x.value}|${x.type}`)
        );
      }
    });
  };

  subscribeToRemoveSymbol = () => {
    removeWatchListSymbol$
      .pipe(
        filter((x) => x.widgetId === this.props.windowId),
        map((x) => x.symbol)
      )
      .subscribe((symbol) => {
        const idx = this.list.findIndex((x) => x.value === symbol);
        this.list.splice(idx, 1);
        this.props.setWidgetSymbols(
          this.list.map((x) => `${x.value}|${x.type}`)
        );
      });
  };

  addListItem = (item: IListItem) => {
    this.list.push(item);
    this.updateGrid(this.currentData);
  };

  handleChange = async (e, type: string) => {
    if (e.key === "Enter") {
      if (e.target.value === "") {
        const title = type === "heading" ? "Heading" : "Symbol";
        notificationSvc.error(`Enter a ${title} to load Data!`);
        return;
      }

      const { setWidgetSymbols, watchListSavedSymbols } = this.props;
      const value = e.target.value.toUpperCase();
      const listItem: IListItem = { type, value };

      const existingItem = this.list.find(
        (element) =>
          element.type === listItem.type && element.value === listItem.value
      );

      if (!existingItem) {
        this.addListItem(listItem);

        if (!watchListSavedSymbols) {
          setWidgetSymbols([`${value}|${type}`]);
        } else {
          setWidgetSymbols([...watchListSavedSymbols, `${value}|${type}`]);
        }

        this.setState({ heading: "", symbol: "" });
      }
    }
  };

  handleColumnsVisibility = (prevProps: Props) => {
    const { columns } = this.props;
    if (columns !== prevProps.columns) {
      const visibleColumnNames = columns
        .filter((x) => x.visible)
        .map((x) => x.field);
      this.gridColumnApi.setColumnsVisible(visibleColumnNames, true);

      const hiddenColumnNames = columns
        .filter((x) => !x.visible)
        .map((x) => x.field);
      this.gridColumnApi.setColumnsVisible(hiddenColumnNames, false);
    }
  };

  handleSavedSymbols = (prevProps: Props) => {
    const previousSymbols = prevProps.watchListSavedSymbols
      ? JSON.stringify(prevProps.watchListSavedSymbols)
      : "";
    const currentSymbols = this.props.watchListSavedSymbols
      ? JSON.stringify(this.props.watchListSavedSymbols)
      : "";

    if (currentSymbols && currentSymbols !== previousSymbols) {
      this.list = [];

      this.props.watchListSavedSymbols.forEach((element) => {
        const parts = element.split("|");
        this.list.push({
          type: parts[1] || "symbol",
          value: parts[0],
        });
      });

      this.updateGrid(this.currentData);
    }
  };

  onGridReady = async (params: any) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const hiddenColumnNames = this.props.columns
      .filter((x) => x.visible === false)
      .map((x) => x.field);
    if (hiddenColumnNames.length > 0) {
      this.gridColumnApi.setColumnsVisible(hiddenColumnNames, false);
    }

    this.updateGrid([]);
  };

  onPaginationChanged = (event: PaginationChangedEvent) => {
    this.subscribeToQuoteMedia(event);
  };

  onRowClicked = (event: RowClickedEvent) => {
    const watchList: IWatchList = event.data;
    this.props.linkWidget(watchList.symbol);
  };

  getColumnDefs = (): ColDef[] => {
    const numericFormatter = numberFormatter(this.props.decimalPlaces);

    return [
      {
        cellRendererFramework: RemoveSymbolButton,
        cellRendererParams: {
          widgetId: this.props.windowId,
        },
        width: 24,
        minWidth: 24,
        cellClass: "pl-0 pt-0",
        pinned: true,
      },
      {
        field: "symbol",
        sortable: true,
        cellClass: "text-underline",
        minWidth: 80,
        cellStyle: (params: { data: IWatchList }) =>
          params.data.type === "heading"
            ? {
                backgroundColor: "#f39222",
                color: "white",
                fontWeight: "bold",
              }
            : {
                color: "#d8dee6",
              },
      },
      {
        field: "longName",
        sortable: true,
        minWidth: 90,
      },
      {
        field: "change",
        type: "numericColumn",
        sortable: true,
        cellClass: "text-right",
        cellClassRules: this.positiveNegativeCellClassRules(),
        minWidth: 80,
        valueFormatter: numericFormatter,
      },
      {
        field: "percentChange",
        type: "numericColumn",
        sortable: true,
        cellClass: "text-right",
        cellClassRules: this.positiveNegativeCellClassRules(),
        minWidth: 80,
        valueFormatter: numericFormatter,
      },
      {
        field: "last",
        type: "numericColumn",
        valueFormatter: numericFormatter,
      },
      {
        field: "size",
        type: "numericColumn",
        valueFormatter: wholeNumberFormatter,
      },
      {
        field: "bidPrice",
        type: "numericColumn",
        valueFormatter: numericFormatter,
      },
      {
        field: "accumulatedVolume",
        type: "numericColumn",
        valueFormatter: volumeFormatter,
      },
      {
        field: "bidSize",
        type: "numericColumn",
        valueFormatter: wholeNumberFormatter,
      },
      {
        field: "askPrice",
        type: "numericColumn",
        valueFormatter: numericFormatter,
      },
      {
        field: "askSize",
        type: "numericColumn",
        valueFormatter: wholeNumberFormatter,
      },
      {
        field: "open",
        type: "numericColumn",
        valueFormatter: numericFormatter,
      },
      {
        field: "close",
        type: "numericColumn",
        valueFormatter: numericFormatter,
      },
      {
        field: "high",
        type: "numericColumn",
        valueFormatter: numericFormatter,
      },
      {
        field: "low",
        type: "numericColumn",
        valueFormatter: numericFormatter,
      },
      {
        field: "lastTradeTime",
        valueGetter: (params: any) =>
          timeValueGetter(params.node.data.lastTradeTime),
      },
      {
        field: "vwap",
        valueFormatter: numericFormatter,
      },
    ];
  };

  subscribeToQuoteMedia = (event: PaginationChangedEvent) => {
    const firstRow = event.api.getFirstDisplayedRow();
    const lastRow = event.api.getLastDisplayedRow();

    const visibleSymbols: string[] = [];
    event.api.forEachNodeAfterFilter((node, index) => {
      if (index >= firstRow && index <= lastRow) {
        const dataItem = node.data as IWatchList;
        if (dataItem.type === "symbol") {
          visibleSymbols.push(dataItem.symbol);
        }
      }
    });

    const subscribed = JSON.stringify(this.subscribedSymbols);
    const visible = JSON.stringify(visibleSymbols);
    if (subscribed !== visible) {
      this.props.unsubscribeQuoteMediaData();

      (this.subscribedSymbols = visibleSymbols).forEach((symbol) =>
        this.props.subscribeQuoteMediaData(symbol)
      );
    }
  };

  updateGrid = (watchList: IWatchList[]) => {
    watchList = watchList.map((x) => ({
      ...x,
      askSize: x.askSize * 100,
      bidSize: x.bidSize * 100,
    }));

    const newData: IWatchList[] = this.list.map((item) => {
      const defaultItem = {
        symbol: item.value,
        type: item.type,
      } as IWatchList;

      if (item.type === "heading") {
        return defaultItem;
      } else {
        const watchListItem = watchList.find(
          (x) => x.symbol === item.value && x.bidPrice
        );

        return watchListItem || defaultItem;
      }
    });

    const add: IWatchList[] = [];
    const update: IWatchList[] = [];

    this.list.forEach((item) => {
      const currentDataItem = this.currentData.find(
        (x) => x.symbol === item.value
      );
      const newDataItem = newData.find(
        (x) => x.symbol === item.value
      ) as IWatchList;

      if (!currentDataItem) {
        add.push(newDataItem);
      } else if (
        JSON.stringify(currentDataItem) !== JSON.stringify(newDataItem)
      ) {
        update.push(newDataItem);
      }
    });

    const remove: IWatchList[] = [];

    this.currentData.forEach((currentDataItem) => {
      const newDataItem = newData.find(
        (x) => x.symbol === currentDataItem.symbol
      );

      if (!newDataItem) {
        remove.push({
          symbol: currentDataItem.symbol,
        } as IWatchList);
      }
    });

    const transaction: RowDataTransaction = {
      add,
      remove,
      update,
    };

    if (
      this.gridApi &&
      this.gridApi.applyTransaction &&
      ((transaction.add?.length || 0) > 0 ||
        (transaction.remove?.length || 0) > 0 ||
        (transaction.update?.length || 0) > 0)
    ) {
      this.gridApi.applyTransaction(transaction);
      this.currentData = newData;
    }
  };
}

const mapStateToProps = (
  state: IAppState,
  ownProps: IWatchListProps
): IWatchListStateProps => ({
  activeWidgetId: state.widgets.activeWidget,
  columns: state.settings.watchListColumns,
  decimalPlaces: state.settings.general.decimalPlaces,
  watchList: state.qm.watchList,
  watchListSavedSymbols: state.widgets.symbols[ownProps.windowId],
});

const mapDispatchToProps = (
  dispatch: any,
  ownProps: IWatchListProps
): IWatchListDispatchProps => {
  const { windowId } = ownProps;

  return {
    linkWidget: (symbol) => dispatch(setLinkedSymbol(windowId, symbol)),
    setWidgetSymbols: (symbols) =>
      dispatch(setWidgetSymbols(windowId, symbols)),
    subscribeQuoteMediaData: (symbol) =>
      dispatch(subscribeQuoteMediaData(windowId, symbol)),
    unsubscribeQuoteMediaData: () =>
      dispatch(unsubscribeQuoteMediaData(windowId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchList);
