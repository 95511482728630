import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import FlexView from 'react-flexview';
import { Spinner } from 'reactstrap';
import logo from "../../Images/logo-vertical.jpg";
import apiSvc from '../../services/api-service';
import globalCache from '../../services/global-cache';
import tempCache from '../../services/temp-cache';
import store from '../../store';
import { setLoginCredentials } from '../../store/auth/auth-actions';
import { authenticateUser } from "../../store/auth/auth-async-actions";
import { IChangePasswordViewProps } from './IChangePasswordViewProps';
import { IChangePasswordViewState } from './IChangePasswordViewState';

class ChangePasswordView extends Component<IChangePasswordViewProps, IChangePasswordViewState> {
  constructor(props) {
    super(props);

    this.state = {
      changePasswordDetails: {
        Username: this.props.displayName,
        Password: "",
        NewPassword: "",
      },
      changePasswordInProgress: false,
      confirmPassword: "",
      isConfirmPasMatched: false,
      isConfirmPasShown: false,
      isNewPasswordShown: false,
      isOldPasswordShown: false,
      isResponseMessageModal: false,
      isSuccess: false,
      responseMessage: "",
    };
  }

  render() {
    const { close_Click } = this.props;
    const {
      changePasswordInProgress,
      isConfirmPasMatched,
      isResponseMessageModal,
      isSuccess,
      responseMessage,
    } = this.state;

    return (
      <>
        <Modal
          show={true}
          onHide={close_Click}
          backdrop="static"
          keyboard={false}
          centered
          className="change-password-modal"
        >
          <Modal.Header>
            <FlexView column hAlignContent="center" vAlignContent="center" basis="100%">
              <img height={96} src={logo} alt="Logo" />
            </FlexView>
          </Modal.Header>
          <Modal.Body
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                this.updatePassword();
              }
            }}
          >
            <Form.Label style={{ fontSize: "20px" }}>Old Password</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type={this.state.isOldPasswordShown ? "text" : "password"}
                value={this.state.changePasswordDetails["Password"]}
                onChange={(e) =>
                  this.setState({
                    changePasswordDetails: {
                      ...this.state.changePasswordDetails,
                      Password: e.target.value,
                    },
                  })
                }
              />

              <InputGroup.Append>
                <InputGroup.Text
                  className="show-password-eye-icon"
                  onClick={() => {
                    this.setState({
                      isOldPasswordShown: !this.state.isOldPasswordShown,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={this.state.isOldPasswordShown ? ["fas", "eye"] : ["fas", "eye-slash"]}
                    color="#fff"
                  />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>

            <Form.Label style={{ fontSize: "20px" }}>New Password</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type={this.state.isNewPasswordShown ? "text" : "password"}
                value={this.state.changePasswordDetails["NewPassword"]}
                onChange={(e) =>
                  this.setState({
                    changePasswordDetails: {
                      ...this.state.changePasswordDetails,
                      NewPassword: e.target.value,
                    },
                  })
                }
              />

              <InputGroup.Append>
                <InputGroup.Text
                  className="show-password-eye-icon"
                  onClick={() => {
                    this.setState({
                      isNewPasswordShown: !this.state.isNewPasswordShown,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={this.state.isNewPasswordShown ? ["fas", "eye"] : ["fas", "eye-slash"]}
                    color="#fff"
                  />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <Form.Label style={{ fontSize: "20px" }}>Confirm Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={this.state.isConfirmPasShown ? "text" : "password"}
                value={this.state.confirmPassword}
                onChange={(e) =>
                  this.setState({
                    confirmPassword: e.target.value,
                  })
                }
              />

              <InputGroup.Append>
                <InputGroup.Text
                  className="show-password-eye-icon"
                  onClick={() => {
                    this.setState({
                      isConfirmPasShown: !this.state.isConfirmPasShown,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={this.state.isConfirmPasShown ? ["fas", "eye"] : ["fas", "eye-slash"]}
                    color="#fff"
                  />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <div className="mb-3">
              {isConfirmPasMatched && <span className="text-danger">Password does not matched</span>}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-change-password-close" variant="primary" onClick={() => close_Click()}>
              <FontAwesomeIcon icon={["fas", "sign-out-alt"]} className="mr-2" />
              Close
            </Button>
            <Button className="btn-change-password" variant="primary" onClick={() => this.matchNewAndConfirmPas()}>
              {changePasswordInProgress ? (
                <Spinner color="light" size="sm" className="mr-1" />
              ) : (
                <FontAwesomeIcon icon={["fas", "lock"]} className="mr-2" />
              )}
              Change Password
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={isResponseMessageModal} backdrop="static" size={"sm"} keyboard={false} centered>
          <Modal.Body>
            <FlexView column hAlignContent="center" vAlignContent="center">
              {isSuccess ? (
                <FontAwesomeIcon icon={["far", "check-circle"]} size="5x" className="mb-3 text-success" />
              ) : (
                <FontAwesomeIcon icon={["fas", "times-circle"]} size="5x" className="mb-3 text-danger" />
              )}
              <span className="font-weight-bold text-capitalize text-dark">{responseMessage}</span>
            </FlexView>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={isSuccess ? "success" : "danger"}
              onClick={this.responseModal_Click}
            >
              {isSuccess ? (
                <FontAwesomeIcon icon={["far", "check-circle"]} className="mr-2" />
              ) : (
                <FontAwesomeIcon icon={["fas", "redo"]} className="mr-2" />
              )}

              {isSuccess ? "Close" : "Try Again"}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  private authenticateUser(username: string, password: string) {
    tempCache.username = username;
    tempCache.password = password;

    const credentials = { username, password };

    store.dispatch(setLoginCredentials(credentials));
    store.dispatch(authenticateUser);

    const { userSavedDetails } = globalCache;
    if (userSavedDetails) {
      globalCache.userSavedDetails = credentials;
    }
  }

  private matchNewAndConfirmPas = () => {
    const { confirmPassword, changePasswordDetails } = this.state;
    if (confirmPassword === changePasswordDetails["NewPassword"]) {
      this.updatePassword();
    } else {
      this.setState({
        isConfirmPasMatched: true,
      });
    }
  };

  private responseModal_Click = () => {
    this.setState({
      isResponseMessageModal: false,
      responseMessage: "",
      confirmPassword: "",
      changePasswordDetails: {
        Username: this.props.displayName,
        Password: "",
        NewPassword: "",
      },
    });

    if (this.state.isSuccess) {
      this.props.close_Click();
    }
  };

  private updatePassword = () => {
    this.setState({ changePasswordInProgress: true });


    apiSvc.api.post('ChangePasswordRequest', this.state.changePasswordDetails)
      .then((res) => {
        this.setState({
          changePasswordInProgress: false,
          responseMessage: res.data.message,
          isSuccess: res.data.IsSuccess,
          isResponseMessageModal: true,
        });

        if (res.data.IsSuccess === true) {
          this.authenticateUser(
            this.state.changePasswordDetails["Username"],
            this.state.changePasswordDetails["NewPassword"]
          );
        }
      });
  };
}

export default ChangePasswordView;
