import { IDataSource } from "./../models/IDataSource";
import apiSvc from "./api-service";

class DataSourceService {
  dataSources: IDataSource[] = [];

  async get() {
    if (this.dataSources?.length > 0) {
      return this.dataSources;
    }
    const response = await apiSvc.get("SelectDataSourcesRequest");
    return (this.dataSources = response?.data.Data);
  }
}

const dataSourceSvc = new DataSourceService();
export default dataSourceSvc;
