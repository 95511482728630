import apiSvc from "../../services/api-service";
import { setUsersAds } from "./users-ads-actions";

export async function loadUsersAds(dispatch: any) {
  apiSvc.get("SelectUserAdRequest").then((response) => dispatch(setUsersAds(response?.data.Data)));
}

export const addUsersAd = (userAd) => async (dispatch: any) => {
  await apiSvc.put('SaveUserAdRequest', userAd);
  dispatch(loadUsersAds);
}

export const deleteUsersAd = (userAdKey) => (dispatch: any) => {
  apiSvc.api.delete('DeleteUserAdRequest', {
    params: {
      PrimaryKey: userAdKey,
      LoadCache: true,
    },
  })
    .then(() => dispatch(loadUsersAds));
};
