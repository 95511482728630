import { IAccount } from "../../models/IAccount";
import { IColumnDefinition } from "../../models/IColumnDefinition";
import globalCache from "../../services/global-cache";
import { ACCOUNT_SET_ACCOUNTS } from "../account/account-types";
import {
  SETTINGS_CONFIRMATION_MODAL, SETTINGS_COPY_STATE, SETTINGS_EXITING_SYSTEM_NOTIFICATION, SETTINGS_FULLSCREEN_MODE,
  SETTINGS_GENERAL_SET_DECIMAL_PLACES, SETTINGS_GENERAL_SET_DEFAULT_ACCOUNT, SETTINGS_GENERAL_SET_DEFAULT_DESTINATION,
  SETTINGS_IS_SHORTCUT_COMBINATION_ALLOWED, SETTINGS_KEYBOARD_SHORTCUT_SHOW_MODAL, 
  SETTINGS_LEVEL_II_SKIN_COLOR, SETTINGS_MODAL_RESPONSE, 
  SETTINGS_NEW_ORDER_LIMIT_NOTIFICATION, SETTINGS_NEW_ORDER_NOTIFICATION, 
  SETTINGS_ORDER_BLOTTER_COLUMN_UPDATE, SETTINGS_ORDER_PRICE_LIMIT_NOTIFICATION, SETTINGS_ORDER_REJECTION_NOTIFICATION, 
  SETTINGS_POSITION_BLOTTER_COLUMN_UPDATE, 
  SETTINGS_RESET_ALL_SETTINGS, SETTINGS_SAVE_LAYOUT_NOTIFICATION, SETTINGS_SAVE_POINT_NOTIFICATION, 
  SETTINGS_SAVE_SHARES_NOTIFICATION, SETTINGS_SET_ACCOUNT_STATUSES, SETTINGS_SET_KEYBOARD_SHORTCUT, 
  SETTINGS_SET_SELECTED_KEYBOARD_SHORTCUT, SETTINGS_SET_SELECTED_SHORTCUT_COMBINATION, SETTINGS_SET_SHORTCUT_COMBINATION, 
  SETTINGS_SHORTCUT_COMBINATION_INFO_SHOW_MODAL, SETTINGS_SHORTCUT_COMBINATION_SHOW_MODAL, SETTINGS_SHOW_MODAL, 
  SETTINGS_TIME_AND_SALES_COLUMN_UPDATE, SETTINGS_USER_CLOSED_WIDGET, SETTINGS_WATCH_LIST_COLUMN_UPDATE, SETTINGS_WIDGET_DISPLAYED
} from "./settings-types";
import { SettingsState } from "./SettingsState";

const initialState = new SettingsState();

export default function settingsReducer(
  state = initialState,
  action: any
): SettingsState {
  let newState: SettingsState;

  switch (action.type) {
    case ACCOUNT_SET_ACCOUNTS:
      const { defaultAccountId, defaultDestinationId } = state.general;
      if (defaultAccountId && defaultDestinationId) {
        return state;
      }

      const account: IAccount = action.payload[0];

      return {
        ...state,
        general: {
          ...state.general,
          defaultAccountId: account?.AccountId,
          defaultDestinationId: account?.AccountDestination && account?.AccountDestination[0]?.DestinationId
        }
      };

    case SETTINGS_GENERAL_SET_DECIMAL_PLACES:
      newState = {
        ...state,
        general: {
          ...state.general,
          decimalPlaces: action.payload,
        },
      };

      globalCache.generalSettings = newState.general;
      return newState;

    case SETTINGS_GENERAL_SET_DEFAULT_ACCOUNT:
      newState = {
        ...state,
        general: {
          ...state.general,
          defaultAccountId: action.payload,
        },
      };

      globalCache.generalSettings = newState.general;
      return newState;

    case SETTINGS_GENERAL_SET_DEFAULT_DESTINATION:
      newState = {
        ...state,
        general: {
          ...state.general,
          defaultDestinationId: action.payload,
        },
      };

      globalCache.generalSettings = newState.general;
      return newState;

    case SETTINGS_SET_ACCOUNT_STATUSES:
      return {
        ...state,
        accountStatuses: action.payload,
      };

    case SETTINGS_SHOW_MODAL:
      return {
        ...state,
        isSettingsShown: action.payload,
      };

    case SETTINGS_SHORTCUT_COMBINATION_SHOW_MODAL:
      return {
        ...state,
        isShortcutCombinationsShown: action.payload,
      };

    case SETTINGS_SHORTCUT_COMBINATION_INFO_SHOW_MODAL:
      return {
        ...state,
        isShortcutCombinationsInfoShown: action.payload,
      };

    case SETTINGS_KEYBOARD_SHORTCUT_SHOW_MODAL:
      return {
        ...state,
        isKeyboardShortcutShown: action.payload,
      };

    case SETTINGS_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: action.payload,
      };

    case SETTINGS_MODAL_RESPONSE:
      return {
        ...state,
        isResponse: action.payload,
      };

    case SETTINGS_USER_CLOSED_WIDGET:
      return {
        ...state,
        isUserClosedWidget: action.payload,
      };

    case SETTINGS_WIDGET_DISPLAYED:
      return {
        ...state,
        isWidgetDisplayedModalShown: action.payload,
      };

    case SETTINGS_FULLSCREEN_MODE:
      return {
        ...state,
        isFullScreen: action.payload,
      };

    case SETTINGS_NEW_ORDER_NOTIFICATION:
      newState = {
        ...state,
        notificationSetting: {
          ...state.notificationSetting,
          orderNotification: action.payload,
        },
      };

      globalCache.notificationSettings = newState.notificationSetting;
      return newState;

    case SETTINGS_NEW_ORDER_LIMIT_NOTIFICATION:
      newState = {
        ...state,
        notificationSetting: {
          ...state.notificationSetting,
          orderLimitNotification: action.payload,
        },
      };

      globalCache.notificationSettings = newState.notificationSetting;
      return newState;

    case SETTINGS_EXITING_SYSTEM_NOTIFICATION:
      newState = {
        ...state,
        notificationSetting: {
          ...state.notificationSetting,
          exitingNotification: action.payload,
        },
      };

      globalCache.notificationSettings = newState.notificationSetting;
      return newState;

    case SETTINGS_ORDER_REJECTION_NOTIFICATION:
      newState = {
        ...state,
        notificationSetting: {
          ...state.notificationSetting,
          orderRejectionNotification: action.payload,
        },
      };

      globalCache.notificationSettings = newState.notificationSetting;
      return newState;

    case SETTINGS_ORDER_PRICE_LIMIT_NOTIFICATION:
      newState = {
        ...state,
        notificationSetting: {
          ...state.notificationSetting,
          orderPriceNotification: action.payload,
        },
      };

      globalCache.notificationSettings = newState.notificationSetting;
      return newState;

    case SETTINGS_RESET_ALL_SETTINGS:
      return new SettingsState();

    case SETTINGS_SAVE_LAYOUT_NOTIFICATION:
      newState = {
        ...state,
        notificationSetting: {
          ...state.notificationSetting,
          saveNotification: action.payload,
        },
      };

      globalCache.notificationSettings = newState.notificationSetting;
      return newState;

    case SETTINGS_SAVE_SHARES_NOTIFICATION:
      newState = {
        ...state,
        notificationSetting: {
          ...state.notificationSetting,
          orderLimit: action.payload,
        },
      };

      globalCache.notificationSettings = newState.notificationSetting;
      return newState;

    case SETTINGS_SAVE_POINT_NOTIFICATION:
      newState = {
        ...state,
        notificationSetting: {
          ...state.notificationSetting,
          oderPrice: action.payload,
        },
      };

      globalCache.notificationSettings = newState.notificationSetting;
      return newState;

    case SETTINGS_ORDER_BLOTTER_COLUMN_UPDATE:
      newState = {
        ...state,
        orderBlotterColumns: updateVisibleColumns(
          state.orderBlotterColumns,
          action.payload.title,
          action.payload.visible
        ),
      };

      globalCache.orderBlotterColumns = newState.orderBlotterColumns;
      return newState;

    case SETTINGS_POSITION_BLOTTER_COLUMN_UPDATE:
      newState = {
        ...state,
        positionBlotterColumns: updateVisibleColumns(
          state.positionBlotterColumns,
          action.payload.title,
          action.payload.visible
        ),
      };

      globalCache.positionBlotterColumns = newState.positionBlotterColumns;
      return newState;

    case SETTINGS_TIME_AND_SALES_COLUMN_UPDATE:
      newState = {
        ...state,
        timeAndSalesColumns: updateVisibleColumns(
          state.timeAndSalesColumns,
          action.payload.title,
          action.payload.visible
        ),
      };

      globalCache.timeAndSalesColumns = newState.timeAndSalesColumns;
      return newState;

    case SETTINGS_WATCH_LIST_COLUMN_UPDATE:
      newState = {
        ...state,
        watchListColumns: updateVisibleColumns(
          state.watchListColumns,
          action.payload.title,
          action.payload.visible
        ),
      };

      globalCache.watchListColumns = newState.watchListColumns;
      return newState;

    case SETTINGS_SET_KEYBOARD_SHORTCUT:
      newState = {
        ...state,
        keyboardShortcut: action.payload,
      };

      globalCache.keyboardShortcuts = newState.keyboardShortcut;
      return newState;

    case SETTINGS_LEVEL_II_SKIN_COLOR:
      newState = {
        ...state,
        marketDepth: {
          ...state.marketDepth,
          levelIISkinColor: action.payload,
        },
      };

      globalCache.marketDepthSettings = newState.marketDepth;
      return newState;

    case SETTINGS_SET_SELECTED_KEYBOARD_SHORTCUT:
      return {
        ...state,
        selectedKeyboardShortcutToEdit: action.payload,
      };

    case SETTINGS_SET_SHORTCUT_COMBINATION:
      newState = {
        ...state,
        shortcutCombinations: action.payload,
      };

      globalCache.shortcutCombinations = newState.shortcutCombinations;
      return newState;

    case SETTINGS_SET_SELECTED_SHORTCUT_COMBINATION:
      return {
        ...state,
        selectedCombinationShortcutToEdit: action.payload,
      };

    case SETTINGS_IS_SHORTCUT_COMBINATION_ALLOWED:
      newState = {
        ...state,
        isShortcutCombinationAllowed: action.payload,
      };

      globalCache.isShortcutCombinationAllowed = action.payload;
      return newState;

    case SETTINGS_COPY_STATE:
      return action.payload

    default:
      return state;
  }
}

function updateVisibleColumns(
  columns: IColumnDefinition[],
  title: string,
  visible: boolean
) {
  const columnsList = [...columns];
  const index = columnsList.findIndex((x) => x.title === title);
  columnsList[index] = {
    ...columnsList[index],
    visible,
  };

  return columnsList;
}
