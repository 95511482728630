import { IColumnDefinition } from "../../../models/IColumnDefinition";

export const positionBlotterDefaultColumns: IColumnDefinition[] = [
  {
    title: "$BOT Value",
    field: "LongExposure",
    visible: true,
    sortOrder: 13,
    minWidth: 120,
  },
  {
    title: "$BP Used",
    field: "BPUsed",
    visible: true,
    sortOrder: 16,
    minWidth: 120,
  },
  {
    title: "$Drawdown",
    field: "DrawDown",
    visible: true,
    sortOrder: 22,
    minWidth: 120,
  },
  {
    title: "$RunUp",
    field: "RunUp",
    visible: true,
    sortOrder: 23,
    minWidth: 120,
  },
  {
    title: "$SLD Value",
    field: "ShortExposure",
    visible: true,
    sortOrder: 14,
    minWidth: 120,
  },
  {
    title: "Account",
    field: "AccountName",
    visible: true,
    sortOrder: 1,
    minWidth: 120,
  },
  {
    title: "Avg Cost",
    field: "AvgPrice",
    visible: true,
    sortOrder: 5,
    minWidth: 120,
  },
  {
    title: "Close PnL",
    field: "RealPL",
    visible: true,
    sortOrder: 7,
    minWidth: 120,
  },
  { title: "Comm", field: "Comm", visible: true, sortOrder: 17, minWidth: 120 },
  {
    title: "Currency",
    field: "Currency",
    visible: true,
    sortOrder: 24,
    minWidth: 120,
  },
  {
    title: "EX Fees",
    field: "ExFees",
    visible: true,
    sortOrder: 20,
    minWidth: 120,
  },
  {
    title: "Group",
    field: "Group",
    visible: true,
    sortOrder: 15,
    minWidth: 120,
  },
  { title: "Last", field: "Last", visible: true, sortOrder: 4, minWidth: 120 },
  { title: "Misc", field: "Misc", visible: true, sortOrder: 21, minWidth: 120 },
  {
    title: "Open PnL",
    field: "UnrealPL",
    visible: true,
    sortOrder: 6,
    minWidth: 120,
  },
  {
    title: "Position Value",
    field: "Exposure",
    visible: true,
    sortOrder: 9,
    minWidth: 120,
  },
  {
    title: "Position",
    field: "Position",
    visible: true,
    sortOrder: 3,
    minWidth: 120,
  },
  { title: "SEC", field: "Sec", visible: true, sortOrder: 18, minWidth: 120 },
  {
    title: "Shares BOT",
    field: "Long",
    visible: true,
    sortOrder: 11,
    minWidth: 120,
  },
  {
    title: "Shares SLD",
    field: "Short",
    visible: true,
    sortOrder: 12,
    minWidth: 120,
  },
  {
    title: "Shares Traded",
    field: "TotalPosition",
    visible: true,
    sortOrder: 10,
    minWidth: 120,
  },
  {
    title: "Symbol",
    field: "Symbol",
    visible: true,
    sortOrder: 2,
    minWidth: 120,
  },
  { title: "TAF", field: "Taf", visible: true, sortOrder: 19, minWidth: 120 },
  {
    title: "Total PnL",
    field: "TotalPL",
    visible: true,
    sortOrder: 8,
    minWidth: 120,
  },
];
