import apiSvc from "../../services/api-service";
import { getAccountsDestinations } from "./accounts-destinations-action";

export async function loadAccountsDestinations(dispatch: any) {
  apiSvc
    .get("SelectAccountDestinationsRequest")
    .then((response) => dispatch(getAccountsDestinations(response?.data.Data)));
}

export const addAccountDestination = (accountDestinationData) => async (dispatch, getState) => {
  const response = await apiSvc.put("SaveAccountDestinationsRequest", accountDestinationData);

  if (response !== undefined) {
    dispatch(loadAccountsDestinations);
  }
};

export const deleteAccountDestination = (accountDestinationId: number) => async (dispatch: any) => {
  await apiSvc.api
    .delete("DeleteAccountDestinationsRequest", {
      params: {
        PrimaryKey: accountDestinationId,
        LoadCache: true,
      },
    })
    .then(() => dispatch(loadAccountsDestinations));
};
