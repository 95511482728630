import { COUNTRY_GET_COUNTRY } from "./country-types";
import { CountryState } from "./CountryState";

const initialState = new CountryState();

export default function CountryReducer(state = initialState, action: any): CountryState {
  switch (action.type) {
    case COUNTRY_GET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };

    default:
      return state;
  }
}
