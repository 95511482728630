import { createRef, PureComponent } from 'react';

export default class Markets extends PureComponent {
  private _ref: any;
  private _options: any;

  constructor(props) {
    super(props);
    this._ref = createRef();
    this._options = {
      symbols: [{
        proName: "FOREXCOM:SPXUSD",
        title: "S&P 500"
      },
      {
        proName: "FOREXCOM:NSXUSD",
        title: "Nasdaq 100"
      },
      {
        description: "Dow 30",
        proName: "FOREXCOM:DJI"
      }
      ],
      colorTheme: "dark",
      isTransparent: false,
      showSymbolLogo: true,
      locale: "en"
    };
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js'
    script.async = true;
    script.innerHTML = JSON.stringify(this._options);
    this._ref.current.appendChild(script);
  }

  render() {
    return (
      <div className="tradingview-widget-container" ref={this._ref}>
        <div className="tradingview-widget-container__widget"></div>
      </div>
    );
  }
}
