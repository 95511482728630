import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import { CONNECTION_COPY_CONNECTION_STATE, CONNECTION_SET_IS_INTERNET_CONNECTED, CONNECTION_SET_IS_NATS_CONNECTED } from "./connection-types";
import { ConnectionState } from "./ConnectionState";

const initialState = new ConnectionState();

export default function connectionReducer(state = initialState, action): ConnectionState {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return {
        ...state,
        isNatsConnected: false
      };

    case CONNECTION_SET_IS_INTERNET_CONNECTED:
      return {
        ...state,
        isInternetConnected: action.payload
      };

    case CONNECTION_SET_IS_NATS_CONNECTED:
      return {
        ...state,
        isNatsConnected: action.payload
      };

    case CONNECTION_COPY_CONNECTION_STATE:
      return action.payload;

    default:
      return state;
  }
}
