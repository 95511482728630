import IconAccountSummary from "../Images/icon_accountsummary.png";
import IconChart from "../Images/icon_chart.png";
import IconLadderView from "../Images/icon_ladderview.png";
import IconLevelII from "../Images/icon_leveliiview.png";
import IconOrderBlotter from "../Images/icon_orderblotter.png";
import IconPositionBlotter from "../Images/icon_positionblotter.png";
import IconQuickTrade from "../Images/icon_quicktrade.png";
import IconTimeAndSales from "../Images/icon_timeandsales.png";
import IconTopMover from "../Images/icon_topmovers.png";
import IconWatchList from "../Images/icon_watchlist.png";
import { IWidgetConfig } from "../models/IWidgetConfig";
import { WidgetTitles } from "./widget-titles";

export const widgetsConfig: { [key: string]: IWidgetConfig } = {
  "order-blotter": {
    title: WidgetTitles.Orders,
    width: 472,
    height: 440,
    icon: IconOrderBlotter,
    isLinkingEnabled: false,
  },
  "position-blotter": {
    title: WidgetTitles.Positions,
    width: 690,
    height: 275,
    icon: IconPositionBlotter,
    isLinkingEnabled: true,
  },
  "time-and-sales": {
    title: WidgetTitles.TimeAndSales,
    width: 316,
    height: 440,
    icon: IconTimeAndSales,
    isLinkingEnabled: true,
  },
  "watch-list": {
    title: WidgetTitles.WatchList,
    width: 835,
    height: 440,
    icon: IconWatchList,
    isLinkingEnabled: true,
  },
  "level-two-view": {
    title: WidgetTitles.LevelII,
    width: 627,
    height: 440,
    icon: IconLevelII,
    isLinkingEnabled: true,
  },
  "ladder-view": {
    title: WidgetTitles.LadderView,
    width: 706,
    height: 542,
    icon: IconLadderView,
    isLinkingEnabled: true,
  },
  "quick-trade": {
    title: WidgetTitles.QuickTrade,
    width: 345,
    height: 260,
    icon: IconQuickTrade,
    maxWidth: 500,
    maxHeight: 300,
    isLinkingEnabled: true,
    canBeMaximized: false,
  },
  "top-movers": {
    title: WidgetTitles.TopMovers,
    width: 627,
    height: 418,
    icon: IconTopMover,
    isLinkingEnabled: false,
  },
  chart: {
    title: WidgetTitles.Chart,
    width: 627,
    height: 440,
    icon: IconChart,
    isLinkingEnabled: true,
  },
  "account-summary": {
    title: WidgetTitles.AccountInfo,
    width: 260,
    height: 455,
    icon: IconAccountSummary,
    maxWidth: 300,
    isLinkingEnabled: false,
    canBeMaximized: false,
  },
  news: {
    title: WidgetTitles.News,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: true,
  },
  users: {
    title: WidgetTitles.Users,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  accounts: {
    title: WidgetTitles.Accounts,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  groups: {
    title: WidgetTitles.Groups,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  firms: {
    title: WidgetTitles.Firms,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  "account-performance": {
    title: WidgetTitles.AccountPerformance,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  "admin-symbols": {
    title: WidgetTitles.AdminSymbol,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  "group-position": {
    title: WidgetTitles.GroupPositions,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  brokers: {
    title: WidgetTitles.Brokers,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  "routing-stratgites": {
    title: WidgetTitles.RoutingStratgites,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  "user-ads": {
    title: WidgetTitles.Broadcasting,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  destinations: {
    title: WidgetTitles.Destinations,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  settings: {
    title: WidgetTitles.Settings,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  "auto-flatter": {
    title: WidgetTitles.AutoFlatter,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  fees: {
    title: WidgetTitles.Fees,
    width: 600,
    height: 260,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  form: {
    title: WidgetTitles.Form,
    width: 700,
    height: 500,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  report: {
    title: WidgetTitles.Reports,
    width: 450,
    height: 300,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
  audit: {
    title: WidgetTitles.AuditTrail,
    width: 700,
    height: 600,
    icon: IconAccountSummary,
    isLinkingEnabled: false,
  },
};
