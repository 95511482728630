import axios from "axios";
import apiSvc from "../../services/api-service";
import { setSymbolLongName } from "../quote-media/quote-media-actions";
import { IAppState } from '../reducers/IAppState';
import { mostActiveWatchListSymbols$, trade2BWatchListSymbols$ } from "./watch-list-observables";

export function showMostActiveSymbolsList(dispatch: any, getState: () => IAppState) {
  const { auth, qm } = getState();
  const { authInfo } = auth;
  const { sid } = qm;

  if (!authInfo || !sid) {
    return;
  }

  const { WMID } = authInfo.Meta;

  const exchanges = [
    'amx',
    'nsd',
    'nye'
  ];

  const promises: Promise<string[]>[] = [];
  exchanges.forEach(exchange => {

    const request = axios
      .get(`https://app.quotemedia.com/data/getMarketStats.json?webmasterId=${WMID}&statExchange=${exchange}&stat=va&sid=${sid}`)
      .then(response => {
        response.data.results.quote
          .filter(quote => quote.equityinfo)
          .forEach(quote => {
            dispatch(setSymbolLongName(quote.symbolstring, quote.equityinfo.longname));
          });

        return response.data.results.quote.map(x => x.symbolstring);
      });

    promises.push(request);
  });

  let symbols: string[] = [];
  Promise.all(promises).then(lists => {
    lists.forEach(list => symbols = [...symbols, ...list].sort());
    mostActiveWatchListSymbols$.next(symbols);
  });
}

export function showTrader2BWatchList() {
  apiSvc.api
    .get('SelectMarketWatchListRequest')
    .then(response => {
      const symbols = response.data.Data.map(x => x.Symbol);
      trade2BWatchListSymbols$.next(symbols);
    });
}
