import { CellClassParams, ValueGetterParams } from "ag-grid-community";
// import CancelOrderBtn from "./cancelOrderBtn";
import FlatPositionBtn from "./flatPositionButton";
import { formatNumberForKPI } from "../../utils/number";

const orderSideCellClass = (params: CellClassParams) => {
  return params.data.Side?.toLowerCase();
};

const priceValueGetter = (params: ValueGetterParams) => {
  if (params.data.Price === 0) {
    return "MKT";
  }

  return params.data.Price?.toFixed(2);
};

export const ColumnDefinitions = () => {
  return [
    {
      field: "Close",
      cellRendererFramework: FlatPositionBtn,
      width: 38,
      resizable: true,
    },
    {
      field: "AccountName",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["apply", "reset"],
      },
    },
    {
      field: "UserName",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["apply", "reset"],
      },
    },
    {
      field: "Symbol",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["apply", "reset"],
      },
    },
    {
      field: "Position",
      sortable: true,
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value);
      },
      // valueFormatter: numericFormatter,
      // valueGetter: (param) => formatNumberForKPI(param.data.Positions)
      // cellClassRules: this.positionCellClassRules(),
    },
    {
      field: "Last",
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value, true);
      },
      // type: "numericColumn",
      // valueFormatter: numericFormatter,
    },
    {
      field: "AvgPrice",
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value, true);
      },
      // valueFormatter: numericFormatter,
    },
    {
      field: "UnrealPL",
      headerName: "Open PnL ",
      // type: "numericColumn",
      sortable: true,
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value, true);
      },
      // valueFormatter: numericFormatter,
      // cellRendererFramework: spanCellRenderer,
      // cellClass: "text-right",
      // cellClassRules: this.positiveNegativeCellClassRules(),
      // suppressCellFlash: true,
    },
    {
      field: "RealPL",
      headerName: "Close PnL",
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value, true);
      },
      // type: "numericColumn",
      sortable: true,
      // valueFormatter: numericFormatter,
      // cellRendererFramework: spanCellRenderer,
      // cellClass: "text-right",
      // cellClassRules: this.positiveNegativeCellClassRules(),
      // suppressCellFlash: true,
    },
    {
      field: "TotalPL",
      headerName: "Total PnL",
      // type: "numericColumn",
      sortable: true,
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value, true);
      },
      // valueFormatter: numericFormatter,
      // cellRendererFramework: spanCellRenderer,
      // cellClass: "text-right",
      // cellClassRules: this.positiveNegativeCellClassRules(),
      // suppressCellFlash: true,
    },
    {
      field: "Exposure",
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value, true);
      },
      // type: "numericColumn",
      // sortable: true,
      // valueFormatter: numericFormatter,
    },
    {
      field: "TotalPosition",
      headerName: "Volume",
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value);
      },
      // type: "numericColumn",
      // valueFormatter: numericFormatter,
    },
    {
      field: "Long",
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value);
      },
      // type: "numericColumn",
      // valueFormatter: numericFormatter,
    },
    {
      field: "Short",
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value);
      },
      // type: "numericColumn",
      // valueFormatter: numericFormatter,
    },
    {
      field: "LongExposure",
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value, true);
      },
      // type: "numericColumn",
      // valueFormatter: numericFormatter,
    },
    {
      field: "ShortExposure",
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value, true);
      },
      // type: "numericColumn",
      // valueFormatter: numericFormatter,
    },
    {
      field: "Group",
      sortable: true,
    },
    {
      field: "BPUsed",
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value, true);
      },
      // type: "numericColumn",
      // valueFormatter: numericFormatter,
    },
    {
      field: "Currency",
      sortable: true,
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value, true);
      },
    },
    {
      field: "RunUp",
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value, true);
      },
      // type: "numericColumn",
      // valueFormatter: numericFormatter,
    },
    {
      field: "DrawDown",
      valueFormatter: (params) => {
        return formatNumberForKPI(params?.value, true);
      },
      // type: "numericColumn",
      // valueFormatter: numericFormatter,
    },
    {
      field: "CallPut",
      headerName: "C/P",
    },
  ];
};
