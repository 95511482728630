import { CloseOutlined } from "@material-ui/icons";
import { CancelOrder } from "../../models/CancelOrder";
import store from "../../store";
import { cancelNatsOrder } from "../../store/connection/connection-async-actions";
import "./styles.scss";
import { OrderStatusEnum } from "../../enums/OrderStatusEnum";

const CancelOrderBtn = (props: any) => {
  const cancelOrder = (data) => {
    if (data) {
      const order = new CancelOrder();
      // order.accountId = accountId;
      order.accountId = data.AccountId;
      order.symbol = data.Symbol;
      order.side = data.Side;
      order.type = data.OrderType;
      order.destinationId = data.DestId;
      order.originalOrderId = data.ClientOrderId;
      store.dispatch(cancelNatsOrder(order));
    }
  };

  return (
    <>
       {[OrderStatusEnum[OrderStatusEnum.New], OrderStatusEnum[OrderStatusEnum.Sending], OrderStatusEnum[OrderStatusEnum.PartiallyFilled], OrderStatusEnum[OrderStatusEnum.Open]].includes(props.data.Status) && (
          <span className="cancel-order">
            <CloseOutlined onClick={() => cancelOrder(props.data)} />
          </span>
        )}
    </>
  );
};

export default CancelOrderBtn;
