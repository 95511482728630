import apiSvc from "../../services/api-service";
import notificationSvc from "../../services/notification-service";
import { loadFirms } from "../firms/firms-async-actions";
import {
  setGroups,
  setGroupSupervisors,
  setGroupTradeLimits,
} from "./groups-actions";

export async function loadGroups(dispatch: any) {
  dispatch(loadFirms);

  const groupsResponse = await apiSvc.get("SelectGroupsRequest");
  if(groupsResponse && groupsResponse.data && groupsResponse.data.Data)
  {
    dispatch(setGroups(groupsResponse.data.Data));
  }
  const supervisorsResponse = await apiSvc.get("SelectGroupSupervisorsRequest");
  if(supervisorsResponse && supervisorsResponse.data && supervisorsResponse.data.Data)
  {
    dispatch(setGroupSupervisors(supervisorsResponse.data.Data))
  }
  apiSvc.get("SelectGroupTradeLimitsRequest", (res) =>
    dispatch(setGroupTradeLimits(res.data.Data))
  );
  return {groupsResponse: groupsResponse?.data?.Data, supervisorsResponse: supervisorsResponse?.data?.Data};
}

export const addGroup = (groupData) => async (dispatch: any, getState) => {
  const groupRequestData = {
    GroupId: groupData.Data.GroupId,
    Name: groupData.Data.Name,
    HasSupervisorRights: groupData.Data.HasSupervisorRights,
    EnRiskControl: groupData.Data.EnRiskControl,
    FirmId: groupData.Data.FirmId,
    SupervisorId: groupData.Data.SupervisorId,
    CreatedBy: groupData.Data.CreatedBy,
    CreatedOn: groupData.Data.CreatedOn,
    ModifiedBy: groupData.Data.ModifiedBy,
    ModifiedOn: groupData.Data.ModifiedOn,
  };
  const { groups } = getState().groups;
  let isSaveGroup;
  if (groupRequestData.GroupId === undefined) {
    isSaveGroup = groups.some(
      (i) => i.Name.toLowerCase() === groupRequestData.Name.toLowerCase()
    );
  } else {
    const filteredGroups = groups.filter(
      (x) => x.Name.toLowerCase() === groupRequestData.Name.toLowerCase()
    );
    if (filteredGroups.length <= 1) {
      isSaveGroup = false;
    }
  }

  if (isSaveGroup === false) {
    const saveGroupResponse = await apiSvc.put(
      "SaveGroupsRequest",
      groupRequestData
    );

    let groupTradeLimitData = {
      GroupId: saveGroupResponse.data.PrimaryKey,
      EnGroupBP: groupData.Data.GroupTradeLimit.EnGroupBP,
      BuyingPower: groupData.Data.GroupTradeLimit.BuyingPower,
      EnUrlzLinkToBP: groupData.Data.GroupTradeLimit.EnUrlzLinkToBP,
      EnUrlzLinkToMaxLoss: groupData.Data.GroupTradeLimit.EnUrlzLinkToMaxLoss,
      EnGroupMaxLoss: groupData.Data.GroupTradeLimit.EnGroupMaxLoss,
      MaxLoss: groupData.Data.GroupTradeLimit.MaxLoss,
      EnGroupMaxPos: groupData.Data.GroupTradeLimit.EnGroupMaxPos,
      MaxPosition: groupData.Data.GroupTradeLimit.MaxPosition,
      CreatedBy: groupData.Data.GroupTradeLimit.CreatedBy,
      CreatedOn: groupData.Data.GroupTradeLimit.CreatedOn,
      ModifiedBy: groupData.Data.GroupTradeLimit.ModifiedBy,
      ModifiedOn: groupData.Data.GroupTradeLimit.ModifiedOn,
    };
    await apiSvc.put("SaveGroupTradeLimitsRequest", groupTradeLimitData);
    dispatch(loadGroups);
  } else {
    notificationSvc.error("Duplicate Groups Not Allowed!");
    dispatch(loadGroups);
  }
};

export const deleteGroup = (groupKey) => async (dispatch: any) => {
  await apiSvc.api.delete("DeleteGroupTradeLimitsRequest", {
    params: {
      PrimaryKey: groupKey,
      LoadCache: true,
    },
  });

  apiSvc.api
    .delete("DeleteGroupsRequest", {
      params: {
        PrimaryKey: groupKey,
        LoadCache: true,
      },
    })
    .then(() => dispatch(loadGroups));
};
