import { IColumnDefinition } from "../../../models/IColumnDefinition";

export const CheckboxRenderer = ({ value }) => {
    return (
      <input type="checkbox" defaultChecked={value} disabled />
    );
  };

export const configColumnsDefinition: IColumnDefinition[] = [
    {
        title: "Module",
        field: "Owner",
        visible: true,
        sortOrder: 3,
        minWidth: 134,
    },
    {
        title: "Setting",
        field: "SettingName",
        visible: true,
        sortOrder: 3,
        minWidth: 134,
    },
    {
        title: "Value",
        field: "SettingValue",
        visible: true,
        sortOrder: 3,
        minWidth: 134,
    },
    {
        title: "Enabled",
        field: "IsEnabled",
        visible: true,
        cellRendererFramework: CheckboxRenderer,
        sortOrder: 3,
        minWidth: 134,
    }
];
