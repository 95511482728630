export class OrderIdGenerator {
  private static readonly UNUSED_BITS = 1;
  private static readonly USER_ID_BITS = 16;
  private static readonly SEQUENCE_BITS = 6;
  private static readonly CUSTOM_EPOCH = 1420070400000;

  private static readonly maxUserId = Math.pow(2, OrderIdGenerator.USER_ID_BITS) - OrderIdGenerator.UNUSED_BITS;
  private static readonly maxSequence = Math.pow(2, OrderIdGenerator.SEQUENCE_BITS) - OrderIdGenerator.UNUSED_BITS;
  private static lastTimestamp: bigint = BigInt(-1);
  private static sequence: bigint = BigInt(0);
  private userId: bigint;

  constructor(userId: number) {
    if (userId < 0 || userId > OrderIdGenerator.maxUserId) {
      throw new Error(`NodeId must be between 0 and ${OrderIdGenerator.maxUserId}`);
    }

    this.userId = BigInt(userId);
  }

  nextId = (): bigint => {
    let currentTimestamp: bigint = BigInt(OrderIdGenerator.getTimeStamp());

    if (currentTimestamp < OrderIdGenerator.lastTimestamp) {
      throw new Error('Invalid System Clock!');
    }

    if (currentTimestamp === OrderIdGenerator.lastTimestamp) {
      OrderIdGenerator.sequence = (OrderIdGenerator.sequence + BigInt(1)) & BigInt(OrderIdGenerator.maxSequence);

      if (OrderIdGenerator.sequence === BigInt(0)) {
        currentTimestamp = OrderIdGenerator.getNextTimeStamp(currentTimestamp);
      }
    } else {
      OrderIdGenerator.sequence = BigInt(0);
    }

    OrderIdGenerator.lastTimestamp = currentTimestamp;

    return (
      BigInt(OrderIdGenerator.sequence)
      | BigInt(this.userId << BigInt(OrderIdGenerator.SEQUENCE_BITS))
      | (currentTimestamp << BigInt(OrderIdGenerator.USER_ID_BITS + OrderIdGenerator.SEQUENCE_BITS))
    );
  }

  private static getTimeStamp = (): number => {
    return new Date().getTime() - OrderIdGenerator.CUSTOM_EPOCH;
  }

  private static getNextTimeStamp = (currentTimestamp: bigint): bigint => {
    while (currentTimestamp === OrderIdGenerator.lastTimestamp) {
      currentTimestamp = BigInt(OrderIdGenerator.getTimeStamp());
    }

    return currentTimestamp;
  }
}