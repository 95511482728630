import { CURRENCY_GET_CURRENCY } from "./currency-types";
import { CurrencyState } from "./CurrencyState";

const initialState = new CurrencyState();

export default function CurrencyReducer(state = initialState, action: any): CurrencyState {
  switch (action.type) {
    case CURRENCY_GET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };

    default:
      return state;
  }
}
