import { useEffect } from "react";
import FlexView from "react-flexview";
import { connect, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import {
  setAdminDefaultColumns,
  setIsAdmin,
} from "../../store/auth/auth-actions";
import { IAppState } from "../../store/reducers/IAppState";
import Footer from "../footer/footer";
import Header from "../header/header";
import MainView from "../main/main";
import {
  IAdminHomeDispatchProps,
  IAdminHomeMappedProps,
} from "./IAdminHomeProps";
import { connectNats } from "../../store/connection/connection-async-actions";
import store from "../../store";
import { reconnectQuoteMedia } from "../../store/quote-media/quote-media-async-actions";
import tempCache from "../../services/temp-cache";
import { refreshToken } from "../../store/auth/auth-async-actions";

type Props = IAdminHomeMappedProps & IAdminHomeDispatchProps;

const AdminHome = (props: Props) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  let defaultAdminColumnPermissions: any = {};
  const authInfo = store.getState().auth.authInfo;
  useEffect(() => {
    props.seIsAdmin(true);
    if (authInfo && authInfo.UserId) {
      dispatch(connectNats(authInfo.UserId));
      dispatch(reconnectQuoteMedia());
      const interval = setInterval(() => {
        if (!tempCache.username) {
          clearInterval(interval);
          return;
        }

        const date = new Date();
        date.setMinutes(date.getMinutes() - 29);
        if (tempCache.lastLoginAt < date.getTime()) {
          refreshToken();
        }
      }, 5 * 1000);
    }

    defaultAdminColumnPermissions = localStorage.getItem(
      "defaultAdminColumnPermissions" || {}
    );
    let parsedAdminColumnPermissions = JSON.parse(
      defaultAdminColumnPermissions
    );
    if (defaultAdminColumnPermissions !== null) {
      dispatch(setAdminDefaultColumns(parsedAdminColumnPermissions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexView column height="100%">
      {!props.isLoggedIn ?
      <header>
        <Header />
      </header> : <></>}

      {props.isLoggedIn && (
        <FlexView grow>
          <MainView />
        </FlexView>
      )}
      <footer className={authInfo && authInfo.UserId ? "" : "footersection"}>
        <Footer />
      </footer>
    </FlexView>
  );
};

const mapStateToProps = (state: IAppState): IAdminHomeMappedProps => ({
  isLoggedIn: !!state.auth.authInfo,
  userRole: state.auth.authInfo?.Meta.RoleName || "",
});

const mapDispatchToProps = (dispatch: any): IAdminHomeDispatchProps => ({
  seIsAdmin: (isAdmin: boolean) => dispatch(setIsAdmin(isAdmin)),
  setAdminDefaultColumns: (columnPermissions: any) =>
    dispatch(setAdminDefaultColumns(columnPermissions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
