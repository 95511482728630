import apiSvc from "../../services/api-service";
import { setBrokers } from "./broker-actions";

export async function loadBrokersList(dispatch: any) {
  dispatch(setBrokers([]));
  apiSvc.get("SelectBrokersRequest").then((response) => dispatch(setBrokers(response?.data.Data)));
}

export const addBroker = (brokerData) => async (dispatch) => {
  const brokerRes = await apiSvc.put('SaveBrokersRequest', brokerData);

  if (brokerRes !== undefined) {
    dispatch(loadBrokersList);
  }
};

export const deleteBroker = (delKey) => async (dispatch) => {
  const params = {
    PrimaryKey: delKey,
    LoadCache: true,
  };

  const brokerDelRes = await apiSvc.api.delete('DeleteBrokersRequest', { params });
  
  if (brokerDelRes !== undefined) {
    dispatch(loadBrokersList);
  }
};
