import { IColumnDefinition } from "../../models/IColumnDefinition";
import { IKeyboardShortcutSetting } from "../../models/IKeyboardShortcutSetting";
import { IOrderInfoConfirmModal } from "../../models/IOrderInfoConfirmModal";
import { IShortcutCombination } from "../../models/IShortcutCombination";
import { IAppState } from "../reducers/IAppState";
import { GeneralSettings } from "./GeneralSettings";
import { MarketDepthSettings } from "./MarketDepthSetting";
import { NotificationSettings } from "./NotificationSettings";
import {
  SETTINGS_CONFIRMATION_MODAL,
  SETTINGS_COPY_STATE,
  SETTINGS_EXITING_SYSTEM_NOTIFICATION,
  SETTINGS_FULLSCREEN_MODE,
  SETTINGS_GENERAL_LOAD,
  SETTINGS_GENERAL_SET_DECIMAL_PLACES,
  SETTINGS_GENERAL_SET_DEFAULT_ACCOUNT,
  SETTINGS_GENERAL_SET_DEFAULT_DESTINATION,
  SETTINGS_IS_SHORTCUT_COMBINATION_ALLOWED,
  SETTINGS_KEYBOARD_SHORTCUT_SHOW_MODAL,
  SETTINGS_LEVEL_II_SKIN_COLOR,
  SETTINGS_MARKET_DEPTH_LOAD,
  SETTINGS_MODAL_RESPONSE,
  SETTINGS_NEW_ORDER_LIMIT_NOTIFICATION,
  SETTINGS_NEW_ORDER_NOTIFICATION,
  SETTINGS_NOTIFICATIONS_LOAD,
  SETTINGS_ORDER_BLOTTER_COLUMN_UPDATE,
  SETTINGS_ORDER_BLOTTER_LOAD_COLUMNS,
  SETTINGS_ORDER_PRICE_LIMIT_NOTIFICATION,
  SETTINGS_ORDER_REJECTION_NOTIFICATION,
  SETTINGS_POSITION_BLOTTER_COLUMN_UPDATE,
  SETTINGS_RESET_ALL_SETTINGS,
  SETTINGS_SAVE_LAYOUT_NOTIFICATION,
  SETTINGS_SAVE_POINT_NOTIFICATION,
  SETTINGS_SAVE_SHARES_NOTIFICATION,
  SETTINGS_SET_KEYBOARD_SHORTCUT,
  SETTINGS_SET_SELECTED_KEYBOARD_SHORTCUT,
  SETTINGS_SET_SELECTED_SHORTCUT_COMBINATION,
  SETTINGS_SET_SHORTCUT_COMBINATION,
  SETTINGS_SHORTCUT_COMBINATION_INFO_SHOW_MODAL,
  SETTINGS_SHORTCUT_COMBINATION_SHOW_MODAL,
  SETTINGS_SHOW_MODAL,
  SETTINGS_TIME_AND_SALES_COLUMN_UPDATE,
  SETTINGS_TIME_AND_SALES_LOAD_COLUMNS,
  SETTINGS_USER_CLOSED_WIDGET,
  SETTINGS_WATCH_LIST_COLUMN_UPDATE,
  SETTINGS_WATCH_LIST_LOAD_COLUMNS,
  SETTINGS_WIDGET_DISPLAYED,
} from "./settings-types";

export const setDecimalPlaces = (decimalPlaces: number) => (dispatch: any) =>
  dispatch({
    type: SETTINGS_GENERAL_SET_DECIMAL_PLACES,
    payload: decimalPlaces,
  });

export const setDefaultAccount = (accountId: number) => (dispatch: any) =>
  dispatch({ type: SETTINGS_GENERAL_SET_DEFAULT_ACCOUNT, payload: accountId });

export const setDefaultDestination =
  (destinationId: number) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_GENERAL_SET_DEFAULT_DESTINATION,
      payload: destinationId,
    });

export const setSettingModalStatus =
  (isSettingModal: boolean) => (dispatch: any) =>
    dispatch({ type: SETTINGS_SHOW_MODAL, payload: isSettingModal });

export const setSettingKeyboardShortcutModalStatus =
  (isSettingKeyboardShortcutModal: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_KEYBOARD_SHORTCUT_SHOW_MODAL,
      payload: isSettingKeyboardShortcutModal,
    });

export const setSettingShortcutCombinationModalStatus =
  (isSettingShortcutCombinationModal: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_SHORTCUT_COMBINATION_SHOW_MODAL,
      payload: isSettingShortcutCombinationModal,
    });

export const setSettingShortcutCombinationInfoModalStatus =
  (isSettingShortcutCombinationInfoModal: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_SHORTCUT_COMBINATION_INFO_SHOW_MODAL,
      payload: isSettingShortcutCombinationInfoModal,
    });

export const setNewOrderNotification =
  (orderNotification: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_NEW_ORDER_NOTIFICATION,
      payload: orderNotification,
    });

export const setLimitOrderNotification =
  (orderLimitNotification: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_NEW_ORDER_LIMIT_NOTIFICATION,
      payload: orderLimitNotification,
    });

export const setExitingSystemNotification =
  (exitingNotification: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_EXITING_SYSTEM_NOTIFICATION,
      payload: exitingNotification,
    });

export const setOrderRejectionNotification =
  (orderRejectionNotification: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_ORDER_REJECTION_NOTIFICATION,
      payload: orderRejectionNotification,
    });

export const setLimitPriceNotification =
  (orderPriceNotification: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_ORDER_PRICE_LIMIT_NOTIFICATION,
      payload: orderPriceNotification,
    });

export const setSaveLayoutNotification =
  (saveNotification: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_SAVE_LAYOUT_NOTIFICATION,
      payload: saveNotification,
    });

export const setSharesLimit = (orderLimit: number) => (dispatch: any) =>
  dispatch({ type: SETTINGS_SAVE_SHARES_NOTIFICATION, payload: orderLimit });

export const setPointsLimit = (oderPrice: number) => (dispatch: any) =>
  dispatch({ type: SETTINGS_SAVE_POINT_NOTIFICATION, payload: oderPrice });

export const updateOrderBlotterColumn =
  (title: string, visible: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_ORDER_BLOTTER_COLUMN_UPDATE,
      payload: {
        title,
        visible,
      },
    });

export const updatePositionBlotterColumn =
  (title: string, visible: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_POSITION_BLOTTER_COLUMN_UPDATE,
      payload: {
        title,
        visible,
      },
    });

export const updateTimeAndSalesColumn =
  (title: string, visible: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_TIME_AND_SALES_COLUMN_UPDATE,
      payload: {
        title,
        visible,
      },
    });

export const updateWatchListColumn =
  (title: string, visible: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_WATCH_LIST_COLUMN_UPDATE,
      payload: {
        title,
        visible,
      },
    });

export const setKeyboardShortcutSetting =
  (keyboardShortcut: IKeyboardShortcutSetting[]) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_SET_KEYBOARD_SHORTCUT,
      payload: keyboardShortcut,
    });

export const setSelectedKeyboardShortcutSettingToEdit =
  (keyboardShortcutSelectedToEdit: IKeyboardShortcutSetting) =>
  (dispatch: any) =>
    dispatch({
      type: SETTINGS_SET_SELECTED_KEYBOARD_SHORTCUT,
      payload: keyboardShortcutSelectedToEdit,
    });

export const setShortcutCombinationSetting =
  (shortcutCombination: IShortcutCombination[]) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_SET_SHORTCUT_COMBINATION,
      payload: shortcutCombination,
    });

export const setSelectedShortcutCombinationSettingToEdit =
  (shortcutCombinationSelectedToEdit: IShortcutCombination) =>
  (dispatch: any) =>
    dispatch({
      type: SETTINGS_SET_SELECTED_SHORTCUT_COMBINATION,
      payload: shortcutCombinationSelectedToEdit,
    });

export const setSelectedShortcutCombinationSettingToAllowed =
  (isShortcutCombination: boolean) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_IS_SHORTCUT_COMBINATION_ALLOWED,
      payload: isShortcutCombination,
    });

export const updateConfirmationModal =
  (
    windowId: string,
    type: string,
    orderInfo?: IOrderInfoConfirmModal,
    visible?: boolean
  ) =>
  (dispatch: any) => {
    const payload = {
      windowId,
      type,
      orderInfo,
      visible,
    };

    dispatch({ type: SETTINGS_CONFIRMATION_MODAL, payload });
  };

export const setLevelIISkinColor =
  (levelIIColor: any) => (dispatch: any, getState: () => IAppState) => {
    dispatch({
      type: SETTINGS_LEVEL_II_SKIN_COLOR,
      payload: levelIIColor,
      decimalPlaces: getState().settings.general.decimalPlaces,
    });
  };

export const setModalResponse = (isResponse: boolean) => (dispatch: any) =>
  dispatch({ type: SETTINGS_MODAL_RESPONSE, payload: isResponse });

export const setWidgetDisplayed = (isDisplay: boolean) => (dispatch: any) => {
  dispatch({ type: SETTINGS_WIDGET_DISPLAYED, payload: isDisplay });
};

export const setUserClosedWidget = (isClosed: boolean) => (dispatch: any) =>
  dispatch({ type: SETTINGS_USER_CLOSED_WIDGET, payload: isClosed });

export const setFullScreenMode = (isFullScreen: boolean) => (dispatch: any) =>
  dispatch({ type: SETTINGS_FULLSCREEN_MODE, payload: isFullScreen });

export const resetAllSettings = () => (dispatch: any) =>
  dispatch({ type: SETTINGS_RESET_ALL_SETTINGS });

export const copySettingState = (data) => (dispatch: any) => {
  dispatch({
    type: SETTINGS_COPY_STATE,
    payload: data,
  });
};

export const loadGeneralSettings =
  (generalSettings: GeneralSettings) => (dispatch: any) =>
    dispatch({ type: SETTINGS_GENERAL_LOAD, payload: generalSettings });

export const loadMarketDepthSettings =
  (settings: MarketDepthSettings) => (dispatch: any) =>
    dispatch({ type: SETTINGS_MARKET_DEPTH_LOAD, payload: settings });

export const loadNotificationSettings =
  (settings: NotificationSettings) => (dispatch: any) =>
    dispatch({ type: SETTINGS_NOTIFICATIONS_LOAD, payload: settings });

export const loadOrderBlotterColumns =
  (columns: IColumnDefinition[]) => (dispatch: any) =>
    dispatch({ type: SETTINGS_ORDER_BLOTTER_LOAD_COLUMNS, payload: columns });

export const loadPositionBlotterColumns =
  (columns: IColumnDefinition[]) => (dispatch: any) =>
    dispatch({
      type: SETTINGS_POSITION_BLOTTER_COLUMN_UPDATE,
      payload: columns,
    });

export const loadTimeAndSalesColumns =
  (columns: IColumnDefinition[]) => (dispatch: any) =>
    dispatch({ type: SETTINGS_TIME_AND_SALES_LOAD_COLUMNS, payload: columns });

export const loadWatchListColumns =
  (columns: IColumnDefinition[]) => (dispatch: any) =>
    dispatch({ type: SETTINGS_WATCH_LIST_LOAD_COLUMNS, payload: columns });
