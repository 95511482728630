import { toast } from 'react-toastify';
import soundSvc from './sound-service';
import * as sounds from '../sounds';

class NotificationService {
  error = (message: string) => {
    toast.error(message);
    soundSvc.play(sounds.error);
  }

  info = (message: string) => {
    toast.info(message);
  }

  success = (message: string) => {
    toast.success(message);
    soundSvc.play(sounds.success);
  }

  toast = (message: string) => {
    toast.dark(message);
  }

  warning = (message: string) => {
    toast.warning(message);
  }
}

const notificationSvc = new NotificationService();
export default notificationSvc;
