import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import FlexView from "react-flexview";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { OrderTypeEnum } from "../../enums/OrderTypeEnum";
import { IOrderInfoConfirmModal } from "../../models/IOrderInfoConfirmModal";
import soundSvc from "../../services/sound-service";
import * as sounds from "../../sounds";
import { IAppState } from "../../store/reducers/IAppState";
import { setModalResponse, updateConfirmationModal } from "../../store/settings/settings-actions";
import { IConfirmationDispatchProps, IConfirmationMappedProps } from "./IConfirmationProps";

type Props = IConfirmationMappedProps & IConfirmationDispatchProps;

class ConfirmationModal extends Component<Props, {}> {
  componentDidUpdate(prevProps: Props) {
    const { confirmationModal } = this.props;
    if (confirmationModal && confirmationModal.visible && !prevProps.confirmationModal.visible) {
      soundSvc.play(sounds.confirmation);
    }
  }

  render() {
    const { confirmationModal, decimalPlaces } = this.props;

    const modalType =
      confirmationModal.type.split(" ").length > 1 ? confirmationModal.type.split(" ")[0] : confirmationModal.type;

    const classNames = [
      confirmationModal.type === "Sell" && "sell-order",
      confirmationModal.type === "Buy" && "buy-order",
      confirmationModal.type === "Short" && "short-order",
      confirmationModal.type === "Cancel: Buy" && "cancel-buy-order",
      confirmationModal.type === "Cancel: Sell" && "cancel-sell-order",
    ]
      .filter((e) => e)
      .join(" ");

    let estimatedAmount = 0;
    const { orderInfo: order } = confirmationModal;
    if (order && order.price && order.quantity) {
      estimatedAmount = order.price * order.quantity;
    }

    const cancelOrder = order.price
      ? ": " + confirmationModal.orderInfo.quantity + " " + confirmationModal.orderInfo.symbol
      : " " + confirmationModal.orderInfo.symbol;

    const price = (order.type === OrderTypeEnum.Market && "MKT") || order.price?.toFixed(decimalPlaces);

    return (
      <Modal className="confirmation-modal" backdrop="static" size={"sm"} centered keyboard={false}
        show={confirmationModal.visible} onClick={e => e.stopPropagation()}>
        <Modal.Header>
          <FlexView column hAlignContent="center" vAlignContent="center" basis="100%">
            <span>Order Confirmation</span>
          </FlexView>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <FlexView column hAlignContent="center" vAlignContent="center">
            <span>
              <p className={classNames} style={{ display: "inline-block" }}>
                {" "}
                {modalType}
              </p>{" "}
              <p style={{ display: "inline-block" }}>{cancelOrder}</p>
            </span>
            <span>{price && (`Price: ${price}`)}</span>
            <span>
              {estimatedAmount !== 0 && "Estimated Cost: $"}
              {estimatedAmount !== 0 && (
                <NumberFormat
                  value={estimatedAmount || 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={0}
                />
              )}
            </span>
          </FlexView>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <FlexView basis="100%">
            <FlexView basis="50%">
              <Button onClick={() => this.props.cancelConfirmation()}>Cancel</Button>
            </FlexView>
            <FlexView basis="50%" hAlignContent="right">
              <Button onClick={() => this.props.approveConfirmation(confirmationModal.windowId || "")}>Send</Button>
            </FlexView>
          </FlexView>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state: IAppState): IConfirmationMappedProps => ({
  confirmationModal: state.settings.confirmationModal,
  decimalPlaces: state.settings.general.decimalPlaces,
});

const mapDispatchToProps = (dispatch: any): IConfirmationDispatchProps => ({
  approveConfirmation: (windowId) => {
    dispatch(setModalResponse(true));
    dispatch(updateConfirmationModal(windowId, "", {} as IOrderInfoConfirmModal, false));
  },
  cancelConfirmation: () => {
    dispatch(setModalResponse(false));
    dispatch(updateConfirmationModal("", "", {} as IOrderInfoConfirmModal, false));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
