import { USERPERMISSIONS_SET_USERPERMISSIONS } from "./user-permissions-types";
import { UserPermissionsState } from "./UserPermissionState";

const initialState = new UserPermissionsState();

export default function UserPermissionsReducer(state = initialState, action: any): UserPermissionsState {
  switch (action.type) {
    case USERPERMISSIONS_SET_USERPERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      };

    default:
      return state;
  }
}
