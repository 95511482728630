import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowClickedEvent,
} from "ag-grid-community";
import { ChangeEvent, Component } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";
import FlexView from "react-flexview";
import { connect } from "react-redux";
import { Defaults } from "../../constants/defaults";
import { SkinColors } from "../../constants/skin-color";
import { OrderSideEnum } from "../../enums/OrderSideEnum";
import { OrderTifEnum } from "../../enums/OrderTifEnum";
import { OrderTypeEnum } from "../../enums/OrderTypeEnum";
import { TradeTypeEnum } from "../../enums/TradeTypeEnum";
import { CancelOrder } from "../../models/CancelOrder";
import { IAccount } from "../../models/IAccount";
import { IAccountOrder } from "../../models/IAccountOrder";
import { IMarketDepth } from "../../models/IMarketDepth";
import { IOrderInfoConfirmModal } from "../../models/IOrderInfoConfirmModal";
import { NewOrder } from "../../models/NewOrder";
import accountsSvc from "../../services/account-services";
import notificationSvc from "../../services/notification-service";
import tempCache from "../../services/temp-cache";
import { setUserAccounts } from "../../store/account/account-actions";
import {
  cancelNatsOrder,
  placeNatsOrder,
} from "../../store/connection/connection-async-actions";
import store from "../../store/index";
import { openTimeAndSales } from "../../store/level-ii-view/level-ii-actions";
import { LevelIIState } from "../../store/level-ii-view/LevelIIState";
import {
  subscribeQuoteMediaData,
  unsubscribeQuoteMediaData,
} from "../../store/quote-media/quote-media-async-actions";
import { IAppState } from "../../store/reducers/IAppState";
import {
  setModalResponse,
  updateConfirmationModal,
} from "../../store/settings/settings-actions";
import { setWidgetSymbol } from "../../store/widgets/widgets-actions";
import { timeValueGetter } from "../../utils/datetime";
import { numberFormatter } from "../../utils/number";
import NumericText from "../controls/numeric-text";
import Table from "../list/list";
import {
  ILevelIIViewDispatchProps,
  ILevelIIViewProps,
  ILevelIIViewStateProps,
} from "./ILevelIIViewProps";
import { ILevelIIViewState } from "./ILevelIIViewState";
import "./styles.scss";

type Props = ILevelIIViewProps &
  ILevelIIViewStateProps &
  ILevelIIViewDispatchProps;

class LevelTwoView extends Component<Props, ILevelIIViewState> {
  asksGridApi?: GridApi;
  bidsGridApi?: GridApi;
  prevLast?: number;
  gridOptions: GridOptions;
  isDecimalPlacesChanged = false;
  isSkinColorChanged = false;
  pressedKey: string = "";
  prevSkinColor: string = "";
  shortcut;
  private selectedAccount: any = "";

  columnDefs: ColDef[] = [
    {
      headerName: "Market",
      field: "market",
    },
    {
      headerName: "Size",
      field: "size",
    },
    {
      headerName: "Price",
      field: "price",
      valueFormatter: numberFormatter(this.props.decimalPlaces),
    },
    {
      headerName: "Time",
      valueGetter: (params: any) => timeValueGetter(params.data.date),
    },
  ];

  constructor(props: Props) {
    super(props);

    this.gridOptions = {
      columnDefs: this.columnDefs,
      rowClass: "custom-row",
    };

    const { accounts, defaultAccount, defaultDestination } = props;

    const selectedAccountId =
      defaultAccount || (accounts.length > 0 ? accounts[0].AccountId : 0);
    const selectedAccount = accounts.find(
      (x) => x.AccountId === selectedAccountId
    );

    this.state = {
      symbol: "",
      isSymbolSelected: false,
      displayQty: 0,
      windowId: props.windowId || "",
      dispatchValue: store.dispatch,
      orderInfo: {
        symbol: "",
        price: 0,
        quantity: 0,
        destinationId:
          defaultDestination ||
          (selectedAccount?.AccountDestination &&
            selectedAccount?.AccountDestination[0]?.DestinationId) ||
          0,
        accountId: selectedAccountId,
        type: OrderTypeEnum.Market,
        tif: OrderTifEnum.Day,
      },
      rowData: [],
      isValid: true,
      side: 0,
      bidAsk: 0,
      pendingOperation: "",
      rowClassRules: this.getRowClassRules(),
      accountsData: [],
    };
  }

  get isDayOrder() {
    return (
      !!this.state.orderInfo && this.state.orderInfo.tif === OrderTifEnum.Day
    );
  }

  get position() {
    if (!this.state.isSymbolSelected) {
      return 0;
    }

    const symbolPosition = this.props.positions.find(
      (x) => x.Symbol === this.state.symbol
    );
    return symbolPosition?.Position || 0;
  }

  get priceData() {
    return this.state.isSymbolSelected && this.props.priceData
      ? this.props.priceData[this.state.symbol]
      : null;
  }

  get quoteData() {
    return this.state.isSymbolSelected && this.props.levelIIStats
      ? this.props.levelIIStats[this.state.symbol]
      : null;
  }

  componentDidMount() {
    const { userAccounts } = this.props;
    store.dispatch(setUserAccounts(userAccounts));

    this.loadInitialSymbol();

    document.addEventListener("keydown", (e) => {
      this.keydownHandler(e);
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { orderInfo } = this.state;
    const { accountData: accountsData } = this.props;
    if (orderInfo.accountId === 0 && accountsData.length > 0) {
      this.setState({
        orderInfo: {
          ...orderInfo,
          accountId: accountsData[0].account.AccountId,
          destinationId: accountsData[0].account.AccountDestination
            ? accountsData[0].account.AccountDestination[0].DestinationId
            : 0,
        },
      });
    }
    // // this.handleDestinationUpdate(prevProps.destinations);
    this.handleAccountUpdate(prevProps.accounts);
    this.handleSymbolUpdate(prevProps.symbol);
    this.handleConfirmationModelResponseUpdate(prevProps.isResponse);
    this.handleSkinColorUpdate(prevProps.skinColor);
    this.handleDecimalPlacesUpdate(prevProps.decimalPlaces);
  }

  componentWillUnmount() {
    this.props.unsubscribeQuoteMediaData();
    document.removeEventListener("keydown", (e) => {
      this.keydownHandler(e);
    });
  }

  render() {
    const { accountData: accountsData } = this.props;
    const allAccounts: any = accountsData ? accountsData.map((x) => x.account) : [];
    const { decimalPlaces, marketDepths, skinColor } = this.props;
    const { displayQty, isSymbolSelected, orderInfo, rowClassRules, symbol } =
      this.state;
    this.selectedAccount = allAccounts.find(
      (x) => x.AccountId === orderInfo.accountId
    );

    const destinations = this.selectedAccount?.AccountDestination || [];

    const change = this.priceData?.change || 0;
    const changeClassName =
      change > 0
        ? "price-positive"
        : change < 0
        ? "price-negative"
        : "price-equal";
    const lastPriceIndicator = this.getLastValueIndicator();

    const asks =
      isSymbolSelected &&
      !this.isSkinColorChanged &&
      !this.isDecimalPlacesChanged
        ? marketDepths.filter(
            (x) => x.symbol === symbol && x.tradeType === TradeTypeEnum.Ask
          )
        : [];

    const bids =
      isSymbolSelected && this.prevSkinColor === skinColor
        ? marketDepths.filter(
            (x) => x.symbol === symbol && x.tradeType === TradeTypeEnum.Bid
          )
        : [];

    return (
      <FlexView column className="level-II-view" grow height="100%">
        <FlexView>
          <FlexView basis="16.5%" vAlignContent="top" hAlignContent="left" grow>
            <Form.Group className="full-width-form m-0">
              <Form.Label className="mb-0">Symbol</Form.Label>
              <Form.Control
                className="symbol"
                onKeyPress={this.symbol_KeyPress}
                value={symbol}
                onChange={this.symbol_Change}
                type="text"
              />
              <Form.Label className="mb-0 mt-2">Account</Form.Label>
              <Form.Control
                as="select"
                value={orderInfo.accountId}
                onChange={(e) => {
                  this.setState({
                    orderInfo: {
                      ...orderInfo,
                      accountId: parseInt(e.target.value),
                    },
                  });
                }}
              >
                {allAccounts.map((data: any, index) => (
                  <option key={index} value={data.AccountId}>
                    {data.Name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </FlexView>
          <FlexView
            style={{
              border: "1px solid #707070",
              color: "#FFFFFF",
              padding: "5px 0",
            }}
            basis="83%"
            vAlignContent="top"
            hAlignContent="left"
            marginLeft="0.5%"
            grow
          >
            <FlexView
              style={{ fontSize: "12px" }}
              basis="23%"
              vAlignContent="top"
              hAlignContent="center"
              column
            >
              <span>Last </span>
              <span className={`last-value ${this.priceData?.rowClass}`}>
                {(this.priceData?.last || 0).toFixed(decimalPlaces)}
                {lastPriceIndicator}
              </span>
              <span>Chg</span>
              <span className={changeClassName}>
                {(this.priceData?.change || 0).toFixed(decimalPlaces)}
              </span>
            </FlexView>
            <FlexView
              style={{ fontSize: "12px" }}
              basis="23%"
              vAlignContent="top"
              hAlignContent="center"
              column
            >
              <span>Chg%</span>
              <span className={changeClassName}>
                {(this.priceData?.percentChange || 0).toFixed(decimalPlaces)}
              </span>
              <span style={{ marginTop: "7px" }}>Bid/Ask</span>
              <span>
                {(this.quoteData?.bidPrice || 0).toFixed(decimalPlaces)}/
                {(this.quoteData?.askPrice || 0).toFixed(decimalPlaces)}
              </span>
            </FlexView>
            <FlexView
              style={{ fontSize: "12px" }}
              basis="20%"
              vAlignContent="top"
              hAlignContent="center"
              column
            >
              <span>Vol</span>
              <span>
                <NumericText value={this.priceData?.accumulatedVolume || 0} />
              </span>
              <span style={{ marginTop: "7px" }}>Size</span>
              <span>
                {(this.quoteData?.bidSize || 0) * 100}/
                {(this.quoteData?.askSize || 0) * 100}
              </span>
            </FlexView>
            <FlexView
              style={{ fontSize: "12px" }}
              basis="34%"
              vAlignContent="top"
              hAlignContent="center"
              column
            >
              <span>High/Low</span>
              <span>
                {(this.priceData?.high || 0).toFixed(decimalPlaces)}/
                {(this.priceData?.low || 0).toFixed(decimalPlaces)}
              </span>
              <span style={{ marginTop: "7px" }}>VWAP</span>
              <span>{(this.priceData?.vwap || 0).toFixed(decimalPlaces)}</span>
            </FlexView>
          </FlexView>
        </FlexView>
        <FlexView className="row-2" height={50}>
          <Form.Group className="full-width-form mt-1">
            <Form.Label>Destination</Form.Label>
            <Form.Control
              as="select"
              className="destinations-combo"
              value={orderInfo.destinationId}
              onChange={this.setDestinationId}
            >
              {destinations.map((data, index) => (
                <option key={index} value={data.DestinationId}>
                  {data.DisplayName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mt-1">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              min={100}
              max={10000}
              step={100}
              value={orderInfo.quantity && Math.max(0, orderInfo.quantity)}
              onChange={this.setQuantity}
            />
          </Form.Group>

          <Form.Group className="mt-1">
            <Form.Label>Price</Form.Label>
            <Form.Control
              disabled={this.enabledPrice()}
              type="number"
              placeholder="Price"
              step={0.01}
              value={orderInfo.price && Math.max(0, orderInfo.price)}
              onChange={this.setPrice}
            />
          </Form.Group>
          <Form.Group className="mt-1">
            <Form.Label>Order Type</Form.Label>
            <Form.Control
              as="select"
              value={orderInfo.type}
              onChange={this.setOrderType}
            >
              <option value={OrderTypeEnum.Market}>Market</option>
              <option value={OrderTypeEnum.Limit}>Limit</option>
              <option value={OrderTypeEnum.Stop}>Stop</option>
              <option value={OrderTypeEnum.MarketClose}>MarketClose</option>
              <option value={OrderTypeEnum.TP}>TP</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mt-1">
            <Form.Label>Display Qty</Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              value={displayQty && Math.max(0, displayQty)}
              onChange={(e) => {
                this.setState({
                  displayQty: Math.min(
                    orderInfo.quantity,
                    parseInt(e.target.value)
                  ),
                });
              }}
            />
          </Form.Group>
        </FlexView>
        <FlexView marginBottom="5px">
          <FlexView basis="50%" grow vAlignContent="center">
            <Button
              disabled={!this.state.isSymbolSelected}
              className={
                this.state.isSymbolSelected
                  ? "btn btn-time-and-sales"
                  : "disabledBtn btn btn-time-and-sales"
              }
              variant={this.state.isSymbolSelected ? "primary" : "secondary"}
              onDoubleClick={this.btnOpenTimeAndSales}
              title="Double Click to Show"
            >
              Trades
            </Button>

            <Button
              disabled={!this.position}
              className={
                this.position
                  ? "btn btn-flat-position"
                  : "disabledBtn btn btn-flat-position"
              }
              variant={this.position ? "primary" : "secondary"}
              title="Double Click to Trade"
              onDoubleClick={this.flatPosition_Click}
            >
              {this.position !== 0 && (
                <span
                  className={
                    this.position > 0 ? "long-position" : "short-position"
                  }
                >
                  {Math.abs(this.position)} {this.position > 0 ? "L" : "S"}
                  &nbsp;&nbsp;&nbsp;
                </span>
              )}
              Flat Position
            </Button>
          </FlexView>
          <FlexView
            className="row-2"
            basis="50%"
            vAlignContent="center"
            grow
            marginTop="3px"
          >
            <Form.Group>
              <Form.Label>TIF</Form.Label>
              <Form.Control
                as="select"
                value={orderInfo.tif}
                onChange={this.tif_Change}
              >
                <option value={OrderTifEnum.Day}>Day</option>
                <option value={OrderTifEnum.OCO}>OCO</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Take Profit</Form.Label>
              <Form.Control
                type="number"
                value={orderInfo.takeProfit}
                onChange={(e) =>
                  this.setState({
                    orderInfo: {
                      ...orderInfo,
                      takeProfit: parseInt(e.target.value),
                    },
                  })
                }
                disabled={this.isDayOrder}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Stop Loss</Form.Label>
              <Form.Control
                type="number"
                value={orderInfo.stopLoss}
                onChange={(e) =>
                  this.setState({
                    orderInfo: {
                      ...orderInfo,
                      stopLoss: parseInt(e.target.value),
                    },
                  })
                }
                disabled={this.isDayOrder}
              />
            </Form.Group>
          </FlexView>
        </FlexView>
        <FlexView marginTop="3px">
          <FlexView basis="50%" grow>
            <Button
              disabled={!this.enableNewOrder()}
              className={
                this.enableNewOrder()
                  ? "btn buy-custom-btn"
                  : "disabledBtn btn buy-custom-btn"
              }
              variant={this.enableNewOrder() ? "primary" : "secondary"}
              title="Double Click to Trade"
              onDoubleClick={this.buy_Click}
            >
              Buy
            </Button>
            <Button
              disabled={!this.enableBidAskOrder()}
              className={
                this.enableBidAskOrder()
                  ? "btn buy-custom-btn"
                  : "disabledBtn btn buy-custom-btn"
              }
              variant={this.enableBidAskOrder() ? "primary" : "secondary"}
              title="Double Click to Trade"
              onDoubleClick={this.buyAtBid_Click}
            >
              Buy @ Bid
            </Button>
            <Button
              disabled={!this.enableBidAskOrder()}
              className={
                this.enableBidAskOrder()
                  ? "btn buy-custom-btn"
                  : "disabledBtn btn buy-custom-btn"
              }
              variant={this.enableBidAskOrder() ? "primary" : "secondary"}
              title="Double Click to Trade"
              onDoubleClick={this.buyAtAsk_Click}
            >
              Buy @ Ask
            </Button>
          </FlexView>
          <FlexView basis="50%">
            <Button
              disabled={!this.enableNewOrder()}
              className={
                this.enableNewOrder()
                  ? "btn sell-custom-btn"
                  : "disabledBtn btn sell-custom-btn"
              }
              variant={this.enableNewOrder() ? "primary" : "secondary"}
              title="Double Click to Trade"
              onDoubleClick={this.sell_Click}
            >
              Sell
            </Button>
            <Button
              disabled={!this.enableBidAskOrder()}
              className={
                this.enableBidAskOrder()
                  ? "btn sell-custom-btn"
                  : "disabledBtn btn sell-custom-btn"
              }
              variant={this.enableBidAskOrder() ? "primary" : "secondary"}
              title="Double Click to Trade"
              onDoubleClick={this.sellAtAsk_Click}
            >
              Sell @ Ask
            </Button>
            <Button
              disabled={!this.enableBidAskOrder()}
              className={
                this.enableBidAskOrder()
                  ? "btn sell-custom-btn"
                  : "disabledBtn btn sell-custom-btn"
              }
              variant={this.enableBidAskOrder() ? "primary" : "secondary"}
              title="Double Click to Trade"
              onDoubleClick={this.sellAtBid_Click}
            >
              Sell @ Bid
            </Button>
          </FlexView>
        </FlexView>
        <FlexView height={30}>
          <FlexView basis="50%" vAlignContent="center" hAlignContent="left">
            <Button
              disabled={!this.enableCancelOrder()}
              className={
                this.enableCancelOrder()
                  ? "cancel-buy-custom-btn"
                  : "disabledBtn cancel-buy-custom-btn"
              }
              variant={this.enableCancelOrder() ? "primary" : "secondary"}
              title="Double Click to Trade"
              onDoubleClick={this.cancelBuy_Click}
            >
              Cancel Buy
            </Button>
            <Button
              disabled={!this.enableCancelOrder()}
              className={
                this.enableCancelOrder()
                  ? "cancel-buy-custom-btn"
                  : "disabledBtn cancel-buy-custom-btn"
              }
              variant={this.enableCancelOrder() ? "primary" : "secondary"}
              title="Double Click to Trade"
              onDoubleClick={this.cancelSellShort_Click}
            >
              Cancel Sell/Short
            </Button>
          </FlexView>
          <FlexView basis="50%" vAlignContent="center" hAlignContent="left">
            <Button
              disabled={!this.enableNewOrder()}
              className={`short-custom-btn ${
                !this.enableNewOrder() && "disabledBtn"
              }`}
              variant={this.enableNewOrder() ? "primary" : "secondary"}
              title="Double Click to Trade"
              onDoubleClick={this.short_Click}
            >
              Short
            </Button>
            <Button
              disabled={!this.enableBidAskOrder()}
              className={`short-custom-btn ${
                !this.enableBidAskOrder() && "disabledBtn"
              }`}
              variant={this.enableBidAskOrder() ? "primary" : "secondary"}
              title="Double Click to Trade"
              onDoubleClick={this.shortAtAsk_Click}
            >
              Short @ Ask
            </Button>
            <Button
              disabled={!this.enableBidAskOrder()}
              className={`short-custom-btn ${
                !this.enableBidAskOrder() && "disabledBtn"
              }`}
              variant={this.enableBidAskOrder() ? "primary" : "secondary"}
              title="Double Click to Trade"
              onDoubleClick={this.shortAtBid_Click}
            >
              Short @ Bid
            </Button>
          </FlexView>
        </FlexView>
        <FlexView marginTop="3px" grow height="40%">
          <FlexView
            basis="49.5%"
            vAlignContent="top"
            hAlignContent="left"
            marginBottom="7px"
            height="100%"
            grow
          >
            <Table
              rowData={bids}
              gridOptions={{ ...this.gridOptions }}
              rowClassRules={rowClassRules}
              getRowClass={(params) => params.data.rowClass}
              onGridReady={this.bidsGrid_Ready}
              onRowClicked={this.grid_RowClicked}
            />
          </FlexView>
          <FlexView basis="1%"></FlexView>
          <FlexView
            basis="49.5%"
            vAlignContent="top"
            hAlignContent="left"
            marginBottom="7px"
            height="100%"
            grow
          >
            <Table
              rowData={asks}
              gridOptions={{ ...this.gridOptions }}
              rowClassRules={rowClassRules}
              getRowClass={(params) => params.data.rowClass}
              onGridReady={this.asksGrid_Ready}
              onRowClicked={this.grid_RowClicked}
            />
          </FlexView>
        </FlexView>
      </FlexView>
    );
  }

  keydownHandler = (e) => {
    const { isCombinationShortcutListAllowed, combinationShortcutList } =
      this.props;
    if (this.pressedKey && !this.pressedKey.includes("+")) {
      this.pressedKey += " + " + e.key;
    } else {
      this.pressedKey = e.key;
    }

    if (
      isCombinationShortcutListAllowed &&
      combinationShortcutList.length > 0
    ) {
      this.shortcut = combinationShortcutList.find(
        (x) => x.keyboard === this.pressedKey
      );
      if (this.shortcut) {
        this.shortcut.details.forEach((element) => {
          this.placeOrderWithShortcutKey(element);
        });
      } else {
        this.placeOrderWithShortcutKey(this.pressedKey);
      }
    } else {
      this.placeOrderWithShortcutKey(this.pressedKey);
    }
  };

  asksGrid_Ready = (ev: GridReadyEvent) => {
    this.asksGridApi = ev.api;
  };

  bidsGrid_Ready = (ev: GridReadyEvent) => {
    this.bidsGridApi = ev.api;
  };

  canPlaceOrder(symbol: string) {
    return (
      this.props.userSymbols.indexOf("*") !== -1 ||
      this.props.userSymbols.indexOf(symbol) !== -1
    );
  }

  enableNewOrder = (): boolean => {
    const { price, type } = this.state.orderInfo;

    return (
      this.enableBidAskOrder() &&
      ((type === OrderTypeEnum.Market && price === 0) ||
        (type !== OrderTypeEnum.Market && price !== 0))
    );
  };

  enableBidAskOrder = (): boolean => {
    const { displayQty, orderInfo, symbol } = this.state;
    const { accountId, destinationId, quantity, tif } = orderInfo;

    return (
      accountId !== 0 &&
      destinationId !== 0 &&
      displayQty !== 0 &&
      quantity !== 0 &&
      symbol !== "" &&
      tif !== OrderTifEnum.None
    );
  };

  enableCancelOrder = (): boolean => {
    const { orderInfo, symbol } = this.state;
    const { accountId, destinationId } = orderInfo;

    return accountId !== 0 && destinationId !== 0 && symbol !== "";
  };

  enabledPrice() {
    const { orderInfo } = this.state;
    const { type } = orderInfo;

    return (
      type === OrderTypeEnum.Market ||
      type === OrderTypeEnum.MarketClose ||
      type === OrderTypeEnum.Stop ||
      type === OrderTypeEnum.TP
    );
  }

  getLastValueIndicator = () => {
    switch (this.priceData?.rowClass) {
      case "price-positive":
        return <FontAwesomeIcon icon={["fas", "arrow-up"]} className="ml-1" />;

      case "price-negative":
        return (
          <FontAwesomeIcon icon={["fas", "arrow-down"]} className="ml-1" />
        );

      default:
        return <span style={{ display: "inline-block", width: 18 }}></span>;
    }
  };

  getRowClassRules = () => ({
    "blue-background": () => this.props.skinColor === SkinColors.DefaultBlueBg,
    "blue-foreground": () => this.props.skinColor === SkinColors.DefaultBlueFg,
    "yellow-background": () =>
      this.props.skinColor === SkinColors.DefaultYellowBg,
    "yellow-foreground": () =>
      this.props.skinColor === SkinColors.DefaultYellowFg,
  });

  grid_RowClicked = (ev: RowClickedEvent) => {
    const marketDepth: IMarketDepth = ev.data;

    this.setState({
      orderInfo: {
        ...this.state.orderInfo,
        type: OrderTypeEnum.Limit,
        price: marketDepth.price,
        quantity: marketDepth.size,
      },
    });
  };

  handleAccountUpdate = (prevAccounts: IAccount[]) => {
    const { accounts } = this.props;
    const { orderInfo } = this.state;

    if (
      accounts.length > 0 &&
      JSON.stringify(prevAccounts) !== JSON.stringify(accounts)
    ) {
      const account = accounts[0];

      this.setState({
        orderInfo: {
          ...orderInfo,
          accountId: account.AccountId,
          destinationId:
            (account.AccountDestination &&
              account.AccountDestination[0]?.DestinationId) ||
            0,
        },
      });
    }
  };

  handleConfirmationModelResponseUpdate = (prevModalResponse: boolean) => {
    const { confirmationModal, hideConfirmation, isResponse, windowId } =
      this.props;
    const { bidAsk, pendingOperation, side } = this.state;

    if (prevModalResponse !== isResponse) {
      if (isResponse === true && windowId === confirmationModal.windowId) {
        switch (pendingOperation) {
          case "flatPosition":
            this.flatPosition();
            this.setState({ pendingOperation: "" });
            break;

          case "cancelBuy":
            this.cancelOrder(OrderSideEnum.Buy);
            this.setState({ pendingOperation: "" });
            break;

          case "cancelSellShort":
            this.cancelOrder(OrderSideEnum.Sell);
            this.setState({ pendingOperation: "" });
            break;

          default:
            this.placeOrder(side, bidAsk);
        }
      }

      hideConfirmation();
    }
  };

  handleDecimalPlacesUpdate = (prevDecimalPlaces: number) => {
    const { decimalPlaces } = this.props;

    if (decimalPlaces === prevDecimalPlaces) {
      this.isDecimalPlacesChanged = false;
      return;
    }

    this.isDecimalPlacesChanged = true;
    this.handleDecimalPlacesInAsksGrid();
    this.handleDecimalPlacesInBidsGrid();
  };

  handleDecimalPlacesInAsksGrid = () => {
    if (!this.asksGridApi) {
      return;
    }

    const { decimalPlaces } = this.props;
    const columnDef = this.columnDefs.find(
      (x) => x.field === "price"
    ) as ColDef;
    columnDef.valueFormatter = numberFormatter(decimalPlaces);
    this.asksGridApi.setColumnDefs(this.columnDefs);
  };

  handleDecimalPlacesInBidsGrid = () => {
    if (!this.bidsGridApi) {
      return;
    }

    const { decimalPlaces } = this.props;
    const columnDef = this.columnDefs.find(
      (x) => x.field === "price"
    ) as ColDef;
    columnDef.valueFormatter = numberFormatter(decimalPlaces);
    this.bidsGridApi.setColumnDefs(this.columnDefs);
  };

  // // handleDestinationUpdate = (prevDestinations: IAccountDestination[]) => {
  // //   const { destinations } = this.props;
  // //   const { orderInfo } = this.state;

  // //   if (destinations && prevDestinations !== destinations) {
  // //     const destinationValue: any = destinations[0];
  // //     this.setState({
  // //       orderInfo: {
  // //         ...orderInfo,
  // //         destinationId: destinationValue.DestinationId,
  // //       },
  // //     });
  // //   }
  // // }

  handleSkinColorUpdate = (prevSkinColor: string) => {
    this.isSkinColorChanged = false;
    this.prevSkinColor = prevSkinColor;

    if (prevSkinColor !== this.props.skinColor) {
      this.setState({ rowClassRules: this.getRowClassRules() });
      this.isSkinColorChanged = true;
    }
  };

  handleSymbolUpdate = (prevSymbol: string) => {
    const { symbol } = this.props;

    if (symbol !== prevSymbol) {
      if (symbol) {
        this.selectSymbol(symbol);
      }
    }
  };

  isValidOrder = (): boolean => {
    const { orderInfo, symbol } = this.state;
    const { accountId, destinationId, price, quantity, tif, type } = orderInfo;

    return (
      !!symbol &&
      quantity !== 0 &&
      tif !== 0 &&
      accountId !== 0 &&
      destinationId !== 0 &&
      type !== OrderTypeEnum.None &&
      (price !== 0 || type === OrderTypeEnum.Market)
    );
  };

  loadInitialSymbol() {
    const { setWidgetSymbol, symbol } = this.props;

    if (symbol) {
      this.selectSymbol(symbol);
    } else {
      const interval = setInterval(() => {
        const { isDataLoaded } = tempCache;
        if (isDataLoaded) {
          const { widgetSymbols, windowId } = this.props;
          if (!(windowId in widgetSymbols)) {
            setWidgetSymbol(Defaults.symbol);
          }

          clearInterval(interval);
        }
      }, 100);
    }
  }

  placeOrderWithShortcutKey(pressedKey) {
    const {
      keyboardShortcutData,
      positions,
      setWidgetSymbol,
      subscribeQuoteMediaData,
    } = this.props;
    const { orderInfo } = this.state;
    this.shortcut = keyboardShortcutData.find((x) => x.shortcut === pressedKey);
    if (this.shortcut) {
      this.pressedKey = "";
      this.setState({
        isSymbolSelected: true,
      });

      subscribeQuoteMediaData(this.shortcut.symbol || "");
      setWidgetSymbol(this.shortcut.symbol || "");

      let price = 0;
      switch (this.shortcut.priceType) {
        case "Displayed":
          price = orderInfo.price + this.shortcut.price;
          break;

        case "Last":
          price = this.priceData
            ? this.priceData.last + this.shortcut.price
            : 0;
          break;

        case "Bid":
          price = this.quoteData
            ? this.quoteData.bidPrice + this.shortcut.price
            : 0;
          break;

        case "Ask":
          price = this.quoteData
            ? this.quoteData.askPrice + this.shortcut.price
            : 0;
          break;

        case "MKT":
          price = 0;
          break;
      }

      let quantity = 0;
      switch (this.shortcut.quantityType) {
        case "Typed":
          quantity = this.shortcut.quantity;
          break;

        case "Displayed":
          quantity = orderInfo.quantity;
          break;

        case "Position":
          const positionValue: any = positions.find(
            (x) => x["Symbol"] === this.shortcut.symbol
          );
          if (positionValue) {
            quantity = positionValue.Position;
          } else {
            quantity = 0;
          }
          break;
      }

      if (this.shortcut.action === "Enter") {
        this.setState({
          symbol: this.shortcut.symbol || "",
          displayQty: this.shortcut.quantity || 0,
          orderInfo: {
            symbol: this.shortcut.symbol || "",
            price: price,
            quantity: quantity,
            destinationId: this.shortcut.destinationId || 0,
            accountId: this.shortcut.accountId || 0,
            type:
              this.shortcut.priceType === "MKT"
                ? OrderTypeEnum.Market
                : OrderTypeEnum.Limit,
            tif: this.shortcut.orderType,
          },
        });
      } else {
        if (!this.canPlaceOrder(this.shortcut.symbol)) {
          this.showNotAllowedMessage(this.shortcut.symbol);
        } else {
          let len = this.shortcut.repeat || 1;

          for (var i = 0; i < len; i++) {
            const order = new NewOrder();

            order.symbol = this.shortcut.symbol || "";
            order.side = this.shortcut.side;
            order.price = price;
            order.quantity = quantity;
            order.destinationId = this.shortcut.destinationId || 0;
            order.accountId = this.shortcut.accountId || orderInfo.accountId;
            order.type =
              this.shortcut.priceType === "MKT"
                ? OrderTypeEnum.Market
                : OrderTypeEnum.Limit;
            order.tif = this.shortcut.orderType;

            store.dispatch(placeNatsOrder(order));
          }
        }
      }
    }
  }

  showNotAllowedMessage(symbol: string) {
    notificationSvc.error(symbol + ": Symbol is not allowed for trading!");
  }

  overlayEffect = () => {
    return (
      <Tooltip style={{ position: "absolute" }} id="button-tooltip">
        Simple tooltip
      </Tooltip>
    );
  };

  placeOrder(side: OrderSideEnum, bidAsk: number | undefined) {
    const { orderInfo } = this.state;
    const { destinationId, price, quantity, tif, type } = orderInfo;

    console.log(
      "placeOrder",
      "symbol : ",
      this.state.symbol + "quantity: ",
      quantity + "TIF : ",
      tif + "Destination  : ",
      destinationId + "order Info type : ",
      type
    );

    if (!this.isValidOrder()) {
      notificationSvc.error("Required fields are missing for New order!");
    }
    //  else if (!this.canPlaceOrder(orderInfo.symbol)) {
    //   this.showNotAllowedMessage(orderInfo.symbol);           removed symbol checked from Admin panel while placing new Order
    // }
    else {
      const order = new NewOrder();

      order.accountId = orderInfo.accountId;
      order.symbol = this.state.symbol;
      order.side = side;
      order.price = bidAsk || price;
      order.quantity = quantity;
      order.destinationId = destinationId;
      order.type = type;
      order.tif = tif;

      store.dispatch(placeNatsOrder(order));
    }
  }

  cancelOrder(side: OrderSideEnum) {
    if (this.props.userOrders?.length === 0) {
      notificationSvc.error("Please Wait!");
    } else {
      const array = this.props.userOrders;
      const orderArray = array?.filter(
        (data: any) =>
          data.Symbol === this.state.symbol &&
          (data.Status === "New" || data.Status === "PartiallyFilled") &&
          ((side === OrderSideEnum.Buy && data.Side === OrderSideEnum.Buy) ||
            data.Side === OrderSideEnum.Sell ||
            data.Side === OrderSideEnum.Short)
      );

      orderArray?.forEach((data: IAccountOrder) => {
        const cancelOrder = new CancelOrder();
        cancelOrder.accountId = this.state.orderInfo.accountId;
        cancelOrder.symbol = data.Symbol;
        cancelOrder.side = OrderSideEnum[data.Side];
        cancelOrder.type = OrderTypeEnum[data.OrderType];
        cancelOrder.destinationId = data.DestId;
        cancelOrder.originalOrderId = data.ClientOrderId;

        store.dispatch(cancelNatsOrder(cancelOrder));
      });
    }
  }

  symbol_Change = (e) => {
    const symbol = e.target.value.toUpperCase();

    this.setState({
      symbol,
      isSymbolSelected: false,
    });
  };

  symbol_KeyPress = async (e: any) => {
    if (e.key === "Enter") {
      if (!e.target.value) {
        notificationSvc.error("Enter a Symbol to start trading!");
      } else {
        this.selectSymbol(e.target.value.toUpperCase());
      }
    }
  };

  selectSymbol = (symbol: string) => {
    this.prevLast = 0;
    if (this.state.symbol) {
      this.props.unsubscribeQuoteMediaData();
    }

    this.setState({
      symbol,
      isSymbolSelected: true,
      orderInfo: {
        ...this.state.orderInfo,
        symbol,
      },
    });

    this.props.subscribeQuoteMediaData(symbol);
    this.props.setWidgetSymbol(symbol);
  };

  tif_Change = (e: ChangeEvent<HTMLSelectElement>) => {
    const tif = parseInt(e.target.value);
    let { stopLoss, takeProfit } = this.state.orderInfo;

    if (tif === OrderTifEnum.Day) {
      stopLoss = takeProfit = 0;
    }

    this.setState({
      orderInfo: {
        ...this.state.orderInfo,
        tif,
        stopLoss,
        takeProfit,
      },
    });
  };

  btnOpenTimeAndSales = () => {
    if (this.state.symbol === "") {
      notificationSvc.error("Provide Symbol to Proceed!");
    } else {
      const data = new LevelIIState();
      data.openTimeAndSales = true;
      data.symbol = this.state.symbol;
      store.dispatch(openTimeAndSales(data));
    }
  };

  flatPosition = () => {
    const { positions } = this.props;
    const { orderInfo, symbol } = this.state;
    const { destinationId } = orderInfo;

    const symPos = positions.find((data) => data.Symbol === symbol);
    if (!symPos) {
      return;
    }

    const order = new NewOrder();

    order.symbol = symbol;
    order.accountId = orderInfo.accountId;
    order.side = symPos.Position > 0 ? OrderSideEnum.Sell : OrderSideEnum.Buy;
    order.quantity = Math.abs(symPos.Position);
    order.type = OrderTypeEnum.Market;
    order.destinationId = destinationId;

    store.dispatch(placeNatsOrder(order));
  };

  setDestinationId = (e) => {
    this.setState({
      orderInfo: {
        ...this.state.orderInfo,
        destinationId: parseInt(e.target.value),
      },
    });
  };

  setOrderType = (e) => {
    const { orderInfo } = this.state;

    if (parseInt(e.target.value) === OrderTypeEnum.Market) {
      this.setState({
        orderInfo: {
          ...orderInfo,
          price: 0,
          type: parseInt(e.target.value),
        },
      });
    }

    if (parseInt(e.target.value) === OrderTypeEnum.Limit) {
      this.setState({
        orderInfo: {
          ...orderInfo,
          price: this.priceData ? this.priceData.last : 0,
          type: parseInt(e.target.value),
        },
      });
    }

    if (parseInt(e.target.value) === OrderTypeEnum.MarketClose) {
      this.setState({
        orderInfo: {
          ...orderInfo,
          price: this.priceData ? this.priceData.last : 0,
          type: parseInt(e.target.value),
        },
      });
    }
    if (parseInt(e.target.value) === OrderTypeEnum.Stop) {
      this.setState({
        orderInfo: {
          ...orderInfo,
          price: this.priceData ? this.priceData.last : 0,
          type: parseInt(e.target.value),
        },
      });
    }
  };

  setPrice = (e) =>
    this.setState({
      orderInfo: {
        ...this.state.orderInfo,
        price: parseFloat(e.target.value),
      },
    });

  setQuantity = (e) => {
    this.setState({
      orderInfo: {
        ...this.state.orderInfo,
        quantity: parseInt(e.target.value),
      },
    });

    this.setState({ displayQty: parseInt(e.target.value) });
  };

  showCancelOrderConfirmation = (side: OrderSideEnum) => {
    this.props.showConfirmation(`Cancel: ${OrderSideEnum[side]}`, {
      symbol: this.state.symbol,
    });
  };

  showOrderConfirmation = (props: { side: OrderSideEnum; bidAsk: number }) => {
    const { bidAsk, side } = props;
    const { orderInfo: orderInfoState } = this.state;

    const orderInfo = {
      quantity: orderInfoState.quantity,
      symbol: this.state.symbol,
      type: orderInfoState.type,
      price:
        orderInfoState.type !== OrderTypeEnum.Market
          ? bidAsk || orderInfoState.price
          : this.priceData?.last,
    };

    this.props.showConfirmation(OrderSideEnum[side], orderInfo);
  };

  buy_Click = () => {
    if (!this.props.orderNotificationEnabled) {
      this.placeOrder(OrderSideEnum.Buy, 0);
      return;
    }

    const orderProps = { side: OrderSideEnum.Buy, bidAsk: 0 };
    this.setState(orderProps);
    this.showOrderConfirmation(orderProps);
  };

  buyAtAsk_Click = () => {
    const askPrice = this.quoteData?.askPrice as number;

    if (!askPrice) {
      notificationSvc.error("Still waiting for the quote data!");
    }

    if (!this.props.orderNotificationEnabled) {
      this.placeOrder(OrderSideEnum.Buy, askPrice);
      return;
    }

    const orderProps = { side: OrderSideEnum.Buy, bidAsk: askPrice };
    this.setState(orderProps);
    this.showOrderConfirmation(orderProps);
  };

  buyAtBid_Click = () => {
    const bidPrice = this.quoteData?.bidPrice as number;

    if (!bidPrice) {
      notificationSvc.error("Still waiting for the quote data!");
    }

    if (!this.props.orderNotificationEnabled) {
      this.placeOrder(OrderSideEnum.Buy, bidPrice);
      return;
    }

    const orderProps = { side: OrderSideEnum.Buy, bidAsk: bidPrice };
    this.setState(orderProps);
    this.showOrderConfirmation(orderProps);
  };

  cancelBuy_Click = () => {
    if (!this.props.orderNotificationEnabled) {
      this.cancelOrder(OrderSideEnum.Buy);
      return;
    }

    this.setState({ pendingOperation: "cancelBuy" });
    this.showCancelOrderConfirmation(OrderSideEnum.Buy);
  };

  cancelSellShort_Click = () => {
    if (!this.props.orderNotificationEnabled) {
      this.cancelOrder(OrderSideEnum.Sell);
      return;
    }

    this.setState({ pendingOperation: "cancelSellShort" });
    this.showCancelOrderConfirmation(OrderSideEnum.Sell);
  };

  flatPosition_Click = () => {
    const { orderNotificationEnabled, positions, showConfirmation } =
      this.props;
    const { symbol } = this.state;

    if (!orderNotificationEnabled) {
      this.flatPosition();
      return;
    }

    this.setState({ pendingOperation: "flatPosition" });

    const symPos = positions.find((data) => data.Symbol === symbol);
    if (!symPos) {
      return;
    }

    const side = symPos.Position > 0 ? "Sell" : "Buy";
    const price = this.priceData?.last || 0;
    const quantity = Math.abs(symPos.Position);

    const orderInfo = {
      quantity: quantity,
      symbol: symbol,
      price: price,
    };

    showConfirmation(side, orderInfo);
  };

  sell_Click = () => {
    if (!this.props.orderNotificationEnabled) {
      this.placeOrder(OrderSideEnum.Sell, 0);
      return;
    }

    const orderProps = { side: OrderSideEnum.Sell, bidAsk: 0 };
    this.setState(orderProps);
    this.showOrderConfirmation(orderProps);
  };

  sellAtAsk_Click = () => {
    const askPrice = this.quoteData?.askPrice as number;

    if (!askPrice) {
      notificationSvc.error("Still waiting for the quote data!");
    }

    if (!this.props.orderNotificationEnabled) {
      this.placeOrder(OrderSideEnum.Sell, askPrice);
      return;
    }

    const orderProps = { side: OrderSideEnum.Sell, bidAsk: askPrice };
    this.setState(orderProps);
    this.showOrderConfirmation(orderProps);
  };

  sellAtBid_Click = () => {
    const bidPrice = this.quoteData?.bidPrice as number;

    if (!bidPrice) {
      notificationSvc.error("Still waiting for the quote data!");
    }

    if (!this.props.orderNotificationEnabled) {
      this.placeOrder(OrderSideEnum.Sell, this.quoteData?.bidPrice);
      return;
    }

    const orderProps = { side: OrderSideEnum.Sell, bidAsk: bidPrice };
    this.setState(orderProps);
    this.showOrderConfirmation(orderProps);
  };

  short_Click = () => {
    if (!this.props.orderNotificationEnabled) {
      this.placeOrder(OrderSideEnum.Short, 0);
      return;
    }

    const orderProps = { side: OrderSideEnum.Short, bidAsk: 0 };
    this.setState(orderProps);
    this.showOrderConfirmation(orderProps);
  };

  shortAtAsk_Click = () => {
    const askPrice = this.quoteData?.askPrice as number;

    if (!askPrice) {
      notificationSvc.error("Still waiting for the quote data!");
    }

    if (!this.props.orderNotificationEnabled) {
      this.placeOrder(OrderSideEnum.Short, askPrice);
      return;
    }

    const orderProps = { side: OrderSideEnum.Short, bidAsk: askPrice };
    this.setState(orderProps);
    this.showOrderConfirmation(orderProps);
  };

  shortAtBid_Click = () => {
    const bidPrice = this.quoteData?.bidPrice as number;

    if (!bidPrice) {
      notificationSvc.error("Still waiting for the quote data!");
    }

    if (!this.props.orderNotificationEnabled) {
      this.placeOrder(OrderSideEnum.Short, this.quoteData?.bidPrice);
      return;
    }
    this.setState({
      side: OrderSideEnum.Short,
      bidAsk: bidPrice,
    });

    const orderProps = { side: OrderSideEnum.Short, bidAsk: bidPrice };
    this.setState(orderProps);
    this.showOrderConfirmation(orderProps);
  };
}

const mapStateToProps = (state: IAppState): ILevelIIViewStateProps => ({
  accounts: state.account.userAccounts,
  combinationShortcutList: state.settings.shortcutCombinations,
  confirmationModal: state.settings.confirmationModal,
  decimalPlaces: state.settings.general.decimalPlaces,
  defaultAccount: state.settings.general.defaultAccountId,
  defaultDestination: state.settings.general.defaultDestinationId,
  // // destinations: state.account.destinations,
  isCombinationShortcutListAllowed: state.settings.isShortcutCombinationAllowed,
  isResponse: state.settings.isResponse || false,
  keyboardShortcutData: state.settings.keyboardShortcut,
  levelIIStats: state.qm.levelIIStats,
  marketDepths: state.qm.marketDepths,
  orderNotificationEnabled:
    state.settings.notificationSetting.orderNotification,
  positions: state.positions.symbols,
  priceData: state.qm.priceData,
  skinColor: state.settings.marketDepth.levelIISkinColor,
  userOrders: state.account.userOrders,
  userSymbols: state.account.userSymbols,
  widgetSymbols: state.widgets.symbols,
  userAccounts: state.userAccountsList.userAccountsList || [],
  accountData: state.adminAccounts.accountData,
});

const mapDispatchToProps = (
  dispatch: any,
  ownProps: ILevelIIViewProps
): ILevelIIViewDispatchProps => {
  const { windowId } = ownProps;

  return {
    hideConfirmation: () => dispatch(setModalResponse(false)),
    setWidgetSymbol: (symbol: string) =>
      dispatch(setWidgetSymbol(windowId, symbol)),
    showConfirmation: (type: string, orderInfo?: IOrderInfoConfirmModal) =>
      dispatch(updateConfirmationModal(windowId, type, orderInfo, true)),
    subscribeQuoteMediaData: (symbol) =>
      dispatch(subscribeQuoteMediaData(windowId, symbol)),
    unsubscribeQuoteMediaData: () =>
      dispatch(unsubscribeQuoteMediaData(windowId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LevelTwoView);
