import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import FlexView from "react-flexview";
import modalSvc from "../../services/modal-service";

interface WorkspaceCloseModalState {
  isShown: boolean;
}

export default class WorkspaceCloseModal extends Component<
  {},
  WorkspaceCloseModalState
> {
  constructor(props) {
    super(props);

    this.state = {
      isShown: false,
    };
  }

  componentDidMount() {
    modalSvc.showCloseWorkspaceConfirmation$.subscribe((isShown) =>
      this.setState({ isShown })
    );
  }

  render() {
    const { isShown } = this.state;

    return (
      <Modal
        className="confirmation-modal"
        backdrop="static"
        centered
        size={"sm"}
        keyboard={false}
        show={isShown}
        onClick={(e) => e.stopPropagation()}
      >
        <Modal.Header>
          <FlexView
            column
            hAlignContent="center"
            vAlignContent="center"
            basis="100%"
          >
            <span className="widget-text">Workspace Close Confirmation</span>
          </FlexView>
        </Modal.Header>
        <Modal.Body>
          <FlexView column hAlignContent="center" vAlignContent="center">
            <span className="widget-text">
              Are you sure you want to close this workspace?
            </span>
          </FlexView>
        </Modal.Body>
        <Modal.Footer>
          <FlexView grow style={{ justifyContent: "space-between" }}>
            <FlexView>
              <Button onClick={this.cancel}>No</Button>
            </FlexView>
            <FlexView hAlignContent="right">
              <Button onClick={this.ok}>Yes</Button>
            </FlexView>
          </FlexView>
        </Modal.Footer>
      </Modal>
    );
  }

  private cancel = () => modalSvc.showCloseWorkspaceConfirmation$.next(false);

  private ok = () => {
    modalSvc.showCloseWorkspaceConfirmation$.next(false);
    modalSvc.closeWorkspaceConfirmed$.next(true);
  };
}
