import { OrderStatusEnum } from "../../enums/OrderStatusEnum";
import { ExecutionReport } from "../../models/ExecutionReport";
import { IAccountDestination } from "../../models/IAccountDestination";
import IndexedDBService from "../../utils/idb_helper";

export const updateExecutionReportToIDB = async (
  executionReport: ExecutionReport,
  destinations: IAccountDestination[],
  users: any[]
) => {
  let existingOrder: any = await IndexedDBService.getValueByKey(
    "Orders",
    executionReport.orderId.toString()
  );

  if (!existingOrder) {
    existingOrder = await IndexedDBService.getValueByKey(
      "Orders",
      executionReport.origOrderId.toString()
    );
  }

  const accountOrder = executionReport.updateAccountOrder(existingOrder);

  accountOrder.Destination = destinations.find(
    (x) => x.AccountId === accountOrder.AccountId
  )?.DisplayName;

  accountOrder.SentUserName =
    accountOrder.Source === "AutoFlatter"
      ? "Auto Flatter"
      : users.find((x) => x.Id === accountOrder.SentUserId)?.UserName;

  if (executionReport.status === OrderStatusEnum.Cancelled) {
    accountOrder.CancelledUserId = executionReport.userId;
    accountOrder.CancelledUserName = users.find(
      (x) => x.UserId === executionReport.userId
    )?.User.UserName;
  }

  if (accountOrder.ClientOrderId) {
    IndexedDBService.writeToDB("Orders", "ClientOrderId", {...accountOrder, ClientOrderId: accountOrder.ClientOrderId.toString()});
  }
};
