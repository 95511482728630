import { ISymbol } from "../models/ISymbol";
import apiSvc from "./api-service";

class SymbolsServices {
  symbols: ISymbol[] = [];

  async get() {
    const response = await apiSvc.get("SelectSymbolsRequest");
    return (this.symbols = response?.data.Data);
  }

  async add(symbolData) {
    await apiSvc.put("SaveSymbolsRequest", symbolData);
  }
  async delete(symbolId) {
    await apiSvc.api.delete("DeleteSymbolsRequest", {
      params: {
        PrimaryKey: symbolId,
        LoadCache: true,
      },
    });
  }
}

const symbolSvc = new SymbolsServices();
export default symbolSvc;
