import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import { SYMBOLS_SET_SYMBOLS } from "./symbols-types";
import { SymbolState } from "./SymbolState";

const initialState = new SymbolState();

export default function SymbolsReducer(
  state = initialState,
  action: any
): SymbolState {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new SymbolState();

    case SYMBOLS_SET_SYMBOLS:
      return {
        ...state,
        symbolsList: action.payload,
      };

    default:
      return state;
  }
}
