import { FunctionComponent } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import "./App.scss";
import AdminHome from "./components/admin/admin-home";
import Chart from "./components/chart/chart";
import LoginView from "./components/login/login";
import "./font-awesome";
import DetachedWindow from "./components/detach-window/detach-window";
import firebaseSvc from "./services/firebase-service";

if (typeof window !== "undefined") {
  injectStyle();
}

const App: FunctionComponent<{ props?: any }> = () => {
  firebaseSvc.initialize();

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <LoginView />
      <Router>
        <Switch>
          <Route path="/admin" component={AdminHome} />
          <Route path="/chart" component={Chart} />
          <Route path="/detach" component={DetachedWindow} />
          <Route path="*">
            <Redirect to={{ pathname: "/admin" }} />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
