import axios from "axios";
import uuid from "react-uuid";
import { filter } from "rxjs/operators";
import store from "..";
import {
  LibrarySymbolInfo,
  PeriodParams,
  ResolutionString,
} from "../../@types/charting_library";
import { QuoteMediaAuthStatus } from "../../enums/QuoteMediaAuthStatus";
import { QuoteMediaConnectionStatus } from "../../enums/QuoteMediaConnectionStatus";
import { IAuthInfoMeta } from "../../models/IAuthInfoMeta";
import { IQuoteMediaMMQuote } from "../../models/IQuoteMediaMMQuote";
import { IQuoteMediaPrice } from "../../models/IQuoteMediaPrice";
import { IQuoteMediaQuote } from "../../models/IQuoteMediaQuote";
import { IQuoteMediaTrade } from "../../models/IQuoteMediaTrade";
import notificationSvc from "../../services/notification-service";
import { IAppState } from "../reducers/IAppState";
import {
  setMmQuoteData,
  setPriceData,
  setQuoteData,
  setQuoteMediaAuthStatus,
  setQuoteMediaConnectionStatus,
  setQuoteMediaSid,
  setSymbolLongName,
  setTradeData,
  setQuoteMediaHeadlines,
} from "./quote-media-actions";
import { invalidSymbols$ } from "./quote-media-observables";

declare const qmci: any;

const messageTypes = [
  qmci.Streamer.dataTypes.MMQUOTE,
  qmci.Streamer.dataTypes.PRICEDATA,
  qmci.Streamer.dataTypes.QUOTE,
  qmci.Streamer.dataTypes.TRADE,
];

const options = { skipHeavyInitialLoad: false };

const qmApiBaseUrl = "https://app.quotemedia.com";
const qmApiDataUrl = `${qmApiBaseUrl}/data`;

let stream: any = null;
let pendingSubscriptions: { windowId: string; symbol: string }[] = [];
let pendingSymbolDescriptions: string[] = [];
let subscriptions: { [key: string]: string[] } = {};
let fetchedSymbolDescriptions: string[] = [];
let toBeFetchedSymbolDescriptions: string[] = [];
let mmQuotes: IQuoteMediaMMQuote[] = [];
let prices: IQuoteMediaPrice[] = [];
let quotes: IQuoteMediaQuote[] = [];
let trades: IQuoteMediaTrade[] = [];
let lastQMConnectAttempt: number = 0;

init();

export const closeQuoteMediaConnection = () => {
  return (dispatch: any) => {
    dispatch(setQuoteMediaAuthStatus(QuoteMediaAuthStatus.NotAuthenticated));
    dispatch(
      setQuoteMediaConnectionStatus(QuoteMediaConnectionStatus.NotConnected)
    );
    dispatch(setQuoteMediaSid(""));

    if (stream !== null) {
      if (!stream.isClosed()) {
        stream.close();
      }

      stream = null;
    }
  };
};

export const connectToQuoteMedia =
  () =>
  async (dispatch: any, getState: () => IAppState): Promise<void> => {
    if(lastQMConnectAttempt <= Date.now() && lastQMConnectAttempt >= Date.now() - 60000)
    {
      return;
    }

    const { auth, qm } = getState();

    if (!auth.authInfo) {
      return;
    }

    const { authStatus } = qm;

    if (authStatus !== QuoteMediaAuthStatus.NotAuthenticated) {
      return;
    }

    dispatch(setQuoteMediaAuthStatus(QuoteMediaAuthStatus.Authenticating));
    lastQMConnectAttempt = Date.now();
    axios
      .post(`${qmApiBaseUrl}/auth/p/authenticate/v0/`, {
        wmId: auth.authInfo.Meta.WMID,
        username: auth.authInfo.Meta.QMUser,
        password: auth.authInfo.Meta.QMPassword,
      })
      .then((res) => {
        // console.log('qm/auth/p/authenticate/v0/response', (res && res.data && res.data.sid) || res);

        if (res.status === 200) {
          switch (res.data.status) {
            case "Cancelled":
              const errorMessage =
                "An error occurred while validating your QuoteMedia account. " +
                "Please contact QuoteMedia support.";
              notificationSvc.error(errorMessage);
              break;

            default:
              dispatch(
                setQuoteMediaAuthStatus(QuoteMediaAuthStatus.Authenticated)
              );
              dispatch(
                setQuoteMediaConnectionStatus(
                  QuoteMediaConnectionStatus.Connecting
                )
              );
              dispatch(setQuoteMediaSid(res.data.sid));

              dispatch(openQuoteMediaStream(res.data.sid));
              fetchPendingSymbolDescriptions();

              break;
          }
        }
      });

    function fetchPendingSymbolDescriptions() {
      dispatch(fetchSymbolDescriptions(pendingSymbolDescriptions));
      pendingSymbolDescriptions = [];
    }
  };

export const disconnectQuoteMediaStream = () => (dispatch: any) => {
  fetchedSymbolDescriptions = [];
  pendingSubscriptions = [];
  pendingSymbolDescriptions = [];
  subscriptions = {};
  toBeFetchedSymbolDescriptions = [];

  mmQuotes = [];
  prices = [];
  quotes = [];
  trades = [];

  dispatch(closeQuoteMediaConnection());
};

export const getEnhancedChartData = async (
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  periodParams: PeriodParams
) => {
  const { auth, qm } = store.getState();
  const { WMID } = auth.authInfo?.Meta as IAuthInfoMeta;
  const { sid } = qm;

  try {
    let url = `getEnhancedChartData.json?symbol=${symbolInfo.name}&webmasterId=${WMID}&sid=${sid}`;

    let sinceDays = 0;
    if (resolution.includes("D")) {
      const from = new Date(periodParams.from * 1000);
      from.setDate(-180);
      url += `&freq=day&datatype=eod&start=${from
        .toISOString()
        .substring(0, 10)}`;
    } else {
      const daysPerYear = 365;
      const avgTradingDaysPerYear = 253;
      const tradingHoursPerDay = 8;
      const resolutionMinutes = parseInt(resolution, 10);
      const minutesPerHour = 60;
      const extraDays = 2;

      sinceDays =
        (periodParams.countBack * (daysPerYear / avgTradingDaysPerYear)) /
          (tradingHoursPerDay * (minutesPerHour / resolutionMinutes)) +
        extraDays;

      const today = new Date();
      today.setDate(today.getDate() - sinceDays);
      url += `&interval=${resolution}&startDateTime=${Math.floor(
        today.getTime() / 1000
      )}`;
    }

    let response = await fetch(`${qmApiDataUrl}/${url}`);
    return await response.json();
  } catch (e) {
    console.error("getBars() - " + e);

    return [];
  }
};

export const openQuoteMediaStream =
  (sid: string) => (dispatch: any, getState: () => IAppState) => {
    qmci.Streamer.open(
      {
        host: `${qmApiBaseUrl}/cache`,
        cors: true,
        rejectExcessiveConnection: false,
        conflation: null,
        format: "application/json",
        credentials: { sid },
      },
      then((str) => {
        stream = str;

        dispatch(
          setQuoteMediaConnectionStatus(QuoteMediaConnectionStatus.Connected)
        );

        str
          .on("message", function (message) {
            switch (qmci.Streamer.dataTypes.get(message)) {
              case qmci.Streamer.dataTypes.MMQUOTE:
                mmQuotes.push(message);
                break;

              case qmci.Streamer.dataTypes.PRICEDATA:
                prices.push(message);
                break;

              case qmci.Streamer.dataTypes.QUOTE:
                quotes.push(message);
                break;

              case qmci.Streamer.dataTypes.TRADE:
                trades.push({ ...message, id: uuid() });
                break;
            }
          })
          .on("error", function (err) {
            console.error("qm/error", err);

            const { connectionStatus } = getState().qm;

            if (
              err.code === 401 &&
              connectionStatus === QuoteMediaConnectionStatus.Connected
            ) {
              dispatch(closeQuoteMediaConnection());
            }
          })
          .on("slow", function (msg) {
            console.debug(
              "Slow -> TimesExceeded: " +
                msg.timesExceeded +
                " MaxExceed: " +
                msg.maxExceed
            );
          })
          .on("initialDataSent", function (msg) {
            // logSvc.log("Initial data sent. Timestamp: " + msg.timestamp);
          })
          .on("resubscribeMessage", function (msg) {
            console.debug(
              "Resubscription has been triggered. Timestamp: " + msg.timestamp
            );
          })
          .on("close", function (msg) {
            if (msg.reason !== "Manually closed") {
              // console.log("qm/closed", msg);
              dispatch(closeQuoteMediaConnection());
            }
          });

        pendingSubscriptions.forEach((subscription) => {
          dispatch(
            subscribeQuoteMediaData(subscription.windowId, subscription.symbol)
          );
        });

        dispatch(
          fetchSymbolDescriptions(pendingSubscriptions.map((x) => x.symbol))
        );
      })
    );

    function then(onSuccess) {
      return function (err, result) {
        if (err) {
          dispatch(closeQuoteMediaConnection());
          console.error(err);
        } else {
          onSuccess(result);
        }
      };
    }
  };

export const reconnectQuoteMedia = () => (dispatch: any) => {
  Object.keys(subscriptions).forEach((symbol) => {
    subscriptions[symbol].forEach((windowId) => {
      pendingSubscriptions.push({ windowId, symbol });
    });
  });

  subscriptions = {};

  dispatch(closeQuoteMediaConnection());
  dispatch(connectToQuoteMedia());
};

export const subscribeQuoteMediaData =
  (windowId: string, symbol: string) =>
  (dispatch, getState: () => IAppState) => {
    dispatch(fetchSymbolDescriptions([symbol]));

    if (stream === null) {
      pendingSubscriptions.push({ windowId, symbol });
      return;
    }

    if (!(symbol in subscriptions)) {
      subscriptions[symbol] = [];
    }

    if (subscriptions[symbol].indexOf(windowId) !== -1) {
      return;
    }

    subscriptions[symbol].push(windowId);

    stream.subscribe([symbol], messageTypes, options);
  };

export const unsubscribeQuoteMediaData =
  (windowId: string, symbol?: string) =>
  (dispatch: any, getState: () => IAppState) => {
    if (stream === null) {
      const index = pendingSubscriptions.findIndex(
        (x) => x.windowId === windowId
      );
      if (index !== -1) {
        pendingSubscriptions.splice(index, 1);
      }

      return;
    }

    Object.keys(subscriptions)
      .filter((x) => !symbol || x === symbol)
      .forEach((symbol) => {
        const idx = subscriptions[symbol].indexOf(windowId);
        if (idx !== -1) {
          subscriptions[symbol].splice(idx, 1);

          if (subscriptions[symbol].length === 0) {
            stream.unsubscribe([symbol], messageTypes);
          }
        }
      });
  };

export const getNewsHeadlines =
  (symbolsList: string[], date?: Date) => (dispatch: any) => {
    const { auth, qm } = store.getState();
    const { authInfo } = auth;
    if (!authInfo || qm.sid === "") {
      return [];
    }

    const { WMID } = authInfo.Meta;

    const params: any = {
      webmasterId: WMID,
      sid: qm.sid,
      topics: symbolsList.join(",") || ["AAPL", "FB", "MSFT", "ORCL"],
      limit: 50,
    };

    const queryString =
      Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&") + (date ? `&start=${date.toISOString().slice(0, 10)}` : "");

    return axios
      .get(`${qmApiDataUrl}/getHeadlines.json?${queryString}`)
      .then((response) => {
        dispatch(
          setQuoteMediaHeadlines(
            response?.data?.results?.news[0]?.newsitem || [[]]
          )
        );
      })
      .catch((reason) => {
        // console.log("qm/getHeadlines", reason);
        return [];
      });
  };

const fetchSymbolDescriptions =
  (symbolsList: string[]) => (dispatch: any, getState: () => IAppState) => {
    const { qm } = getState();

    if (qm.authStatus !== QuoteMediaAuthStatus.Authenticated) {
      return;
    }

    const { authInfo } = getState().auth;

    if (authInfo === undefined || !qm.sid) {
      symbolsList.forEach((symbol) => {
        if (!pendingSymbolDescriptions.find((x) => x === symbol)) {
          pendingSymbolDescriptions.push(symbol);
        }
      });

      return;
    }

    symbolsList.forEach((symbol) => {
      if (!fetchedSymbolDescriptions.find((x) => x === symbol)) {
        toBeFetchedSymbolDescriptions.push(symbol);
      }
    });

    setInterval(execute, 250);

    function execute() {
      const { auth, qm } = getState();
      const { authInfo } = auth;

      if (authInfo === undefined || !qm.sid) {
        return;
      }

      fetchedSymbolDescriptions = fetchedSymbolDescriptions.concat(
        toBeFetchedSymbolDescriptions
      );

      while (toBeFetchedSymbolDescriptions.length > 0) {
        const concatenatedSymbols = toBeFetchedSymbolDescriptions
          .splice(0, 100)
          .join(",");
        const { WMID } = authInfo.Meta;

        const params = {
          webmasterId: WMID,
          sid: qm.sid,
          symbols: concatenatedSymbols,
        };

        const queryString = Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join("&");
        axios
          .get(`${qmApiDataUrl}/getQuotes.json?${queryString}`)
          .then((response) => {
            if (response.data.results.quote) {
              response.data.results.quote.forEach((quote) =>
                quote.equityinfo
                  ? dispatch(
                      setSymbolLongName(
                        quote.symbolstring,
                        quote.equityinfo.longname
                      )
                    )
                  : invalidSymbols$.next(quote.symbolstring)
              );
            }
          })
          .catch((reason) => {
            console.error("qm/getQuotes", reason);
          });
      }
    }
  };

function init() {
  invalidSymbols$.pipe(filter((x) => x !== "")).subscribe((symbol) => {
    const idx = fetchedSymbolDescriptions.indexOf(symbol);
    fetchedSymbolDescriptions.splice(idx, 1);
  });

  store.dispatch(setupDataBurst());

  if (!window.location.search.includes("detach")) {
    store.dispatch(setupMarketDataReconnection());
  }
}

function setupDataBurst() {
  return (dispatch: any) => {
    setInterval(() => {

      const {detachedWindows} = store.getState().widgets;

      if(!window.location.search.includes("detach") && detachedWindows.length)
      {
        detachedWindows.forEach((windowInstance: Window) => {
          const state = store.getState();
          const payloadKeys = ["account", "orderBlotter", "positions", "qm"];
          const payload = {};
          payloadKeys.forEach((key) => {
            payload[key] = state[key];
          });
          windowInstance.postMessage(JSON.stringify({type: "marketData", payload}));
        });
      }

      if (mmQuotes.length > 0) {
        const stackedMmQuotes = [...mmQuotes];
        mmQuotes.length = 0;
        dispatch(setMmQuoteData(stackedMmQuotes));
      }

      if (prices.length > 0) {
        const stackedPrices = [...prices];
        prices.length = 0;
        dispatch(setPriceData(stackedPrices));
      }

      if (quotes.length > 0) {
        const stackedQuotes = [...quotes];
        quotes.length = 0;
        dispatch(setQuoteData(stackedQuotes));
      }

      if (trades.length > 0) {
        const stackedTrades = [...trades];
        trades.length = 0;
        dispatch(setTradeData(stackedTrades));
      }
    }, 250);
  };
}

function setupMarketDataReconnection() {
  return (dispatch: any, getState: () => IAppState) => {
    // refresh quote media connection every 29 minutes
    setInterval(() => {
      const { auth, connection, qm } = getState();
      const { authInfo } = auth;

      if (!authInfo || !connection.isInternetConnected) {
        dispatch(closeQuoteMediaConnection());
        return;
      }

      const { authStatus, connectionStatus, sidGeneratedAt } = qm;

      if (
        authStatus === QuoteMediaAuthStatus.NotAuthenticated &&
        connectionStatus === QuoteMediaConnectionStatus.NotConnected
      ) {
        // console.log("qm/reconnection-in-progress");
        dispatch(reconnectQuoteMedia());
        return;
      }

      if (new Date().getTime() - sidGeneratedAt.getTime() > 29 * 60 * 1000) {
        // console.log("qm/refresh-in-progress");
        dispatch(reconnectQuoteMedia());
      }
    }, 3000);
  };
}
