export const defaultFirmColumns = {
  isName: false,
  isAddress: false,
  isCityCountry: false,
  isPostalCode: false,
  isFirmType: false,
  isRiskEnable: false,
};

export const updateFirmColumnsForAdminRole = () => {
  const columns = { ...defaultFirmColumns };
  Object.keys(columns).forEach((key) => (columns[key] = true));

  return columns;
};

export const updateFirmColumnsForRiskManagerRole = () => {
  const columns = { ...defaultFirmColumns };
  return columns;
};
