import { QuoteMediaAuthStatus } from "../../enums/QuoteMediaAuthStatus";
import { QuoteMediaConnectionStatus } from "../../enums/QuoteMediaConnectionStatus";
import { IQuoteMediaMMQuote } from "../../models/IQuoteMediaMMQuote";
import { IQuoteMediaPrice } from "../../models/IQuoteMediaPrice";
import { IQuoteMediaQuote } from "../../models/IQuoteMediaQuote";
import { IQuoteMediaTrade } from "../../models/IQuoteMediaTrade";
import { IQuoteMediaHeadline } from "../../models/IQuoteMediaHeadline";
import { IAppState } from "../reducers/IAppState";
import {
  QUOTE_MEDIA_MM_QUOTE, QUOTE_MEDIA_PRICE,
  QUOTE_MEDIA_QUOTE, QUOTE_MEDIA_SET_AUTH_STATUS,
  QUOTE_MEDIA_SET_CONNECTION_STATUS, QUOTE_MEDIA_SET_SID, QUOTE_MEDIA_SET_SYMBOL_LONG_NAME, QUOTE_MEDIA_TRADE,
  QUOTE_MEDIA_SET_HEADLINES,
  QUOTE_MEDIA_COPY_QM_STATE
} from "./quote-media-types";

export const setMmQuoteData = (payload: IQuoteMediaMMQuote[]) => (dispatch: any, getState: any) => {
  const state: IAppState = getState();

  dispatch({
    type: QUOTE_MEDIA_MM_QUOTE,
    payload,
    decimalPlaces: state.settings.general.decimalPlaces,
    skinColor: state.settings.marketDepth.levelIISkinColor
  });
}

export const setPriceData = (payload: IQuoteMediaPrice[]) => (dispatch: any, getState: any) => {
  const state: IAppState = getState();

  dispatch({ type: QUOTE_MEDIA_PRICE, payload, decimalPlaces: state.settings.general.decimalPlaces });
};

export const setQuoteData = (payload: IQuoteMediaQuote[]) => (dispatch: any, getState: any) => {
  const state: IAppState = getState();

  dispatch({
    type: QUOTE_MEDIA_QUOTE,
    payload,
    decimalPlaces: state.settings.general.decimalPlaces,
    skinColor: state.settings.marketDepth.levelIISkinColor
  });
};

export const setQuoteMediaAuthStatus = (status: QuoteMediaAuthStatus) => (dispatch: any) =>
  dispatch({ type: QUOTE_MEDIA_SET_AUTH_STATUS, payload: status })

  export const setQuoteMediaConnectionStatus = (status: QuoteMediaConnectionStatus) => (dispatch: any) =>
  dispatch({ type: QUOTE_MEDIA_SET_CONNECTION_STATUS, payload: status })

export const setQuoteMediaSid = (sid: string) => (dispatch: any) => {
  dispatch({ type: QUOTE_MEDIA_SET_SID, payload: sid });
};

export const setSymbolLongName = (symbol: string, longName: string) => (dispatch: any) =>
  dispatch({
    type: QUOTE_MEDIA_SET_SYMBOL_LONG_NAME,
    payload: {
      symbol,
      longName
    }
  });

export const setTradeData = (payload: IQuoteMediaTrade[]) => (dispatch: any, getState: any) => {
  const state: IAppState = getState();

  dispatch({
    type: QUOTE_MEDIA_TRADE,
    payload,
    decimalPlaces: state.settings.general.decimalPlaces,
  });
};

export const setQuoteMediaHeadlines = (headlines: IQuoteMediaHeadline[]) => (dispatch: any) =>
  dispatch({ type: QUOTE_MEDIA_SET_HEADLINES, payload: headlines });

export const copyQMState = (data) => (dispatch: any) =>
  dispatch({ type: QUOTE_MEDIA_COPY_QM_STATE, payload: data });