import apiSvc from "../../services/api-service";
import { setSymbols } from "./symbols-action";

export async function loadSymbols(dispatch: any) {
  apiSvc
    .get("SelectSymbolsRequest")
    .then((response) => dispatch(setSymbols(response?.data.Data)));
}

export const addSymbol = (symbolData) => async (dispatch) => {
  const symbolAddRes = await apiSvc.put("SaveSymbolsRequest", symbolData);

  if (symbolAddRes !== undefined) {
    dispatch(loadSymbols);
  }
};

export const deleteSymbol = (symbolId: number) => async (dispatch: any) => {
  apiSvc.api
    .delete("DeleteSymbolsRequest", {
      params: {
        PrimaryKey: symbolId,
        LoadCache: true,
      },
    })
    .then(() => dispatch(loadSymbols));
};
