import {
  CloseOutlined,
  ExitToAppOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  LinkOutlined,
} from "@material-ui/icons";
import { ResizeDirection } from "re-resizable";
import * as React from "react";
import { Badge, Dropdown } from "react-bootstrap";
import { DraggableEvent } from "react-draggable";
import FlexView from "react-flexview";
import { connect } from "react-redux";
import { DraggableData, Position, ResizableDelta, Rnd } from "react-rnd";
import { WatchListTypeEnum } from "../../enums/WatchListTypeEnum";
import { IPosition } from "../../models/IPosition";
import tempCache from "../../services/temp-cache";
import store from "../../store";
import { IAppState } from "../../store/reducers/IAppState";
import {
  detachNewWidget,
  linkWidget,
  removeWidgetSymbol,
  setActiveWidget,
  unlinkWidget,
} from "../../store/widgets/widgets-actions";
import AccountSummary from "../account-summary/account-summary";
import AccountPerformance from "../admin/account-performance/account-performance";
import AccountsList from "../admin/accounts/accounts-list";
import FirmsList from "../admin/firms/firms-list";
import GroupsList from "../admin/groups/groups-list";
import UsersList from "../admin/users/users-list";
import AdminSymbols from "../admin/symbols/symbols-list";
import Chart from "../chart/chart";
import LadderView from "../ladder-view/ladder-view";
import LevelTwoView from "../level2view/level2view";
import News from "../news/news";
import OrderBlotter from "../order-blotter/order-blotter";
import PositionBlotter from "../position-blotter/position-blotter";
import QuickTrade from "../quick-trade/quick-trade";
import TimeAndSales from "../time-and-sales/time-and-sales";
import TopMovers from "../top-movers/top-movers";
import WatchList from "../watch-list/watch-list";
import {
  IShellViewDispatchedProps,
  IShellViewMappedProps,
  IShellViewProps,
} from "./IShellViewProps";
import { IShellViewState } from "./IShellViewState";
import ShellDropdown from "./shelldropdown";
import "./styles.scss";
import GroupPosition from "../admin/group-position/group-position";
import BrokerList from "../admin/brokers-list/broker-list";
import StrategiesList from "../admin/strategies-list/strategies-list";
import UsersAds from "../admin/users-ads/users-ads";
import DestinationList from "../admin/destination-list/destination-list";
import Config from "../admin/config/config";
import Form from "../forms/detail-form";
import AutoFlatter from "../admin/autoFlatter/autoFlatter";
import Fees from "../admin/fees/fees";
import Reports from "../admin/Reports/Reports";
import AuditTrail from "../admin/Audit-Trail/audit-trail-list";

type Props = IShellViewMappedProps &
  IShellViewDispatchedProps &
  IShellViewProps;

class ShellView extends React.Component<Props, IShellViewState> {
  private rnd: Rnd | null = null;
  private linkingDropdownArray: string[] = [
    "Unlink",
    "Red",
    "Blue",
    "Yellow",
    "Green",
    "Orange",
    "Magenta",
    "Brown",
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      height: props.height,
      isDragging: false,
      isMaximized: props.isMaximized,
      position: {
        x: props.positionX,
        y: props.positionY,
      },
      width: props.width,
    };
  }
  componentDidMount() {
    if (this.props.workspaceId === "Default") {
      this.linkWidget("Red");
    }
  }

  render() {
    const { height, isMaximized, position, width } = this.state;
    const {
      canBeMaximized,
      close,
      dropdownValues,
      icon,
      id,
      isActive,
      isLinkingEnabled,
      linkedColor,
      symbol,
      maxHeight,
      maxWidth,
      title,
      watchListType,
      isDetached,
    } = this.props;
    const { detachedWindows } = store.getState().widgets;

    return (
      // <div className="position-absolute d-flex justify-content-center align-items-center" style={{top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <Rnd
        default={{
          x: isMaximized ? 0 : position.x,
          y: isMaximized ? 0 : position.y,
          width: isMaximized ? "100%" : width,
          height: isMaximized ? "100%" : height,
        }}
        minWidth={0}
        id={id}
        minHeight={0}
        maxWidth={maxWidth || 9999}
        maxHeight={maxHeight || 9999}
        bounds="parent"
        className={`shell-view  ${
          title.includes("Edit") || title.includes("Add")
            ? "box-shadow-shell"
            : ""
        } ${isActive ? "active-widget" : ""}
        ${title === "Chart" ? "shell-collaps" : ""}
        ${title === "Watchlist" ? "shell-collaps" : ""}
        ${
          this.props.workspaceId !== "Default" && !isMaximized
            ? "mini-wiget"
            : "max-wiget"
        }
        ${
          detachedWindows &&
          detachedWindows.find((w) => w.location.search.includes(id))
            ? "d-none"
            : ""
        }
        ${
          title === "Accounts" ||
          title === "Groups" ||
          title === "Firms" ||
          title === "Account Watch" ||
          title === "Users" ||
          title === "Orders" ||
          title === "Positions" ||
          title === "Level II View" ||
          title === "Trades" ||
          title === "Quick Trade" ||
          title === "Watchlist" ||
          title === "Chart" ||
          (title === "News" &&
            this.props.workspaceId !== "Default" &&
            !isMaximized &&
            !isActive)
            ? "gen-shell"
            : ""
        }
        ${title === "Reports" && !isMaximized ? "report-width" : ""}`}
        disableDragging={isMaximized}
        enableResizing={!isMaximized}
        dragHandleClassName="toolbar"
        onResizeStop={this.onResize}
        onDragStart={() => this.setState({ isDragging: true })}
        onDragStop={this.onDrag}
        ref={(x) => {
          if (!this.rnd) {
            this.rnd = x;
          }
        }}
      >
        <FlexView
          column
          className={`flex-view 
          ${
            title.includes("Edit") || title.includes("Add")
              ? "fc-control maintain-height"
              : ""
          } 
          ${title === "AccountPerformance" ? "fc-table" : ""}
          ${title === "Chart" && !isMaximized ? "widget-width" : "side-width"}
          ${
            title === "Watchlist" && !isMaximized
              ? "widget-width"
              : "side-width"
          }
          `}
          onClick={() =>
            this.props.setActiveWidget(this.props.workspaceId, this.props.id)
          }
        >
          <FlexView
            className={`toolbar ${
              title.includes("Edit") || title.includes("Add")
                ? "form-toolbar"
                : ""
            }`}
          >
            <FlexView
              hAlignContent="left"
              vAlignContent="center"
              className="title"
              id={`${title === "Form" ? "EditForm" : ""}`}
            >
              <img
                src={icon}
                alt={title + " Icon"}
                className="mr-2 my-auto"
                height={15}
              />
              {title}
              {watchListType === WatchListTypeEnum.MostActive
                ? " - Most Active"
                : watchListType === WatchListTypeEnum.Trader2B
                ? " - trader2B"
                : ""}
              {dropdownValues && (
                <ShellDropdown id={id} dataValues={dropdownValues} />
              )}
            </FlexView>
            <FlexView
              hAlignContent="right"
              vAlignContent="center"
              className="actions py-1"
            >
              {isLinkingEnabled && (
                <Dropdown>
                  <Dropdown.Toggle className="linking-icon">
                    <span title="Link Widget" className="link-icon-contain">
                      <LinkOutlined style={{ color: linkedColor }} />
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="linking-icon-dropdown-menu">
                    {this.linkingDropdownArray.map((color, index) => (
                      <Dropdown.Item
                        key={`link-${color}`}
                        className="linking-icon-dropdown-items px-3"
                        onClick={() => this.linkWidget(color)}
                      >
                        <Badge
                          className={
                            index === 0
                              ? "mr-1 px-2 py-1 text-white"
                              : "mr-1 px-2 py-1 text-dark"
                          }
                          style={{
                            backgroundColor: index === 0 ? "inherit" : color,
                          }}
                        >
                          {index === 0 ? "X" : index}
                        </Badge>{" "}
                        {color}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {isDetached ? (
                <></>
              ) : (
                <span className="icon-widget" title="Detach Window">
                  {" "}
                  <ExitToAppOutlined onClick={() => this.detach()} />
                </span>
              )}
              {/* <span title="Minimize">
                {" "}
                <MinimizeOutlined />
              </span> */}

              {canBeMaximized !== false && (
                <>
                  {!isMaximized && (
                    <span title="Maximize" className="icon-widget">
                      <FullscreenOutlined onClick={this.maximize} />
                    </span>
                  )}

                  {isMaximized && (
                    <span title="Exit Full Screen" className="icon-widget">
                      <FullscreenExitOutlined onClick={this.restore} />
                    </span>
                  )}
                </>
              )}

              <span title="Close">
                <CloseOutlined onClick={close} />
              </span>
            </FlexView>
          </FlexView>
          <FlexView
            column
            className={`content ${
              title.includes("Edit") || title.includes("Add")
                ? "form-box"
                : title === "Orders" ||
                  title === "Positions" ||
                  title === "Reports"
                ? "bg-order"
                : ""
            } 
          ${title === "Reports" && !isMaximized ? "shell-full-overflow" : ""}`}
          >
            {title === "Trades" && (
              <TimeAndSales windowId={this.props.id} symbol={symbol} />
            )}
            {title === "Watchlist" && (
              <WatchList
                windowId={this.props.id}
                workspaceId={this.props.workspaceId}
                watchListType={this.props.watchListType}
              />
            )}
            {title === "Orders" && <OrderBlotter windowId={this.props.id} />}
            {title === "Positions" && (
              <PositionBlotter windowId={this.props.id} />
            )}
            {title === "Level II View" && (
              <LevelTwoView windowId={this.props.id} symbol={symbol} />
            )}
            {title === "Ladder View" && (
              <LadderView width={this.state.width} height={this.state.height} />
            )}
            {title === "News" && (
              <News windowId={this.props.id} symbol={symbol} />
            )}
            {title === "Quick Trade" && (
              <QuickTrade windowId={this.props.id} symbol={symbol} />
            )}
            {title === "Top Movers" && (
              <TopMovers width={this.state.width} height={this.state.height} />
            )}
            {title === "Chart" && (
              <Chart windowId={this.props.id} symbol={symbol} />
            )}
            {title === "Account Info" && <AccountSummary />}
            {title === "Users" && <UsersList windowId={this.props.id} />}
            {title === "Accounts" && <AccountsList windowId={this.props.id} />}
            {title === "Groups" && <GroupsList windowId={this.props.id} />}
            {title === "Firms" && <FirmsList windowId={this.props.id} />}
            {title === "Account Watch" && (
              <AccountPerformance windowId={this.props.id} />
            )}
            {title === "Admin Symbols" && <AdminSymbols />}
            {title === "Group Watch" && <GroupPosition />}
            {title === "Brokers" && <BrokerList />}
            {title === "Routing Stratgites" && <StrategiesList />}
            {title === "Broadcasting" && <UsersAds />}
            {title === "Destinations" && <DestinationList />}
            {title === "Settings" && <Config windowId={this.props.id} />}
            {(title.includes("Edit") || title.includes("Add")) && (
              <Form fieldName={title} id={id} />
            )}
            {title === "Auto Flatter" && <AutoFlatter />}
            {title === "Fees" && <Fees />}
            {title === "Reports" && <Reports />}
            {title === "Audit Trail" && <AuditTrail windowId={this.props.id} />}
          </FlexView>
        </FlexView>
      </Rnd>
      //</div>
    );
  }

  private detach() {
    const { title, id, height, width } = this.props;
    const detachedInstance = window.open(
      `/detach?detached=1&title=${title}&window=${id}`,
      "",
      `height=${height},width=${width},scrollbars=yes`
    );
    if (detachedInstance) {
      detachedInstance.addEventListener("load", () => {
        const state = store.getState();
        store.dispatch(detachNewWidget(detachedInstance));
        detachedInstance.postMessage(
          JSON.stringify({ type: "loadProps", payload: this.props })
        );
        detachedInstance.postMessage(
          JSON.stringify({
            type: "stateData",
            payload: {
              ...state,
              widgets: { ...state.widgets, detachedWindows: [] },
            },
          })
        );
      });
    }
  }

  private maximize = () => {
    const rnd = this.rnd as Rnd;
    const obj = {
      x: this.state.position.x,
      y: this.state.position.y,
    };

    tempCache.widgetPreviousPosition = obj;

    rnd.updateSize({ width: "100%", height: "100%" });
    rnd.updatePosition({ x: 0, y: 0 });

    this.setState({ isMaximized: true }, () => this.props.update(this.state));
    this.props.maximize(true);
  };

  private restore = () => {
    const { height, width } = this.state;

    const previousPosition =
      tempCache.widgetPreviousPosition || ({} as IPosition);
    const { x, y } = previousPosition;
    this.rnd?.updateSize({ width, height });
    this.rnd?.updatePosition({ x, y });

    this.setState({ isMaximized: false });
    this.props.maximize(false);
  };

  private onDrag = (e: DraggableEvent, data: DraggableData) => {
    this.setState({ isDragging: false });

    if (data.x !== this.state.position.x || data.y !== this.state.position.y) {
      this.setState(
        {
          position: {
            x: data.x,
            y: data.y,
          },
        },
        () => this.props.update(this.state)
      );
    }
  };

  private onResize = (
    e: MouseEvent | TouchEvent,
    dir: ResizeDirection,
    elementRef: HTMLElement,
    delta: ResizableDelta,
    position: Position
  ) => {
    if (delta.height !== 0 || delta.width !== 0) {
      this.setState(
        {
          width: elementRef.offsetWidth,
          height: elementRef.offsetHeight,
          position: {
            x: position.x,
            y: position.y,
          },
        },
        () => this.props.update(this.state)
      );
    }
  };

  private linkWidget(color: string) {
    if (color !== "Unlink") {
      this.props.linkWidget(color, this.props.symbol);
    } else {
      this.props.unlinkWidget();
      this.props.removeWidgetSymbol();
    }
  }
}

const mapStateToProps = (
  state: IAppState,
  ownProps: IShellViewProps
): IShellViewMappedProps => {
  const workspaceIds = Object.keys(state.widgets.activeWidget);
  const activeWidgets = workspaceIds.map((x) => state.widgets.activeWidget[x]);
  const isActive = activeWidgets.indexOf(ownProps.id) !== -1;
  const symbols = state.widgets.symbols[activeWidgets[0]];

  const linkedWidget =
    state.widgets.linkedWidgets &&
    state.widgets.linkedWidgets.find(
      (x) => x.widgets.indexOf(ownProps.id) !== -1
    );

  const widgetSymbol = (state.widgets.symbols[ownProps.id] || [])[0];

  return {
    isActive,
    linkedColor: linkedWidget?.color || "inherit",
    symbol: linkedWidget?.symbol || widgetSymbol || "",
    symbols,
  };
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: IShellViewProps
): IShellViewDispatchedProps => ({
  linkWidget: (link: string, symbol: string) =>
    dispatch(linkWidget(ownProps.workspaceId, ownProps.id, link, symbol)),
  removeWidgetSymbol: () => dispatch(removeWidgetSymbol(ownProps.id)),
  setActiveWidget: (workspaceId: string, widgetId: string) =>
    dispatch(setActiveWidget(workspaceId, widgetId)),
  unlinkWidget: () => dispatch(unlinkWidget(ownProps.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShellView);
