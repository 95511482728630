import uuid from "react-uuid";
import { OrderComboTypeEnum } from "../enums/OrderComboTypeEnum";
import { OrderMessageTypeEnum } from "../enums/OrderMessageTypeEnum";
import { OrderSideEnum } from "../enums/OrderSideEnum";
import { OrderSourceEnum } from "../enums/OrderSourceEnum";
import { OrderStatusEnum } from "../enums/OrderStatusEnum";
import { OrderTifEnum } from "../enums/OrderTifEnum";
import { OrderTypeEnum } from "../enums/OrderTypeEnum";
import tempCache from "../services/temp-cache";
import {
  int32FromUint8Array,
  doubleFromUint8Array,
  int64FromUint8Array,
  stringFromUint8Array,
  numberFromUint8Array,
} from "../utils/decoder";
import {
  doubleToInt8Array,
  longToInt8Array,
  longToUint8Array,
  numberToUint8Array,
  stringToAsciiArray,
} from "../utils/encoder";
import { OrderIdGenerator } from "../utils/OrderIdGenerator";
import { IAccountOrder } from "./IAccountOrder";

export class NewOrder {
  accountId = 0;
  ask = 0;
  bid = 0;
  comboType = OrderComboTypeEnum.None;
  destinationId: number = 0;
  destinationName: string = "";
  id = "";
  ocoId = "";
  price = 0;
  quantity = 0;
  side = OrderSideEnum.None;
  source = OrderSourceEnum[process.env.REACT_APP_NAME as string];
  symbol = "";
  tif = OrderTifEnum.Day;
  time: Date;
  type = OrderTypeEnum.Market;
  userId: number;

  private static readonly _blockLength = 86;
  private static readonly _messageType = OrderMessageTypeEnum.NewOrder;
  private static readonly _process = 0;

  constructor() {
    const { userInfo } = tempCache;
    this.userId = userInfo ? +userInfo.UserId : 0;

    this.id = new OrderIdGenerator(this.userId).nextId().toString();
    this.time = new Date();
  }

  get displayQuantity() {
    return this.quantity;
  }

  get messageSize() {
    return NewOrder._blockLength + this.symbol.length;
  }

  get symbolLength() {
    return this.symbol.length;
  }

  encode(): Uint8Array {
    const result = new Uint8Array(128);

    result.set([this.messageSize], 0);
    result.set([NewOrder._messageType], 1);
    result.set([NewOrder._process], 2);
    result.set([NewOrder._blockLength], 3);
    result.set([this.symbolLength], 4);
    result.set(numberToUint8Array(this.accountId), 5);
    result.set(doubleToInt8Array(this.price), 9);
    result.set(doubleToInt8Array(this.quantity), 17);
    result.set(numberToUint8Array(this.destinationId), 25);
    result.set(doubleToInt8Array(this.displayQuantity || this.quantity), 29);
    result.set(numberToUint8Array(this.userId), 37);
    result.set(longToInt8Array(BigInt(this.time.getTime())), 41);
    result.set(stringToAsciiArray(this.side.toString()), 49);
    result.set(stringToAsciiArray(this.tif.toString()), 50);
    result.set(stringToAsciiArray(this.type.toString()), 51);
    result.set([this.comboType], 52);
    result.set([this.source], 53);
    result.set(longToUint8Array(BigInt(this.id)), 54);
    result.set(longToUint8Array(BigInt(this.ocoId)), 62);
    result.set(doubleToInt8Array(this.bid), 70);
    result.set(doubleToInt8Array(this.ask), 78);
    result.set(stringToAsciiArray(this.symbol), NewOrder._blockLength);

    return result;
  }

  getAccountOrder(accountName: string): IAccountOrder {
    return {
      AccountId: this.accountId,
      AccountName: accountName,
      ClientOrderId: this.id,
      DateTime: this.time.toString(),
      DestId: this.destinationId,
      Destination: this.destinationName,
      OCOID: this.ocoId,
      OrderType: OrderTypeEnum[this.type],
      Price: this.price,
      Qty: this.quantity,
      RowId: uuid(),
      SentUserId: this.userId,
      Side: OrderSideEnum[this.side],
      Source: OrderSourceEnum[this.source],
      Status:
        this.type === OrderTypeEnum.Limit ||
        this.type === OrderTypeEnum.Stop ||
        this.type === OrderTypeEnum.TP
          ? OrderStatusEnum[OrderStatusEnum.Pending]
          : OrderStatusEnum[OrderStatusEnum.Sending],
      Symbol: this.symbol,
      TIF: OrderTifEnum[this.tif],
    };
  }

  load(buffer: Uint8Array) {
    this.accountId = int32FromUint8Array(buffer, 5);
    this.price = doubleFromUint8Array(buffer, 9);
    this.quantity = doubleFromUint8Array(buffer, 17);
    this.destinationId = int32FromUint8Array(buffer, 25);
    this.userId = int32FromUint8Array(buffer, 37);
    this.time = new Date(Number(int64FromUint8Array(buffer, 41)));
    this.side = +stringFromUint8Array(buffer, 49, 1);
    this.tif = +stringFromUint8Array(buffer, 50, 1);
    this.type = +stringFromUint8Array(buffer, 51, 1);
    this.comboType = numberFromUint8Array(buffer, 52);
    this.source = numberFromUint8Array(buffer, 53);
    this.id = "" + int64FromUint8Array(buffer, 54);
    this.ocoId = "" + int64FromUint8Array(buffer, 62);
    this.bid = doubleFromUint8Array(buffer, 70);
    this.ask = doubleFromUint8Array(buffer, 78);
    const symbolLength = numberFromUint8Array(buffer, 4);
    this.symbol = stringFromUint8Array(
      buffer,
      NewOrder._blockLength,
      symbolLength
    );
  }
}
