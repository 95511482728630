import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import FlexView from "react-flexview";
import modalSvc from "../../services/modal-service";
import notificationSvc from "../../services/notification-service";
import "./styles.scss";

interface IRenameWorkspaceState {
  isShown: boolean;
  name: string;
}

export default class RenameWorkspace extends Component<
  {},
  IRenameWorkspaceState
> {
  constructor(props) {
    super(props);

    this.state = {
      isShown: false,
      name: "",
    };
  }

  componentDidMount() {
    modalSvc.showRenameWorkspaceModal$.subscribe((isShown) =>
      this.setState({ isShown })
    );
  }

  render() {
    const { isShown } = this.state;

    return (
      <Modal
        className="confirmation-modal"
        backdrop="static"
        centered
        size={"sm"}
        keyboard={false}
        show={isShown}
        onClick={(e) => e.stopPropagation()}
      >
        <Modal.Header>
          <FlexView
            column
            hAlignContent="center"
            vAlignContent="center"
            basis="100%"
          >
            <span className="widget-text">Rename Workspace</span>
          </FlexView>
        </Modal.Header>
        <Modal.Body>
          <FlexView column hAlignContent="center" vAlignContent="center">
            <input
              className="workspace-rename"
              type="text"
              maxLength={20}
              minLength={1}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </FlexView>
        </Modal.Body>
        <Modal.Footer>
          <FlexView grow style={{ justifyContent: "space-between" }}>
            <FlexView>
              <Button onClick={this.cancel}>Cancel</Button>
            </FlexView>
            <FlexView hAlignContent="right">
              <Button onClick={this.ok}>Save</Button>
            </FlexView>
          </FlexView>
        </Modal.Footer>
      </Modal>
    );
  }

  private cancel = () => modalSvc.showRenameWorkspaceModal$.next(false);

  private ok = () => {
    const { name } = this.state;

    if (name.length < 1) {
      notificationSvc.error("Please Enter a Valid Workspace Name");
    } else if (name.length > 20) {
      notificationSvc.error(
        "Workspace Name should not be more than 20 Characters long!"
      );
    } else {
      modalSvc.showRenameWorkspaceModal$.next(false);
      modalSvc.workspaceRenamed$.next(name);
    }
  };
}
