import moment from "moment";

const StoreIndicies = {};

class IndexedDBService {
  private static dbName: string = "HW_Admin"; // Global variable for DB name

  /**
   * Opens a connection to the IndexedDB.
   * @returns A promise that resolves to the database connection or null if unsuccessful.
   */
  static openDBConnection(): Promise<IDBDatabase | null> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(IndexedDBService.dbName);
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => resolve(null);
      request.onupgradeneeded = (event: any) => {
        // Handle database schema creation or update
      };
    });
  }

  /**
   * Writes or updates a value in the specified object store.
   * @param storeName The name of the object store.
   * @param keyPath The key path.
   * @param value The value to write.
   * @returns A promise that resolves when the operation is complete.
   */
  static async writeToDB(
    storeName: string,
    keyPath: string,
    value: any
  ): Promise<void> {
    try {
      let db = await IndexedDBService.openDBConnection();
      if (!db) throw new Error("Failed to open DB connection");

      // Check if objectStore exists
      if (!db.objectStoreNames.contains(storeName)) {
        // If not, upgrade DB version and create the object store
        const newVersion = db.version + 1;
        db.close(); // Close the current connection before opening a new one
        db = await IndexedDBService.openDBConnectionWithVersion(
          newVersion,
          storeName,
          keyPath
        );
      }

      // Now, perform the write operation
      const transaction = db.transaction(storeName, "readwrite");
      const store = transaction.objectStore(storeName);
      const request = store.put({...value, TimeStamp: Date.now()}); // Assuming `value` includes the keyPath

      return new Promise((resolve, reject) => {
        request.onsuccess = () => resolve();
        request.onerror = () => reject("Failed to write to DB");
      });
    } catch (error) {
      console.error("Error in writeToDB:", error);
      throw error; // Re-throw the error for external handling, if necessary
    }
  }

  /**
   * Opens a database connection with a specific version.
   * @param version The database version.
   * @returns A promise that resolves to the database connection.
   */
  private static openDBConnectionWithVersion(
    version: number,
    storeName: string,
    keyPath?: string
  ): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(IndexedDBService.dbName, version);
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject("Failed to open DB with new version");
      request.onupgradeneeded = (event: any) => {
        const db = event.target.result as IDBDatabase;
        if (!db.objectStoreNames.contains(storeName)) {
          const store = db.createObjectStore(
            storeName,
            keyPath ? { keyPath: keyPath } : undefined
          );
          if (StoreIndicies[storeName]) {
            store.createIndex(
              StoreIndicies[storeName],
              StoreIndicies[storeName],
              { unique: false }
            );
          }
        }
      };
    });
  }

  /**
   * Retrieves an entire object store.
   * @param storeName The name of the object store.
   * @returns A promise that resolves to the contents of the object store or an empty array.
   */
  static async getObjectStore(storeName: string): Promise<any[]> {
    try {
      const db = await IndexedDBService.openDBConnection();
      if (!db) throw new Error("DB connection failed");
      if (!db.objectStoreNames.contains(storeName)) {
        db.close();
        return [];
      }
      const transaction = db.transaction(storeName, "readonly");
      const store = transaction.objectStore(storeName);
      return new Promise((resolve, reject) => {
        const request = store.openCursor(null, "prev"); // Use 'prev' for reverse order
        const result: any[] = []; // Array to accumulate the results
        request.onsuccess = (event) => {
          const cursor = (event.target as IDBRequest<IDBCursorWithValue>)
            .result;
          if (cursor) {
            result.push(cursor.value); // Add the current record to the result array
            cursor.continue(); // Move to the next record in reverse order
          } else {
            // Once there are no more records, resolve the promise with the result array
            resolve(result);
          }
        };
        request.onerror = () =>
          reject(new Error("Failed to iterate over object store"));
      });
    } catch (error) {
      console.error("Error in getObjectStore:", error);
      return [];
    }
  }

  /**
   * Retrieves a value from the specified object store by its key.
   * @param storeName The name of the object store.
   * @param key The key of the value to retrieve.
   * @returns A promise that resolves to the value or null if not found.
   */
  static async getValueByKey(storeName: string, key: any): Promise<any | null> {
    try {
      const db = await IndexedDBService.openDBConnection();
      if (!db) throw new Error("DB connection failed");

      if (!db.objectStoreNames.contains(storeName)) {
        db.close();
        return null;
      }

      const transaction = db.transaction(storeName, "readonly");
      const store = transaction.objectStore(storeName);
      const request = store.get(key);

      return new Promise((resolve, reject) => {
        request.onsuccess = (event) =>
          resolve((event.target as IDBRequest<any>).result);
        request.onerror = () => resolve(null); // Resolve with null if not found
      });
    } catch (error) {
      console.error("Error in getValueByKey:", error);
      return null;
    }
  }

  /**
   * Writes or updates multiple records in the specified object store.
   * @param storeName The name of the object store.
   * @param keyPath The key path.
   * @param data The array of objects to write.
   * @returns A promise that resolves when all operations are complete.
   */
  static async saveCollectionToDB(
    storeName: string,
    keyPath: string,
    data: any[]
  ): Promise<void> {
    try {
      let db = await IndexedDBService.openDBConnection();
      if (!db) throw new Error("Failed to open DB connection");

      // Check if objectStore exists
      if (!db.objectStoreNames.contains(storeName)) {
        // If not, upgrade DB version and create the object store
        const newVersion = db.version + 1;
        db.close(); // Close the current connection before opening a new one
        db = await IndexedDBService.openDBConnectionWithVersion(
          newVersion,
          storeName,
          keyPath
        );
      }

      const transaction = db.transaction(storeName, "readwrite");
      const store = transaction.objectStore(storeName);

      // Process each item in the array
      for (const item of data) {
        const request = store.put(item); // put() either adds or updates a record
        await new Promise<void>((resolve, reject) => {
          request.onsuccess = () => resolve();
          request.onerror = () => reject(request.error);
        });
      }
    } catch (error) {
      console.error("Error in saveCollectionToDB:", error);
      throw error; // Re-throw the error for external handling, if necessary
    }
  }

  static async getDataAfterDateTime(
    storeName: string,
    dateFiled: string,
    dateTimeValue: string
  ): Promise<any[]> {
    try {
      const parseDateTime = (datetimeStr: string): Date => {
        const [date, time] = datetimeStr.split(" ");
        return new Date(`${date}T${time}`);
      };

      const db = await IndexedDBService.openDBConnection();
      if (!db) throw new Error("Failed to open DB connection");

      const transaction = db.transaction(storeName, "readonly");
      const store = transaction.objectStore(storeName);
      const date = parseDateTime(dateTimeValue);
      const keyRange = IDBKeyRange.lowerBound(date, true);

      return new Promise((resolve, reject) => {
        const request = store.index(dateFiled).openCursor(keyRange);
        const orders: any[] = [];

        request.onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) {
            orders.push(cursor.value);
            cursor.continue();
          } else {
            resolve(orders); // Cursor has reached the end
          }
        };

        request.onerror = () => reject("Failed to retrieve orders");
      });
    } catch (error) {
      console.error("Error in getOrdersAfterDateTime:", error);
      throw error;
    }
  }

  static cleanDatabase() {
      // Open a connection to the indexedDB database
      const request = indexedDB.open(IndexedDBService.dbName);
    
      request.onsuccess = () => {
        const db = request.result;
        
        let allStores = db.objectStoreNames;

        Array.from(allStores).forEach((store) => {
          const transaction = db.transaction([store], 'readwrite');
          const objectStore = transaction.objectStore(store);
          
          // Get all entries from the object store
          const getAllEntriesRequest = objectStore.getAll();
      
          getAllEntriesRequest.onsuccess = () => {
            const entries = getAllEntriesRequest.result;
      
            // Get the current date and time
            const currentDate = moment();
      
            // Iterate through each entry and delete the ones older than the current date
            entries.forEach(entry => {
              const entryTimeStamp = moment(entry.TimeStamp);
              const timeDifference = currentDate.diff(entryTimeStamp, 'minutes');
      
              // Check if the entry is older than the current date
              if (timeDifference > 0 && typeof objectStore.keyPath === "string") {
                // Delete the entry
                const deleteRequest = objectStore.delete(entry[objectStore.keyPath]);
      
                deleteRequest.onerror = (event: Event) => {
                  console.error('Error deleting entry:', event);
                };
              }
            });
          };
      
          getAllEntriesRequest.onerror = (event: Event) => {
            console.error('Error fetching entries:', event);
          };
        });
        
      };
    
      request.onerror = (event: Event) => {
        console.error('Error opening database:', event);
      };
    }
}

export default IndexedDBService;
