import { CloseOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { OrderSideEnum } from "../../enums/OrderSideEnum";
import { OrderTypeEnum } from "../../enums/OrderTypeEnum";
import { NewOrder } from "../../models/NewOrder";
import notificationSvc from "../../services/notification-service";
import store from "../../store";
import { placeNatsOrder } from "../../store/connection/connection-async-actions";
import { IAppState } from "../../store/reducers/IAppState";
import { useState } from "react";
import GeneralConfirmation from "../confirmation/general-confirmation";

const FlatPositionBtn = (props: any) => {

  const [showClosePositionConfirmation, setShowClosePositionConfirmation] = useState(false);

  const defaultDestinationId = useSelector(
    (state: IAppState) => state.settings.general.defaultDestinationId
  );

  const flatPosition = (data) => {
    if (data && defaultDestinationId !== -1) {
      const destinations =
        store.getState().accountsDestinations.destinationsLookup;
      const { authInfo } = store.getState().auth;
      const order = new NewOrder();

      order.symbol = data.Symbol;
      if (data.Position > 0) {
        order.side = OrderSideEnum.Sell;
      } else if (data.Position < 0) {
        order.side = OrderSideEnum.Buy;
      }

      order.price = 0;
      order.quantity = Math.abs(data.Position);
      order.type = OrderTypeEnum.Market;
      order.accountId = data.AccountId;
      order.userId = parseInt(authInfo?.UserId ? authInfo.UserId : "0");
      order.destinationId =
        destinations.find((x) => x.AccountId === data.AccountId)
          ?.DestinationId || 0;

      store.dispatch(placeNatsOrder(order));
    } else {
      notificationSvc.error(
        "Kindly Select Default Destination from Setting General!"
      );
    }
    setShowClosePositionConfirmation(false);
  };

  const orderConfirmationMessage = `Place ${props.data.Position > 0 ? OrderSideEnum[OrderSideEnum.Buy] : OrderSideEnum[OrderSideEnum.Sell]} Order of ${Math.abs(props.data.Position)} ${props.data.Symbol} @MKT For Account ${props.data.AccountName}`;

  return (
    <>
      {props.data.Position > 0 || props.data.Position < 0 ? (
        <span className="flat-position">
          <CloseOutlined onClick={() => setShowClosePositionConfirmation(true)} />
        </span>
      ) : (
        <div></div>
      )}
      {
        showClosePositionConfirmation
          ?
          <GeneralConfirmation cancelButtonHandler={() => { setShowClosePositionConfirmation(false) }} cancelButtonText={"Cancel"} confirmButtonHandler={() => { flatPosition(props.data) }} confirmButtonText={"Yes"} headerName={"Are you sure?"} message={orderConfirmationMessage} show={showClosePositionConfirmation} />
          :
          <></>
      }
    </>
  );
};

export default FlatPositionBtn;
