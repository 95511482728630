import { ConfigActionEnum } from "../../enums/ConfigActionEnum";
import { ConfigMainCategoryEnum } from "../../enums/ConfigMainCategoryEnum";
import { ConfigSubCategoryEnum } from "../../enums/ConfigSubCategoryEnum";
import { ConfigUpdate } from "../../models/ConfigUpdate";
import store from "../../store";
import { updateConfig } from "../../store/connection/connection-async-actions";

const adminUserId = store.getState().auth.authInfo?.UserId || "0";

export const changeBuyingPowerWithNats = (obj, userId) => {
  const account = new ConfigUpdate();
  account.mainCategory = ConfigMainCategoryEnum.ACCOUNT;
  account.subCategory = ConfigSubCategoryEnum.BP;
  account.action = ConfigActionEnum.MODIFY;
  account.mainId = obj.AccountId;
  account.subId = 0;
  account.modifier = parseFloat(adminUserId);
  account.value = obj.BuyingPower;

  store.dispatch(updateConfig(account, userId));
};

export const changeAccountStatusWithNats = (obj, userId) => {
  const account = new ConfigUpdate();
  account.mainCategory = ConfigMainCategoryEnum.ACCOUNT;
  account.subCategory = ConfigSubCategoryEnum.STATUS;
  account.action = ConfigActionEnum.MODIFY;
  account.mainId = obj.AccountId;
  account.subId = 0;
  account.modifier = parseFloat(adminUserId);
  account.value = obj.AccountStatusID;

  store.dispatch(updateConfig(account, userId));
};

export const changeAccountTradeLimitWithNats = (obj, userId) => {
  const account = new ConfigUpdate();
  account.mainCategory = ConfigMainCategoryEnum.ACCOUNT;
  account.subCategory = ConfigSubCategoryEnum.ACC_TRADE_LIMIT;
  account.action = ConfigActionEnum.MODIFY;
  account.mainId = obj.AccountId;
  account.subId = 0;
  account.modifier = parseFloat(adminUserId);
  account.value = 0;

  store.dispatch(updateConfig(account, userId));
};

export const addNewAccountWithNats = (obj, userId) => {
  const account = new ConfigUpdate();
  account.mainCategory = ConfigMainCategoryEnum.ACCOUNT;
  account.subCategory = ConfigSubCategoryEnum.NONE;
  account.action = ConfigActionEnum.ADD;
  account.mainId = obj.AccountId;
  account.subId = 0;
  account.modifier = parseFloat(adminUserId);
  account.value = obj.AccountId;

  store.dispatch(updateConfig(account, userId));
};

export const assignAccountToUsersWithNats = (obj, userId) => {
  const account = new ConfigUpdate();
  account.mainCategory = ConfigMainCategoryEnum.USER;
  account.subCategory = ConfigSubCategoryEnum.ACCOUNT;
  account.action = ConfigActionEnum.ADD;
  account.mainId = obj.UserId;
  account.subId = 0;
  account.modifier = parseFloat(adminUserId);
  account.value = obj.AccountId;

  store.dispatch(updateConfig(account, userId));
};

export const unAssignAccountToUsersWithNats = (obj, userId) => {
  const account = new ConfigUpdate();
  account.mainCategory = ConfigMainCategoryEnum.USER;
  account.subCategory = ConfigSubCategoryEnum.ACCOUNT;
  account.action = ConfigActionEnum.DELETE;
  account.mainId = obj.UserId;
  account.subId = 0;
  account.modifier = parseFloat(adminUserId);
  account.value = obj.AccountId;

  store.dispatch(updateConfig(account, userId));
};

export const changeAccountGroupWithNats = (obj, userId) => {
  const account = new ConfigUpdate();
  account.mainCategory = ConfigMainCategoryEnum.ACCOUNT;
  account.subCategory = ConfigSubCategoryEnum.GROUP;
  account.action = ConfigActionEnum.MODIFY;
  account.mainId = obj.AccountId;
  account.subId = 0;
  account.modifier = parseFloat(adminUserId);
  account.value = obj.GroupId;

  store.dispatch(updateConfig(account, userId));
};

export const changeAccountFirmWithNats = (obj, userId) => {
  const account = new ConfigUpdate();
  account.mainCategory = ConfigMainCategoryEnum.ACCOUNT;
  account.subCategory = ConfigSubCategoryEnum.FIRM;
  account.action = ConfigActionEnum.MODIFY;
  account.mainId = obj.AccountId;
  account.subId = 0;
  account.modifier = parseFloat(adminUserId);
  account.value = obj.FirmId;

  store.dispatch(updateConfig(account, userId));
};

export const changeAccountDestinationsWithNats = (obj, userId) => {
  const account = new ConfigUpdate();
  account.mainCategory = ConfigMainCategoryEnum.ACCOUNT;
  account.subCategory = ConfigSubCategoryEnum.DESTINATION;
  account.action = ConfigActionEnum.MODIFY;
  account.mainId = obj.AccountId;
  account.subId = 0;
  account.modifier = parseFloat(adminUserId);
  // account.value = obj.FirmId;

  store.dispatch(updateConfig(account, userId));
};

export const changeAccountBalanceWithNats = (obj, userId) => {
  const account = new ConfigUpdate();
  account.mainCategory = ConfigMainCategoryEnum.ACCOUNT;
  account.subCategory = ConfigSubCategoryEnum.BALANCE;
  account.action = ConfigActionEnum.MODIFY;
  account.mainId = obj.AccountId;
  account.subId = 0;
  account.modifier = parseFloat(adminUserId);
  account.doubleValue = obj.Balance;

  store.dispatch(updateConfig(account, userId));
};

export const changeAccountSymbolTradeLimitWithNats = (obj, userId) => {
  const account = new ConfigUpdate();
  account.mainCategory = ConfigMainCategoryEnum.ACCOUNT;
  account.subCategory = ConfigSubCategoryEnum.SYM_TRADE_LIMIT;
  account.action = ConfigActionEnum.MODIFY;
  account.mainId = obj.AccountId;
  account.subId = 0;
  account.modifier = parseFloat(adminUserId);
  account.value = obj.TradeLimit;

  store.dispatch(updateConfig(account, userId));
};

export const changeUserStatusWithNats = (obj, userId) => {
  const account = new ConfigUpdate();
  account.mainCategory = ConfigMainCategoryEnum.USER;
  account.subCategory = ConfigSubCategoryEnum.STATUS;
  account.action = ConfigActionEnum.MODIFY;
  account.mainId = obj.UserId;
  account.subId = 0;
  account.modifier = parseFloat(adminUserId);
  account.value = obj.UserStatusId;

  store.dispatch(updateConfig(account, userId));
};

export interface TodoItem {
  id: number;
  text: string;
}