import { IGroup } from "../../models/IGroup";
import { IGroupData } from "../../models/IGroupData";
import { IGroupTradeLimit } from "../../models/IGroupTradeLimit";
import { GROUPS_SET_GROUPS, GROUPS_SET_GROUP_SUPERVISORS, GROUPS_SET_GROUP_TRADE_LIMITS } from "./groups-types";
import { GroupsState } from "./GroupsState";

const initialState = new GroupsState();

export default function GroupsReducer(state = initialState, action: any): GroupsState {
  switch (action.type) {
    case GROUPS_SET_GROUPS:
      return {
        ...state,
        groups: action.payload,
        groupsData: prepareGroupsList(action.payload, state.supervisors, state.tradeLimits),
      };

    case GROUPS_SET_GROUP_SUPERVISORS:
      return {
        ...state,
        supervisors: action.payload,
        groupsData: prepareGroupsList(state.groups, action.payload, state.tradeLimits),
      };

    case GROUPS_SET_GROUP_TRADE_LIMITS:
      return {
        ...state,
        tradeLimits: action.payload,
        groupsData: prepareGroupsList(state.groups, state.supervisors, action.payload),
      };

    default:
      return state;
  }
}

function prepareGroupsList(
  groups: IGroup[],
  supervisors: any[],
  tradeLimits: IGroupTradeLimit[]
): IGroupData[] {
  const result = groups?.map((group) => ({
    group,
    supervisor: supervisors?.find((x) => x.GroupId === group.GroupId),
    tradeLimits: tradeLimits?.find((x) => x.GroupId === group.GroupId),
  }));

  return result;
}
