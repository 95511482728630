import Form from "react-bootstrap/Form";
import FlexView from "react-flexview";
import ArrowUp from "../../Images/up-arrow.png";
import Table from "../list/list";
import "./styles.scss";

const LadderView = (props) => {
  // const [gridApi, setGridApi] = React.useState<any>(null);
  // const [gridColumnApi, setGridColumnApi] = React.useState<any>(null);
  const rowData = [];

  const columnDefs = [
    {
      headerName: "Market",
      field: "market",
      // editable: true,
    },
    {
      headerName: "Size",
      field: "size"
    },
    {
      headerName: "Price",
      field: "price"
    },
    {
      headerName: "Time",
      field: "time"
    },
    // {
    //   headerName: "Price",
    //   field: "price",
    //   cellStyle: (params: any) =>
    //     params.value > 35000 ? { color: "red" } : { color: "green" },
    //   headerHeight: 10,
    // },
  ];

  // const onGridReady = async (params: any) => {
  // await setGridApi(params.api);
  // await setGridColumnApi(params.columnApi);
  // gridApi.sizeColumnToFit();
  // };
  const gridOptions = {
    rowData: rowData,
    columnDefs: columnDefs,
    // onRowClicked: (event: any) => {
    //   console.log("row Clicked", event.data);
    //   console.log("row Index", event.rowIndex);
    // },
    // rowClass: "custom-row",
    // RowEditingStartedEvent: (event: any) => {
    //   console.log("data ", event);
    // },
    // onCellValueChanged: (event: any) => {
    //   console.log(
    //     "newValue:  ",
    //     event.newValue + " Previous Value : ",
    //     event.oldValue
    //   );
    // },
    // rowClassRules: {
    //   // row style function
    //   "sick-days-warning": function (params) {
    //     var numSickDays = params.data.sickDays;
    //     return numSickDays > 1 && numSickDays <= 2;
    //   },
    //    // row style expression
    //   "sick-days-breach": "data.sickDays >= 3",
    // },
  };
  return (
    <FlexView className="ladder-view">
      <FlexView column basis="45%" style={{ padding: "10px 10px" }} className="mr-1" id="mainheaderLadderView">
        <div>
          <FlexView grow className="mb-1">
            <FlexView basis="48%">
              <Form.Group className="full-width-form">
                <Form.Label className="ladderTextLabel" style={{ marginLeft: "8px" }}>Symbol</Form.Label>
                <Form.Control type="text" className="ladderTextLabel inputStyle inputStyleColor" />
              </Form.Group>
            </FlexView>

            <FlexView basis="4%"></FlexView>

            <FlexView basis="48%">
              <Form.Group className="full-width-form">
                <Form.Label className="ladderTextLabel" style={{ marginLeft: "8px" }}>Account</Form.Label>
                <Form.Control as="select" className="ladderTextLabel inputStyleColor">
                  <option>demot2515</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Form.Group>
            </FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView>
            <FlexView basis="20%" className="ladderTextLabel" vAlignContent="center">Last</FlexView>
            <FlexView basis="30%" className="ladderTextLabel" vAlignContent="center" style={{ fontSize: "20px" }}>
              305.25 <img src={ArrowUp} alt="Arrow Up" className="ml-1" height="10" />
            </FlexView>
            <FlexView basis="10%"></FlexView>
            <FlexView basis="20%" className="ladderTextLabel" vAlignContent="center">Chg</FlexView>
            <FlexView basis="20%" className="ladderTextLabel" vAlignContent="center" style={{ color: "#3BFE82" }}>0.00</FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView className="mb-1">
            <FlexView basis="20%" className="ladderTextLabel">Bid</FlexView>
            <FlexView basis="30%" className="ladderTextLabel">304.65</FlexView>
            <FlexView basis="10%"></FlexView>
            <FlexView basis="20%" className="ladderTextLabel">Chg%</FlexView>
            <FlexView basis="20%" className="ladderTextLabel" style={{ color: "#3BFE82" }}>0.00 %</FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView className="mb-1">
            <FlexView basis="20%" className="ladderTextLabel">Ask</FlexView>
            <FlexView basis="30%" className="ladderTextLabel">305.39</FlexView>
            <FlexView basis="10%"></FlexView>
            <FlexView basis="20%" className="ladderTextLabel">Low</FlexView>
            <FlexView basis="20%" className="ladderTextLabel">0.00</FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView className="mb-1">
            <FlexView basis="20%" className="ladderTextLabel">Size</FlexView>
            <FlexView basis="30%" className="ladderTextLabel">0 x 0</FlexView>
            <FlexView basis="10%"></FlexView>
            <FlexView basis="20%" className="ladderTextLabel">High</FlexView>
            <FlexView basis="20%" className="ladderTextLabel">0.00</FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView className="mb-3">
            <FlexView basis="20%" className="ladderTextLabel">VWAP</FlexView>
            <FlexView basis="30%" className="ladderTextLabel">0.00</FlexView>
            <FlexView basis="10%"></FlexView>
            <FlexView basis="20%" className="ladderTextLabel">Vol</FlexView>
            <FlexView basis="20%" className="ladderTextLabel">0</FlexView>
          </FlexView>
        </div>

        <button className="btn mb-2 btnTimesSalesLadderView">Times & Sales</button>

        <div>
          <FlexView className="mb-3" vAlignContent="center">
            <FlexView basis="50%" className="ladderTextLabel">Type</FlexView>
            <FlexView basis="50%">
              <Form.Control as="select" className="ladderTextLabel inputStyleColor" style={{ textAlignLast: "center" }}>
                <option>LMT</option>
                <option>1</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Form.Control>
            </FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView className="mb-3" vAlignContent="center">
            <FlexView basis="50%" className="ladderTextLabel">Order Type</FlexView>
            <FlexView basis="50%">
              <Form.Control type="text" className="ladderTextLabel inputStyleColor text-center" />
            </FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView className="mb-3" vAlignContent="center">
            <FlexView basis="50%" className="ladderTextLabel">Price</FlexView>
            <FlexView basis="50%">
              <Form.Control type="number" step="0.01" className="ladderTextLabel inputStyleColor text-center" />
            </FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView className="mb-3" vAlignContent="center">
            <FlexView basis="50%" className="ladderTextLabel">Quantity</FlexView>
            <FlexView basis="50%">
              <Form.Control type="number" className="ladderTextLabel inputStyleColor text-center" />
            </FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView>
            <FlexView column basis="29%" className="mr-3">
              <button className="btn qtyBtnLadderView p-0 mb-2">100</button>
              <button className="btn qtyBtnLadderView p-0">1000</button>
            </FlexView>
            <FlexView column basis="29%" className="mr-3">
              <button className="btn qtyBtnLadderView p-0 mb-2">200</button>
              <button className="btn qtyBtnLadderView p-0">2000</button>
            </FlexView>
            <FlexView column basis="29%">
              <button className="btn qtyBtnLadderView p-0 mb-2">500</button>
              <button className="btn qtyBtnLadderView p-0">5000</button>
            </FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView className="my-3">
            <FlexView basis="50%" className="ladderTextLabel">Display Quantity</FlexView>
            <FlexView basis="50%">
              <Form.Control as="select" className="ladderTextLabel inputStyleColor" style={{ textAlignLast: "center" }}>
                <option>Order Qty</option>
                <option>1</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Form.Control>
            </FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView className="mb-3">
            <FlexView basis="50%" className="ladderTextLabel">Destination</FlexView>
            <FlexView basis="50%">
              <Form.Control as="select" className="ladderTextLabel inputStyleColor" style={{ textAlignLast: "center" }}>
                <option>1</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Form.Control>
            </FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView className="mb-2">
            <FlexView basis="50%">
              <button className="btn btn-block btnTimesSalesLadderView text-uppercase mr-1">Trial</button>
            </FlexView>
            <FlexView basis="50%">
              <button className="btn btn-block btnTimesSalesLadderView text-uppercase">N/A</button>
            </FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView className="mb-1">
            <FlexView basis="50%">
              <button className="btn btn-block btn-primary py-4 mr-1 btnTimesSalesLadderView" style={{ backgroundColor: "#04478E" }}>Buy MKT</button>
            </FlexView>
            <FlexView basis="50%">
              <button className="btn btn-block btn-danger py-4 btnTimesSalesLadderView" style={{ backgroundColor: "#C43019" }}>Sell MKT</button>
            </FlexView>
          </FlexView>
        </div>

        <div>
          <FlexView>
            <FlexView basis="50%">
              <button className="btn btn-block btn-primary py-4 mr-1 btnTimesSalesLadderView" style={{ backgroundColor: "#04478E" }}>Join BID</button>
            </FlexView>
            <FlexView basis="50%">
              <button className="btn btn-block btn-danger py-4 btnTimesSalesLadderView" style={{ backgroundColor: "#C43019" }}>Join Offer</button>
            </FlexView>
          </FlexView>
        </div>

        <button className="btn btn-block btnTimesSalesLadderView mt-2">Cancel All</button>
      </FlexView>

      <FlexView column basis="55%">
        <Table gridOptions={gridOptions} />
      </FlexView>
    </FlexView>
  );
};
export default LadderView;
