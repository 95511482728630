import FlexView from "react-flexview";
import Form from "react-bootstrap/Form";
import "./styles.scss";
const TopMovers = (props: any) => {
  return (
    <FlexView className="top-movers">
      <FlexView grow>
        <FlexView basis="10%" hAlignContent="center">
          <span>Market</span>
        </FlexView>{" "}
        <FlexView basis="90%">
          <Form.Control className="top-movers-field" as="select">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Form.Control>
          <Form.Control className="top-movers-field" as="select">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Form.Control>
          <Form.Control className="top-movers-field" as="select">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Form.Control>
        </FlexView>
      </FlexView>
    </FlexView>
  );
};
export default TopMovers;
