import store from "..";
import { IAuthInfo } from "../../models/IAuthInfo";
import { authSvc } from "../../services/auth-service";
import globalCache from "../../services/global-cache";
import tempCache from "../../services/temp-cache";
import { disconnectNats } from "../connection/connection-async-actions";
import {
  AUTH_COPY_AUTH_STATE,
  AUTH_LOGOUT_USER,
  AUTH_SET_ADMIN_PANEL_STATE,
  AUTH_SET_AUTH_INFO,
  AUTH_SET_ISADMIN,
  AUTH_SET_LOGIN_CREDENTIALS,
  AUTH_SET_LOGIN_REQUEST_IN_PROGRESS,
  AUTH_SET_LOGIN_RESPONSE,
} from "./auth-types";

export const logoutUser = () => (dispatch: any) => {

  //Close all detached instances on logout
  const {detachedWindows} = store.getState().widgets;
  detachedWindows.forEach((widget) => {
    widget.close();
  });

  authSvc.isLoggedIn$.next(false);

  tempCache.clear();
  globalCache.generalSettings = null;
  disconnectNats();
  window.history.pushState("", "", "/admin");
  dispatch({ type: AUTH_LOGOUT_USER });
  authSvc.userId$.next(0);
};

export const setAuthInfo = (authInfo: IAuthInfo) => (dispatch: any) => {
  dispatch({ type: AUTH_SET_AUTH_INFO, payload: authInfo });
};

export const setLoginCredentials = (credentials: any) => (dispatch: any) => {
  dispatch({ type: AUTH_SET_LOGIN_CREDENTIALS, payload: credentials });
};

export const setLoginRequestInProgress =
  (inProgress: boolean) => (dispatch: any) => {
    dispatch({ type: AUTH_SET_LOGIN_REQUEST_IN_PROGRESS, payload: inProgress });
  };

export const setLoginResponse = (response: any) => (dispatch: any) => {
  dispatch({ type: AUTH_SET_LOGIN_RESPONSE, payload: response });
};

export const setIsAdmin = (response: any) => (dispatch: any) => {
  dispatch({ type: AUTH_SET_ISADMIN, payload: response });
};

export const setAdminDefaultColumns = (response: any) => (dispatch: any) => {
  dispatch({ type: AUTH_SET_ADMIN_PANEL_STATE, payload: response });
};

export const copyAuthState = (data: any) => (dispatch: any) => {
  dispatch({ type: AUTH_COPY_AUTH_STATE, payload: data });
};