import { ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import moment from "moment";

const timeZone = "America/New_York";

const dateOptions: Intl.DateTimeFormatOptions = {
  timeZone,
  year: "numeric",
  month: "short",
  day: "numeric",
};

const timeOptions: Intl.DateTimeFormatOptions = {
  timeZone,
  hour12: false,
};

export const dateFormatter =
  (field: string) => (params: ValueFormatterParams) =>
    params.data[field]
      ? new Date(params.data[field]).toLocaleDateString("en-US", dateOptions)
      : "";
      
export const dateValueGetter = (field: string) => (params: ValueGetterParams) =>
  params.data[field] ? new Date(params.data[field]) : "";

export const toJsonDateTime = (date: Date) => JSON.stringify(date).replace(/"/g, '');

export const timeFormatter =
  (field: string) => (params: ValueFormatterParams | ValueGetterParams) =>
    params.data[field]
      ? new Date(params.data[field]).toLocaleTimeString("en-US", timeOptions)
      : "";

export const fixDateTimeOffset = (date: string) =>
  date.replace("+00:00", "-04:00");

export const timeValueGetter = (value: string): string =>
  value ? new Date(value).toLocaleTimeString("en-US", timeOptions) : "";

export const setTimeToUTC = (value: Date): string =>
  value ? value.toISOString().replace(/T/, " ").replace(/\..+/, "") : "";

export const getDateForLastTradingDay = (): string => {
  const today = moment();
  if (today.day() === 0) {
    return today.subtract(2, 'days').format('YYYY-MM-DD');
  } else {
    return today.subtract(1, 'days').format('YYYY-MM-DD');
  }
};

export const isValidDateRange = (startDate: string, endDate: string): boolean => {

  if(!startDate)
  {
    return false;
  }
  else if(!endDate)
  {
    return false;
  }

  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);

  // Check if start date is less than end date
  const isValidRange = momentStartDate.isBefore(momentEndDate);

  return isValidRange;
};
