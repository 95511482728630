import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "./style.scss";
import CustomLoadingOverlay from "../../time-and-sales/customLoadingOverlay";
import { ITableProps } from "../../list/ITableProps";
import LeftArrow from "../../../Images/leftarrow.svg";
import RightArrow from "../../../Images/rightarrow.svg";
import React from "react";
import { TbSearch } from "react-icons/tb";
import ContextMenu from "../../context-menu/context-menu";
import { ContextMenuOption } from "../../context-menu/context-menu-types";
import { nestedSearch } from "../../../utils/general";
import StatCard from "../../kpi-card/stat-card";
import globalCache from "../../../services/global-cache";
import { GetRowNodeIdFunc } from "ag-grid-community";

export interface KPIData {
  title: string;
  data: any;
};

type AdditionalProps = {
  primaryButtonText?: string;
  primaryButtonOnClickHandler?: Function;
  setRecordForContextMenu?: Function;
  contextMenuOptions?: ContextMenuOption[];
  gridName?: string;
  kpiData?: KPIData[];
  noKPIData?: boolean;
  windowId: string;
  hideInternalSearch?: boolean;
  rowIdSelector?: GetRowNodeIdFunc | undefined;
}

const AggridList = (props: ITableProps & AdditionalProps) => {

  const dummyKPIData = Array.from({ length: 8 }).map((x, index) => {
    return { title: `Card ${index + 1}`, data: (index + 1) * 100 }
  });
  const pageSizes = [25, 50, 100, 500];
  const kpiDataArray = props.kpiData && props.kpiData.length ? props.kpiData : [];

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ top: 0, left: 0 });
  const [searchString, setSearchString] = useState("");
  const [gridData, setGridData] = useState(props.rowData);
  const [pageSize, setPageSize] = useState<number>(25);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [visibleRows, setVisibleRows] = useState<number>(gridData ? gridData.length : 0);

  const gridRef = useRef<any>();

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
      suppressAutoSize: true,
      suppressSizeToFit: true,
      unSortIcon: true,
    };
  }, []);

  const handleCustomContextMenu = (e) => {
    if (contextMenuVisible && contextMenuPosition.left === e.event.x && contextMenuPosition.top === e.event.y) {
      setContextMenuVisible(false);
    }
    else {
      setContextMenuPosition({ left: e.event.x, top: e.event.y });
      setContextMenuVisible(true);
      if (props.setRecordForContextMenu) {
        props.setRecordForContextMenu(e.data);
      }
    }
  };

  const contextAreaSingleClickHandler = (e) => {
    setContextMenuVisible(false);
  };

  const getSearchResults = () => {
    if (props.rowData) {
      if (searchString && searchString.trim().length) {
        setGridData(nestedSearch(props.rowData, searchString));
      }
      else {
        setGridData(props.rowData);
      }
    }
  };

  const paginationNumberFormatter = useCallback((params) => {
    return '[' + params.value.toLocaleString() + ']';
  }, []);

  const onGridReady = (params: any) => {
    if (props.onGridReady) {
      props.onGridReady(params);
      gridRef.current = params;
    }

  };

  const onPageSizeChanged = (e) => {
    const value = e.target.value;
    setPageSize(Number(value));
    gridRef.current.api.paginationSetPageSize(Number(value));
  };

  const onBtNext = useCallback(() => {
    gridRef.current.api.paginationGoToNextPage();
    setCurrentPageNumber(currentPageNumber + 1);
  }, [currentPageNumber]);

  const onBtPrevious = useCallback(() => {
    gridRef.current.api.paginationGoToPreviousPage();
    setCurrentPageNumber(currentPageNumber - 1);
  }, [currentPageNumber]);

  const goToPage = useCallback((pageNumber: number) => {
    if (pageNumber !== currentPageNumber) {
      gridRef.current.api.paginationGoToPage(pageNumber);
      setCurrentPageNumber(pageNumber);
    }
  }, []);

  const saveColumnLayout = (layout) => {
    try {
      let currentLayouts: any = globalCache.agGridColumns;
      currentLayouts = currentLayouts || {};
      currentLayouts = { ...currentLayouts, [props.windowId]: layout };
      globalCache.agGridColumns = JSON.stringify(currentLayouts);
    }
    catch (e) {
      console.error(e);
    }
  };

  const onDragStopped = (event: any) => {
    const columns = event.columnApi;
    saveColumnLayout(columns.columnController.displayedColumns.map((w) => { return { field: w.colId, minWidth: w.minWidth } }));
  };

  let savedGridLayouts = globalCache.agGridColumns;
  savedGridLayouts = savedGridLayouts || {};
  let gridOptions = props.gridOptions;
  gridOptions = { ...gridOptions, rowClass: "custom-row", suppressDragLeaveHidesColumns: true, onDragStopped };

  if (savedGridLayouts && savedGridLayouts[props.windowId]) {

    let mergeColumnDefinitions: any[] = [];

    mergeColumnDefinitions = savedGridLayouts[props.windowId].map((column) => {
      const defaultDefinition = gridOptions?.columnDefs?.find((w: any) => w.field == column.field);
      if (defaultDefinition) {
        column = { ...defaultDefinition, minWidth: column.minWidth };
      }
      return column;
    });

    if (props.gridOptions && props.gridOptions.columnDefs) {
      props.gridOptions.columnDefs.forEach((w: any) => {
        if (!mergeColumnDefinitions.find((z) => z.field === w.field)) {
          mergeColumnDefinitions.push(w);
        }
      });
    }

    gridOptions = { rowClass: "custom-row", suppressDragLeaveHidesColumns: true, onDragStopped, columnDefs: mergeColumnDefinitions };
  }

  const renderPageSizeOptions = () => {
    return pageSizes.map((resultPerPage, index) => (
      <option key={index} value={resultPerPage}>{resultPerPage}</option>
    ));
  };

  const visiblePageRange = useMemo(() => {

    const totalResults = visibleRows || gridData?.length || 0;
    const numberOfPages = Math.ceil(totalResults / pageSize);
    const PageWindow = Array.from({ length: numberOfPages }).slice(0, 5).map((page, index) => {
      return (
        <>
          {
            (currentPageNumber + index + 1) > numberOfPages
              ?
              <React.Fragment></React.Fragment>
              :
              <button className={`btn-page-name ${index === 0 ? "active" : ""}`} onClick={() => { goToPage(currentPageNumber + index) }}>{currentPageNumber + index + 1}</button>
          }
        </>
      );
    });

    return (
      <>
        {
          currentPageNumber === 0
            ?
            <></>
            :
            <button className="btn-page-name px-1" onClick={onBtPrevious} disabled={currentPageNumber === 0}>
              <img src={LeftArrow} alt="" />
            </button>
        }
        {
          PageWindow
        }
        {currentPageNumber > numberOfPages - 1
          ?
          <></>
          :
          <button className="btn-page-name px-1" onClick={onBtNext} disabled={currentPageNumber >= numberOfPages - 1}>
            <img src={RightArrow} alt="" />
          </button>
        }
      </>
    )
  }, [pageSize, currentPageNumber, gridData, visibleRows]);

  const handleFilterChange = (params: any) => {
    let currentVisibleData: any[] = [];
    params.api.forEachNodeAfterFilter(function (node) {
      currentVisibleData.push(node.data);
    });
    setVisibleRows(currentVisibleData.length);
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setContextMenuVisible(false);
    });
    return () => {
      document.removeEventListener("click", () => {
        setContextMenuVisible(false);
      });
    }
  }, []);

  useEffect(() => {
    if(props.rowData && props.rowData.length)
    {
      setVisibleRows(props.rowData.length);
    }
  }, [props.rowData])
  

  return (
    <>
      {
        props.noKPIData
          ?
          <></>
          :
          <div className="row mx-0 info-table-top flex-nowrap">
            {kpiDataArray.map((data, index) => {
              return (
                <StatCard key={index} data={data.data} title={data.title} />
              )
            })}
          </div>
      }
      <div className="row px-0 mx-0 btn-main-bg">
        <div className="col-12 d-flex justify-content-between pt-auto px-0 pt-1">
          {
            props.primaryButtonText
              ?
              <div className="d-flex justify-content-start mt-auto px-0">
                <div className="btn-bg">
                  <button type="button" className="btn add-btn-form text-white" onClick={() => props.primaryButtonOnClickHandler ? props.primaryButtonOnClickHandler() : () => { }}>
                    <span className="pl-0">{props.primaryButtonText}</span>
                  </button>
                </div>
              </div>
              :
              <React.Fragment></React.Fragment>
          }
          {
            props.hideInternalSearch
              ?
              <React.Fragment></React.Fragment>
              :
              <div className=" d-flex justify-content-end mt-auto px-0 ag-grid-search-bar mb-2 mx-2">
                <input
                  type="text"
                  placeholder={`Search in ${props.gridName}`}
                  value={searchString}
                  onChange={(e) => { setSearchString(e.target.value) }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      getSearchResults();
                    }
                  }}
                />
                <TbSearch
                  className="searchIcon"
                  onClick={getSearchResults}
                />
              </div>
          }
        </div>
      </div>
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine" onClick={contextAreaSingleClickHandler} onContextMenu={(e) => { e.preventDefault() }}>
          <AgGridReact
            defaultColDef={defaultColDef}
            frameworkComponents={{ customLoadingOverlay: CustomLoadingOverlay }}
            getRowNodeId={props.rowIdSelector}
            gridOptions={gridOptions}
            onCellClicked={props.onCellClicked}
            onCellContextMenu={handleCustomContextMenu}
            onFilterChanged={handleFilterChange}
            onGridReady={onGridReady}
            onPaginationChanged={props.onPaginationChanged}
            onRowClicked={props.onRowClicked}
            onRowDoubleClicked={props.onRowDoubleClicked}
            overlayNoRowsTemplate={`<span style="padding: 10px;">${props.emptyGridMessage || "No data exist"
              }</span>`}
            pagination={props.pagination}
            paginationPageSize={pageSize}
            paginationNumberFormatter={paginationNumberFormatter}
            rowData={gridData}
            rowClassRules={props.rowClassRules}
            rowHeight={28}
            rowSelection={props.rowSelection}
          />
        </div>
        {contextMenuVisible && (
          <div >
            <ContextMenu
              menuOptions={props?.contextMenuOptions || []}
              style={{ top: contextMenuPosition.top, left: contextMenuPosition.left }}
              closeMenu={() => { setContextMenuVisible(false) }}
            />
          </div>
        )}
      </div>
      <div>
        <div className="pagination-header col-12 pl-0 pr-5 d-flex justify-content-between align-items-center">
          <div className="col-auto px-0">
            <span>Show 1 - </span>
            <select onChange={onPageSizeChanged}>
              {renderPageSizeOptions()}
            </select>
            <span>of {visibleRows} Items</span>
          </div>
          <div className="col-auto px-0">
            {visiblePageRange}
          </div>
        </div>
      </div>

    </>
  );
};

export default AggridList;
