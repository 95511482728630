import { IUserSymbol } from "../models/IUserSymbol";
import apiSvc from "./api-service";

class UserSymbolServices {
  userSymbols: IUserSymbol[] = [];

  async get(UserId?) {
    let response;
    if (UserId >= 0) {
      response = await apiSvc.get(`SelectUserSymbolsRequest?SelectKey=${UserId}&SelectColumnName=UserId`);
    } else {
      response = await apiSvc.get("SelectUserSymbolsRequest");
    }
    return (this.userSymbols = response?.data.Data);
  }

  async add(userSymbol) {
    await apiSvc.put("SaveUserSymbolsRequest", userSymbol);
  }
  async delete(userSymbolId) {
    await apiSvc.api.delete("DeleteUserSymbolsRequest", {
      params: {
        PrimaryKey: userSymbolId.primaryKey,
        UserId: userSymbolId.userId,
        LoadCache: true,
      },
    });
  }
}

const userSymbolSvc = new UserSymbolServices();
export default userSymbolSvc;
