import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import { USER_ACCOUNT_COPY_USER_ACCOUNTS, USER_ACCOUNT_SET_SELECTED_USER_ACCOUNT, USER_ACCOUNT_SET_USER_ACCOUNTS } from "./user-account-types";
import { UserAccountState } from "./UserAccountState";

const initialState = new UserAccountState();

export default function UserAccountReducer(state = initialState, action: any): UserAccountState {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new UserAccountState();

    case USER_ACCOUNT_SET_USER_ACCOUNTS:
      return {
        ...state,
        userAccountsList: action.payload,
      };

    case USER_ACCOUNT_SET_SELECTED_USER_ACCOUNT:
      return {
        ...state,
        selectedUserAccount: action.payload,
      };

    case USER_ACCOUNT_COPY_USER_ACCOUNTS:
      return action.payload;

    default:
      return state;
  }
}
