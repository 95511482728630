import { BehaviorSubject } from "rxjs";

class AuthService {
  isLoggedIn$ = new BehaviorSubject<boolean>(false);
  loginTime = new Date().getTime();
  userId$ = new BehaviorSubject<number>(0);
  firebaseUserId$ = new BehaviorSubject<string>("");
}

export const authSvc = new AuthService();
