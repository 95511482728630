import { Button, Modal } from 'react-bootstrap';
import FlexView from 'react-flexview';

const GeneralConfirmation = ({show, headerName, message, cancelButtonText, confirmButtonText, cancelButtonHandler, confirmButtonHandler}) => {
  return (
    <Modal className="confirmation-modal" backdrop="static" size={"sm"} centered keyboard={false}
        show={show} onClick={e => e.stopPropagation()}>
        <Modal.Header>
          <FlexView column hAlignContent="center" vAlignContent="center" basis="100%">
            <span>{headerName}</span>
          </FlexView>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <FlexView column hAlignContent="center" vAlignContent="center">
            <p style={{ display: "inline-block" }}>{message}</p>
          </FlexView>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <FlexView basis="100%">
            <FlexView basis="50%">
              <Button onClick={() => cancelButtonHandler()}>{cancelButtonText}</Button>
            </FlexView>
            <FlexView basis="50%" hAlignContent="right">
              <Button onClick={() => confirmButtonHandler()}>{confirmButtonText}</Button>
            </FlexView>
          </FlexView>
        </Modal.Footer>
      </Modal>
  )
}

export default GeneralConfirmation