import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import { MARGIN_SET_MARGIN } from "./margin-types";
import { MarginState } from "./MarginState";

const initialState = new MarginState();

export default function SymbolMarginReducer(state = initialState, action: any): MarginState {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new MarginState();

    case MARGIN_SET_MARGIN:
      return {
        ...state,
        symbolMargins: action.payload,
      };
      
    default:
      return state;
  }
}
