import * as React from "react";
import { Component } from "react";
import { Spinner } from "react-bootstrap";
import "./styles.scss";
class CustomSpinner extends Component<{}, {}> {
  render() {
    return (
    <div className=" d-flex justify-content-center align-items-center h-100">
    <Spinner animation="border" className="spinner-body" />
    </div>)
  }
}
export default CustomSpinner;
