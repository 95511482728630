import { DataManager, Query } from "@syncfusion/ej2-data";
import { ColumnModel } from "@syncfusion/ej2-grids";
import { DataSourceChangedEventArgs } from "@syncfusion/ej2-react-grids";
import { Component } from "react";
import { connect } from "react-redux";
import { IAppState } from "../../../store/reducers/IAppState";
import { addUsersAd, deleteUsersAd, loadUsersAds } from "../../../store/users-ads/users-ads-async-actions";
import { setTimeToUTC } from "../../../utils/datetime";
import AdminBaseList from "../base-list/admin-base-list";
import { IUsersAdsDispatchProps, IUsersAdsMappedProps, IUsersAdsProps } from "./IUsersAdsProps";

type Props = IUsersAdsProps & IUsersAdsMappedProps & IUsersAdsDispatchProps;

class UsersAds extends Component<Props, {}> {
  columnDefs: ColumnModel[];

  constructor(props: Props) {
    super(props);

    this.columnDefs = this.getColumnDefs();
  }

  private typesOfAdUrgency = ["Urgent", "Flash", "Normal"];
  private users: any = this.props.users;

  componentDidMount() {
    this.props.loadUsersAds();
  }

  render() {
    const { usersAds, users } = this.props;
    const rowData = usersAds.map((userAd) => ({
      ...userAd,
      UserName: users.find((user) => user.Id === userAd.UserId)?.UserName,
    }));

    return <AdminBaseList columnDefs={this.columnDefs} rowData={rowData} dataSourceChanged={this.dataSourceChanged} />;
  }

  getColumnDefs = (): ColumnModel[] => {
    return [
      {
        field: "UserName",
        headerText: "User Name",
        editType: "dropdownedit",
        width: "300",
        edit: {
          params: {
            actionComplete: () => true,
            allowFiltering: true,
            dataSource: new DataManager(this.users),
            fields: { text: "UserName", value: "Id" },
            query: new Query(),
          },
        },
      },
      {
        field: "Expiry",
        headerText: "Expiry",
        type: "datetime",
        format: "yyyy-MM-dd HH:mm",
        width: 200,
        editType: "dateTimePickeredit",
      },
      {
        field: "Urgency",
        headerText: "Urgency",
        editType: "dropdownedit",
        width: "170",

        edit: {
          params: {
            actionComplete: () => true,
            allowFiltering: true,
            dataSource: new DataManager(this.typesOfAdUrgency),
            query: new Query(),
          },
        },
      },
      { field: "Text", headerText: "Text", validationRules: { required: true } },
    ];
  };
  dataSourceChanged = (args: DataSourceChangedEventArgs) => {
    if (args.action === "add") {
      const newItem: any = args.data;
      console.log("Inside add", args.data);
      const obj = {
        Data: {
          UserId: newItem.UserName,
          Expiry: setTimeToUTC(newItem.Expiry),
          Frequency: 0,
          Priority: 0,
          Urgency: newItem.Urgency,
          Text: newItem.Text,
          CreatedBy: 13,
          CreatedOn: setTimeToUTC(new Date()),
          ModifiedBy: 13,
          ModifiedOn: setTimeToUTC(new Date()),
        },
        LoadCache: true,
      };
      console.log("Add Object", obj);
      this.props.addUserAd(obj);
    } else if (args.action === "edit") {
      const newItem: any = args.data;
      const obj = {
        Data: {
          UserId: newItem.UserName,
          Expiry: setTimeToUTC(newItem.Expiry),
          Frequency: 0,
          Priority: 0,
          Urgency: newItem.Urgency,
          Text: newItem.Text,
          CreatedBy: 13,
          CreatedOn: setTimeToUTC(new Date()),
          ModifiedBy: 13,
          ModifiedOn: setTimeToUTC(new Date()),
        },
        LoadCache: true,
      };
      this.props.addUserAd(obj);
    } else if (args.requestType === "delete") {
      const obj: any = args.data;
      if (obj) {
        this.props.deleteUserAd(obj[0].Id);
      }
    }
  };
}

const mapStateToProps = (state: IAppState): IUsersAdsMappedProps => ({
  usersAds: state.usersAds.userAds || [],
  users: state.users.data || [],
});

const mapDispatchToProps = (dispatch: any): IUsersAdsDispatchProps => ({
  loadUsersAds: () => dispatch(loadUsersAds),
  addUserAd: (userAd) => dispatch(addUsersAd(userAd)),
  deleteUserAd: (key) => dispatch(deleteUsersAd(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersAds);
