import { DataManager, Query } from "@syncfusion/ej2-data";
import { ColumnModel } from "@syncfusion/ej2-grids";
import {
  ColumnDirective,
  ColumnsDirective,
  DataSourceChangedEventArgs,
  Edit,
  EditSettingsModel,
  Grid,
  GridComponent,
  InfiniteScroll,
  Inject,
  Page,
  PageSettingsModel,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import * as React from "react";
import { Component } from "react";
import FlexView from "react-flexview/lib";
import brokerSvc from "../../../services/broker-services";
import destinationSvc from "../../../services/destination-service";
import strategySvc from "../../../services/strategy-service";
import { setTimeToUTC } from "../../../utils/datetime";
import CustomSpinner from "../spinner/spinner";
import { IDestinationListProps } from "./IDestinationListProps";
import { IDestinationState } from "./IDestinationState";

type Props = IDestinationListProps;

class DestinationList extends Component<Props, IDestinationState> {
  public editOptions: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Normal",
  };
  public toolbarOptions: ToolbarItems[] = ["Add", "Edit", "Delete", "Update", "Cancel", "Search"];
  public pageSettings: PageSettingsModel = { pageSize: 10 };

  private columnDefs: ColumnModel[] = [];
  private grid: Grid | null = null;

  templates: { broker: any; strategy: any } = {
    broker: (props: any) => <>{props.Broker?.BrokerName}</>,
    strategy: (props: any) => <>{props.Strategy?.Name}</>,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      destinations: [],
      brokers: [],
      strategies: [],
    };

    this.columnDefs = this.getColumnDefs();
  }

  componentDidMount() {
    if (!this.allListPopulated()) {
      destinationSvc.get().then((destinations) => this.setState({ destinations }));
      brokerSvc.get().then((brokers) => this.setState({ brokers }));
      strategySvc.get().then((strategies) => this.setState({ strategies }));
    }
  }

  allListPopulated = () => {
    const { destinations, brokers, strategies } = this.state;
    if (destinations?.length > 0 && brokers?.length > 0 && strategies?.length > 0) {
      this.columnDefs = this.getColumnDefs();
      return true;
    }
  };

  debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = Grid;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  onLoad = () => {
    this.grid?.element.addEventListener(
      "keydown",
      this.debounce((e) => {
        if (e.target.getAttribute("id").indexOf("_searchbar") !== -1) {
          this.grid?.search((e.target as HTMLInputElement).value);
        }
      }, 250)
    );
  };

  render() {
    const { destinations } = this.state;

    if (this.allListPopulated()) {
      const data = {
        result: destinations,
        count: destinations.length,
      };
      return (
        <FlexView className="hw-admin-grid ag-theme-alpine">
          <GridComponent
            id="grid"
            height="100%"
            width="100%"
            editSettings={this.editOptions}
            pageSettings={this.pageSettings}
            toolbar={this.toolbarOptions}
            dataSource={data}
            load={this.onLoad}
            allowPaging={true}
            dataSourceChanged={this.dataSourceChanged}
            enableInfiniteScrolling={true}
            ref={(g) => (this.grid = g)}
          >
            <ColumnsDirective>
              {this.columnDefs.map((column) => (
                <ColumnDirective key={column.field} headerText={column.headerText} {...column} />
              ))}
            </ColumnsDirective>
            <Inject services={[Edit, Toolbar, InfiniteScroll, Page]} />
          </GridComponent>
        </FlexView>
      );
    } else {
      return (
        <div className="m-auto">
          <CustomSpinner />
        </div>
      );
    }
  }

  getColumnDefs = (): ColumnModel[] => {
    const { brokers, strategies } = this.state;
    return [
      {
        field: "BrokerId",
        headerText: "Broker",
        editType: "dropdownedit",
        width: "150",
        template: this.templates.broker,
        edit: {
          params: {
            actionComplete: () => true,
            allowFiltering: true,
            dataSource: new DataManager(brokers),
            fields: { text: "BrokerName", value: "BrokerId" },
            query: new Query(),
          },
        },
        validationRules: { required: true },
      },
      {
        field: "StrategyId",
        headerText: "Strategy",
        template: this.templates.strategy,
        editType: "dropdownedit",
        width: "150",
        edit: {
          params: {
            actionComplete: () => true,
            allowFiltering: true,
            dataSource: new DataManager(strategies),
            fields: { text: "Name", value: "StrategyId" },
            query: new Query(),
          },
        },
        validationRules: { required: true },
      },
      {
        field: "BuyTags",
        headerText: "Buy Tags",
        validationRules: { required: true },
      },
      {
        field: "SellTags",
        headerText: "Sell Tags",
        validationRules: { required: true },
      },
      {
        field: "ShortTags",
        headerText: "Short Tags",
        validationRules: { required: true },
      },
    ];
  };
  dataSourceChanged = (args: DataSourceChangedEventArgs) => {
    const item: any = args.data;
    let obj: any;

    if (args.action === "add") {
      obj = {
        Data: {
          BrokerId: item.BrokerId,
          StrategyId: item.StrategyId,
          BuyTags: item.BuyTags,
          SellTags: item.SellTags,
          ShortTags: item.ShortTags,
          CreatedBy: 13,
          CreatedOn: setTimeToUTC(new Date()),
          ModifiedBy: 13,
          ModifiedOn: setTimeToUTC(new Date()),
        },
        LoadCache: true,
      };
      destinationSvc.add(obj);
      destinationSvc.get().then((destinations) => this.setState({ destinations }));
    } else if (args.action === "edit") {
      obj = {
        Data: {
          DestinationId: item.DestinationId,
          BrokerId: item.BrokerId,
          StrategyId: item.StrategyId,
          BuyTags: item.BuyTags,
          SellTags: item.SellTags,
          ShortTags: item.ShortTags,
          CreatedBy: 13,
          CreatedOn: setTimeToUTC(new Date()),
          ModifiedBy: 13,
          ModifiedOn: setTimeToUTC(new Date()),
        },
        LoadCache: true,
      };
      destinationSvc.add(obj);
      destinationSvc.get().then((destinations) => this.setState({ destinations }));
    } else if (args.requestType === "delete") {
      if (item) {
        destinationSvc.delete(item[0].DestinationId);
        destinationSvc.get().then((destinations) => this.setState({ destinations }));
      }
    }
  };
}

export default DestinationList;
