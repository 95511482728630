export class WidgetTitles {
  public static AccountInfo = "Account Info";
  public static Chart = "Chart";
  public static LadderView = "Ladder View";
  public static LevelII = "Level II View";
  public static Orders = "Orders";
  public static Positions = "Positions";
  public static QuickTrade = "Quick Trade";
  public static TimeAndSales = "Trades";
  public static TopMovers = "Top Movers";
  public static WatchList = "Watchlist";
  public static News = "News";
  public static Users = "Users";
  public static Accounts = "Accounts";
  public static Groups = "Groups";
  public static Firms = "Firms";
  public static AccountPerformance = "Account Watch";
  public static AdminSymbol = "Admin Symbols";
  public static GroupPositions = "Group Watch";
  public static Brokers = "Brokers";
  public static RoutingStratgites = "Routing Stratgites";
  public static Broadcasting = "Broadcasting";
  public static Destinations = "Destinations";
  public static Settings = "Settings";
  public static AutoFlatter = "Auto Flatter";
  public static Fees = "Fees";
  public static Form = "Form";
  public static Reports = "Reports";
  public static AuditTrail = "Audit Trail";
}
