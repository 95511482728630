export const defaultAccountColumns = {
  isName: false,
  isStatus: false,
  isCurrency: false,
  isBorrow: false,
  isFee: false,
};

export const updateAccountColumnsForAdminRole = () => {
  const columns = { ...defaultAccountColumns };
  Object.keys(columns).forEach((key) => (columns[key] = true));
  return columns;
};

export const updateAccountColumnsForRiskManagerRole = () => {
  const columns = { ...defaultAccountColumns };
  return columns;
};
