import { useEffect, useRef, useState } from 'react';
import AGGridServerComponent from '../admin/ag-grid-list/ag-grid-server';
import { ColumnDefinitions } from './ColumnDefinitions';
import { GridApi, GridReadyEvent, RowDataTransaction } from 'ag-grid-community';
import { getPositions } from '../../store/user-accounts/user-account-async-action';
import { areArraysOfObjectsIdentical, buildQueryString, compareAndUpdateLists } from '../../utils/general';
import store from '../../store';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store/reducers/IAppState';
import { KPIData } from '../admin/ag-grid-list/aggrid-list';
import { formatNumberForKPI } from '../../utils/number';
import moment from 'moment';
import IndexedDBService from '../../utils/idb_helper';
import { subscribeQuoteMediaData, unsubscribeQuoteMediaData } from '../../store/quote-media/quote-media-async-actions';

type PositionBlotterProps = {
  windowId: string
}

type GridFilter = {
  [key: string]: number | string
}

let lastAPICallTimeStamp = "";
let WebServiceKPIs = {
  totalVolume: 0,
  totalPositions: 0,
  totalLongExposure: 0,
  totalShortExposure: 0,
  totalBuyingPowerMax: 0,
  totalBuyingPowerMin: 0,
  totalSymbolPositions: 0,
};
let subscribedSymbols: string[] = []; //Store the symbol to subscribe market data for non-zero position in order to calculate pnl

const POSITIONS_STATS = ["Total Position", "Total Volume", "Long Exposure", "Short Exposure", "Open PnL", "Close PnL", "Max Buying Power Used", "Min Buying Power Used"];


const PositionBlotter = ({ windowId }: PositionBlotterProps) => {

  const columnDefs = ColumnDefinitions();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageSize, setPageSize] = useState(50);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [filters, setfilters] = useState<GridFilter>();
  const [kpiDataArray, setKpiDataArray] = useState<KPIData[]>([]);

  const totalVolumeRef = useRef(0);
  const totalPositionsRef = useRef(0);
  const totalLongExposureRef = useRef(0);
  const totalShortExposureRef = useRef(0);
  const totalBuyingPowerMaxRef = useRef(0);
  const totalBuyingPowerMinRef = useRef(0);
  const totalSymbolPositionsRef = useRef(0);
  const totalOpenPnLRef = useRef(0);
  const totalClosePnLRef = useRef(0);

  const userAccounts = useSelector((state: IAppState) => state.userAccountsList.userAccountsList);

  const positionsKPIData = () => {
    const stats: KPIData[] = [];

    POSITIONS_STATS.forEach((param) => {

      let data;

      switch (param) {
        case "Total Position":
          data = formatNumberForKPI(totalPositionsRef.current || 0);
          break;
        case "Total Volume":
          data = formatNumberForKPI(totalVolumeRef.current || 0);
          break;
        case "Long Exposure":
          data = formatNumberForKPI(totalLongExposureRef.current || 0, true);
          break;
        case "Short Exposure":
          data = formatNumberForKPI(totalShortExposureRef.current || 0, true);
          break;
        case "Open PnL":
          data = formatNumberForKPI(totalOpenPnLRef.current || 0, true);
          break;
        case "Close PnL":
          data = formatNumberForKPI(totalClosePnLRef.current || 0, true);
          break;
        case "Max Buying Power Used":
          data = formatNumberForKPI(totalBuyingPowerMaxRef.current || 0, true);
          break;
        case "Min Buying Power Used":
          data = formatNumberForKPI(totalBuyingPowerMinRef.current || 0, true);
          break;
      }

      stats.push({
        title: param,
        data
      });

    });

    return stats;
  };

  const filterExternalData = (data: any[]): any[] => {
    if (!gridApi || !gridApi.getFilterModel() || Object.keys(gridApi.getFilterModel()).length === 0) {
      return data; // Return original data if no filters are applied
    }

    const currentAppliedFilters = gridApi.getFilterModel();

    return data.filter(row => {
      let flag = true;

      Object.keys(row).forEach((key) => {
        const isFilterDefined = typeof currentAppliedFilters[key] !== "undefined";
        const isRowDefined = typeof row[key] !== "undefined";

        const isFilterMismatch = isFilterDefined && row[key] != currentAppliedFilters[key].filter;
        const isStringAndNotIncluded = isFilterDefined && isRowDefined && typeof row[key] === "string" && !row[key].toLowerCase().trim().includes(currentAppliedFilters[key].filter.toLowerCase().trim());

        const isFilterFailed = isFilterDefined && isRowDefined && (isFilterMismatch || isStringAndNotIncluded);

        if (isFilterFailed) {
          flag = false;
          return;
        }

      });

      if (flag) {
        return row;
      }
    });
  };


  const updateKPIData = async () => {
    try {
      if (!lastAPICallTimeStamp) {
        return;
      }
      let symbolPositions = await IndexedDBService.getObjectStore("SymbolPositions");
      symbolPositions = filterExternalData(symbolPositions);
      let accountPositions = await IndexedDBService.getObjectStore("AccountPositions");
      accountPositions = filterExternalData(accountPositions);

      let updatedKPI = {
        totalVolume: 0,
        totalPositions: 0,
        totalLongExposure: 0,
        totalShortExposure: 0,
        totalBuyingPowerMax: 0,
        totalBuyingPowerMin: 0,
        totalSymbolPositions: 0,
        totalOpenPnL: 0,
        totalClosePnL: 0,
      };
      accountPositions.forEach((accountPosition) => {
        updatedKPI.totalBuyingPowerMax += Number(accountPosition.MaxBPUsed);
        updatedKPI.totalBuyingPowerMin += Number(accountPosition.MinBPUsed);
        let accountAllSymbols = symbolPositions.filter((symbol) => symbol.AccountId === accountPosition.AccountId);
        accountAllSymbols.forEach(async (accountSymbolPosition) => {
          if(!subscribedSymbols.includes(accountSymbolPosition.Symbol) && Math.abs(accountSymbolPosition.Position))
          {
            store.dispatch(subscribeQuoteMediaData("Global", accountSymbolPosition.Symbol));
          }
          else if(!Math.abs(accountSymbolPosition.Position) && subscribedSymbols.includes(accountSymbolPosition.Symbol))
          {
            store.dispatch(unsubscribeQuoteMediaData("Global", accountSymbolPosition.Symbol));
          }
          updatedKPI.totalVolume += Number(accountSymbolPosition.TotalPosition);
          updatedKPI.totalPositions += Math.abs(Number(accountSymbolPosition.Position));
          updatedKPI.totalLongExposure += Number(accountSymbolPosition.LongExposure);
          updatedKPI.totalShortExposure += Number(accountSymbolPosition.ShortExposure);
          updatedKPI.totalOpenPnL += Number(accountSymbolPosition.UnrealPL);
          updatedKPI.totalClosePnL += Number(accountSymbolPosition.RealPL);
        });
      });
      totalPositionsRef.current = updatedKPI.totalPositions;
      totalVolumeRef.current = updatedKPI.totalVolume;
      totalLongExposureRef.current = updatedKPI.totalLongExposure;
      totalShortExposureRef.current = updatedKPI.totalShortExposure;
      totalBuyingPowerMaxRef.current = updatedKPI.totalBuyingPowerMax;
      totalBuyingPowerMinRef.current = updatedKPI.totalBuyingPowerMin;
      totalClosePnLRef.current = updatedKPI.totalClosePnL;
      totalOpenPnLRef.current = updatedKPI.totalOpenPnL;
    }
    catch (e) {
      console.error(e);
    }
    setKpiDataArray(positionsKPIData());
  };

  const updateGrid = async (rowsTobeAdded: any[], rowsTobeUpdated: any[], rowsTobeDeleted: any[]) => {

    if (!rowsTobeAdded.length && !rowsTobeDeleted.length && rowsTobeUpdated.length) {
      let currentRows = getCurrentVisibleRows();
      currentRows = currentRows.filter((w) => rowsTobeUpdated.find((x) => x.Id === w.Id));
      if (areArraysOfObjectsIdentical(currentRows, rowsTobeUpdated, "Id")) {
        return;
      }
    }

    const transaction: RowDataTransaction = {
    };

    let accountsMap;

    if (userAccounts.length) {
      accountsMap = userAccounts.reduce((acc, obj) => acc.set(obj.AccountId, obj), new Map());
    }

    if(!accountsMap)
    {
      return;
    }

    rowsTobeAdded.forEach((position) => {
      const userAccount = accountsMap.get(position.AccountId);
      if (userAccount) {
        position.AccountName = userAccount?.Account?.Name;
        position.UserName = userAccount?.User?.UserName;
      }
    });

    rowsTobeUpdated.forEach((position) => {
      const userAccount = accountsMap.get(position.AccountId);
      if (userAccount) {
        position.AccountName = userAccount?.Account?.Name;
        position.UserName = userAccount?.User?.UserName;
      }
    });

    if (rowsTobeAdded.length) {
      transaction.add = rowsTobeAdded;
      transaction.addIndex = 0;
    }
    if (rowsTobeUpdated.length) {
      transaction.update = rowsTobeUpdated;
    }
    if (rowsTobeDeleted.length) {
      transaction.remove = rowsTobeDeleted;
    }

    if (gridApi && gridApi.applyTransaction) {
      gridApi.applyTransaction(transaction);
      const nodes = gridApi.getRenderedNodes();
      gridApi.refreshCells({
        force: true,
        rowNodes: nodes,
        columns: ["Close"],
      });
      updateKPIData();
    }
  };

  const getCurrentVisibleRows = () => {
    let allVisibleRows: any[] = [];
    if (gridApi) {
      gridApi.forEachNodeAfterFilterAndSort(node => {
        if (node.displayed) {
          allVisibleRows.push(node.data);
        }
      });
    }
    return allVisibleRows;
  };

  const fetchData = async (page
    : number) => {
    try {
      const { authInfo } = store.getState().auth;
      if (!authInfo) {
        return;
      }

      let queryParams = {
        UserId: authInfo.UserId,
        Role: authInfo.Meta.RoleName,
        CurrentPage: page,
        PageSize: pageSize
      };

      if (filters && Object.keys(filters).length) {
        queryParams = { ...queryParams, ...filters };
      }
      setIsLoadingData(true);
      const positionsResponse = await getPositions(buildQueryString(queryParams));
      WebServiceKPIs.totalBuyingPowerMax = positionsResponse.TotalBuyingPowerMax;
      WebServiceKPIs.totalBuyingPowerMin = positionsResponse.TotalBuyingPowerMin;
      WebServiceKPIs.totalLongExposure = positionsResponse.TotalLongExposure;
      WebServiceKPIs.totalPositions = positionsResponse.TotalPositions;
      WebServiceKPIs.totalShortExposure = positionsResponse.TotalShortExposure;
      WebServiceKPIs.totalSymbolPositions = positionsResponse.TotalSymbolPositions;
      WebServiceKPIs.totalVolume = positionsResponse.TotalVolume;

      totalBuyingPowerMaxRef.current = positionsResponse.TotalBuyingPowerMax;
      totalBuyingPowerMinRef.current = positionsResponse.TotalBuyingPowerMin;
      totalLongExposureRef.current = positionsResponse.TotalLongExposure;
      totalPositionsRef.current = positionsResponse.TotalPositions;
      totalShortExposureRef.current = positionsResponse.TotalShortExposure;
      totalSymbolPositionsRef.current = positionsResponse.TotalSymbolPositions;
      totalVolumeRef.current = positionsResponse.TotalVolume;

      setTotalPages(positionsResponse.TotalSymbolPositions);
      lastAPICallTimeStamp = moment().format("YYYY-MM-DD HH:mm:ss");
      updateGrid(positionsResponse.symbolsPositions, [], getCurrentVisibleRows());

    }
    catch (e) {
      console.error(e);
    }
    finally {
      setIsLoadingData(false);
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const handleFilterChange = (e) => {
    if (gridApi) {
      const filterModel = gridApi.getFilterModel();
      let currentFilters: any = {};

      if (filterModel && Object.keys(filterModel).length) {
        Object.keys(filterModel).forEach((key) => {
          if (filterModel[key] && filterModel[key].filter) {
            switch (key) {

            }
          }
        });
      }

      if (currentFilters && Object.keys(currentFilters).length) {
        setfilters(currentFilters);
      }
      else {
        setfilters({});
      }
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchData(newPage);
  };

  useEffect(() => {
    if (gridApi) {
      fetchData(1);
      setCurrentPage(1);
    }
  }, [pageSize, filters, gridApi]);

  useEffect(() => {
    if (gridApi) {
      const gridUpdateInterval = setInterval(async () => {
        let currentSymbolPositions = getCurrentVisibleRows();
        let symbolPositions = await IndexedDBService.getObjectStore("SymbolPositions");
        symbolPositions = filterExternalData(symbolPositions).slice(0, pageSize - 1);
        let { rowsTobeAdded, rowsTobeDeleted, rowsTobeUpdated } = compareAndUpdateLists(currentSymbolPositions, symbolPositions, "Id", pageSize);
        updateGrid(rowsTobeAdded, rowsTobeUpdated, rowsTobeDeleted);
      }, 250);

      return () => {
        clearInterval(gridUpdateInterval);
      };
    }

  }, [gridApi])


  return (
    <>
      <AGGridServerComponent
        isLoadingGridData={isLoadingData}
        columnDefs={columnDefs}
        currentPage={currentPage}
        gridApi={gridApi}
        onFilterChanged={handleFilterChange}
        onPageChange={handlePageChange}
        paginationPageSize={pageSize}
        onGridReady={onGridReady}
        rowData={[]}
        totalPages={totalPages}
        totalResults={totalSymbolPositionsRef.current || 0}
        key={windowId}
        setPageSize={setPageSize}
        kpiData={kpiDataArray}
        showKPIData={true}
        rowIdSelector={(data) => data.Id}
      />
    </>
  )
}

export default PositionBlotter
