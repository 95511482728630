import {
  default as apiService,
  default as apiSvc,
} from "../../services/api-service";
import notificationSvc from "../../services/notification-service";
import { setTimeToUTC } from "../../utils/datetime";
import { setFirms, setFirmTradeLimits } from "./firms-actions";

export async function loadFirms(dispatch: any) {
  const firmsResponse = await apiService.get("SelectFirmsRequest");
  if(firmsResponse && firmsResponse.data && firmsResponse.data.Data)
  {
    dispatch(setFirms(firmsResponse.data.Data))
  }
  const firmsTradeLimitResponse = await apiService.get("SelectFirmTradeLimitsRequest");
  if(firmsTradeLimitResponse && firmsTradeLimitResponse.data && firmsTradeLimitResponse.data.Data)
  {
    dispatch(setFirmTradeLimits(firmsTradeLimitResponse.data.Data));
  }
  return {firmsResponse: firmsResponse?.data?.Data, firmsTradeLimitResponse: firmsTradeLimitResponse?.data?.Data};
}

export const addFirms = (firmData) => async (dispatch, getState) => {
  const firmsData = {
    FirmId: firmData.Data.FirmId,
    FirmTypeId: firmData.Data.FirmTypeId,
    Name: firmData.Data.Name,
    Address: firmData.Data.Address,
    CityCountry: firmData.Data.CityCountry,
    PostCode: firmData.Data.PostCode,
    Comments: firmData.Data.Comments,
    Description: firmData.Data.Description,
    RiskControlIsEnabled: firmData.Data.RiskControlIsEnabled,
    CreatedBy: 13,
    CreatedOn: setTimeToUTC(new Date()),
    ModifiedBy: 13,
    ModifiedOn: setTimeToUTC(new Date()),
  };
  const { list } = getState().firms;
  let saveFirm;
  if (firmsData.FirmId === undefined) {
    saveFirm = list.some(
      (i) => i.Name.toLowerCase() === firmsData.Name.toLowerCase()
    );
  } else {
    const filteredFirms = list.filter(
      (x) => x.Name.toLowerCase() === firmsData.Name.toLowerCase()
    );
    if (filteredFirms.length <= 1) {
      saveFirm = false;
    }
  }

  if (saveFirm === false) {
    const firmsResponse = await apiSvc.put("SaveFirmsRequest", firmsData);

    const tradeLimitData = {
      FirmId: firmsResponse.data.PrimaryKey,
      BuyingPower: firmData.Data.FirmTradeLimit.BuyingPower,
      CreatedBy: firmData.Data.FirmTradeLimit.CreatedBy,
      CreatedOn: firmData.Data.FirmTradeLimit.CreatedOn,
      EnFirmBp: firmData.Data.FirmTradeLimit.EnFirmBp,
      EnFirmMaxLoss: firmData.Data.FirmTradeLimit.EnFirmMaxLoss,
      EnFirmMaxPos: firmData.Data.FirmTradeLimit.EnFirmMaxPos,
      EnRejectWashTradeWithinFirm:
        firmData.Data.FirmTradeLimit.EnRejectWashTradeWithinFirm,
      EnUrlzLinkToBp: firmData.Data.FirmTradeLimit.EnUrlzLinkToBp,
      EnUrlzLinkToMaxLoss: firmData.Data.FirmTradeLimit.EnUrlzLinkToMaxLoss,
      MaxLoss: firmData.Data.FirmTradeLimit.MaxLoss,
      MaxPosition: firmData.Data.FirmTradeLimit.MaxPosition,
      ModifiedBy: firmData.Data.FirmTradeLimit.ModifiedBy,
      ModifiedOn: firmData.Data.FirmTradeLimit.ModifiedOn,
    };

    const FirmsTradeRes = await apiSvc.put(
      "SaveFirmTradeLimitsRequest",
      tradeLimitData
    );

    if (FirmsTradeRes !== undefined) {
      dispatch(loadFirms);
    }
  } else {
    notificationSvc.error("Duplicate Firms Not Allowed!");
    dispatch(loadFirms);
  }
};
export const deleteFirms = (delKey) => async (dispatch: any) => {
  const params = {
    PrimaryKey: delKey,
    LoadCache: true,
  };

  apiSvc.api
    .delete("DeleteFirmTradeLimitsRequest", { params })
    .then(() =>
      apiSvc.api
        .delete("DeleteFirmsRequest", { params })
        .then(() => dispatch(loadFirms))
    );
};
