import { IUserAd } from "../../models/IUserAd";
import { IUser } from "../../models/IUser";
import {
  USERS_SET_ADS,
  USERS_SET_USERS,
  USERS_SET_SEARCH_PARAMS,
  USERS_SHOW_EDIT_FORM,
  USERS_SET_SEARCH_RESULTS,
  USERS_UPDATE_SEARH_RESULTS,
  USERS_UPDATE_ONLINE_USERS_LIST,
  USERS_COPY_USERS_STATE,
} from "./users-types";
import apiSvc from "../../services/api-service";
import store from "..";

export const setUsers = (users: IUser[] | undefined) => (dispatch: any) =>
  dispatch({ type: USERS_SET_USERS, payload: users });

export const setUserAds = (ads: IUserAd[]) => (dispatch: any) =>
  dispatch({ type: USERS_SET_ADS, payload: ads });

export const setSearchParams = (setSearchParams: any) => (dispatch: any) =>
  dispatch({ type: USERS_SET_SEARCH_PARAMS, payload: setSearchParams });

export const showEditForm = (flag: any) => (dispatch: any) =>
  dispatch({ type: USERS_SHOW_EDIT_FORM, payload: flag });

export const setSearchResults =
  (searchText: any, criteria: any) => async (dispatch: any) => {
    const userId = store.getState().auth.authInfo?.UserId;
    const searchResults = await apiSvc.get(
      `GlobalSearchRequest?UserId=${userId}&SearchText=${searchText}&Criteria=${criteria}`
    );
    if (searchResults?.data && searchResults.data.IsSuccess) {
      dispatch({ type: USERS_SET_SEARCH_RESULTS, payload: searchResults.data });
    } else {
      dispatch({ type: USERS_SET_SEARCH_RESULTS, payload: [] });
    }
  };

export const updateSearchResults = (updatedRecord: any) => (dispatch: any) =>
  dispatch({ type: USERS_UPDATE_SEARH_RESULTS, payload: updatedRecord });

export const updateOnlineUsersArrayList = (userId: number, status: boolean) => (dispatch: any) =>
  dispatch({ type: USERS_UPDATE_ONLINE_USERS_LIST, payload: {userId, status} });

export const updateUsersState = (data) => (dispatch: any) =>
  dispatch({ type: USERS_COPY_USERS_STATE, payload: data });