import { LEVEL_II_CLEAR_TIME_AND_SALES_FLAG, LEVEL_II_COPY_STATE, LEVEL_II_OPEN_TIME_AND_SALES } from "./level-ii-types";
import { LevelIIState } from "./LevelIIState";

const initialState = new LevelIIState();

export default function levelIIReducer(state = initialState, action: any): LevelIIState {
  switch (action.type) {
    case LEVEL_II_CLEAR_TIME_AND_SALES_FLAG:
      return {
        ...state,
        openTimeAndSales: false,
        symbol: "",
      };

    case LEVEL_II_OPEN_TIME_AND_SALES:
      return {
        ...state,
        ...action.payload,
      };

    case LEVEL_II_COPY_STATE:
      return action.payload;

    default:
      return state;
  }
}
