import { AccountStatusEnum } from "../enums/AccountStatusEnum";
import { NatsMessageTypeEnum } from "../enums/NatsMessageTypeEnum";
import { UserStatusEnum } from "../enums/UserStatusEnum";
import {
  doubleFromUint8Array,
  int32FromUint8Array,
  numberFromUint8Array,
} from "../utils/decoder";
import { IAccountPosition } from "./IAccountPosition";

export class AccountPositionResponse {
  private static readonly _offset = 3;

  private _buffer: Uint8Array;

  constructor(buffer: Uint8Array) {
    this._buffer = buffer;
  }

  public get size(): number {
    return numberFromUint8Array(this._buffer, 0);
  }

  public get messageType(): NatsMessageTypeEnum {
    return numberFromUint8Array(this._buffer, 1);
  }

  public get process(): number {
    return numberFromUint8Array(this._buffer, 2);
  }

  public get position(): number {
    return int32FromUint8Array(this._buffer, AccountPositionResponse._offset);
  }

  public get sharesTraded(): number {
    return int32FromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 8
    );
  }

  public get realPnL(): number {
    return doubleFromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 16
    );
  }

  public get unrealPnL(): number {
    return doubleFromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 24
    );
  }

  public get bpUsed(): number {
    return doubleFromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 32
    );
  }

  public get exposure(): number {
    return doubleFromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 40
    );
  }

  public get balance(): number {
    return doubleFromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 48
    );
  }

  public get userStatus(): UserStatusEnum {
    return numberFromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 56
    );
  }

  public get accountStatus(): AccountStatusEnum {
    return numberFromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 57
    );
  }

  public get firmId(): number {
    return int32FromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 58
    );
  }

  public get groupId(): number {
    return int32FromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 62
    );
  }

  public get userId(): number {
    return int32FromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 66
    );
  }

  public get accountId(): number {
    return int32FromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 70
    );
  }

  public get roundTrades(): number {
    return int32FromUint8Array(
      this._buffer,
      AccountPositionResponse._offset + 74
    );
  }

  public getAccountPosition(): IAccountPosition {
    return {
      AccountId: this.accountId,
      BPUsed: this.bpUsed,
      Exposure: this.exposure,
      MaxLoss: 0,
      Balance: this.balance,
      Position: this.position,
      RealPL: this.realPnL,
      QtyTraded: this.sharesTraded,
      UnrealPL: this.unrealPnL,
      TotalPL: this.realPnL + this.unrealPnL,
      DrawDown: 0,
      RunUp: 0,
      RoundTrades: this.roundTrades,
    };
  }

  public test() {
    let print = "";
    Array.from(this._buffer).forEach((byte, index) => {
      print += index + " = " + byte + "\n";
    });

    console.log(print);

    console.log();
    console.log("size", this.size);
    console.log("messageType", this.messageType);
    console.log("process", this.process);
    console.log("position", this.position);
    console.log("sharedTraded", this.sharesTraded);
    console.log("realPnl", this.realPnL);
    console.log("unrealPnl", this.unrealPnL);
    console.log("bpUsed", this.bpUsed);
    console.log("exposure", this.exposure);
    // console.log("maxLoss", this.maxLoss);
    console.log("userStatus", this.userStatus);
    console.log("accountStatus", this.accountStatus);
    console.log("firmId", this.firmId);
    console.log("groupId", this.groupId);
    console.log("userId", this.userId);
    console.log("accountId", this.accountId);
  }
}
