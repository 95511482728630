import globalCache from "../services/global-cache";
import notificationSvc from "../services/notification-service";

type ObjectWithKey = { [key: string]: any };

type GenericObject = { [key: string]: any };

export const nestedSearch = (data: any[], searchString: string): any[] =>
  data.filter((item) => {
    const searchInObject = (obj: any): boolean =>
      Object.values(obj).some((value) =>
        typeof value === "string"
          ? value.toLowerCase().includes(searchString.toLowerCase())
          : typeof value === "object" && value !== null && searchInObject(value)
      );

    return searchInObject(item);
  });

export const copyToClipboard = (gridApiRef: any) => {
  const params = {
    allColumns: true,
    onlySelected: false,
    skipHeader: false,
    columnSeparator: "\t",
    rowSeparator: "\n",
    customHeader: null,
    customFooter: null,
  };

  const data = gridApiRef.current.getDataAsCsv(params);

  navigator.clipboard.writeText(data).then(
    () => {
      notificationSvc.info("Data copied to clipboard");
    },
    (err) => {
      notificationSvc.error("Unable to copy to clipboard");
    }
  );
};

export const removeDuplicatesByKey = (
  arr: ObjectWithKey[],
  key: string
): ObjectWithKey[] => {
  const uniqueMap = new Map();

  for (const obj of arr) {
    const keyValue = obj[key];

    if (!uniqueMap.has(keyValue)) {
      uniqueMap.set(keyValue, obj);
    }
  }

  return Array.from(uniqueMap.values());
};

export const areArraysIdentical = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const frequencyCounter = {};

  for (const element of arr1) {
    frequencyCounter[element] = (frequencyCounter[element] || 0) + 1;
  }

  for (const element of arr2) {
    if (!frequencyCounter[element]) {
      return false;
    }
    frequencyCounter[element] -= 1;
  }

  return true;
};

export const areArraysOfObjectsIdentical = (
  PreviousArray: Array<any>,
  CurrentArray: Array<any>,
  ArrayKeyIdentifier: string,
  keysToIgnore: string[] = [] // Optional array of keys to ignore
): boolean => {
  // Check if the arrays have the same length
  if (PreviousArray.length !== CurrentArray.length) {
    return false;
  }

  // Create a Map for the PreviousArray using the ArrayKeyIdentifier
  const previousMap = new Map(
    PreviousArray.map((item) => [item[ArrayKeyIdentifier], item])
  );

  // Loop through the CurrentArray to check if all keys exist in the PreviousArray
  for (const currentItem of CurrentArray) {
    const key = currentItem[ArrayKeyIdentifier];
    if (!previousMap.has(key)) {
      return false;
    }

    const previousItem = previousMap.get(key);

    // Compare objects manually by iterating through their keys
    for (const prop in currentItem) {
      if (
        !keysToIgnore.includes(prop) &&
        currentItem[prop] !== previousItem[prop]
      ) {
        return false;
      }
    }
  }

  // If all checks pass, the arrays are identical
  return true;
};

export const isDebounceTimeExceeded = (
  lastUpdatedAt: number,
  debounceTime: number
): boolean => {
  const currentTime = Date.now();
  return currentTime - lastUpdatedAt > debounceTime;
};

export const buildQueryString = (
  paramsObj: Record<string, string | number>
): string => {
  const queryString = Object.entries(paramsObj)
    .map(([key, value]) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value.toString());
      return `${encodedKey}=${encodedValue}`;
    })
    .join("&");
  return queryString;
};

export const cleanUpMap = <K, V>(
  map: Map<K, V>,
  entries: number
): Map<K, V> => {
  const desiredEntries = Array.from(map.entries()).slice(0, entries);

  map.clear();
  desiredEntries.forEach(([key, value]) => map.set(key, value));

  return map;
};

export const compareAndUpdateLists = (
  currentList: GenericObject[],
  updatedList: GenericObject[],
  key: string,
  bufferSize?: number
): {
  rowsTobeAdded: GenericObject[];
  rowsTobeUpdated: GenericObject[];
  rowsTobeDeleted: GenericObject[];
} => {
  if (currentList.length && !updatedList.length) {
    return {
      rowsTobeAdded: [],
      rowsTobeUpdated: currentList,
      rowsTobeDeleted: [],
    };
  } else if (!currentList.length && updatedList.length) {
    return {
      rowsTobeAdded: updatedList,
      rowsTobeUpdated: [],
      rowsTobeDeleted: [],
    };
  } else {
    const currentListKeys = new Set(currentList.map((item) => item[key]));
    const updatedListKeys = new Set(updatedList.map((item) => item[key]));

    const rowsTobeAdded = updatedList.filter(
      (item) => !currentListKeys.has(item[key])
    );
    const rowsTobeDeleted =
      bufferSize && currentList.length + rowsTobeAdded.length >= bufferSize
        ? currentList.filter((item, index) => index >= (bufferSize - rowsTobeAdded.length) && !updatedListKeys.has(item[key]))
        : [];
    const rowsTobeUpdated = updatedList.filter(
      (item) => currentListKeys.has(item[key]) && updatedListKeys.has(item[key])
    );

    return { rowsTobeAdded, rowsTobeUpdated, rowsTobeDeleted };
  }
};
