import { ExecTypeEnum } from "../enums/ExecTypeEnum";
import { NatsMessageTypeEnum } from "../enums/NatsMessageTypeEnum";
import { OrderSideEnum } from "../enums/OrderSideEnum";
import { OrderStatusEnum } from "../enums/OrderStatusEnum";
import {
  charFromUint8Array,
  doubleFromUint8Array,
  int32FromUint8Array,
  int64FromUint8Array,
  numberFromUint8Array,
  stringFromUint8Array,
} from "../utils/decoder";
import { IAccountOrder } from "./IAccountOrder";

export class ExecutionReport {
  private static readonly _offset = 11;

  private _buffer: Uint8Array;

  constructor(buffer: Uint8Array) {
    this._buffer = buffer;
  }

  public get size(): number {
    return numberFromUint8Array(this._buffer, 0);
  }

  public get messageType(): NatsMessageTypeEnum {
    return numberFromUint8Array(this._buffer, 1);
  }

  public get process(): number {
    return numberFromUint8Array(this._buffer, 2);
  }

  public get exchOrderIdLength(): number {
    return numberFromUint8Array(this._buffer, 3);
  }

  public get exchOrderIdIndex(): number {
    return numberFromUint8Array(this._buffer, 4);
  }

  public get execIdLength(): number {
    return numberFromUint8Array(this._buffer, 5);
  }

  public get execIdIndex(): number {
    return numberFromUint8Array(this._buffer, 6);
  }

  public get rejDetailLength(): number {
    return numberFromUint8Array(this._buffer, 7);
  }

  public get rejDetailIndex(): number {
    return numberFromUint8Array(this._buffer, 8);
  }

  public get symbolIndex(): number {
    return numberFromUint8Array(this._buffer, 9);
  }

  public get symbolLength(): number {
    return numberFromUint8Array(this._buffer, 10);
  }

  public get price(): number {
    return doubleFromUint8Array(this._buffer, ExecutionReport._offset + 0);
  }

  public get avgPrice(): number {
    return doubleFromUint8Array(this._buffer, ExecutionReport._offset + 8);
  }

  public get lastPrice(): number {
    return doubleFromUint8Array(this._buffer, ExecutionReport._offset + 16);
  }

  public get quantity(): number {
    return doubleFromUint8Array(this._buffer, ExecutionReport._offset + 24);
  }

  public get cumFillQuantity(): number {
    return doubleFromUint8Array(this._buffer, ExecutionReport._offset + 32);
  }

  public get lastFillQuantity(): number {
    return doubleFromUint8Array(this._buffer, ExecutionReport._offset + 40);
  }

  public get remainingQuantity(): number {
    return doubleFromUint8Array(this._buffer, ExecutionReport._offset + 48);
  }

  public get userId(): number {
    return int32FromUint8Array(this._buffer, ExecutionReport._offset + 56);
  }

  public get accountId(): number {
    return int32FromUint8Array(this._buffer, ExecutionReport._offset + 60);
  }

  public get time(): bigint {
    return int64FromUint8Array(this._buffer, ExecutionReport._offset + 64);
  }

  public get side(): OrderSideEnum {
    return parseInt(
      charFromUint8Array(this._buffer, ExecutionReport._offset + 72)
    ) as OrderSideEnum;
  }

  public get status(): OrderStatusEnum {
    return parseInt(
      charFromUint8Array(this._buffer, ExecutionReport._offset + 73)
    ) as OrderStatusEnum;
  }

  public get executionType(): ExecTypeEnum {
    return parseInt(
      charFromUint8Array(this._buffer, ExecutionReport._offset + 74)
    ) as ExecTypeEnum;
  }

  public get liqFlag(): string {
    return charFromUint8Array(this._buffer, ExecutionReport._offset + 75);
  }

  public get orderId(): bigint {
    return int64FromUint8Array(this._buffer, ExecutionReport._offset + 76);
  }

  public get origOrderId(): bigint {
    return int64FromUint8Array(this._buffer, ExecutionReport._offset + 84);
  }

  public get exchangeOrderId() {
    return stringFromUint8Array(
      this._buffer,
      this.exchOrderIdIndex,
      this.exchOrderIdLength
    );
  }

  public get executionId() {
    return stringFromUint8Array(
      this._buffer,
      this.execIdIndex,
      this.execIdLength
    );
  }

  public get rejDetail() {
    return stringFromUint8Array(
      this._buffer,
      this.rejDetailIndex,
      this.rejDetailLength
    );
  }

  public get symbol() {
    return stringFromUint8Array(
      this._buffer,
      this.symbolIndex,
      this.symbolLength
    );
  }

  public updateAccountOrder(accountOrder: IAccountOrder): IAccountOrder {
    return {
      ...accountOrder,
      AvgFillPrice: this.avgPrice,
      ExecID: this.executionId,
      ExecType: ExecTypeEnum[this.executionType],
      FillPrice: this.avgPrice,
      FillQty: this.cumFillQuantity,
      LastFill: this.lastFillQuantity,
      LiqFlag: this.liqFlag,
      OrderId: this.exchangeOrderId,
      OrigOrderId: this.origOrderId.toString(),
      RejDetail: this.rejDetail,
      RestQty: this.remainingQuantity,
      //SentUserId: this.userId,
      Status: OrderStatusEnum[this.status],
      IsOpen: [OrderStatusEnum.New, OrderStatusEnum.PartiallyFilled, OrderStatusEnum.Sending].indexOf(this.status) !== -1
    };
  }

  public test() {
    console.log(this.size);
    console.log(this.messageType);
    console.log(this.process);
    console.log(this.exchOrderIdLength);
    console.log(this.exchOrderIdIndex);
    console.log(this.execIdLength);
    console.log(this.execIdIndex);
    console.log(this.rejDetailLength);
    console.log(this.rejDetailIndex);
    console.log(this.symbolIndex);
    console.log(this.symbolLength);
    console.log(this.price);
    console.log(this.avgPrice);
    console.log(this.lastPrice);
    console.log(this.quantity);
    console.log(this.cumFillQuantity);
    console.log(this.lastFillQuantity);
    console.log(this.remainingQuantity);
    console.log(this.userId);
    console.log(this.accountId);
    console.log(new Date(Number(this.time)));
    console.log(this.side);
    console.log(this.status);
    console.log(this.executionType);
    console.log(this.liqFlag);
    console.log(this.orderId);
    console.log(this.origOrderId);
    console.log(this.exchangeOrderId);
    console.log(this.executionId);
    console.log(this.rejDetail);
    console.log(this.symbol);
  }
}
