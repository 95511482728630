import { USERSADS_SET_USERSADS } from "./users-ads-types";
import { UserAdState } from "./UsersAdsState";

const initialState = new UserAdState();

export default function UsersAdsReducer(state = initialState, action: any): UserAdState {
  switch (action.type) {
    case USERSADS_SET_USERSADS:
      return {
        ...state,
        userAds: action.payload,
      };

    default:
      return state;
  }
}
