import React from 'react'

type CardProps = {
    title: string;
    data: any;
};

const StatCard = ({ title, data }: CardProps) => {
    return (
        <div className="col-auto infobox d-flex align-items-start  justify-content-center flex-column">
            <label className="info-title">{title}</label>
            <span className="info-value">{data}</span>
        </div>
    )
}

export default StatCard