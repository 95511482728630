import { useEffect, useMemo, useRef, useState } from 'react'
import AggridList, { KPIData } from '../ag-grid-list/aggrid-list';
import { GridOptions } from 'ag-grid-community';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CustomSpinner from '../spinner/spinner';
import { accountColumnsDefinition } from './AccountColumnsDefinition';
import store from '../../../store';
import { loadAccounts } from '../../../store/admin-accounts/accounts-async-actions';
import { ContextMenuOption } from '../../context-menu/context-menu-types';
import { showEditForm } from '../../../store/users/users-actions';
import editIcon from "../../../Images/editicon.svg";
import copyIcon from '../../../Images/copyicon.svg';
import { copyToClipboard } from '../../../utils/general';
import { formatNumberForKPI } from '../../../utils/number';
import { IAppState } from '../../../store/reducers/IAppState';
import apiSvc from '../../../services/api-service';

const ACCOUNT_STATS = ["Accounts", "Disabled", "Close Positions Only", "Dummy 2", "Dummy 3", "Dummy 4", "Dummy 5", "Dummy 6"];

type Props = {
  windowId: string,
  searchResults?: any[]
}

const AccountsList = ({windowId, searchResults}: Props) => {

  const gridOptions: GridOptions = {
    columnDefs: accountColumnsDefinition
  };

  const dispatch = useDispatch();
  const formData = useSelector((state: IAppState) => state.users.formData);

  const [accountsData, setAccountsData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [currentRow, setCurrentRow] = useState<any>();

  const gridApiRef = useRef<any>(null);

  const accountContextMenuOptions: ContextMenuOption[] = [
    {
      handler: () => { 
        store.dispatch(
          showEditForm({
            status: true,
            data: {
              fieldName: `Edit Account - ${currentRow?.AccountName}`,
              fieldData: currentRow?.Id,
            },
          })
        );
      },
      name: "Edit Account",
      optionIcon: editIcon
    },
    {
      handler: () => copyToClipboard(gridApiRef),
      name: "Copy All",
      optionIcon: copyIcon
    },
  ];

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
  };

  const accountKPIData = useMemo<KPIData[]>(() => {
    const stats: KPIData[] = [];

    ACCOUNT_STATS.forEach((param) => {

      let data;

      switch(param)
      {
        case "Accounts":
          data = formatNumberForKPI(accountsData.length);
          break;
        case "Disabled":
          data = formatNumberForKPI(accountsData.filter((account: any) => account.AccountStatusId === 3).length);
          break;
        case "Close Positions Only":
          data = formatNumberForKPI(accountsData.filter((account: any) => account.AccountStatusId === 2).length);
          break;
        case "Dummy 2":
          data = formatNumberForKPI(45855);
          break;
        case "Dummy 3":
          data = formatNumberForKPI(5855);
          break;
        case "Dummy 4":
          data = formatNumberForKPI(5845855);
          break;
        case "Dummy 5":
          data = formatNumberForKPI(645855, true);
          break;
        case "Dummy 6":
          data = formatNumberForKPI(855);
          break;
      }

      stats.push({
        title: param,
        data
      });

    });

    return stats;
    
  }, [accountsData]);

  const addNewAccount = () => {
    store.dispatch(
      showEditForm({
        status: true,
        data: {
          fieldName: "Add Account",
          fieldData: -1,
        },
      })
    );
  };
  
  const loadAccountsList = async () => {
    const {getState} = store;
    try {
      let response: any ={};

      const groupsResponse = await apiSvc.get("SelectGroupsRequest");
      
      if(searchResults && searchResults.length)
      {
        response = {data: {Data: searchResults}};  //Map search results to the same format in order to reuse the table
      }
      else {
        response = await loadAccounts(dispatch, getState);
      }


      if (response && response.data) {
        const userAccounts = store.getState().userAccountsList.userAccountsList; 
        const formattedAccounts = response.data.Data.map((account) => {
          const userAccount = userAccounts.find((ua) => ua.AccountId == account.AccountId);
          const groupName = groupsResponse?.data?.Data.find((w) => w.GroupId === account.GroupId)?.Name;

          return {
            FirmName: account?.Firm?.Name,
            AccountStatus: account?.AccountStatus?.Status,
            AccountName: account?.Name,
            AccountType: account?.AccountType,
            AccountStatusId: account?.AccountStatus?.AccountStatusID,
            Currency: account?.Currency?.Name,
            TradeOut: "",
            CreatedOn: new Date(moment(account?.CreatedOn).format("YYYY-MM-DD HH:mm:ss")),
            Balance: account?.Balance,
            Equity: 0,
            Id: account?.AccountId,
            MaxDrawDown: account?.MaxDrawDown,
            Email: userAccount ? userAccount.User.Email : "",
            GroupId: account?.GroupId,
            GroupName: groupName,
            CrRatio: account?.CrRatio,
            Resets: account?.Resets
          }
        }) .sort((a, b) => {
          const dateA = new Date(a.CreatedOn).getTime();
          const dateB = new Date(b.CreatedOn).getTime();
          return dateB - dateA;
        });
        setAccountsData(formattedAccounts);
      }
    }
    catch (e) {
      console.error(e);
    }
    finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    loadAccountsList();
  }, []);

  useEffect(() => {
    if(formData && formData.updatedData && formData.updatedData.accountForm)
    {
      setIsLoadingData(true);
      loadAccountsList();
    }
  }, [JSON.stringify(formData)]);

  return (
    <>
      {
        isLoadingData
        ?
        <CustomSpinner />
        :
        <AggridList
          gridOptions={gridOptions}
          rowData={accountsData}
          pagination={true}
          primaryButtonText={searchResults && searchResults.length > 0 ? "" : 'Add New Account'}
          primaryButtonOnClickHandler={addNewAccount}
          contextMenuOptions={accountContextMenuOptions}
          setRecordForContextMenu={setCurrentRow}
          gridName={"Accounts"}
          onGridReady={onGridReady}
          kpiData={accountKPIData}
          windowId={windowId}
          noKPIData={searchResults && searchResults.length > 0}
          hideInternalSearch={searchResults && searchResults.length > 0}
        />
      }
    </>
  )
}

export default AccountsList