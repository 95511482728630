import { useEffect, useRef, useState } from 'react'
import AggridList, { KPIData } from '../ag-grid-list/aggrid-list';
import { GridApi, GridOptions, RowDataTransaction } from 'ag-grid-community';
import { useDispatch } from 'react-redux';
import CustomSpinner from '../spinner/spinner';
import { accountPerformanceColumnsDefinition } from './AccountPerformaceColumns';
import store from '../../../store';
import { ContextMenuOption } from '../../context-menu/context-menu-types';
import { showEditForm } from '../../../store/users/users-actions';
import editIcon from "../../../Images/editicon.svg";
import copyIcon from '../../../Images/copyicon.svg';
import { compareAndUpdateLists, copyToClipboard } from '../../../utils/general';
import { formatNumberForKPI } from '../../../utils/number';
import { subscribeForSymPosInfo } from '../../../store/connection/connection-async-actions';
import IndexedDBService from '../../../utils/idb_helper';
import { subscribeQuoteMediaData, unsubscribeQuoteMediaData } from '../../../store/quote-media/quote-media-async-actions';

const ACCOUNT_WATCH_STATS = ["Accounts", "Positions", "Total Volume", "Total Open PNL", "Total Close PNL", "Total BP Used", "Notional"];
let subscribedSymbols: string[] = []; //Store the symbol to subscribe ma

type Props = {
  windowId: string,
  searchResults?: []
}

const AccountPerformance = ({ windowId, searchResults }: Props) => {

  const gridOptions: GridOptions = {
    columnDefs: accountPerformanceColumnsDefinition
  };

  const dispatch = useDispatch();

  const [accountPerformanceData, setAccountPerformanceData] = useState<any[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [currentRow, setCurrentRow] = useState<any>();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [kpiDataList, setKpiDataList] = useState<KPIData[]>([]);


  const gridApiRef = useRef<any>(null);


  const accountWatchContextMenuOptions: ContextMenuOption[] = [
    {
      handler: () => {
        store.dispatch(
          showEditForm({
            status: true,
            data: {
              fieldName: "Edit User",
              fieldData: currentRow?.UserId,
            },
          })
        );
      },
      name: "Edit User",
      optionIcon: editIcon
    },
    {
      handler: () => {
        store.dispatch(
          showEditForm({
            status: true,
            data: {
              fieldName: "Edit Account",
              fieldData: currentRow?.AccountId,
            },
          })
        );
      },
      name: "Edit Account",
      optionIcon: editIcon
    },
    {
      handler: () => {
        store.dispatch(
          showEditForm({
            status: true,
            data: {
              fieldName: "Orders",
              fieldData: currentRow?.UserAccount,
            },
          })
        );
      },
      name: "View Orders",
      optionIcon: editIcon
    },
    {
      handler: () => {
        store.dispatch(
          showEditForm({
            status: true,
            data: {
              fieldName: "Positions",
              fieldData: currentRow?.UserAccount,
            },
          })
        );
      },
      name: "View Positions",
      optionIcon: editIcon
    },
    {
      handler: () => copyToClipboard(gridApiRef),
      name: "Copy All",
      optionIcon: copyIcon
    },
  ];

  const getCurrentVisibleRows = () => {
    let allVisibleRows: any[] = [];
    if (gridApi) {
      gridApi.forEachNodeAfterFilterAndSort(node => {
        if (node.displayed) {
          allVisibleRows.push(node.data);
        }
      });
    }
    return allVisibleRows;
  };

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
    setGridApi(params.api);
  };

  const updateKPIData = (accountWatchData) => {
    const stats: KPIData[] = [];

    ACCOUNT_WATCH_STATS.forEach((param) => {

      let data;

      let gridData: any[] = accountWatchData;

      switch (param) {
        case "Accounts":
          data = formatNumberForKPI(accountWatchData.length);
          break;
        case "Positions":
          const sumOfPositions = gridData.reduce((acc: number, row: any) => acc + row.TotalPosition, 0);
          data = formatNumberForKPI(sumOfPositions);
          break;
        case "Total Volume":
          const sumOfVolume = gridData.reduce((acc: number, row: any) => acc + row.QtyTraded, 0);
          data = formatNumberForKPI(sumOfVolume);
          break;
        case "Total Open PNL":
          const sumOfOpenPNL = gridData.reduce((acc: number, row: any) => acc + row.OpenPNL, 0);
          data = formatNumberForKPI(sumOfOpenPNL, true);
          break;
        case "Total Close PNL":
          const sumOfClosePNL = gridData.reduce((acc: number, row: any) => acc + row.ClosePNL, 0);
          data = formatNumberForKPI(sumOfClosePNL, true);
          break;
        case "Total BP Used":
          const sumOfBPUsed = gridData.reduce((acc: number, row: any) => acc + row.BPUsed, 0);
          data = formatNumberForKPI(sumOfBPUsed, true);
          break;
        case "Notional":
          data = formatNumberForKPI(500000, true);
          break;
      }

      stats.push({
        title: param,
        data
      });

    });

    setKpiDataList(stats);
  };

  const updateGrid = async () => {
    const accountPositions = await IndexedDBService.getObjectStore("AccountPositions");
    const symbolPositions = await IndexedDBService.getObjectStore("SymbolPositions");

    let accountWatchData: any[] = [];

    const userAccounts = store.getState().userAccountsList.userAccountsList;
    const users = store.getState().users.data;
    const accounts = store.getState().adminAccounts.accounts;
    const { groups } = store.getState().groups;

    const userAccountsMap = userAccounts.reduce((acc, obj) => acc.set(obj.AccountId, obj), new Map());
    const usersMap = users.reduce((acc, obj) => acc.set(obj.Id, obj), new Map());
    const accountsMap = accounts.reduce((acc, obj) => acc.set(obj.AccountId, obj), new Map());

    accountPositions.forEach((accountPosition) => {

      const userAccount = userAccountsMap.get(accountPosition.AccountId);
      const user = usersMap.get(userAccount.UserId);
      const account = accountsMap.get(accountPosition.AccountId);
      const group = groups.find((grp) => grp.GroupId == account?.GroupId);

      let totalPositions = 0;
      let totalOpenPL = 0;
      let totalClosePL = 0;
      let totalPNL = 0;
      let totalAbsPositions = 0;
      let bpUsed = 0;
      let totalVolume = 0;

      let accountPerformanceObject = {
        AccountStatus: account?.AccountStatus?.Status,
        UserStatus: user?.UserStatus?.Status,
        UserName: `${user?.FirstName} ${user?.LastName}`,
        AccountName: account?.Name,
        TotalPosition: 0,
        OpenPNL: 0,
        ClosePNL: 0,
        TotalPNL: 0,
        BPUsed: 0,
        BuyingPower: 0,
        MaxLoss: 0,
        Balance: 0,
        Exposure: 0,
        QtyTraded: 0,
        FirmName: user?.Firm?.Name,
        GroupName: group?.Name,
        DrawDown: "0.00",
        RunUp: "0.00",
        PrePostMarket: "0.00",
        BPAvailable: "0.00",
        MaxBP: "0.00",
        MinBP: "0.00",
        MaxDrawdown: "0.00",
        DDMaxLoss: "0.00",
        UserId: user?.Id,
        AccountId: account?.AccountId,
        UserAccount: userAccount,
        PositionMultiplier: 1
      };

      let accountSymbolPositions = symbolPositions.filter((position) => position.AccountId == accountPosition.AccountId);
      accountSymbolPositions.forEach(async (symbol) => {
        if (!subscribedSymbols.includes(symbol.Symbol) && Math.abs(symbol.Position)) {
          store.dispatch(subscribeQuoteMediaData("Global", symbol.Symbol));
        }
        else if (!Math.abs(symbol.Position) && subscribedSymbols.includes(symbol.Symbol)) {
          store.dispatch(unsubscribeQuoteMediaData("Global", symbol.Symbol));
        }
        store.dispatch(subscribeQuoteMediaData("Global", symbol.Symbol));
        totalPositions += symbol.Position;
        totalAbsPositions += Math.abs(symbol.Position);
        totalOpenPL += symbol.UnrealPL;
        totalClosePL += symbol.RealPL;
        totalVolume += Number(symbol.TotalPosition);
        totalPNL += symbol.TotalPL;
        bpUsed += symbol.BPUsed;
      });

      accountPerformanceObject.QtyTraded = totalVolume;
      accountPerformanceObject.TotalPosition = totalAbsPositions;
      accountPerformanceObject.PositionMultiplier = totalPositions < 0 ? -1 : 1
      accountPerformanceObject.OpenPNL = totalOpenPL;
      accountPerformanceObject.ClosePNL = totalClosePL;
      accountPerformanceObject.TotalPNL = totalPNL;
      accountPerformanceObject.BPUsed = bpUsed;

      accountWatchData.push(accountPerformanceObject);

    });

    let currentAccountWatchData = getCurrentVisibleRows();

    let { rowsTobeAdded, rowsTobeUpdated } = compareAndUpdateLists(currentAccountWatchData, accountWatchData, "AccountId");

    const transaction: RowDataTransaction = {
    };

    if (rowsTobeAdded.length) {
      transaction.add = rowsTobeAdded;
      transaction.addIndex = 0;
    }
    if (rowsTobeUpdated.length) {
      transaction.update = rowsTobeUpdated;
    }

    if (gridApi && gridApi.applyTransaction) {
      gridApi.applyTransaction(transaction);
      updateKPIData(accountWatchData);
      setAccountPerformanceData(accountWatchData);
    }

  };

  useEffect(() => {
    dispatch(subscribeForSymPosInfo());
  }, []);

  useEffect(() => {
    if (gridApi) {
      const gridUpdateInterval = setInterval(() => {
        updateGrid();
      }, 250);

      return () => {
        clearInterval(gridUpdateInterval);
      }
    }
  }, [gridApi]);


  return (
    <>
      {
        isLoadingData
          ?
          <CustomSpinner />
          :
          <AggridList
            gridOptions={gridOptions}
            rowData={accountPerformanceData}
            pagination={true}
            contextMenuOptions={accountWatchContextMenuOptions}
            rowIdSelector={(data) => data.AccountId}
            setRecordForContextMenu={setCurrentRow}
            gridName={"Account Watch"}
            onGridReady={onGridReady}
            kpiData={kpiDataList}
            windowId={windowId}
            noKPIData={searchResults && searchResults.length > 0}
            hideInternalSearch={searchResults && searchResults.length > 0}
          />
      }
    </>
  )
}

export default AccountPerformance