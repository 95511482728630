import { IColumnDefinition } from "../../../models/IColumnDefinition";

export const watchListDefaultColumns: IColumnDefinition[] = [
  { title: "% Chg", field: "percentChange", visible: true, sortOrder: 4, minWidth: 65 },
  { title: "% Held", field: "percentHeld", visible: true, sortOrder: 62, minWidth: 60 },
  { title: "% of Avg Vol", field: "percentAvgVol", visible: true, sortOrder: 21, minWidth: 80 },
  { title: "$ET Change", field: "etChange", visible: true, sortOrder: 27, minWidth: 71 },
  { title: "52 wk High", field: "yearHigh", visible: true, sortOrder: 39, minWidth: 69 },
  { title: "52 wk Low", field: "yearLow", visible: true, sortOrder: 40, minWidth: 64 },
  { title: "Ask Chg", field: "askChg", visible: true, sortOrder: 23, minWidth: 60 },
  { title: "Ask Size", field: "askSize", visible: true, sortOrder: 11, minWidth: 60 },
  { title: "Ask Time", field: "askTime", visible: true, sortOrder: 37, minWidth: 60 },
  { title: "Ask", field: "askPrice", visible: true, sortOrder: 10, minWidth: 60 },
  { title: "Assets", field: "assets", visible: true, sortOrder: 54, minWidth: 60 },
  { title: "Avg Vol", field: "avgVol", visible: true, sortOrder: 63, minWidth: 60 },
  { title: "Balance Date", field: "balanceDate", visible: true, sortOrder: 57, minWidth: 77 },
  { title: "Beta", field: "beta", visible: true, sortOrder: 45, minWidth: 60 },
  { title: "Bid chg", field: "bidChg", visible: true, sortOrder: 22, minWidth: 60 },
  { title: "Bid Size", field: "bidSize", visible: true, sortOrder: 9, minWidth: 60 },
  { title: "Bid Time", field: "bidTime", visible: true, sortOrder: 38, minWidth: 60 },
  { title: "Bid", field: "bidPrice", visible: true, sortOrder: 8, minWidth: 60 },
  { title: "Change", field: "change", visible: true, sortOrder: 3, minWidth: 70 },
  { title: "Chg From Open", field: "chgFromOpen", visible: true, sortOrder: 18, minWidth: 95 },
  { title: "Close", field: "close", visible: true, sortOrder: 13, minWidth: 60 },
  { title: "Cmn Shr Out", field: "commissionShareOut", visible: true, sortOrder: 53, minWidth: 79 },
  { title: "Days to Exp", field: "daysToExp", visible: true, sortOrder: 29, minWidth: 70 },
  { title: "Debt", field: "debt", visible: true, sortOrder: 56, minWidth: 60 },
  { title: "Delay", field: "delay", visible: true, sortOrder: 17, minWidth: 60 },
  { title: "Description", field: "longName", visible: true, sortOrder: 2, minWidth: 90 },
  { title: "Div Amount", field: "divAmount", visible: true, sortOrder: 48, minWidth: 72 },
  { title: "Div Rate", field: "divRate", visible: true, sortOrder: 49, minWidth: 60 },
  { title: "Div Yield", field: "divYield", visible: true, sortOrder: 50, minWidth: 60 },
  { title: "EPS", field: "eps", visible: true, sortOrder: 46, minWidth: 60 },
  { title: "ET Last", field: "etLast", visible: true, sortOrder: 26, minWidth: 60 },
  { title: "Ex Div Date", field: "exDivDate", visible: true, sortOrder: 51, minWidth: 68 },
  { title: "Exchange Root", field: "exchangeRoot", visible: true, sortOrder: 47, minWidth: 87 },
  { title: "Fisc Yr End", field: "fiscYrEnd", visible: true, sortOrder: 58, minWidth: 64 },
  { title: "FSI", field: "fsi", visible: true, sortOrder: 36, minWidth: 60 },
  { title: "High", field: "high", visible: true, sortOrder: 14, minWidth: 60 },
  { title: "Historical Volatility", field: "HistoricalVolatility", visible: true, sortOrder: 41, minWidth: 107 },
  { title: "Last Size", field: "size", visible: true, sortOrder: 6, minWidth: 60 },
  { title: "Last", field: "last", visible: true, sortOrder: 5, minWidth: 60 },
  { title: "Liabilities", field: "liabilities", visible: true, sortOrder: 55, minWidth: 60 },
  { title: "Low", field: "low", visible: true, sortOrder: 15, minWidth: 60 },
  { title: "Mkt Cap", field: "mktCap", visible: true, sortOrder: 20, minWidth: 60 },
  { title: "Mkt Center", field: "mktCenter", visible: true, sortOrder: 31, minWidth: 67 },
  { title: "Open Int", field: "openInt", visible: true, sortOrder: 30, minWidth: 60 },
  { title: "Open", field: "open", visible: true, sortOrder: 12, minWidth: 60 },
  { title: "P/E", field: "pe", visible: true, sortOrder: 44, minWidth: 60 },
  { title: "Pay Date", field: "payDate", visible: true, sortOrder: 52, minWidth: 60 },
  { title: "Prev Vol", field: "prevVol", visible: true, sortOrder: 43, minWidth: 60 },
  { title: "Range", field: "range", visible: true, sortOrder: 25, minWidth: 60 },
  { title: "Restricted", field: "restricted", visible: true, sortOrder: 28, minWidth: 61 },
  { title: "Settlement", field: "settlement", visible: true, sortOrder: 32, minWidth: 66 },
  { title: "SettlementDate", field: "settlementDate", visible: true, sortOrder: 33, minWidth: 93 },
  { title: "Short Interest", field: "shortInterest", visible: true, sortOrder: 59, minWidth: 80 },
  { title: "SIC", field: "sic", visible: true, sortOrder: 42, minWidth: 60 },
  { title: "Split # 1", field: "split1", visible: true, sortOrder: 60, minWidth: 60 },
  { title: "Split # 2", field: "split2", visible: true, sortOrder: 61, minWidth: 60 },
  { title: "Spread", field: "spread", visible: true, sortOrder: 24, minWidth: 60 },
  { title: "Symbol", field: "symbol", visible: true, sortOrder: 1, minWidth: 70 },
  { title: "Tick ID", field: "tickId", visible: true, sortOrder: 35, minWidth: 60 },
  { title: "Trade Time", field: "lastTradeTime", visible: true, sortOrder: 16, minWidth: 70 },
  { title: "Trades Today", field: "todayTrades", visible: true, sortOrder: 33, minWidth: 81 },
  { title: "Volatility", field: "volatility", visible: true, sortOrder: 19, minWidth: 60 },
  { title: "Volume", field: "accumulatedVolume", visible: true, sortOrder: 7, minWidth: 70 },
  { title: "VWAP", field: "vwap", visible: true, sortOrder: 34, minWidth: 60 },
];
