import { GRIDS_SET_DATA } from "./grids-types";
import { GridsState } from "./GridsState";

const initialState = new GridsState();

export default function gridsReducer(state = initialState, action: any): GridsState {
  switch (action.type) {
    case GRIDS_SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: action.payload.data
        }
      };

    default:
      return state;
  }
}
