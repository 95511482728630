

  export const bufferToLong = function (bytes: number[]) {
    let value = 0;

    for (let i = bytes.length - 1; i >= 0; i--) {
      value = value * 0xff + bytes[i];
    }

    return value;
  };

  export const doubleToInt8Array = (value: number): Int8Array => {
    const buffer = new ArrayBuffer(8);
    const longNum = new Float64Array(buffer);
    longNum[0] = value;
    return new Int8Array(buffer);
  }

  export const longToUint8Array = (value: bigint): Uint8Array => {
    const buffer = new ArrayBuffer(8);
    const longNum = new BigUint64Array(buffer);
    longNum[0] = value;
    return new Uint8Array(buffer);
  };

  export const longToInt8Array = (value: bigint): Int8Array => {
    const buffer = new ArrayBuffer(8);
    const longNum = new BigUint64Array(buffer);
    longNum[0] = value;
    return new Int8Array(buffer);
  };

  export const numberToUint8Array = (value: number): Uint8Array => {
    const buffer = new ArrayBuffer(4);
    const intNumber = new Int32Array(buffer);
    intNumber[0] = value;
    return new Uint8Array(buffer);
  };

  export const numberToUint32Array = (value: number): Uint32Array => {
    const buffer = new ArrayBuffer(4);
    const intNumber = new Int32Array(buffer);
    intNumber[0] = value;
    return new Uint32Array(buffer);
  };

  export const stringToAsciiArray = (value: string): number[] => {
    const result: number[] = [];

    for (let i = 0; i < value.length; i++) {
      result.push(value.charCodeAt(i));
    }

    return result;
  };