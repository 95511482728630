import moment from "moment";
import { IColumnDefinition } from "../../../models/IColumnDefinition";
import { formatCurrency } from "../../../utils/number";

export const accountColumnsDefinition: IColumnDefinition[] = [
  {
    title: "Status",
    field: "AccountStatus",
    visible: true,
    sortOrder: 13,
    minWidth: 25,
    cellClass: (params) => "custom-status-class",
  },
  {
    title: "Email",
    field: "Email",
    visible: true,
    sortOrder: 18,
    minWidth: 80,
  },
  {
    title: "Account",
    field: "AccountName",
    visible: true,
    sortOrder: 18,
    minWidth: 80,
  },
  {
    title: "Created On",
    field: "CreatedOn",
    visible: true,
    sortOrder: 9,
    minWidth: 80,
    filter: "agDateColumnFilter",
    filterParams: {
      filterOptions: ['inRange'],
      maxNumConditions: 0,
      buttons: ['apply', 'reset'],
      inRangeInclusive: true
    },
    valueFormatter: (params) => {
      return moment(params?.value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    title: "Cash Balance",
    field: "Balance",
    visible: true,
    sortOrder: 9,
    minWidth: 70,
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => {
      return formatCurrency(params.value);
    }, 
    cellClass : (params) => {
      return params.value > 0 ? 'positive-value' : 'negative-value';
    },
  },
  {
    title: "Max DrawDown",
    field: "MaxDrawDown",
    visible: true,
    sortOrder: 12,
    minWidth: 70,
  },
  {
    title: "Firm",
    field: "FirmName",
    visible: true,
    sortOrder: 3,
    minWidth: 80,
  },
  {
    title: "Account Type",
    field: "AccountType",
    visible: true,
    sortOrder: 19,
    minWidth: 50,
  },
  {
    title: "Group Name",
    field: "GroupName",
    visible: true,
    sortOrder: 19,
    minWidth: 50,
  },
  {
    title: "Cr Ratio",
    field: "CrRatio",
    visible: true,
    sortOrder: 19,
    minWidth: 50,
  },
  {
    title: "Resets",
    field: "Resets",
    visible: true,
    sortOrder: 19,
    minWidth: 50,
  },
  {
    title: "Main Currency",
    field: "Currency",
    visible: true,
    sortOrder: 11,
    minWidth: 50,
  },
  {
    title: "Net Equity",
    field: "Equity",
    visible: true,
    sortOrder: 21,
    minWidth: 70,
  },
];
