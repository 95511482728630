import { FEE_GET_FEELIST } from "./fee-types";
import { FeeState } from "./FeeState";

const initialState = new FeeState();

export default function FeeListReducer(state = initialState, action: any): FeeState {
  switch (action.type) {
    case FEE_GET_FEELIST:
      return {
        ...state,
        feeList: action.payload,
      };

    default:
      return state;
  }
}
