export const AUTH_LOGOUT_USER = "auth/logout-user";
export const AUTH_SET_ADMIN_PANEL_STATE = "auth/adminPanelState";
export const AUTH_SET_AUTH_INFO = "auth/set-auth-info";
export const AUTH_SET_ISADMIN = "auth/isAdmin";
export const AUTH_SET_LOGIN_CREDENTIALS = "auth/set-login-credentials";
export const AUTH_SET_LOGIN_REQUEST_IN_PROGRESS = "auth/set-login-request-in-progress";
export const AUTH_SET_LOGIN_RESPONSE = "auth/set-login-response";
export const AUTH_SET_LOGIN_SUCCESSFUL_BEEP = "auth/login-successful-beep";
export const AUTH_SET_QM_SID = "auth/set-qm-sid";
export const AUTH_COPY_AUTH_STATE = 'auth/copy-auth-state';
