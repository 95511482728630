import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import FlexView from "react-flexview";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { filter } from "rxjs/operators";
import { widgetsConfig } from "../../constants/widgets-config";
import { WatchListTypeEnum } from "../../enums/WatchListTypeEnum";
import { authSvc } from "../../services/auth-service";
import globalCache from "../../services/global-cache";
import notificationSvc from "../../services/notification-service";
import soundSvc from "../../services/sound-service";
import tempCache from "../../services/temp-cache";
import * as sounds from "../../sounds";
import { setDetachWindowDetailsInProgress } from "../../store/account/account-actions";
import { logoutUser, setLoginCredentials } from "../../store/auth/auth-actions";
import { authenticateUser } from "../../store/auth/auth-async-actions";
import store from "../../store/index";
import { clearTimeAndSalesFlag } from "../../store/level-ii-view/level-ii-actions";
import { disconnectQuoteMediaStream } from "../../store/quote-media/quote-media-async-actions";
import { IAppState } from "../../store/reducers/IAppState";
import {
  resetAllSettings,
  setKeyboardShortcutSetting,
  setSelectedShortcutCombinationSettingToAllowed,
  setShortcutCombinationSetting,
  setUserClosedWidget,
  setWidgetDisplayed,
} from "../../store/settings/settings-actions";
import { setSearchParams, showEditForm } from "../../store/users/users-actions";
import {
  showMostActiveSymbolsList,
  showTrader2BWatchList,
} from "../../store/watch-list/watch-list-async-actions";
import {
  mostActiveWatchListSymbols$,
  trade2BWatchListSymbols$,
} from "../../store/watch-list/watch-list-observables";
import {
  linkWidget,
  loadLinkedWidgets,
  loadWidgetSymbols,
  setActiveWidget,
  setWidgetSymbol,
  setWidgetSymbols,
  unlinkWidget,
} from "../../store/widgets/widgets-actions";
import ConfirmationModal from "../confirmation/confirmation";
import { IShellViewState } from "../shell/IShellViewState";
import ShellView from "../shell/shell";
import Sidebar from "../sidebar/sidebar";
import WidgetCloseConfirmation from "../widget-close-modal/widgetCloseConfirmation";
import {
  IMainViewDispatchProps,
  IMainViewMappedProps,
  IMainViewProps,
} from "./IMainViewProps";
import {
  IWorkspace,
  IWorkspaceDictionary,
  MainViewState,
  Widget,
} from "./MainViewState";
import "./styles.scss";
import jquery from "jquery";
import SearchResults from "../admin/searchResults/searchResults";
import modalSvc from "../../services/modal-service";
import { Defaults } from "../../constants/defaults";
import { IWidgetSymbolsDictionary } from "../../models/IWidgetSymbol";
import { IKeyboardShortcutSetting } from "../../models/IKeyboardShortcutSetting";
import { IShortcutCombination } from "../../models/IShortcutCombination";
import { ILinkedWidget } from "../../models/ILinkedWidget";
import RenameWorkspace from "../rename-workspace/rename-workspace-modal";
import WorkspaceCloseModal from "../workspace-close/workspace-close-modal";
import Header from "../header/header";
import firebaseSvc from "../../services/firebase-service";
import CustomSpinner from "../admin/spinner/spinner";

const ADMIN_DEFAULT_WORKSPACES = ["Users", "Accounts", "Positions", "Orders", "Account Performance"];
const CACHE_VERSION = "23";

type Props = IMainViewProps & IMainViewMappedProps & IMainViewDispatchProps;

class MainView extends Component<Props, MainViewState> {
  audioRef;
  dataLoadedFromCache = false;
  index;
  isModalVisible = false;
  previousName;
  prevWorkspaceId;
  delWorkspaceId;
  renameWorkspaceId;

  constructor(props: Props) {
    super(props);
    this.audioRef = React.createRef();
    this.state = new MainViewState();
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    console.log("NODE_ENV:", process.env.NODE_ENV);
  }

  componentDidMount() {
    if (!this.validateCache()) {
      notificationSvc.info(
        "Major updates are installed. You will have to login again."
      );
      soundSvc.play(sounds.logout);
      store.dispatch(logoutUser());
      return;
    }

    const { userInfo } = tempCache;
    if (userInfo) {
      const { password, username } = tempCache;

      if (username && password) {
        this.authenticateUser(username, password);
      } else {
        store.dispatch(logoutUser());
      }
    }

    // this.loadDataFromCache();
    this.subscribeToIsLoggedIn();
    this.subscribeToTrader2bWatchList();
    this.subscribeToFirebaseInitialization();
    this.subscribeToMostActiveWatchList();

    window.addEventListener("beforeunload", this.unload);

    modalSvc.closeWorkspaceConfirmed$
      .pipe(filter((x) => x !== false))
      .subscribe(() => {
        if (this.delWorkspaceId !== undefined) {
          this.deleteWorkspace(this.delWorkspaceId);
        }
      });

    modalSvc.workspaceRenamed$
      .pipe(filter((x) => !!x))
      .subscribe((workspaceNewName) => {
        this.saveWorkspaceName(workspaceNewName, this.renameWorkspaceId);
        modalSvc.showRenameWorkspaceModal$.next(false);
      });
  }

  componentDidUpdate(prevProps: Props) {
    const {
      isDetachWindowDetailsEnabled,
      isLoggedIn,
      isUserClosedWidget,
    } = this.props;

    if (
      isDetachWindowDetailsEnabled &&
      isDetachWindowDetailsEnabled !== prevProps.isDetachWindowDetailsEnabled
    ) {
      const { detachWindowDetails } = globalCache;
      if (detachWindowDetails) {
        const width: any = detachWindowDetails.width;
        const height: any = detachWindowDetails.height;
        const left: any = window.top
          ? window.top.outerWidth / 2 + window.top.screenX - width / 2
          : 0;
        const top = window.top
          ? window.top.outerHeight / 2 + window.top.screenY - height / 2
          : 0;

        const url = window.location.href + "detachWindow";
        window.open(
          url,
          "",
          `height=${height},width=${width},scrollbars=yes,top=${top},left=${left}`
        );

        store.dispatch(setDetachWindowDetailsInProgress(false));

        // detachWindow &&
        //   detachWindow.document.writeln(
        //     `<html><head><title>${value.title}</title></head><body height="100%" width="100%"><iframe src="http://localhost:3000/detachWindow" height="100%" width="100%"></iframe></body></html>`
        //   );
      }
    }

    if (this.props.openTimeAndSales === true) {
      const widget = this.addWidget("time-and-sales") as Widget;
      this.props.setWidgetSymbol(widget.key, this.props.openedSymbol);
      this.props.clearTimeAndSalesFlag();
    }

    if (!isLoggedIn && this.dataLoadedFromCache) {
      this.setState({ workspaces: {} });
      this.dataLoadedFromCache = false;
      tempCache.isDataLoaded = false;
    }

    // this.loadDataFromCache();

    if (isUserClosedWidget) {
      this.removeWidget(this.index);
    }

    if (!isLoggedIn && prevProps.isLoggedIn !== this.props.isLoggedIn) {
      soundSvc.play(sounds.logout);
    }

    if (store.getState().users.searchParams) {
      const searchParams = store.getState().users.searchParams;
      let workSpaceExists = false;
      store.dispatch(setSearchParams(undefined));
      this.setState({
        searchString: searchParams.value,
        searchParam: searchParams.key,
      });
      if (this.state.workspaces) {
        for (let workspaceId in this.state.workspaces) {
          if (this.state.workspaces[workspaceId].title === "Search Results") {
            this.setWorkspace(workspaceId);
            workSpaceExists = true;
          }
        }
      }
      if (!workSpaceExists) {
        setTimeout(() => {
          this.addWorkspace("Search Results");
        }, 1000);
      }
    }
    let widgetTitle =
      store.getState().users.formData &&
      store.getState().users.formData.fieldName
        ? store.getState().users.formData.fieldName
        : "";

    let widgetId =
      store.getState().users.formData &&
      store.getState().users.formData.fieldData
        ? store.getState().users.formData.fieldData
        : 0;
    if (widgetTitle === "Orders" || widgetTitle === "Positions") {
      widgetId = `${widgetId.AccountId}_${widgetId.Account.Name}_${widgetTitle}`;
    }
    if (this.props.showEditForm) {
      this.addWidget({ key: "form", id: widgetId, title: widgetTitle });
      store.dispatch(showEditForm({ status: false }));
    } else if (
      !this.props.showEditForm &&
      store.getState().users.formData &&
      !widgetId
    ) {
      const workspaces = { ...this.state.workspaces };
      const selectedWorkspace = {
        ...workspaces[this.state.selectedWorkspaceKey],
      };
      const widgets = [...selectedWorkspace.widgets];
      const index = widgets.findIndex((widget) => widget.title === widgetTitle);
      if (index !== -1) this.removeWidget(index);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.unload);
    this.dataLoadedFromCache = false;
    tempCache.isDataLoaded = false;
  }

  handleSidebarCollapse = () => {
    this.setState({ sidebarCollapse: !this.state.sidebarCollapse });
    if (this.state.selectedWorkspaceKey === "Default") {
      setTimeout(() => {
        this.showDefaultLayout("Default");
      }, 1000);
    }
  };

  render() {
    const { isLoggedIn } = this.props;
    const { selectedWorkspaceKey, workspaces, unsavedWorkspaceIds, isWorkspaceLoaded } =
      this.state;
    const {searchResults} = store.getState().users;
    const widgetHandlerProps = {
      addNewWidget: this.addWidget,
      trader2B_Click: this.trader2B_Click,
      mostActive_Click: this.mostActive_Click,
      handleSidebarCollapse: this.handleSidebarCollapse,
    };

    return (
      <>
        <FlexView column>
          <Sidebar {...widgetHandlerProps} />
        </FlexView>
        {
          isWorkspaceLoaded
          ?
          <FlexView
            column
            className={`main-view ${
              this.state.sidebarCollapse ? "pl-3 side-collap" : "pl-5 "
            } `}
            onClick={this.click}
          >
            <ConfirmationModal />
            <WidgetCloseConfirmation />
            <RenameWorkspace />
            <WorkspaceCloseModal />
            {isLoggedIn && (
              <>
                  <div key={"workspace-div"} className="d-flex-column">
                    <Header key={"header-key"} />
                    <FlexView key={"main-flex-view"} className="workspaces-list p-0">
                      <FlexView
                        key={"workspace-flex-view"}
                        hAlignContent="left"
                        vAlignContent="center"
                        marginRight="10"
                        grow
                        wrap
                      >
                        {workspaces &&
                          Object.values(workspaces).map((workspace, index) => {
                            if (workspace.title === "Search Results" && !searchResults?.IsSuccess) {
                              return <></>
                            }
                            return (
                              <div
                                key={index}
                                className="workspace-tab-container"
                              >
                                <div
                                  id={workspace.id}
                                  key={workspace.id}
                                  className={
                                    workspace.id === selectedWorkspaceKey
                                      ? "workspace-tab selected btn"
                                      : "workspace-tab btn"
                                  }
                                  onClick={() => this.setWorkspace(workspace.id)}
                                  onKeyPress={(e) =>
                                    this.saveWorkspaceName(e, workspace.id)
                                  }
                                >
                                  {workspace.title}{" "}
                                  {unsavedWorkspaceIds.indexOf(workspace.id) !== -1
                                    ? " *"
                                    : ""}
                                  {
                                    ADMIN_DEFAULT_WORKSPACES.includes(workspace.id)
                                      ?
                                      <Dropdown className="workspace-menu-dropdown">
                                        <Dropdown.Toggle variant="link" bsPrefix="p-0">
                                          <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={(e) => this.saveWorkspace(workspace.id)}
                                          >
                                            Save
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      :
                                      <Dropdown className="workspace-menu-dropdown">
                                        <Dropdown.Toggle variant="link" bsPrefix="p-0">
                                          <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {workspace.id !== "Default" && (
                                            <>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  this.renameWorkspaceId = workspace.id;
                                                  modalSvc.showRenameWorkspaceModal$.next(
                                                    true
                                                  );
                                                }}
                                              >
                                                Rename
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  modalSvc.showCloseWorkspaceConfirmation$.next(
                                                    true
                                                  );
                                                  this.delWorkspaceId = workspace.id;
                                                }}
                                              >
                                                Delete
                                              </Dropdown.Item>
                                            </>
                                          )}
                                          <Dropdown.Item
                                            onClick={(e) => this.saveWorkspace(workspace.id)}
                                          >
                                            Save
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={(e) =>
                                              this.showDefaultLayout(workspace.id)
                                            }
                                          >
                                            Default Layout
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                  }
                                </div>
                              </div>
                            )
                          })}

                        {Object.keys(workspaces).length <= 20 && (
                          <button
                            key={"Add_Workspace"}
                            className="btn customBtnAdd"
                            onClick={(e) => {
                              this.addWorkspace();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fas", "plus"]}
                              className="icon-add-workspace"
                            />
                          </button>
                        )}
                      </FlexView>
                    </FlexView>
                  </div>
                  <FlexView
                    id="widgets-area"
                    key={"flex-area-for-widget"}
                    className={` ${workspaces[selectedWorkspaceKey].widgets.length > 0
                        ? `widgets-area `
                        : `widgets-area justify-content-center`
                      } 
                  ${workspaces[selectedWorkspaceKey].widgets.length < 1
                        ? `widgets-scroll`
                        : `overflow-hidden`
                      }
                  ${workspaces[selectedWorkspaceKey].title === "Search Results" &&
                        workspaces[selectedWorkspaceKey].id !== "Default"
                        ? "trans-shell"
                        : ""
                      }
                  `}
                  >
                    {workspaces &&
                      Object.keys(workspaces) &&
                      Object.keys(workspaces).map((workspaceKey) => {
                        if(selectedWorkspaceKey !== workspaceKey)
                        {
                          return <></>
                        }
                        return (
                          <div
                            key={workspaceKey}
                            className={`${selectedWorkspaceKey === workspaceKey
                                ? "h-100 w-100"
                                : "d-none"
                              }`}
                          >
                            {workspaces[workspaceKey].title === "Search Results" ? (
                              <>
                                <SearchResults />
                                {workspaces[workspaceKey].widgets.map(
                                  (widget, index) => {
                                    return (
                                      <ShellView
                                        {...widget}
                                        id={widget.key}
                                        workspaceId={workspaceKey}
                                        close={() => {
                                          this.index = index;
                                          this.props.updateWidgetDisplay(true);
                                          soundSvc.play(sounds.confirmation);
                                        }}
                                        maximize={(isMaximized: boolean) =>
                                          this.maximizeWidget(index, isMaximized)
                                        }
                                        update={(props: IShellViewState) =>
                                          this.updateWidget(index, props)
                                        }
                                        key={index}
                                      ></ShellView>
                                    );
                                  }
                                )}
                              </>
                            ) : workspaces[workspaceKey].widgets.length > 0 ? (
                              workspaces[workspaceKey].widgets.map(
                                (widget, index) => {
                                  return (
                                    <ShellView
                                      {...widget}
                                      id={widget.key}
                                      workspaceId={workspaceKey}
                                      close={() => {
                                        this.index = index;
                                        this.props.updateWidgetDisplay(true);
                                        soundSvc.play(sounds.confirmation);
                                        // this.removeWidget(index);
                                      }}
                                      // lock={(isLocked: boolean) => this.lockWidget(index, isLocked)}
                                      maximize={(isMaximized: boolean) =>
                                        this.maximizeWidget(index, isMaximized)
                                      }
                                      update={(props: IShellViewState) =>
                                        this.updateWidget(index, props)
                                      }
                                    ></ShellView>
                                  );
                                }
                              )
                            ) : (
                              <FlexView
                                className="empty-workspace-message h-100"
                                hAlignContent="center"
                                vAlignContent="center"
                                grow
                              >
                                Click widget to setup your workspace
                              </FlexView>
                            )}
                          </div>
                        );
                      })}
                  </FlexView>
              </>
            )}
          </FlexView>
          :
          <div className="theme-new-bg d-flex justify-content-center">
            <CustomSpinner />
          </div>
        }
      </>
    );
  }

  addWorkspace = (workspceName?: string, temporaryWorkSpace?: boolean) => {
    const { workspaces } = this.state;

    const workspaceCount = Object.keys(workspaces).length;
    const id = uuid();
    this.prevWorkspaceId = Object.keys(workspaces).pop();
    let counter;

    if (workspaceCount === 1) {
      counter = workspaceCount;
    } else {
      counter = workspaces[this.prevWorkspaceId].counter + 1;
    }

    const workspace = {
      id,
      counter,
      // title: "Workspace " + id.substr(0, 4),
      title: workspceName || "Workspace " + counter,
      widgets: [],
    };

    workspaces[workspace.id] = workspace;

    this.setState({ workspaces });
    this.setWorkspace(id);

    const { userId } = this.props;

    const { workspaces: allWorkspaces } = globalCache;
    allWorkspaces[userId] = workspaces;
    if(!temporaryWorkSpace)
    {
      globalCache.workspaces = allWorkspaces;
      firebaseSvc.saveSettings("workspaces", workspaces);
    }
    return {workspaces, id};
  };

  loadWorkspaces = async (): Promise<IWorkspaceDictionary | null> => {
    let workspaces: IWorkspaceDictionary | null = null;

    workspaces = await firebaseSvc.getSettings<IWorkspaceDictionary>(
      "workspaces"
      );
      
      
      let layouts = await firebaseSvc.getGridLayout();
      
      if(layouts)
      {
        globalCache.agGridColumns = layouts;
      }
      
      if (!workspaces || !Object.entries(workspaces).length || !Object.keys(workspaces).some((w) => !ADMIN_DEFAULT_WORKSPACES.includes(w))) {
        return this._setDefaultWorkspaces();
      }

    this.setState({ workspaces });
    return workspaces;
  };

  loadDataFromCache = async () => {
    this.loadWorkspaces().then((workspaces) => {
      this.loadLinkedWidgets(workspaces);

      firebaseSvc
        .getSettings<IWidgetSymbolsDictionary>("widget-symbols")
        .then((widgetSymbols) =>
          this.props.loadWidgetSymbols(widgetSymbols || {})
        );
    }).finally(() => {
      this.setState({isWorkspaceLoaded: true});
    });

    firebaseSvc
      .getSettings<IKeyboardShortcutSetting[]>("keyboard-shortcuts")
      .then((keyboardShortcuts) =>
        store.dispatch(setKeyboardShortcutSetting(keyboardShortcuts || []))
      );

    firebaseSvc
      .getSettings<IShortcutCombination[]>("shortcut-combinations")
      .then((shortcutCombinations) =>
        store.dispatch(
          setShortcutCombinationSetting(shortcutCombinations || [])
        )
      );

    firebaseSvc
      .getSettings<boolean>("is-shortcut-combination-allowed")
      .then((isShortcutCombinationAllowed) => {
        store.dispatch(
          setSelectedShortcutCombinationSettingToAllowed(
            isShortcutCombinationAllowed || false
          )
        );
      });

    tempCache.isDataLoaded = true;
  };

  loadLinkedWidgets = async (workspaces: IWorkspaceDictionary | null) => {
    if (workspaces) {
      const widgetIds = Object.keys(workspaces).map(
        (workspaceId) => workspaces[workspaceId].widgets
      );

      const linkedWidgets = await firebaseSvc.getSettings<ILinkedWidget[]>(
        "linked-widgets"
      );

      if (!linkedWidgets) {
        return;
      }

      linkedWidgets.forEach((linkedWidget) => {
        for (let idx = linkedWidget.widgets.length - 1; idx >= 0; idx--) {
          const widgetId = linkedWidget.widgets[idx];
          const isWidgetSaved = widgetIds.findIndex(
            (x) => x.findIndex((y) => y.key === widgetId) !== -1
          );

          if (isWidgetSaved === -1) {
            linkedWidget.widgets.splice(idx, 1);
          }
        }

        if (linkedWidget.widgets.length === 0) {
          const linkedWidgetIndex = linkedWidgets.indexOf(linkedWidget);
          linkedWidgets.splice(linkedWidgetIndex, 1);
        }
      });

      this.props.loadLinkedWidgets(linkedWidgets);
    }
  };

  addUnsavedWorkspaceId = () => {
    const { selectedWorkspaceKey, unsavedWorkspaceIds } = this.state;

    if (unsavedWorkspaceIds.indexOf(selectedWorkspaceKey) === -1) {
      this.setState({
        unsavedWorkspaceIds: [...unsavedWorkspaceIds, selectedWorkspaceKey],
      });
    }
  };

  setupDefaultLayoutWidgets = (workspace: IWorkspace) => {
    if (!workspace) {
      return;
    }
    workspace.widgets = MainViewState.defaultLayout;
    workspace.widgets.forEach((widget) => {
      this.props.linkWidget(workspace.id, widget.key, "Red", "AAPL");
    });
    this.addUnsavedWorkspaceId();
  };

  setupDefaultAdminWorkspaces = (workspaces: IWorkspaceDictionary) => {

    ADMIN_DEFAULT_WORKSPACES.forEach((workspace, index) => {

      let widgets: Widget[] = [];

      switch (workspace) {
        case "Users":
          widgets = MainViewState.usersWorkspace;
          break;
        case "Accounts":
          widgets = MainViewState.accountsWorkspace;
          break;
        case "Positions":
          widgets = MainViewState.positionsWorkspace;
          break;
        case "Orders":
          widgets = MainViewState.ordersWorkspace;
          break;
        case "Account Performance":
          widgets = MainViewState.accountPerformanceWorkspace;
          break;
      }

      workspaces[workspace] = {
        counter: index + 1,
        id: workspace,
        title: workspace,
        widgets
      }
    });
  }

  _setDefaultWorkspaces = async (): Promise<IWorkspaceDictionary> => {
    const { workspaces } = new MainViewState();
    this.setupDefaultAdminWorkspaces(workspaces);
    this.setupDefaultLayoutWidgets(workspaces[Defaults.workspace]);
    this.setState({
      selectedWorkspaceKey: Defaults.workspace,
      workspaces,
    });
    firebaseSvc.saveSettings("workspaces", workspaces);
    return workspaces;
  };

  click = () => {
    if (this.props.modalVisible || this.isModalVisible) {
      soundSvc.play(sounds.error);
    }
  };

  addWidget = (data: any) => {
    if (((typeof data === "string" && !data.toLowerCase().includes("add") && !data.toLowerCase().includes("edit")) || ((data.title && ["Orders", "Positions"].includes(data.title))))) {
      const { id, workspaces } = this.addWorkspace(typeof data === "string" ? data : data.title, true);
      data = typeof data === "string" ? { key: data } : data;
      return this.processWidgetOperation("add", -1, data, workspaces, id);
    }
    else {
      data = typeof data === "string" ? { key: data } : data;
      return this.processWidgetOperation("add", -1, data);
    }

  };

  authenticateUser(username: string, password: string) {
    tempCache.username = username;
    tempCache.password = password;

    const credentials = { username, password };

    store.dispatch(setLoginCredentials(credentials));
    store.dispatch(authenticateUser);
  }

  maximizeWidget(index: number, isMaximized: boolean) {
    this.processWidgetOperation("maximize", index, isMaximized);
  }

  mostActive_Click = () => {
    this.props.showMostActiveSymbolsList();
  };

  processWidgetOperation(operation: string, index: number, data: any, workspaceList?, currentWorkspace?) {
    // immutable state properties
    const workspaces = workspaceList || { ...this.state.workspaces };
    const selectedWorkspace = currentWorkspace ? workspaceList[currentWorkspace] : {
      ...workspaces[this.state.selectedWorkspaceKey],
    };

    const widgets = [...selectedWorkspace.widgets];
    let widget: Widget | undefined;
    let workspaceKey = currentWorkspace || this.state.selectedWorkspaceKey;

    switch (operation) {
      case "add":
        let lengthOfWidgets =
          this.state.workspaces[workspaceKey].widgets.length; // Get length of widgets array
        const widgetConfig = widgetsConfig[data.key];

        widget = new Widget(
          data.title || widgetConfig.title,
          widgetConfig.width,
          widgetConfig.height,
          widgetConfig.maxHeight,
          widgetConfig.maxWidth,
          widgetConfig.dropdownValues,
          0,
          0,
          widgetConfig.icon,
          data.id || ""
        );

        // check whether widgets array is empty
        if (data.id) {
          widget.positionX = 450;
          widget.positionY = 25;
        } else {
          widget.positionX = window.innerWidth / 3;
          widget.positionY = window.innerHeight / 12;
        }
        // else if (lengthOfWidgets === 0) {
        //   widget.positionX = window.innerWidth / 3;
        //   widget.positionY = window.innerHeight / 12;
        // }
        // else {
        //   let latestWidget =
        //     this.state.workspaces[workspaceKey].widgets[lengthOfWidgets - 1];
        //   widget.positionX = latestWidget.positionX + 25;
        //   widget.positionY = latestWidget.positionY + 25;
        // }

        widget.canBeMaximized = widgetConfig.canBeMaximized;
        widget.isLinkingEnabled = widgetConfig.isLinkingEnabled;
        widget.isMaximized = !widget.title.toLowerCase().includes("add") && !widget.title.toLowerCase().includes("edit");
        widget.watchListType = data.watchListType;
        widgets.push(widget);

        this.props.setActiveWidget(workspaceKey, widget.key);
        break;

      case "maximize":
        widgets.forEach((widget, pos) => {
          if (pos !== index && !widgets[index].isMaximized) {
            jquery(`#${widget.key}`).addClass("d-none");
          } else {
            jquery(`#${widget.key}`).removeClass("d-none");
          }
        });
        widgets[index] = {
          ...widgets[index],
          isMaximized: data,
        };

        break;

      case "remove":
        widgets.forEach((widget, pos) => {
          if (pos !== index && widgets[index] && widgets[index].isMaximized) {
            jquery(`#${widget.key}`).removeClass("d-none");
          }
        });
        widget = widgets[index];
        if (widget && widget.key) {
          this.props.unlinkWidget(widget.key);
        }
        widgets.splice(index, 1);

        break;

      case "update":
        widgets[index] = {
          ...widgets[index],
          height: data.height,
          width: data.width,
          positionX: data.position.x,
          positionY: data.position.y,
        };

        break;
    }

    // set widgets to selected workspace in immutable manner
    selectedWorkspace.widgets = widgets;
    workspaces[workspaceKey] = selectedWorkspace;
    this.setState({ workspaces });

    return widget;
  }

  removeWidget = (index: number) => {
    this.processWidgetOperation("remove", index, null);
    this.props.updateUserCloseWidget(false);
  };

  setWorkspace = (selectedWorkspaceKey: string) => {
    this.setState({ selectedWorkspaceKey });
  };

  trader2B_Click = () => {
    showTrader2BWatchList();
  };

  updateWidget(index: number, props: IShellViewState) {
    this.processWidgetOperation("update", index, props);
  }

  validateCache = () => {
    const { cacheVersion } = globalCache;
    globalCache.cacheVersion = CACHE_VERSION;
    if (cacheVersion && cacheVersion !== CACHE_VERSION) {
      globalCache.clear();
      this.props.resetAllSettings();
      return false;
    }

    return true;
  };

  enableWorkspaceRenaming(workspaceId) {
    const tab = document.getElementById(workspaceId);

    if (tab) {
      tab.contentEditable = "true";
      tab.focus();
      this.previousName = tab.innerHTML;
    }
  }

  async saveWorkspaceName(name, workspaceId: string) {
    
    if (name.length > 1) {
      const workspaces = this.state.workspaces;
      workspaces[workspaceId].title = name;
      firebaseSvc.saveSettings("workspaces", workspaces);
      this.setState({workspaces});
    }
  }

  async deleteWorkspace(workspaceId: string) {
    const workspaces = { ...this.state.workspaces };
    const savedWorkspaces =
      (await firebaseSvc.getSettings<IWorkspaceDictionary>("workspaces")) || {};

    if (savedWorkspaces) {
      if (savedWorkspaces[workspaceId]) {
        delete savedWorkspaces[workspaceId];
        firebaseSvc.saveSettings("workspaces", savedWorkspaces);

        this.setState({
          workspaces: savedWorkspaces,
          selectedWorkspaceKey: "Default",
        });
      } else {
        delete workspaces[workspaceId];
        firebaseSvc.saveSettings("workspaces", workspaces);
        this.setState({
          workspaces,
          selectedWorkspaceKey: "Default",
        });
      }
    }

    const { unsavedWorkspaceIds } = this.state;
    this.setState({
      unsavedWorkspaceIds: unsavedWorkspaceIds.filter((x) => x !== workspaceId),
    });
  }

  async saveGridLayouts() {
    const gridLayouts = globalCache.agGridColumns;
    firebaseSvc.saveGridLayout(gridLayouts);
  }

  async saveWorkspace(workspaceId: string) {
    const userWorkspaces = await firebaseSvc.getSettings<IWorkspaceDictionary>(
      "workspaces"
    );

    if (!userWorkspaces) {
      return;
    }

    userWorkspaces[workspaceId].widgets =
      this.state.workspaces[workspaceId].widgets;
    firebaseSvc.saveSettings("workspaces", userWorkspaces);
    this.saveGridLayouts()

    const { unsavedWorkspaceIds } = this.state;
    this.setState({
      unsavedWorkspaceIds: unsavedWorkspaceIds.filter((x) => x !== workspaceId),
    });

    notificationSvc.success("Workspace saved successfully");
  }

  showDefaultLayout(workspaceId: string) {
    const { workspaces } = this.state;
    this.setupDefaultLayoutWidgets(workspaces[workspaceId]);
  }

  parseJwtToken<T extends object = { [k: string]: string | number }>(
    token: string
  ): T | undefined {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch {
      return undefined;
    }
  }

  showWatchList = (symbols: string[], watchListType: WatchListTypeEnum) => {
    const { selectedWorkspaceKey, workspaces } = this.state;
    const selectedWorkspace = workspaces[selectedWorkspaceKey];

    let watchListWidget = selectedWorkspace.widgets.find(
      (x) => x.watchListType === watchListType
    );

    if (!watchListWidget) {
      watchListWidget = this.addWidget({
        key: "watch-list",
        watchListType,
      }) as Widget;
    }

    this.props.setWidgetSymbols(watchListWidget.key, symbols);
  };

  subscribeToIsLoggedIn = () => {
    authSvc.isLoggedIn$.subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.props.disconnectQuoteMediaStream();
        this.setState({ unsavedWorkspaceIds: [] });
      }
    });
  };

  subscribeToFirebaseInitialization = () => {
    let prevUserId = "";
    firebaseSvc.userId$.subscribe((userId) => {
      if (!userId) {
        prevUserId = "";
      } else if (userId !== prevUserId) {
        prevUserId = userId;

        this.loadDataFromCache();
      }
    });
  };

  subscribeToMostActiveWatchList = () => {
    mostActiveWatchListSymbols$
      .pipe(filter((x) => x.length > 0))
      .subscribe((symbols) => {
        this.showWatchList(symbols, WatchListTypeEnum.MostActive);
      });
  };

  subscribeToTrader2bWatchList = () => {
    trade2BWatchListSymbols$
      .pipe(filter((x) => x.length > 0))
      .subscribe((symbols) =>
        this.showWatchList(symbols, WatchListTypeEnum.Trader2B)
      );
  };

  updateTime = () => {
    this.setState({ currentTime: Date.now() });
  };

  unload = (e: BeforeUnloadEvent) => {
    if (this.state.unsavedWorkspaceIds.length > 0) {
      e.preventDefault();
      e.returnValue =
        "There are some unsaved workspaces. Are you sure you want to close the WebTrader?";
    }
    const detachedWidgets = store.getState().widgets.detachedWindows;
    detachedWidgets.forEach((w) => {
      w.close();
    });
  };
}

const mapStateToProps = (state: IAppState): IMainViewMappedProps => ({
  accountId: state.account.accountId,
  isDetachWindowDetailsEnabled: state.account.isDetachWindowDetails,
  isLoggedIn: state.auth.authInfo !== undefined,
  modalVisible: state.settings.isWidgetDisplayedModalShown,
  openedSymbol: state.levelII.symbol,
  openTimeAndSales: state.levelII.openTimeAndSales,
  qmSid: state.qm.sid,
  token: state.auth.authInfo?.RefreshToken || "",
  userId: state.auth.authInfo?.UserId || "",
  username: state.auth.username,
  isUserClosedWidget: state.settings.isUserClosedWidget || false,
  searchParams: state.users.searchParams,
  showEditForm: state.users.showEditForm,
});

const mapDispatchToProps = (dispatch: any): IMainViewDispatchProps => ({
  clearTimeAndSalesFlag: () => dispatch(clearTimeAndSalesFlag()),
  disconnectQuoteMediaStream: () => dispatch(disconnectQuoteMediaStream()),
  linkWidget: (workspaceId, widgetId, link, symbol) =>
    dispatch(linkWidget(workspaceId, widgetId, link, symbol)),
  loadLinkedWidgets: (linkedWidgets) =>
    dispatch(loadLinkedWidgets(linkedWidgets)),
  loadWidgetSymbols: (widgetSymbols) =>
    dispatch(loadWidgetSymbols(widgetSymbols)),
  resetAllSettings: () => dispatch(resetAllSettings()),
  setActiveWidget: (workspaceId, widgetId) =>
    dispatch(setActiveWidget(workspaceId, widgetId)),
  setWidgetSymbol: (widgetId, symbol) =>
    dispatch(setWidgetSymbol(widgetId, symbol)),
  setWidgetSymbols: (widgetId, symbols) =>
    dispatch(setWidgetSymbols(widgetId, symbols)),
  showMostActiveSymbolsList: () => dispatch(showMostActiveSymbolsList),
  unlinkWidget: (widgetId) => dispatch(unlinkWidget(widgetId)),
  updateUserCloseWidget: (response) => dispatch(setUserClosedWidget(response)),
  updateWidgetDisplay: (response) => dispatch(setWidgetDisplayed(response)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
