export enum OrderSourceEnum {
  TradingPlatform = 1,
  Admin = 2,
  AutoFlatter = 3,
  Android = 4,
  IOS = 5,
  MobileService = 6,
  WebTrader = 7,
  WebAdmin = 8,
  Script = 9,
  AutoTradeAPI = 10,
};
