export enum ConfigSubCategoryEnum {
  NONE = 0,
  ACCOUNT = 1,
  SYMBOL = 2,
  PERMISSION = 3,
  CONFIG = 4,
  STATUS = 5,
  GROUP = 6,
  ACC_TRADE_LIMIT = 7,
  SYM_TRADE_LIMIT = 8,
  DESTINATION = 9,
  FIRM = 10,
  FIRM_TRADE_LIMIT = 11,
  GROUP_TRADE_LIMIT = 11,
  DATA_SOURCE = 12,
  LOG = 13,
  BALANCE = 14,
  BP = 15,
  MAXLOSS = 16,
  POSITION = 17,
  ORDER = 18,
  AF = 19,
}
