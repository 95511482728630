import { IAccount } from "../../models/IAccount";
import { IAccountDestination } from "../../models/IAccountDestination";
import { IAccountOrder } from "../../models/IAccountOrder";
import { IAccountTradeLimit } from "../../models/IAccountTradeLimit";
import { IOrderExecution } from "../../models/IOrderExecution";

export class AccountState {
  accountId = 0;
  buyingPower = 0;
  destinationsLookup: IAccountDestination[] = [];
  orderExecutions: IOrderExecution[] = [];
  orderLookup:  Map<BigInt, IAccountOrder> = new Map<BigInt, IAccountOrder>();
  tradeLimits: IAccountTradeLimit[] = [];
  userAccount = {} as IAccount;
  userAccounts: IAccount[] = [];
  userOrders: IAccountOrder[] = [];
  userSymbols: string[] = [];
  isDetachWindowDetails = false;
  cryptoSymbols: string[] = [];
}
