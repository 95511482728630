import { ColumnModel, Selection } from "@syncfusion/ej2-grids";
import {
  ColumnDirective,
  ColumnsDirective,
  DataSourceChangedEventArgs,
  Edit,
  EditSettingsModel,
  Grid,
  GridComponent,
  InfiniteScroll,
  Inject,
  Page,
  PageSettingsModel,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import * as React from "react";
import { Component } from "react";
import FlexView from "react-flexview/lib";
import strategySvc from "../../../services/strategy-service";
import { setTimeToUTC } from "../../../utils/datetime";
import CustomSpinner from "../spinner/spinner";
import { IStrategyListProps } from "./IStrategyListProps";
import { IStrategyState } from "./IStrategyState";

type Props = IStrategyListProps;

class StrategiesList extends Component<Props, IStrategyState> {
  public editOptions: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Normal",
  };
  public toolbarOptions: ToolbarItems[] = ["Add", "Edit", "Delete", "Update", "Cancel", "Search"];
  public pageSettings: PageSettingsModel = { pageSize: 10 };

  private columnDefs: ColumnModel[] = [];

  private grid: Grid | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      strategies: [],
    };
    this.columnDefs = this.getColumnDefs();
  }

  componentDidMount() {
    if (!this.allListPopulated()) {
      strategySvc.get().then((strategies) => this.setState({ strategies }));
    }
  }

  debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = StrategiesList;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  onLoad = () => {
    const { strategies } = this.state;
    if (this.grid) {
      const data = {
        result: strategies,
        count: strategies.length,
      };
      this.grid.dataSource = data;
      this.grid.element.addEventListener(
        "keydown",
        this.debounce((e) => {
          if (e.target.getAttribute("id")?.indexOf("_searchbar") !== -1 && this.grid) {
            this.grid.search((e.target as HTMLInputElement).value);
          }
        }, 200)
      );
    }
  };

  allListPopulated = () => {
    const { strategies } = this.state;
    if (strategies?.length > 0) {
      this.columnDefs = this.getColumnDefs();
      return true;
    }
  };

  render() {
    const { strategies } = this.state;

    if (this.allListPopulated()) {
      const gridData = {
        result: strategies,
        count: strategies.length,
      };
      return (
        <FlexView className="hw-admin-grid ag-theme-alpine">
          <GridComponent
            id="grid"
            height="100%"
            width="100%"
            pageSettings={this.pageSettings}
            editSettings={this.editOptions}
            toolbar={this.toolbarOptions}
            load={this.onLoad}
            dataSource={gridData}
            dataSourceChanged={this.dataSourceChanged}
            enableInfiniteScrolling={true}
            ref={(g) => (this.grid = g)}
          >
            <ColumnsDirective>
              {this.columnDefs.map((column) => (
                <ColumnDirective key={column.field} headerText={column.headerText} {...column} />
              ))}
            </ColumnsDirective>
            <Inject services={[Edit, Toolbar, InfiniteScroll, Page, Selection]} />
          </GridComponent>
        </FlexView>
      );
    } else {
      return (
        <div className="m-auto">
          <CustomSpinner />
        </div>
      );
    }
  }

  getColumnDefs = (): ColumnModel[] => {
    return [
      {
        field: "Name",
        headerText: "Name",
        validationRules: { required: true },
        width: "150",
      },
    ];
  };

  dataSourceChanged = async (args: DataSourceChangedEventArgs) => {
    const item: any = args.data;
    let obj: any;

    if (args.action === "add" || args.action === "edit") {
      obj = {
        StrategyId: 0,
        Name: item?.Name,
        CreatedOn: setTimeToUTC(new Date()),
        CreatedBy: 13,
        ModifiedOn: setTimeToUTC(new Date()),
        ModifiedBy: 13,
      };
    }
    if (args.action === "add") {
      strategySvc.add(obj);
    } else if (args.action === "edit") {
      obj.StrategyId = item.StrategyId;
      strategySvc.add(obj);
    } else if (args.requestType === "delete") {
      strategySvc.delete(item[0].StrategyId);
    }
  };
}

export default StrategiesList;
