import { ClientAppEnum } from "../enums/ClientAppEnum";
import { NatsMessageTypeEnum } from "../enums/NatsMessageTypeEnum";
import { authSvc } from "../services/auth-service";
import {
  int64FromUint8Array,
  numberFromUint8Array,
  stringFromUint8Array,
} from "../utils/decoder";
import { longToUint8Array, stringToAsciiArray } from "../utils/encoder";

export class ClientHeartBeat {
  private static _offset = 5;
  private _buffer: Uint8Array;

  constructor(buffer?: Uint8Array) {
    this._buffer = buffer || new Uint8Array(128);

    if (buffer) {
      this.debug();
    }
  }

  get size(): number {
    return numberFromUint8Array(this._buffer, 0);
  }

  set size(value: number) {
    this._buffer.set([value], 0);
  }

  get messageType(): NatsMessageTypeEnum {
    return numberFromUint8Array(this._buffer, 1) as NatsMessageTypeEnum;
  }

  set messageType(value: NatsMessageTypeEnum) {
    this._buffer.set([value], 1);
  }

  get clientAppInstanceIdIndex(): number {
    return numberFromUint8Array(this._buffer, 2);
  }

  get clientAppInstanceIdLength(): number {
    return numberFromUint8Array(this._buffer, 3);
  }

  get clientApp(): ClientAppEnum {
    return numberFromUint8Array(this._buffer, 4) as ClientAppEnum;
  }

  set clientApp(value: ClientAppEnum) {
    this._buffer.set([value], 4);
  }

  get userId(): bigint {
    return int64FromUint8Array(this._buffer, ClientHeartBeat._offset);
  }

  set userId(value: bigint) {
    this._buffer.set(longToUint8Array(value), ClientHeartBeat._offset);
  }

  get loginTime(): number {
    return Number(
      int64FromUint8Array(this._buffer, ClientHeartBeat._offset + 8)
    );
  }

  set loginTime(value: number) {
    this._buffer.set(
      longToUint8Array(BigInt(value)),
      ClientHeartBeat._offset + 8
    );
  }

  get clientAppInstanceId(): string {
    return stringFromUint8Array(
      this._buffer,
      this.clientAppInstanceIdIndex,
      this.clientAppInstanceIdLength
    );
  }

  set clientAppInstanceId(value: string) {
    const idx = ClientHeartBeat._offset + 16;
    this._buffer.set([idx + value.length], 0);
    this._buffer.set([idx], 2);
    this._buffer.set([value.length], 3);
    this._buffer.set(stringToAsciiArray(value), idx);
  }

  static create(userId: bigint, instanceId: string) {
    const heartbeat = new ClientHeartBeat();
    heartbeat.messageType = NatsMessageTypeEnum.ClientHeartBeat;
    heartbeat.clientApp = ClientAppEnum.WebTrader;
    heartbeat.userId = userId;
    heartbeat.clientAppInstanceId = instanceId;
    heartbeat.loginTime = authSvc.loginTime;
    return heartbeat;
  }

  debug() {
//     console.debug(`messageType: ${this.messageType}\n
// size: ${this.size}\n
// userId: ${this.userId}\n
// clientApp: ${this.clientApp}\n
// instanceId: ${this.clientAppInstanceId}\n
// loginTime: ${new Date(this.loginTime)}`);
  }

  encode() {
    return this._buffer;
  }
}
