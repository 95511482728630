import { useEffect, useRef, useState } from 'react'
import AggridList from '../ag-grid-list/aggrid-list';
import { GridOptions } from 'ag-grid-community';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CustomSpinner from '../spinner/spinner';
import { firmColumnsDefinition } from './FirmColumnsDefinition';
import { loadFirms } from '../../../store/firms/firms-async-actions';
import { ContextMenuOption } from '../../context-menu/context-menu-types';
import store from '../../../store';
import { showEditForm } from '../../../store/users/users-actions';
import editIcon from "../../../Images/editicon.svg";
import copyIcon from '../../../Images/copyicon.svg';
import { copyToClipboard } from '../../../utils/general';
import { IAppState } from '../../../store/reducers/IAppState';
import { loadAccounts } from '../../../store/admin-accounts/accounts-async-actions';

type Props = {
  windowId: string,
  searchResults?: any[]
}

const FirmList = ({windowId, searchResults}: Props) => {

  const gridOptions: GridOptions = {
    columnDefs: firmColumnsDefinition
  };

  const dispatch = useDispatch();
  const formData = useSelector((state: IAppState) => state.users.formData);

  const [firmsData, setFirmsData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [currentRow, setCurrentRow] = useState<any>();

  const gridApiRef = useRef<any>(null);

  const firmContextMenuOptions: ContextMenuOption[] = [
    {
      handler: () => { 
        store.dispatch(
          showEditForm({
            status: true,
            data: {
              fieldName: `Edit Firm - ${currentRow?.Name}`,
              fieldData: currentRow?.Id,
            },
          })
        );
      },
      name: "Edit Firm",
      optionIcon: editIcon
    },
    {
      handler: () => copyToClipboard(gridApiRef),
      name: "Copy All",
      optionIcon: copyIcon
    },
  ];

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
  };

  const loadFirmsList = async () => {
    try {
      let response: any = await loadFirms(dispatch);
      if (response && response.firmsResponse && response.firmsTradeLimitResponse) {
        if(searchResults && searchResults.length)
        {
          response.firmsResponse = searchResults;
        }
        const formattedFirms = response.firmsResponse.map((firm) => {
          return {
            Name: firm?.Name,
            Address: firm?.Address,
            CityCountry: firm?.CityCountry,
            FirmType: firm?.FirmType?.Name,
            Id: firm?.FirmId,
            RiskEnabled: firm?.RiskControlIsEnabled,
            CreatedOn: moment(firm.CreatedOn).format("YYYY-MM-DD hh:mm:ss"),
          }
        }) .sort((a, b) => {
          const dateA = new Date(a.CreatedOn).getTime();
          const dateB = new Date(b.CreatedOn).getTime();
          return dateB - dateA;
        });
        setFirmsData(formattedFirms);
      }
    }
    catch (e) {
      console.error(e);
    }
    finally {
      setIsLoadingData(false);
    }
  };

  const addNewFirm = () => {
    store.dispatch(
      showEditForm({
        status: true,
        data: {
          fieldName: "Add Firm",
          fieldData: -1,
        },
      })
    );
  };

  useEffect(() => {
    loadFirmsList();
  }, []);

  useEffect(() => {
    if(formData && formData.updatedData && formData.updatedData.currentFirm)
    {
      setIsLoadingData(true);
      loadFirmsList();
      loadAccounts(dispatch, store.getState);
    }
  }, [JSON.stringify(formData)])

  return (
    <>
      {
        isLoadingData
        ?
        <CustomSpinner />
        :
        <AggridList
          gridOptions={gridOptions}
          rowData={firmsData}
          pagination={true}
          primaryButtonText={searchResults && searchResults.length > 0 ? "" : 'Add New Firm'}
          primaryButtonOnClickHandler={addNewFirm}
          contextMenuOptions={firmContextMenuOptions}
          setRecordForContextMenu={setCurrentRow}
          gridName={"Firms"}
          onGridReady={onGridReady}
          windowId={windowId}
          noKPIData={searchResults && searchResults.length > 0}
          hideInternalSearch={searchResults && searchResults.length > 0}
        />
      }
    </>
  )
}

export default FirmList