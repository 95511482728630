import { IFirm } from "../../models/IFirm";
import { IGroup } from "../../models/IGroup";
import { IGroupData } from "../../models/IGroupData";
import { IGroupTradeLimit } from "../../models/IGroupTradeLimit";

export class GroupsState {
  firms: IFirm[] = [];
  groups: IGroup[] = [];
  groupsData: IGroupData[] = [];
  supervisors: any[] = [];
  tradeLimits: IGroupTradeLimit[] = [];
}