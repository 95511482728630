import { IDestination } from "../models/IDestination";
import apiSvc from "./api-service";

class DestinationService {
  destinations: IDestination[] = [];

  async get() {
    const destinationsResponse = await apiSvc.get("SelectDestinationsRequest");
    this.destinations = destinationsResponse?.data.Data;
    return this.destinations;
  }

  async add(destination) {
    await apiSvc.api.put("SaveDestinationsRequest", destination);
  }

  async delete(destinationKey) {
    apiSvc.api.delete("DeleteDestinationsRequest", {
      params: {
        PrimaryKey: destinationKey,
        LoadCache: true,
      },
    });
  }
}
const destinationSvc = new DestinationService();
export default destinationSvc;
