import { IAccountDestination } from "../models/IAccountDestination";
import apiSvc from "./api-service";

class AccountDestinationService {
  accountDestinations: IAccountDestination[] = [];

  async get() {
    const accountDestinationResponse = await apiSvc.get("SelectAccountDestinationsRequest");
    this.accountDestinations = accountDestinationResponse?.data.Data;
    return this.accountDestinations;
  }

  async add(accountDestination) {
    apiSvc.api.put("SaveAccountDestinationsRequest", accountDestination);
  }

  async delete(accountDestinationKey) {
    apiSvc.api.delete("DeleteAccountDestinationsRequest", {
      params: {
        PrimaryKey: accountDestinationKey,
        LoadCache: true,
      },
    });
  }
}
const accountDestinationSvc = new AccountDestinationService();
export default accountDestinationSvc;
