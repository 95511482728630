export const defaultGroupColumns = {
  isFirm: false,
  isGroup: false,
  isDataSource: false,
  isRiskEnable: false,
};

export const updateGroupColumnsForAdminRole = () => {
  const columns = { ...defaultGroupColumns };
  Object.keys(columns).forEach((key) => (columns[key] = true));

  return columns;
};

export const updateGroupColumnsForRiskManagerRole = () => {
  const columns = { ...defaultGroupColumns };
  return columns;
};
