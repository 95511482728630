import { ColDef } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { IColumnDefinition } from "../../models/IColumnDefinition";
import globalCache from "../../services/global-cache";
import CustomLoadingOverlay from "../time-and-sales/customLoadingOverlay";
import { ITableProps } from "./ITableProps";
import "./styles.scss";

const Table = (props: ITableProps) => {
  const defaultColDef: ColDef = {
    flex: 1,
    lockVisible: true,
    minWidth: 60,
    resizable: true,
    sortable: false,
    suppressAutoSize: true,
    suppressSizeToFit: true,
    unSortIcon: true,
    ...props.defaultColDef,
  };

  const pagination = props.pagination || false;
  const getRowClass =
    props.getRowClass ||
    ((params) =>
      params.node.rowIndex % 2 === 0 ? "row-light-background" : "");

  return (
    <div className="hw-grid ag-theme-alpine">
      <AgGridReact
        defaultColDef={defaultColDef}
        gridOptions={props.gridOptions}
        headerHeight={15}
        overlayNoRowsTemplate={`<span style="padding: 10px;">${
          props.emptyGridMessage || "No data exist"
        }</span>`}
        pagination={pagination}
        paginationAutoPageSize={true}
        rowHeight={20}
        rowData={props.rowData}
        rowSelection={props.rowSelection}
        frameworkComponents={{ customLoadingOverlay: CustomLoadingOverlay }}
        loadingOverlayComponent={"customLoadingOverlay"}
        loadingOverlayComponentParams={{
          loadingMessage: "Please search for any symbol",
        }}
        getRowClass={getRowClass}
        rowBuffer={25}
        rowClassRules={props.rowClassRules}
        onFilterChanged={props.onFilterChanged}
        onRowDataChanged={props.onRowDataChanged}
        getRowNodeId={props.rowIdSelector}
        // onColumnResized={onColumnResized}
        // onColumnMoved={onColumnMoved}
        onGridReady={props.onGridReady}
        onPaginationChanged={props.onPaginationChanged}
        onRowClicked={props.onRowClicked}
        // onVirtualColumnsChanged={onVirtualColumnsChanged}
      ></AgGridReact>
    </div>
  );
};

export default Table;
