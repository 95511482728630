import React from 'react';
import Portal from './portal';
import './style.scss';
import { ContextMenuOption } from './context-menu-types';
import  addIcon from '../../Images/addicon.svg';
import  editIcon from '../../Images/editicon.svg';
import  copyIcon from '../../Images/copyicon.svg';

type ContextMenuProps = {
    style: React.CSSProperties;
    menuOptions: ContextMenuOption[];
    closeMenu: Function;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ menuOptions, style, closeMenu }: ContextMenuProps) => {

    const handleMenuItemOnClick = (menuItem: ContextMenuOption) => {
        menuItem.handler();
        closeMenu();
    };

    return (
        <Portal>
            <ul className="context-menu" onContextMenu={(e) => { e.preventDefault() }} style={{ ...style }}>
                {
                    menuOptions.map((menuItem, index) => {
                        return (
                            <li key={index} className="menu-item">
                                <a className='link-menu' onClick={() => handleMenuItemOnClick(menuItem)}>
                                    <img src={menuItem.optionIcon} className="img-icon" alt="" />
                                    {menuItem.name}
                                </a>
                            </li>
                        )
                    })
                }
            </ul>
        </Portal>
    );
};

export default ContextMenu;
