import { PERMISSIONS_SET_PERMISSIONS } from "./permissions-types";
import { PermissionState } from "./PermissionState";
const initialState = new PermissionState();

export default function PermissionsReducer(state = initialState, action: any): PermissionState {
  switch (action.type) {
    case PERMISSIONS_SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };

    default:
      return state;
  }
}
