export const WIDGETS_ACTIVE_WIDGET = "widgets/active-widget";
export const WIDGETS_LINK_WIDGET = "widgets/link-widget";
export const WIDGETS_LINK_WIDGET_WITH_SYMBOL = "widgets/link-widget-with-symbol";
export const WIDGETS_LOAD_LINKED_WIDGETS = "widgets/load-linked-widgets";
export const WIDGETS_LOAD_WIDGET_SYMBOLS = "widgets/load-widget-symbols";
export const WIDGETS_SET_LINKED_SYMBOL = "widgets/set-linked-symbol";
export const WIDGETS_SET_WIDGET_SYMBOLS = "widgets/set-widget-symbol";
export const WIDGETS_UNLINK_WIDGET = "widgets/unlink-widget";
export const WIDGETS_DETACH_NEW_WIDGET = "widgets/detach-new-widget";
export const WIDGETS_COPY_WIDGET_STATE = "widgets/copy-widget-state";
