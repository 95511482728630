import { DESTINATION_SET_DESTINATION } from "./destination-types";
import { DestinationState } from "./DestinationState";

const initialState = new DestinationState();

export default function DestinationReducer(
  state = initialState,
  action: any
): DestinationState {
  switch (action.type) {
    case DESTINATION_SET_DESTINATION:
      return {
        ...state,
        destinationList: action.payload,
      };

    default:
      return state;
  }
}
