import { combineReducers } from "redux";
import accountReducer, { initializeAccountState } from "../account/account-reducer";
import AccountsDestinationsReducer from "../accounts-destinations/accounts-destinations-reducer";
import AdminAccountsReducer from "../admin-accounts/accounts-reducer";
import AssetsListReducer from "../assets/assets-reducer";
import authReducer, { initializeAuthState } from "../auth/auth-reducer";
import BorrowListReducer from "../borrow-list/borrow-list-reducer";
import BrokerReducer from "../broker/broker-reducer";
import connectionReducer from "../connection/connection-reducer";
import CountryReducer from "../countries/country-reducer";
import CurrencyReducer from "../currency/currency-reducer";
import dataSourcesReducer from "../data-sources/data-sources-reducer";
import DestinationReducer from "../destination/destination-reducer";
import FeeListReducer from "../fee/fee-reducer";
import FirmsTypesListReducer from "../firm-types-list/firms-types-list-reducer";
import firmsReducer from "../firms/firms-reducer";
import gridsReducer from "../grids/grids-reducer";
import groupsReducer from "../groups/groups-reducer";
import levelIIReducer from "../level-ii-view/level-ii-reducer";
import orderBlotterReducer from "../order-blotter/order-blotter-reducer";
import PermissionsReducer from "../permissions/permissions-reducer";
import positionsReducer from "../positions/positions-reducer";
import quoteMediaReducer, { initializeQuoteMediaState } from "../quote-media/quote-media-reducer";
import settingsReducer from "../settings/settings-reducer";
import StrategyReducer from "../strategy/strategy-reducer";
import SymbolMarginReducer from "../symbol-margin/margin-reducer";
import SymbolsReducer from "../symbols/symbols-reducer";
import UserAccountReducer from "../user-accounts/user-account-reducer";
import UserPermissionsReducer from "../user-permissions/user-permissions-reducer";
import UserRolesReducer from "../user-roles/user-roles-reducer";
import UserStatusReducer from "../user-status/user-status-reducer";
import UserSymbolsReducer from "../user-symbol/user-symbol-reducer";
import UsersAdsReducer from "../users-ads/users-ads-reducer";
import usersReducer from "../users/users-reducer";
import widgetModalReducer from "../widget-modal/widget-modal-reducer";
import widgetsReducer from "../widgets/widgets-reducer";

initializeAuthState();
initializeAccountState();
initializeQuoteMediaState();

const allReducers = combineReducers({
  account: accountReducer,
  adminAccounts: AdminAccountsReducer,
  accountsDestinations: AccountsDestinationsReducer,
  assetsList: AssetsListReducer,
  auth: authReducer,
  borrowList: BorrowListReducer,
  connection: connectionReducer,
  currency: CurrencyReducer,
  country: CountryReducer,
  dataSources: dataSourcesReducer,
  feeList: FeeListReducer,
  firms: firmsReducer,
  firmsTypesList: FirmsTypesListReducer,
  grids: gridsReducer,
  groups: groupsReducer,
  levelII: levelIIReducer,
  orderBlotter: orderBlotterReducer,
  permissions: PermissionsReducer,
  positions: positionsReducer,
  qm: quoteMediaReducer,
  settings: settingsReducer,
  symbolMargin: SymbolMarginReducer,
  symbols: SymbolsReducer,
  userAccountsList: UserAccountReducer,
  usersAds: UsersAdsReducer,
  userPermissions: UserPermissionsReducer,
  userRoles: UserRolesReducer,
  users: usersReducer,
  usersStatus: UserStatusReducer,
  userSymbolsList: UserSymbolsReducer,
  destinationList: DestinationReducer,
  brokersList: BrokerReducer,
  strategiesist: StrategyReducer,
  widgetModal: widgetModalReducer,
  widgets: widgetsReducer,
});

export default allReducers;
