import { Component } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FlexView from "react-flexview";
import { connect } from "react-redux";
import { OrderSideEnum } from "../../enums/OrderSideEnum";
import { OrderTifEnum } from "../../enums/OrderTifEnum";
import { OrderTypeEnum } from "../../enums/OrderTypeEnum";
import { IAccount } from "../../models/IAccount";
import { IKeyboardShortcutSetting } from "../../models/IKeyboardShortcutSetting";
import { IOrderInfoConfirmModal } from "../../models/IOrderInfoConfirmModal";
import { IShortcutCombination } from "../../models/IShortcutCombination";
import { NewOrder } from "../../models/NewOrder";
import accountsSvc from "../../services/account-services";
import notificationSvc from "../../services/notification-service";
import store from "../../store";
import { placeNatsOrder } from "../../store/connection/connection-async-actions";
import {
  subscribeQuoteMediaData,
  unsubscribeQuoteMediaData,
} from "../../store/quote-media/quote-media-async-actions";
import { IAppState } from "../../store/reducers/IAppState";
import {
  setModalResponse,
  updateConfirmationModal,
} from "../../store/settings/settings-actions";
import { setWidgetSymbol } from "../../store/widgets/widgets-actions";
import {
  IQuickTradeDispatchProps,
  IQuickTradeMappedProps,
  IQuickTradeProps,
} from "./IQuickTradeProps";
import { IQuickTradeState } from "./IQuickTradeState";
import "./styles.scss";

type Props = IQuickTradeProps &
  IQuickTradeMappedProps &
  IQuickTradeDispatchProps;

class QuickTrade extends Component<Props, IQuickTradeState> {
  isWaiting: boolean = true;
  pressedKey: string = "";
  shortcut?: IKeyboardShortcutSetting | IShortcutCombination;

  constructor(props: Props) {
    super(props);

    const { accounts, defaultAccount, defaultDestination } = props;

    const selectedAccountId =
      defaultAccount || (accounts.length > 0 ? accounts[0].AccountId : 0);
    const selectedAccount = accounts.find(
      (x) => x.AccountId === selectedAccountId
    );

    this.state = {
      destinationId:
        defaultDestination ||
        (selectedAccount?.AccountDestination &&
          selectedAccount?.AccountDestination[0]?.DestinationId) ||
        0,
      accountId:
        defaultAccount || (accounts.length > 0 ? accounts[0].AccountId : 0),
      symbol: "",
      isSymbolSelected: false,
      price: 0,
      quantity: 0,
      position: "0.00",
      openPnl: 0,
      side: 0,
      accountsData: [],
      orderInfo: {
        symbol: "",
        price: 0,
        quantity: 0,
        destinationId:
          defaultDestination ||
          (selectedAccount?.AccountDestination &&
            selectedAccount?.AccountDestination[0]?.DestinationId) ||
          0,
        accountId: selectedAccountId,
        type: OrderTypeEnum.Market,
        tif: OrderTifEnum.Day,
      },
    };
  }

  get priceData() {
    return this.state.isSymbolSelected
      ? this.props.priceData[this.state.symbol]
      : null;
  }

  get quoteData() {
    return this.state.isSymbolSelected
      ? this.props.quoteData[this.state.symbol]
      : null;
  }

  componentDidMount() {
    accountsSvc.get().then((accountsData) => this.setState({ accountsData }));
    const {
      symbol,
      isCombinationShortcutListAllowed,
      combinationShortcutList,
    } = this.props;

    document.addEventListener("keydown", (e) => {
      if (this.pressedKey && !this.pressedKey.includes("+")) {
        this.pressedKey += " + " + e.key;
      } else {
        this.pressedKey = e.key;
      }

      if (
        isCombinationShortcutListAllowed &&
        combinationShortcutList.length > 0
      ) {
        this.shortcut = combinationShortcutList.find(
          (x) => x.keyboard === this.pressedKey
        );
        if (this.shortcut) {
          this.shortcut.details.forEach((element) => {
            this.placeOrderWithShortcutKey(element);
          });
        } else {
          this.placeOrderWithShortcutKey(this.pressedKey);
        }
      } else {
        this.placeOrderWithShortcutKey(this.pressedKey);
      }
    });

    if (symbol) {
      this.setState({ symbol, isSymbolSelected: true });
      this.props.setWidgetSymbol(symbol);
      this.props.subscribeQuoteMediaData(symbol);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { orderInfo, accountsData } = this.state;
    if (orderInfo.accountId === 0 && accountsData.length > 0) {
      this.setState({
        orderInfo: {
          ...orderInfo,
          accountId: accountsData[0].account.AccountId,
          destinationId: accountsData[0].account.AccountDestination
            ? accountsData[0].account.AccountDestination[0].DestinationId
            : 0,
        },
      });
    }

    const { isResponse, confirmationModal, symbol } = this.props;

    this.handleAccountUpdate(prevProps.accounts);

    if (prevProps.isResponse !== isResponse) {
      if (
        isResponse === true &&
        this.props.windowId === confirmationModal.windowId
      ) {
        this.placeOrder(this.state.side);
      }

      this.props.hideConfirmation();
    }

    if (prevProps.symbol !== symbol) {
      this.setState({ symbol });

      if (symbol) {
        this.setState({ isSymbolSelected: true });
        this.props.setWidgetSymbol(symbol);
        this.props.subscribeQuoteMediaData(symbol);
      }
    }
  }

  componentWillUnmount() {
    this.props.unsubscribeQuoteMediaData();
  }

  render() {
    const { accountsData } = this.state;
    const allAccounts: any = accountsData.map((x) => x.account);
    const { quoteData, accounts, defaultDecimal } = this.props;
    const {
      accountId,
      destinationId,
      isSymbolSelected,
      price,
      quantity,
      symbol,
    } = this.state;

    const selectedAccount = allAccounts.find((x) => x.AccountId === accountId);
    const destinations = selectedAccount?.AccountDestination || [];

    const ask =
      (isSymbolSelected &&
        quoteData &&
        quoteData[symbol] &&
        quoteData[symbol].askPrice) ||
      0;
    const bid =
      (isSymbolSelected &&
        quoteData &&
        quoteData[symbol] &&
        quoteData[symbol].bidPrice) ||
      0;
    const { position, openPnl } = this.getPosition();
    let positionClassName =
      position > 0
        ? "price-positive"
        : position === 0
        ? "price-equal"
        : "price-negative";
    let opnPnlClassName =
      openPnl > 0
        ? "price-positive"
        : openPnl === 0
        ? "price-equal"
        : "price-negative";
    return (
      <FlexView
        className="quick-trade"
        column
        height="100%"
        style={{ minWidth: 280, minHeight: 220 }}
      >
        <FlexView className="all-controls" column grow>
          <FlexView className="first-row" grow>
            <FlexView className="symbol-account-wrapper">
              <Form.Group>
                <Form.Label>Symbol</Form.Label>
                <Form.Control
                  className="trade-field"
                  type="text"
                  value={symbol}
                  onChange={this.symbol_Change}
                  onKeyPress={this.symbol_KeyPress}
                />
                <Form.Label className="mt-1">Account</Form.Label>
                <Form.Control
                  className="trade-field"
                  as="select"
                  value={accountId}
                  onChange={(e) => {
                    this.setState({ accountId: parseInt(e.target.value) });
                    this.setState({
                      destinationId:
                        this.state.accountsData.find(
                          (x) =>
                            x.account.AccountId === parseFloat(e.target.value)
                        )?.account?.AccountDestination[0].DestinationId || 0,
                    });
                  }}
                >
                  {allAccounts.map((data: any, index) => (
                    <option key={index} value={data.AccountId}>
                      {data.Name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </FlexView>
            <FlexView
              grow
              className="position-wrapper"
              hAlignContent="left"
              vAlignContent="top"
            >
              <FlexView
                hAlignContent="center"
                vAlignContent="center"
                column
                grow
              >
                <FlexView vAlignContent="bottom" grow>
                  Position
                </FlexView>
                <span className="spacer-v"></span>
                <FlexView
                  className={positionClassName}
                  vAlignContent="top"
                  grow
                >
                  {position}
                </FlexView>
              </FlexView>
              <FlexView
                hAlignContent="center"
                vAlignContent="center"
                column
                grow
              >
                <FlexView vAlignContent="bottom" grow>
                  Open P/L
                </FlexView>
                <span className="spacer-v"></span>
                <FlexView className={opnPnlClassName} vAlignContent="top" grow>
                  {openPnl.toFixed(defaultDecimal)}
                </FlexView>
              </FlexView>
            </FlexView>
          </FlexView>

          <FlexView className="order-info-wrapper" grow>
            <Form.Group>
              <Form.Label>Destination</Form.Label>
              <Form.Control
                className="trade-field"
                as="select"
                value={destinationId}
                onChange={this.setDestinationId}
              >
                {destinations.map((data, index) => (
                  <option key={index} value={data.DestinationId}>
                    {data.DisplayName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <span className="spacer-h"></span>
            <FlexView column>
              <Form.Group>
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  className="trade-field"
                  type="number"
                  placeholder="Qty"
                  min={100}
                  max={10000}
                  step={100}
                  value={quantity}
                  onChange={(e) =>
                    this.setState({ quantity: parseInt(e.target.value) })
                  }
                />
              </Form.Group>
            </FlexView>
            <span className="spacer-h"></span>
            <FlexView column>
              <Form.Group>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  className="trade-field"
                  type="number"
                  placeholder="Price"
                  step={0.01}
                  value={price}
                  onChange={(e) =>
                    this.setState({ price: parseFloat(e.target.value) })
                  }
                />
              </Form.Group>
            </FlexView>
          </FlexView>

          <FlexView grow>
            <FlexView grow column>
              <Form.Label>Bid</Form.Label>
              <FlexView
                className="bid"
                vAlignContent="center"
                hAlignContent="center"
                onClick={(e) => this.setState({ price: bid })}
                onDoubleClick={() => this.buyAtBid_Click(bid)}
                title="Double Click to Trade"
              >
                <span className="text-white font-weight-bold">
                  {bid.toFixed(defaultDecimal)}
                </span>
              </FlexView>
            </FlexView>

            <span className="spacer-h" />

            <FlexView grow column>
              <Form.Label>Ask</Form.Label>
              <FlexView
                className="ask"
                vAlignContent="center"
                hAlignContent="center"
                onClick={(e) => this.setState({ price: ask })}
                onDoubleClick={() => this.sellAtAsk_Click(ask)}
                title="Double Click to Trade"
              >
                <span id="ask" className="text-white font-weight-bold">
                  {ask.toFixed(defaultDecimal)}
                </span>
              </FlexView>
            </FlexView>
          </FlexView>
        </FlexView>

        <FlexView className="action-buttons" grow>
          <FlexView hAlignContent="center" grow>
            <Button
              disabled={this.enableBuySell()}
              className={
                !this.enableBuySell() ? "buy-btn" : "disabledBtn buy-btn"
              }
              variant={!this.enableBuySell() ? "primary" : "secondary"}
              onDoubleClick={() => this.order_Click(OrderSideEnum.Buy)}
              title="Double Click to Trade"
            >
              BUY
            </Button>
          </FlexView>
          <FlexView className="sell-button-wrapper" hAlignContent="center" grow>
            <Button
              disabled={this.enableBuySell()}
              className={
                !this.enableBuySell() ? "sell-btn" : "disabledBtn sell-btn"
              }
              variant={!this.enableBuySell() ? "primary" : "secondary"}
              onDoubleClick={() => this.order_Click(OrderSideEnum.Sell)}
              title="Double Click to Trade"
            >
              SELL
            </Button>
          </FlexView>
        </FlexView>
      </FlexView>
    );
  }

  handleAccountUpdate = (prevAccounts: IAccount[]) => {
    const { accounts } = this.props;

    if (
      accounts.length > 0 &&
      JSON.stringify(prevAccounts) !== JSON.stringify(accounts)
    ) {
      const account = accounts[0];

      this.setState({
        accountId: account.AccountId,
        destinationId:
          (account.AccountDestination &&
            account.AccountDestination[0]?.DestinationId) ||
          0,
      });
    }
  };

  setDestinationId = (e) =>
    this.setState({
      destinationId: parseInt(e.target.value),
    });

  placeOrderWithShortcutKey(pressedKey) {
    const {
      defaultDestination,
      keyboardShortcutData,
      setWidgetSymbol,
      positions,
    } = this.props;

    this.shortcut = keyboardShortcutData.find((x) => x.shortcut === pressedKey);

    if (this.shortcut) {
      this.pressedKey = "";
      const symbol = this.shortcut.symbol as string;
      const tolerance = this.shortcut.price || 0;

      if (this.canPlaceOrder(symbol)) {
        this.props.subscribeQuoteMediaData(symbol);
        this.setState({ isSymbolSelected: true });
        setWidgetSymbol(symbol);
        let price = 0;
        switch (this.shortcut.priceType) {
          case "Displayed":
            price = this.state.price + tolerance;
            break;

          case "Last":
            price = this.priceData ? this.priceData.last + tolerance : 0;
            break;

          case "Bid":
            price = this.quoteData ? this.quoteData.bidPrice + tolerance : 0;
            break;

          case "Ask":
            price = this.quoteData ? this.quoteData.askPrice + tolerance : 0;
            break;

          case "MKT":
            price = 0;
            break;
        }

        let quantity = 0;
        switch (this.shortcut.quantityType) {
          case "Typed":
            quantity = this.shortcut.quantity as number;
            break;

          case "Displayed":
            quantity = this.state.quantity;
            break;

          case "Position":
            const positionValue: any = positions.find(
              (x) => x["Symbol"] === symbol
            );
            if (positionValue) {
              quantity = positionValue.Position;
            } else {
              quantity = 0;
            }
            break;
        }

        if (this.shortcut.action === "Enter") {
          this.setState({
            symbol: this.shortcut.symbol || "",
            price: price,
            quantity: quantity,
            destinationId: this.shortcut.destinationId || 0,
            accountId: this.shortcut.accountId || 0,
            side: this.shortcut.side,
          });
        } else {
          let len = this.shortcut.repeat || 1;

          for (var i = 0; i < len; i++) {
            const order = new NewOrder();

            order.symbol = this.shortcut.symbol || "";
            order.side = this.shortcut.side;
            order.price = price;
            order.quantity = quantity;
            order.destinationId =
              this.shortcut.destinationId || defaultDestination;
            order.accountId = this.shortcut.accountId || 0;
            order.type =
              this.shortcut.priceType === "MKT"
                ? OrderTypeEnum.Market
                : OrderTypeEnum.Limit;
            order.tif = this.shortcut.orderType;

            store.dispatch(placeNatsOrder(order));
          }
        }
      } else {
        this.showNotAllowedMessage(symbol);
      }
    }
  }

  canPlaceOrder(symbol: string) {
    return true;
    // return (
    //   this.props.userSymbols.indexOf("*") !== -1 ||
    //   this.props.userSymbols.indexOf(symbol) !== -1
    // );
  }

  buyAtBid_Click(bid: number) {
    if (!this.state.quantity) {
      return;
    }

    this.setState({ price: bid }, () => this.order_Click(OrderSideEnum.Buy));
  }

  sellAtAsk_Click(ask: number) {
    if (!this.state.quantity) {
      return;
    }

    this.setState({ price: ask }, () => this.order_Click(OrderSideEnum.Sell));
  }

  getPosition() {
    const { positions } = this.props;
    const { symbol } = this.state;

    let position = 0,
      openPnl = 0;

    if (this.priceData) {
      const symPos = positions.find((x) => x.Symbol === symbol);
      if (symPos && symPos.Position !== 0) {
        position = symPos.Position;

        if (symPos.Position > 0) {
          openPnl =
            symPos.Position * this.priceData.last -
            symPos.Position * symPos.AvgPrice;
        } else if (symPos.Position < 0) {
          openPnl +=
            (symPos.AvgPrice - this.priceData.last) * Math.abs(symPos.Position);
        }
      }
    }

    return { position, openPnl };
  }

  placeOrder = (side: OrderSideEnum) => {
    const { accountId, destinationId, price, quantity, symbol } = this.state;
    const { authInfo } = store.getState().auth;

    if (!symbol || !price || !quantity || !destinationId) {
      notificationSvc.error("Required fields are missing for Order!");
    } else {
      const order = new NewOrder();

      order.accountId = accountId;
      order.destinationId = destinationId;
      order.price = price;
      order.quantity = quantity;
      order.side = side;
      order.symbol = symbol;
      order.type = OrderTypeEnum.Limit;
      order.userId = parseFloat(authInfo?.UserId || "0");

      store.dispatch(placeNatsOrder(order));
    }
  };

  showNotAllowedMessage = (symbol: string) => {
    notificationSvc.error(`${symbol}: Symbol is not allowed for trading!`);
  };

  symbol_Change = (e) => {
    if (this.state.symbol) {
      this.props.unsubscribeQuoteMediaData();
    }

    this.setState({
      symbol: e.target.value.toUpperCase(),
      isSymbolSelected: false,
    });
  };

  symbol_KeyPress = (e) => {
    if (e.key === "Enter") {
      if (!e.target.value) {
        notificationSvc.error("Enter a Symbol to start trading!");
      } else {
        const symbol = e.target.value.toUpperCase();
        this.props.subscribeQuoteMediaData(symbol);
        this.setState({ isSymbolSelected: true });
        this.props.setWidgetSymbol(symbol);
      }
    }
  };

  enableBuySell = () => {
    const { symbol, destinationId, accountId, price, quantity } = this.state;
    if (
      symbol !== "" &&
      destinationId !== 0 &&
      accountId !== 0 &&
      price !== 0 &&
      quantity !== 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  order_Click = (side: OrderSideEnum) => {
    const { orderNotification } = this.props;
    const { symbol } = this.state;

    if (!orderNotification) {
      this.placeOrder(side);
      return;
    }

    if (!this.canPlaceOrder(symbol)) {
      this.showNotAllowedMessage(symbol);
      return;
    }

    this.setState({ side });
    let orderType = side === 1 ? "Buy" : "Sell";
    let orderInfo = {
      quantity: this.state.quantity,
      price: this.state.price,
      symbol,
    };

    this.props.showConfirmation(orderType, orderInfo);
  };
}

const mapStateToProps = (state: IAppState): IQuickTradeMappedProps => ({
  combinationShortcutList: state.settings.shortcutCombinations,
  confirmationModal: state.settings.confirmationModal,
  defaultAccount: state.settings.general.defaultAccountId,
  defaultDecimal: state.settings.general.decimalPlaces,
  defaultDestination: state.settings.general.defaultDestinationId,
  isCombinationShortcutListAllowed: state.settings.isShortcutCombinationAllowed,
  isResponse: state.settings.isResponse,
  keyboardShortcutData: state.settings.keyboardShortcut,
  orderNotification: state.settings.notificationSetting.orderNotification,
  positions: state.positions.symbols,
  priceData: state.qm.priceData,
  quoteData: state.qm.quoteData,
  accounts: state.account.userAccounts,
  userSymbols: state.account.userSymbols,
});

const mapDispatchToProps = (
  dispatch: any,
  ownProps: IQuickTradeProps
): IQuickTradeDispatchProps => {
  const { windowId } = ownProps;

  return {
    hideConfirmation: () => dispatch(setModalResponse(false)),
    setWidgetSymbol: (symbol: string) =>
      dispatch(setWidgetSymbol(windowId, symbol)),
    showConfirmation: (type: string, orderInfo: IOrderInfoConfirmModal) =>
      dispatch(updateConfirmationModal(windowId, type, orderInfo, true)),
    subscribeQuoteMediaData: (symbol) =>
      dispatch(subscribeQuoteMediaData(windowId, symbol)),
    unsubscribeQuoteMediaData: () =>
      dispatch(unsubscribeQuoteMediaData(windowId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickTrade);
