import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import { Button, Modal } from "react-bootstrap";
import FlexView from "react-flexview/lib";
import { IPopupProps } from "./IPopupProps";
import "./styles.scss";

export const Popup: FunctionComponent<IPopupProps> = (props) => {
  const { click, message, type } = props;

  return (
    <Modal
      show={!!message}
      className={"error-popup"}
      backdrop="static"
      size={"sm"}
      keyboard={false}
      centered
    >
      <Modal.Body>
        <FlexView column hAlignContent="center" vAlignContent="center">
          <FontAwesomeIcon
            icon={["fas", "times-circle"]}
            size="3x"
            className={`mb-3 text-${type}`}
          />
          <span className="text-dark">{message}</span>
        </FlexView>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={type} size="sm" onClick={click}>
          <FontAwesomeIcon icon={["fas", "redo"]} className="mr-2" />
          Try
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
