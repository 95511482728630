import { IAccount } from "../models/IAccount";
import { IAccountTradeLimit } from "../models/IAccountTradeLimit";
import apiSvc from "./api-service";

class AccountsServices {
  accounts: IAccount[] = [];
  accountTradeLimit: IAccountTradeLimit[] = [];
  async get() {
    const accountsResponse = await apiSvc.get("SelectAccountsRequest");
    const accountsTradeLimits = await apiSvc.get("SelectAccountTradeLimitsRequest");
    const result = accountsResponse?.data.Data?.map((account) => ({
      account,
      accountTradeLimits: accountsTradeLimits?.data.Data.find((x) => x.AccountId === account.AccountId),
    }));
    this.accounts = accountsResponse?.data.Data;
    this.accountTradeLimit = accountsTradeLimits?.data.Data;
    return result;
  }

  async getAccounts() {
    const accountsResponse = await apiSvc.get("SelectAccountsRequest");
    const result = accountsResponse?.data.Data;
    return result;
  }

  async add(accountData) {
    const accountRequestData = {
      AccountId: accountData.Data.AccountId,
      AccountDestination: [],
      AccountStatusID: accountData.Data.AccountStatusID,
      ApprovalWhenSendingOrder: accountData.Data.ApprovalWhenSendingOrder,
      BorrowListID: accountData.Data.BorrowListID,
      FeeId: accountData.Data.FeeId,
      MainCurrencyID: accountData.Data.MainCurrencyID,
      Name: accountData.Data.Name,
      CreatedBy: accountData.Data.CreatedBy,
      CreatedOn: accountData.Data.CreatedOn,
      ModifiedBy: accountData.Data.ModifiedBy,
      ModifiedOn: accountData.Data.ModifiedBy,
    };

    const accountResponse = await apiSvc.put("SaveAccountsRequest", accountRequestData);

    const accountTradeLimitData = {
      AccountId: accountResponse.data.PrimaryKey,
      BuyingPower: accountData.Data.AccountTradeLimit.BuyingPower,
      CreatedBy: accountData.Data.AccountTradeLimit.CreatedBy,
      CreatedOn: accountData.Data.AccountTradeLimit.CreatedOn,
      EnBpCtrl: accountData.Data.AccountTradeLimit.EnBpCtrl,
      EnLinkWithNetEquity: accountData.Data.AccountTradeLimit.EnLinkWithNetEquity,
      EnMarginCtrl: accountData.Data.AccountTradeLimit.EnMarginCtrl,
      EnOpenOrderBp: accountData.Data.AccountTradeLimit.EnOpenOrderBp,
      EnPrePostMkt: accountData.Data.AccountTradeLimit.EnPrePostMkt,
      EnUrlzLinkToBp: accountData.Data.AccountTradeLimit.EnUrlzLinkToBp,
      EnUrlzLinkToMaxLoss: accountData.Data.AccountTradeLimit.EnUrlzLinkToMaxLoss,
      EnVarOpenOrderBp: accountData.Data.AccountTradeLimit.EnVarOpenOrderBp,
      LinkWithNetEquity: accountData.Data.AccountTradeLimit.LinkWithNetEquity,
      MaxLoss: accountData.Data.AccountTradeLimit.MaxLoss,
      EnMaxPosition: accountData.Data.AccountTradeLimit.EnMaxPosition,
      EnMaxOpenOrder: accountData.Data.AccountTradeLimit.EnMaxOpenOrder,
      MaxOpenOrderCount: accountData.Data.AccountTradeLimit.MaxOpenOrderCount,
      EnOpenShares: accountData.Data.AccountTradeLimit.EnOpenShares,
      MaxPosition: accountData.Data.AccountTradeLimit.MaxPosition,
      ModifiedBy: accountData.Data.AccountTradeLimit.ModifiedBy,
      ModifiedOn: accountData.Data.AccountTradeLimit.ModifiedOn,
      OpenOrderBp: accountData.Data.AccountTradeLimit.OpenOrderBp,
    };

    await apiSvc.put("SaveAccountTradeLimitsRequest", accountTradeLimitData);
    this.get();
  }

  async delete(accountId) {
    const deletedTradeLimit = await apiSvc.api.delete("DeleteAccountTradeLimitsRequest", {
      params: {
        PrimaryKey: accountId,
        LoadCache: true,
      },
    });

    if (deletedTradeLimit !== undefined) {
      apiSvc.api.delete("DeleteAccountsRequest", {
        params: {
          PrimaryKey: accountId,
          LoadCache: true,
          Force: true,
        },
      });
    }
  }
}
const accountsSvc = new AccountsServices();
export default accountsSvc;
