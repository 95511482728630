import globalCache from "../../services/global-cache";

export class NotificationSettings {
  orderNotification = true;
  orderLimit = 0;
  orderLimitNotification = false;
  exitingNotification = false;
  orderRejectionNotification = false;
  oderPrice = 0;
  orderPriceNotification = false;
  saveNotification = false;

  constructor() {
    let notificationSettings: NotificationSettings;

    const { notificationSettings: cachedSettings } = globalCache;
    if (cachedSettings) {
      notificationSettings = cachedSettings
    } else {
      notificationSettings = {
        orderNotification: true,
        orderLimit: 0,
        orderLimitNotification: false,
        exitingNotification: true,
        orderRejectionNotification: true,
        oderPrice: 0,
        orderPriceNotification: false,
        saveNotification: true,
      };

      globalCache.notificationSettings = notificationSettings;
    }

    this.orderNotification = notificationSettings.orderNotification;
    this.orderLimit = notificationSettings.orderLimit;
    this.orderLimitNotification = notificationSettings.orderLimitNotification;
    this.exitingNotification = notificationSettings.exitingNotification;
    this.orderRejectionNotification = notificationSettings.orderRejectionNotification;
    this.oderPrice = notificationSettings.oderPrice;
    this.orderPriceNotification = notificationSettings.orderPriceNotification;
    this.saveNotification = notificationSettings.saveNotification;
  }

  static get default() {
    const notifications = {
      exit: true,
      order: true,
      orderLimit: false,
      orderLimitValue: 0,
      orderPrice: false,
      orderPriceValue: 0,
      orderRejection: true,
      saveLayout: true,
      shares: false,
      alerts: [],
      alertPopup: false,
      widgetCloseConfirmation: true,
    };

    const settings = new NotificationSettings();
    Object.keys(notifications).forEach(prop => settings[prop] = notifications[prop]);
    return settings;
  }
}
