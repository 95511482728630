import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import FlexView from "react-flexview";
import { connect } from "react-redux";
import { IAppState } from "../../store/reducers/IAppState";
import { setUserClosedWidget, setWidgetDisplayed } from "../../store/settings/settings-actions";
import { IWidgetCloseDispatchProps, IWidgetCloseProps } from "./IWidgetCloseProps";

type Props = IWidgetCloseProps & IWidgetCloseDispatchProps;

class WidgetCloseConfirmation extends Component<Props, {}> {
  render() {
    const { isWidgetCloseConfirmation } = this.props;
    return (
      <Modal className="confirmation-modal" backdrop="static" centered size={"sm"} keyboard={false}
        show={isWidgetCloseConfirmation} onClick={e => e.stopPropagation()}>
        <Modal.Header>
          <FlexView column hAlignContent="center" vAlignContent="center" basis="100%">
            <span className="widget-text">Widget Close Confirmation</span>
          </FlexView>
        </Modal.Header>
        <Modal.Body>
          <FlexView column hAlignContent="center" vAlignContent="center">
            <span className="widget-text">Are you sure you want to close this widget?</span>
          </FlexView>
        </Modal.Body>
        <Modal.Footer>
          <FlexView grow style={{ justifyContent: "space-between" }}>
            <FlexView>
              <Button onClick={this.cancel}>No</Button>
            </FlexView>
            <FlexView hAlignContent="right">
              <Button onClick={this.ok}>Yes</Button>
            </FlexView>
          </FlexView>
        </Modal.Footer>
      </Modal>
    );
  }

  private cancel = () => this.props.updateWidgetDisplay(false);

  private ok = () => {
    this.props.updateWidgetDisplay(false);
    this.props.updateUserCloseWidget(true);
  };
}

const mapStateToProps = (state: IAppState): IWidgetCloseProps => ({
  isWidgetCloseConfirmation: state.settings.isWidgetDisplayedModalShown || false
});

const mapDispatchToProps = (dispatch: any): IWidgetCloseDispatchProps => ({
  updateWidgetDisplay: (response: boolean) => dispatch(setWidgetDisplayed(response)),
  updateUserCloseWidget: (response: boolean) => dispatch(setUserClosedWidget(response)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetCloseConfirmation);
