import { IUserAd } from "../../models/IUserAd";
import { IUser } from "../../models/IUser";
import { IBroker } from "../../models/IBroker";
import { IDestination } from "../../models/IDestination";
import { IAccount } from "../../models/IAccount";
import { IGroup } from "../../models/IGroup";
import { IFirm } from "../../models/IFirm";

export interface SearchResults {
  IsSuccess: boolean;
  users: IUser[];
  accounts: IAccount[];
  groups: IGroup[];
  firms: IFirm[];
  brokers: IBroker[];
  destinations: IDestination[];
  accountPositions: [];
  symbolsPositions: [];
  orders: [];
  ordersHistory: [];
  executions: [];
  symbolTradeLimits: [];
  accountTradeLimits: [];
  userSymbols: [];
  userActivities: [];
}
export class UsersState {
  ads: IUserAd[] = [];
  data: IUser[] = [];
  onlineUsers: number[] = [];
  searchParams: any;
  showEditForm: boolean = false;
  formData: any;
  searchResults: SearchResults | undefined;
}
