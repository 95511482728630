import { DataManager, Query } from "@syncfusion/ej2-data";
import { ColumnModel } from "@syncfusion/ej2-grids";
import {
  ColumnDirective,
  ColumnsDirective,
  DataSourceChangedEventArgs,
  Edit,
  EditSettingsModel,
  Grid,
  GridComponent,
  InfiniteScroll,
  Inject,
  Page,
  PageSettingsModel,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import { Component } from "react";
import FlexView from "react-flexview/lib";
import { connect } from "react-redux";
import assetsSvc from "../../../services/assets-services";
import dataSourceSvc from "../../../services/data-source-service";
import symbolSvc from "../../../services/symbols-services";
import { loadAssetsList } from "../../../store/assets/assets-async-action";
import { loadCurrency } from "../../../store/currency/currency-async-action";
import { loadDataSources } from "../../../store/data-sources/data-sources-async-actions";
import { IAppState } from "../../../store/reducers/IAppState";
import { addSymbol, deleteSymbol, loadSymbols } from "../../../store/symbols/symbols-async-actions";
import { setTimeToUTC } from "../../../utils/datetime";
import CustomSpinner from "../spinner/spinner";
import { ISymbolsListDispatchProps, ISymbolsListMappedProps } from "./ISymbolsListProps";
import { ISymbolState } from "./ISymbolState";

type Props = ISymbolsListMappedProps & ISymbolsListDispatchProps;

class AdminSymbolsList extends Component<Props, ISymbolState> {
  public editOptions: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Normal",
  };
  public toolbarOptions: ToolbarItems[] = ["Add", "Edit", "Delete", "Update", "Cancel", "Search"];
  public pageSettings: PageSettingsModel = { pageSize: 10 };
  private columnDefs: ColumnModel[] = [];
  private grid: Grid | null = null;

  templates: { asset: any; dataSource: any } = {
    asset: (props: any) => <>{props.Asset.AssetName}</>,
    dataSource: (props: any) => <>{props.DataSource.Name}</>,
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      symbols: [],
      assets: [],
      dataSources: [],
    };
    this.columnDefs = this.getColumnDefs();
  }

  componentDidMount() {
    if (!this.allListPopulated()) {
      symbolSvc.get().then((symbols) => this.setState({ symbols }));
      assetsSvc.get().then((assets) => this.setState({ assets }));
      dataSourceSvc.get().then((dataSources) => this.setState({ dataSources }));
    }
  }

  allListPopulated = () => {
    const { symbols, assets, dataSources } = this.state;
    if (symbols?.length > 0 && assets?.length > 0 && dataSources?.length > 0) {
      this.columnDefs = this.getColumnDefs();
      return true;
    }
  };

  debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = Grid;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  onLoad = () => {
    this.grid?.element.addEventListener(
      "keydown",
      this.debounce((e) => {
        if (e.target.getAttribute("id").indexOf("_searchbar") !== -1) {
          this.grid?.search((e.target as HTMLInputElement).value);
        }
      }, 250)
    );
  };

  render() {
    const { symbols } = this.state;
    const data = {
      result: symbols,
      count: symbols?.length,
    };
    // return <AdminBaseList columnDefs={this.columnDefs} rowData={symbols} dataSourceChanged={this.dataSourceChanged} />;
    if (this.allListPopulated()) {
      return (
        <FlexView className="hw-admin-grid ag-theme-alpine">
          <GridComponent
            id="grid"
            height="100%"
            width="100%"
            allowPaging={true}
            pageSettings={this.pageSettings}
            editSettings={this.editOptions}
            toolbar={this.toolbarOptions}
            dataSource={data}
            dataSourceChanged={this.dataSourceChanged}
            enableInfiniteScrolling={true}
            ref={(g) => (this.grid = g)}
          >
            <ColumnsDirective>
              {this.columnDefs.map((column) => (
                <ColumnDirective key={column.field} headerText={column.headerText} {...column} />
              ))}
            </ColumnsDirective>
            <Inject services={[Edit, Toolbar, InfiniteScroll, Page]} />
          </GridComponent>
        </FlexView>
      );
    } else {
      return (
        <div className="m-auto">
          <CustomSpinner />
        </div>
      );
    }
  }

  getColumnDefs = (): ColumnModel[] => {
    const { assets, dataSources } = this.state;

    return [
      { field: "Symbol_", headerText: "Symbol" },
      {
        field: "Asset.Id",
        headerText: "Asset",
        template: this.templates.asset,
        editType: "dropdownedit",
        edit: {
          params: {
            actionComplete: () => true,
            allowFiltering: true,
            dataSource: new DataManager(assets),
            fields: { text: "AssetName", value: "Id" },
            query: new Query(),
          },
        },
        validationRules: { required: true },
      },
      {
        field: "DataSource.DataSourceId",
        headerText: "DataSource",
        template: this.templates.dataSource,
        editType: "dropdownedit",
        edit: {
          params: {
            actionComplete: () => true,
            allowFiltering: true,
            dataSource: new DataManager(dataSources),
            fields: { text: "Name", value: "DataSourceId" },
            query: new Query(),
          },
        },
        validationRules: { required: true },
      },
    ];
  };

  dataSourceChanged = (args: DataSourceChangedEventArgs) => {
    let newItem: any = args.data;

    if (args.action === "add") {
      const obj = {
        Symbol_: newItem.Symbol_,
        AssetId: newItem.Asset.Id,
        Currency: newItem.Currency,
        Digits: newItem.Digits,
        ContractSize: newItem.ContractSize,
        ContractValue: newItem.ContractValue,
        DataSourceId: newItem.DataSource.DataSourceId,
        Expiry: setTimeToUTC(new Date()),
        CreatedBy: 13,
        CreatedOn: setTimeToUTC(new Date()),
        ModifiedBy: 13,
        ModifiedOn: setTimeToUTC(new Date()),
        TickSize: newItem.TickSize,
        BidMarkup: newItem.BidMarkup,
        AskMarkup: newItem.AskMarkup,
        Spread: newItem.Spread,
      };

      // this.props.addSymbol(obj);
      symbolSvc.add(obj);
      symbolSvc.get().then((symbols) => this.setState({ symbols }));
    } else if (args.action === "edit") {
      const obj = {
        Id: newItem.Id,
        Symbol_: newItem.Symbol_,
        AssetId: newItem.Asset.Id,
        Currency: newItem.Currency,
        Digits: newItem.Digits,
        ContractSize: newItem.ContractSize,
        ContractValue: newItem.ContractValue,
        DataSourceId: newItem.DataSource.DataSourceId,
        Expiry: setTimeToUTC(new Date()),
        CreatedBy: 13,
        CreatedOn: setTimeToUTC(new Date()),
        ModifiedBy: 13,
        ModifiedOn: setTimeToUTC(new Date()),
        TickSize: newItem.TickSize,
        BidMarkup: newItem.BidMarkup,
        AskMarkup: newItem.AskMarkup,
        Spread: newItem.Spread,
      };

      // this.props.addSymbol(obj);
      symbolSvc.add(obj);
      symbolSvc.get().then((symbols) => this.setState({ symbols }));
    } else if (args.requestType === "delete") {
      const obj: any = args.data;

      if (obj) {
        // this.props.deleteSymbol(obj[0].Id);
        symbolSvc.delete(obj[0].Id);
        this.forceUpdate();
        symbolSvc.get().then((symbols) => this.setState({ symbols }));
      }
    }
  };
}
const mapStateToProps = (state: IAppState): ISymbolsListMappedProps => ({
  // symbols: state.symbols.symbolsList?.sort((a, b) => (a.Symbol_ > b.Symbol_ ? 1 : -1)),
  // assets: state.assetsList.assetsList || [],
  // dataSources: state.dataSources.dataSources || [],
});

const mapDispatchToProps = (dispatch: any): ISymbolsListDispatchProps => ({
  addSymbol: (symbolData) => dispatch(addSymbol(symbolData)),
  deleteSymbol: (key) => dispatch(deleteSymbol(key)),
  loadAssets: () => dispatch(loadAssetsList),
  loadDataSources: () => dispatch(loadDataSources),
  loadCurrency: () => dispatch(loadCurrency),
  loadSymbols: () => dispatch(loadSymbols),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSymbolsList);
