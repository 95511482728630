import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  Grid,
  GridComponent,
  InfiniteScroll,
  Inject,
  PageSettingsModel,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import { Component } from "react";
import FlexView from "react-flexview/lib";
import { IAdminBaseListProps } from "./IAdminBaseListProps";
import { IAdminBaseListState } from "./IAdminBaseListState";
import "./styles.scss";

class AdminBaseList extends Component<IAdminBaseListProps, IAdminBaseListState> {
  private grid: Grid | null = null;

  public editOptions: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Normal",
  };
  public toolbarOptions: ToolbarItems[] = ["Add", "Edit", "Delete", "Update", "Cancel", "Search"];
  public pageSettings: PageSettingsModel = { pageSize: 10 };

  // dataBound() {
  // Code for search
  //   this.element.querySelector('#' + this.element.getAttribute('id') + '_searchbar').addEventListener('keyup', function(e){
  //     this.closest('.e-grid').ej2_instances[0].search(this.value);
  //   });
  // }

  render() {
    const { dataSourceChanged, rowData } = this.props;

    const data = {
      result: rowData,
      count: rowData?.length,
    };

    return (
      <FlexView className="hw-admin-grid ag-theme-alpine">
        <GridComponent
          id="grid"
          height="100%"
          width="100%"
          editSettings={this.editOptions}
          toolbar={this.toolbarOptions}
          dataSource={data}
          dataSourceChanged={dataSourceChanged}
          enableInfiniteScrolling={true}
          // dataBound={this.dataBound}
          ref={(g) => (this.grid = g)}
        >
          <ColumnsDirective>
            {this.props.columnDefs.map((column) => (
              <ColumnDirective key={column.field} headerText={column.headerText} {...column} />
            ))}
          </ColumnsDirective>
          <Inject services={[Edit, Toolbar, InfiniteScroll]} />
        </GridComponent>
      </FlexView>
    );
  }
}

export default AdminBaseList;
