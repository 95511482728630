import { IFirm } from "../../models/IFirm";
import { IFirmData } from "../../models/IFirmData";
import { IFirmTradeLimit } from "../../models/IFirmTradeLimit";
import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import { FIRMS_SET_FIRMS, FIRMS_SET_FIRMSTRADELIMITS } from "./firms-types";
import { FirmsState } from "./FirmsState";

const initialState = new FirmsState();

export default function FirmsReducer(
  state = initialState,
  action: any
): FirmsState {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new FirmsState();
    case FIRMS_SET_FIRMS:
      return {
        ...state,
        list: action.payload,
        firmsData: prepareFirmsList(action.payload, state.firmTradeLimits),
      };
    case FIRMS_SET_FIRMSTRADELIMITS:
      return {
        ...state,
        firmTradeLimits: action.payload,
        firmsData: prepareFirmsList(state.list, action.payload),
      };

    default:
      return state;
  }
}

function prepareFirmsList(
  firm: IFirm[],
  firmTradeLimits: IFirmTradeLimit[]
): IFirmData[] {
  const result = firm?.map((firm) => ({
    firm,
    firmTradeLimits: firmTradeLimits?.find((x) => x.FirmId === firm.FirmId),
  }));

  return result;
}
