import store from "../../store/index";
import { connect } from "react-redux";
import { IAppState } from "../../store/reducers/IAppState";
import { setWidgetSymbol } from "../../store/widgets/widgets-actions";
import { ChangeEvent, Component } from "react";
import Form from "react-bootstrap/Form";
import { INewsDispatchProps, INewsMappedProps, INewsProps } from "./INewsProps";
import { INewsState } from "./INewsState";
import { getNewsHeadlines } from "../../store/quote-media/quote-media-async-actions";
import "./style.scss";

type Props = INewsProps & INewsMappedProps & INewsDispatchProps;

class News extends Component<Props, INewsState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      symbol: this.props.symbol,
      isSymbolSelected: false,
      headlines: store.getState().qm.headlines,
      searchDate: new Date(),
      dateFilter: false,
    };
  }

  async componentDidMount() {
    //this.loadInitialSymbol();
    this.setState({
      headlines: store.getState().qm.headlines,
      symbol: this.props.symbol,
    });
  }

  async componentDidUpdate(prevProps: Props) {
    this.handleSymbolUpdate(prevProps.symbol);
  }

  render() {
    setTimeout(async () => {
      if (
        store.getState().qm.headlines.length === 0 &&
        store.getState().qm.sid
      ) {
        await store.dispatch(getNewsHeadlines([this.state.symbol]));
        this.setState({
          headlines: store.getState().qm.headlines,
        });
      }
    }, 2500);

    return (
      <div>
        <div className="row">
          <div className="col">
            <div className="input-group mb-3">
              <input
                type="text"
                value={this.state.symbol}
                className="form-control"
                placeholder={this.state.symbol}
                aria-describedby="basic-addon2"
                onFocus={(ev) => ev.target.select()}
                onKeyPress={this.symbol_KeyPress}
                onChange={(e) => {
                  this.setState({
                    symbol: e.target.value.toUpperCase(),
                  });
                }}
              />
              <div className="input-group-append">
                <Form.Control
                  className="symbol"
                  type="button"
                  value={"Search"}
                  style={{ backgroundColor: "var(--foreground-color)" }}
                  onClick={() => {
                    this.search_Button();
                  }}
                />
                {/* <span className="input-group-text" id="basic-a ddon2">@example.com</span> */}
              </div>
            </div>
          </div>
          <div className="col-auto my-1">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={this.state.dateFilter}
                onChange={() => {
                  this.setState({ dateFilter: !this.state.dateFilter });
                }}
              />
              <label className="form-check-label">Search by Date</label>
            </div>
          </div>
          <div className="col">
            <Form.Control
              className="symbol"
              type="date"
              onChange={(e) => {
                this.setState({ searchDate: new Date(e.target.value) });
              }}
            />
          </div>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 scrollable">
            {store.getState().qm.headlines.length > 0
              ? store.getState().qm.headlines.map((headline) => {
                  return (
                    <div style={{ flexDirection: "row" }}>
                      {/* <div> */}
                      <span className="datecolor"> {headline.datetime}</span>
                      {/* </div>
                                   <div> */}
                      <a
                        href={headline.permalink || headline.storyurl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="content-date">
                          {" "}
                          {headline.headline}
                        </span>
                        {/* </div> */}
                      </a>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    );
  }

  symbol_Change = (e: ChangeEvent<HTMLInputElement>) => {
    const { isSymbolSelected } = this.state;

    if (isSymbolSelected) {
      /*this.props.unsubscribeMarketData();
          this.clearGrid();*/
    }

    this.setState({
      symbol: e.target.value.toUpperCase(),
      isSymbolSelected: false,
    });
  };

  symbol_KeyPress = async (e) => {
    if (e.key === "Enter") {
      if (!e.target.value) {
        //notificationSvc.error("Enter a Symbol to load Data!");
      } else {
        let symbol = e.target.value.toUpperCase();
        // symbol = getSymbol(symbol);
        //this.props.subscribeMarketData(symbol);
        this.props.setWidgetSymbol(symbol);
        this.setState({ isSymbolSelected: true });
        e.target.blur();
        e.target.focus();
        if (this.state.dateFilter) {
          await store.dispatch(
            getNewsHeadlines([symbol], this.state.searchDate)
          );
        } else {
          await store.dispatch(getNewsHeadlines([symbol]));
        }
        this.setState({
          headlines: store.getState().qm.headlines,
        });
      }
    } else if (e.target.value) {
      this.setState({
        symbol: e.target.value,
      });
    }
  };

  search_Button = async (symbol?: string) => {
    let searchSymbol = symbol ?? this.state.symbol;
    if (searchSymbol) {
      if (this.state.dateFilter) {
        await store.dispatch(
          getNewsHeadlines([searchSymbol], this.state.searchDate)
        );
      } else {
        await store.dispatch(getNewsHeadlines([searchSymbol]));
      }
      this.setState({
        headlines: store.getState().qm.headlines,
      });
    }
  };

  loadInitialSymbol(): void {
    const { symbol } = this.props;

    if (symbol) {
      this.setState({ symbol, isSymbolSelected: true });
      //this.props.subscribeMarketData(symbol);
      this.props.setWidgetSymbol(symbol);
    } else {
      /*const interval = setInterval(() => {
            const { isDataLoaded } = tempCache;
            if (isDataLoaded) {
              const { widgetSymbols, windowId } = this.props;
              if (!(windowId in widgetSymbols)) {
                this.props.setWidgetSymbol(Defaults.symbol);
              }
    
              clearInterval(interval);
            }
          }, 100);*/
    }
  }
  selectSymbol = (symbol: string) => {
    this.setState({
      symbol,
      isSymbolSelected: true,
    });
    this.props.setWidgetSymbol(symbol);
  };
  handleSymbolUpdate = (prevSymbol: string) => {
    const { symbol } = this.props;

    if (symbol !== prevSymbol) {
      if (symbol) {
        this.selectSymbol(symbol);
        this.setState({
          symbol: symbol,
        });
        this.search_Button(symbol);
      }
    }
  };
}

const mapStateToProps = (state: IAppState): INewsMappedProps => ({
  widgetSymbols: state.widgets.symbols,
});

const mapDispatchToProps = (
  dispatch: any,
  ownProps: INewsProps
): INewsDispatchProps => {
  const { windowId } = ownProps;

  return {
    setWidgetSymbol: (symbol) => dispatch(setWidgetSymbol(windowId, symbol)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
