import React from 'react'
import { useSelector } from 'react-redux'
import { IAppState } from '../../../store/reducers/IAppState'
import UsersList from '../users/users-list';
import AccountsList from '../accounts/accounts-list';
import GroupsList from '../groups/groups-list';
import FirmsList from '../firms/firms-list';
import AccountPerformance from '../account-performance/account-performance';
import store from '../../../store';
import FlexView from 'react-flexview';
import Accounts from "../../../Images/WebAdminIconSVG/account.svg";
import firm from "../../../Images/WebAdminIconSVG/firm.svg";
import group from "../../../Images/WebAdminIconSVG/group.svg";
import User from "../../../Images/WebAdminIconSVG/user.svg";
import "./style.scss";
import OrderBlotter from '../../order-blotter/order-blotter';

const SearchResults = () => {

  const searchResults = useSelector((state: IAppState) => state.users.searchResults);

  return (
    <React.Fragment>
      {searchResults && searchResults.IsSuccess && Object.keys(searchResults).map((key, index) => {
        switch(key)
        {
          case "users":
            if (searchResults.users.length) {
              return (
                <>
                  <FlexView className='toolbar-search'>
                    <FlexView
                      hAlignContent="left"
                      vAlignContent="center"
                      className="title"
                    >
                      <img
                        src={User}
                        alt={"Icon"}
                        className="mr-2 my-auto"
                        height={18}
                      />
                      User
                    </FlexView>
                  </FlexView>
                  <div className="border-widget">
                    <UsersList key={index} windowId={'users-search-results'} searchResults={searchResults.users} />
                  </div>
                </>
              )
            }
            else {
              return <React.Fragment key={index}></React.Fragment>
            }
          case "accounts":
            if (searchResults.accounts.length) {
              return (
                <>
                  <FlexView className='toolbar-search'>
                    <FlexView
                      hAlignContent="left"
                      vAlignContent="center"
                      className="title"
                    >
                      <img
                        src={Accounts}
                        alt={"Icon"}
                        className="mr-2 my-auto"
                        height={18}
                      />
                      Account
                    </FlexView>
                  </FlexView>
                  <div className="border-widget">
                    <AccountsList key={index} windowId={'accounts-search-results'} searchResults={searchResults.accounts} />
                  </div>
                </>
              )
            }
            else {
              return <React.Fragment key={index}></React.Fragment>
            }
          case "groups":
            if (searchResults.groups.length) {
              return (
                <>
                  <FlexView className='toolbar-search'>
                    <FlexView
                      hAlignContent="left"
                      vAlignContent="center"
                      className="title"
                    >
                      <img
                        src={group}
                        alt={"Icon"}
                        className="mr-2 my-auto"
                        height={18}
                      />
                      Group
                    </FlexView>
                  </FlexView>
                  <div className="border-widget">
                    <GroupsList key={index} windowId={'groups-search-results'} searchResults={searchResults.groups} />
                  </div>
                </>
              )
            }
            else {
              return <React.Fragment key={index}></React.Fragment>
            }
          case "firms":
            const isSuperAdmin = store.getState().auth.authInfo?.Meta.RoleName === "SuperAdmin";
            if (searchResults.firms.length && isSuperAdmin) {
              return (
                <>
                  <FlexView className='toolbar-search'>
                    <FlexView
                      hAlignContent="left"
                      vAlignContent="center"
                      className="title"
                    >
                      <img
                        src={firm}
                        alt={"Icon"}
                        className="mr-2 my-auto"
                        height={18}
                      />
                      Firm
                    </FlexView>
                    <div className="border-widget">
                      <FirmsList key={index} windowId={'firms-search-results'} searchResults={searchResults.firms} />
                    </div>
                  </FlexView>
                </>
              )
            }
            else {
              return <React.Fragment key={index}></React.Fragment>
            }
          case "accountPositions":
            if (searchResults.accountPositions.length) {
              return (
                <>
                  <FlexView className='toolbar-search'>
                    <FlexView
                      hAlignContent="left"
                      vAlignContent="center"
                      className="title"
                    >
                      <img
                        src={Accounts}
                        alt={"Icon"}
                        className="mr-2 my-auto"
                        height={18}
                      />
                      Account Watch
                    </FlexView>
                    <div className="border-widget">
                      <AccountPerformance key={index} windowId={'account-watch-search-results'} searchResults={searchResults.accountPositions} />
                    </div>
                  </FlexView>
                </>
              )
            }
            else {
              return <React.Fragment key={index}></React.Fragment>
            }
          case "orders":
            return (
              <>
                <FlexView className='toolbar-search'>
                  <FlexView
                    hAlignContent="left"
                    vAlignContent="center"
                    className="title"
                  >
                    <img
                      src={Accounts}
                      alt={"Icon"}
                      className="mr-2 my-auto"
                      height={18}
                    />
                    Orders
                  </FlexView>
                </FlexView>
                  <div className="border-widget">
                    <OrderBlotter key={index} windowId={'orders-search-results'} searchResults={searchResults.orders.concat(searchResults.ordersHistory)} />
                  </div>
              </>
            )
          default:
            return <React.Fragment key={index}></React.Fragment>
        }
      })}
    </React.Fragment>
  )
}

export default SearchResults;