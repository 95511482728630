export enum NatsMessageTypeEnum {
  None = 0,
  NewOrder = 1,
  CancelOrder = 2,
  ReplaceOrder = 3,
  CancelAllOrders = 4,
  UpdateCache = 5,
  GetPositions = 6,
  ExecReport = 7,
  SymPosUpdate = 8,
  AccPosUpdate = 9,
  GrpPosUpdate = 10,
  ConfigUpdate = 11,
  HB = 12,
  Config = 13,
  ClientHeartBeat,
}
