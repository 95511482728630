export enum ConfigMainCategoryEnum {
  NONE = 0,
  USER = 1,
  ACCOUNT = 2,
  SYMBOL = 3,
  FIRM = 4,
  GROUP = 5,
  ACTIVITY = 6,
  ROLE = 7,
  BROKER = 8,
  STRATEGY = 9,
  DESTINATION = 10,
  LOGIN = 11,
  LOGOUT = 12
}
