import { LEVEL_II_CLEAR_TIME_AND_SALES_FLAG, LEVEL_II_COPY_STATE, LEVEL_II_OPEN_TIME_AND_SALES } from "./level-ii-types";
import { LevelIIState } from "./LevelIIState";

export const clearTimeAndSalesFlag = () => (dispatch: any) => {
  dispatch({ type: LEVEL_II_CLEAR_TIME_AND_SALES_FLAG });
};

export const openTimeAndSales = (data: LevelIIState) => (dispatch: any) => {
  dispatch({ type: LEVEL_II_OPEN_TIME_AND_SALES, payload: data });
};

export const copyLevelIIState = (data) => (dispatch: any) => {
  dispatch({ type: LEVEL_II_COPY_STATE, payload: data });
};
