import { BehaviorSubject } from "rxjs";
import { IConfirmationModal } from "../models/IConfirmationModal";
import {
  DefaultConfirmationModalResponse,
  IConfirmationModalResponse,
} from "../models/IConfirmationModalResponse";

class ModalService {
  closeWorkspaceConfirmed$ = new BehaviorSubject<boolean>(false);
  orderConfirmation$ = new BehaviorSubject<IConfirmationModal | null>(null);
  orderConfirmationResponse$ = new BehaviorSubject<IConfirmationModalResponse>(
    DefaultConfirmationModalResponse
  );
  showCloseWorkspaceConfirmation$ = new BehaviorSubject<boolean>(false);
  showRenameWorkspaceModal$ = new BehaviorSubject<boolean>(false);
  workspaceRenamed$ = new BehaviorSubject<string>("");

  confirmationResponseIsHandled() {
    this.orderConfirmationResponse$.next(DefaultConfirmationModalResponse);
  }
}

const modalSvc = new ModalService();
export default modalSvc;
