import { IAccountStatus } from "../../models/IAccountStatus";
import { IColumnDefinition } from "../../models/IColumnDefinition";
import { IConfirmationModal } from "../../models/IConfirmationModal";
import { IKeyboardShortcutSetting } from "../../models/IKeyboardShortcutSetting";
import { IOrderInfoConfirmModal } from "../../models/IOrderInfoConfirmModal";
import { IShortcutCombination } from "../../models/IShortcutCombination";
import globalCache from "../../services/global-cache";
import { orderBlotterDefaultColumns } from "./default-columns/order-blotter";
import { positionBlotterDefaultColumns } from "./default-columns/position-blotter";
import { timeAndSalesDefaultColumns } from "./default-columns/time-and-sales";
import { watchListDefaultColumns } from "./default-columns/watch-list";
import { GeneralSettings } from "./GeneralSettings";
import { MarketDepthSettings } from "./MarketDepthSetting";
import { NotificationSettings } from "./NotificationSettings";

export class SettingsState {
  accountStatuses: IAccountStatus[] = [];
  general = new GeneralSettings();
  isSettingsShown = false;
  isShortcutCombinationsShown = false;
  isShortcutCombinationsInfoShown = false;
  isKeyboardShortcutShown = false;
  notificationSetting = new NotificationSettings();
  orderBlotterColumns: IColumnDefinition[] = [];
  positionBlotterColumns: IColumnDefinition[] = [];
  timeAndSalesColumns: IColumnDefinition[] = [];
  watchListColumns: IColumnDefinition[] = [];
  keyboardShortcut: IKeyboardShortcutSetting[] = [];
  selectedKeyboardShortcutToEdit = {} as IKeyboardShortcutSetting;
  isShortcutCombinationAllowed: boolean = false;
  shortcutCombinations: IShortcutCombination[] = [];
  selectedCombinationShortcutToEdit = {} as IShortcutCombination;
  confirmationModal: IConfirmationModal;
  isResponse?: boolean = false;
  isWidgetDisplayedModalShown = false;
  isUserClosedWidget = false;
  marketDepth = new MarketDepthSettings();
  isFullScreen: boolean = false;

  constructor() {
    const { orderBlotterColumns, positionBlotterColumns, timeAndSalesColumns, watchListColumns } = globalCache;

    this.orderBlotterColumns = orderBlotterColumns || orderBlotterDefaultColumns;
    if (!orderBlotterColumns) {
      globalCache.orderBlotterColumns = orderBlotterDefaultColumns;
    }

    this.positionBlotterColumns = positionBlotterColumns || positionBlotterDefaultColumns;
    if (!positionBlotterColumns) {
      globalCache.positionBlotterColumns = positionBlotterDefaultColumns;
    }

    this.timeAndSalesColumns = timeAndSalesColumns || timeAndSalesDefaultColumns;
    if (!timeAndSalesColumns) {
      globalCache.timeAndSalesColumns = timeAndSalesDefaultColumns;
    }

    this.watchListColumns = watchListColumns || watchListDefaultColumns;
    if (!watchListColumns) {
      globalCache.watchListColumns = watchListDefaultColumns;
    }

    this.confirmationModal = {
      windowId: "",
      type: "",
      orderInfo: {} as IOrderInfoConfirmModal,
      visible: false,
    };
    
    this.isResponse = false;
    this.isWidgetDisplayedModalShown = false;
    this.isUserClosedWidget = false;
  }
}
