import { ASSETS_GET_ASSETSLIST } from "./assets-types";
import { AssetState } from "./AssetState";

const initialState = new AssetState();

export default function AssetsListReducer(state = initialState, action: any): AssetState {
  switch (action.type) {
    case ASSETS_GET_ASSETSLIST:
      return {
        ...state,
        assetsList: action.payload,
      };

    default:
      return state;
  }
}
