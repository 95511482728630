import store from "../../store";
import "./forms.scss";
import jquery from "jquery";
import { showEditForm } from "../../store/users/users-actions";
import React, { useState } from "react";

const DragAndDrop = ({
  id,
  LeftTitle,
  LeftSideData,
  RightTitle,
  RightSideData,
  setNewData,
  removePreviousData,
  handler,
  fieldName,
}) => {

  const [leftSectionSearchString, setLeftSectionSearchString] = useState("");
  const [rightSectionSearchString, setRightSectionSearchString] = useState("");

  jquery(function () {
    if (jquery(".firstbox option").length === 0) {
      jquery("a.btn-form.right").addClass("disable");
    } else {
      jquery("a.btn-form.right").removeClass("disable");
    }
  });
  jquery(function () {
    if (jquery(".secbox option").length === 0) {
      jquery("a.btn-form.left").addClass("disable");
    } else {
      jquery("a.btn-form.left").removeClass("disable");
    }
  });

  const moveAll = (from, to) => {
    jquery("#" + from + " option")
      .remove()
      .appendTo("#" + to);
    updateRightSideEntries();
  };

  const moveSelected = (from, to) => {
    jquery("#" + from + " option:selected")
      .remove()
      .appendTo("#" + to);
    updateRightSideEntries();
  };

  const updateRightSideEntries = () => {
    let newValues: any = [];
    let deletedValues: any = [];
    const currentEntries: any = [];
    jquery(`#from_${id} option`).each((index, el) => {
      const itemId = parseInt(jquery(el).attr("value"));
      currentEntries.push(itemId);
      if (RightSideData && RightSideData.length) {
        const pos = RightSideData.findIndex((entry) => entry.value === itemId);
        if (pos === -1) {
          newValues.push(itemId);
        }
      } else {
        newValues.push(itemId);
      }
    });

    if (RightSideData && RightSideData.length) {
      RightSideData.forEach((item) => {
        if (!currentEntries.includes(item.value)) {
          deletedValues.push(item.primaryKey);
        }
      });
    }
    removePreviousData(deletedValues);
    setNewData(newValues);
  };

  const handleDoubleClick = (event) => {
    if (LeftTitle && LeftTitle.includes("Account")) {
      store.dispatch(
        showEditForm({
          status: true,
          data: {
            fieldName: "Edit Account",
            fieldData: event.target.value,
          },
        })
      );
    }
  };

  return (
    <React.Fragment key={id}>
      <div>
        <form name="selection" method="post">
          <div className="row mx-0 justify-content-center">
            <div className="col-12 d-flex px-0 px-sm-2">
              <div className="col-5">
                <div className="text-center">
                  <h6>{LeftTitle}</h6>
                </div>
                <div className="d-flex justify-content-end mt-auto px-0 drag-and-drop-search-bar mb-2">
                  <input
                    type="text"
                    placeholder={`Search in ${LeftTitle}`}
                    value={leftSectionSearchString}
                    onChange={(e) => { setLeftSectionSearchString(e.target.value) }}
                  />
                </div>
                <select
                  multiple
                  id={`to_${id}`}
                  size={15}
                  className="box1 firstbox"
                  onDoubleClick={(e) => {
                    handleDoubleClick(e);
                  }}
                >
                  {LeftSideData.filter((w) => (leftSectionSearchString && w.label && w.label.trim().toLowerCase().includes(leftSectionSearchString.trim().toLowerCase())) || !leftSectionSearchString.trim().length).map((option, index) => {
                    if (
                      RightSideData &&
                      RightSideData.length &&
                      RightSideData.findIndex(
                        (item) => item.value === option.value
                      ) === -1
                    ) {
                      return (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      );
                    } else if (!RightSideData || !RightSideData.length) {
                      return (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
              <div className="d-flex align-items-center">
                <div className="col-auto d-flex justify-content-center align-items-center px-auto text-center d-none d-md-block">
                  <a
                    onClick={() => {
                      moveSelected(`to_${id}`, `from_${id}`);
                    }}
                    className="btn btn-form btn-drag-drop dis-button mb-1 right"
                  >
                    &#62;
                  </a>
                  <a
                    onClick={() => {
                      moveSelected(`from_${id}`, `to_${id}`);
                    }}
                    className="btn btn-form btn-drag-drop dis-button my-1 left"
                  >
                    &#60;
                  </a>
                  <a
                    onClick={() => {
                      moveAll(`to_${id}`, `from_${id}`);
                    }}
                    className="btn btn-form btn-drag-drop dis-button my-1 right"
                  >
                    &#62;&#62;
                  </a>
                  <a
                    onClick={() => {
                      moveAll(`from_${id}`, `to_${id}`);
                    }}
                    className="btn btn-form btn-drag-drop dis-button mt-1 left"
                  >
                    &#60;&#60;
                  </a>
                </div>
                <div className="col-auto d-flex justify-content-center align-items-center px-0 text-center d-block d-md-none">
                  <a
                    onClick={() => {
                      moveSelected(`from_${id}`, `to_${id}`);
                    }}
                    className="btn btn-form btn-drag-drop dis-button me-1 right"
                  >
                    &#62;
                  </a>
                  <a
                    onClick={() => {
                      moveSelected(`to_${id}`, `from_${id}`);
                    }}
                    className="btn btn-form btn-drag-drop dis-button mx-1 left"
                  >
                    &#60;
                  </a>
                  <a
                    onClick={() => {
                      moveAll(`from_${id}`, `to_${id}`);
                    }}
                    className="btn btn-form btn-drag-drop dis-button mx-1 right"
                  >
                    &#62;&#62;
                  </a>
                  <a
                    onClick={() => {
                      moveAll(`to_${id}`, `from_${id}`);
                    }}
                    className="btn btn-form btn-drag-drop dis-button ms-1 left"
                  >
                    &#60;&#60;
                  </a>
                </div>
              </div>
              <div className="col-5 px-0 px-sm-2">
                <div className="text-center">
                  <h6>{RightTitle}</h6>
                  <div className="d-flex justify-content-end mt-auto px-0 drag-and-drop-search-bar mb-2">
                    <input
                      type="text"
                      placeholder={`Search in ${RightTitle}`}
                      value={rightSectionSearchString}
                      onChange={(e) => { setRightSectionSearchString(e.target.value) }}
                    />
                  </div>
                </div>
                <select
                  multiple
                  id={`from_${id}`}
                  className="box1 secbox"
                  size={15}
                  onDoubleClick={(e) => {
                    handleDoubleClick(e);
                  }}
                >
                  {RightSideData && RightSideData.length ? (
                    RightSideData.filter((w) => (rightSectionSearchString && w.label && w.label.trim().toLowerCase().includes(rightSectionSearchString.trim().toLowerCase())) || !rightSectionSearchString.trim().length).map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="d-flex justify-content-center mt-4 ">
        <button
          type="button"
          className="btn btn-form dis-button"
          onClick={handler}
          style={{ width: "100px" }}
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-form"
          data-bs-dismiss="modal"
          style={{ width: "100px" }}
          onClick={() => {
            store.dispatch(
              showEditForm({
                status: false,
                data: { fieldData: 0, fieldName },
              })
            );
          }}
        >
          Cancel
        </button>
      </div>
    </React.Fragment>
  );
};

export default DragAndDrop;
