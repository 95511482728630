import { CellClassParams, ValueGetterParams } from "ag-grid-community";
import {
  dateFormatter,
  dateValueGetter,
  timeFormatter,
  timeValueGetter,
} from "../../utils/datetime";
import CancelOrderBtn from "./cancelOrderBtn";

const orderSideCellClass = (params: CellClassParams) => {
  return params.data.Side?.toLowerCase();
};

const priceValueGetter = (params: ValueGetterParams) => {
  if (params.data.Price === 0) {
    return "MKT";
  }

  return params.data.Price?.toFixed(2);
};

export const ColumnDefinitions = (): any[] => {
  return [
    {
      field: "Cancel",
      title: "Cancel",
      cellRendererFramework: CancelOrderBtn,
      width: 45,
      pinned: true,
      resizable: true,
      visible: true,
    },
    {
      field: "Date",
      title: "Date",
      sortable: true,
      valueGetter: (params: any) => dateValueGetter(params?.data?.DateTime),
      valueFormatter: dateFormatter("DateTime"),
      visible: true,
    },
    {
      field: "Time",
      title: "Time",
      sortable: true,
      valueGetter: (params: any) => timeValueGetter(params?.data?.DateTime),
      valueFormatter: timeFormatter("DateTime"),
      visible: true,
    },
    {
      field: "AccountName",
      title: "AccountName",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['apply', 'reset'],
      },
      visible: true,
      sortable: true,
    },
    {
      field: "Source",
      title: "Source",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['apply', 'reset'],
      },
      visible: true,
      sortable: true,
    },
    {
      field: "Status",
      title: "Status",
      filter: "agTextColumnFilter",
      visible: true,
      sortable: true,
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['apply', 'reset'],
      },
      // cellClassRules: this.orderStatusCellClassRules(),
    },
    {
      field: "Symbol",
      title: "Symbol",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['apply', 'reset'],
      },
      visible: true,
      sortable: true,
    },
    {
      field: "Side",
      title: "Side",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['apply', 'reset'],
      },
      visible: true,
      sortable: true,
      cellClass: orderSideCellClass,
      // cellRendererFramework: textCellRenderer("Side"),
    },
    {
      valueGetter: priceValueGetter,
      field: "Price",
      title: "Price",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['apply', 'reset'],
      },
      visible: true,
    },
    {
      field: "AvgFillPrice",
      title: "AvgFillPrice",
      visible: true,
      sortable: true,
    },
    {
      field: "FillPrice",
      title: "FillPrice",
      visible: true,
      sortable: true,
    },
    {
      field: "Qty",
      title: "Qty",
      visible: true,
      cellClass: orderSideCellClass,
      // cellRendererFramework: textCellRenderer("Qty"),
    },
    {
      field: "SentUserName",
      title: "SentUserName",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['apply', 'reset'],
      },
      visible: true,
      sortable: true,
    },
    {
      field: "OrderType",
      title: "OrderType",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['apply', 'reset'],
      },
      visible: true,
      sortable: true,
    },
    {
      field: "Destination",
      title: "Destination",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['apply', 'reset'],
      },
      visible: true,
      sortable: true,
    },
    {
      field: "LiqFlag",
      title: "LiqFlag",
      visible: true,
      sortable: true,
    },
    {
      field: "CancelledUserName",
      title: "CancelledUserName",
      visible: true,
      sortable: true,
    },
    {
      field: "ClientOrderId",
      title: "ClientOrderId",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['apply', 'reset'],
      },
      visible: true,
      sortable: true,
    },
    {
      field: "OrigOrderId",
      title: "OrigOrderId",
      visible: true,
      sortable: true,
    },
    {
      field: "OrderId",
      title: "OrderId",
      visible: true,
      sortable: true,
    },
    {
      field: "RejDetail",
      title: "RejDetail",
      visible: true,
      sortable: true,
    },
    {
      field: "CallPut",
      headerName: "C/P",
      visible: true,
      sortable: true,
    },
  ];
  
}
