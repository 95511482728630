import { IUserAccount } from "../models/IUserAccount";
import apiSvc from "./api-service";

class UserAccountService {
  userAccounts: IUserAccount[] = [];

  async get() {
    const userAccountsResponse = await apiSvc.get("SelectUserAccountsRequest");
    this.userAccounts = userAccountsResponse?.data.Data;
    return this.userAccounts;
  }

  async add(userAccount) {
    await apiSvc.api.put("SaveUserAccountsRequest", userAccount);
  }

  async delete(delKeys) {
    await apiSvc.api.delete("DeleteUserAccountsRequest", {
      params: {
        PrimaryKey: delKeys.primaryKey,
        UserId: delKeys.userId,
        LoadCache: true,
      },
    });
  }
}
const userAccountSvc = new UserAccountService();
export default userAccountSvc;
