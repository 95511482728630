import { SkinColors } from "../../constants/skin-color";
import globalCache from "../../services/global-cache";

export class MarketDepthSettings {
  levelIISkinColor: string;

  constructor() {
    const { marketDepthSettings } = globalCache;
    this.levelIISkinColor = marketDepthSettings ? marketDepthSettings.levelIISkinColor : SkinColors.DefaultYellowBg;
  }
}
