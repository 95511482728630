import { ORDER_BLOTTER_ADD_COLUMN_FILTER, ORDER_BLOTTER_COPY_STATE, ORDER_BLOTTER_REMOVE_COLUMN_FILTER, ORDER_BLOTTER_RESET_COLUMN_FILTERS } from "./order-blotter-types";

export const addColumnFilter = (field: string, value: string) => (dispatch: any) =>
  dispatch({ type: ORDER_BLOTTER_ADD_COLUMN_FILTER, payload: { field, value } });

export const removeColumnFilter = (field: string) => (dispatch: any) =>
  dispatch({ type: ORDER_BLOTTER_REMOVE_COLUMN_FILTER, payload: field });

export const resetColumnFilters = () => (dispatch: any) =>
  dispatch({ type: ORDER_BLOTTER_RESET_COLUMN_FILTERS });

export const copyOrderBlotterState = (data) => (dispatch: any) =>
  dispatch({ type: ORDER_BLOTTER_COPY_STATE, payload: data });