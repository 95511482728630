import { useState } from "react";
import store from "../../../store";
import "./style.scss";
import DatePicker from 'react-datepicker';
import '../../../../node_modules/react-datepicker/dist/react-datepicker.css';
import Select, { MultiValue } from 'react-select';
import React from "react";
import apiSvc from "../../../services/api-service";
import moment from "moment";
import exportFromJSON from "export-from-json";
import notificationSvc from "../../../services/notification-service";
import { getDateForLastTradingDay, isValidDateRange } from "../../../utils/datetime";

enum UserReportType {
    USER_LOGS,
    USER_DETAIL,
    ACTIVE_USERS,
    EXECUTIONS,
    QM_ACCOUNT_REPORTS,
    LEADERBOARD,
    TRADERFI
};

enum ReportPeriod {
    TODAY,
    YESTERDAY,
    LAST_WEEK,
    LAST_FORTNIGHT,
    LAST_MONTH,
    LAST_YEAR,
    CUSTOM_RANGE
}

const reportTypes = [
    {
        label: "User Overview Report",
        value: UserReportType.USER_LOGS
    },
    {
        label: "User Detailed Report",
        value: UserReportType.USER_DETAIL
    },
    {
        label: "Active Users",
        value: UserReportType.ACTIVE_USERS
    },
    {
        label: "Executions",
        value: UserReportType.EXECUTIONS
    },
    {
        label: "Quotemedia Accounts",
        value: UserReportType.QM_ACCOUNT_REPORTS
    },
    {
        label: "Leaderboard",
        value: UserReportType.LEADERBOARD
    },
    {
        label: "Traderfi",
        value: UserReportType.TRADERFI
    },
];

type MenuOption = {
    label: string;
    value: number;
}

const reportRange = [
    {
        value: ReportPeriod.TODAY,
        label: "Today"
    },
    {
        value: ReportPeriod.YESTERDAY,
        label: "Last Trading Day" //Last Trading day
    },
    {
        value: ReportPeriod.LAST_WEEK,
        label: "Last 7 Days"
    },
    {
        value: ReportPeriod.LAST_FORTNIGHT,
        label: "Last 15 Days"
    },
    {
        value: ReportPeriod.LAST_MONTH,
        label: "Last Month"
    },
    {
        value: ReportPeriod.LAST_YEAR,
        label: "Last Year"
    },
    {
        value: ReportPeriod.CUSTOM_RANGE,
        label: "Custom Range"
    },
];

const reportsWithAccounts: UserReportType[] = [UserReportType.EXECUTIONS];
const reportsWithUsers: UserReportType[] = [UserReportType.USER_LOGS, UserReportType.USER_DETAIL, UserReportType.EXECUTIONS, UserReportType.LEADERBOARD, UserReportType.TRADERFI];
const reportsWithDateRange: UserReportType[] = [UserReportType.USER_LOGS, UserReportType.USER_DETAIL, UserReportType.EXECUTIONS, UserReportType.ACTIVE_USERS]

const Reports = () => {

    const users = store.getState().users.data;
    let userId = Number(store.getState().auth.authInfo?.UserId);
    userId = !isNaN(userId) ? Number(userId) : 0;

    const [dateRange, setDateRange] = useState<ReportPeriod>();
    const [reportType, setReportType] = useState<UserReportType>();
    const [usersList, setUsersList] = useState<MenuOption[]>([]);
    const [accountsList, setAccountsList] = useState<MenuOption[]>([]);
    const [selectedUsersList, setSelectedUsersList] = useState<number[]>([]);
    const [selectedAccountsList, setSelectedAccountsList] = useState<number[]>([]);
    const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#f7931a' : '#14181f',
            color: state.isSelected ? '#ffffff' : '#ffffff',
            '&:hover': {
                backgroundColor: '#f7931a',
                color: '#ffffff',
            },
            cursor: "pointer"
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#111111',
            color: '#ffffff',
            maxHeight: '200px',
            overflowY: 'auto',
        }),
    };

    const handleSelectUser = (option: MultiValue<MenuOption>) => {
        const userAccounts = store.getState().userAccountsList.userAccountsList;
        setSelectedUsersList(option.map((opt) => opt.value));
        setAccountsList(userAccounts.filter((usrAccount) => option.find((w) => w.value == usrAccount.UserId)).map((usrAccount) => {
            return { label: usrAccount.Account.Name, value: usrAccount.AccountId }
        }));
    };

    const handleSelectedAccount = (option: MultiValue<MenuOption>) => {
        setSelectedAccountsList(option.map((opt) => opt.value));
    };

    const handleSelectReportType = (value: number) => {
        setReportType(value);
        switch (value) {
            case UserReportType.USER_LOGS:
            case UserReportType.EXECUTIONS:
            case UserReportType.USER_DETAIL:
                setUsersList(users.map((user) => {
                    return { label: `${user.FirstName} ${user.LastName} ${user.Email}`, value: user.Id }
                }));
                break;
            default:
                break;
        }
    };

    const getDateRange = (data: any) => {
        let payload = { ...data };

        switch (dateRange) {
            case ReportPeriod.TODAY:
                payload = { ...payload, StartDate: `${moment().format("YYYY-MM-DD")} 00:00:00`, EndDate: `${moment().format("YYYY-MM-DD")} 23:59:59` };
                break;
            case ReportPeriod.YESTERDAY:
                let fromDate = `${getDateForLastTradingDay()} 00:00:00`;
                let toDate = `${getDateForLastTradingDay()} 23:59:59`;
                payload = { ...payload, StartDate: fromDate, EndDate: toDate };
                break;
            case ReportPeriod.LAST_WEEK:
                payload = { ...payload, StartDate: `${moment().subtract(7, "days").format("YYYY-MM-DD")} 00:00:00`, EndDate: `${moment().format("YYYY-MM-DD")} 23:59:59` };
                break;
            case ReportPeriod.LAST_FORTNIGHT:
                payload = { ...payload, StartDate: `${moment().subtract(15, "days").format("YYYY-MM-DD")} 00:00:00`, EndDate: `${moment().format("YYYY-MM-DD")} 23:59:59` };
                break;
            case ReportPeriod.LAST_MONTH:
                payload = { ...payload, StartDate: `${moment().subtract(1, "month").format("YYYY-MM-DD")} 00:00:00`, EndDate: `${moment().format("YYYY-MM-DD")} 23:59:59` };
                break;
            case ReportPeriod.LAST_YEAR:
                payload = { ...payload, StartDate: `${moment().subtract(1, "year").format("YYYY-MM-DD")} 00:00:00`, EndDate: `${moment().format("YYYY-MM-DD")} 23:59:59` };
                break;
            case ReportPeriod.CUSTOM_RANGE:
                payload = { ...payload, StartDate: `${moment(startDate).format("YYYY-MM-DD")} 00:00:00`, EndDate: `${moment(endDate).format("YYYY-MM-DD")} 23:59:59` };
                break;
            default:
                break;
        }

        return payload;
    };

    const generateUserLogs = async () => {
        try {
            const users = selectedUsersList.length ? selectedUsersList.length : usersList.map((w) => Number(w.value))
            let payload = getDateRange({
                CurrentUserId: userId,
                RequestUserIds: users
            });

            const response = await apiSvc.api.post("SelectUserLogsRequest", payload);
            if (response.data && response.data.IsSuccess) {
                const usersList = response.data?.Data.Users;
                let logData: any[] = [];
                let allActivityLogs: any[] = response.data.Data.ActivityLogs;
                let qmAccounts: any[] = response.data.Data.QMAccounts;

                usersList.forEach((user) => {

                    let userAccounts: any[] = response.data?.Data.UserAccounts.filter((w) => w.UserId == user?.Id).map((w) => w?.Account?.Name);
                    let qmAccountInfo = qmAccounts.find((w) => w.UserID == user?.Id);
                    let accountNames = userAccounts.length ? userAccounts.join(",") : "";
                    let activityLogs = allActivityLogs.filter((w) => w.UserId == user?.Id)
                    activityLogs = activityLogs.reduce((acc, current) => {
                        const existingEntry = acc.find(entry => entry.ExternalIP === current.ExternalIP);

                        if (!existingEntry) {
                            acc.push(current);
                        } else {
                            const existingTime = moment(existingEntry.Time);
                            const currentTime = moment(current.Time);

                            if (currentTime.isAfter(existingTime)) {
                                acc[acc.indexOf(existingEntry)] = current;
                            }
                        }

                        return acc;
                    }, []);

                    activityLogs.sort((a, b) => moment(a.Time).diff(moment(b.Time)));

                    logData.push({
                        UserName: user?.UserName,
                        Email: user?.Email,
                        FullName: `${user?.FirstName} ${user?.LastName}`,
                        Accounts: accountNames,
                        QMAccount: qmAccountInfo ? qmAccountInfo.UserName : "",
                        CreatedOn: moment(user?.CreatedDate).format("YYYY-MM-DD HH:mm:ss"),
                        FirstLogin: activityLogs.length ? moment(activityLogs[0].Time).format("YYYY-MM-DD HH:mm:ss") : "",
                        LastLoginAt: activityLogs.length ? moment(activityLogs[activityLogs.length - 1].Time).format("YYYY-MM-DD HH:mm:ss") : "",
                        LastLoginFromPlatform: activityLogs.length ? `${activityLogs[activityLogs.length - 1].Application} ${activityLogs[activityLogs.length - 1].Version}` : "",
                        LastLoginLocation: activityLogs.length ? `${activityLogs[activityLogs.length - 1].Location} ` : "",
                        LastLoginIP: activityLogs.length ? `${activityLogs[activityLogs.length - 1].ExternalIP} ` : "",
                        SuccessfulLogins: activityLogs.length
                    });
                });
                logData = logData.filter((w) => w.SuccessfulLogins > 0);
                if (logData.length) {
                    const fileName = `user_logs_reports_${moment().format("YYYY-MM-DD HH:mm:ss")}`;
                    const exportType = "csv";
                    exportFromJSON({ data: logData, fileName, exportType });
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    };

    const generateUserChargebackLogs = async () => {
        try {
            let payload = getDateRange({
                CurrentUserId: userId,
                RequestUserIds: selectedUsersList
            });

            const response = await apiSvc.api.post("SelectDetailedUserLogsRequest", payload);
            if (response.data && response.data.IsSuccess) {
                let reportData: any[] = [];

                selectedUsersList.forEach((usrId) => {
                    let userActivity = [];
                    const responseData = response.data.Data;
                    let loginHistory = responseData.ActivityLogs.filter((w) => w.UserId === usrId).map((w) => { return { ...w, ActivityType: "Login", ActivityTime: moment(w.Time).format("YYYY-MM-DD HH:mm:ss") } });
                    const usrInfo = responseData.Users.find((w) => w.Id === usrId);
                    let orders = responseData.Orders.filter((w) => w.SentUserId === usrId).map((w) => { return { ...w, ActivityType: "Order", ActivityTime: moment(w.DateTime).format("YYYY-MM-DD HH:mm:ss") } });
                    orders = orders.concat(responseData.OrdersHistory.filter((w) => w.SentUserId === usrId).map((w) => { return { ...w, ActivityType: "Order", ActivityTime: moment(w.DateTime).format("YYYY-MM-DD HH:mm:ss") } }));

                    userActivity = loginHistory.concat(orders);
                    userActivity.sort((a: any, b: any) => new Date(a.ActivityTime).getTime() - new Date(b.ActivityTime).getTime());
                    for (let i = 0; i < userActivity.length; i++) {
                        const currentElement: any = userActivity[i];
                        if (currentElement.ActivityType === "Order" && !currentElement.Application) {
                            for (let j = i - 1; j >= 0; j--) {
                                const previousElement: any = userActivity[j];
                                if (previousElement.ActivityType === "Login" && previousElement.Application) {
                                    currentElement.Application = previousElement.Application;
                                    currentElement.Version = previousElement.Version;
                                    currentElement.ExternalIP = previousElement.ExternalIP;
                                    currentElement.Location = previousElement.Location;
                                    break;
                                }
                            }
                        }
                    }
                    userActivity.forEach((activity: any, index) => {
                        let activityInfo;
                        if (activity.ActivityType === "Login") {
                            activityInfo = {
                                Id: index + 1,
                                UserName: usrInfo?.UserName,
                                FullName: `${usrInfo?.FirstName} ${usrInfo?.LastName}`,
                                ActivityType: activity.ActivityType,
                                Detail: "Logs in to platform",
                                ActivityTime: activity.ActivityTime,
                                ExternalIP: activity.ExternalIP,
                                Location: activity.Location,
                                Application: activity.Application,
                                Version: activity.Version
                            };
                        }
                        else {
                            activityInfo = {
                                Id: index + 1,
                                UserName: usrInfo?.UserName,
                                FullName: `${usrInfo?.FirstName} ${usrInfo?.LastName}`,
                                Detail: `Placed An Order - ${activity.ClientOrderId}`,
                                ...activity
                            };
                            delete activityInfo.DateTime;
                        }
                        reportData.push(activityInfo);
                    });
                    if (reportData.length) {
                        // reportData = reportData.reduce((acc, current) => {
                        //     const existingEntry = acc.find(entry => ((entry.ActivityType === "Login" && entry.ExternalIP === current.ExternalIP && entry.Application === current.Application)));

                        //     if (!existingEntry || current.ActivityType === "Order") {
                        //         acc.push(current);
                        //     } else {
                        //         const existingTime = moment(existingEntry.ActivityTime);
                        //         const currentTime = moment(current.ActivityTime);

                        //         if (currentTime.isAfter(existingTime)) {
                        //             acc[acc.indexOf(existingEntry)] = current;
                        //         }
                        //     }

                        //     return acc;
                        // }, []);
                        reportData.sort((a, b) => moment(a.ActivityTime).diff(moment(b.ActivityTime)));
                        const fileName = `${usrInfo.FirstName}_${usrInfo.LastName}_Details_${moment().format("YYYY-MM-DD HH:mm:ss")}`;
                        const exportType = "csv";
                        exportFromJSON({ data: reportData, fileName, exportType });
                    }
                });
            }
        }
        catch (e) {
            console.error(e);
        }
    };

    const getAccountIds = () => {
        let accountIds: any[] = [];
        const userAccounts = store.getState().userAccountsList.userAccountsList;
        if (selectedAccountsList.length) {
            accountIds = selectedAccountsList;
        }
        else if (!selectedAccountsList.length && selectedUsersList.length) {
            accountIds = userAccounts.filter((w) => selectedUsersList.includes(Number(w.UserId))).map((w) => w.AccountId);
        }
        else if (!selectedAccountsList.length && !selectedUsersList.length) {
            accountIds = userAccounts.map((w) => w.AccountId);
        }
        return accountIds;
    };

    const generateExecutionsReport = async () => {
        try {
            let payload = getDateRange({
                CurrentUserId: userId,
                RequestAccountIds: getAccountIds()
            });

            const response = await apiSvc.api.post("SelectExecutionsReport", payload);
            if (response.data && response.data.IsSuccess) {
                let reportData: any[] = [];
                const userAccounts = store.getState().userAccountsList.userAccountsList;
                let activityLogs = response.data.Data.ActivityLogs;
                activityLogs = activityLogs.map((w) => {
                    const userAccount = userAccounts.find((usr) => usr.UserId == w.UserId);
                    w.ActivityType = "Login";
                    w.ActivityTime = moment(w.Time).format("YYYY-MM-DD HH:mm:ss");
                    w.AccountId = userAccount?.AccountId;
                    return w;
                })
                let executions = response.data.Data.Executions;
                executions = executions.concat(response.data.Data.ExecutionsHistory);
                executions= executions.map((w) => {
                    const userAccount = userAccounts.find((usr) => usr.AccountId == w.AccountId);
                    w = {UserName: userAccount?.User?.Email, Account: userAccount?.Account?.Name, ActivityType: "Executions", ActivityTime: moment(w.Time).format("YYYY-MM-DD HH:mm:ss"), ...w};
                    return w;
                });

                let accountIdArray = executions.map((w) => w.AccountId);
                accountIdArray = [...new Set(accountIdArray)];

                accountIdArray.forEach((id) => {
                    const accountActivityLogs = activityLogs.filter((w) => w.AccountId == id);
                    const accountExecutions = executions.filter((w) => w.AccountId == id);
                    let accountExecutionReport = accountActivityLogs.concat(accountExecutions);
                    accountExecutionReport.sort((a, b) => moment(a.ActivityTime).diff(moment(b.ActivityTime)));
                    for (let i = 0; i < accountExecutionReport.length; i++) {
                        const currentElement: any = accountExecutionReport[i];
                        if (currentElement.ActivityType === "Executions" && !currentElement.Application) {
                            for (let j = i - 1; j >= 0; j--) {
                                const previousElement: any = accountExecutionReport[j];
                                if (previousElement.ActivityType === "Login" && previousElement.Application) {
                                    currentElement.Application = previousElement.Application;
                                    currentElement.Version = previousElement.Version;
                                    currentElement.ExternalIP = previousElement.ExternalIP;
                                    currentElement.Location = previousElement.Location;
                                    break;
                                }
                            }
                        }
                    }
    
                    const overheadKeys = ["ActivityTime", "ActivityType", "AccountId", "Id", "DestId", "OrderId"];
    
                    accountExecutionReport = accountExecutionReport.filter((w) => w.ActivityType !== "Login").map((w) => { 
                        w.Time = w.ActivityTime;
                        Object.keys(w).forEach((z) => {
                            if(overheadKeys.includes(z))
                            {
                                delete w[z];
                            }
                        });
                        return w;
                    });

                    reportData = reportData.concat(accountExecutionReport);
                });

                if(reportData.length)
                {
                    reportData.sort((a, b) => moment(a.Time).diff(moment(b.Time)));
                    const fileName = `Executions_${moment().format("YYYY-MM-DD HH:mm:ss")}`;
                    const exportType = "csv";
                    exportFromJSON({ data: reportData, fileName, exportType });
                }

            }
        }
        catch (e) {
            console.error(e);
        }
    };

    const generateQMAccountReports = async () => {
        try {
            const response = await apiSvc.api.post("SelectQMAccountReport", {
                CurrentUserId: userId,
                RequestUserIds: selectedUsersList
            });
            if (response.data && response.data.IsSuccess) {
                const usersList = response.data?.Data.Users;
                const qmAccounts = response.data?.Data.QMAccounts;
                let logData: any[] = [];
                qmAccounts.forEach((acc) => {
                    let user = usersList.find((w) => w.Id == acc.UserID);
                    let qmAccountInfo = {
                        User: user.Email,
                        FullName: `${user.FirstName} ${user.LastName}`,
                        QMAccount: acc.UserName,
                        Password: acc.Password,
                        Expiry: moment(user.PasswordExpiresOn).format("YYYY-MM-DD")
                    };
                    logData.push(qmAccountInfo);
                });
                if (logData.length) {
                    const fileName = `qm_accounts_report_${moment().format("YYYY-MM-DD HH:mm:ss")}`;
                    const exportType = "csv";
                    exportFromJSON({ data: logData, fileName, exportType });
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    };

    const generateActiveUsersReport = async () => {
        try {
            const allUsers = users.map((w) => Number(w.Id));
            let payload = getDateRange({
                CurrentUserId: userId,
                RequestUserIds: allUsers
            });

            const response = await apiSvc.api.post("SelectUserLogsRequest", payload);
            if (response.data && response.data.IsSuccess) {
                const usersList = response.data?.Data.Users;
                let logData: any[] = [];
                let allActivityLogs: any[] = response.data.Data.ActivityLogs;
                let qmAccounts: any[] = response.data.Data.QMAccounts;

                usersList.forEach((user) => {
                    let userAccounts: any[] = response.data?.Data.UserAccounts.filter((w) => w.UserId == user?.Id).map((w) => w?.Account?.Name);
                    let qmAccountInfo = qmAccounts.find((w) => w.UserID == user?.Id);
                    let accountNames = userAccounts.length ? userAccounts.join(",") : "";
                    let activityLogs = allActivityLogs.filter((w) => w.UserId == user?.Id)
                    activityLogs = activityLogs.reduce((acc, current) => {
                        const existingEntry = acc.find(entry => entry.ExternalIP === current.ExternalIP);

                        if (!existingEntry) {
                            acc.push(current);
                        } else {
                            const existingTime = moment(existingEntry.Time);
                            const currentTime = moment(current.Time);

                            if (currentTime.isAfter(existingTime)) {
                                acc[acc.indexOf(existingEntry)] = current;
                            }
                        }

                        return acc;
                    }, []);

                    activityLogs.sort((a, b) => moment(a.Time).diff(moment(b.Time)));

                    logData.push({
                        UserName: user?.UserName,
                        Email: user?.Email,
                        FullName: `${user?.FirstName} ${user?.LastName}`,
                        Accounts: accountNames,
                        QMAccount: qmAccountInfo ? qmAccountInfo.UserName : "",
                        CreatedOn: moment(user?.CreatedDate).format("YYYY-MM-DD HH:mm:ss"),
                        FirstLogin: activityLogs.length ? moment(activityLogs[0].Time).format("YYYY-MM-DD HH:mm:ss") : "",
                        LastLoginAt: activityLogs.length ? moment(activityLogs[activityLogs.length - 1].Time).format("YYYY-MM-DD HH:mm:ss") : "",
                        LastLoginFromPlatform: activityLogs.length ? `${activityLogs[activityLogs.length - 1].Application} ${activityLogs[activityLogs.length - 1].Version}` : "",
                        LastLoginLocation: activityLogs.length ? `${activityLogs[activityLogs.length - 1].Location} ` : "",
                        LastLoginIP: activityLogs.length ? `${activityLogs[activityLogs.length - 1].ExternalIP} ` : "",
                        SuccessfulLogins: activityLogs.length
                    });
                });
                logData = logData.filter((w) => w.SuccessfulLogins > 0).map((w) => { return { Email: w.Email } });
                if (logData.length) {
                    const fileName = `active_users_reports_${moment().format("YYYY-MM-DD HH:mm:ss")}`;
                    const exportType = "csv";
                    exportFromJSON({ data: logData, fileName, exportType });
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    };

    const generateReport = async () => {
        try {
            if (dateRange === ReportPeriod.CUSTOM_RANGE && !isValidDateRange(startDate, endDate)) {
                notificationSvc.error("Please select valid start date and End Date");
                return false;
            }

            setIsGeneratingReport(true);
            switch (reportType) {
                case UserReportType.USER_LOGS:
                    await generateUserLogs();
                    break;
                case UserReportType.USER_DETAIL:
                    await generateUserChargebackLogs();
                    break;
                case UserReportType.QM_ACCOUNT_REPORTS:
                    await generateQMAccountReports();
                    break;
                case UserReportType.EXECUTIONS:
                    await generateExecutionsReport();
                    break;
                case UserReportType.ACTIVE_USERS:
                    await generateActiveUsersReport();
                    break;
                default:
                    break;
            }
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setIsGeneratingReport(false);
        }
    };

    return (
        <div className="row mx-0 px-0 pb-3 overflow-y-scroll">
            <form className="w-100 report-form">
                <div className="col-12 d-flex align-items-center py-2 my-2 w-100">
                    <label className="col-2 px-0 form-label">Report</label>
                    <select
                        className="form-control fc-field form-select report-select pl-2"
                        aria-label=".form-select-lg example"
                        onChange={(e) => {
                            if (Number(e.target.value) > -1) {
                                handleSelectReportType(Number(e.target.value));
                            }
                        }}
                    >
                        <option key={-1} value={-1}>Select Report Type</option>
                        {
                            reportTypes.map((type, index) => {
                                return <option key={index} value={type.value}>{type.label}</option>
                            })
                        }
                    </select>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-between my-2 py-2 w-100 flex-wrap pr-0">
                    <label className={`col-2 pl-0 form-label mb-0`}>User</label>
                    <div className={`col-10 pl-0 custom-search-user-table`}>
                        <Select
                            value={usersList.filter((usr) => selectedUsersList.includes(usr.value))}
                            onChange={(e) => { handleSelectUser(e) }}
                            options={usersList}
                            isSearchable={true}
                            placeholder={reportType !== undefined && reportType > -1 ? "Select User" : ""}
                            isDisabled={reportType !== undefined && !reportsWithUsers.includes(reportType)}
                            styles={customStyles}
                            isMulti={true}
                        />
                    </div>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-between my-2 py-2 w-100 flex-wrap pr-0">
                    <label className={`col-2 pl-0 form-label`}>Account</label>
                    <div className={`col-10 pl-0 custom-search-user-table`}>
                        <Select
                            value={accountsList.filter((acc) => selectedAccountsList.includes(acc.value))}
                            onChange={(e) => { handleSelectedAccount(e) }}
                            options={accountsList}
                            isSearchable={true}
                            placeholder={reportType !== undefined && reportType > -1 ? "Select Account" : ""}
                            styles={customStyles}
                            isDisabled={reportType !== undefined && !reportsWithAccounts.includes(reportType)}
                            isMulti={true}
                        />

                    </div>
                </div>
                <React.Fragment>
                    <div className="col-12 d-flex align-items-center py-2 my-2 w-100">
                        <label className="col-2 px-0 form-label">Period</label>
                        <select
                            disabled={reportType !== undefined && !reportsWithDateRange.includes(Number(reportType))}
                            className="form-control fc-field form-select report-select pl-2"
                            aria-label=".form-select-lg example"
                            onChange={(e) => {
                                if (Number(e.target.value) > -1) {
                                    setDateRange(Number(e.target.value));
                                }
                            }}
                        >
                            <option key={-1} value={-1} selected>
                                Select Period
                            </option>
                            {
                                reportRange.map((range, index) => {
                                    return <option key={index} value={range.value} >{range.label}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-between my-2 py-2">
                        {
                            dateRange === ReportPeriod.CUSTOM_RANGE
                                ?
                                <React.Fragment>
                                    <label htmlFor="exampleInputDateCreate" className="col-2 pl-0 form-label mb-0">Start Date</label>
                                    <div className="col-4 px-0">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => { setStartDate(date) }}
                                            className="col-12 form-control fc-field pl-0 d-flex align-items-center"
                                            name="exampleInputDateCreate"
                                            id="exampleInputDateCreate"
                                            aria-describedby="AccountDateCreate"
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </div>
                                    <label htmlFor="exampleInputDateCreate" className="col-2 pl-0 form-label mb-0">End Date</label>
                                    <div className="col-4 px-0 d-flex justify-content-end">
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => { setEndDate(date) }}
                                            className="col-12 form-control fc-field pl-0 d-flex align-items-center"
                                            name="exampleInputDateCreate"
                                            id="exampleInputDateCreate"
                                            aria-describedby="AccountDateCreate"
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                        }
                    </div>
                </React.Fragment>
                <div className="col-auto d-flex align-items-center justify-content-end mt-2 px-0">
                    <button
                        type="button"
                        className="col-2 btn btn-form mr-3 mt-3 px-4"
                        onClick={generateReport}
                        disabled={isGeneratingReport}
                    >
                        {isGeneratingReport ? "Generating..." : "Generate"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Reports;
