import { IColumnDefinition } from "../../../models/IColumnDefinition";

export const groupColumnsDefinition: IColumnDefinition[] = [
  {
    title: "Firm",
    field: "FirmName",
    visible: true,
    sortOrder: 3,
    minWidth: 80,
  },
  {
    title: "Group",
    field: "GroupName",
    visible: true,
    sortOrder: 13,
    minWidth: 60,
  },
  {
    title: "SuperVisors",
    field: "SuperVisors",
    visible: true,
    sortOrder: 18,
    minWidth: 60,
  },
  {
    title: "Created On",
    field: "CreatedOn",
    visible: true,
    sortOrder: 9,
    minWidth: 80,
    filter: 'agDateColumnFilter' 
  }];
