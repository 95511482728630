import { STRATEGIES_GET_STRATEGIES } from "./strategy-types";
import { StrategyState } from "./StrategyState";

const initialState = new StrategyState();

export default function StrategyReducer(
  state = initialState,
  action: any
): StrategyState {
  switch (action.type) {
    case STRATEGIES_GET_STRATEGIES:
      return {
        ...state,
        strategiesist: action.payload,
      };

    default:
      return state;
  }
}
