import { OrderMessageTypeEnum } from "../enums/OrderMessageTypeEnum";
import { OrderSideEnum } from "../enums/OrderSideEnum";
import { OrderTypeEnum } from "../enums/OrderTypeEnum";
import tempCache from "../services/temp-cache";
import { longToInt8Array, longToUint8Array, numberToUint8Array, stringToAsciiArray } from "../utils/encoder";
import { OrderIdGenerator } from "../utils/OrderIdGenerator";

export class CancelOrder {
  accountId = 0;
  destinationId = 0;
  ocoId = '';
  originalOrderId = '';
  side = OrderSideEnum[OrderSideEnum.None];
  symbol = "";
  type = OrderTypeEnum[OrderTypeEnum.Market];
  userId: number;

  private static readonly _blockLength = 46;
  private static readonly _messageType = OrderMessageTypeEnum.CancelOrder;
  private static readonly _offset = 5;
  private static _process = 0;
  private _id: bigint = BigInt(0);
  private _time: Date;

  constructor() {
    const { userInfo } = tempCache;
    this.userId = userInfo ? +userInfo.UserId : 0;

    this._id = new OrderIdGenerator(this.userId).nextId();
    this._time = new Date();
  }

  get messageSize() {
    return CancelOrder._blockLength + this.symbol.length;
  }

  get symbolLength() {
    return this.symbol.length;
  }

  encode(): Uint8Array {
    const symbolIndex = CancelOrder._offset + CancelOrder._blockLength;
    
    const result = new Uint8Array(128);
    // console.log('cancel-order/messageSize', this.messageSize);
    result.set([this.messageSize], 0);
    // console.log('cancel-order/messageType', CancelOrder._messageType);
    result.set([CancelOrder._messageType], 1);
    // console.log('cancel-order/process', CancelOrder._process);
    result.set([CancelOrder._process], 2);
    // console.log('cancel-order/symbolIndex', symbolIndex);
    result.set([symbolIndex], 3);
    // console.log('cancel-order/symbolLength', this.symbolLength);
    result.set([this.symbolLength], 4);
    // console.log('cancel-order/userId', this.userId, numberToUint8Array(this.userId));
    result.set(numberToUint8Array(this.userId), CancelOrder._offset);
    // console.log('cancel-order/accountId', this.accountId, numberToUint8Array(this.accountId));
    result.set(numberToUint8Array(this.accountId), CancelOrder._offset + 4);
    // console.log('cancel-order/time', this._time.getTime(), longToInt8Array(BigInt(this._time.getTime())));
    result.set(longToInt8Array(BigInt(this._time.getTime())), CancelOrder._offset + 8);
    // console.log('cancel-order/side', OrderSideEnum[this.side].toString(), stringToAsciiArray(OrderSideEnum[this.side].toString()));
    result.set(stringToAsciiArray(OrderSideEnum[this.side].toString()), CancelOrder._offset + 16);
    // console.log('cancel-order/type', OrderTypeEnum[this.type].toString(), stringToAsciiArray(OrderTypeEnum[this.type].toString()));
    result.set(stringToAsciiArray(OrderTypeEnum[this.type].toString()), CancelOrder._offset + 17);
    // console.log('cancel-order/destinationId', this.destinationId, numberToUint8Array(this.destinationId));
    result.set(numberToUint8Array(this.destinationId), CancelOrder._offset + 18);
    // console.log('cancel-order/id', this._id, longToUint8Array(this._id));
    result.set(longToUint8Array(this._id), CancelOrder._offset + 22);
    // console.log('cancel-order/originalOrderId', this.originalOrderId, longToUint8Array(BigInt(this.originalOrderId)));
    result.set(longToUint8Array(BigInt(this.originalOrderId)), CancelOrder._offset + 30);
    // console.log('cancel-order/ocoId', this.ocoId, longToUint8Array(BigInt(this.ocoId)));
    result.set(longToUint8Array(BigInt(this.ocoId)), CancelOrder._offset + 38);
    // console.log('cancel-order/symbol', this.symbol, stringToAsciiArray(this.symbol));
    result.set(stringToAsciiArray(this.symbol), symbolIndex);

    return result;
  }

  // // toString() {
  // //   console.log('CancelOrder', this, `
  // //     message size: ${this.messageSize}
  // //     message type: ${CancelOrder._messageType}
  // //     process: ${CancelOrder._process}
  // //     symbol index: ${CancelOrder._blockLength + CancelOrder._offset}
  // //     symbol length: ${this.symbolLength}
  // //     userId: ${this.userId} / ${numberToUint8Array(this.userId)}
  // //     accountId: ${this.accountId} / ${numberToUint8Array(this.accountId)}
  // //     time: ${this._time.getTime()} / ${longToInt8Array(BigInt(this._time.getTime()))}
  // //     side: ${this.side} / ${numberToUint8Array(this.side)}
  // //     type: ${this.type} / ${numberToUint8Array(this.type)}
  // //     destination: ${this.destinationId} / ${numberToUint8Array(this.destinationId)}
  // //     id: ${this._id} / ${longToUint8Array(this._id)}
  // //     originalOrderId: ${this.originalOrderId} / ${longToUint8Array(BigInt(this.originalOrderId))}
  // //     ocoId: ${this.ocoId} / ${longToUint8Array(BigInt(this.ocoId))}
  // //     symbol: ${this.symbol} / ${stringToAsciiArray(this.symbol)}
  // //   `);
  // // }
}
