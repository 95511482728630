export const ACCOUNT_ADD_ASSIGNED_USER_ACCOUNT = "account/add-assigned-user-account";
export const ACCOUNT_REMOVE_UNASSIGNED_USER_ACCOUNT = "account/remove-unassigned-user-account";
export const ACCOUNT_SET_ACCOUNT_STATUS = "account/set-account-status";
export const ACCOUNT_SET_ACCOUNT_TRADE_LIMITS = "account/set-account-trade-limits";
export const ACCOUNT_SET_ACCOUNTS = "account/set-accounts";
export const ACCOUNT_SET_BUYING_POWER = "account/set-buying-power";
export const ACCOUNT_SET_DETACH_WINDOW_DETAILS_IN_PROGRESS = "account/set-detach-window-details-in-progress";
export const ACCOUNT_SET_ORDER_EXECUTIONS = "account/set-order-executions";
export const ACCOUNT_SET_FIRMS = "account/set-firms";
export const ACCOUNT_SET_ORDERS = "account/set-orders";
export const ACCOUNT_SET_USER_SYMBOL_LIST = "account/set-symbols-list";
export const ACCOUNT_UPDATE_ASSIGNED_USER_ACCOUNT = "account/update-assigned-user-account";
export const ACCOUNTS_SET_ALL_ACCOUNTS = "account/set-all-accounts";
export const ADD_NEW_ORDER = "account/new-order";
export const ADD_ORDER_EXECUTION = "account/order-execution";
export const ACCOUNTS_COPY_ACCOUNT_STATE = "account/copy-account-state";
