import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import { FIRMSTYPESLIST_GET_FIRMSTYPESLIST } from "./firms-types-list-types";
import { FirmsTypesListState } from "./FirmsTypesListState";

const initialState = new FirmsTypesListState();

export default function FirmsTypesListReducer(state = initialState, action: any): FirmsTypesListState {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new FirmsTypesListState();

    case FIRMSTYPESLIST_GET_FIRMSTYPESLIST:
      return {
        ...state,
        firmTypeList: action.payload,
      };

    default:
      return state;
  }
}
