import { useEffect, useRef, useState } from 'react';
import AGGridServerComponent from '../admin/ag-grid-list/ag-grid-server';
import { ColumnDefinitions } from './ColumnDefinitions';
import { GridApi, GridOptions, GridReadyEvent, RowDataTransaction } from 'ag-grid-community';
import { getOrders } from '../../store/user-accounts/user-account-async-action';
import { areArraysOfObjectsIdentical, buildQueryString, compareAndUpdateLists } from '../../utils/general';
import store from '../../store';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store/reducers/IAppState';
import AggridList, { KPIData } from '../admin/ag-grid-list/aggrid-list';
import { formatNumberForKPI } from '../../utils/number';
import moment from 'moment';
import IndexedDBService from '../../utils/idb_helper';

type OrderBlotterProps = {
  windowId: string,
  searchResults?: any[]
}

type GridFilter = {
  [key: string]: number | string
}

let lastAPICallTimeStamp = "";
let WebServiceKPIs = {
  totalOrders: 0,
  totalOpenOrders: 0,
  totalRejections: 0,
  totalFills: 0,
  totalBuys: 0,
  totalSells: 0,
  totalExecutions: 0,
  totalPriceValues: 0,
  totalCancelledOrders: 0,
};

const ORDER_STATS = ["Total Orders", "Open Orders", "Filled Orders", "Notional", "Buy / Sell", "Rejections", "Cancelled Orders", "Executions"];
let openOrders = [];

const OrderBlotter = ({ windowId, searchResults }: OrderBlotterProps) => {

  const columnDefs = ColumnDefinitions();
  const gridOptions: GridOptions = {
    columnDefs: columnDefs
  };

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageSize, setPageSize] = useState(50);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [filters, setfilters] = useState<GridFilter>();
  const [kpiDataArray, setKpiDataArray] = useState<KPIData[]>([]);

  const totalOrdersRef = useRef(0);
  const totalExecutionsRef = useRef(0);
  const totalOpenOrdersRef = useRef(0);
  const totalFilledOrdersRef = useRef(0);
  const totalValueOrderRef = useRef(0);
  const totalBuySellOrders = useRef("");
  const totalRejectedOrdersRef = useRef(0);
  const totalCancelledOrdersRef = useRef(0);

  const userAccounts = useSelector((state: IAppState) => state.userAccountsList.userAccountsList);

  const updateKPIData = async () => {
    try {
      if (!lastAPICallTimeStamp) {
        return;
      }
      let newOrders = await IndexedDBService.getObjectStore("Orders");
      newOrders = filterExternalData(newOrders);
      let updatedKPI = JSON.parse(JSON.stringify(WebServiceKPIs));
      newOrders.forEach((order) => {
        if (moment(lastAPICallTimeStamp).isBefore(order.DateTime)) {
          updatedKPI.totalOrders++;
          switch (order.Side) {
            case "Buy":
              updatedKPI.totalBuys++;
              break;
            case "Sell":
              updatedKPI.totalSells++;
              break;
            default:
              break;
          }

          switch (order.Status) {
            case "Filled":
              updatedKPI.totalFills++;
              updatedKPI.totalPriceValues += (order.FillQty + order.FillPrice);
              updatedKPI.totalExecutions++;
              if (openOrders.find((w: bigint) => w.toString() == order.ClientOrderId)) {
                updatedKPI.totalOpenOrders--;
              }
              break;
            case "Rejected":
              updatedKPI.totalRejections++;
              if (openOrders.find((w: bigint) => w.toString() == order.ClientOrderId)) {
                updatedKPI.totalOpenOrders--;
              }
              break;
            case "Cancelled":
              updatedKPI.totalCancelledOrders++;
              if (openOrders.find((w: bigint) => w.toString() == order.ClientOrderId)) {
                updatedKPI.totalOpenOrders--;
              }
              break;
            case "New":
            case "Sending":
            case "Pending":
              updatedKPI.totalOpenOrders++;
              break;
            // default:
            //   updatedKPI.totalOpenOrders++;
            //   break;
          }
        }
      });
      //Update KPI:
      totalOrdersRef.current = updatedKPI.totalOrders;
      totalExecutionsRef.current = updatedKPI.totalExecutions;
      totalOpenOrdersRef.current = updatedKPI.totalOpenOrders;
      totalFilledOrdersRef.current = updatedKPI.totalFills;
      totalValueOrderRef.current = updatedKPI.totalPriceValues;
      totalRejectedOrdersRef.current = updatedKPI.totalRejections;
      totalBuySellOrders.current = `${formatNumberForKPI(updatedKPI.totalBuys)} / ${formatNumberForKPI(updatedKPI.totalSells)}` as any;
      totalCancelledOrdersRef.current = updatedKPI.totalCancelledOrders;
    }
    catch (e) {
      console.error(e);
    }
  };

  const updateGrid = async (rowsTobeAdded: any[], rowsTobeUpdated: any[], rowsTobeDeleted: any[]) => {

    if (!rowsTobeAdded.length && !rowsTobeDeleted.length && rowsTobeUpdated.length) {
      let currentRows = getCurrentVisibleRows();
      currentRows = currentRows.filter((w) => rowsTobeUpdated.find((x) => x.ClientOrderId === w.ClientOrderId));
      if (areArraysOfObjectsIdentical(currentRows, rowsTobeUpdated, "ClientOrderId")) {
        return;
      }
    }

    const transaction: RowDataTransaction = {
    };

    let accountsMap

    if (userAccounts.length) {
      accountsMap = userAccounts.reduce((acc, obj) => acc.set(obj.AccountId, obj), new Map());
    }

    if(!accountsMap)
    {
      return;
    }

    rowsTobeAdded.forEach((order) => {
      const userAccount = accountsMap.get(order.AccountId);
      if (userAccount) {
        order.AccountName = userAccount?.Account?.Name;
        order.SentUserName = userAccount?.User?.UserName;
      }
    });

    rowsTobeUpdated.forEach((order) => {
      const userAccount = accountsMap.get(order.AccountId);
      if (userAccount) {
        order.AccountName = userAccount?.Account?.Name;
        order.SentUserName = userAccount?.User?.UserName;
      }
    });

    if (rowsTobeAdded.length) {
      transaction.add = rowsTobeAdded;
      transaction.addIndex = 0;
    }
    if (rowsTobeUpdated.length) {
      transaction.update = rowsTobeUpdated;
    }
    if (rowsTobeDeleted.length) {
      transaction.remove = rowsTobeDeleted;
    }

    if (gridApi && gridApi.applyTransaction) {
      gridApi.applyTransaction(transaction);
      const nodes = gridApi.getRenderedNodes();
      gridApi.refreshCells({
        force: true,
        rowNodes: nodes,
        columns: ["Cancel"],
      });
      updateKPIData();
      setKpiDataArray(ordersKPIData());
    }
  };

  const getCurrentVisibleRows = () => {
    let allVisibleRows: any[] = [];
    if (gridApi) {
      gridApi.forEachNodeAfterFilterAndSort(node => {
        if (node.displayed) {
          allVisibleRows.push(node.data);
        }
      });
    }
    return allVisibleRows;
  };

  const fetchData = async (page
    : number) => {
    try {
      const { authInfo } = store.getState().auth;
      if (!authInfo) {
        return;
      }

      let queryParams = {
        UserId: authInfo.UserId,
        Role: authInfo.Meta.RoleName,
        CurrentPage: page,
        PageSize: pageSize
      };

      if (filters && Object.keys(filters).length) {
        queryParams = { ...queryParams, ...filters };
      }
      setIsLoadingData(true);
      const orderResponse = await getOrders(buildQueryString(queryParams));
      if (orderResponse.IsSuccess) {
        WebServiceKPIs.totalBuys = Number(orderResponse?.BuyOrders);
        WebServiceKPIs.totalFills = Number(orderResponse?.FilledOrders);
        WebServiceKPIs.totalOpenOrders = Number(orderResponse?.OpenOrders?.length);
        WebServiceKPIs.totalOrders = Number(orderResponse?.TotalOrders);
        WebServiceKPIs.totalPriceValues = Number(orderResponse?.TotalOrderPrice);
        WebServiceKPIs.totalRejections = Number(orderResponse?.RejectedOrders);
        WebServiceKPIs.totalSells = Number(orderResponse?.SellOrders);
        WebServiceKPIs.totalExecutions = Number(orderResponse?.TotalExecutions);
        WebServiceKPIs.totalCancelledOrders = Number(orderResponse?.CancelledOrders);

        totalOrdersRef.current = Number(orderResponse?.TotalOrders);
        totalExecutionsRef.current = Number(orderResponse?.TotalExecutions);
        totalOpenOrdersRef.current = Number(orderResponse?.OpenOrders?.length);
        openOrders = orderResponse?.OpenOrders || [];
        totalFilledOrdersRef.current = Number(orderResponse?.FilledOrders);
        totalValueOrderRef.current = Number(orderResponse?.TotalOrderPrice);
        totalRejectedOrdersRef.current = Number(orderResponse?.RejectedOrders);
        totalBuySellOrders.current = `${formatNumberForKPI(orderResponse?.BuyOrders)} / ${formatNumberForKPI(orderResponse?.SellOrders)}` as any;
        setTotalPages(orderResponse?.TotalPages);
        orderResponse.orders.sort((a, b) => moment(b.DateTime).diff(moment(a.DateTime)));
        lastAPICallTimeStamp = moment().format("YYYY-MM-DD HH:mm:ss");
        updateGrid(orderResponse.orders, [], getCurrentVisibleRows());
      }
    }
    catch (e) {
      console.error(e);
    }
    finally {
      setIsLoadingData(false);
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    applyInitialColumnFilters(params.api);
  };

  const ordersKPIData = () => {
    const stats: KPIData[] = [];

    ORDER_STATS.forEach((param) => {

      let data;
      //["Total Orders", "Open Orders", "Filled Orders", "Notional", "Buy / Sell", "Rejections", "Cancelled Orders", "Executions"];

      switch (param) {
        case "Total Orders":
          data = formatNumberForKPI(totalOrdersRef.current || 0);
          break;
        case "Open Orders":
          data = formatNumberForKPI(totalOpenOrdersRef.current || 0);
          break;
        case "Filled Orders":
          data = formatNumberForKPI(totalFilledOrdersRef.current || 0);
          break;
        case "Notional":
          data = formatNumberForKPI(totalValueOrderRef.current || 0, true);
          break;
        case "Buy / Sell":
          data = totalBuySellOrders.current;
          break;
        case "Rejections":
          data = formatNumberForKPI(totalRejectedOrdersRef.current || 0);
          break;
        case "Cancelled Orders":
          data = formatNumberForKPI(totalCancelledOrdersRef.current || 0);
          break;
        case "Executions":
          data = formatNumberForKPI(totalExecutionsRef.current || 0);
          break;
      }

      stats.push({
        title: param,
        data
      });

    });

    return stats;
  };

  const handleFilterChange = () => {
    if (gridApi) {
      const filterModel = gridApi.getFilterModel();
      let currentFilters: any = {};

      if (filterModel && Object.keys(filterModel).length) {
        Object.keys(filterModel).forEach((key) => {
          if (filterModel[key] && filterModel[key].filter) {
            switch (key) {
              case "AccountName":
                currentFilters = { ...currentFilters, [key]: filterModel[key].filter };
                break;
              case "Source":
                currentFilters = { ...currentFilters, ["OrderSource"]: filterModel[key].filter };
                break;
              case "Status":
                currentFilters = { ...currentFilters, ["OrderStatus"]: filterModel[key].filter };
                break;
              case "Symbol":
                currentFilters = { ...currentFilters, [key]: filterModel[key].filter };
                break;
              case "Side":
                currentFilters = { ...currentFilters, ["OrderSide"]: filterModel[key].filter };
                break;
              case "Price":
                currentFilters = { ...currentFilters, ["OrderPrice"]: filterModel[key].filter };
                break;
              case "SentUserName":
                currentFilters = { ...currentFilters, [key]: filterModel[key].filter };
                break;
              case "OrderType":
                currentFilters = { ...currentFilters, [key]: filterModel[key].filter };
                break;
              case "Destination":
                currentFilters = { ...currentFilters, ["OrderDestination"]: filterModel[key].filter };
                break;
              case "ClientOrderId":
                currentFilters = { ...currentFilters, ["OrderId"]: filterModel[key].filter };
                break;
              default:
                break;
            }
          }
        });
      }

      if (currentFilters && Object.keys(currentFilters).length) {
        setfilters(currentFilters);
      }
      else {
        setfilters({});
      }
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchData(newPage);
  };

  const filterExternalData = (data: any[]): any[] => {
    if (!gridApi || !gridApi.getFilterModel() || Object.keys(gridApi.getFilterModel()).length === 0) {
      return data; // Return original data if no filters are applied
    }

    const currentAppliedFilters = gridApi.getFilterModel();

    return data.filter(row => {
      let flag = true;

      Object.keys(row).forEach((key) => {
        const isFilterDefined = typeof currentAppliedFilters[key] !== "undefined";
        const isRowDefined = typeof row[key] !== "undefined";

        const isFilterMismatch = isFilterDefined && row[key] != currentAppliedFilters[key].filter;
        const isStringAndNotIncluded = isFilterDefined && isRowDefined && typeof row[key] === "string" && !row[key].toLowerCase().trim().includes(currentAppliedFilters[key].filter.toLowerCase().trim());

        const isFilterFailed = isFilterDefined && isRowDefined && (isFilterMismatch || isStringAndNotIncluded);

        if (isFilterFailed) {
          flag = false;
          return;
        }

      });

      if (flag) {
        return row;
      }
    });
  };

  const applyInitialColumnFilters = (api) => {

    const filterValue = windowId.substring(windowId.indexOf('_') + 1, windowId.lastIndexOf('_'));

    if (filterValue) {
      const filterModel = {
        AccountName: { type: 'equals', filter: filterValue },
      };
      api.setFilterModel(filterModel);
    }
  };

  useEffect(() => {
    if (gridApi) {
      fetchData(1);
      setCurrentPage(1);
    }
  }, [pageSize, filters, gridApi]);

  useEffect(() => {
    if (gridApi && !searchResults) {
      const gridUpdateInterval = setInterval(async () => {
        let ordersList = await IndexedDBService.getObjectStore("Orders");
        let filteredData = filterExternalData(ordersList).slice(0, pageSize - 1);
        let currentRows = getCurrentVisibleRows();
        let { rowsTobeAdded, rowsTobeDeleted, rowsTobeUpdated } = compareAndUpdateLists(currentRows, filteredData, "ClientOrderId", pageSize);
        updateGrid(rowsTobeAdded, rowsTobeUpdated, rowsTobeDeleted);
      }, 250);

      return () => {
        clearInterval(gridUpdateInterval);
      };
    }

  }, [gridApi])

  return (
    <>
      {
        searchResults
          ?
          <AggridList
            gridOptions={gridOptions}
            pagination={true}
            rowData={searchResults}
            gridName={"Orders"}
            onGridReady={() => {}}
            rowIdSelector={(data) => data.ClientOrderId}
            windowId={windowId}
            noKPIData={true}
            hideInternalSearch={true}
          />
          :
          <AGGridServerComponent
            isLoadingGridData={isLoadingData}
            columnDefs={columnDefs}
            currentPage={currentPage}
            gridApi={gridApi}
            onFilterChanged={handleFilterChange}
            onPageChange={handlePageChange}
            paginationPageSize={pageSize}
            onGridReady={onGridReady}
            rowData={[]}
            totalPages={totalPages}
            totalResults={totalOrdersRef.current || 0}
            key={windowId}
            setPageSize={setPageSize}
            kpiData={kpiDataArray}
            showKPIData={true}
            rowIdSelector={(data) => data.ClientOrderId}
          />
      }
    </>
  )
}

export default OrderBlotter
