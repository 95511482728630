import { ILinkedWidget } from "../../models/ILinkedWidget";
import { IAppState } from "../reducers/IAppState";
import {
  WIDGETS_ACTIVE_WIDGET,
  WIDGETS_LOAD_LINKED_WIDGETS,
  WIDGETS_LINK_WIDGET,
  WIDGETS_SET_WIDGET_SYMBOLS,
  WIDGETS_UNLINK_WIDGET,
  WIDGETS_LOAD_WIDGET_SYMBOLS,
  WIDGETS_LINK_WIDGET_WITH_SYMBOL,
  WIDGETS_SET_LINKED_SYMBOL,
  WIDGETS_COPY_WIDGET_STATE,
  WIDGETS_DETACH_NEW_WIDGET,
} from "./widgets-types";

export const linkWidget =
  (workspaceId: string, widgetId: string, link: string, symbol: string) => (dispatch: any, getState: any) => {
    const appState: IAppState = getState();

    dispatch({
      type: WIDGETS_LINK_WIDGET,
      payload: {
        workspaceId,
        widgetId,
        link,
        symbol,
        userId: appState.auth.authInfo?.UserId,
      },
    });
  };

export const linkWidgetWithSymbol =
  (workspaceId: string, widgetId: string, link: string, symbol: string) => (dispatch: any) =>
    dispatch({ type: WIDGETS_LINK_WIDGET_WITH_SYMBOL, payload: { workspaceId, widgetId, link, symbol } });

export const removeWidgetSymbol = (widgetId: string) => (dispatch: any, getState: any) => {
  const appState: IAppState = getState();

  dispatch({
    type: WIDGETS_SET_WIDGET_SYMBOLS,
    payload: {
      widgetId,
      symbols: [],
      userId: appState.auth.authInfo?.UserId,
    },
  });
};

export const setActiveWidget = (workspaceId: string, widgetId: string) => (dispatch: any) => {
  dispatch({
    type: WIDGETS_ACTIVE_WIDGET,
    payload: {
      workspaceId,
      widgetId,
    },
  });
};

export const setLinkedSymbol = (widgetId: string, symbol: string) => (dispatch: any, getState: any) => {
  const appState: IAppState = getState();

  dispatch({
    type: WIDGETS_SET_LINKED_SYMBOL,
    payload: {
      widgetId,
      symbol,
      userId: appState.auth.authInfo?.UserId,
    },
  });
};

export const setWidgetSymbol = (widgetId: string, symbol: string) => (dispatch: any, getState: any) => {
  const appState: IAppState = getState();

  dispatch({
    type: WIDGETS_SET_WIDGET_SYMBOLS,
    payload: {
      widgetId,
      symbols: [symbol],
      userId: appState.auth.authInfo?.UserId,
    },
  });
};

export const setWidgetSymbols = (widgetId: string, symbols: string[]) => (dispatch: any, getState: any) => {
  const appState: IAppState = getState();

  dispatch({
    type: WIDGETS_SET_WIDGET_SYMBOLS,
    payload: {
      widgetId,
      symbols,
      userId: appState.auth.authInfo?.UserId,
    },
  });
};

export const loadLinkedWidgets = (linkedWidgets: ILinkedWidget[]) => (dispatch: any) =>
  dispatch({ type: WIDGETS_LOAD_LINKED_WIDGETS, payload: linkedWidgets });

export const loadWidgetSymbols = (widgetSymbols: { [widgetId: string]: string[] }) => (dispatch: any) =>
  dispatch({ type: WIDGETS_LOAD_WIDGET_SYMBOLS, payload: widgetSymbols });

export const copyWidgetState = (data) => (dispatch: any) =>
  dispatch({ type: WIDGETS_COPY_WIDGET_STATE, payload: data });

export const detachNewWidget = (windowInstance: Window) => (dispatch: any) =>
  dispatch({ type: WIDGETS_DETACH_NEW_WIDGET, payload: { windowInstance } });

export const unlinkWidget = (widgetId: string) => (dispatch: any, getState: any) => {
  const appState: IAppState = getState();

  dispatch({
    type: WIDGETS_UNLINK_WIDGET,
    payload: {
      widgetId,
      userId: appState.auth.authInfo?.UserId,
    },
  });
};
