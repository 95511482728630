import { IAccountPosition } from "../../models/IAccountPosition";
import { ISymbolPosition } from "../../models/ISymbolPosition";

export class PositionsState {
  accounts: IAccountPosition[] = [];
  closePnL = 0;
  openPnL = 0;
  position = 0;
  symbols: ISymbolPosition[] = [];
  totalVolume = 0;
}
