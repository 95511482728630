import { IGroup } from "../../models/IGroup";
import { GROUPS_SET_GROUPS, GROUPS_SET_GROUP_SUPERVISORS, GROUPS_SET_GROUP_TRADE_LIMITS } from "./groups-types";

export const setGroups = (groups: IGroup[] | undefined) => (dispatch: any) =>
  dispatch({ type: GROUPS_SET_GROUPS, payload: groups });

export const setGroupSupervisors = (supervisors: IGroup[]) => (dispatch: any) =>
  dispatch({ type: GROUPS_SET_GROUP_SUPERVISORS, payload: supervisors });

export const setGroupTradeLimits = (tradeLimits: IGroup[]) => (dispatch: any) =>
  dispatch({ type: GROUPS_SET_GROUP_TRADE_LIMITS, payload: tradeLimits });
