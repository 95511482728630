import { IAsset } from "../models/IAsset";
import apiSvc from "./api-service";

class AssetsServices {
  assets: IAsset[] = [];

  async get() {
    if (this.assets.length > 0) {
      return this.assets;
    }
    const response = await apiSvc.get("SelectAssetsRequest");
    return (this.assets = response?.data.Data);
  }
}

const assetsSvc = new AssetsServices();
export default assetsSvc;
