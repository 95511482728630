import { BROKER_SET_BROKER } from "./broker-types";
import { BrokerState } from "./BrokerState";

const initialState = new BrokerState();

export default function BrokerReducer(
  state = initialState,
  action: any
): BrokerState {
  switch (action.type) {
    case BROKER_SET_BROKER:
      return {
        ...state,
        brokersList: action.payload,
      };

    default:
      return state;
  }
}
