import { useEffect, useRef, useState } from 'react'
import AggridList from '../ag-grid-list/aggrid-list';
import { GridOptions } from 'ag-grid-community';
import { useDispatch } from 'react-redux';
import CustomSpinner from '../spinner/spinner';
import store from '../../../store';
import { showEditForm } from '../../../store/users/users-actions';
import { ContextMenuOption } from '../../context-menu/context-menu-types';
import editIcon from "../../../Images/editicon.svg";
import copyIcon from '../../../Images/copyicon.svg';
import { copyToClipboard } from '../../../utils/general';
import { configColumnsDefinition } from './ConfigColumnsDefinition';
import configSvc from '../../../services/config-services';

type Props = {
  windowId: string
}

const ConfigList = ({windowId}: Props) => {

  const gridOptions: GridOptions = {
    columnDefs: configColumnsDefinition
  };

  const configContextMenuOptions: ContextMenuOption[] = [
    {
      handler: () => { 
        store.dispatch(
          showEditForm({
            status: true,
            data: {
              fieldName: "Edit Setting",
              fieldData: currentRow?.Id,
            },
          })
        );
      },
      name: "Edit Setting",
      optionIcon: editIcon
    },
    {
      handler: () => copyToClipboard(gridApiRef),
      name: "Copy All",
      optionIcon: copyIcon
    },
  ];

  const dispatch = useDispatch();

  const [configData, setConfigData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [currentRow, setCurrentRow] = useState<any>();

  const gridApiRef = useRef<any>(null);

  useEffect(() => {
    const loadConfigData = async () => {
      try {
        const response: any = await configSvc.get();
        if (response) {
          const formattedSettings = response.map((setting) => {
            return {
              Owner: setting.Owner,
              SettingName: setting.Key,
              SettingValue: setting.Value,
              IsEnabled: setting.Enabled,
              ConfigId: setting.ConfigId
            }
          }).sort((a, b) => {
            const dateA = new Date(a.CreatedOn).getTime();
            const dateB = new Date(b.CreatedOn).getTime();
            return dateB - dateA;
          });
          setConfigData(formattedSettings);
        }
      }
      catch (e) {
        console.error(e);
      }
      finally {
        setIsLoadingData(false);
      }
    };
    loadConfigData();
  }, []);

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
  };

  return (
    <>
      {
        isLoadingData
          ?
          <CustomSpinner />
          :
          <AggridList
            gridOptions={gridOptions}
            onRowDoubleClicked={() => {}}
            primaryButtonText={'Add User'}
            primaryButtonOnClickHandler={() => { }}
            pagination={true}
            rowData={configData}
            contextMenuOptions={configContextMenuOptions}
            setRecordForContextMenu={setCurrentRow}
            gridName={"Settings"}
            onGridReady={onGridReady}
            noKPIData={true}
            windowId={windowId}
          />
      }
    </>
  )
}

export default ConfigList