class SoundService {
  play = (src: string) => {
    try {
      new Audio(src).play();
    } catch { }
  }
}

const soundSvc = new SoundService();
export default soundSvc;
