import { ConfigActionEnum } from "../enums/ConfigActionEnum";
import { ConfigMainCategoryEnum } from "../enums/ConfigMainCategoryEnum";
import { ConfigSubCategoryEnum } from "../enums/ConfigSubCategoryEnum";
import { NatsMessageTypeEnum } from "../enums/NatsMessageTypeEnum";
import {
  doubleFromUint8Array,
  int16FromUint8Array,
  int32FromUint8Array,
  numberFromUint8Array,
} from "../utils/decoder";
import { doubleToInt8Array, numberToUint8Array } from "../utils/encoder";

export class ConfigUpdate {
  private _buffer: Uint8Array;

  constructor(buffer?: Uint8Array) {
    if (buffer) {
      this._buffer = buffer;
    } else {
      this._buffer = new Uint8Array(128);
      this._buffer.set([22], 0);
      this._buffer.set([NatsMessageTypeEnum.ConfigUpdate], 1);
    }
  }

  get size(): number {
    return numberFromUint8Array(this._buffer, 0);
  }

  get messageType(): NatsMessageTypeEnum {
    return numberFromUint8Array(this._buffer, 1) as NatsMessageTypeEnum;
  }

  get mainCategory(): ConfigMainCategoryEnum {
    return numberFromUint8Array(this._buffer, 3) as ConfigMainCategoryEnum;
  }

  set mainCategory(val: ConfigMainCategoryEnum) {
    this._buffer.set([val], 3);
  }

  get subCategory(): ConfigSubCategoryEnum {
    return numberFromUint8Array(this._buffer, 4) as ConfigSubCategoryEnum;
  }

  set subCategory(val: ConfigSubCategoryEnum) {
    this._buffer.set([val], 4);
  }

  get action(): ConfigActionEnum {
    return numberFromUint8Array(this._buffer, 5) as ConfigActionEnum;
  }
  set action(val: ConfigActionEnum) {
    this._buffer.set([val], 5);
  }

  get mainId(): number {
    return int32FromUint8Array(this._buffer, 6);
  }

  set mainId(val: number) {
    this._buffer.set(numberToUint8Array(val), 6);
  }

  get subId(): number {
    return int32FromUint8Array(this._buffer, 10);
  }
  set subId(val: number) {
    this._buffer.set(numberToUint8Array(val), 10);
  }

  get modifier(): number {
    return int32FromUint8Array(this._buffer, 14);
  }
  set modifier(val: number) {
    this._buffer.set(numberToUint8Array(val), 14);
  }

  get value(): number {
    return numberFromUint8Array(this._buffer, 18);
  }

  set value(val: number) {
    this._buffer.set([val], 18);
  }

  get doubleValue(): number {
    return doubleFromUint8Array(this._buffer, 19);
  }

  set doubleValue(val: number) {
    this._buffer.set(doubleToInt8Array(val), 19);
  }
}
