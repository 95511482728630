import { BORROW_LIST_SET_BORROW_LIST } from "./borrow-list-types";
import { BorrowState } from "./BorrowState";

const initialState = new BorrowState();

export default function BorrowListReducer(state = initialState, action: any): BorrowState {
  switch (action.type) {
    case BORROW_LIST_SET_BORROW_LIST:
      return {
        ...state,
        borrowList: action.payload,
      };

    default:
      return state;
  }
}
