import apiSvc from "../../services/api-service";
import { authSvc } from "../../services/auth-service";
import tempCache from "../../services/temp-cache";
import { USER_LOGGED_OUT } from "../actions/types";
import {
  AUTH_COPY_AUTH_STATE,
  AUTH_LOGOUT_USER,
  AUTH_SET_ADMIN_PANEL_STATE,
  AUTH_SET_AUTH_INFO,
  AUTH_SET_ISADMIN,
  AUTH_SET_LOGIN_CREDENTIALS,
  AUTH_SET_LOGIN_REQUEST_IN_PROGRESS,
  AUTH_SET_LOGIN_RESPONSE,
} from "./auth-types";
import { AuthState } from "./AuthState";

const initialState = new AuthState();

export default function authReducer(
  state = initialState,
  action: any
): AuthState {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      localStorage.removeItem("defaultAdminColumnPermissions");
      return new AuthState();

    case AUTH_SET_AUTH_INFO:
      return {
        ...state,
        authInfo: action.payload,
      };

    case AUTH_SET_ISADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      };

    case AUTH_SET_ADMIN_PANEL_STATE:
      localStorage.setItem(
        "defaultAdminColumnPermissions",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        adminPanelState: {
          defaultColumns: {
            ...action.payload,
          },
        },
      };

    case AUTH_SET_LOGIN_CREDENTIALS:
      return {
        ...state,
        ...action.payload,
      };

    case AUTH_SET_LOGIN_RESPONSE:
      return {
        ...state,
        ...action.payload,
      };

    case AUTH_SET_LOGIN_REQUEST_IN_PROGRESS:
      return {
        ...state,
        loginRequestInProgress: action.payload,
      };

    case USER_LOGGED_OUT:
      tempCache.clear();
      return initialState;

    case AUTH_COPY_AUTH_STATE:
        return action.payload;

    default:
      return state;
  }
}

export function initializeAuthState() {
  const { userInfo } = tempCache;
  if (userInfo) {
    initialState.authInfo = userInfo;
    apiSvc.bearerToken = userInfo.BearerToken;

    authSvc.isLoggedIn$.next(true);
    authSvc.userId$.next(+userInfo.UserId);
  }
}
