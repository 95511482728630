import { IStrategy } from "../models/IStrategy";
import apiSvc from "./api-service";

class StrategyService {
  strategies: IStrategy[] = [];

  async get() {
    const response = await apiSvc.get("SelectStrategiesRequest");
    return (this.strategies = response?.data.Data);
  }
  async add(strategy) {
    await apiSvc.put("SaveStrategiesRequest", strategy);
  }

  async delete(strategyKey) {
    await apiSvc.api.delete("DeleteStrategiesRequest", {
      params: {
        PrimaryKey: strategyKey,
        LoadCache: true,
      },
    });
  }
}

const strategySvc = new StrategyService();
export default strategySvc;
