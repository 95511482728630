import { useEffect, useState } from 'react'
import Shell from '../shell/shell';
import { IShellViewProps } from '../shell/IShellViewProps';
import { copyWidgetState, setActiveWidget } from '../../store/widgets/widgets-actions';
import store from '../../store';
import { copyQMState } from '../../store/quote-media/quote-media-actions';
import { copyAccountState } from '../../store/account/account-actions';
import { copyAuthState } from '../../store/auth/auth-actions';
import { copyConnectionState } from '../../store/connection/connection-actions';
import { copyLevelIIState } from '../../store/level-ii-view/level-ii-actions';
import { copyOrderBlotterState } from '../../store/order-blotter/order-blotter-action';
import { copyPositionState } from '../../store/positions/positions-actions';
import { copySettingState } from '../../store/settings/settings-actions';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store/reducers/IAppState';
import { Widget } from '../main/MainViewState';
import { widgetsConfig } from '../../constants/widgets-config';
import { WatchListTypeEnum } from '../../enums/WatchListTypeEnum';
import { showEditForm, updateUsersState } from '../../store/users/users-actions';
import { copyUserAccounts } from '../../store/user-accounts/user-account-actions';

const DetachedWindow = () => {

  const [windowProps, setWindowProps] = useState<IShellViewProps>();

  const userStateData = useSelector((state: IAppState) => state.users);
  const [widgetsList, setWidgetsList] = useState<Widget[]>([]);

  useEffect(() => {

    const updateAppState = (stateData) => {
      if (stateData && Object.keys(stateData).length) {
        Object.keys(stateData).forEach((stateKey) => {
          switch (stateKey) {
            case "account":
              store.dispatch(copyAccountState(stateData[stateKey]));
              break;
            case "auth":
              store.dispatch(copyAuthState(stateData[stateKey]));
              break;
            case "connection":
              store.dispatch(copyConnectionState(stateData[stateKey]));
              break;
            case "levelII":
              store.dispatch(copyLevelIIState(stateData[stateKey]));
              break;
            case "orderBlotter":
              store.dispatch(copyOrderBlotterState(stateData[stateKey]));
              break;
            case "positions":
              store.dispatch(copyPositionState(stateData[stateKey]));
              break;
            case "settings":
              store.dispatch(copySettingState(stateData[stateKey]));
              break;
            case "widgets":
              store.dispatch(copyWidgetState(stateData[stateKey]));
              break;
            case "qm":
              store.dispatch(copyQMState(stateData[stateKey]));
              break;
            case "users":
              store.dispatch(updateUsersState(stateData[stateKey]));
              break;
            case "userAccountsList":
              store.dispatch(copyUserAccounts(stateData[stateKey]));
              break;
            default:
              break;
          }
        });
      }
    };

    const messageListener = (event: MessageEvent) => {
      try {
        if (event.origin === window.location.origin && typeof event.data === "string") {
          const parsedMessage = JSON.parse(event.data);
          switch (parsedMessage.type) {
            case "loadProps":
              setWindowProps(parsedMessage.payload);
              break;
            case "marketData":
            case "stateData":
              updateAppState(parsedMessage.payload);
              break;
            default:
              break;
          }
        }
      }
      catch (e) {
        console.error("Unable to handle the message", e);
        // window.close();
      }
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  useEffect(() => {
    if (userStateData.showEditForm) {
      let widgetTitle =
        store.getState().users.formData &&
          store.getState().users.formData.fieldName
          ? store.getState().users.formData.fieldName
          : "";

      let widgetId =
        store.getState().users.formData &&
          store.getState().users.formData.fieldData
          ? store.getState().users.formData.fieldData
          : 0;
      if (widgetTitle === "Orders" || widgetTitle === "Positions") {
        widgetId = `${widgetId.AccountId}_${widgetId.Account.Name}_${widgetTitle}`;
      }

      const config = widgetsConfig["form"];

      const widget: Widget = {
        ...config,
        title: widgetTitle,
        maxHeight: config.maxHeight,
        key: widgetId,
        maxWidth: config.maxWidth,
        dropdownValues: config.dropdownValues,
        positionX: 600,
        positionY: 150,
        isLocked: false,
        isMaximized: false,
        link: '',
        symbol: '',
        watchListType: WatchListTypeEnum.None
      }
      setWidgetsList(widgetsList.concat(widget));
      store.dispatch(showEditForm({ status: false }));
      store.dispatch(setActiveWidget(widget.key, widget.key));

      const parentWidget = document.getElementById(`${windowProps?.id}`);
      if(parentWidget)
      {
        parentWidget.classList.remove("active-widget");
      }
    }
  }, [userStateData])

  return (
    <div>
      {
        widgetsList.map((widget, index) => {
          return(
            <Shell
              close={() => {setWidgetsList((widgetsList.filter((w) => w.key != widget.key))) }}
              id={widget.key}
              workspaceId={widget.key}
              maximize={() => { }}
              update={() => { }}
              {...widget}
              isMaximized={false}
              isDetached={true} 
            />
          )
        })
      }
      {
        windowProps && windowProps.id
          ?
          <Shell
            {...windowProps}
            isMaximized={true}
            isDetached={true}
            maximize={() => { }}
            update={() => { }}
            close={() => {window.close()}}
          />
          :
          <></>
      }
    </div>
  )
}

export default DetachedWindow