import { IColumnDefinition } from "../../../models/IColumnDefinition";
import { formatCurrency, formatNumberForKPI } from "../../../utils/number";

export const accountPerformanceColumnsDefinition: IColumnDefinition[] = [
  {
    title: "Account Status",
    field: "AccountStatus",
    visible: true,
    sortOrder: 3,
    minWidth: 150,
  },
  {
    title: "User Status",
    field: "UserStatus",
    visible: true,
    sortOrder: 13,
    minWidth: 150,
  },
  {
    title: "Name",
    field: "UserName",
    visible: true,
    sortOrder: 18,
    minWidth: 150,
  },
  {
    title: "Account",
    field: "AccountName",
    visible: true,
    sortOrder: 19,
    minWidth: 150,
  },
  {
    title: "Total Position",
    field: "TotalPosition",
    visible: true,
    sortOrder: 11,
    minWidth: 150,
    valueFormatter: (params) => {
      return formatNumberForKPI(Math.abs(params.value));
  },
  // cellClass: params => (params.data.PositionMultiplier > 0 ? 'positive-value' : params.data.PositionMultiplier < 0 ? 'negative-value' : 'null-value'),
  },
  {
    title: "Open PNL",
    field: "OpenPNL",
    visible: true,
    sortOrder: 10,
    minWidth: 150,
    valueFormatter: (params) => {
        return formatCurrency(params.value);
    },
    // cellClass: params => (params.value > 0 ? 'positive-value' : params.value == 0 ? 'null-value' : 'negative-value'),
  },
  {
    title: "Close PNL",
    field: "ClosePNL",
    visible: true,
    sortOrder: 10,
    minWidth: 150,
    valueFormatter: (params) => {
        return formatCurrency(params.value);
    },
    // cellClass: params => (params.value > 0 ? 'positive-value' : params.value == 0 ? 'null-value' : 'negative-value'),
  },
  {
    title: "Total PNL",
    field: "TotalPNL",
    visible: true,
    sortOrder: 10,
    minWidth: 150,
    valueFormatter: (params) => {
        return formatCurrency(params.value);
    },
    // cellClass: params => (params.value > 0 ? 'positive-value' : params.value == 0 ? 'null-value' : 'negative-value'),
  },
  {
    title: "BP Used",
    field: "BPUsed",
    visible: true,
    sortOrder: 9,
    minWidth: 150,
    valueFormatter: (params) => {
      return formatCurrency(params.value);
    },
  },
  {
    title: "Buying Power",
    field: "BuyingPower",
    visible: true,
    sortOrder: 9,
    minWidth: 150,
    valueFormatter: (params) => {
      return formatCurrency(params.value);
    },
  },
  {
    title: "Max Loss",
    field: "MaxLoss",
    visible: true,
    sortOrder: 9,
    minWidth: 150,
    valueFormatter: (params) => {
      return formatCurrency(params.value);
    },
  },
  {
    title: "Account Balance",
    field: "Balance",
    visible: true,
    sortOrder: 9,
    minWidth: 150,
    valueFormatter: (params) => {
      return formatCurrency(params.value);
    },
  },
  {
    title: "Exposure",
    field: "Exposure",
    visible: true,
    sortOrder: 9,
    minWidth: 150,
    valueFormatter: (params) => {
      return formatCurrency(params.value);
    },
  },
  {
    title: "Qty Traded",
    field: "QtyTraded",
    visible: true,
    sortOrder: 9,
    minWidth: 150
  },
  {
    title: "Firm",
    field: "FirmName",
    visible: true,
    sortOrder: 21,
    minWidth: 150,
  },
  {
    title: "Group",
    field: "GroupName",
    visible: true,
    sortOrder: 21,
    minWidth: 150,
  },
  {
    title: "DrawDown",
    field: "DrawDown",
    visible: true,
    sortOrder: 12,
    minWidth: 150,
  },
  {
    title: "Run Up",
    field: "RunUp",
    visible: true,
    sortOrder: 12,
    minWidth: 150,
  },
  {
    title: "Pre/Post Market",
    field: "PrePostMarket",
    visible: true,
    sortOrder: 12,
    minWidth: 150,
  },
  {
    title: "BP Available",
    field: "BPAvailable",
    visible: true,
    sortOrder: 12,
    minWidth: 150,
    valueFormatter: (params) => {
        return formatCurrency(params.value);
      },
  },
  {
    title: "Max BP",
    field: "MaxBP",
    visible: true,
    sortOrder: 12,
    minWidth: 150,
  },
  {
    title: "Min BP",
    field: "MinBP",
    visible: true,
    sortOrder: 12,
    minWidth: 150,
  },
  {
    title: "Max Drawdown",
    field: "MaxDrawdown",
    visible: true,
    sortOrder: 9,
    minWidth: 150
  },
  {
    title: "DD Max Loss",
    field: "DDMaxLoss",
    visible: true,
    sortOrder: 12,
    minWidth: 150,
  }];
