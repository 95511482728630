import { FirebaseApp, initializeApp } from "firebase/app";
import {
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  child,
  DataSnapshot,
  get,
  getDatabase,
  onChildAdded,
  onChildChanged,
  onValue,
  ref,
  set,
} from "firebase/database";
import { firebaseConfig } from "../constants/firebaseConfig";
import store from '../store/index';
import { setWidgetSymbol } from "../store/widgets/widgets-actions";
import { BehaviorSubject } from "rxjs";
import { ResolutionString, VisibleTimeRange } from "../@types/charting_library";
import { authSvc } from "./auth-service";
import { GeneralSettings } from "../store/settings/GeneralSettings";
import { MarketDepthSettings } from "../store/settings/MarketDepthSetting";
import { loadGeneralSettings, loadMarketDepthSettings, loadNotificationSettings, loadOrderBlotterColumns, loadPositionBlotterColumns, loadTimeAndSalesColumns, loadWatchListColumns } from "../store/settings/settings-actions";
import { NotificationSettings } from "../store/settings/NotificationSettings";
import { orderBlotterDefaultColumns } from "../store/settings/default-columns/order-blotter";
import { positionBlotterDefaultColumns } from "../store/settings/default-columns/position-blotter";
import { timeAndSalesDefaultColumns } from "../store/settings/default-columns/time-and-sales";
import { watchListDefaultColumns } from "../store/settings/default-columns/watch-list";
import { IColumnDefinition } from "../models/IColumnDefinition";

class FirebaseService {
  app?: FirebaseApp;
  auth?: Auth;
  userId$ = new BehaviorSubject<string>("");
  promises: {
    [userId: string]: {
      [path: string]: any;
    };
  } = {};

  private userId = "";

  benchmark = () => {
    const dbRef = ref(getDatabase());
    const pathRef = child(dbRef, "counter");

    onValue(pathRef, callback);
    for (let idx = 0; idx < 2000; idx++) {
      set(pathRef, idx);
    }

    function callback(data: DataSnapshot) {
      if (data.key) {
        console.log("firebase", new Date().getTime());
      }
    }
  };

  getChartInterval = (chartId: string) => {
    return this.getData<ResolutionString>("charts", `${chartId}/interval`);
  };

  getChartLayout = (chartId: string) => {
    return this.getData<object>("charts", `${chartId}/layout`);
  };

  getChartVisibleRange = (chartId: string) => {
    return this.getData<VisibleTimeRange>("charts", `${chartId}/visible-range`);
  };

  getLayout = <T>(key: LayoutKey) => {
    return this.getData<T>("layout", key);
  };

  getGridLayout = <T>() => {
    return this.getData<T>("gridlayout", "columns");
  };

  getSettings = <T>(key: SettingKey) => {
    return this.getData<T>("settings", key);
  };

  handleChartSymbolUpdates = (userId: string) => {
    const userChartsRef = ref(getDatabase(), `${userId}/charts`);
    onChildAdded(userChartsRef, callback);
    onChildChanged(userChartsRef, callback);

    function callback(data: DataSnapshot) {
      if (data.key) {
        store.dispatch(setWidgetSymbol(data.key, data.val()));
      }
    }
  };

  initialize = () => {
    this.app = initializeApp(firebaseConfig);

    this.auth = getAuth(this.app);

    authSvc.firebaseUserId$.subscribe((userId) => {
      if (!userId) {
        this.userId = "";
      } else if (!this.userId) {
        this.userId = userId
        this.getSettings<GeneralSettings>("general-settings").then(
          (settings) =>
            settings && store.dispatch(loadGeneralSettings(settings))
        );

        this.getSettings<MarketDepthSettings>("market-depth-settings").then(
          (settings) =>
            settings && store.dispatch(loadMarketDepthSettings(settings))
        );

        this.getSettings<NotificationSettings>("notifications").then(
          (settings) =>
            store.dispatch(
              loadNotificationSettings({
                ...(settings || NotificationSettings.default),
                order: true, // Always true as this setting is disabled for now
              })
            )
        );

        this.loadColumns("order-blotter", orderBlotterDefaultColumns);
        this.loadColumns("position-blotter", positionBlotterDefaultColumns);
        this.loadColumns("time-and-sales", timeAndSalesDefaultColumns);
        this.loadColumns("watch-list", watchListDefaultColumns);
      }

      this.userId$.next(userId);
    });
  };

  firebaseSignIn = (email, password) => {
    if (!this.auth) {
      return false;
    }
    signInWithEmailAndPassword(this.auth, email, password)
      .then((data) => {
        authSvc.firebaseUserId$.next(data.user.uid);
      })
      .catch((error) => {
        // If the error indicates that the user doesn't exist, create the user
        if (error.code && this.auth) {
          createUserWithEmailAndPassword(this.auth, email, password)
            .then((data) => {
              authSvc.firebaseUserId$.next(data.user.uid);
            })
            .catch((createUserError) => {
              const errorCode = createUserError.code;
              const errorMessage = createUserError.message;
              console.error("Error creating user:", errorCode, errorMessage);
            });
        } else {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error("Error signing in:", errorCode, errorMessage);
        }
      });
  };

  saveChartInterval = async (chartId: string, interval: ResolutionString) => {
    return this.saveChildNode("charts", `${chartId}/interval`, interval);
  };

  saveChartLayout = async (chartId: string, layout: object) => {
    return this.saveChildNode("charts", `${chartId}/layout`, layout);
  };

  saveChartVisibleRange = async (
    chartId: string,
    visibleRange: VisibleTimeRange
  ) => {
    return this.saveChildNode(
      "charts",
      `${chartId}/visible-range`,
      visibleRange
    );
  };

  saveLayout = async (key: LayoutKey, value: any) => {
    return this.saveChildNode("layout", key, value);
  };

  saveGridLayout = async (value: any) => {
    return this.saveChildNode("gridlayout", "columns", value);
  };

  saveSettings = async (key: SettingKey, value: any) => {
    return this.saveChildNode("settings", key, value);
  };

  setChartSymbol = (windowId: string, symbol: string) => {
    set(ref(getDatabase(), `${this.userId}/charts/${windowId}`), symbol);
  };

  private getData = async <T>(parentNode: string, childNode: string) => {
    if (!this.userId) {
      return Promise.resolve(null);
    }

    const path = `${this.userId}/${parentNode}/${childNode}`;

    if (this.userId in this.promises && path in this.promises[this.userId]) {
      return this.promises[this.userId][path];
    }

    const promise = new Promise(async (resolve) => {
      const layoutRef = child(ref(getDatabase()), path);

      try {
        const snapshot = await get(layoutRef);

        if (snapshot.exists()) {
          const val = snapshot.val();
          resolve(val ? (JSON.parse(val) as T) : null);
        }

        resolve(null);
      } catch (error) {
        console.error(error);
        resolve(null);
      }
    });

    this.promises = {
      ...this.promises,
      [this.userId]: {
        ...this.promises[this.userId],
        [path]: promise,
      },
    };

    return promise;
  };

  private loadColumns = (
    key: LayoutKey,
    defaultColumns: IColumnDefinition[]
  ) => {
    this.getLayout<IColumnDefinition[]>(key).then((columns) => {
      switch (key) {
        case "order-blotter":
          const orderBlotterColumns: IColumnDefinition[] =
            columns || defaultColumns;
          if (
            orderBlotterColumns.findIndex((x) => x.field === "IsOpen") === -1
          ) {
            orderBlotterColumns.push({
              field: "IsOpen",
              title: "",
              sortOrder: 999,
              visible: false,
            });
          }

          store.dispatch(loadOrderBlotterColumns(orderBlotterColumns));
          break;

        case "position-blotter":
          store.dispatch(loadPositionBlotterColumns(columns || defaultColumns));
          break;

        case "time-and-sales":
          store.dispatch(loadTimeAndSalesColumns(columns || defaultColumns));
          break;

        case "watch-list":
          store.dispatch(loadWatchListColumns(columns || defaultColumns));
          break;
      }

      if (!columns) {
        this.saveLayout(key, defaultColumns);
      }
    });
  };

  private saveChildNode = async (
    parentNode: string,
    childNode: string,
    value: any
  ) => {
    if (!this.userId) {
      return Promise.resolve(null);
    }

    const path = `${this.userId}/${parentNode}/${childNode}`;

    this.promises = {
      ...this.promises,
      [this.userId]: {
        ...this.promises[this.userId],
        [path]: Promise.resolve(value),
      },
    };

    const layoutRef = child(ref(getDatabase()), path);

    try {
      set(layoutRef, JSON.stringify(value));
    } catch (error) {
      console.error(error);
      return await Promise.resolve(null);
    }
  };
}

const firebaseSvc = new FirebaseService();

export default firebaseSvc;


export type LayoutKey =
  | "order-blotter"
  | "position-blotter"
  | "time-and-sales"
  | "watch-list";

export type SettingKey =
  | "general-settings"
  | "is-shortcut-combination-allowed"
  | "keyboard-shortcuts"
  | "linked-widgets"
  | "market-depth-settings"
  | "notifications"
  | "shortcut-combinations"
  | "widget-symbols"
  | "workspaces"
  | "detached-windows"
  | "theme-preference-settings"
  | "searchedSymbols";