import { CacheKeys } from '../constants/cache-keys';
import { IColumnDefinition } from '../models/IColumnDefinition';
import { IKeyboardShortcutSetting } from '../models/IKeyboardShortcutSetting';
import { ILoginCredentials } from '../models/ILoginCredentials';
import { IShortcutCombination } from '../models/IShortcutCombination';
import { IUserLinkedWidgets } from '../models/IUserLinkedWidgets';
import { IUserWidgetSymbol } from '../models/IUserWidgetSymbol';
import { IUserWorkspaces } from '../models/IUserWorkspaces';
import { GeneralSettings } from '../store/settings/GeneralSettings';
import { MarketDepthSettings } from '../store/settings/MarketDepthSetting';
import { NotificationSettings } from '../store/settings/NotificationSettings';

class GlobalCache {
  get cacheVersion() {
    return this.getValue('trader2b-cache-version');
  }

  set cacheVersion(value: string | null) {
    this.setItem('trader2b-cache-version', value);
  }

  get detachWindowDetails() {
    return this.getItem<any>('trader2b-detach-window-details');
  }

  set detachWindowDetails(value: any) {
    this.setItem('trader2b-detach-window-details', value);
  }

  get generalSettings() {
    return this.getItem<GeneralSettings>('trader2b-general-settings');
  }

  set generalSettings(value: GeneralSettings | null) {
    this.setItem('trader2b-general-settings', JSON.stringify(value));
  }

  get isShortcutCombinationAllowed() {
    return this.getItem<boolean>('trader2b-is-shortcut-combination-allowed') || false;
  }

  set isShortcutCombinationAllowed(value: boolean) {
    this.setItem('trader2b-is-shortcut-combination-allowed', value);
  }

  get keyboardShortcuts() {
    return this.getItem<IKeyboardShortcutSetting[]>('trader2b-keyboard-shortcuts') || [];
  }

  set keyboardShortcuts(value: IKeyboardShortcutSetting[]) {
    this.setItem('trader2b-keyboard-shortcuts', value);
  }

  get linkedWidgets() {
    return this.getItem<IUserLinkedWidgets>('trader2b-linked-widgets') || {};
  }

  set linkedWidgets(savedDetails: IUserLinkedWidgets) {
    this.setItem('trader2b-linked-widgets', savedDetails);
  }

  get marketDepthSettings() {
    return this.getItem<MarketDepthSettings>('trader2b-market-depth-settings');
  }

  set marketDepthSettings(value: MarketDepthSettings | null) {
    this.setItem('trader2b-market-depth-settings', value);
  }

  get notificationSettings() {
    return this.getItem<NotificationSettings>('trader2b-notification-settings');
  }

  set notificationSettings(value: NotificationSettings | null) {
    this.setItem('trader2b-notification-settings', JSON.stringify(value));
  }

  get orderBlotterColumns() {
    return this.getItem<IColumnDefinition[]>(CacheKeys.OrderBlotterColumns);
  }

  set orderBlotterColumns(value: IColumnDefinition[] | null) {
    this.setItem(CacheKeys.OrderBlotterColumns, value);
  }

  get positionBlotterColumns() {
    return this.getItem<IColumnDefinition[]>(CacheKeys.PositionBlotterColumns);
  }

  set positionBlotterColumns(value: IColumnDefinition[] | null) {
    this.setItem(CacheKeys.PositionBlotterColumns, value);
  }

  get shortcutCombinations() {
    return this.getItem<IShortcutCombination[]>('trader2b-shortcut-combination-list') || [];
  }

  set shortcutCombinations(value: IShortcutCombination[]) {
    this.setItem('trader2b-shortcut-combination-list', value);
  }

  get timeAndSalesColumns() {
    return this.getItem<IColumnDefinition[]>(CacheKeys.TimeAndSalesColumns);
  }

  set timeAndSalesColumns(value: IColumnDefinition[] | null) {
    this.setItem(CacheKeys.TimeAndSalesColumns, value);
  }

  get userSavedDetails() {
    return this.getItem<ILoginCredentials>('trader2b-user-saved-details');
  }

  set userSavedDetails(value: ILoginCredentials | null) {
    this.setItem('trader2b-user-saved-details', value);
  }

  get watchListColumns() {
    return this.getItem<IColumnDefinition[]>(CacheKeys.WatchListColumns);
  }

  set watchListColumns(value: IColumnDefinition[] | null) {
    this.setItem(CacheKeys.WatchListColumns, value);
  }

  get agGridColumns() {
    return this.getItem(CacheKeys.AGGridColumns);
  }

  set agGridColumns(value: any) {
    this.setItem(CacheKeys.AGGridColumns, value);
  }

  get widgetSymbols() {
    return this.getItem<IUserWidgetSymbol>('trader2b-widget-symbols') || {};
  }

  set widgetSymbols(value: IUserWidgetSymbol) {
    this.setItem('trader2b-widget-symbols', value);
  }

  get workspaces() {
    return this.getItem<IUserWorkspaces>('trader2b-workspaces') || {};
  }

  set workspaces(savedDetails: IUserWorkspaces) {
    this.setItem('trader2b-workspaces', savedDetails);
  }

  get globalSearchHistory() {
    return this.getItem<string[]>('trader2b-global-search-history') || [];
  }

  set globalSearchHistory(value: string[]) {
    this.setItem('trader2b-global-search-history', value);
  }

  clear = () => localStorage.clear();

  getItem = <T>(key: string): T | null => {
    const cached = localStorage.getItem(key);

    return cached ? JSON.parse(cached) : null
  }

  getValue = (key: string): string | null => localStorage.getItem(key);

  removeItem = (key: string) => localStorage.removeItem(key);

  setItem = (key: string, value: any) =>
    localStorage.setItem(key, typeof value !== 'string' ? JSON.stringify(value) : value);
};

export default new GlobalCache();
