import { IColumnDefinition } from "../../../models/IColumnDefinition";

export const orderBlotterDefaultColumns: IColumnDefinition[] = [
  {
    title: "Account",
    field: "AccountName",
    visible: true,
    sortOrder: 3,
    minWidth: 150,
  },
  {
    title: "Avg Filled Price",
    field: "AvgFillPrice",
    visible: true,
    sortOrder: 13,
    minWidth: 150,
  },
  {
    title: "Canceled By",
    field: "CancelledUserName",
    visible: true,
    sortOrder: 18,
    minWidth: 150,
  },
  {
    title: "CI Ord ID",
    field: "ClientOrderId",
    visible: true,
    sortOrder: 19,
    minWidth: 150,
  },
  {
    title: "Source",
    field: "Source",
    visible: true,
    sortOrder: 11,
    minWidth: 150,
  },
  {
    title: "Created By",
    field: "SentUserName",
    visible: true,
    sortOrder: 10,
    minWidth: 150,
  },
  { title: "Date", field: "Date", visible: true, sortOrder: 1, minWidth: 150 },
  {
    title: "Destination",
    field: "Destination",
    visible: true,
    sortOrder: 9,
    minWidth: 150,
  },
  {
    title: "Exch Ord ID",
    field: "OrderId",
    visible: true,
    sortOrder: 21,
    minWidth: 150,
  },
  {
    title: "Last Filled Qty",
    field: "LastFill",
    visible: true,
    sortOrder: 12,
    minWidth: 150,
  },
  {
    title: "Left Shares",
    field: "RestQty",
    visible: true,
    sortOrder: 16,
    minWidth: 150,
  },
  {
    title: "Liq Flag",
    field: "LiqFlag",
    visible: true,
    sortOrder: 14,
    minWidth: 150,
  },
  {
    title: "Order Size",
    field: "Qty",
    visible: true,
    sortOrder: 7,
    minWidth: 150,
  },
  {
    title: "Order Status",
    field: "Status",
    visible: true,
    sortOrder: 8,
    minWidth: 150,
  },
  {
    title: "Order Type",
    field: "OrderType",
    visible: true,
    sortOrder: 12,
    minWidth: 150,
  },
  {
    title: "Orig CI Ord ID",
    field: "OrigOrderId",
    visible: true,
    sortOrder: 20,
    minWidth: 150,
  },
  {
    title: "Price",
    field: "Price",
    visible: true,
    sortOrder: 6,
    minWidth: 150,
  },
  {
    title: "Rejection Reason",
    field: "RejDetail",
    visible: true,
    sortOrder: 17,
    minWidth: 250,
  },
  { title: "Side", field: "Side", visible: true, sortOrder: 5, minWidth: 155 },
  {
    title: "Symbol",
    field: "Symbol",
    visible: true,
    sortOrder: 4,
    minWidth: 150,
  },
  { title: "Time", field: "Time", visible: true, sortOrder: 2, minWidth: 150 },
  {
    title: "Total Filled Shares",
    field: "FillQty",
    visible: true,
    sortOrder: 15,
    minWidth: 150,
  },
];
