import React, { useEffect, useState } from 'react';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Sort } from '@syncfusion/ej2-react-grids';
import apiSvc from '../../../services/api-service';
import "./style.scss";

const Fees = () => {
    const [feesData, setFeesData] = useState([]);

    const fetchData = async () => {
        const response = await apiSvc.get("SelectFeeRequest");
        if (response?.data) {
            setFeesData(response.data.Data);
        }
    }

    const feeTableColumns = ["Name", "Commission", "TafFinra", "ClearingFee", "MiscFee", "Rebate", "Sec", "Ecn"]

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <GridComponent dataSource={feesData} allowSorting={true} height={270} allowFiltering={true}>
                <ColumnsDirective>
                    {
                        feeTableColumns.map(column => {
                            return (
                                <ColumnDirective field={column} width='100' textAlign="Right" />
                            );
                        })
                    }
                </ColumnsDirective>
                <Inject services={[Sort]} />
            </GridComponent>
        </div>
    )
}

export default Fees