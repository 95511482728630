import apiSvc from "../../services/api-service";
import { AuthState } from "../auth/AuthState";
import { setAccounts, setAccountTradeLimits } from "./accounts-actions";

export async function loadAccounts(dispatch, getState) {
  const auth: AuthState = getState().auth;
  if (!auth.authInfo) {
    return;
  }

  const accountListResponse = await apiSvc
    .get("SelectAccountsRequest");
    // .then((response) => dispatch(setAccounts(response?.data.Data)));
  dispatch(setAccounts(accountListResponse?.data.Data));

  const accountsTradeLimitResponse = await apiSvc
    .get("SelectAccountTradeLimitsRequest");
    // .then((response) => dispatch(setAccountTradeLimits(response?.data.Data)));
  dispatch(setAccountTradeLimits(accountsTradeLimitResponse?.data.Data))
  return accountListResponse;
}

export const addAccount = (accountData: any) => async (dispatch, getState) => {
  const accountRequestData = {
    AccountId: accountData.Data.AccountId,
    AccountDestination: [],
    AccountStatusID: accountData.Data.AccountStatusID,
    ApprovalWhenSendingOrder: accountData.Data.ApprovalWhenSendingOrder,
    BorrowListID: accountData.Data.BorrowListID,
    FeeId: accountData.Data.FeeId,
    MainCurrencyID: accountData.Data.MainCurrencyID,
    Name: accountData.Data.Name,
    CreatedBy: accountData.Data.CreatedBy,
    CreatedOn: accountData.Data.CreatedOn,
    ModifiedBy: accountData.Data.ModifiedBy,
    ModifiedOn: accountData.Data.ModifiedBy,
  };

  const accountResponse = await apiSvc.put(
    "SaveAccountsRequest",
    accountRequestData
  );

  const accountTradeLimitData = {
    AccountId: accountResponse.data.PrimaryKey,
    BuyingPower: accountData.Data.AccountTradeLimit.BuyingPower,
    CreatedBy: accountData.Data.AccountTradeLimit.CreatedBy,
    CreatedOn: accountData.Data.AccountTradeLimit.CreatedOn,
    EnBpCtrl: accountData.Data.AccountTradeLimit.EnBpCtrl,
    EnLinkWithNetEquity: accountData.Data.AccountTradeLimit.EnLinkWithNetEquity,
    EnMarginCtrl: accountData.Data.AccountTradeLimit.EnMarginCtrl,
    EnOpenOrderBp: accountData.Data.AccountTradeLimit.EnOpenOrderBp,
    EnPrePostMkt: accountData.Data.AccountTradeLimit.EnPrePostMkt,
    EnUrlzLinkToBp: accountData.Data.AccountTradeLimit.EnUrlzLinkToBp,
    EnUrlzLinkToMaxLoss: accountData.Data.AccountTradeLimit.EnUrlzLinkToMaxLoss,
    EnVarOpenOrderBp: accountData.Data.AccountTradeLimit.EnVarOpenOrderBp,
    LinkWithNetEquity: accountData.Data.AccountTradeLimit.LinkWithNetEquity,
    MaxLoss: accountData.Data.AccountTradeLimit.MaxLoss,
    EnMaxPosition: accountData.Data.AccountTradeLimit.EnMaxPosition,
    EnMaxOpenOrder: accountData.Data.AccountTradeLimit.EnMaxOpenOrder,
    MaxOpenOrderCount: accountData.Data.AccountTradeLimit.MaxOpenOrderCount,
    EnOpenShares: accountData.Data.AccountTradeLimit.EnOpenShares,
    MaxPosition: accountData.Data.AccountTradeLimit.MaxPosition,
    ModifiedBy: accountData.Data.AccountTradeLimit.ModifiedBy,
    ModifiedOn: accountData.Data.AccountTradeLimit.ModifiedOn,
    OpenOrderBp: accountData.Data.AccountTradeLimit.OpenOrderBp,
  };

  await apiSvc.put("SaveAccountTradeLimitsRequest", accountTradeLimitData);
  dispatch(loadAccounts);
};

export const deleteAccount = (accountId: number) => async (dispatch: any) => {
  await apiSvc.api
    .delete("DeleteAccountTradeLimitsRequest", {
      params: {
        PrimaryKey: accountId,
        LoadCache: true,
      },
    })
    .then(() =>
      apiSvc.api
        .delete("DeleteAccountsRequest", {
          params: {
            PrimaryKey: accountId,
            LoadCache: true,
            Force: true,
          },
        })
        .then(() => dispatch(loadAccounts))
    );
};
