import { WIDGET_MODAL } from "../actions/types";
import { AUTH_LOGOUT_USER } from "../auth/auth-types";
import { WidgetModalState } from "./WidgetModalState";

const initialState = new WidgetModalState();

function widgetModalReducer(state = initialState, action: any) {
  switch (action.type) {
    case AUTH_LOGOUT_USER:
      return new WidgetModalState();

    case WIDGET_MODAL:
      return {
        ...state,
        id: action.id,
        action: action.payload,
      };

    default:
      return state;
  }
}

export default widgetModalReducer;
